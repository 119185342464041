import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  Marker,
  OverlayView
} from '@react-google-maps/api';
import MobileLocationMarker from 'resources/svgs/newLocationMarker.svg';
import MobileLocationMarkerActive from 'resources/svgs/newLocationMarkerActive.svg';
import RotationAngleMarker from 'resources/svgs/RotationAngleMarker';
import { motion } from 'framer-motion';
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import FullscreenButton from 'components/other/FullscreenButton';
import TourPreviewPopup from './TourPreviewPopup';

// Helpers
import { getCurrentEnvironment, capitalize } from 'helpers';
import { useProjectState } from 'stores/ProjectStore';
import { useTourState } from 'stores/TourStore';
import { useMapState } from 'stores/MapStore';
import {
  useUiState,
  navigationFloatingButtonsBottomPosition
} from 'stores/UiStore';
import uniqid from 'uniqid';
import localizer from 'localization/localizer';
import {
  isMobile,
  isMobileOnly,
  useMobileOrientation
} from 'react-device-detect';
import useMenuOptionVisibilityCheck from 'helpers/customHooks/useMenuOptionVisibilityCheck';

// Styling
import mapStyling from './mapStyling';
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  background-color: white;
`;

const NoAddressMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
`;

const PageTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

// As "toggle full screen" functionality implementation is postponed
// we make FullscreenButton invisible
const FullScreenButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  border: none;
  visibility: hidden;
`;

const CameraIndicator = styled.div.attrs((props) => ({
  style: {
    transform: 'translate(-43px, -84px) rotate(' + props.angle + 'deg)',
    transformOrigin: 'center bottom'
  }
}))``;

const defaultZoomLevel = 16;

const MapPage = () => {
  const navigate = useNavigate();

  const { ProjectState } = useProjectState();
  const { contentCollection, project, navigationItem, shareCode } =
    ProjectState;

  const { UiStateDispatch } = useUiState();

  const { TourStateDispatch } = useTourState();
  const { MapState, MapStateDispatch } = useMapState();
  const { mapMode } = MapState;

  const [loaderId] = useState(uniqid('loader-'));
  const [mapId] = useState(uniqid('map-'));
  const [mapInstance, setMapInstance] = useState();
  const googleMapsLibraries = ['drawing', 'visualization', 'places'];

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [latLng, setLatLng] = useState();
  const [mapView, setMapView] = useState();
  const [highlightedSpot, setHighlightedSpot] = useState();
  const [rotateTransform, setRotateTransform] = useState(null);
  const [showTourPreview, setShowTourPreview] = useState(false);
  const [tourPreviewContent, setTourPreviewContent] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: getCurrentEnvironment().googleMapsApiKey,
    id: loaderId,
    version: 'weekly',
    preventGoogleFontsLoading: true,
    googleMapsLibraries
  });

  const { shouldShowMenuOption } = useMenuOptionVisibilityCheck();
  const { isLandscape } = useMobileOrientation();

  // Show menu on this page
  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: {
        showMenu: !isMobileOnly || (isMobileOnly && !isLandscape)
      }
    });
  }, [UiStateDispatch, isLandscape]);

  useEffect(() => {
    const mapViewItemList =
      navigationItem?.navigationCollection?.vmNavigationItemList.filter(
        (item) => item.navigationItemType === 'mapView'
      );

    if (mapViewItemList && mapViewItemList.length > 0) {
      setMapView(mapViewItemList[0]);
    }
  }, [navigationItem, setMapView]);

  useEffect(() => {
    if (isLoaded && window.google) {
      const geocoder = new window.google.maps.Geocoder();
      const addressObject = project?.address;

      if (addressObject) {
        if (addressObject.latitude && addressObject.longitude) {
          const latLong = {
            lat: parseFloat(addressObject.latitude),
            lng: parseFloat(addressObject.longitude)
          };
          setLatLng(latLong);
        } else {
          const addressString = `${addressObject.addressLine1} ${addressObject.city} ${addressObject.country} ${addressObject.zipCode}`;
          geocoder.geocode({ address: addressString }, (results) => {
            if (results && results.length > 0) {
              const location = results[0].geometry.location;
              const newLatLong = {
                lat: location.lat(),
                lng: location.lng()
              };
              setLatLng(newLatLong);
            }
          });
        }
      } else {
        setLatLng(null);
      }
    }
  }, [isLoaded, project]);

  useEffect(() => {
    let position = navigationFloatingButtonsBottomPosition.default;
    if (showTourPreview) {
      position = navigationFloatingButtonsBottomPosition.overTourPreview;
    }
    UiStateDispatch({
      type: 'update',
      payload: {
        navigationFloatingButtonsBottomPosition: position
      }
    });
    return () => {
      UiStateDispatch({
        type: 'update',
        payload: {
          navigationFloatingButtonsBottomPosition:
            navigationFloatingButtonsBottomPosition.default
        }
      });
    };
  }, [showTourPreview, UiStateDispatch]);

  if (!isLoaded || loadError) {
    return null;
  }

  if (!latLng) {
    return <NoAddressMessage>{localizer.noAddressWarning}</NoAddressMessage>;
  }

  const onMapTypeIdChanged = () => {
    if (mapInstance) {
      const newType = mapInstance?.getMapTypeId() || 'roadmap';
      if (mapMode !== newType) {
        MapStateDispatch({
          type: 'update',
          payload: { mapMode: newType }
        });
      }
    }
  };

  const onMapLoad = (instance) => {
    setMapInstance(instance);

    setTimeout(() => {
      setIsMapLoaded(true);
    }, 200);
  };

  const onSpotClicked = (spot) => {
    isMobile ? onMobileSpotClicked(spot) : onNavigateToMediaView(spot);
  };

  const onMobileSpotClicked = (spot) => {
    setRotateTransform({
      position: {
        lat: parseFloat(spot.yCoordinate),
        lng: parseFloat(spot.xCoordinate)
      }
    });
    setHighlightedSpot(spot);
    setShowTourPreview(true);
    const mediaView =
      navigationItem?.navigationCollection?.vmNavigationItemList.find(
        (item) =>
          item.navigationItemType === 'mediaView' &&
          item.objectId === spot.value
      );

    const contentItem = contentCollection?.vmContentItemList?.find(
      (x) => x.objectId === mediaView.value
    );
    setTourPreviewContent(contentItem);
  };

  const onNavigateToMediaView = (spot) => {
    const mediaView =
      navigationItem?.navigationCollection?.vmNavigationItemList.find(
        (item) =>
          item.navigationItemType === 'mediaView' &&
          item.objectId === spot.value
      );

    // add content item to media view
    const contentItem = contentCollection?.vmContentItemList?.find(
      (x) => x.objectId === mediaView.value
    );

    TourStateDispatch({
      type: 'update',
      payload: {
        activeMediaView: { ...mediaView, contentItem }
      }
    });

    UiStateDispatch({
      type: 'update',
      payload: { enableShowcaseBackButton: true }
    });
    UiStateDispatch({
      type: 'increaseStack'
    });
    navigate(`/${shareCode}/tour${window.location.search}`);
  };

  // is not triggered if spot clicked
  const onMapClick = () => {
    setRotateTransform(null);
    setHighlightedSpot(null);
    setShowTourPreview(false);
    setTourPreviewContent(null);
  };

  // map options reference:
  // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
  const mapOptions = {
    fullscreenControl: false,
    clickableIcons: false,
    disableDefaultUI: true,
    styles: mapStyling,
    zoomControl: !isMobile,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_BOTTOM
    },
    streetViewControl: !isMobile,
    streetViewControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_BOTTOM
    },
    mapTypeControl: !isMobile,
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.TOP_RIGHT
    },
    mapTypeId: mapMode || 'roadmap',
    controlSize: 25,
    draggableCursor: 'default',
    gestureHandling: 'greedy',
    keyboardShortcuts: !isMobile
  };

  const polygonOptions = {
    fillColor: 'rgba(166, 211, 176, 0.8)',
    fillOpacity: 0.6,
    strokeColor: '#4ca760',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  };

  // Create markers
  let markers = <Marker position={latLng} />;
  if (mapView) {
    markers = mapView?.navigationCollection?.vmNavigationItemList.map(
      (spot, index) => {
        if (
          spot.navigationItemType === 'navigationSpot' &&
          // don't show markers if Tour menu option is disabled
          shouldShowMenuOption('tour')
        ) {
          const position = {
            lat: parseFloat(spot.yCoordinate),
            lng: parseFloat(spot.xCoordinate)
          };

          const isHighlighted = spot?.value === highlightedSpot?.value;

          let marker = MobileLocationMarker;
          if (isHighlighted) {
            marker = MobileLocationMarkerActive;
          }

          return (
            <Marker
              key={index}
              position={position}
              icon={marker}
              zIndex={1}
              onMouseOver={() => {
                setHighlightedSpot(spot);
              }}
              onMouseOut={() => {
                setHighlightedSpot(null);
              }}
              onClick={() => {
                onSpotClicked(spot);
              }}
            />
          );
        }

        if (spot.navigationItemType === 'polygonSpot') {
          const paths = JSON.parse(spot.value);
          return <Polygon key={index} paths={paths} options={polygonOptions} />;
        }

        return null;
      }
    );
  }

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={isMapLoaded ? { opacity: 1 } : { opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <MobileLayoutHeader>
        <PageTitle>
          {capitalize(localizer.map)}
          <FullScreenButtonWrapper>
            <FullscreenButton />
          </FullScreenButtonWrapper>
        </PageTitle>
      </MobileLayoutHeader>
      <GoogleMap
        id={mapId}
        center={latLng}
        onLoad={onMapLoad}
        onMapTypeIdChanged={onMapTypeIdChanged}
        mapContainerStyle={{
          height: '100%',
          width: '100%'
        }}
        options={mapOptions}
        zoom={defaultZoomLevel}
        onClick={onMapClick}
      >
        {markers}
        {showTourPreview && rotateTransform && highlightedSpot && (
          <OverlayView
            position={rotateTransform.position}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <CameraIndicator angle={+(highlightedSpot.rotationAngle ?? 0)}>
              <RotationAngleMarker />
            </CameraIndicator>
          </OverlayView>
        )}
      </GoogleMap>

      <TourPreviewPopup
        show={showTourPreview}
        content={tourPreviewContent}
        onClick={() => onNavigateToMediaView(highlightedSpot)}
        landscape={isLandscape}
      />
    </Wrapper>
  );
};

MapPage.propTypes = {};

MapPage.defaultProps = {};

export default MapPage;
