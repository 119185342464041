import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullscreenButton from 'components/other/FullscreenButton';
import FavoriteUnitButton from 'components/share/FavoriteUnitButton';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import { isMobileOnly, isMobile } from 'react-device-detect';
import Tippy from '@tippyjs/react';
import localizer from 'localization/localizer';
import { pulse } from 'styles/animations';

// Styling
import styled, { withTheme, css } from 'styled-components';

const HeaderWrapper = styled.div`
  width: 100%;
  min-height: 110px;
  z-index: 10;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  padding: 0 25px 0 50px;
  box-sizing: border-box;
`;

const Title = styled.div`
  height: 41px;
  font-family: ${({ theme }) => theme.mainFontFamily}, sans-serif;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 6px;
  text-align: center;
  color: ${(props) => props.theme.showcaseBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const ButtonsSection = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

const BackButton = styled(FontAwesomeIcon)`
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 15px;
  visibility: hidden;
`;

const FullscreenButtonContainer = styled.div`
  width: ${isMobile ? 40 : 50}px;
  height: ${isMobile ? 40 : 50}px;
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.gray200}`};
  border-radius: 2px;
`;

const FullscreenButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${pulse} 2s linear infinite;
    `}
`;

const UnitDetailHeader = ({ unit, theme }) => {
  const navigate = useNavigate();

  const { ProjectState } = useProjectState();
  const { shareCode, showcaseConfiguration } = ProjectState;

  // UI Store
  const { UiState } = useUiState();
  const { isEmbeddedShowcase } = UiState;

  return (
    <HeaderWrapper>
      <ButtonsWrapper>
        <BackButton
          icon={['fal', 'long-arrow-left']}
          size="lg"
          onClick={() => {
            if (window.history.length > 1) {
              navigate(-1); // go back
            } else {
              navigate(`/${shareCode}/tour`);
            }
          }}
        />
      </ButtonsWrapper>
      <Title>{unit?.title}</Title>

      {!isMobileOnly && (
        <ButtonsSection>
          {!showcaseConfiguration?.hideFavouriteOptions && (
            <FavoriteUnitButton
              unit={unit}
              buttonSize={isMobile ? 40 : 50}
              iconSize={24}
              wrapperStyles={`margin-right: 10px;`}
            />
          )}
          <FullscreenButtonContainer>
            <Tippy
              offset={[0, 5]}
              theme="favoritesToggler"
              placement="bottom"
              content={<div>{localizer.fullscreen}</div>}
              arrow={false}
              touch={false}
            >
              <FullscreenButtonWrapper animate={isEmbeddedShowcase}>
                <FullscreenButton
                  wrapperStyle={`
                    margin: 0;
                    padding: 0;
                  `}
                  iconStyle={`
                    color: ${theme.primary100},
                    font-size: 32px
                  `}
                />
              </FullscreenButtonWrapper>
            </Tippy>
          </FullscreenButtonContainer>
        </ButtonsSection>
      )}
    </HeaderWrapper>
  );
};

export default withTheme(UnitDetailHeader);
