import React, { useState, useEffect } from 'react';

// Helpers
import ReactSlider from 'react-slider';

// Styling
import styled from 'styled-components';

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledSlider = styled(ReactSlider)`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledThumb = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px ${({ theme }) => theme.gray300};
  background-color: ${({ theme }) => theme.showcaseWhite};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  outline: none;
  cursor: grab;
`;

const StyledTrack = styled.div`
  height: 6px;
  border-radius: 4px;
  background: ${({ index, theme }) =>
    index === 1 ? theme.mainAccent : theme.gray100};
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-flow: column;
  label {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.primary100};
    margin: 0 0 4px;
  }
`;

const StyledInput = styled.input`
  height: 50px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.gray200};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.mainFontFamily};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.showcaseBlack};
  outline: none;
  &:focus {
    border-color: #b6a36c;
  }
`;

const ValuesDivider = styled.span`
  margin: 0 10px;
  color: ${({ theme }) => theme.primary100};
  position: relative;
  top: 32px;
`;

const Error = styled.span`
  margin: 5px 0 0;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.errorColor};
`;

const formatValue = (val) =>
  val.toLocaleString('en-EN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

const InputField = ({ label, value, updateValue, range, fieldName }) => {
  const [localValue, setLocalValue] = useState(formatValue(value));

  const [error, setError] = useState();

  useEffect(() => {
    setLocalValue(formatValue(value));
  }, [value]);

  const validateAndUpdate = (value) => {
    const isValid = value >= range.min && value <= range.max;
    const roundedValue = value ? Number.parseFloat(value).toFixed(2) : 0;
    if (isValid) {
      setLocalValue(formatValue(roundedValue));
      updateValue(fieldName, Number(roundedValue));
    } else {
      setLocalValue(formatValue(roundedValue));
      setError('Value is out of range');
    }
  };

  return (
    <InputFieldWrapper>
      <label id={label}>{label}</label>
      <StyledInput
        aria-labelledby={label}
        error={error}
        value={localValue}
        onChange={({ target }) => {
          setError('');
          setLocalValue(target.value);
        }}
        onFocus={() => {
          setError('');
          setLocalValue(value);
        }}
        onBlur={() => validateAndUpdate(localValue)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            validateAndUpdate(localValue);
          } else if (
            ![
              '0',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '0',
              '.'
            ].includes(event.key)
          ) {
            event.preventDefault();
          }
        }}
      />
      {error && <Error>{error}</Error>}
    </InputFieldWrapper>
  );
};

const RangeFilter = ({ filterKey, filterObject, onUpdateValues }) => {
  const [localValues, setLocalValues] = useState(
    filterObject.values ?? filterObject.limits
  );

  useEffect(() => {
    setLocalValues(filterObject.values);
  }, [filterObject]);

  return (
    <VerticalWrapper data-testid={`filterSlider${filterObject.filterOption}`}>
      <StyledSlider
        min={filterObject.limits.min}
        max={filterObject.limits.max}
        step={Math.ceil(
          (filterObject.limits.max - filterObject.limits.min) / 100
        )}
        value={[localValues.min, localValues.max]}
        onChange={(values) => {
          const max =
            values[1] > filterObject.limits.max
              ? filterObject.limits.max
              : values[1];
          const min =
            values[0] < filterObject.limits.min
              ? filterObject.limits.min
              : values[0];
          onUpdateValues({ min, max });
          setLocalValues({ min, max });
        }}
        renderTrack={(props, state) => {
          return (
            <StyledTrack {...props} index={state.index}>
              {state.valueNow}
            </StyledTrack>
          );
        }}
        renderThumb={(props, state) => {
          return <StyledThumb {...props} />;
        }}
      />

      <ValueWrapper>
        <InputField
          label={`Min ${filterKey.toLowerCase()}, ${filterObject.unit}`}
          fieldName={'min'}
          value={localValues.min}
          updateValue={(fieldName, value) => {
            const newValues = { ...localValues, [fieldName]: value };
            onUpdateValues(newValues);
            setLocalValues(newValues);
          }}
          range={filterObject.limits}
        />
        <ValuesDivider>-</ValuesDivider>
        <InputField
          label={`Max ${filterKey.toLowerCase()}, ${filterObject.unit}`}
          fieldName={'max'}
          value={localValues.max}
          updateValue={(fieldName, value) => {
            const newValues = { ...localValues, [fieldName]: value };
            onUpdateValues(newValues);
            setLocalValues(newValues);
          }}
          range={filterObject.limits}
        />
      </ValueWrapper>
    </VerticalWrapper>
  );
};

export default RangeFilter;
