import * as React from 'react';

function TouchScreenIcon(props) {
  return (
    <svg width={36} height={36} {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-2 37V-3h40v40z" />
        <path
          d="M22.934 2.52h-7.373v-.025A2.967 2.967 0 0 0 14.02.727 3.038 3.038 0 0 0 11.67.55a2.967 2.967 0 0 0-1.767 1.541 3.08 3.08 0 0 0-.177.43H2.858L4.476.852l-.91-.883L.51 3.15l3.056 3.182.909-.884-1.616-1.666h6.717c.025.227.075.43.126.656l4.975 15c-1.238-.555-3.03-1.136-4.42-.757-.732.202-1.287.631-1.64 1.288-1.112 2.045.858 3.282 2.423 4.267 1.111.707 2.248 1.415 2.526 2.298.606 1.793 1.843 3.056 3.61 3.662 2.097.707 3.764 2.399 4.47 4.495l.202.606 13.561-4.495-.227-.682a13.91 13.91 0 0 1-.707-4.545l.025-3.359c0-1.111-.151-2.197-.505-3.258l-1.894-5.63-1.111-3.385c-.101-.328-.379-.934-.985-1.338-.43-.303-1.187-.58-2.298-.227-.555.176-1.01.429-1.338.732-.48-1.06-1.591-1.843-3.182-1.313a3.724 3.724 0 0 0-1.414.808c-.556-.859-1.591-1.389-2.98-.934-.555.176-.985.429-1.313.732l-1.54-4.647h6.969l-1.616 1.667.909.884 3.056-3.182-3.056-3.182-.909.884 1.641 1.692zm-4.798 7.096c.152-.303.53-.556 1.061-.758 1.111-.378 1.54.329 1.692.758l.354 1.035 1.413 4.268 1.188-.404-1.415-4.242c-.05-.152-.151-.859 1.162-1.288 1.111-.379 1.54.328 1.692.757l1.54 4.647 1.187-.404-1.086-3.283h.025s-.05-.202.051-.43c.151-.302.53-.555 1.086-.757.505-.177.884-.151 1.187.076.303.202.454.556.505.707l1.111 3.384 1.894 5.631c.303.91.454 1.869.454 2.828l-.025 3.359c0 1.515.202 3.005.606 4.444l-11.212 3.712a8.326 8.326 0 0 0-5.025-4.696c-1.389-.48-2.349-1.44-2.803-2.854-.43-1.313-1.768-2.172-3.056-2.98-1.894-1.212-2.45-1.742-1.995-2.6.202-.354.48-.556.859-.683 1.464-.404 3.99.834 4.848 1.339l1.465.884-5.985-18.081a1.772 1.772 0 0 1 .101-1.389c.227-.43.581-.758 1.035-.909.455-.152.96-.126 1.39.101.429.227.757.58.909 1.035L18.54 15.4l1.187-.404-1.641-4.95c-.026.076-.076-.151.05-.429z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default TouchScreenIcon;
