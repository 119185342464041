import React from 'react';

// Components
import ContactUs from 'components/share/ContactUs/ContactUs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import { useProjectState } from 'stores/ProjectStore';
import { getValueFromLocalized } from 'helpers';

// Styling
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.div`
  padding: ${isMobileOnly ? '23px 15px 0' : '0'};
  ${!isMobileOnly &&
  `
    position: relative;
    z-index: 10;
    margin: 10px 25px;
  `};
`;

const Card = styled.div`
  padding: 16px 30px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray200};
  background-color: ${(props) => props.theme.grayWhiteOff};
  ${!isMobileOnly &&
  `
      padding: 18px 30px 18px 20px;
      flex-direction: row;
    `};
`;

const CardText = styled.p`
  color: #574f6b;
  font-size: ${isMobileOnly ? '1rem' : '1.25rem'};
  margin: ${isMobileOnly ? '10px 0 20px' : '0 20px'};
  word-break: break-word;
  span {
    font-weight: 600;
    display: ${isMobileOnly ? 'block' : 'inline'};
  }
`;

const StyledContactUsIcon = styled(FontAwesomeIcon)`
  font-size: 46px;
  color: ${(props) => props.theme.primary100};
`;

const ContactUsButtonWrapper = styled.div`
  margin-left: ${isMobileOnly ? '0' : 'auto'};
  width: ${isMobileOnly ? '100%' : '120px'};
  flex-shrink: 0;
`;

const ContactUsCard = ({ theme }) => {
  // Project state
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;
  const embededUnitFormConfiguration =
    showcaseConfiguration?.embededUnitFormConfiguration;

  const bannerText = getValueFromLocalized(
    embededUnitFormConfiguration?.localizedContactUsBannerText,
    embededUnitFormConfiguration?.contactUsBannerText ||
      localizer.formatString(
        localizer.greatChoice,
        <span>{localizer.specialOffer}.</span>
      )
  );

  return (
    <Wrapper>
      <Card>
        <StyledContactUsIcon icon={['fal', 'envelope-open-text']} size="1x" />
        <CardText>{bannerText}</CardText>
        <ContactUsButtonWrapper>
          <ContactUs
            buttonStyles={`background-color: ${theme.defaultFloatingContactUsButtonColor}`}
            location="Favorites page"
          />
        </ContactUsButtonWrapper>
      </Card>
    </Wrapper>
  );
};

export default withTheme(ContactUsCard);
