import React, { useState, useEffect, useCallback } from 'react';
import { number, func } from 'prop-types';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import localizer from 'localization/localizer';
import {
  createDefaultFiltersForUnits,
  unitStatesNotArchived,
  shouldHidePriceWhenShared
} from 'helpers/units/VmUnitHelper';
import { useShowUnitGeneralFields } from 'helpers/customHooks';

// Components
import SearchField from 'components/other/SearchField';
import FilterToggleButtons from './FilterToggleButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterSlider from './FilterSlider';

// Styling
import { motion } from 'framer-motion';
import styled from 'styled-components';

const VerticalWrapper = styled(motion.div)`
  position: absolute;
  z-index: 60;
  width: 290px;
  right: 15px;
  bottom: 145px;
  max-height: calc(100vh - 240px);
  overflow: auto;
  display: flex;
  flex-flow: column;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 5px;
  padding: 25px 20px;
  padding-top: 15px;
  box-sizing: border-box;
`;

const StyledSearchField = styled(SearchField)`
  margin: 15px;
  margin-top: 0;
`;

const StyledSlider = styled(FilterSlider)`
  margin-bottom: 10px;
`;

const FilterSection = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
`;

const InformationSection = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const UnitsInFilterIndicator = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-flow: row;
  color: ${(props) => props.theme.primary200};
`;

const IndicatorBold = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const ResetFiltersButton = styled.div`
  font-size: 0.7rem;
  color: ${(props) => props.theme.primary100};
  user-select: none;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: relative;
  right: -15px;
  top: -5px;
  margin-left: auto;
  background: none;
  outline: none;
  border: none;
  width: fit-content;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  background-color: ${(props) => props.theme.gray100};
`;

const ToggleButton = styled.button`
  border: none;
  outline: none;
  font-size: 0.625rem;
  padding: 10px 15px;
  background: ${(props) =>
    props.toggled ? props.theme.primary900 : props.theme.gray100};
  color: ${(props) =>
    props.toggled ? props.theme.whitePure : props.theme.primary200};
  cursor: pointer;
  margin: 0 1px;
`;

/**
 * Modal that contains options to filter the units that are currently displayed
 * The filters are directly updated in the project store since they are used in the entire showcase
 */
const UnitFilterModal = ({
  amountOfUnitsInFilter,
  onCloseModalRequest,
  onSearchChanged
}) => {
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { unitFilters, filterSearchValue, project, showcaseConfiguration } =
    ProjectState;

  // Auth State
  const { AuthState } = useAuthState();

  const showUnitState = showcaseConfiguration?.showUnitState ?? true;
  const { showPrice, showBathrooms, showBedrooms, showSurface } =
    useShowUnitGeneralFields(false, null, project.unitList);

  const [bathroomFilter, setBathroomFilter] = useState();
  const [bedroomFilter, setBedroomFilter] = useState();
  const [surfaceFilter, setSurfaceFilter] = useState();
  const [priceFilter, setPriceFilter] = useState();
  const [stateFilter, setStateFilter] = useState();

  const setFilterSliderData = useCallback(
    (filters) => {
      const defaultUnitFilters = createDefaultFiltersForUnits(project.unitList);

      const existingBathroomsFilter = filters?.find(
        (x) => x.filterOption === 'numberOfBathrooms'
      );
      const defaultBathroomsFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'numberOfBathrooms'
      );
      setBathroomFilter(existingBathroomsFilter ?? defaultBathroomsFilter);

      const existingBedroomsFilter = filters?.find(
        (x) => x.filterOption === 'numberOfBedrooms'
      );
      const defaultBedroomsFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'numberOfBedrooms'
      );
      setBedroomFilter(existingBedroomsFilter ?? defaultBedroomsFilter);

      const existingSurfaceFilter = filters?.find(
        (x) => x.filterOption === 'surface'
      );
      const defaultSurfaceFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'surface'
      );
      setSurfaceFilter(existingSurfaceFilter ?? defaultSurfaceFilter);

      const existingPriceFilter = filters?.find(
        (x) => x.filterOption === 'price'
      );
      const defaultPriceFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'price'
      );
      setPriceFilter(existingPriceFilter ?? defaultPriceFilter);

      const existingStateFilter = filters?.find(
        (x) => x.filterOption === 'state'
      );
      const defaultStateFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'state'
      );
      setStateFilter(existingStateFilter ?? defaultStateFilter);
    },
    [project]
  );

  // Set filters stored in the showcase
  useEffect(() => {
    if (
      !bathroomFilter &&
      !bedroomFilter &&
      !surfaceFilter &&
      !priceFilter &&
      !stateFilter
    ) {
      setFilterSliderData(unitFilters);
    }
  }, [
    unitFilters,
    bedroomFilter,
    bathroomFilter,
    surfaceFilter,
    priceFilter,
    stateFilter,
    setFilterSliderData
  ]);

  const onUpdateFilter = (filter, values) => {
    let newFilters = unitFilters;

    if (unitFilters.find((x) => x.filterOption === filter.filterOption)) {
      newFilters = unitFilters.map((x) => {
        if (x.filterOption !== filter.filterOption) {
          return x;
        } else {
          return { ...filter, values: values };
        }
      });
    } else {
      newFilters.push({ ...filter, values: values });
    }

    // We directly update the store instead of just passing this to the parent since we use these filters in the entire showcase
    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        unitFilters: [...newFilters]
      }
    });
  };

  const onResetFilters = () => {
    setFilterSliderData([]);
    // Update the filters used elsewhere in the showcase
    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        unitFilters: [],
        filterSearchValue: ''
      }
    });

    // Reset the search field
    onSearchChanged('');
    document.getElementById('clearButton').click();
  };

  return (
    <VerticalWrapper
      data-testid="unitFilterModal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <CloseButton onClick={onCloseModalRequest}>
        <FontAwesomeIcon icon={['fal', 'times']} size="1x" />
      </CloseButton>
      <StyledSearchField
        onChange={onSearchChanged}
        initValue={filterSearchValue}
      />
      <FilterSection>
        {bathroomFilter && showBathrooms && (
          <StyledSlider
            key={'sliderBathrooms'}
            filterObject={bathroomFilter}
            onUpdateValues={(values) => {
              onUpdateFilter(bathroomFilter, values);
            }}
          />
        )}
        {bedroomFilter && showBedrooms && (
          <FilterSlider
            key={'sliderBedrooms'}
            filterObject={bedroomFilter}
            onUpdateValues={(values) => {
              onUpdateFilter(bedroomFilter, values);
            }}
          />
        )}
        {(showBathrooms || showBedrooms) && <Divider />}
        {surfaceFilter && showSurface && (
          <StyledSlider
            key={'sliderSurface'}
            filterObject={surfaceFilter}
            onUpdateValues={(values) => {
              onUpdateFilter(surfaceFilter, values);
            }}
          />
        )}
        {priceFilter && showPrice && (
          <FilterSlider
            key={'sliderPrice'}
            filterObject={priceFilter}
            onUpdateValues={(values) => {
              onUpdateFilter(priceFilter, values);
            }}
            disabled={
              shouldHidePriceWhenShared(showcaseConfiguration, AuthState) ||
              !showPrice
            }
          />
        )}

        {stateFilter &&
          showUnitState &&
          showcaseConfiguration?.hideInOptionSoldUnits !== true && (
            <>
              {(showPrice || showSurface) && <Divider />}
              <FilterToggleButtons
                key={'toggleButtonsState'}
                toggledOptions={stateFilter}
                allOptions={unitStatesNotArchived}
                onUpdateToggledOptions={(toggledOptions) => {
                  onUpdateFilter(stateFilter, toggledOptions);
                }}
                filterKey="state"
                button={(props) => (
                  <ToggleButton {...props}>{props.children}</ToggleButton>
                )}
              />
            </>
          )}
      </FilterSection>
      <InformationSection>
        <ResetFiltersButton onClick={onResetFilters}>
          {localizer.resetAll}
        </ResetFiltersButton>
        <UnitsInFilterIndicator>
          <IndicatorBold>
            {`${amountOfUnitsInFilter} ${
              amountOfUnitsInFilter === 1
                ? localizer.unit.toLowerCase()
                : localizer.units.toLowerCase()
            }`}
          </IndicatorBold>
          <div>{localizer.inFilter}</div>
        </UnitsInFilterIndicator>
      </InformationSection>
    </VerticalWrapper>
  );
};

UnitFilterModal.propTypes = {
  /** The amount of units that are in the current filter */
  amountOfUnitsInFilter: number.isRequired,
  /** We ask the parent to be closed
   * @callback
   */
  onCloseModalRequest: func.isRequired,
  /** Callback when search value in the filter modal has been changed
   * @callback
   * @param {string} value The search value
   */
  onSearchChanged: func.isRequired
};

export default UnitFilterModal;
