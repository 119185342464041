import React, { useState, memo } from 'react';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { ShareIcon } from 'resources/icons';
import ShareModal from 'components/share/ShareModal';

// Helpers
import localizer from 'localization/localizer';
import { capitalize, isDarkColor } from 'helpers';
import { isMobile, useMobileOrientation } from 'react-device-detect';

// Styling
import styled, { css } from 'styled-components';

const Button = styled(motion.button)`
  position: fixed;
  z-index: 4;
  bottom: 25px;
  left: 15px;
  border: solid 1px rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  backdrop-filter: blur(4px);
  background-color: ${({ theme }) =>
    isDarkColor(theme.contactCardBg) ? theme.contactCardBg : theme.brand500};
  color: ${({ theme }) => theme.showcaseWhite};
  cursor: pointer;
  font-family: ${({ theme }) => theme.mainFontFamily};
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  &:hover {
    padding: ${isMobile ? '0 5px' : '0 30px 0 10px'};
  }
  ${({ landscape, view }) => {
    if (!isMobile) return '';
    return css`
      left: calc(100% - 60px);
      bottom: ${landscape && view === 'map' ? 15 : 75}px;
      padding: 0 5px;
    `;
  }}
`;

const IconWrapper = styled(motion.div)`
  width: 40px;
  height: ${isMobile ? 50 : 60}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ hovered }) => (hovered ? 5 : 0)}px;
  transition: all 100ms ease 100ms;
  font-size: 1rem;
`;

const Text = styled(motion.div)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.03rem;
  font-size: 1rem;
`;

const StyledShareIcon = styled(ShareIcon)`
  transform: scale(1.65);
  color: white;
  fill: white;
`;

const SharePortfolioButton = memo(({ encodedVaultId, view }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const { isLandscape } = useMobileOrientation();

  let portfolioUrl = 'portfolio';
  if (encodedVaultId) {
    portfolioUrl += `/${encodedVaultId}`;
  }

  const nativeSharingHandler = () => {
    navigator
      .share({
        title: 'PROMPTO MARKETPLACE',
        url: `${encodedVaultId ? encodedVaultId : ''}${window.location.search}`
      })
      .then(() => {})
      .catch(() => {});
  };

  const modal = (
    <ShareModal
      onCloseModal={() => setShowShareModal(false)}
      providedPath={portfolioUrl}
      withMobileOption={true}
      nativeSharingHandler={nativeSharingHandler}
    />
  );

  return (
    <>
      <AnimatePresence>
        <Button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={(e) => {
            e.stopPropagation();
            setShowShareModal(true);
          }}
          landscape={isLandscape}
          view={view}
        >
          <IconWrapper hovered={isHovered && !isMobile}>
            <StyledShareIcon />
          </IconWrapper>
          <AnimatePresence>
            {isHovered && !isMobile && (
              <Text
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
              >
                {capitalize(localizer.share)}
              </Text>
            )}
          </AnimatePresence>
        </Button>
      </AnimatePresence>

      {showShareModal && modal}
    </>
  );
});

export default SharePortfolioButton;
