import React from 'react';

// Components
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortfolioCardThumbnail from '../PortfolioCardThumbnail';

// Helpers
import { isDarkColor, buildAddress } from 'helpers';
import { getLocalizedProjectTitle } from 'helpers/project/VmProjectSectionHelper';
import to from 'await-to-js';
import { isMobile } from 'react-device-detect';
import { NavigationCollection } from '@prompto-api';

// Styling
import styled from 'styled-components';

const IMAGE_HEIGHT = isMobile ? 100 : 120;
const IMAGE_WIDTH = isMobile ? 120 : 160;

const Wrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  width: 600px;
  max-width: 90vw;
  height: ${IMAGE_HEIGHT}px;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 2px ${({ theme }) => theme.gray200};
  background-color: ${({ theme }) => theme.showcaseWhite};
  transition: all 180ms ease;
  flex-shrink: 0;
  &:hover {
    border: solid 2px
      ${({ theme }) =>
        isDarkColor(theme.contactCardBg)
          ? theme.contactCardBg
          : theme.brand500};
    cursor: pointer;
    #arrow-right {
      right: 25px;
    }
  }

  #arrow-right {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 180ms ease;
  }
`;

const CardContentWrapper = styled.div`
  padding: 10px 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  position: relative;
  z-index: 4;
  flex-grow: 1;
`;

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 30px;
  margin: 0 0 10px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
`;

const Address = styled(Title)`
  font-size: 1rem;
  line-height: 20px;
  color: ${({ theme }) => theme.primary100};
  margin: 0;
  padding-left: 20px;
  position: relative;
  flex-shrink: 0;
`;

const LocationIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const ThumbnailWrapper = styled.div`
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT}px;
  position: relative;
  flex-shrink: 0;
`;

const ArrowRightIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${({ theme }) => theme.primary100};
`;

const ProjectCard = ({ project, onShowcaseSelected, sessionToken }) => (
  <Wrapper
    onClick={async () => {
      const contentCollectionObjectId = project.vmContentCollection?.objectId;

      // get the id of the first navigationItem in the collection
      let navigationItemObjectId;
      const navCollId = project?.navigationCollection?.objectId;
      if (navCollId) {
        const [, result] = await to(
          NavigationCollection.get(navCollId, true, sessionToken)
        );
        if (result) {
          const navCollection = result.data?.navigationCollection;
          const navItemList = navCollection?.vmNavigationItemList;
          if (navItemList && navItemList.length > 0) {
            navigationItemObjectId = navItemList[0].objectId;
          }
        }
      }

      onShowcaseSelected(
        contentCollectionObjectId,
        navigationItemObjectId,
        project.objectId
      );
    }}
  >
    <ThumbnailWrapper>
      <PortfolioCardThumbnail
        uri={project.thumbnailUri}
        height={IMAGE_HEIGHT}
      />
    </ThumbnailWrapper>
    <CardContentWrapper>
      <Title>{getLocalizedProjectTitle(project)}</Title>
      <Address>
        <LocationIcon icon={['fas', 'map-marker-alt']} size="sm" />
        {buildAddress(project?.address)}
      </Address>
    </CardContentWrapper>
    <ArrowRightIcon id="arrow-right" icon={['far', 'angle-right']} size="1x" />
  </Wrapper>
);

export default ProjectCard;
