import { useState, useReducer, useEffect } from 'react';

// Components
import { PlayThinIcon } from 'resources/icons/index';

// Helpers
import { getCurrentEnvironment, computeCloudinaryThumbnail } from 'helpers';
import { number, shape, string, oneOfType } from 'prop-types';
import localizer from 'localization/localizer';
import { isMobileOnly } from 'react-device-detect';

// Styling
/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StyledPlayThinIcon = styled(PlayThinIcon)`
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const ThumbnailError = styled.p`
  z-index: 1;
  height: 15px;
  opacity: 0.75;
  font-size: 11px;
  text-align: center;
  color: white;
  margin: 5px;
`;

const Thumbnail = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  ${(props) =>
    props.error &&
    `
    filter: blur(10px);
    -moz-filter: blur(10px);
    transform: scale(1.1);
  `}
`;

function eventReducer(state, action) {
  switch (action.type) {
    case 'trackThumbnailLoadedFailed':
      return { ...state };
    default:
      throw new Error();
  }
}

const RATIO = 1.78; // standard 16x9 video ratio

const VideoPreview = ({ data, size, fallbackThumbnail, isVerticalLayout }) => {
  const dispatch = useReducer(eventReducer, { data })[1];
  const currentEnvironment = getCurrentEnvironment();

  // calculate sizes
  let height = size;
  let width = size * RATIO;

  if (isVerticalLayout) {
    width = size;
    height = width / RATIO;
  }

  const [thumbnailError, setThumbnailError] = useState(false);
  const [thumbnailUri, setThumbnailUri] = useState(
    computeCloudinaryThumbnail(
      decodeURIComponent(data.contentUri),
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryVideoBaseUrl
    )
  );

  // track when the thumbnail fails to load
  useEffect(() => {
    if (thumbnailError) {
      dispatch({ type: 'trackThumbnailLoadedFailed' });
    }
  }, [thumbnailError, dispatch]);

  const previewAttributes = {
    style: {
      objectFit: 'cover',
      objectPosition: 'center'
    }
  };
  if (isMobileOnly) {
    previewAttributes.style = {
      ...previewAttributes.style,
      height: '100%',
      width: '100%'
    };
  } else {
    previewAttributes.height = height;
    previewAttributes.width = width;
    previewAttributes.style = {
      ...previewAttributes.style,
      height: `${height}px`,
      width: `${width}px`
    };
  }

  return (
    <Wrapper style={previewAttributes.style}>
      <PreviewContainer>
        <StyledPlayThinIcon />
        {thumbnailError && (
          <ThumbnailError>{localizer.noPreview}</ThumbnailError>
        )}
      </PreviewContainer>
      <Thumbnail
        error={thumbnailError}
        src={thumbnailUri}
        alt="thumbnail"
        onError={() => {
          setThumbnailError(true);
          setThumbnailUri(fallbackThumbnail);
        }}
        {...previewAttributes}
      />
    </Wrapper>
  );
};

VideoPreview.propTypes = {
  data: shape({}).isRequired,
  pageWidth: number,
  size: oneOfType([number, string]),
  fallbackThumbnail: string
};

VideoPreview.defaultProps = {
  fallbackThumbnail: null,
  pageWidth: null,
  size: null
};

export default VideoPreview;
