import React, { forwardRef } from 'react';

// Styling
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  margin-left: 0;
  margin-right: ${({ layoutType, noGap }) =>
    layoutType === 'horizontal' && !noGap ? '50px' : 0};
  margin-bottom: ${({ layoutType }) =>
    layoutType === 'horizontal' ? 0 : '50px'};
  position: relative;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  line-height: 60px;
  color: ${({ theme }) => theme.primary300};
  margin: 0;
`;

const ContentSection = forwardRef(
  ({ title, children, layoutType, noGap = false }, ref) => (
    <Wrapper layoutType={layoutType} ref={ref} noGap={noGap}>
      {title && <Title>{title}</Title>}
      {children}
    </Wrapper>
  )
);

export default ContentSection;
