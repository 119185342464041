import React from 'react';

// Components
import ToggleFavoritesButton from 'components/other/ToggleFavoritesButton';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import localizer from 'localization/localizer';
import { useNavigate } from 'react-router-dom';

const ToggleShowFavoritesButton = () => {
  // Project state
  const { ProjectState } = useProjectState();
  const { favoritesList, shareCode, showcaseConfiguration } = ProjectState;

  // UI state
  const { UiState, UiStateDispatch } = useUiState();
  const { showFavorites } = UiState;

  const navigate = useNavigate();

  const navigateToFavoritesList = (e) => {
    e.preventDefault();
    UiStateDispatch({
      type: 'update',
      payload: { enableShowcaseBackButton: true }
    });
    UiStateDispatch({
      type: 'increaseStack'
    });
    navigate(`/${shareCode}/favorites${window.location.search}`);
  };

  if (showcaseConfiguration?.hideFavouriteOptions) return null;

  return (
    <ToggleFavoritesButton
      onClick={navigateToFavoritesList}
      tipText={localizer.favorites}
      switchedOn={showFavorites}
      favoritesCount={favoritesList?.length ?? 0}
      iconStyles={`color: rgba(0,0,0,0.5) !important`}
      tipPlacement="bottom"
    />
  );
};

export default ToggleShowFavoritesButton;
