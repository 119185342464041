import React, { useState, useEffect } from 'react';
import { number, func, bool } from 'prop-types';

// Components
import FilterModal from './UnitFilterModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnitSearchCTA from './UnitSearchCTA';

// Styling
import styled, { css, keyframes } from 'styled-components';

//Helpers
import Cookies from 'universal-cookie';
import { getCurrentEnvironment } from 'helpers';
import { useProjectState } from 'stores/ProjectStore';
import {
  useUiState,
  navigationFloatingButtonsBottomPosition
} from 'stores/UiStore';
import localizer from 'localization/localizer';

const MainIndicator = styled.button`
  position: absolute;
  z-index: 500;
  width: 82px;
  height: 50px;
  right: 15px;
  bottom: 80px;
  outline: none;
  border: none;
  border: 1px solid transparent;
  background-color: ${(props) =>
    props.enabled ? props.theme.fadeBlack : props.theme.fadeGray};
  font-size: 16px;
  color: ${(props) =>
    props.enabled ? props.theme.whitePure : props.theme.primary100};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: width 0.3s ease-in-out;
  cursor: ${(props) => (props.enabled ? 'pointer' : 'inherit')};
  ${({ animate }) =>
    animate &&
    css`
      animation: ${pulse} 2s linear infinite;
    `}
  &:hover {
    width: 120px;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.05);
  }
  28% {
    transform: scale(0.95);
  }
  46% {
    transform: scale(1.05);
  }
  64% {
    transform: scale(1);
  }
`;

const FilterIcon = styled(FontAwesomeIcon)`
  margin-right: ${(props) => (props.isHovered ? '8px' : '0')};
`;

const ActiveFiltersIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 34px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.mainAccent};
  font-family: open-sans;
  font-size: 0.75rem;
  font-weight: 600;
`;

/**
 * Button that opens and closes the unit filter modal in the image tour
 */
const ToggleFilterModalButton = ({
  amountOfUnitsInFilter,
  onModalToggled,
  onSearchChanged,
  enabled,
  isFilterButtonHovered,
  setIsFilterButtonHovered,
  showUnits
}) => {
  const { ProjectState } = useProjectState();
  const { unitFilters, filterSearchValue } = ProjectState;

  const unitFiltersCount = unitFilters?.filter(
    (filter) =>
      filter?.limits?.min !== filter?.values?.min ||
      filter?.limits?.max !== filter?.values?.max
  ).length + !!filterSearchValue;

  const { UiStateDispatch } = useUiState();

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSearchCTAVisible, setSearchCTAVisible] = useState(false);

  const cookies = new Cookies();
  const showSearchUnitCTACookie = `prompto/${
    getCurrentEnvironment().env
  }/showSearchUnitCTA`;

  const hideTooltip = () => {
    // do not set cookie if filter button is disabled
    if (!enabled) return;
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 30); // 30 days in milliseconds
    cookies.set(showSearchUnitCTACookie, 'false', {
      expires: date,
      sameSite: 'none',
      secure: true
    });
    setSearchCTAVisible(false);
  };

  useEffect(() => {
    const showSearchUnitCTA = cookies.get(showSearchUnitCTACookie);
    if (!showSearchUnitCTA) {
      setSearchCTAVisible(true);
    }
  }, [showSearchUnitCTACookie, cookies]);

  useEffect(() => {
    // if we are showing the CTA tooltip, we move the contact us floating button
    let position = isSearchCTAVisible
      ? navigationFloatingButtonsBottomPosition.overUnitFilterTooltip
      : navigationFloatingButtonsBottomPosition.overZoomIndicator;
    UiStateDispatch({
      type: 'update',
      payload: {
        navigationFloatingButtonsBottomPosition: position
      }
    });
  }, [UiStateDispatch, isSearchCTAVisible]);

  return (
    <>
      <MainIndicator
        onClick={() => {
          if (!enabled) return null;
          hideTooltip();
          setIsModalOpened((current) => {
            onModalToggled(!current);
            return !current;
          });
        }}
        enabled={enabled}
        onMouseEnter={() => setIsFilterButtonHovered(true)}
        onMouseLeave={() => setIsFilterButtonHovered(false)}
        animate={
          enabled && !isModalOpened && !isFilterButtonHovered && !showUnits
        }
      >
        <UnitSearchCTA show={isSearchCTAVisible && enabled} />
        <FilterIcon
          icon={['fas', 'filter']}
          size="1x"
          isHovered={isFilterButtonHovered}
        />
        {isFilterButtonHovered && <>{localizer.filters}</>}
        {unitFiltersCount > 0 && (
          <ActiveFiltersIndicator>{unitFiltersCount}</ActiveFiltersIndicator>
        )}
      </MainIndicator>
      {isModalOpened && (
        <FilterModal
          amountOfUnitsInFilter={amountOfUnitsInFilter}
          onCloseModalRequest={() => {
            onModalToggled(false);
            setIsModalOpened(false);
          }}
          onSearchChanged={onSearchChanged}
        />
      )}
    </>
  );
};

ToggleFilterModalButton.propTypes = {
  /** The amount of units that are in the current filter */
  amountOfUnitsInFilter: number.isRequired,
  /** Callback when the filter modal visibility has been toggled
   * @callback
   * @param {bool} isOpened If the modal is opened or not
   */
  onModalToggled: func.isRequired,
  /** Callback when search value in the filter modal has been changed
   * @callback
   * @param {string} value The search value
   */
  onSearchChanged: func.isRequired,
  /** Sets the filter availability */
  enabled: bool.isRequired
};

export default ToggleFilterModalButton;
