import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import { useAuthState } from 'stores/AuthStore';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';
import { capitalize } from 'helpers';

// Styling
import styled, { css } from 'styled-components';

const Button = styled(motion.button)`
  border: none;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.showcaseWhite};
  color: ${({ theme }) => theme.primary100};
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  margin-right: 10px;
  ${isMobile &&
  css`
    padding: 0;
    background-color: transparent;
    color: ${({ theme }) => theme.primary300};
    backdrop-filter: none;
  `}
  &:hover {
    background-color: ${({ theme }) => theme.grayWhiteOff};
    padding-right: 15px;
  }
`;

const IconWrapper = styled(motion.div)`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease 100ms;
  font-size: 1.25rem;
`;

const Text = styled(motion.div)`
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

const BackToMarketplaceButton = memo(({ show }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isVaultPublic, setIsVaultPublic] = useState(false);
  const [encodedVaultId, setEncodedVaultId] = useState();
  // button is hidden initially
  const [buttonAllowed, setButtonAllowed] = useState(false);

  // Project store
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { project } = ProjectState;
  const vault = project?.vault;

  // UI state
  const { UiState } = useUiState();
  const { isEmbeddedShowcase } = UiState;

  // Auth state
  const { AuthState } = useAuthState();
  const { authenticated } = AuthState;

  // Marketplace state
  const { MarketplaceState } = useMarketplaceState();
  const { marketplaceInitialized } = MarketplaceState;

  useEffect(() => {
    // never show the button in embedded mode
    if (isEmbeddedShowcase) return;

    // standalone mode
    // always show the button for authorized users
    if (authenticated) {
      setButtonAllowed(true);
      return;
    }
    // for regular user button is shown only if came to a showcase from the marketplace
    setButtonAllowed(marketplaceInitialized);
  }, [isEmbeddedShowcase, authenticated, marketplaceInitialized]);

  useEffect(() => {
    if (!vault) return;
    if (vault.vaultSettings?.portfolioIsPublic) {
      setEncodedVaultId(encodeURIComponent(window.btoa(vault.objectId)));
      setIsVaultPublic(true);
    }
  }, [vault]);

  const navigate = useNavigate();

  const showBackButton =
    isVaultPublic && encodedVaultId && show && buttonAllowed;

  return (
    <AnimatePresence>
      {showBackButton && (
        <Button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={(e) => {
            e.stopPropagation();
            ProjectStateDispatch({
              type: 'reset'
            });
            navigate(
              `/portfolio/${encodedVaultId}${window.location.search}`
            );
          }}
        >
          <IconWrapper>
            <FontAwesomeIcon
              icon={['fal', isHovered ? 'chevron-double-left' : 'city']}
              size="1x"
            />
          </IconWrapper>
          <AnimatePresence>
            {isHovered && !isMobile && (
              <Text
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
              >
                {capitalize(localizer.portfolio)}
              </Text>
            )}
          </AnimatePresence>
        </Button>
      )}
    </AnimatePresence>
  );
});

export default BackToMarketplaceButton;
