import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react';
import { bool, func, string, number } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { debounce } from 'helpers';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  min-width: 100px;
  flex-grow: ${({ canGrow }) => (canGrow ? 1 : 0)};
  height: 50px;
  display: flex;
  justify-content: ${isMobile ? 'flex-start' : 'center'};
  align-items: center;
  transition: 0.2s;
  color: ${(props) =>
    !props.disabled ? props.theme.primary100 : props.theme.gray300};
  border-bottom: 1px solid ${(props) => props.theme.gray300};
  transition: all 200ms ease;
  ${({ styles }) => styles}
`;

const InputWrapper = styled(motion.div)`
  overflow: hidden;
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-grow: ${isMobile ? '1' : '0'};
`;

const TextInput = styled.input`
  font-size: 1rem;
  width: 100%;
  font-family: ${({ theme }) => theme.mainFontFamily}, 'ProximaNova',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  border: none;
  letter-spacing: ${isMobile ? 'normal' : '1.5px'};
  outline: none;
  transition: 0.5s;
  text-align: ${isMobile ? 'left' : 'center'};
  color: ${(props) =>
    !props.disabled ? props.theme.primary100 : props.theme.gray300};
  flex-grow: ${(props) => (!props.disabled && props.isfocussed ? 1 : 0)};

  &::placeholder {
    color: ${(props) =>
      !props.disabled ? props.theme.primary100 : props.theme.gray300};
    text-transform: ${isMobile ? 'none' : 'uppercase'};
  }

  ${(props) => props.styles};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${isMobile ? '18px' : '14px'};
  color: ${(props) =>
    !props.disabled ? props.theme.primary100 : props.theme.gray300};
`;

const SearchIcon = styled(Icon)`
  margin-right: ${isMobile ? '10px' : '20px'};
  ${({ styles }) => styles}
`;

const ClearIcon = styled(Icon)`
  ${({ styles }) => styles}
`;

const ClearIconWrapper = styled(motion.div)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  cursor: ${({ disabled, isfocussed }) =>
    !disabled && isfocussed ? 'pointer' : 'default'};
  margin-left: ${isMobile ? '10px' : '0'};
  &:hover {
    cursor: pointer;
  }
`;

const FoundCount = styled(motion.span)`
  font-size: 0.75rem;
  margin: 0 6px;
  color: ${({ theme }) => theme.primary100};
  white-space: nowrap;
`;

const FilterToggle = styled(motion.button)`
  position: relative;
  height: 20px;
  width: 30px;
  min-width: 30px;
  margin-left: 8px;
  padding: 0;
  border: none;
  border-left: 1px solid ${({ theme }) => theme.gray200};
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: inherit;
  font-size: 1rem;
  color: ${({ theme }) => theme.primary100};
  transition: all 200ms ease;
`;

const FilterIcon = styled(FontAwesomeIcon)`
  pointer-events: none;
  font-size: 1rem;
  position: static;
  left: 0;
  top: 0;
  transform: translateY(0);
`;

const ApppliedFiltersBadge = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary100};
  color: ${({ theme }) => theme.showcaseWhite};
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const debounceChange = debounce((onChange, value) => {
  onChange(value);
}, 450);

const SearchField = forwardRef(
  (
    {
      onChange,
      placeholder,
      disabled,
      className,
      initValue,
      inputFieldStyles,
      searchIconStyles,
      wrapperStyles,
      clearIconStyles,
      found,
      showResultsInside,
      onOpenFilter,
      filtersCount,
      canGrow,
      enableSearchOnEnterPress,
      onClear,
      onFocus
    },
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isFocussed, setIsFocussed] = useState(false);
    const [value, setValue] = useState(initValue ?? '');

    const inputRef = useRef();
    const filterToggleRef = useRef();

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    }));

    useEffect(() => {
      if (enableSearchOnEnterPress) return;
      debounceChange(onChange, value);
    }, [value, onChange, enableSearchOnEnterPress]);

    const onValueChange = (event) => {
      if (value !== event.target.value) {
        setValue(event.target.value);
      }
    };

    const onClearInput = () => {
      setValue('');
      if (enableSearchOnEnterPress) {
        onClear();
      }
    };

    return (
      <Wrapper
        className={className}
        isHovered={isHovered}
        isfocussed={isFocussed ? 1 : 0}
        disabled={disabled}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={(e) => {
          setIsFocussed(true);
          if (filterToggleRef?.current?.contains(e.target)) {
            return;
          }

          onFocus();
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        canGrow={canGrow}
        styles={wrapperStyles}
      >
        <SearchIcon
          disabled={disabled}
          isfocussed={isFocussed ? 1 : 0}
          icon={['fal', 'search']}
          styles={searchIconStyles}
        />
        <InputWrapper
          id="search-field-input-wrapper"
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 'auto' }}
        >
          <TextInput
            data-testid="searchField"
            name={'searchField'}
            type="text"
            autoComplete="off"
            value={value}
            ref={inputRef}
            placeholder={placeholder}
            onChange={onValueChange}
            disabled={disabled}
            isfocussed={isFocussed ? 1 : 0}
            onBlur={() => {
              setTimeout(() => {
                setIsFocussed(false);
              }, 100);
            }}
            onKeyPress={(e) => {
              if (enableSearchOnEnterPress && e.key === 'Enter') {
                onChange(value);
              }
            }}
            styles={inputFieldStyles}
          />
          {showResultsInside && value && found > 0 && (
            <FoundCount>{found} found</FoundCount>
          )}
          <ClearIconWrapper
            data-testid="clearButton"
            id="clearButton"
            initial={{ opacity: 0 }}
            animate={
              value ? { opacity: 1, width: 'auto' } : { opacity: 0, width: 0 }
            }
            disabled={disabled}
            isfocussed={isFocussed ? 1 : 0}
            onClick={(e) => {
              if (value) {
                e.preventDefault();
                e.stopPropagation();
                onClearInput();
                setTimeout(() => {
                  setIsFocussed(false);
                }, 100);
              }
            }}
          >
            <ClearIcon
              disabled={disabled}
              icon={['fal', 'times-circle']}
              styles={clearIconStyles}
            />
          </ClearIconWrapper>

          {onOpenFilter && isMobile && (
            <FilterToggle onClick={onOpenFilter} ref={filterToggleRef}>
              <FilterIcon icon={['fal', 'filter']} size="1x" />
            </FilterToggle>
          )}
        </InputWrapper>
        {filtersCount > 0 && (
          <ApppliedFiltersBadge>{filtersCount}</ApppliedFiltersBadge>
        )}
      </Wrapper>
    );
  }
);

SearchField.propTypes = {
  onChange: func,
  placeholder: string,
  disabled: bool,
  found: number,
  showResultsInside: bool,
  onOpenFilter: func,
  filtersCount: number,
  canGrow: bool,
  enableSearchOnEnterPress: bool,
  onClear: func,
  onFocus: func
};

SearchField.defaultProps = {
  onChange: () => {},
  placeholder: localizer.search,
  disabled: false,
  found: 0,
  showResultsInside: false,
  filtersCount: 0,
  canGrow: false,
  enableSearchOnEnterPress: false,
  onClear: () => {},
  onFocus: () => {}
};

export default SearchField;
