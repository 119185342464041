import { useState, useEffect } from 'react';

// Helpers
import { useProjectState } from 'stores/ProjectStore';

const useCustomShowcaseTheme = (defaultTheme) => {
  const [customTheme, setCustomTheme] = useState(defaultTheme);

  // ProjectState
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  useEffect(() => {
    // check if the custom colors feature enabled for the project
    if (showcaseConfiguration?.useCustomColors) {
      // if custom colors are not set fallback to default colors
      const primaryColor =
        showcaseConfiguration?.showcasePrimaryColor ||
        defaultTheme.defaultBrandPrimaryColor;
      const secondaryColor =
        showcaseConfiguration?.showcaseSecondaryColor ||
        defaultTheme.defaultBrandSecondaryColor;
      const floatingContactUsButtonColor =
        showcaseConfiguration?.showcasePrimaryColor ||
        defaultTheme.defaultFloatingContactUsButtonColor;
      setCustomTheme((prev) => ({
        ...prev,
        defaultBrandPrimaryColor: primaryColor,
        defaultBrandSecondaryColor: secondaryColor,
        defaultFloatingContactUsButtonColor: floatingContactUsButtonColor
      }));
      document.documentElement.style.setProperty(
        '--defaultBrandPrimaryColor',
        primaryColor
      );
    }
    const unitDefaultColor =
      showcaseConfiguration?.unitColors?.unitDefaultColor ||
      defaultTheme.unitDefault;
    setCustomTheme((prev) => ({
      ...prev,
      unitDefault: unitDefaultColor,
    }));
    if (showcaseConfiguration?.showUnitState) {
      // if the show unit availability option is true, update colors for the unit states
      const unitAvailableColor =
        showcaseConfiguration?.unitColors?.unitAvailableColor ||
        defaultTheme.unitAvailable;
      const unitSoldColor =
        showcaseConfiguration?.unitColors?.unitSoldColor ||
        defaultTheme.unitSold;
      const unitOptionColor =
        showcaseConfiguration?.unitColors?.unitOptionColor ||
        defaultTheme.unitInOption;
      setCustomTheme((prev) => ({
        ...prev,
        unitAvailable: unitAvailableColor,
        unitSold: unitSoldColor,
        unitInOption: unitOptionColor,
      }));
    }
  }, [defaultTheme, showcaseConfiguration]);

  return { customTheme };
};

export default useCustomShowcaseTheme;
