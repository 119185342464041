import React, { useRef } from 'react';

// Styling
import styled from 'styled-components';

// Components
import Preview from '../contentItems/Preview';

const gridGap = 30;

const GridWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  grid-gap: ${gridGap}px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${gridGap}px;
  width: calc(50% - ${gridGap / 2}px);
`;

const FlexItemWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
`;

const VerticalGrid = ({
  indexedPageList,
  videoFallbackThumbnail,
  onPageClick
}) => {
  const verticalGridRef = useRef();

  // split pages in two columns
  const leftColumn = [];
  const rightColumn = [];
  indexedPageList.forEach((page, idx) => {
    idx % 2 ? rightColumn.push(page) : leftColumn.push(page);
  });

  const gridWrapperWidth = verticalGridRef?.current?.offsetWidth;
  const gridTwoColumnsWidth = (gridWrapperWidth - gridGap) / 2;

  return (
    <GridWrapper ref={verticalGridRef}>
      {[leftColumn, rightColumn].map((col) => (
        <Column>
          {col
            .map((page) => {
              const content = (
                <Preview
                  key={page.pageIndex}
                  data={page}
                  pageIndex={page.pageIndex}
                  size={gridTwoColumnsWidth}
                  videoFallbackThumbnail={videoFallbackThumbnail}
                  isVerticalLayout={true}
                />
              );

              return (
                <FlexItemWrapper
                  key={page.pageIndex}
                  onClick={(e) => onPageClick(e, page.pageIndex)}
                  ref={page.ref}
                >
                  {content}
                </FlexItemWrapper>
              );
            })
            .filter((x) => x)}
        </Column>
      ))}
    </GridWrapper>
  );
};
export default VerticalGrid;
