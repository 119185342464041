import { useState, useEffect } from 'react';
import { useHubspotContext } from './useHubspotContext';

const useHubspotForm = (props) => {
  const { loaded, error } = useHubspotContext();
  const [formCreated, setFormCreated] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      if (loaded && window.hbspt && !formCreated) {
        window.hbspt.forms.create(props);
        setFormCreated(true);
      }
    }
  }, [loaded, formCreated, setFormCreated, props]);

  return { loaded, formCreated, error };
};

export default useHubspotForm;
