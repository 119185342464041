import React, { useState, useRef, useEffect, useCallback } from 'react';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Wrapper = styled.div`
  height: 40px;
  width: 240px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.gray200};
  border-radius: 2px;
  position: relative;
  margin-right: 20px;
  margin-left: auto;
`;

const ActiveSortOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SortBy = styled.span`
  margin: 0 5px 0 0;
  color: ${(props) => props.theme.primary100};
`;

const CurrentFieldToSort = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.showcaseBlack};
  margin-right: 5px;
  flex-grow: 1;
`;

const DropDownIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: -3px;
  cursor: pointer;
  color: ${(props) => props.theme.primary100};
  fon-size: 14px;
  transition: all 150ms ease;
  transform: scaleY(${({ show }) => (show ? '-1' : '1')})
    translateY(${({ show }) => (show ? '9px' : '0')});
  transform-origin: center bottom;
`;

const CheckedItemIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${(props) => props.theme.primary300};
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(9px, 60%);
`;

const DropDown = styled(motion.div)`
  position: absolute;
  z-index: 15;
  background-color: ${(props) => props.theme.showcaseWhite};
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.gray200};
  border-radius: 0 0 2px 2px;
`;

const ItemsList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

const SubList = styled(ItemsList)``;

const SortingItem = styled.li`
  position: relative;
  font-size: 1rem;
  color: ${(props) =>
    props.selected ? props.theme.showcaseBlack : props.theme.primary100};
  width: 100%;
  height: ${(props) => props.height};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px 5px 5px 30px;
  box-sizing: border-box;
  border-top: ${({ chosen, theme }) =>
    chosen ? `1px solid ${theme.gray200}` : 'none'};
  border-bottom: ${({ chosen, theme }) =>
    chosen ? `1px solid ${theme.gray200}` : 'none'};
  cursor: pointer;
  &:hover {
    background-color: rgba(127, 127, 127, 0.05);
  }
`;

const SubItem = styled(SortingItem)`
  &:hover {
    background: none;
  }
`;

const getSortingOptionLabel = (field) => {
  let label;
  switch (field) {
    case 'title':
      label = 'unitName';
      break;
    case 'state':
      label = 'unitStatus';
      break;
    default:
      label = field;
  }
  return localizer.unitFields[label];
};

const SortUnits = ({
  fields,
  currentFieldToSort,
  onSortByChanged,
  isSortAscending
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [chosenFieldToSort, setChosenFieldToSort] =
    useState(currentFieldToSort);

  const sortingOptionsRef = useRef();

  const checkOutsideClick = useCallback(
    (event) => {
      if (!sortingOptionsRef.current?.contains(event.target)) {
        setShowOptions(false);
      }
    },
    [sortingOptionsRef]
  );

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);
    return () => window.removeEventListener('click', checkOutsideClick);
  }, [checkOutsideClick]);

  return (
    <Wrapper ref={sortingOptionsRef}>
      <ActiveSortOptionWrapper onClick={() => setShowOptions((prev) => !prev)}>
        <SortBy>{capitalize(localizer.sortBy)}:</SortBy>
        <CurrentFieldToSort>
          {getSortingOptionLabel(currentFieldToSort)}
        </CurrentFieldToSort>
        <DropDownIcon show={showOptions ? 1 : 0} icon={['fas', 'sort-down']} />
      </ActiveSortOptionWrapper>
      <AnimatePresence>
        {showOptions && (
          <DropDown
            initial={{ scaleY: 0, transformOrigin: 'top', opacity: 0.6 }}
            animate={{ scaleY: 1, opacity: 1 }}
            exit={{ scaleY: 0, opacity: 0.4 }}
          >
            <ItemsList>
              {fields.map((field) => (
                <SortingItem
                  key={field}
                  selected={field === chosenFieldToSort}
                  onClick={(e) => {
                    e.stopPropagation();
                    setChosenFieldToSort(field);
                  }}
                  height={field === chosenFieldToSort ? 'auto' : '30px'}
                  chosen={field === chosenFieldToSort}
                >
                  {field === chosenFieldToSort && (
                    <CheckedItemIcon icon={['far', 'check']} size="1x" />
                  )}
                  {getSortingOptionLabel(field)}
                  {field === chosenFieldToSort && (
                    <SubList>
                      {[
                        { label: capitalize(localizer.ascending), isAsc: true },
                        {
                          label: capitalize(localizer.descending),
                          isAsc: false
                        }
                      ].map((opt) => (
                        <SubItem
                          key={opt.label}
                          selected={
                            field === currentFieldToSort &&
                            isSortAscending === opt.isAsc
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            onSortByChanged(field, opt.isAsc);
                            setShowOptions(false);
                          }}
                        >
                          {field === currentFieldToSort &&
                            isSortAscending === opt.isAsc && (
                              <CheckedItemIcon
                                icon={['far', 'check']}
                                size="1x"
                              />
                            )}
                          {opt.label}
                        </SubItem>
                      ))}
                    </SubList>
                  )}
                </SortingItem>
              ))}
            </ItemsList>
          </DropDown>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default SortUnits;
