import React from 'react';

// Helpers
import { isDarkColor } from 'helpers';

// Styling
import styled from 'styled-components';

const ContactSales = styled.div`
  position: relative;
  height: 40px;
  width: auto;
  background-color: ${({ theme }) => theme.grayWhiteOff};
  border-radius: 20px;
  padding: 5px 40px 5px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
  cursor: pointer;
  span {
    margin: 0 5px 0 0;
    line-height: 1;
  }
  #contactManager {
    font-weight: 600;
    color: ${({ theme }) =>
      isDarkColor(theme.contactCardBg) ? theme.contactCardBg : theme.brand500};
  }
`;

const SalesPersonAvatar = styled.img`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
`;

const SalesPersonToggle = ({ salesPerson, onClick, localizer }) => (
  <ContactSales onClick={onClick}>
    <span>{localizer.cantFindWhatYouWant}</span>
    <span id="contactManager">{localizer.contactYourManager}</span>
    <SalesPersonAvatar
      src={
        salesPerson?.profilePictureUri || salesPerson?.profilePicturePlaceholder
      }
      alt=""
    />
  </ContactSales>
);

export default SalesPersonToggle;
