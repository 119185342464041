import React, { useReducer, createContext, useContext } from 'react';
export const ProjectStoreContext = createContext();

/**
 * Store containing the currently opened project.
 * All children element of this component will get access to:
 *  ProjectState: the project last loaded from the back-end (See https://devapiv2.vr-tual.media/asciidoc/index.html#_vmprojectsection for the fields accessible)
 *  ProjectStateDispatch: a function to update the project locally (it will not update it in the back-end)
 * @param {Object} props Only used to get all the children element of this component
 */
const ProjectStore = (props) => {
  const [ProjectState, ProjectStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'reset':
          return {
            project: null,
            contentCollection: null,
            navigationItem: null,
            shareCode: null,
            showcaseLoaded: false,
            unitFilters: [],
            vault: null,
            appliedFilters: [],
            filterSearchValue: '',
            appliedFilterSearchValue: '',
            nightMode: false,
            introVideo: null,
            favoritesList: [],
            providedFavoritesList: [],
            userFavoritesList: [],
            turntableMasks: {},
            folderConfiguration: null,
            foldersRefs: null,
            activeShareItem: null
          };
        case 'setProjectData':
          return {
            ...state,
            ...action.payload
          };
        case 'setTurntable':
          return {
            ...state,
            turntable: action.payload
          };
        case 'setAllTurntable':
          return {
            ...state,
            turntableList: action.payload,
            turntableListFetched: true
          };
        case 'setTurntableMasks':
          return {
            ...state,
            turntableMasks: {
              ...state.turntableNavigationTargets,
              ...action.payload
            }
          };
        case 'setAllUsps':
          return {
            ...state,
            uspList: action.payload,
            uspListFetched: true
          };
        case 'setNightMode':
          return {
            ...state,
            nightMode: action.payload
          };
        case 'toggleFavorites':
          return {
            ...state,
            userFavoritesList: state.userFavoritesList.includes(action.payload)
              ? state.userFavoritesList.filter(
                  (unitId) => unitId !== action.payload
                )
              : [...state.userFavoritesList, action.payload]
          };
        case 'setProvidedFavorites':
          return {
            ...state,
            providedFavoritesList: [...action.payload]
          };
        case 'setInitialUserFavorites':
          return {
            ...state,
            userFavoritesList: [...action.payload]
          };
        case 'setTotalFavorites':
          return {
            ...state,
            favoritesList: [...action.payload]
          };
        case 'setInitialFavorites':
          return {
            ...state,
            favoritesList: [...state.favoritesList, ...action.payload]
          };
        case 'setIsUserIdle':
          return {
            ...state,
            isUserIdle: action.payload
          };
        case 'setProjectFolderConfiguration':
          return {
            ...state,
            folderConfiguration: action.payload
          };
        case 'setActiveShareItem':
          return {
            ...state,
            activeShareItem: action.payload
          };
        default:
          return state;
      }
    },
    {
      isUserIdle: false,
      project: null,
      firstImage: null,
      turntable: null,
      contentCollection: null,
      navigationItem: null,
      showcaseConfiguration: null,
      shareCode: null,
      showcaseLoaded: false,
      turntableList: [],
      turntableHtmlElements: [],
      uspList: [],
      unitFilters: [],
      appliedFilters: [],
      filterSearchValue: '',
      appliedFilterSearchValue: '',
      vault: null,
      nightMode: false,
      introVideo: null,
      favoritesList: [],
      // providedFavoritesList - taken from "fav" query param
      providedFavoritesList: [],
      // userFavoritesList - units chosen by user and saved in cookie
      userFavoritesList: [],
      cachedPDFs: {},
      cachedCanvases: {},
      turntableMasks: {},
      folderConfiguration: null,
      foldersRefs: null,
      activeShareItem: null
    }
  );

  return (
    <ProjectStoreContext.Provider
      value={{ ProjectState, ProjectStateDispatch }}
    >
      {props.children}
    </ProjectStoreContext.Provider>
  );
};

export const useProjectState = () => useContext(ProjectStoreContext);

export default ProjectStore;
