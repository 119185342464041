import { useEffect, useState } from 'react';
import { string, func } from 'prop-types';
import localizer from 'localization/localizer';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GalleryLoader from './GalleryLoader';

//Styling
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from 'styled-components';

const MainWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: grey;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const IFrameWrapper = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
`;

const BackButton = styled.button`
  z-index: 101;
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  padding: 15px 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: ${(props) => props.backButtonBottom};
  left: ${(props) => props.backButtonLeft};
  outline: none;
`;

const BackButtonText = styled.div`
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const BackButtonIcon = styled(FontAwesomeIcon)`
  color: white;
  padding-right: 10px;
`;

const FullscreenIframePreview = ({
  experienceType,
  src,
  onExit,
  loaderGalleryUrl
}) => {
  const [tempLoader, setTempLoader] = useState(null);
  const [progressbarValue, setProgressbarValue] = useState(0);

  useEffect(() => {
    let interval;
    if (loaderGalleryUrl) {
      interval = setInterval(() => {
        setProgressbarValue((curr) => {
          return curr + 100;
        });
      }, 100);
      setTempLoader(
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="tempLoader"
          css={css`
            position: fixed;
            height: 100%;
            width: 100%;
            z-index: 20;
            background: black;
          `}
        ></motion.div>
      );
      setTimeout(() => {
        setTempLoader(null);
      }, 3000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loaderGalleryUrl]);

  let backButtonLeft, backButtonBottom;
  if (experienceType === 'external') {
    // match ViewIt panel placement
    backButtonLeft = '2%';
    backButtonBottom = 'calc(2% + 75px)';
  } else {
    backButtonLeft = '30px';
    backButtonBottom = '30px'; // needs to be above prompto loading bar
  }

  return (
    <MainWrapper key="iframePreviewKey">
      <Wrapper>
        <AnimatePresence>
          {tempLoader}
          {loaderGalleryUrl && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 1 }}
              key="galleryLoader"
            >
              <GalleryLoader
                loaderGalleryUrl={loaderGalleryUrl}
                progressbarValue={progressbarValue}
                maxValue={40000}
              />
            </motion.div>
          )}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1 }}
            key="theIframe"
          >
            <IFrameWrapper title="iframe for preview" src={src}></IFrameWrapper>
          </motion.div>
          <BackButton
            key="button"
            backButtonLeft={backButtonLeft}
            backButtonBottom={backButtonBottom}
            onClick={() => {
              onExit();
            }}
          >
            <BackButtonIcon icon={['fal', 'arrow-left']} />
            <BackButtonText>{localizer.back}</BackButtonText>
          </BackButton>
        </AnimatePresence>
      </Wrapper>
    </MainWrapper>
  );
};

FullscreenIframePreview.propTypes = {
  experienceType: string.isRequired,
  src: string.isRequired,
  onExit: func.isRequired,
  loaderGalleryUrl: string
};

FullscreenIframePreview.defaultProps = {
  loaderGalleryUrl: null
};

export default FullscreenIframePreview;
