import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import { getColorForUnitState } from 'helpers/units/VmUnitHelper';

// Styling
import styled, { withTheme } from 'styled-components';
import { trackUnitAction } from 'components/pages/unitDetail/tracking/UnitTrackingHelper';

const Wrapper = styled.div`
  ${(props) => props.wrapperStyles};
`;

const Button = styled.button`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1px solid ${(props) => props.theme.gray200};
  border-radius: 2px;
  cursor: pointer;
  background-color: ${({ theme, isFavorite }) =>
    isFavorite ? '#f9f7fa' : theme.showcaseWhite};
  transition: background-color 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.noBackground &&
    `
    background-color: transparent;
    border: none;
  `}
`;

const HeartIcon = styled(FontAwesomeIcon)`
  font-size: ${(props) => props.iconsize}px;
  color: ${({ color, favorite, theme }) =>
    favorite ? color : theme.primary100};
`;

const FavoriteUnitButton = (props) => {
  const {
    buttonSize = 40,
    iconSize = 18,
    unit,
    theme,
    wrapperStyles,
    noBackground = false
  } = props;

  const { AuthState } = useAuthState();

  // Project state
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const {
    favoritesList,
    providedFavoritesList,
    userFavoritesList,
    showcaseConfiguration
  } = ProjectState;

  const showUnitState = showcaseConfiguration?.showUnitState ?? true;

  const toggleUnitIsFavorite = () => {
    if (
      providedFavoritesList.includes(unit.objectId) &&
      !userFavoritesList.includes(unit.objectId)
    ) {
      ProjectStateDispatch({
        type: 'setProvidedFavorites',
        payload: [
          ...providedFavoritesList.filter((unitId) => unitId !== unit.objectId)
        ]
      });
    } else if (
      providedFavoritesList.includes(unit.objectId) &&
      userFavoritesList.includes(unit.objectId)
    ) {
      ProjectStateDispatch({
        type: 'setProvidedFavorites',
        payload: [
          ...providedFavoritesList.filter((unitId) => unitId !== unit.objectId)
        ]
      });
      ProjectStateDispatch({
        type: 'toggleFavorites',
        payload: unit.objectId
      });
    } else {
      ProjectStateDispatch({
        type: 'toggleFavorites',
        payload: unit.objectId
      });
    }
  };

  const calculateIconBg = (unitState) => {
    return showUnitState
      ? getColorForUnitState(theme, unitState)
      : theme.successColor;
  };

  const isFavorite = favoritesList.includes(unit?.objectId);

  return (
    <Wrapper wrapperStyles={wrapperStyles}>
      <Button
        size={buttonSize}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isFavorite) {
            trackUnitAction(
              'unitFavoured',
              unit.objectId,
              ProjectState,
              AuthState
            );
          }
          toggleUnitIsFavorite();
        }}
        isFavorite={isFavorite}
        noBackground={noBackground}
      >
        <HeartIcon
          icon={[isFavorite ? 'fas' : 'fal', 'heart']}
          iconsize={iconSize}
          size="1x"
          color={calculateIconBg(unit?.state)}
          favorite={isFavorite ? 1 : 0}
        />
      </Button>
    </Wrapper>
  );
};

export default withTheme(FavoriteUnitButton);
