import React, { useState } from 'react';
import { func } from 'prop-types';

// Styling
import styled, { css } from 'styled-components';
import localizer from 'localization/localizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { isMobile, useMobileOrientation } from 'react-device-detect';

const MainBar = styled.div`
  position: fixed;
  top: 15px;
  left: calc(50% - 75px);
  width: 125px;
  height: 30px;
  border: 1px solid ${(props) => props.theme.gray300};
  background-color: ${(props) =>
    props.istoggled ? props.theme.primary900 : props.theme.showcaseWhite};
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: top 200ms ease;
  ${({ landscape }) => {
    if (!isMobile) return '';
    return css`
      top: ${landscape ? 15 : 85}px;
    `;
  }}
`;

const iconWidth = 25;

const ToggleIcon = styled.div`
  position: absolute;
  width: ${iconWidth}px;
  height: ${iconWidth}px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.istoggled ? props.theme.showcaseWhite : props.theme.primary200};
  transition: all 0.5s ease;
  ${(props) =>
    props.istoggled ? 'left: 2%;' : `left: calc(98% - ${iconWidth}px);`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DayNightIcon = styled(FontAwesomeIcon)`
  color: ${(props) =>
    props.istoggled ? props.theme.primary900 : props.theme.showcaseWhite};
`;

const Text = styled.div`
  font-size: 1rem;
  ${(props) =>
    props.istoggled
      ? `margin-right: -${iconWidth / 2.0}px`
      : `margin-left: -${iconWidth / 2.0}px`};

  transition: all 0.5s ease;
  color: ${(props) =>
    props.istoggled ? props.theme.showcaseWhite : props.theme.primary200};
  user-select: none;
`;

const DayNightToggle = ({ onToggle, initialState }) => {
  const [isToggled, setIsToggled] = useState(initialState);

  const { isLandscape } = useMobileOrientation();

  return (
    <MainBar
      istoggled={isToggled ? 1 : 0}
      onClick={() => {
        setIsToggled((current) => {
          onToggle(!current);
          return !current;
        });
      }}
      landscape={isLandscape}
    >
      <Text istoggled={isToggled ? 1 : 0}>
        {isToggled
          ? localizer.night.toUpperCase()
          : localizer.day.toUpperCase()}
      </Text>
      <ToggleIcon istoggled={isToggled ? 1 : 0}>
        <DayNightIcon
          istoggled={isToggled ? 1 : 0}
          icon={isToggled ? ['fas', 'moon'] : ['fas', 'sun']}
          size="1x"
        />
      </ToggleIcon>
    </MainBar>
  );
};

DayNightToggle.propTypes = {
  onToggle: func.isRequired
};

export default DayNightToggle;
