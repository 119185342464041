import React, { useState, useEffect, useRef, createRef } from 'react';
import { useParams } from 'react-router-dom';

// Components
import UnitInfoCard from './UnitInfoCard';
import UnitInfoHeader from './UnitInfoHeader';
import UnitContentList from './UnitContentList';
import { IdealImage } from '@prompto-ui';
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import UnitDetailMobilePage from './UnitDetailMobilePage';

import VideoContent from './contentTypes/VideoContent';
import ZoomIndicator from '../tour/tourComponents/ZoomIndicator';
import ThreeSixtyImageContent from './contentTypes/ThreeSixtyImageContent';

import Div100vh from 'react-div-100vh';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { PdfViewer } from '@prompto-ui';

// Helpers
import { useAuthState } from 'stores/AuthStore';
import { useProjectState } from 'stores/ProjectStore';
import {
  useUiState,
  navigationFloatingButtonsBottomPosition
} from 'stores/UiStore';
import localizer from 'localization/localizer';
import { isMobileOnly, isMobile } from 'react-device-detect';
import screenfull from 'screenfull';

import {
  capitalize,
  getCurrentEnvironment,
  computeCloudinaryThumbnail
} from 'helpers';
import qs from 'query-string';
import {
  shouldShowUnitPrice,
  localizeUnitFieldValues,
  sortBySortingOrders
} from 'helpers/units/VmUnitHelper';

// Styling
import styled from 'styled-components';
import { trackUnitAction } from './tracking/UnitTrackingHelper';

const contentListHeight = 110;

const ViewWrapper = styled(Div100vh)`
  width: 100vw;
  box-sizing: border-box;
  padding: 50px 50px 30px 50px;
  padding-top: 110px;
  background: ${(props) => props.theme.whitePure};
`;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
`;

const Sidebar = styled.div`
  width: ${({ collapsed }) => (collapsed ? '160px' : '260px')};
  height: 100%;
  align-self: flex-start;
  margin-right: 20px;
  z-index: 10;
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  transition: width 200ms ease;
`;

const SelectedContent = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 3px;
  position: relative;
  height: calc(100% - ${contentListHeight}px);
`;

const ContentWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const NoContentWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  color: ${(props) => props.theme.whitePure};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  z-index: 5;
`;

const Album360Wrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
`;

const Image360Wrapper = styled(Album360Wrapper)`
  height: 100%;
  width: 100%;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: ${(props) => (props.offsetNavButtons ? 350 : 40)}px;
  top: 40px;
  z-index: 10;
`;

const Button = styled.button`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  cursor: ${(props) => (props.visible ? 'pointer' : 'default')};
  transition: 0.2s;
  align-items: center;
  backdrop-filter: blur(3px);
  border: none;
  background-color: ${(props) => props.theme.fadeLightGray};
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  width: 50px;
  height: 50px;
  justify-content: center;
  outline: none;
  z-index: 15;
  margin-right: 3px;

  &:hover {
    background-color: rgba(200, 200, 200, 0.4);
  }
`;

const ZoomIndicatorWrapper = styled(ZoomIndicator)`
  bottom: 15px;
  right: 15px;
  z-index: 1;
`;

const DocumentWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledLoader = styled(ShowcaseLoader)`
  color: ${(props) => props.theme.showcaseBlack};
  font-size: 24px;
`;

const ContentGalleryWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - ${contentListHeight + 30}px);
`;

const FillingIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const UnitContentListWrapper = styled.div`
  padding: 10px;
  padding-left: ${({ smallPadding }) => (smallPadding ? '0' : '90px')};
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${contentListHeight}px;
  transition: padding-left 300ms ease;
`;

const mediaTypes = [
  'image',
  'video',
  'document',
  'image360',
  'url',
  'floorplan',
  'tour360'
];

const sanitizeContentList = (list) => {
  if (!list) return [];
  const findFloorplan = (item) => item.contentItemType === 'floorplan';
  const floorplanItem = list.find(findFloorplan);
  if (floorplanItem) {
    const listWithoutDuplications = list.filter(
      (item) => item.title.textMap?.en !== floorplanItem.title.textMap?.en
    );
    // move floorplan to the first position
    listWithoutDuplications.unshift(floorplanItem);
    return listWithoutDuplications;
  } else {
    return list;
  }
};

const UnitDetailPage = () => {
  const { unitId } = useParams();

  const { AuthState } = useAuthState();

  const { ProjectState } = useProjectState();
  const { project, vault, contentCollection, showcaseConfiguration } =
    ProjectState;
  const defaultContentItemSorting = project?.defaultContentItemSorting;

  const { UiStateDispatch } = useUiState();

  const [loadedUnit, setLoadedUnit] = useState();
  const [unitHasContent, setUnitHasContent] = useState(false);
  const [unitHasMediaContent, setUnitHasMediaContent] = useState(false);

  const [contentLoaded, setContentLoaded] = useState(false);

  const [selectedContent, setSelectedContent] = useState();

  const [fallbackContent, setFallbackContent] = useState();
  const [minZoom, setMinZoom] = useState(0.5);

  // Boolean to offset the navigation buttons, this is if we want to display content where the buttons normally are placed
  const [offsetNavButtons, setOffsetNavButtons] = useState(false);
  const [contentItemList, setContentItemList] = useState([]);

  const [isUnitInfoCollapsed, setUnitInfoCollapsed] = useState(false);
  const [sidebarOverlapsContentList, setSidebarOverlapsContentList] =
    useState(false);
  const [shouldRecalculateSidebarHeight, setShouldRecalculateSidebarHeight] =
    useState(false);

  const [unitFloorPlanFile, setUnitFloorPlanFile] = useState(null);

  const [contentContainerSize, setContentContainerSize] = useState({
    width: 0,
    height: 0
  });

  const [imageDimensions, setImageDimensions] = useState();

  const [selectedImage360, setSelectedImage360] = useState(false);
  const [imageIsCentered, setImageIsCentered] = useState(false);

  // Tracking
  const [unitVisitTracked, setUnitVisitTracked] = useState(false);

  const contentWrapperRef = useRef();
  const sidebarRef = useRef();
  const contentListWrapperRef = useRef();

  // Content Items Refs
  const contentItemsRefs = contentItemList.reduce((refs, value) => {
    refs[value.objectId] = createRef();
    return refs;
  }, {});

  useEffect(() => {
    if (
      contentItemList.length &&
      sidebarRef?.current &&
      contentListWrapperRef?.current
    ) {
      const sidebarCoords = sidebarRef?.current.getBoundingClientRect();
      const contentListCoords =
        contentListWrapperRef?.current.getBoundingClientRect();
      setSidebarOverlapsContentList(
        sidebarCoords.bottom > contentListCoords.top
      );
    }
  }, [contentItemList, shouldRecalculateSidebarHeight]);

  // Track unit page visit
  useEffect(() => {
    if (unitId && ProjectState && AuthState && !unitVisitTracked) {
      setUnitVisitTracked(
        trackUnitAction('unitVisited', unitId, ProjectState, AuthState)
      );
    }
  }, [unitId, ProjectState, AuthState, unitVisitTracked]);

  useEffect(() => {
    const position = isMobile
      ? navigationFloatingButtonsBottomPosition.default
      : navigationFloatingButtonsBottomPosition.unitDetailsPage;
    UiStateDispatch({
      type: 'update',
      payload: {
        showMenu: isMobileOnly,
        navigationFloatingButtonsBottomPosition: position
      }
    });

    return () => {
      UiStateDispatch({
        type: 'update',
        payload: {
          navigationFloatingButtonsBottomPosition:
            navigationFloatingButtonsBottomPosition.default
        }
      });
    };
  }, [UiStateDispatch]);

  useEffect(() => {
    if (unitId && project?.unitList) {
      const unit = project?.unitList.find((x) => x.objectId === unitId);

      if (unit) {
        setLoadedUnit(localizeUnitFieldValues(project, unit));

        // Check if a unit has attached floor plan
        // Only one floor plan can be attached to a unit
        const unitFloorPlanFile =
          unit?.vmContentCollection?.vmContentItemList?.find(
            (x) => x.contentItemType === 'floorplan'
          );
        setUnitFloorPlanFile(unitFloorPlanFile);

        // Get the fallback thumbnail
        const unitImageList =
          unit?.vmContentCollection?.vmContentItemList?.filter(
            (x) => x.contentItemType === 'image'
          );

        const projectContentList = contentCollection?.vmContentItemList?.filter(
          (x) => x.contentItemType === 'image'
        );

        if (unitImageList && unitImageList.length > 0) {
          setFallbackContent(unitImageList[0]);
        } else if (projectContentList && projectContentList.length > 0) {
          setFallbackContent(projectContentList[0]);
        }

        // Get the first image from the unit content
        // sanitizeContentList function removes posible duplications
        const unitContentList = sanitizeContentList(
          unit?.vmContentCollection?.vmContentItemList
        );

        let linkedContentList =
          unit?.linkedContentCollection?.vmContentItemList || [];
        linkedContentList = linkedContentList.map((item) => ({
          ...item,
          isLinked: true
        }));

        let allUnitContent = [...unitContentList, ...linkedContentList];
        if (unit?.album360Url) {
          allUnitContent.push({
            objectId: unit?.album360Url,
            contentItemType: 'tour360'
          });
        }

        // Compute all the video thumbnails
        allUnitContent.forEach((content) => {
          if (content.contentItemType === 'video') {
            const cloudinaryImageUri = computeCloudinaryThumbnail(
              decodeURIComponent(content?.contentUri),
              getCurrentEnvironment().googleStorageBucketId,
              getCurrentEnvironment().cloudinaryVideoBaseUrl
            );
            content.thumbnailUri = cloudinaryImageUri;
          }
        });

        // sort items by custom order if it is defined
        // else sort by default order
        allUnitContent = sortBySortingOrders(
          allUnitContent,
          unit?.contentItemCustomSortingValues,
          defaultContentItemSorting
        );

        // Decide the first selected content item
        if (allUnitContent && allUnitContent.length > 0) {
          setUnitHasContent(true);
          trySelectContent(allUnitContent[0]);
          setUnitHasMediaContent(true);
        }
        // No unit image content... Get the first image from the project content
        else {
          // even though unit has no image content it still may have 'url' content items
          setUnitHasContent(allUnitContent?.length > 0);
          setUnitHasMediaContent(false);
          if (projectContentList && projectContentList.length > 0) {
            trySelectContent(projectContentList[0]);
          }
        }

        setContentItemList(allUnitContent);
      }
    }
  }, [unitId, project, contentCollection, defaultContentItemSorting]);

  useEffect(() => {
    setOffsetNavButtons(false);
  }, [selectedContent]);

  // calculate content container size
  useEffect(() => {
    if (selectedContent) {
      const container = document.getElementById('unit-media-container');
      if (container) {
        setContentContainerSize({
          width: container.getBoundingClientRect().width,
          height: container.getBoundingClientRect().height
        });
      }
    }
  }, [selectedContent]);

  useEffect(() => {
    if (selectedContent) {
      if (selectedContent.contentItemType === 'image360') {
        setSelectedImage360({
          ...selectedContent,
          contentUri: `${getCurrentEnvironment().baseImageUrl}/o=true/${
            selectedContent?.contentUri
          }`
        });
      } else {
        setSelectedImage360(false);
      }
    }
  }, [selectedContent]);

  const trySelectContent = (newContent) => {
    setSelectedContent(newContent);
  };

  const onNext = () => {
    const unitContentList = contentItemList.filter((x) =>
      mediaTypes.includes(x.contentItemType)
    );

    const currentIndex = unitContentList.findIndex(
      (x) => x.objectId === selectedContent.objectId
    );

    // Logic to include 360 tour and external experience
    if (currentIndex < 0) {
      trySelectContent(unitContentList[0]);
    } else if (currentIndex < unitContentList.length - 1) {
      // Default content list logic
      const nextElement = unitContentList[currentIndex + 1];
      trySelectContent(nextElement);
    }
  };

  const onPrevious = () => {
    const unitContentList = contentItemList.filter((x) =>
      mediaTypes.includes(x.contentItemType)
    );

    const currentIndex = unitContentList.findIndex(
      (x) => x.objectId === selectedContent.objectId
    );

    if (currentIndex === 0) {
    } else if (currentIndex > 0) {
      // Normal logic to go through normal media content
      const previousElement = unitContentList[currentIndex - 1];
      trySelectContent(previousElement);
    }
  };

  let fallbackImageUri;
  if (fallbackContent?.contentUri) {
    let transform = 'q=80:h=1800';
    fallbackImageUri = `${getCurrentEnvironment().baseImageUrl}/${transform}/${
      fallbackContent?.contentUri
    }`;
  }

  // CONTENT
  let content;
  if (unitHasMediaContent) {
    switch (selectedContent?.contentItemType) {
      case 'url':
        content = (
          <FillingIframe
            title={'Prompto'}
            src={selectedContent.contentUri}
            allowFullScreen
            onLoad={() => {
              setContentLoaded(true);
            }}
          />
        );
        break;
      case 'tour360':
        content = (
          <FillingIframe
            title={'Prompto'}
            src={loadedUnit?.album360Url}
            allowFullScreen
            onLoad={() => {
              setContentLoaded(true);
            }}
          />
        );
        break;
      case 'video':
        content = (
          <VideoContent
            key={selectedContent.objectId}
            content={selectedContent}
            fallbackThumbnail={fallbackImageUri}
            onLoad={() => {
              setContentLoaded(true);
            }}
          />
        );
        break;
      case 'document':
      case 'floorplan':
        content = (
          <DocumentWrapper
            ref={contentItemsRefs[selectedContent.objectId]}
            key={selectedContent.objectId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <PdfViewer
              key={selectedContent.objectId}
              url={selectedContent.contentUri}
              objectId={selectedContent.objectId}
              onLoad={() => {
                setContentLoaded(true);
              }}
              onToggleSidebar={setOffsetNavButtons}
              fileTitle={selectedContent.title?.textMap?.en ?? ''}
              onToggleFullscreen={() => {
                if (screenfull.isEnabled) {
                  screenfull.toggle(
                    contentItemsRefs[selectedContent.objectId]?.current
                  );
                }
              }}
            />
          </DocumentWrapper>
        );
        break;
      case 'loader':
        content = <StyledLoader />;
        break;
      default:
      case 'image':
        content = (
          <TransformWrapper
            key={selectedContent.objectId}
            options={{ minScale: minZoom, maxScale: 3 }}
            pan={{ paddingSize: 0 }}
            zoomIn={{ step: 5 }}
            zoomOut={{ step: 5 }}
            reset={{ animation: false, animationTime: 0 }}
          >
            {({
              zoomIn,
              zoomOut,
              scale,
              resetTransform,
              positionX,
              positionY,
              setTransform
            }) => {
              const centerPosition = {
                centerX:
                  (imageDimensions?.width - contentContainerSize.width) / -2,
                centerY:
                  (imageDimensions?.height - contentContainerSize.height) / -2
              };

              const scaleUnitInfoCollapsed =
                (contentContainerSize.width + 100) / contentContainerSize.width;

              return (
                <>
                  <ZoomIndicatorWrapper
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    scale={scale}
                    resetTransform={resetTransform}
                    setTransform={setTransform}
                    centerPosition={centerPosition}
                  />
                  <TransformComponent>
                    <IdealImage
                      parentType={'unit'}
                      key={selectedContent?.objectId}
                      contentUri={selectedContent?.contentUri}
                      fallbackUri={fallbackContent?.contentUri}
                      imageSize={selectedContent?.originalImageSize}
                      containerSize={contentContainerSize}
                      onLoad={(iWidth, iHeight, target, isPlaceholder) => {
                        if (!isPlaceholder) return;
                        const newMinZoom = Math.min(
                          contentContainerSize.width / iWidth,
                          contentContainerSize.height / iHeight
                        );
                        setImageDimensions({
                          width: iWidth,
                          height: iHeight
                        });
                        setMinZoom(newMinZoom);
                        setTimeout(() => {
                          const centerPosition = {
                            centerX: (iWidth - contentContainerSize.width) / -2,
                            centerY:
                              (iHeight - contentContainerSize.height) / -2
                          };
                          setTransform(
                            centerPosition.centerX,
                            centerPosition.centerY,
                            1,
                            0
                          );
                          setImageIsCentered(true);
                        }, 100);
                        setContentLoaded(true);
                      }}
                      enableTracking={true}
                      vaultId={vault?.objectId}
                      projectId={project?.objectId}
                      shouldExpandImage={isUnitInfoCollapsed}
                      onImageExpanded={() => {
                        // positionX indicates  which part of the image in pixels is hidden
                        // behind the left part of its container
                        // if current positionX is more than 100 we decrease it by 100 so the image covers its container
                        // else set the positionX to 0 to show the whole image
                        // 100px is the width of the gap which appears when the unitCardInfo is collapsed
                        let newPositionX = 0;
                        if (positionX < -100) {
                          newPositionX = positionX += 100;
                        }
                        setTransform(
                          positionX > 0 ? positionX + 50 : newPositionX,
                          positionY,
                          positionX > 0 ? 1 : scaleUnitInfoCollapsed
                        );
                      }}
                      onImageCollapsed={() => {
                        setTransform(
                          positionX > 0 ? positionX - 50 : positionX,
                          positionY,
                          1
                        );
                      }}
                      displayOnDemand={true}
                      displayDemanded={imageIsCentered}
                      disableFade={true}
                      baseImageUrl={getCurrentEnvironment().baseImageUrl}
                    />
                  </TransformComponent>
                </>
              );
            }}
          </TransformWrapper>
        );
        break;
    }
  } else {
    content = (
      <>
        <IdealImage
          parentType={'unit'}
          contentUri={fallbackContent?.contentUri}
          imageSize={fallbackContent?.originalImageSize}
          containerSize={{
            // We use a temporary size when the container hasn't been loaded yet.
            // 400x200 is just a random size, this doesn't matter much since it's the blurred out fallback image and will be replaced when the container is loaded
            width:
              contentWrapperRef.current?.getBoundingClientRect().width || 400,
            height:
              contentWrapperRef.current?.getBoundingClientRect().height || 200
          }}
          onLoad={() => {
            if (!contentLoaded) {
              setContentLoaded(true);
            }
          }}
          vaultId={vault?.objectId}
          projectId={project?.objectId}
          blur
          baseImageUrl={getCurrentEnvironment().baseImageUrl}
        />
        <NoContentWrapper>
          {capitalize(localizer.noAttachmentForUnit)}
        </NoContentWrapper>
      </>
    );
  }

  if (!loadedUnit) {
    return null;
  }

  // Get the 360
  const album360Url = loadedUnit?.album360Url;
  const album360Code = album360Url
    ? qs.parse(album360Url.split('?')[1], {
        parseBooleans: true,
        parseNumbers: true
      }).id
    : null;

  const hasSceneStreaming =
    loadedUnit?.scene && loadedUnit?.sceneCloudStreamingUrl;

  let sceneContentId;

  if (hasSceneStreaming) {
    sceneContentId = loadedUnit?.scene?.objectId;
  }

  // Get the external experience
  const externalExperienceUrl = loadedUnit?.externalExperienceUrl;

  // Show arrow logic
  let showPrevious = false;
  let showNext = false;

  const contentList = contentItemList.filter((x) =>
    mediaTypes.includes(x.contentItemType)
  );

  if (contentList?.length > 0) {
    const currentIndex = contentList.findIndex(
      (x) => x.objectId === selectedContent?.objectId
    );
    showPrevious = currentIndex > 0;
    showNext = currentIndex < contentList.length - 1;
  }

  if (isMobileOnly) {
    return (
      <UnitDetailMobilePage
        unit={loadedUnit}
        contentLoaded={contentLoaded}
        contentItemList={contentItemList}
        unitHasContent={unitHasContent}
        currencyCode={
          ProjectState?.vault?.vaultSettings?.defaultCurrencyCode ?? 'EUR'
        }
        showUnitPrice={shouldShowUnitPrice(
          showcaseConfiguration,
          AuthState,
          loadedUnit?.state
        )}
        showUnitState={showcaseConfiguration?.showUnitState ?? true}
        showUnitDescription={
          showcaseConfiguration?.showUnitDescription ?? false
        }
        externalExperienceUrl={externalExperienceUrl}
        fallbackThumbnail={fallbackImageUri}
        album360Url={album360Url}
        sceneContentId={sceneContentId}
        hasSceneStreaming={hasSceneStreaming}
        floorPlanFile={unitFloorPlanFile}
      />
    );
  }

  return (
    <ViewWrapper>
      <Wrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <UnitInfoHeader unit={loadedUnit} />
        <Sidebar collapsed={isUnitInfoCollapsed}>
          <UnitInfoCard
            ref={sidebarRef}
            unit={loadedUnit}
            currencyCode={
              ProjectState?.vault?.vaultSettings?.defaultCurrencyCode ?? 'EUR'
            }
            showUnitPrice={shouldShowUnitPrice(
              showcaseConfiguration,
              AuthState,
              loadedUnit?.state
            )}
            showUnitState={showcaseConfiguration?.showUnitState ?? true}
            showUnitDescription={
              showcaseConfiguration?.showUnitDescription ?? false
            }
            isCollapsedView={isUnitInfoCollapsed}
            setCollapsedView={setUnitInfoCollapsed}
            floorPlanFile={unitFloorPlanFile}
            setShouldRecalculateSidebarHeight={
              setShouldRecalculateSidebarHeight
            }
          />
        </Sidebar>
        <ContentGalleryWrapper>
          <SelectedContent id="unit-media-container">
            <Navigation offsetNavButtons={offsetNavButtons}>
              <Button visible={showPrevious} onClick={onPrevious}>
                <FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="sm" />
              </Button>
              <Button visible={showNext} onClick={onNext}>
                <FontAwesomeIcon icon={['fal', 'long-arrow-right']} size="sm" />
              </Button>
            </Navigation>
            <AnimatePresence>
              <ContentWrapper
                ref={contentWrapperRef}
                key={
                  selectedContent?.objectId ??
                  `selected-content-item-${selectedContent?.contentUri}`
                }
                initial={{ opacity: 0 }}
                animate={contentLoaded ? { opacity: 1 } : { opacity: 0 }}
                transition={{ delay: 0.3, duration: 0.15 }}
              >
                {content}
                <Image360Wrapper
                  key={'image360'}
                  initial={{
                    opacity: 0,
                    zIndex: -1,
                    display: 'none'
                  }}
                  animate={
                    selectedImage360
                      ? { opacity: 1, zIndex: 1, display: 'flex' }
                      : {
                          opacity: 0,
                          transitionEnd: {
                            zIndex: -1,
                            display: 'none'
                          }
                        }
                  }
                >
                  <ThreeSixtyImageContent
                    contentUri={selectedImage360?.contentUri}
                    onLoad={() => {}}
                  />
                </Image360Wrapper>
              </ContentWrapper>
              {!contentLoaded && <StyledLoader />}
            </AnimatePresence>
          </SelectedContent>

          <UnitContentListWrapper
            smallPadding={isUnitInfoCollapsed || !sidebarOverlapsContentList}
            ref={contentListWrapperRef}
          >
            <UnitContentList
              contentItems={contentItemList}
              album360Code={album360Code}
              sceneContentId={sceneContentId}
              selectedContentItem={selectedContent}
              externalExperienceUrl={externalExperienceUrl}
              onSelectContentItem={(newItem) => {
                if (newItem !== selectedContent) {
                  trySelectContent(newItem);
                }
              }}
              on360Selected={(newItem) => {
                trySelectContent(newItem);
              }}
              onSceneSelected={(newItem) => {}}
              onExternalExperienceSelected={(contentItem) => {
                trySelectContent(contentItem);
              }}
              fallbackThumbnail={fallbackImageUri}
            />
          </UnitContentListWrapper>
        </ContentGalleryWrapper>
      </Wrapper>
    </ViewWrapper>
  );
};

UnitDetailPage.propTypes = {};

UnitDetailPage.defaultProps = {};

export default UnitDetailPage;
