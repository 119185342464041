import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect
} from 'react';
import { bool, func, string } from 'prop-types';

// Helpers
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  min-width: 100px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: ${isMobile ? 'flex-start' : 'center'};
  align-items: center;
  color: ${(props) =>
    !props.disabled ? props.theme.primary100 : props.theme.gray300};
  transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  margin-top: 15px;
  ${({ styles }) => styles}
`;

const InputWrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  flex-grow: 1;
  position: relative;
`;

const TextInput = styled.input`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  width: 100%;
  outline: none;
  transition: 0.5s;
  text-align: left;
  color: ${({ disabled, theme }) =>
    !disabled ? theme.showcaseBlack : theme.gray300};
  flex-grow: 1;
  padding: 13px 10px;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.errorColor : theme.gray200)};
  height: 48px;
  box-sizing: border-box;

  &:focus {
    border: 1px solid
      ${({ theme, error }) => (error ? theme.errorColor : theme.gray300)};
  }

  &::placeholder {
    color: ${(props) =>
      !props.disabled ? props.theme.primary100 : props.theme.gray300};
  }

  ${(props) => props.styles};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.primary100};
  font-size: 0.875rem;
  margin-bottom: 3px;
  display: block;
  cursor: pointer;
`;

export const Text = styled.p`
  color: ${({ error, info, success, theme }) => {
    if (error) return theme.errorColor;
    if (info) return theme.accent;
    if (success) return theme.successColor;
  }};
  margin: 0;
  text-align: ${({ info }) => (info ? 'left' : 'right')};
  position: absolute;
  top: calc(100% + 1px);
  font-size: 0.75rem;
  right: 0;
`;

const InputField = forwardRef(
  (
    {
      onChange,
      placeholder,
      disabled,
      initValue,
      inputFieldStyles,
      searchIconStyles,
      wrapperStyles,
      onFocus,
      label,
      required,
      name,
      error,
      success,
      setFieldValue,
      onBlur,
      value: formikValue
    },
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const [value, setValue] = useState(initValue ?? '');

    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    }));

    const onValueChange = (event) => {
      onChange(event);
      if (value !== event.target.value) {
        setFieldValue(event.target.value);
        setValue(event.target.value);
      }
    };

    useEffect(() => {
      if (formikValue !== value) {
        setValue(formikValue);
      }
    }, [formikValue, value]);

    return (
      <Wrapper
        isHovered={isHovered}
        disabled={disabled}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          onFocus();
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        styles={wrapperStyles}
      >
        <InputWrapper
          id="input-field-input-wrapper"
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: '100%' }}
        >
          {label ? (
            <Label htmlFor={name}>
              {label}
              {required ? '*' : ''}
            </Label>
          ) : null}
          <TextInput
            data-testid="InputField"
            name={name}
            id={name}
            type="text"
            autoComplete="off"
            value={value}
            ref={inputRef}
            placeholder={placeholder || label}
            onChange={onValueChange}
            onBlur={onBlur}
            disabled={disabled}
            styles={inputFieldStyles}
            error={error}
          />
          {error && <Text error={error}>{error}</Text>}
          {success && <Text success={success}>{success}</Text>}
        </InputWrapper>
      </Wrapper>
    );
  }
);

InputField.propTypes = {
  value: string,
  initValue: string,
  error: string,
  success: string,
  placeholder: string,
  name: string,
  label: string,
  disabled: bool,
  required: bool,
  onChange: func,
  setFieldValue: func,
  onBlur: func,
  onFocus: func
};

InputField.defaultProps = {
  value: '',
  initValue: '',
  error: '',
  success: '',
  placeholder: '',
  name: '',
  label: '',
  disabled: false,
  required: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  setFieldValue: () => {}
};

export default InputField;
