import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
// https://www.npmjs.com/package/pannellum-react
import { Pannellum } from 'pannellum-react';
import {
  getCurrentEnvironment,
  addQueryParams,
  removeQueryParams,
  fetchSettingsFromURL
} from 'helpers';
import urlJoin from 'url-join';
import localizer from 'localization/localizer';
import queryParamAbbreviations from 'configs/QueryParamAbbreviations.json';
import { isMobile } from 'react-device-detect';

// Styling
import styled, { withTheme } from 'styled-components';

const PageWrapper = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: ${isMobile ? 'calc(100% - 60px)' : '100%'};
`;

const LoadingOverlay = styled.div`
  position: fixed;
  z-index: 10000;
  background-color: ${(props) => props.theme.showcaseWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div`
  position: fixed;
  z-index: 1;
  width: 360px;
  top: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: 1px;
  text-align: center;
`;

const LoadingText = styled.p`
  color: ${(props) => props.showcaseBlack};
  letter-spacing: 1px;
  text-align: center;
  max-width: 70vw;
  margin: 1rem;
  font-size: 1rem;
`;

const BackButton = styled.button`
  z-index: 101;
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  padding: 15px 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: 15px;
  left: 75px;
  outline: none;
`;

const BackButtonText = styled.div`
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const BackButtonIcon = styled(FontAwesomeIcon)`
  color: white;
  padding-right: 10px;
`;

const Fullscreen360Viewer = ({
  image360ContentItem,
  onExit,
  hideBackButton
}) => {
  const { objectId, contentUri } = image360ContentItem;
  const [loading, setLoading] = useState(true);
  const [viewerIsTracked, setViewerIsTracked] = useState(false);

  const pannellumRef = useRef();

  const transform = 'q=100';
  const fullSpotContentUri = urlJoin(
    getCurrentEnvironment().baseImageUrl,
    transform,
    contentUri
  );

  const { search } = useLocation();
  const qp = queryParamAbbreviations.threeSixtyImage;

  // respond to browser Back arrow click
  useEffect(() => {
    if (!viewerIsTracked) return;
    const queryParams = fetchSettingsFromURL();

    if (!queryParams[qp]) onExit();
  }, [search, qp, onExit, viewerIsTracked]);

  // keep track of currently opened 360 image viewer
  useEffect(() => {
    addQueryParams([{ [qp]: objectId }]);
    setViewerIsTracked(true);
    return () => removeQueryParams([{ [qp]: '' }]);
  }, [objectId, qp]);

  return (
    <PageWrapper>
      {loading && (
        <LoadingOverlay>
          <ShowcaseLoader />
          <LoadingText>{localizer.threeSixtyImagePreparing}</LoadingText>
        </LoadingOverlay>
      )}
      <Instructions>{localizer.threeSixtyImageNavigateDesktop}</Instructions>
      <Pannellum
        ref={pannellumRef}
        width="100%"
        height="100%"
        image={fullSpotContentUri}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
        onLoad={() => setLoading(false)}
        showControls={false}
      >
        {/* NOTE: can put hot spots inside */}
      </Pannellum>
      {!hideBackButton && (
        <BackButton
          onClick={() => {
            onExit();
          }}
          key="backButton"
        >
          <BackButtonIcon icon={['fal', 'arrow-left']} />
          <BackButtonText>{localizer.back}</BackButtonText>
        </BackButton>
      )}
    </PageWrapper>
  );
};

export default withTheme(Fullscreen360Viewer);
