import React, { useCallback, useState, useEffect } from 'react';
import { shape, bool, func } from 'prop-types';

// Components
import { theme } from '../../../../../main';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useTourState } from 'stores/TourStore';
import { isMobile } from 'react-device-detect';
import { useCustomShowcaseTheme } from 'helpers/customHooks';

// Styling
import styled, { keyframes, css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Circle = styled.div`
  width: ${(props) => props.size + 'px' ?? '20px'};
  height: ${(props) => props.size + 'px' ?? '20px'};
  display: flex;
  justify-content: center;
  transition: 0.4s;
  align-items: center;
  background-color: ${(props) =>
    props.isSpotHovered
      ? props.theme.showcaseWhite
      : props.theme.showcaseBlack};
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.disabled ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.5)'};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  ${() =>
    css`
      animation: ${pulse} 2s linear
        ${({ animationEnabled }) => (animationEnabled ? 'infinite' : '')};
    `}
`;

const InnerCircle = styled.div`
  width: ${(props) => props.size * 0.6 + 'px' ?? '12px'};
  height: ${(props) => props.size * 0.6 + 'px' ?? '12px'};
  transition: 0.4s;
  background-color: ${(props) => props.color};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border-radius: 50%;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.35);
  }
  28% {
    transform: scale(0.95);
  }
  46% {
    transform: scale(1.26);
  }
  64% {
    transform: scale(1);
  }
`;

const UnitSpot = ({
  spotObject,
  spotLocation,
  isSpotHovered,
  onSpotClicked,
  disabled,
  spotAbsoluteLocation,
  animationEnabled // Enable or disable the infinite dot animation (the first animation always appears)
}) => {
  const { customTheme } = useCustomShowcaseTheme(theme);
  const { ProjectState } = useProjectState();
  const showUnitState =
    ProjectState?.showcaseConfiguration?.showUnitState ?? true;
  const [hovered, setHovered] = useState(false);
  const [highlighted, setHighlighted] = useState(false);

  const { TourState, TourStateDispatch } = useTourState();
  const { highlightedUnitSpotParams } = TourState;

  useEffect(() => {
    setHighlighted(
      spotObject?.objectId === highlightedUnitSpotParams?.objectId
    );
  }, [highlightedUnitSpotParams, spotObject]);

  let color;
  if (!showUnitState) {
    color = customTheme?.unitDefault;
  } else {
    spotObject?.unitItem?.state === 'AVAILABLE'
      ? (color = customTheme?.unitAvailable)
      : spotObject?.unitItem?.state === 'SOLD'
      ? (color = customTheme?.unitSold)
      : spotObject?.unitItem?.state === 'IN_OPTION'
      ? (color = customTheme?.unitInOption)
      : (color = customTheme?.unitDefault);
  }

  const onUnitSpotHover = useCallback(
    (e) => {
      if (!hovered) {
        setHovered(true);
        TourStateDispatch({
          type: 'update',
          payload: {
            highlightedUnitSpotParams: {
              showUnitState,
              spotLocation,
              spotAbsoluteLocation: spotAbsoluteLocation,
              objectId: spotObject?.objectId
            }
          }
        });
      }
    },
    [
      TourStateDispatch,
      hovered,
      showUnitState,
      spotLocation,
      spotAbsoluteLocation,
      spotObject
    ]
  );

  return (
    <Wrapper>
      <Circle
        isSpotHovered={highlighted || (isSpotHovered && hovered)}
        disabled={disabled}
        size={spotObject?.size ?? 20}
        color={color}
        onClick={() => {
          if (isMobile || highlighted) {
            onSpotClicked(spotObject);
          }
        }}
        onMouseOver={(e) => {
          if (!isMobile) {
            onUnitSpotHover(e);
            setHovered(false);
          }
        }}
        animationEnabled={animationEnabled}
      >
        <InnerCircle
          isSpotHovered={highlighted || (isSpotHovered && hovered)}
          showUnitState={showUnitState}
          unitState={spotObject?.unitItem?.state}
          disabled={disabled}
          size={spotObject?.size ?? 20}
          color={color}
        />
      </Circle>
    </Wrapper>
  );
};

UnitSpot.propTypes = {
  spotObject: shape({}).isRequired,
  spotLocation: shape({}).isRequired,
  isSpotHovered: bool,
  onSpotClicked: func,
  disabled: bool
};

UnitSpot.defaultProps = {
  isSpotHovered: false,
  disabled: false
};

export default UnitSpot;
