import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { useLocation } from 'react-router-dom';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Turntable from './components/Turntable';
import { isMobile } from 'react-device-detect';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import localizer from 'localization/localizer';
import {
  addQueryParams,
  removeQueryParams,
  fetchSettingsFromURL
} from 'helpers';
import queryParamAbbreviations from 'configs/QueryParamAbbreviations.json';

//Styling
import styled from 'styled-components';

const MainWrapper = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: ${isMobile ? `calc(100% - 60px)` : `calc(100% - 70px)`};
  overflow: hidden;
`;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const BackButton = styled.button`
  z-index: 101;
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  padding: 15px 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: 15px;
  left: 75px;
  outline: none;
`;

const BackButtonText = styled.div`
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const BackButtonIcon = styled(FontAwesomeIcon)`
  color: white;
  padding-right: 10px;
`;

const FullscreenTurntablePreview = ({
  turntable,
  onOpenUnitPage,
  onExit,
  isMobile
}) => {
  const { ProjectState } = useProjectState();
  const { project, showcaseConfiguration } = ProjectState;

  const [turntableImages, setTurntableImages] = useState([]);
  const [viewerIsTracked, setViewerIsTracked] = useState(false);

  const { search } = useLocation();
  const qp = queryParamAbbreviations.turntable;

  // respond to browser Back arrow click
  useEffect(() => {
    if (!viewerIsTracked) return;
    const queryParams = fetchSettingsFromURL();

    if (!queryParams[qp]) onExit();
  }, [search, qp, onExit, viewerIsTracked]);

  // keep track of currently opened turntable viewer
  useEffect(() => {
    addQueryParams([{ [qp]: turntable.objectId }]);
    setViewerIsTracked(true);
    return () => removeQueryParams([{ [qp]: '' }]);
  }, [turntable, qp]);

  useEffect(() => {
    if (turntable) {
      // Check first for list of POV's
      if (turntable.turnPointOfViewList) {
        const allImages = [];
        turntable.turnPointOfViewList.forEach((turnPoint) => {
          allImages.push(turnPoint?.contentItem?.contentUri);
        });

        setTurntableImages(allImages);
      }
    }
  }, [turntable]);

  return (
    <MainWrapper isMobile={isMobile}>
      <AnimatePresence key="fullscreenTurntable">
        <Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="mainTurntableView"
        >
          <Turntable
            turntable={turntable}
            images={turntableImages}
            units={project?.unitList}
            onOpenUnitPage={onOpenUnitPage}
            showcaseConfiguration={showcaseConfiguration}
          />
        </Wrapper>
        <BackButton
          onClick={() => {
            onExit();
          }}
          key="backButton"
        >
          <BackButtonIcon icon={['fal', 'arrow-left']} />
          <BackButtonText>{localizer.back}</BackButtonText>
        </BackButton>
      </AnimatePresence>
    </MainWrapper>
  );
};

FullscreenTurntablePreview.propTypes = {
  turntable: object.isRequired,
  onExit: func.isRequired
};

export default FullscreenTurntablePreview;
