import React from 'react';

// Styling
import styled from 'styled-components';

// Components
import ScreenWrapper from './ScreenWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { isMobile } from 'react-device-detect';

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Info = styled.div`
  min-height: ${isMobile ? '150px' : 'none'};
  padding: ${isMobile ? '20px 15px' : '20px 30px'};
  box-sizing: border-box;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.theme.showcaseWhite};
`;

const ScreenTitle = styled.h3`
  font-family: ${isMobile ? 'inherit' : 'open-sans'};
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0 0 5px;
  color: ${(props) => props.theme.showcaseBlack};
`;

const ScreenText = styled.p`
  font-family: ${isMobile ? 'inherit' : 'open-sans'};
  color: ${(props) => (isMobile ? props.theme.primary200 : props.theme.black)};
  margin: 0;
  font-size: ${isMobile ? 'none' : '1.125rem'};
`;

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
  color: ${(props) => props.theme.primary100};
  cursor: pointer;
  visibility: ${isMobile ? 'hidden' : 'visible'};
`;

const ScreenBase = ({ image, title, text, direction, onClose }) => {
  return (
    <ScreenWrapper direction={direction}>
      <CloseIcon icon={['fal', 'times']} size="1x" onClick={() => onClose()} />
      <Image src={image} alt={title} />
      <Info>
        <ScreenTitle>{title}</ScreenTitle>
        <ScreenText>{text}</ScreenText>
      </Info>
    </ScreenWrapper>
  );
};

export default ScreenBase;
