import React, { useReducer, createContext, useContext } from 'react';

export const Context = createContext();

const StreamStore = (props) => {
  const [StreamState, StreamStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'open':
          return {
            ...state,
            ...action.payload
          };
        case 'close':
          return {
            ...state,
            streamUrl: null
          };
        default:
          return state;
      }
    },
    {
      streamUrl: null,
      streamType: null,
      project: null,
      useProjectGallery: false,
      sessionToken: null
    }
  );
  return (
    <Context.Provider value={{ StreamState, StreamStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useStreamState = () => useContext(Context);

export default StreamStore;
