import { useState, useEffect } from 'react';

import * as pdfjsLib from 'pdfjs-dist/build/pdf';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { getCurrentEnvironment, computeCloudinaryThumbnail } from 'helpers';
import { sortBySortingOrders } from 'helpers/units/VmUnitHelper';

import BrokenTnumbnailPlaceholder from 'resources/images/BrokenThumbnailPlaceholder.png';

const mediaTypes = ['image', 'video', 'document', 'floorplan'];

const useGetUnitImagePreview = (unit, fallbackContent, params) => {
  /**
   * We need to set a worker in order to increase the performance when loading pdf's since the worker will do the heavy lifting.
   * We use a publicly availble worker from https://unpkg.com/ which is commonly used.
   * Make sure that the version specified here matches the pdfjs-dist version in the package.json
   */
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

  // Project Store
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration, project } = ProjectState;
  const defaultContentItemSorting = project?.defaultContentItemSorting;

  const [unitHasContent, setUnitHasContent] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [previewContent, setPreviewContent] = useState();

  const [index, setIndex] = useState(0);
  const [sortedUnitContent, setSortedUnitContent] = useState();
  const [isPreparingURL, setIsPreparingURL] = useState(true);

  useEffect(() => {
    if (unit) {
      const unitContentList =
        unit?.vmContentCollection?.vmContentItemList?.filter((x) =>
          mediaTypes.includes(x.contentItemType)
        ) ?? [];
      const unitLinkedContentList =
        unit?.linkedContentCollection?.vmContentItemList?.filter((x) =>
          mediaTypes.includes(x.contentItemType)
        ) ?? [];
      const contentList = [
        ...unitContentList,
        ...unitLinkedContentList.map((item) => ({ ...item, isLinked: true }))
      ];
      if (contentList.length > 0) {
        const sortedContent = sortBySortingOrders(
          contentList,
          unit?.contentItemCustomSortingValues,
          defaultContentItemSorting
        );
        setUnitHasContent(true);
        setSortedUnitContent(sortedContent);
      } else {
        setUnitHasContent(false);
        setIsPreparingURL(false);
      }
    }
  }, [unit, defaultContentItemSorting]);

  useEffect(() => {
    if (
      sortedUnitContent &&
      index < sortedUnitContent.length &&
      isPreparingURL
    ) {
      if (!!sortedUnitContent[index].contentUri) {
        if (
          sortedUnitContent[index].contentItemType === 'document' ||
          sortedUnitContent[index].contentItemType === 'floorplan'
        ) {
          const loadingTask = pdfjsLib.getDocument(
            sortedUnitContent[index].contentUri
          );
          loadingTask.promise
            .then(() => {
              setIsPreparingURL(false);
            })
            .catch((err) => {
              setIndex(index + 1);
            });
        } else {
          setIsPreparingURL(false);
        }
      } else {
        setIndex(index + 1);
      }
    }
  }, [sortedUnitContent, index, isPreparingURL]);

  useEffect(() => {
    if (sortedUnitContent) {
      setPreviewContent(sortedUnitContent[index]);
    }
  }, [sortedUnitContent, index]);

  useEffect(() => {
    if (showcaseConfiguration?.showUnitPreviewImage) {
      const baseUrl = getCurrentEnvironment().baseImageUrl;
      const fallback = fallbackContent?.contentUri
        ? `${baseUrl}/${params}/${fallbackContent.contentUri}`
        : BrokenTnumbnailPlaceholder;
      if (unitHasContent && previewContent?.contentUri && !isPreparingURL) {
        if (previewContent?.contentItemType === 'image') {
          const originalImageUri = `${baseUrl}/${
            params
              ? `${params}/${previewContent.contentUri}`
              : `o=true/${previewContent.contentUri}`
          }`;
          setPreviewImageUrl(originalImageUri);
        } else if (previewContent?.contentItemType === 'video') {
          const env = getCurrentEnvironment();
          const cloudinaryImageUri = computeCloudinaryThumbnail(
            decodeURIComponent(previewContent.contentUri),
            env.googleStorageBucketId,
            env.cloudinaryVideoBaseUrl,
            'h_80,c_fill,dn_50'
          );

          setPreviewImageUrl(cloudinaryImageUri);
        } else if (
          previewContent?.contentItemType === 'document' ||
          previewContent?.contentItemType === 'floorplan'
        ) {
          const env = getCurrentEnvironment();
          const cloudinaryImageUri = computeCloudinaryThumbnail(
            decodeURIComponent(previewContent?.contentUri),
            env.googleStorageBucketId,
            env.cloudinaryImageBaseUrl,
            'h_80,c_fill,dn_50'
          );
          setPreviewImageUrl(cloudinaryImageUri);
        } else if (fallbackContent && !isPreparingURL) {
          setPreviewImageUrl(fallback);
        }
      } else if (!isPreparingURL) {
        setPreviewImageUrl(fallback);
      }
    }
  }, [
    showcaseConfiguration,
    previewContent,
    fallbackContent,
    unitHasContent,
    params,
    isPreparingURL
  ]);

  return { previewImageUrl, isPreparingURL };
};

export default useGetUnitImagePreview;
