import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { string, bool, func, shape, array, oneOfType } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectUnitsInfo from './ProjectUnitsInfo';
import FavoriteProjectButton from 'components/share/FavoriteProjectButton';
import PortfolioCardThumbnail from './PortfolioCardThumbnail';

// Helpers
import { buildAddress, displayLocalizedValue } from 'helpers';
import { motion, AnimatePresence } from 'framer-motion';
import { getLocalizedProjectTitle } from 'helpers/project/VmProjectSectionHelper';
import { view } from './PortfolioPage';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const CardWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: ${isMobileOnly
    ? `0 4px 12px 0 rgba(0, 0, 0, 0.2)`
    : `0 10px 20px 0 rgba(0, 0, 0, 0.4)`};
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 200ms ease-out;
  &::before {
    position: absolute;
    z-index: 0;
    display: ${isMobileOnly ? 'none' : 'block'};
    content: '';
    width: 96%;
    height: 96%;
    top: 0;
    left: 0;
    transition: all 200ms ease 100ms;
  }
  &:hover {
    &::before {
      top: -10px;
      left: -10px;
      background-color: ${({ theme, projectPrimaryColor }) =>
        projectPrimaryColor ?? theme.contactCardBg};
    }
  }
  ${({ origin, unitsdataheight, highlighted, hovered, clicked, landscape }) => {
    const defaultCardHeight = isMobileOnly ? 180 : 200;
    const defaultHoveredHeight = 300;
    const hoveredHeight = Math.max(
      defaultHoveredHeight,
      unitsdataheight + defaultCardHeight
    );
    const hoveredState = `
      height: ${hoveredHeight}px;
      max-height: ${hoveredHeight}px;
      &::before {
        display: none !important;
      }
    `;
    switch (origin) {
      case view.map:
        const height = isMobileOnly ? `${defaultCardHeight}px` : '210px';
        return `
          height: ${height};
          max-height: ${
            landscape && isMobileOnly ? 'calc(100vh - 100px)' : height
          };
          transition: height 200ms ease;
          align-self: flex-end;
          ${(highlighted || hovered) && hoveredState}
        `;
      case view.cards:
        return `
          position: relative;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
          flex-flow: column;
          &::before {
            left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 90%;
              height: 90%;
              transition: all 200ms ease;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.05);
            &::before {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: calc(100% + 8px);
              height: calc(100% + 6px);
              opacity: 0.4;
            }
          }
        `;
      default:
        return ``;
    }
  }}
`;

const CardContent = styled(motion.div)`
  ${({ origin }) => {
    switch (origin) {
      case view.cards:
        return `
          position: relative;
          height: ${isMobileOnly ? '200' : '280'}px;
          width: 100%;
        `;

      default:
        return `
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          max-height: 100%;
        `;
    }
  }}
  padding: ${({ hidden }) =>
    hidden ? 0 : isMobileOnly ? '60px 15px 15px' : '70px 30px 30px'};
  box-sizing: border-box;
  color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  overflow: hidden;
  transition: all 200ms ease;
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  position: relative;
  z-index: 4;
`;

// to be used later as a container for a tag and a favorites button
const TopBar = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 15px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
`;

const Title = styled.div`
  font-size: ${isMobileOnly ? 1.5 : 1.875}rem;
  line-height: ${isMobileOnly ? 30 : 40}px;
  margin: 0 0 ${isMobileOnly ? 5 : 10}px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
`;

const Address = styled(Title)`
  font-size: ${isMobileOnly ? 0.875 : 1}rem;
  line-height: 20px;
  margin: 0;
  padding-left: 20px;
  position: relative;
  flex-shrink: 0;
`;

const LocationIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const UnitsInfoWrapper = styled(motion.div)`
  position: relative;
  z-index: 5;
  transition: margin 200ms ease;
  margin-top: ${({ hovered }) => (hovered ? (isMobileOnly ? 15 : 30) : 0)}px;
`;

const OuterUnitsInfoWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  padding: ${isMobileOnly ? '15px' : '15px 30px 30px'};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.showcaseWhite};
  border-top: 1px solid ${({ theme }) => theme.gray200};
`;

const ProjectStateLabel = styled(motion.div)`
  height: ${({ origin }) => (origin === view.map ? 30 : 40)}px;
  padding: 5px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  backdrop-filter: blur(10px);
  background-color: rgba(159, 156, 170, 0.5);
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.showcaseWhite};
  text-transform: uppercase;
  user-select: none;
  margin-right: auto;
`;

const PortfolioCard = forwardRef(
  (
    {
      project,
      thumbnailUri,
      isDragging,
      onCardClick,
      contentOpacity = 1,
      unitsData,
      configuration,
      status,
      vaultSettings,
      origin,
      onToggleHoverState = () => {},
      highlighted,
      shouldShowPrices,
      forceShowUnitsData = false,
      shouldShowStatuses
    },
    ref
  ) => {
    const [cardIsHovered, setCardIsHovered] = useState(
      isMobileOnly && origin !== view.map
    );
    const [cardIsClicked, setCardIsClicked] = useState(false);

    const [cardImageHeight, setCardImageHeight] = useState();

    const unitsInfoRef = useRef();
    const cardContentRef = useRef();

    const cardColor = thumbnailUri ? 'white' : 'black';
    const { isLandscape } = useMobileOrientation();

    // project state related
    let isGreyedOut;
    let projectState;
    switch (project?.state) {
      case 'COMPLETED':
        projectState = localizer.completed;
        break;
      case 'DRAFT':
        projectState = localizer.draft;
        break;
      case 'UPCOMING':
        projectState = localizer.upcoming;
        break;
      default:
        isGreyedOut = false;
    }

    // calculate card image height needed
    useEffect(() => {
      if (origin === view.cards) {
        const height = isMobileOnly ? 200 : 280;
        setCardImageHeight(height);
      } else if (origin === view.gallery) {
        const height = cardContentRef.current.offsetHeight;
        setCardImageHeight(height);
      } else if (origin === view.map) {
        const height = cardContentRef.current.offsetHeight;
        setCardImageHeight(height);
      }
    }, [origin]);

    useEffect(() => {
      onToggleHoverState(cardIsHovered ? project : null);
    }, [cardIsHovered, onToggleHoverState, project]);

    const projectDescription = displayLocalizedValue({
      en: project?.description,
      ...project?.localizedDescription?.textMap
    });

    const projectUnitsInfoShareProps = {
      description: projectDescription,
      unitsData,
      configuration,
      status,
      vaultSettings,
      origin,
      shouldShowPrices
    };

    const showUnitsData = cardIsHovered || highlighted || forceShowUnitsData;

    const favButtonProps = {
      projectId: project?.objectId
    };
    if (origin === view.map) {
      favButtonProps.buttonSize = 30;
      favButtonProps.iconSize = 18;
    }

    const calculateUnitsDataHeight = () => {
      if (forceShowUnitsData) return 'auto';
      // 170 is a combined height of TopBar, Title and Address (140 in mobile)
      const minHeight = isMobileOnly ? 140 : 170;
      const unitsInfoHeight = unitsInfoRef.current?.offsetHeight;
      const cardContentHeight =
        cardContentRef.current?.offsetHeight || minHeight;
      const maxAvailableHeight = cardContentHeight - minHeight;
      const shownUnitsDataHeigth = unitsInfoHeight
        ? `${Math.min(maxAvailableHeight, unitsInfoHeight)}px`
        : maxAvailableHeight;
      const height = showUnitsData ? shownUnitsDataHeigth : 0;
      return height;
    };

    return (
      <AnimatePresence>
        <CardWrapper
          {...getAnimationByView(origin, cardIsHovered)}
          onClick={() => {
            if (!isDragging && (cardIsHovered || forceShowUnitsData)) {
              setCardIsClicked(true);
              onCardClick();
            }
          }}
          onMouseEnter={() => setCardIsHovered(true)}
          onMouseLeave={() => setCardIsHovered(false)}
          projectPrimaryColor={
            !!configuration?.useCustomColors
              ? configuration?.showcasePrimaryColor
              : undefined
          }
          origin={origin}
          unitsdataheight={unitsInfoRef.current?.offsetHeight ?? 0}
          hovered={cardIsHovered}
          highlighted={highlighted}
          clicked={cardIsClicked}
          ref={ref}
          landscape={isLandscape}
        >
          <CardContent
            ref={cardContentRef}
            color={cardColor}
            opacity={contentOpacity}
            thumbnail={thumbnailUri ? 1 : 0}
            hovered={cardIsHovered}
            origin={origin}
          >
            <TopBar>
              {shouldShowStatuses &&
                ['COMPLETED', 'UPCOMING', 'DRAFT'].includes(project?.state) && (
                  <ProjectStateLabel origin={origin}>
                    {projectState}
                  </ProjectStateLabel>
                )}

              <FavoriteProjectButton
                cardColor={cardColor}
                {...favButtonProps}
              />
            </TopBar>

            <PortfolioCardThumbnail
              uri={thumbnailUri}
              height={cardImageHeight}
              hovered={cardIsHovered}
              origin={origin}
            />

            <CardContentWrapper>
              <Title>{getLocalizedProjectTitle(project)}</Title>
              <Address>
                <LocationIcon icon={['fas', 'map-marker-alt']} size="sm" />
                {buildAddress(project?.address)}
              </Address>
            </CardContentWrapper>
            {origin !== view.cards && (
              <UnitsInfoWrapper
                hovered={showUnitsData}
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: calculateUnitsDataHeight(),
                  opacity: showUnitsData ? 1 : 0
                }}
                transition={{ duration: 0.25, ease: 'easeOut' }}
              >
                <ProjectUnitsInfo
                  ref={unitsInfoRef}
                  color={cardColor}
                  visible={showUnitsData}
                  {...projectUnitsInfoShareProps}
                />
              </UnitsInfoWrapper>
            )}
          </CardContent>
          {origin === view.cards && (
            <OuterUnitsInfoWrapper>
              <ProjectUnitsInfo
                color={'cards'}
                visible={true}
                {...projectUnitsInfoShareProps}
              />
            </OuterUnitsInfoWrapper>
          )}
        </CardWrapper>
      </AnimatePresence>
    );
  }
);

const getAnimationByView = (origin, cardIsHovered) => {
  switch (origin) {
    case view.map:
      const yOffset = isMobileOnly ? 300 : -125;
      return {
        initial: { opacity: 0, y: yOffset },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: yOffset },
        transition: {
          duration: 0.2,
          type: 'tween',
          ease: 'easeOut'
        }
      };
    case view.cards:
      return {
        initial: { opacity: 1 }
      };
    default:
      return {
        initial: { opacity: 0 },
        animate: {
          opacity: 1,
          scale: cardIsHovered && !isMobileOnly ? 1.05 : 1
        }
      };
  }
};

PortfolioCard.propTypes = {
  thumbnailUri: string,
  isDragging: bool,
  onCardClick: func,
  titleOpacity: shape({}),
  project: shape({}),
  unitsData: oneOfType([string, array]),
  configuration: shape({})
};

PortfolioCard.defaultProps = {
  thumbnailUri: null,
  isDragging: false,
  onCardClick: () => {},
  titleOpacity: null
};

export default PortfolioCard;
