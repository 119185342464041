import React, { useState, useEffect, useCallback } from 'react';
import { func, array } from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Component
import FullscreenButton from 'components/other/FullscreenButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleShowFavoritesButton from './ToggleShowFavoritesButton';
import ContactUsFloatingButton from 'components/share/ContactUsFloatingButton';
import BackToMarketplaceButton from 'components/other/BackToMarketplaceButton';

// Helpers
import { motion } from 'framer-motion';
import { useUiState } from 'stores/UiStore';
import { useProjectState } from 'stores/ProjectStore';
import {
  isMobile,
  isMobileOnly,
  useMobileOrientation
} from 'react-device-detect';
import useMenuOptionVisibilityCheck, {
  MENUBAR_CONFIGURABLE_OPTIONS
} from 'helpers/customHooks/useMenuOptionVisibilityCheck';
import Tippy from '@tippyjs/react';
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';
import { pulse } from 'styles/animations';

// Styling
import styled, { css } from 'styled-components';

const MenuBar = styled(motion.div)`
  height: 40px;
  flex-shrink: 0;
  display: flex;
  border: ${(props) => (props.enabled ? '1px solid' : 'none')};
  border-color: ${(props) => props.theme.gray300};
`;

const LeftSubMenu = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
`;

const MainMenuWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const MainMenu = styled.div`
  flex-grow: 1;
  height: 100%;
  position: relative;
  margin-left: ${({ embedded }) => (isMobile || embedded ? '0' : '100px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 500ms;
  ${isMobileOnly &&
  `
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    justify-content: space-between;
  `}
`;

const SubMenu = styled(motion.div)`
  height: 100%;
  margin-right: ${({ isActive, embedded }) =>
    isActive ? (embedded ? '0' : `calc(45% - 170px)`) : `0`};
  transform: translateX(${({ embedded }) => (embedded ? '0' : '50%')});

  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const SubMenuItem = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  user-select: none;
  font-size: 14px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${(props) =>
    props.active ? props.theme.primary600 : props.theme.primary100};

  &:hover {
    color: ${(props) => props.theme.primary600};
  }
`;

const RightSideMenu = styled(motion.div)`
  min-width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: auto;
  margin-bottom: auto;
  height: 40%;
`;

const Button = styled.div`
  font-family: open-sans;
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  flex-grow: ${isMobileOnly ? '1' : '0'};
  cursor: pointer;
  user-select: none;
  color: ${({ active, theme }) =>
    active ? theme.primary300 : theme.primary100};
  background-color: ${({ active, theme }) =>
    active ? theme.grayWhiteOff : theme.showcaseWhite};
  transition: all 500ms;
  z-index: 10;
  padding: ${({ minified }) => `10px ${minified ? 10 : 20}px`};
  min-width: 40px;
  border-radius: 2px;

  &:hover {
    color: ${(props) => props.theme.primary600};
  }

  &:not(:last-child) {
    margin-right: ${isMobileOnly ? 5 : 20}px;
  }
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ minified }) => (minified ? 0 : 15)}px;
  font-size: 1rem;
`;

const ButtonText = styled(motion.div)`
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
`;

const FloatingButtons = styled.div`
  position: fixed;
  z-index: 600;
  bottom: ${(props) => props.bottom}px;
  right: 10px;
  transition: bottom 200ms ease;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: bottom 200ms ease;
`;

const VerticalGap = styled.div`
  height: 10px;
`;

const FullscreenButtonWrapper = styled.div`
  ${({ animate }) =>
    animate &&
    css`
      animation: ${pulse} 2s linear infinite;
    `}
`;

const ShareButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
`;

const ShareIcon = styled(FontAwesomeIcon)`
  margin: auto;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
`;

const ShowMapIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  background: ${({ theme, active }) =>
    active ? theme.grayWhiteOff : theme.showcaseWhite};
  transition: all 0.2s ease;
  margin-right: 35px;
`;

const ShowMapIcon = styled.div`
  margin: auto;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const VDivider = styled.div`
  width: 1px;
  height: 30px;
  background: ${({ theme }) => theme.gray200};
  margin: 0 15px 0 0;
`;

const NavigationMenu = ({ data, onShareClick, tourEnabled }) => {
  // UI Store
  const { UiState, UiStateDispatch } = useUiState();
  const {
    showMenu,
    subMenu,
    navigationFloatingButtonsBottomPosition,
    isEmbeddedShowcase,
    resourcesHasDocuments,
    hasMinimap,
    showMinimap
  } = UiState;
  const [fileteredMenuOptions, setFilteredMenuOptions] = useState([]);
  const [isShowcaseEmbedded, setIsShowcaseEmbedded] = useState(false);

  // Project Store
  const { ProjectState } = useProjectState();
  const { favoritesList, showcaseConfiguration } = ProjectState;

  const { isLandscape } = useMobileOrientation();

  // Check whether the showcase is embedded
  useEffect(() => {
    setIsShowcaseEmbedded(window.self !== window.top);
  }, []);

  const { customMenuParams, shouldShowMenuOption } =
    useMenuOptionVisibilityCheck();

  const navigate = useNavigate();
  const location = useLocation();
  const { code } = useParams();
  const subRoute =
    location.pathname.split('/')[
      location.pathname.split('/').indexOf(code) + 1
    ];

  const getProperLandingPage = useCallback(
    (params) => {
      let subRoute;
      if (params.includes('tour')) {
        subRoute = 'tour';
      } else {
        subRoute = params.includes('resources')
          ? 'resources'
          : // use the first specified option if customMenu param is set
          customMenuParams.length
          ? customMenuParams[0]
          : params[0];
      }
      if (customMenuParams.length) {
        subRoute += `?customMenu=${customMenuParams.join('-')}`;
      }

      subRoute && navigate(subRoute, { replace: true });
    },
    [navigate, customMenuParams]
  );

  // FILTER MENU OPTIONS
  useEffect(() => {
    if (customMenuParams !== null) {
      const filteredOptions = MENUBAR_CONFIGURABLE_OPTIONS.filter((param) =>
        tourEnabled ? true : param !== 'tour'
      ).filter(shouldShowMenuOption);
      // as Unit page is accessible from Units, Tour and Turntable page
      // we add this check
      if (
        filteredOptions.includes('units') ||
        filteredOptions.includes('tour') ||
        filteredOptions.includes('turntable')
      ) {
        filteredOptions.push('unit');
      }

      filteredOptions.push('favorites');

      // Manually add 'more', 'share', 'project_information' options
      // as they cannot be configured in showcase settings.
      isMobile &&
        filteredOptions.push(...['more', 'share', 'project_information']);
      setFilteredMenuOptions(filteredOptions);
    }
  }, [
    shouldShowMenuOption,
    getProperLandingPage,
    tourEnabled,
    customMenuParams
  ]);

  // REDIRECT BASED ON CUSTOM MENU PARAM
  useEffect(() => {
    if (
      fileteredMenuOptions.length &&
      subRoute &&
      !fileteredMenuOptions.includes(subRoute)
    ) {
      getProperLandingPage(fileteredMenuOptions);
    }
  }, [fileteredMenuOptions, getProperLandingPage, subRoute]);

  return (
    <>
      <MenuBar
        id="navigationMenu"
        initial={{ height: 0, opacity: 0.0 }}
        animate={
          showMenu
            ? { height: isMobile ? 60 : 80, opacity: 1.0 }
            : { height: 0, opacity: 0.0 }
        }
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.5, type: 'tween', ease: 'easeIn' }}
        enabled={showMenu}
      >
        {!isMobile && (
          <LeftSubMenu>
            <BackToMarketplaceButton show={true} />
            {subRoute === 'tour' && hasMinimap && (
              <>
                <Tippy
                  offset={[0, -10]}
                  theme="favoritesToggler"
                  placement="bottom"
                  content={<div>{localizer.minimap}</div>}
                  arrow={false}
                  touch={false}
                >
                  <ShowMapIconWrapper active={showMinimap}>
                    <ShowMapIcon
                      key="mapButton"
                      onClick={() => {
                        UiStateDispatch({
                          type: 'update',
                          payload: {
                            showMinimap: !showMinimap
                          }
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'map']} size="1x" />
                    </ShowMapIcon>
                  </ShowMapIconWrapper>
                </Tippy>
                <VDivider />
              </>
            )}
          </LeftSubMenu>
        )}
        <MainMenuWrapper>
          <MainMenu embedded={isShowcaseEmbedded}>
            <ButtonsWrapper resourcesHasDocuments={resourcesHasDocuments}>
              {data
                .filter(({ id }) => {
                  // There are some buttons passed to this component with all the info needed to display it.
                  // Here we check based on the filtered options if we should show this button, the check is done on the id of the button
                  let shouldBeEnabled = false;
                  fileteredMenuOptions.forEach((option) => {
                    // need precise comparison to distinguish a "units" from  a "unit"
                    if (id.replace('Button', '') === option) {
                      shouldBeEnabled = true;
                    }
                  });

                  return shouldBeEnabled;
                })
                .map((button) => (
                  <Button
                    id={button.id}
                    key={button.id}
                    active={button.active}
                    onClick={button.callback}
                    minified={isMobileOnly ? 1 : 0}
                  >
                    <ButtonIcon
                      icon={button.icon}
                      size={button.iconSize}
                      minified={isMobile ? 1 : 0}
                    />
                    <ButtonText
                      initial={{ width: '0px' }}
                      animate={{ width: 'min-content' }}
                      transition={{ delay: 0.3, duration: 0.5 }}
                    >
                      {button.text}
                    </ButtonText>
                  </Button>
                ))}
            </ButtonsWrapper>
          </MainMenu>
        </MainMenuWrapper>

        <SubMenu
          initial={{ opacity: 0.0 }}
          animate={
            !!subMenu
              ? { opacity: 1.0, width: 'auto' }
              : { opacity: 0, width: 0 }
          }
          transition={{ duration: 0.5 }}
          isActive={!!subMenu}
          embedded={isShowcaseEmbedded}
        >
          {subMenu &&
            subMenu.map((item, index) => (
              <SubMenuItem
                key={index}
                active={item.active}
                onClick={item.onClick}
              >
                {item.name}
              </SubMenuItem>
            ))}
        </SubMenu>

        {!isMobile && (
          <RightSideMenu>
            <Divider />
            {!showcaseConfiguration?.hideFavouriteOptions && (
              <ToggleShowFavoritesButton />
            )}
            <Tippy
              offset={[0, -10]}
              theme="favoritesToggler"
              placement="bottom"
              content={<div>{capitalize(localizer.share)}</div>}
              arrow={false}
              touch={false}
            >
              <ShareButton onClick={onShareClick}>
                <ShareIcon icon={['fal', 'share']} size="1x" />
              </ShareButton>
            </Tippy>

            <FullscreenButtonWrapper animate={isEmbeddedShowcase}>
              <Tippy
                offset={[0, -10]}
                theme="favoritesToggler"
                placement="bottom"
                content={<div>{localizer.fullscreen}</div>}
                arrow={false}
                touch={false}
              >
                <div>
                  <FullscreenButton
                    wrapperStyle={`
                    width: 50px;
                    height: 50px;
                    padding: 0;
                  `}
                  />
                </div>
              </Tippy>
            </FullscreenButtonWrapper>
          </RightSideMenu>
        )}
      </MenuBar>
      <FloatingButtons
        bottom={
          navigationFloatingButtonsBottomPosition -
          (isMobileOnly && isLandscape ? 60 : 0)
        }
      >
        <ContactUsFloatingButton />
        {isMobile && favoritesList.length > 0 && (
          <>
            <VerticalGap />
            <ToggleShowFavoritesButton />
          </>
        )}
      </FloatingButtons>
    </>
  );
};

NavigationMenu.propTypes = {
  data: array,
  onShareClick: func
};

NavigationMenu.defaultProps = {
  data: [],
  onShareClick: () => {}
};

export default NavigationMenu;
