import React, { useReducer, createContext, useContext, useRef } from 'react';

import { clamp } from '@prompto-helpers';
import { useMotionValue } from 'framer-motion';

import navigationCategories from 'configs/NavigationCategories.json';
import fileCategories from 'configs/SupportedFileCategories.json';
import navigationFilters from 'configs/NavigationFilters.json';

export const Context = createContext();
const navigationWidth = 360;

const scrollToRef = (gridRef, offset, dragOffset) => {
  const gridWidth = gridRef.current?.offsetWidth;
  const maxXOffset = gridWidth > 0 ? -(gridWidth - window.innerWidth) : 0;
  const newOffset = clamp(dragOffset + offset, maxXOffset, 0);
  return newOffset;
};

const SwitcherStore = (props) => {
  const [SwitcherState, SwitcherStateDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'setPages':
          return {
            ...state,
            pages: action.payload
          };
        case 'setShowGrid':
          return {
            ...state,
            showGrid: action.payload
          };
        case 'setActivePage':
          const newActivePage = state.pages[action.payload];
          let newCategory = state.activeCategory;
          if (newActivePage.contentItemType === fileCategories.document) {
            newCategory = navigationCategories.documents;
          } else {
            newCategory = navigationCategories.media;
          }

          return {
            ...state,
            activePage: action.payload,
            activeCategory: newCategory
          };
        case 'setGridItemClicked':
          return { ...state, gridItemClicked: action.payload };

        case 'setActiveFilter':
          state.dragXOffset.set(-10);
          return { ...state, activeFilter: action.payload };
        case 'setOrderedCollection':
          return {
            ...state,
            ...action.payload
          };
        case 'setActiveFolder':
          return {
            ...state,
            activeFolder: action.payload
          };
        case 'setActiveFolderRef':
          return {
            ...state,
            activeFolderRef: state.foldersRefs[action.payload]
          };
        case 'scrollToActiveFolder':
          let dragXOffsetValue = 0;
          if (state.activeFolder !== action.payload.uuid) {
            if (action.payload.folderIndex === 0) {
              dragXOffsetValue = 0;
            } else {
              const folderBounds =
                action.payload.ref.current?.getBoundingClientRect() ?? {
                  left: 0
                };
              const newOffset = scrollToRef(
                state.masonryGrid,
                -folderBounds.left + navigationWidth,
                action.payload.currentOffset
              );
              dragXOffsetValue = newOffset;
            }
          }
          return {
            ...state,
            dragXOffsetValue,
            activeFolder: action.payload.uuid
          };
        case 'toggleOnUpdateEvent':
          return {
            ...state,
            enableOnUpdateEvent: action.payload
          };
        case 'setDragOffsetValue':
          return {
            ...state,
            dragXOffsetValue: action.payload
          };
        default:
          return state;
      }
    },
    {
      pages: [],
      activePage: 0,
      gridItemClicked: false,
      showGrid: true,
      activeCategory: navigationCategories.media,
      activeFilter: navigationFilters.all,
      masonryGrid: useRef(),
      mediaMasonryGrid: useRef(),
      documentMasonryGrid: useRef(),
      verticalGrid: useRef(),
      documentVerticalGrid: useRef(),
      dragXOffsetValue: 0,
      dragXOffset: useMotionValue(0),
      orderedContentCollection: null,
      folders: [],
      showFolders: false,
      activeFolder: '',
      foldersRefs: null,
      enableOnUpdateEvent: false
    }
  );

  return (
    <Context.Provider value={{ SwitcherState, SwitcherStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useSwitcherState = () => useContext(Context);

export default SwitcherStore;
