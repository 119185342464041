import * as React from 'react';

function TurntableLoader(props) {
  const { progress, color, autoanimate } = props;
  const [localProgress, setLocalProgress] = React.useState(progress);

  const progressInterval = React.useRef();

  React.useEffect(() => {
    setLocalProgress(progress);
  }, [progress]);

  React.useEffect(() => {
    if (autoanimate) {
      let i = 0;
      progressInterval.current = setInterval(() => {
        if (autoanimate) {
          i++;
          setLocalProgress(i % 100);
        }
      }, 20);
    } else {
      clearInterval(progressInterval.current);
    }
  }, [autoanimate]);

  return (
    <svg width={48} height={48} xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: color, stopOpacity: 1 }} />
          <stop
            offset={`${localProgress}%`}
            style={{ stopColor: color, stopOpacity: 1 }}
          />
          <stop
            offset={`${localProgress}%`}
            style={{ stopColor: 'transparent', stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: 'transparent', stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.5 21L7 44.5H20.5L21 34.5H27.5V45H41L41.5 21L24.5 5.5L6.5 21Z"
          fill="url(#gradient)"
        />
        <path
          d="M6.8913 20.2174V45H20.913V34.8913H27.4348V45H41.4565V20.2174L47 25.1087L24.5 5.54348L10.4783 17.2826M2 25.1087L10.4783 17.2826M10.4783 17.2826V11.087M10.4783 8.80435C10.4783 6.68478 12.5978 5.54348 12.5978 5.54348C12.5978 5.54348 16.0217 5.05435 16.0217 0"
          stroke={color}
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export default TurntableLoader;
