import * as React from 'react';

function SvgSmallSizeIcon(props) {
  return (
    <svg viewBox="0 0 12 9" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.125 0h9.75C11.485 0 12 .516 12 1.125v6.75C12 8.508 11.484 9 10.875 9h-9.75A1.11 1.11 0 010 7.875v-6.75C0 .515.492 0 1.125 0zm9.75 8.25a.385.385 0 00.375-.375v-6.75a.403.403 0 00-.375-.375h-9.75a.385.385 0 00-.375.375V4.75h2.625a.38.38 0 01.375.375V8.25h7.125z"
      />
    </svg>
  );
}

export default SvgSmallSizeIcon;
