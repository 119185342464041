import React, { useState, useRef, useEffect, useCallback } from 'react';
import { array, bool, func, string } from 'prop-types';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Components
import { Virtuoso } from 'react-virtuoso';
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnitListCard from './UnitListMobileCard';
import SortingIcon from 'resources/svgs/SortingIcon.js';
import MobileSortingPopup from './MobileSortingPopup';
import MobileUnitFilterModal from 'components/share/MobileUnitFilterModal';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  background-color: white;
`;

const FilterButtonWrapper = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.primary300};
  position: absolute;
  z-index: 10;
  left: 10px;
  bottom: 10px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.showcaseWhite};
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 5%);
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const ActiveFilterMarker = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.theme.successColor};
`;

const ProjectTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 24px;
`;

const ProjectTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 30px;
  margin-right: 4px;
  white-space: nowrap;
`;

const UnitListWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.grayWhiteOff};
`;

const SortingWrapper = styled.div`
  position: relative;
`;

const UnitsCounter = styled.span`
  color: ${(props) => props.theme.primary100};
  font-size: 1.5rem;
`;

/**
 * Virtuoso is a package to virtualize a list, meaning to render large lists without rendering all the items
 * You can find more information here: https://virtuoso.dev/
 */
const ListVirtualized = styled(Virtuoso)`
  z-index: 10;
  height: 100%;
`;

const UnitsMobilePage = ({
  units,
  onOpenUnitPage,
  unitSortableFields,
  currentFieldToSort,
  isSortAscending,
  onSortByChanged,
  searchValue,
  setSearchValue,
  setFilterValues,
  fallbackContent,
  showContactUsCard
}) => {
  // Project state
  const { ProjectState } = useProjectState();
  const { unitFilters, showcaseConfiguration } = ProjectState;

  // UI state
  const { UiState } = useUiState();
  const { showFavorites } = UiState;

  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  const [showSorting, setShowSorting] = useState(false);
  const sortRef = useRef(null);
  const sortingPopupRef = useRef(null);

  // showContactUsCard is true if favorite units are shown and ContactUsForm is set to be shown as generic one
  // filter and sorting keep using units without Contact Us card
  const unitsForList = showContactUsCard
    ? [{ type: 'contact-us-card' }, ...units]
    : units;

  useEffect(() => {
    setFilterValues(unitFilters);
  }, [unitFilters, setFilterValues]);

  const checkOutsideClick = useCallback(
    (event) => {
      if (!sortingPopupRef.current?.contains(event.target)) {
        setShowSorting(false);
      }
      if (sortRef.current?.contains(event.target) && !showSorting) {
        setShowSorting(true);
      }
    },
    [sortingPopupRef, showSorting, sortRef]
  );

  useEffect(() => {
    window.addEventListener('touchstart', checkOutsideClick);
    return () => window.removeEventListener('touchstart', checkOutsideClick);
  }, [checkOutsideClick]);

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MobileLayoutHeader
        rightAction={
          <SortingWrapper>
            <SortingIcon
              color={showSorting ? '#9f9caa' : '#574f6b'}
              ref={sortRef}
            />
            {showSorting && (
              <MobileSortingPopup
                unitSortableFields={unitSortableFields}
                currentFieldToSort={currentFieldToSort}
                isSortAscending={isSortAscending}
                onSortByChanged={onSortByChanged}
                ref={sortingPopupRef}
                onClose={() => setShowSorting(false)}
              />
            )}
          </SortingWrapper>
        }
      >
        <ProjectTitleWrapper>
          <ProjectTitle>
            {showFavorites
              ? capitalize(localizer.favorites)
              : capitalize(localizer.units)}{' '}
            {units?.length > 0 && <UnitsCounter>({units.length})</UnitsCounter>}
          </ProjectTitle>
        </ProjectTitleWrapper>
      </MobileLayoutHeader>
      <UnitListWrapper>
        {units && (
          <ListVirtualized
            data={unitsForList}
            itemContent={(index, cardData) => {
              return (
                <UnitListCard
                  key={cardData.objectId}
                  index={index}
                  last={index + 1 === unitsForList?.length}
                  unit={cardData}
                  onOpenUnitPage={onOpenUnitPage}
                  fallbackContent={fallbackContent}
                />
              );
            }}
          />
        )}
      </UnitListWrapper>

      {!isMobileFilterVisible && (
        <FilterButtonWrapper
          filterIsVisible={isMobileFilterVisible}
          isHidden={!!showcaseConfiguration?.hideFilterOptions}
          onTouchEnd={(e) => {
            setTimeout(() => setMobileFilterVisible(true), 100);
          }}
        >
          <FontAwesomeIcon icon={['fal', 'filter']} size="2x" />
          {(unitFilters?.length > 0 || searchValue) && <ActiveFilterMarker />}
        </FilterButtonWrapper>
      )}

      {isMobileFilterVisible && (
        <MobileUnitFilterModal
          amountOfUnitsInFilter={units.length}
          onCloseModalRequest={() => setMobileFilterVisible(false)}
          onSearchChanged={setSearchValue}
          searchValue={searchValue}
        />
      )}
    </Wrapper>
  );
};

UnitsMobilePage.propTypes = {
  units: array.isRequired,
  onOpenUnitPage: func.isRequired,
  unitSortableFields: array.isRequired,
  currentFieldToSort: string,
  isSortAscending: bool,
  onSortByChanged: func.isRequired,
  setSearchValue: func.isRequired,
  setFilterValues: func.isRequired,
  searchValue: string
};

UnitsMobilePage.defaultProps = {
  units: [],
  currentFieldToSort: '',
  isSortAscending: false,
  searchValue: ''
};

export default UnitsMobilePage;
