import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import UnitStateBadge from 'components/unit/UnitStateBadge';
import ContactUs from 'components/share/ContactUs/ContactUs';
import FavoriteUnitButton from 'components/share/FavoriteUnitButton';
import ContactUsCard from './ContactUsCard';

// Helpers
import currencySymbolMap from 'currency-symbol-map';
import {
  unitStatesNotArchived,
  shouldShowUnitPrice
} from 'helpers/units/VmUnitHelper';
import localizer from 'localization/localizer';
import { motion, AnimatePresence } from 'framer-motion';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import {
  isValidUrl,
  getAbsoluteUrl,
  capitalize,
  getFieldIcon,
  displayLocalizedValue,
  getUnitPrice
} from 'helpers';
import {
  useGetUnitImagePreview,
  useShowUnitGeneralFields,
  useGetUnitCustomFields
} from 'helpers/customHooks';
import { isMobileOnly } from 'react-device-detect';

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const UnitCardWrapper = styled(motion.div)`
  z-index: 10;
  padding: 23px 15px 0;
  padding-bottom: ${(props) => (props.last ? '10px' : '0')};
`;

const UnitCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray300};
  background-color: ${(props) => props.theme.whitePure};
`;

const UnitInfo = styled.div`
  font-size: 14px;
  color: black;
`;

const UnitTitle = styled(UnitInfo)`
  font-size: 1.5rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  white-space: nowrap;
`;

const UnitPriceStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnitPrice = styled(UnitInfo)`
  font-weight: 500;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
  display: flex;
  align-items: center;
`;

const Price = styled(UnitInfo)`
  font-weight: 700;
  padding-left: 5px;
  color: inherit;
`;

const UnitMetaInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: ${(props) => props.flexBasis}%;
`;

const UnitMetaInfo = styled.div`
  display: flex;
  flex-grow: 1;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const UnitMetaIcon = styled(FontAwesomeIcon)`
  transform: rotate(0.03deg);
  font-size: 1rem;
  color: ${(props) => props.theme.showcaseBlack};
  opacity: 0.5;
  margin-right: 14px;
  flex-shrink: 0;
`;

const UnitStateTag = styled(UnitStateBadge)`
  position: absolute;
  right: 15px;
  top: -13px;
  font-size: 0.75rem;
  color: ${(props) => props.theme.showcaseWhite};
  border-radius: 20px;
  min-width: 5rem;
  height: 26px;
`;

const Divider = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${(props) => props.theme.gray200};
`;

const HDivider = styled(Divider)`
  width: 100%;
  height: 1px;
`;

const BlockWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
`;

const MainInfoBlockWrapper = styled(BlockWrapper)`
  align-items: center;
  padding: 24px 20px;
`;

const GeneralFieldsBlockWrapper = styled(BlockWrapper)`
  justify-content: center;
`;

const CustomFieldsBlockWrapper = styled(BlockWrapper)`
  flex-direction: ${isMobileOnly ? 'column' : 'row'};
  ${!isMobileOnly &&
  `
    flex-wrap: wrap;
  `}
`;

const NoPreviewPlaceholder = styled.div`
  width: 80px;
  height: 80px;
  padding: 8px;
  box-sizing: border-box;
  margin-right: 15px;
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  font-size: 0.75rem;
  color: ${(props) => props.theme.fadeGray};
`;

const PreviewImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 2px;
  object-fit: cover;
  object-position: center;
`;

const UnitMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${(props) => (props.withImagePreview ? 140 : 60)}px);
`;

const UnitTypeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnitType = styled.div`
  height: 20px
  font-size: 14px;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 5px;
  color: ${(props) => props.theme.primary100};
`;

const StyledUnitTypeIcon = styled(StyledPriceIcon)``;
const StyledCustomFieldIcon = styled(StyledPriceIcon)`
  margin-top: 3px;
`;

const Toast = styled(motion.div)`
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.warning};
  border-radius: 17px;
  width: fit-content;
  max-width: 90vw;
  height: 34px;
  padding: 0 25px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) => props.theme.whitePure};
`;

const ToastIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const CustomFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${(props) => (props.first ? '0' : '12px')};
  word-break: break-word;
  flex-basis: ${(props) => props.flexBasis}%;
  ${!isMobileOnly &&
  `
    margin-top: 0;
  `}
`;

const StyledCustomFieldValue = styled.p`
  margin: 0;
  padding: 0 10px 5px 0;
  box-sizing: border-box;
  word-break: break-all;
`;

const DividerWithMargin = styled.div`
  width: 1px;
  margin-right: 20px;
  height: 100%;
  background-color: ${(props) => props.theme.gray200};
`;

const UnitListMobileCard = ({
  index,
  unit,
  onOpenUnitPage,
  last,
  fallbackContent
}) => {
  const [customFields, setCustomFields] = useState([]);

  // Auth state
  const { AuthState } = useAuthState();

  // Project state
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration, vault } = ProjectState;
  const {
    embededUnitFormConfiguration,
    showUnitContactUsButton,
    showUnitCustomFields,
    showUnitPreviewImage,
    showUnitExternalLink,
    hideFavouriteOptions
  } = showcaseConfiguration;

  const [isInvalidUrlErrorShown, setInvalidUrlErrorShown] = useState(false);

  const { previewImageUrl } = useGetUnitImagePreview(
    unit,
    fallbackContent,
    `h=80`
  );
  const { showPrice, showSurface, showBedrooms, showBathrooms } =
    useShowUnitGeneralFields(false, null, [unit]);

  const toggleErrorMessage = useCallback(() => {
    setInvalidUrlErrorShown(true);
    const timer = setTimeout(() => {
      setInvalidUrlErrorShown(false);
      clearTimeout(timer);
    }, 3000);
  }, []);

  const showUnitPrice = shouldShowUnitPrice(
    ProjectState?.showcaseConfiguration,
    AuthState,
    unit?.state
  );

  const showUnitState = showcaseConfiguration?.showUnitState ?? true;

  const showContactUsButton =
    showUnitContactUsButton &&
    embededUnitFormConfiguration?.showContactUsFormAsGeneric !== true;

  const currencySymbol = currencySymbolMap(
    vault?.vaultSettings?.defaultCurrencyCode || 'EUR'
  );
  let unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;

  const handleUnitClick = useCallback(
    (unitItem) => {
      if (showUnitExternalLink) {
        if (!unitItem?.unitMetadata?.externalLink) return;
        if (['IN_OPTION', 'SOLD'].includes(unitItem?.state)) return;
        if (isValidUrl(unitItem.unitMetadata.externalLink)) {
          window.open(getAbsoluteUrl(unitItem.unitMetadata.externalLink));
        } else {
          toggleErrorMessage();
        }
      } else {
        onOpenUnitPage(unitItem);
      }
    },
    [showUnitExternalLink, onOpenUnitPage, toggleErrorMessage]
  );

  const renderImagePreviewOrPlaceholder = useCallback(() => {
    if (previewImageUrl) {
      return <PreviewImage src={previewImageUrl} />;
    }
    return (
      <NoPreviewPlaceholder>
        {capitalize(localizer.noAttachmentForUnit)}
      </NoPreviewPlaceholder>
    );
  }, [previewImageUrl]);

  const renderGeneralFields = () => {
    const metaInfo = [];
    if (showSurface) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'surface', 'fal'),
        info: `${unit.unitMetadata?.surface} m²`
      });
    }
    if (showBedrooms) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBedrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBedrooms
      });
    }
    if (showBathrooms) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBathrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBathrooms
      });
    }
    const flexBasis = 100 / metaInfo.length;
    return metaInfo.map((item, idx) => {
      const needDivider = metaInfo.length > 1 && idx !== 0;
      return (
        <UnitMetaInfoWrapper key={idx} flexBasis={flexBasis}>
          {needDivider && <Divider />}
          <UnitMetaInfo>
            {!!item.icon && <UnitMetaIcon icon={item.icon} size="1x" />}
            <UnitInfo>{item.info}</UnitInfo>
          </UnitMetaInfo>
        </UnitMetaInfoWrapper>
      );
    });
  };

  const { customFields: unitCustomFields } = useGetUnitCustomFields(unit);

  useEffect(() => {
    if (unitCustomFields) {
      const filteredFields =
        unitCustomFields?.filter((field) => field.isVisibleInUnitList) ?? [];
      setCustomFields(filteredFields);
    }
  }, [unitCustomFields]);

  const renderCustomFields = useCallback(() => {
    let flexBasis = 100;
    if (unit?.customFieldValues?.length === 2) {
      flexBasis = 50;
    } else if (unit?.customFieldValues?.length > 2) {
      flexBasis = 33.3;
    }
    return customFields?.map((field, idx) => {
      const needDivider =
        !!(customFields?.length === 2 && idx !== 0) ||
        !!(customFields?.length > 2 && idx % 3);
      return (
        <CustomFieldWrapper key={idx} first={idx === 0} flexBasis={flexBasis}>
          {needDivider && <DividerWithMargin />}
          <StyledCustomFieldIcon icon={['far', field.icon]} size="1x" />
          <StyledCustomFieldValue>
            {capitalize(field.title)}: {field.value}
          </StyledCustomFieldValue>
        </CustomFieldWrapper>
      );
    });
  }, [customFields, unit]);

  if (unit.type === 'contact-us-card') {
    return <ContactUsCard />;
  }

  return (
    <>
      <UnitCardWrapper
        key={index}
        onClick={() => {
          handleUnitClick(unit);
        }}
        last={last}
      >
        <UnitCard>
          {showUnitState && (
            <UnitStateTag unitState={unit?.state} unitFlow={unit?.unitFlow} />
          )}

          {/* Description */}
          <MainInfoBlockWrapper>
            {showUnitPreviewImage && renderImagePreviewOrPlaceholder()}
            <UnitMainInfo
              withImagePreview={showcaseConfiguration?.showUnitPreviewImage}
            >
              <UnitTitle>{unit.title}</UnitTitle>
              <UnitTypeAndPrice>
                {unit?.unitType && (
                  <UnitType>
                    {unit?.unitType?.icon && (
                      <StyledUnitTypeIcon
                        icon={['far', unit.unitType.icon]}
                        size="1x"
                      />
                    )}
                    {displayLocalizedValue(unit?.unitType?.name?.textMap)}
                  </UnitType>
                )}
                {showPrice && (
                  <UnitPriceStatus>
                    <UnitPrice>
                      <StyledPriceIcon
                        icon={getFieldIcon(
                          showcaseConfiguration,
                          'price',
                          'far'
                        )}
                        size="1x"
                      />
                      <Price>
                        {showUnitPrice ? unitPrice : localizer.onRequest}
                      </Price>
                    </UnitPrice>
                  </UnitPriceStatus>
                )}
              </UnitTypeAndPrice>
            </UnitMainInfo>
            {!hideFavouriteOptions && (
              <FavoriteUnitButton
                unit={unit}
                buttonSize={36}
                wrapperStyles={`margin-left: auto`}
              />
            )}
          </MainInfoBlockWrapper>

          {/* General fields */}
          {(showSurface || showBedrooms || showBathrooms) && (
            <>
              <HDivider />
              <GeneralFieldsBlockWrapper>
                {renderGeneralFields()}
              </GeneralFieldsBlockWrapper>
            </>
          )}

          {/* Custom fields */}
          {showUnitCustomFields &&
            unit?.customFieldValues &&
            customFields?.length > 0 && (
              <>
                <HDivider />
                <CustomFieldsBlockWrapper>
                  {isMobileOnly
                    ? customFields?.map((field, idx) => (
                        <CustomFieldWrapper key={idx} first={idx === 0}>
                          <StyledCustomFieldIcon
                            icon={['far', field.icon]}
                            size="1x"
                          />
                          {capitalize(field.title)}: {field.value}
                        </CustomFieldWrapper>
                      ))
                    : renderCustomFields()}
                </CustomFieldsBlockWrapper>
              </>
            )}

          {/* Contact us */}
          {showContactUsButton && (
            <>
              <HDivider />
              <BlockWrapper>
                <ContactUs unit={unit} location="Units list (mobile)" />
              </BlockWrapper>
            </>
          )}
        </UnitCard>
      </UnitCardWrapper>
      <AnimatePresence>
        {isInvalidUrlErrorShown && (
          <Toast
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ToastIcon icon={['far', 'ban']} size="sm" />
            {localizer.wrongUrlWarning}
          </Toast>
        )}
      </AnimatePresence>
    </>
  );
};

UnitListMobileCard.propTypes = {
  /** vmUnit object that will get its information displayed in this card */
  unit: PropTypes.shape({
    title: PropTypes.string,
    unitPrice: PropTypes.shape({
      price: PropTypes.number
    }),
    state: PropTypes.oneOf(unitStatesNotArchived),
    unitMetadata: PropTypes.shape({
      numberOfBedrooms: PropTypes.number,
      numberOfBathrooms: PropTypes.number
    })
  }).isRequired
};

export default UnitListMobileCard;
