import React, { useEffect, useState, useCallback } from 'react';

import { useUiState } from 'stores/UiStore';
import { useProjectState } from 'stores/ProjectStore';
import Turntable from './components/Turntable';
import Instruction from './components/Instruction';

// Helpers
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getCurrentEnvironment } from 'helpers';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';

// Styling
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 100%;
  background-color: white;
  position: relative;
`;

const cookies = new Cookies();
const hideTurntableInstructionCookie = `prompto/${
  getCurrentEnvironment().env
}/hideTurntableInstruction`;

const TurntablePage = ({ onOpenUnitPage }) => {
  const navigate = useNavigate();

  const { UiStateDispatch } = useUiState();
  const { ProjectState } = useProjectState();
  const { project, showcaseConfiguration, turntable, shareCode } = ProjectState;

  const [turntableImages, setTurntableImages] = useState([]);

  const [unitList, setUnitList] = useState([]);

  const [showInstruction, setShowInstruction] = useState(false);
  const [hasHideInstructionCookie, setHasHideInstructionCookie] =
    useState(false);

  useEffect(() => {
    const hideInstruction = cookies.get(hideTurntableInstructionCookie);
    if (hideInstruction) {
      setHasHideInstructionCookie(true);
      setShowInstruction(false);
    }
  }, []);

  const { isLandscape } = useMobileOrientation();

  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: {
        showMenu: !isMobileOnly || (isMobileOnly && !isLandscape)
      }
    });
  }, [UiStateDispatch, isLandscape]);

  useEffect(() => {
    if (turntable === 'notPresent') {
      navigate(`/${shareCode}`);
    } else if (turntable) {
      // Check first for list of POV's
      if (turntable.turnPointOfViewList) {
        const allImages = [];
        turntable.turnPointOfViewList.forEach((turnPoint) => {
          allImages.push(turnPoint?.contentItem?.contentUri);
        });

        setTurntableImages(allImages);
      }
    }
  }, [turntable, navigate, shareCode]);

  useEffect(() => {
    if (project) {
      setUnitList(project.unitList);
    }
  }, [project]);

  const onTurntableLoaded = useCallback(() => {
    if (!hasHideInstructionCookie) {
      setShowInstruction(true);
    }
  }, [hasHideInstructionCookie]);

  return (
    <Wrapper>
      <Turntable
        turntable={turntable}
        images={turntableImages}
        units={unitList}
        onOpenUnitPage={onOpenUnitPage}
        showcaseConfiguration={showcaseConfiguration}
        onLoaded={onTurntableLoaded}
      />
      <AnimatePresence>
        {showInstruction && (
          <Instruction
            removeInstruction={() => {
              setShowInstruction(false);
              const date = new Date();
              date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 7); // one week in milliseconds
              cookies.set(hideTurntableInstructionCookie, 'false', {
                expires: date,
                sameSite: 'none',
                secure: true
              });
            }}
          />
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default TurntablePage;
