import React, { useState, useCallback } from 'react';

// Components
import UspSpotTooltip from './uspSpot/UspSpotTooltip';
import { motion } from 'framer-motion';

// Helpers
import { isMobile } from 'react-device-detect';
import { useUiState } from 'stores/UiStore';
import zIndices from 'configs/TourImageZIndices.json';

// Styling
import styled, { css } from 'styled-components';

const Wrapper = styled(motion.div)`
  z-index: ${zIndices.cornerPinnedUsp};
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    z-index: ${zIndices.cornerPinnedUspHovered};
  }
  ${({ position, offsets, doIndent, zoomScale }) => {
    let additionalIndent = 0;
    switch (position) {
      case 'TOP_LEFT':
        additionalIndent = doIndent ? 60 / zoomScale : 0; // 60 - height of back button + top indentation to it
        return css`
          position: absolute;
          top: ${offsets.top + additionalIndent}px;
          left: ${offsets.left}px;
          transform-origin: top left;
        `;
      case 'TOP_RIGHT':
        return css`
          position: absolute;
          top: ${offsets.top}px;
          right: ${offsets.right}px;
          transform-origin: top right;
        `;
      case 'BOTTOM_LEFT':
        return css`
          position: absolute;
          bottom: ${offsets.bottom}px;
          left: ${offsets.left}px;
          transform-origin: bottom left;
        `;
      case 'BOTTOM_RIGHT':
        additionalIndent = 97 / zoomScale; // 97px - width of zoom controls + right indent to it
        return css`
          position: absolute;
          bottom: ${offsets.bottom}px;
          right: ${offsets.right + additionalIndent}px;
          transform-origin: bottom right;
        `;
      default:
        return ``;
    }
  }}
`;

const PinnedSpot = ({
  spot,
  moveForward,
  offsets,
  zoomScale,
  enlargeUsp,
  showcaseConfiguration
}) => {
  const [enlargedUspData, setEnlargedUspData] = useState({});

  // UI state
  const { UiState } = useUiState();
  const { enableShowcaseBackButton } = UiState;

  const canBeEnlarged =
    spot?.usp?.uspVisibilitySettings?.showMedia !== false &&
    !(isMobile || !showcaseConfiguration) &&
    ['image', 'video'].includes(enlargedUspData?.mediaType);

  const onEnlargeUsp = useCallback(
    (event) => {
      if (!canBeEnlarged) return;
      enlargeUsp(event, spot?.usp?.objectId, enlargedUspData);
    },
    [enlargedUspData, spot, canBeEnlarged, enlargeUsp]
  );

  let content = null;
  switch (spot.navigationItemType) {
    case 'uspSpot':
      content = (
        <UspSpotTooltip
          spotObject={spot}
          useInheritPosition={true}
          isPinned={true}
          enlargeUsp={onEnlargeUsp}
          setEnlargedUspData={setEnlargedUspData}
        />
      );
      break;
    default:
  }
  return (
    <Wrapper
      animate={{ scale: 1 / zoomScale }}
      transition={{ delay: 0, duration: 0 }}
      position={spot?.pinConfiguration?.position}
      doIndent={enableShowcaseBackButton} // if back button enabled - do additional indentation
      zoomScale={zoomScale}
      onClick={moveForward}
      offsets={offsets}
    >
      {content}
    </Wrapper>
  );
};

export default PinnedSpot;
