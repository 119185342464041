import React, { Fragment, useRef, useState, useEffect } from 'react';
import { shape, array, func } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence } from 'framer-motion';

// Helpers
import settings from './PortfolioSettings.json';
import { usePrevious } from '@prompto-helpers';
import { isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';

// Styling
import styled, { css } from 'styled-components';

const mobileSpecificStyles = {
  wrapper: css`
    position: fixed;
    z-index: 3;
    top: 70px;
    left: 10px;
    right: 10px;
    background-color: transparent;
    padding: 0;
    border: none;
  `,
  menu: css`
    top: 0;
  `,
  sortOption: css`
    padding: 10px;
  `
};

const Wrapper = styled.div`
  position: relative;
  min-width: 200px;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  box-sizing: border-box;
  border-radius: 25px;
  border: solid 1px ${({ theme }) => theme.gray200};
  background-color: rgba(
    255,
    255,
    255,
    ${({ theme }) => theme.controlsOpacity}
  );
  backdrop-filter: blur(5px);
  font-size: 0.875rem;
  color: ${({ theme }) => theme.promary100};
  cursor: pointer;
  ${isMobileOnly && mobileSpecificStyles.wrapper}
`;

const SelectedOption = styled.div`
  color: ${({ theme }) => theme.showcaseBlack};
  font-weight: 500;
  margin: 0 auto 0 10px;
`;

const Menu = styled(motion.div)`
  position: absolute;
  z-index: 1;
  left: -1px;
  right: -1px;
  top: calc(100% + 2px);
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: solid 1px ${({ theme }) => theme.gray200};
  border-radius: 25px;
  display: flex;
  flex-flow: column;
  transform-origin: top;
  overflow: hidden;
  box-shadow: 1px 6px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  ${isMobileOnly && mobileSpecificStyles.menu}
`;

const SortOption = styled.p`
  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  color: ${({ theme, selected }) =>
    selected ? theme.showcaseBlack : theme.primary300};
  cursor: pointer;
  user-select: none;
  margin: 0;
  padding: 5px 20px;
  padding-right: 30px;
  &:hover {
    color: ${({ theme }) => theme.accentAlt700};
    background: ${({ theme }) => theme.gray100};
  }
  ${isMobileOnly && mobileSpecificStyles.sortOption}
`;

const SelectedOptionContainer = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.primary100};
`;

const OptionGroup = styled.div`
  margin: 0;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const PortfolioSort = ({ selectedSortOption, onSortChanged, vaultId }) => {
  const [sortOptionsOpened, setSortOptionsOpened] = useState(isMobileOnly);

  const { sortOptions } = settings;

  const previousSortOption = usePrevious(selectedSortOption);

  const onSortOptionClick = (sortOption) => {
    setSortOptionsOpened(false);
    if (sortOption !== previousSortOption) {
      onSortChanged(sortOption);
    }
  };

  const sortOptionsComp = sortOptions.map((group) => {
    const options = group.value.map((sortOption) => (
      <SortOption
        key={sortOption.name}
        selected={sortOption.name === selectedSortOption?.name}
        onClick={(e) => {
          if (isMobileOnly) return;
          onSortOptionClick(sortOption);
        }}
        onTouchEnd={() => onSortOptionClick(sortOption)}
      >
        {sortOption.name}
      </SortOption>
    ));

    return <OptionGroup key={group.key}>{options}</OptionGroup>;
  });

  const sortRef = useRef();

  useEffect(() => {
    if (!sortOptionsOpened || isMobileOnly) return;
    const handleOutsideClick = ({ target }) => {
      if (sortRef.current && !sortRef.current.contains(target)) {
        setSortOptionsOpened(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [sortOptionsOpened]);

  return (
    <Fragment>
      <Wrapper>
        {!isMobileOnly && (
          <SelectedOptionContainer onClick={() => setSortOptionsOpened(true)}>
            {localizer.marketplace.sortProjectsBy}:
            <SelectedOption>{selectedSortOption.name}</SelectedOption>
            <FontAwesomeIcon icon={['fas', 'caret-down']} size="lg" />
          </SelectedOptionContainer>
        )}
        <AnimatePresence>
          {sortOptionsOpened && (
            <Menu
              ref={sortRef}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {sortOptionsComp}
            </Menu>
          )}
        </AnimatePresence>
      </Wrapper>
    </Fragment>
  );
};

PortfolioSort.propTypes = {
  selectedSortOption: shape({}),
  sortOptions: array,
  onSortChanged: func
};

PortfolioSort.defaultProps = {
  selectedSortOption: null,
  sortOptions: [],
  onSortChanged: () => {}
};

export default PortfolioSort;
