import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputField } from 'components/ui';
import DatePicker from 'react-datepicker';
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import ConfirmationModal from 'components/other/ConfirmationModal';

// Helpers
import { useAuthState } from 'stores/AuthStore';
import localizer from 'localization/localizer';
import emailValidator from 'email-validator';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';
import { fetchSettingsFromURL } from 'helpers';
import { ShareItem, PortalSettings } from '@prompto-api';

// Styling
import styled from 'styled-components';

const Settings = styled.section`
  position: relative;
  width: 100%;
  min-height: 60px;
  border-top: 1px solid ${({ theme }) => theme.gray200};
  border-bottom: 1px solid ${({ theme }) => theme.gray200};
  padding: ${isMobileOnly ? '20px 15px' : '20px 40px'};
  margin: 0;
  margin-top: 30px;
  box-sizing: border-box;
`;

const SettingsHeader = styled.h4`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal;
  color: ${({ theme }) => theme.showcaseBlack};
  margin: 0;
  margin-bottom: 10px;
`;

const StyledInputField = styled(InputField)`
  input {
    background-color: ${({ theme }) => theme.showcaseWhite};
    font-size: 0.875rem;
    font-family: ${({ theme }) => theme.mainFontFamily};
    color: ${({ theme }) => theme.showcaseBlack};
    padding: 8px 10px;
    border-color: ${({ theme, error }) =>
      error ? theme.errorColor : theme.gray200};
    margin: 0;
    transition: all 150ms ease;
    &::placeholder {
      color: ${({ theme }) => theme.primary100};
      font-size: 0.875rem;
    }
  }
  ${({ styles }) => styles}
`;

const DatePickerWrapper = styled.div`
  position: relative;
  z-index: 1;

  border-radius: 2px;
  border: ${({ theme }) => `1px solid ${theme.gray200}`};
  padding: 0 0 0 10px;
  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) => props.theme.primary100};
  font-size: 0.875rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.mainFontFamily};
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  ${({ styles }) => styles}

  input {
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    padding: 8px 15px;
    box-sizing: border-box;
    transition: all 100ms;
    outline: transparent;
    color: ${({ theme }) => theme.showcaseBlack};
    border: none;

    &:hover {
      cursor: pointer;
    }

    &::placeholder {
      color: ${(props) => props.theme.gray400};
      font-size: 0.875rem;
    }

    &:disabled {
      background: ${(props) => props.theme.showcaseWhite};
      color: ${(props) => props.theme.primary100};
    }
  }

  .react-datepicker-wrapper {
    display: block;
    width: 100%;
  }

  .react-datepicker__close-icon {
    right: 4px;
  }

  .react-datepicker__close-icon::after {
    width: 15px;
    height: 15px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 0.6;
    box-sizing: border-box;
    background: transparent;
    color: ${(props) => props.theme.primary100};
  }

  .react-datepicker__close-icon::before {
    display: block;
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 7px;
    right: 4px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.primary100};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  color: ${(props) => props.theme.gray400};
  pointer-events: none;
`;

const IconWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  div {
    z-index: 2;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.showcaseWhite};
  opacity: 0.5;
`;

const ErrorMessage = styled.div`
  margin-top: 10px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.errorColor};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const TryAgainButton = styled.div`
  margin-bottom: 8px;
  flex-shrink: 0;
  background: ${(props) => props.theme.showcaseWhite};
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.gray200};
  box-sizing: border-box;
  color: #574f6b;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.88px;
  line-height: 18px;
  outline: none;
  text-align: center;
  height: 40px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  > p {
    margin: auto 0;
  }
`;

const EmailMessage = styled(motion.div)`
  font-size: 0.75rem;
  position: relative;
  top: 4px;
  text-align: center;
  color: ${({ theme, valid }) =>
    valid ? theme.successColor : theme.errorColor};
`;

const CustomerInputGroup = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ${isMobileOnly
    ? "'name name' 'email email'"
    : "'name email'"};
  margin-bottom: 20px;
`;

const geiSharecodeInputGrid = (canArchiveSharecodes, isMobileOnly) => {
  if (isMobileOnly) {
    return canArchiveSharecodes
      ? "'expiration expiration' 'favorites favorites' 'note note'"
      : "'favorites favorites' 'note note'";
  } else {
    return canArchiveSharecodes
      ? "'expiration favorites' 'note note'"
      : "'note favorites'";
  }
};

const SharecodeInputGroup = styled(CustomerInputGroup)`
  grid-row-gap: 10px;
  grid-template-areas: ${({ canArchiveSharecodes }) =>
    geiSharecodeInputGrid(canArchiveSharecodes, isMobileOnly)};
`;

const SharecodeEmailInputGroup = styled(CustomerInputGroup)`
  grid-row-gap: 10px;
  grid-template-areas: ${isMobileOnly
    ? "'toggle toggle toggle' 'language language language' 'createlead createlead createlead'"
    : "'toggle language' 'createlead x'"};
  margin-bottom: 0;
`;

const SettingWrapper = styled.div`
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.gray300};
  ${({ styles }) => styles}
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 300ms;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.gray400};

  &:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: -2px;
    transform: translateX(0px);
    bottom: -2px;
    background-color: transparent;
    transition: 300ms;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.gray400};
  }
`;

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 22px;
  height: 14px;
  margin: 0;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + ${Slider} {
      background: ${({ theme }) => theme.accentAlt500};
      border: 2px solid ${({ theme }) => theme.accentAlt500};
      &:before {
        transform: translateX(8px);
        background: ${({ theme }) => theme.grayWhiteOff};
        border: 2px solid ${({ theme }) => theme.accentAlt500};
      }
    }
    &:disabled + ${Slider} {
      opacity: 0.6;
      cursor: default;
    }
  }
`;

const ToggleInput = styled.div`
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  color: ${({ theme, checked }) =>
    checked ? theme.showcaseBlack : theme.primary100};
  background: ${({ theme }) => theme.grayWhiteOff};
  #label {
    margin: 0 10px 0 0;
  }
`;

const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ styles }) => styles}
`;

const LanguageDropdownWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.promary100};
  cursor: pointer;
`;

const SelectedOptionContainer = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.primary100};
`;

const Menu = styled(motion.div)`
  position: absolute;
  z-index: 1;
  left: -1px;
  right: -1px;
  bottom: 41px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.showcaseWhite};
  border: solid 1px ${({ theme }) => theme.gray200};
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  transform-origin: top;
  overflow: hidden;
`;

const SortOption = styled.p`
  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  color: ${({ theme, selected }) =>
    selected ? theme.showcaseBlack : theme.primary300};
  cursor: pointer;
  user-select: none;
  margin: 0;
  padding: 5px 20px;
  padding-right: 30px;
  &:hover {
    color: ${({ theme }) => theme.accentAlt700};
    background: ${({ theme }) => theme.gray100};
  }
`;

const SelectedOption = styled.div`
  color: ${({ theme }) => theme.showcaseBlack};
  font-weight: 500;
  margin: 0 auto 0 10px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  transition: all 150ms ease;
  transform: rotate(${({ opened }) => (opened ? 180 : 0)}deg);
`;

const ContactData = styled.div`
  font-weight: 600;
  margin: auto 0;
`;

const dateFormat = 'dd.MM.yyyy';
const languageOptions = [
  { name: 'English', value: 'en' },
  { name: 'Deutsch', value: 'de' },
  { name: 'Français', value: 'fr' },
  { name: 'Nederlands', value: 'nl' }
];

const Toggle = ({ id, checked, onChange, disabled }) => {
  return (
    <SliderWrapper>
      <SwitchWrapper>
        <input
          id={`checkbox_${id}`}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <Slider />
      </SwitchWrapper>
    </SliderWrapper>
  );
};

const ShareCodeSettings = ({
  shareItemId,
  code,
  disabled,
  canArchiveSharecodes,
  setSharecodeSettings,
  isInvalidCode,
  processing,
  error,
  setShareItemError,
  sendEmailToContact = false,
  setSendEmailToContact = () => {},
  favoritesList,
  isShareOnlyFavorites,
  setShareOnlyFavorites,
  onLanguageChanged,
  emailLanguage,
  shouldCreateLead,
  setShouldCreateLead
}) => {
  const [codeExpirationDate, setCodeExpirationDate] = useState(Date.now());
  const [shareItem, setShareItem] = useState(null);

  const [isFetchingShareItem, setIsFetchingShareItem] = useState(false);

  const [localSettings, setLocalSettings] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [dirtyFields, setDirtyFields] = useState({});
  const [sortOptionsOpened, setSortOptionsOpened] = useState(false);

  const [shareItemHasContactAssigned, setShareItemHasContactAssigned] =
    useState(false);

  const [portalSettings, setPortalSettings] = useState(null);
  const [loadingPortalSettings, setLoadingPortalSettings] = useState(false);

  const navigate = useNavigate();

  const sortRef = useRef();

  // Auth store
  const { AuthState } = useAuthState();
  const { user } = AuthState;
  const { sessionToken, vaultId } = user;

  const getShareItemSettings = useCallback(() => {
    setIsFetchingShareItem(true);
    ShareItem.get(shareItemId)
      .then((result) => {
        setShareItem(result?.data?.shareItem ?? null);
        if (result?.data?.shareItem) {
          const { shareItem, customer, contact } = result.data;

          setShareItemHasContactAssigned(!!customer || !!contact);

          const contactOrCustomer = contact ?? customer;

          // check if there is a contact or a customer bound to this share item
          // contact takes precedence over customer if both are presented
          // if none exists, use share item properties
          const shareItemSettings = {
            ...shareItem,
            customerEmail:
              contactOrCustomer?.email ?? (shareItem.customerEmail || ''),
            firstName: (contactOrCustomer?.firstName || '').trim(),
            lastName: (contactOrCustomer?.lastName || '').trim()
          };

          setLocalSettings(shareItemSettings);
        }
        setIsFetchingShareItem(false);
      })
      .catch((error) => {
        setShareItemError({
          message: localizer.shareCodeSettings.errorWhileFetching,
          error
        });
        setIsFetchingShareItem(false);
      });
  }, [shareItemId, setShareItemError]);

  const setFieldDirty = (key) =>
    setDirtyFields((fields) => ({ ...fields, [key]: true }));

  useEffect(() => {
    if (!shareItem) return;
    setCodeExpirationDate(shareItem?.expiresAt ?? null);
  }, [shareItem]);

  useEffect(() => {
    if (!code) return;
    if (!sessionToken) return;

    getShareItemSettings();
  }, [code, sessionToken, getShareItemSettings]);

  useEffect(() => {
    if (!localSettings) return;
    setIsValidEmail(emailValidator.validate(localSettings.customerEmail));
  }, [localSettings]);

  // update settings
  useEffect(() => {
    setSharecodeSettings({ ...localSettings, isValidEmail });
  }, [localSettings, isValidEmail, setSharecodeSettings]);

  //
  useEffect(() => {
    if (sendEmailToContact) return;
    if (dirtyFields.sendEmailToContact) return;
    if (localSettings?.customerEmail && isValidEmail) {
      setSendEmailToContact(true);
    }
  }, [
    setSendEmailToContact,
    isValidEmail,
    localSettings,
    sendEmailToContact,
    dirtyFields
  ]);

  // get default email language
  useEffect(() => {
    if (emailLanguage) return;
    // first, check language query param as it takes presedence over
    // browser language
    const queryParams = fetchSettingsFromURL();
    const lang = queryParams.language ?? localizer.getLanguage() ?? 'en';

    const languageOption = languageOptions.find(
      (option) => option.value === lang
    );
    onLanguageChanged(languageOption);
  }, [emailLanguage, onLanguageChanged]);

  useEffect(() => {
    if (!sortOptionsOpened || isMobileOnly) return;
    const handleOutsideClick = ({ target }) => {
      if (sortRef.current && !sortRef.current.contains(target)) {
        setSortOptionsOpened(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [sortOptionsOpened]);

  // Get Portal settings
  useEffect(() => {
    if (portalSettings) return;
    if (loadingPortalSettings) return;

    setLoadingPortalSettings(true);
    PortalSettings.get(vaultId, sessionToken)
      .then((response) => {
        const portalSettings = response?.data?.vmPortalSettings;
        setPortalSettings(portalSettings);
        setShouldCreateLead(!!portalSettings?.createLeadWhenShowcaseIsShared);
      })
      .catch(() => {
        setPortalSettings({
          createLeadWhenShowcaseIsShared: false
        });
        setShouldCreateLead(false);
      })
      .finally(() => setLoadingPortalSettings(false));
  }, [vaultId, sessionToken, portalSettings, loadingPortalSettings]);

  const onFieldChange = (key, value) => {
    setLocalSettings((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const trimValue = (key) => {
    setLocalSettings((prev) => ({
      ...prev,
      [key]: prev[key] ? prev[key].trim() : ''
    }));
  };

  const onChangeExpirationDate = useCallback((timestamp) => {
    setCodeExpirationDate(timestamp);
    setLocalSettings((prev) => ({
      ...prev,
      expiresAt: timestamp ?? 0
    }));
  }, []);

  const onSortOptionClick = (sortOption) => {
    setSortOptionsOpened(false);
    onLanguageChanged(sortOption);
  };

  const sortOptionsComp = languageOptions.map((sortOption) => (
    <SortOption
      key={sortOption.name}
      selected={sortOption.name === emailLanguage?.name}
      onClick={(e) => {
        if (isMobileOnly) return;
        onSortOptionClick(sortOption);
      }}
      onTouchEnd={() => onSortOptionClick(sortOption)}
    >
      {sortOption.name}
    </SortOption>
  ));

  const processingOverlay = (
    <Wrapper>
      <ShowcaseLoader />
    </Wrapper>
  );

  if (isInvalidCode) {
    return (
      <ConfirmationModal
        open={true}
        onConfirm={() => {
          navigate(`/`);
        }}
        title={localizer.invalidShowcaseTitle}
        descriptionBlocks={[
          localizer.formatString(localizer.invalidShowcaseDescription, code)
        ]}
        confirmButtonText={localizer.tryDifferentCode}
      />
    );
  }

  return (
    <Settings data-testid="sharecode-settings">
      {(isFetchingShareItem || processing || disabled) && processingOverlay}

      {localSettings && (
        <>
          <SettingsHeader>
            {localizer.shareCodeSettings.customer}
          </SettingsHeader>
          <CustomerInputGroup>
            {shareItemHasContactAssigned ? (
              <ContactData>
                {localSettings.firstName ?? ''}{' '}
                {localSettings.lastName ? ` ${localSettings.lastName}` : ''}
              </ContactData>
            ) : (
              <div style={{ gridArea: 'name' }}>
                <StyledInputField
                  dataTestId="sharecode-first-name-input"
                  fieldName={`firstName`}
                  onFieldChange={onFieldChange}
                  onBlur={() => trimValue(`firstName`)}
                  hasDebounce={false}
                  value={localSettings.firstName ?? ''}
                  placeholder={localizer.form.firstName}
                  styles={`margin-bottom: 10px;`}
                />

                <StyledInputField
                  dataTestId="sharecode-last-name-input"
                  fieldName={`lastName`}
                  onFieldChange={onFieldChange}
                  onBlur={() => trimValue(`lastName`)}
                  hasDebounce={false}
                  value={localSettings.lastName ?? ''}
                  placeholder={localizer.form.lastName}
                />
              </div>
            )}

            <EmailInputWrapper styles={`grid-area: email;`}>
              {shareItemHasContactAssigned ? (
                <ContactData>{localSettings.customerEmail ?? ''}</ContactData>
              ) : (
                <>
                  <StyledInputField
                    dataTestId="sharecode-email-input"
                    fieldName={`customerEmail`}
                    onFieldChange={onFieldChange}
                    hasDebounce={false}
                    value={localSettings.customerEmail ?? ''}
                    placeholder={localizer.shareCodeSettings.customerEmail}
                    error={
                      (localSettings.customerEmail || sendEmailToContact) &&
                      !isValidEmail
                    }
                  />
                  <AnimatePresence>
                    {(localSettings.customerEmail || sendEmailToContact) &&
                      !isValidEmail && (
                        <EmailMessage
                          initial={{ opacity: 0, y: 6 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -6 }}
                          valid={isValidEmail}
                        >
                          {localSettings.customerEmail
                            ? localizer.shareCodeSettings.emailError
                            : localizer.shareCodeSettings.provideEmailError}
                        </EmailMessage>
                      )}
                  </AnimatePresence>
                </>
              )}
            </EmailInputWrapper>
          </CustomerInputGroup>

          <SettingsHeader>
            {localizer.shareCodeSettings.sharecode}
          </SettingsHeader>
          <SharecodeInputGroup canArchiveSharecodes={canArchiveSharecodes}>
            {canArchiveSharecodes && (
              <DatePickerWrapper styles={`grid-area: expiration;`}>
                <div>{localizer.shareCodeSettings.activeUntil}:</div>
                <IconWrapper>
                  <DatePicker
                    selected={
                      codeExpirationDate
                        ? new Date(codeExpirationDate)
                        : null || null
                    }
                    onChange={(val) => {
                      if (val instanceof Date) {
                        onChangeExpirationDate(val.getTime());
                        return val.getTime();
                      } else {
                        onChangeExpirationDate(null);
                      }
                    }}
                    onKeyDown={(event) => event.preventDefault()}
                    placeholderText={'DD.MM.YYYY'}
                    autoComplete="off"
                    dateFormat={dateFormat}
                    isClearable
                  />
                  {!codeExpirationDate && (
                    <Icon icon={['far', 'calendar-day']} size="1x" />
                  )}
                </IconWrapper>
              </DatePickerWrapper>
            )}

            <SettingWrapper styles={`grid-area: favorites;`}>
              <ToggleInput checked={isShareOnlyFavorites}>
                <label id="label">
                  {localizer.shareCodeSettings.shareOnlyFavorites}
                </label>
                <Toggle
                  id="shareOnlyFavorites"
                  checked={isShareOnlyFavorites}
                  onChange={() => setShareOnlyFavorites(!isShareOnlyFavorites)}
                  disabled={!favoritesList?.length}
                />
              </ToggleInput>
            </SettingWrapper>

            <StyledInputField
              dataTestId="sharecode-note-input"
              fieldName={`note`}
              onFieldChange={onFieldChange}
              onBlur={() => trimValue(`note`)}
              hasDebounce={false}
              value={localSettings.note ?? ''}
              placeholder={localizer.shareCodeSettings.note}
              styles={`grid-area: note;`}
            />
          </SharecodeInputGroup>

          <SettingsHeader>{`Sharecode email`}</SettingsHeader>
          <SharecodeEmailInputGroup>
            <SettingWrapper styles={`grid-area: toggle;`}>
              <ToggleInput checked={sendEmailToContact}>
                <label id="label">
                  {localizer.shareCodeSettings.sendEmailToCustomer}
                </label>
                <Toggle
                  id="sendEmailToContact"
                  checked={sendEmailToContact}
                  onChange={() => {
                    setFieldDirty('sendEmailToContact');
                    setSendEmailToContact(!sendEmailToContact);
                  }}
                />
              </ToggleInput>
            </SettingWrapper>
            <SettingWrapper styles={`grid-area: language;`}>
              <LanguageDropdownWrapper>
                <SelectedOptionContainer
                  onClick={() => setSortOptionsOpened(true)}
                >
                  {localizer.shareCodeSettings.emailLanguage}:
                  <SelectedOption>{emailLanguage?.name ?? ''}</SelectedOption>
                  <ArrowIcon
                    icon={['fas', 'chevron-down']}
                    size="1x"
                    opened={sortOptionsOpened ? 1 : 0}
                  />
                </SelectedOptionContainer>

                <AnimatePresence>
                  {sortOptionsOpened && (
                    <Menu
                      ref={sortRef}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      {sortOptionsComp}
                    </Menu>
                  )}
                </AnimatePresence>
              </LanguageDropdownWrapper>
            </SettingWrapper>
            <SettingWrapper styles={`grid-area: createlead;`}>
              <ToggleInput checked={shouldCreateLead}>
                <label id="label">
                  {localizer.shareCodeSettings.createLead}
                </label>
                <Toggle
                  id="createLead"
                  checked={shouldCreateLead}
                  onChange={() => {
                    setFieldDirty('createLead');
                    setShouldCreateLead(!shouldCreateLead);
                  }}
                />
              </ToggleInput>
            </SettingWrapper>
          </SharecodeEmailInputGroup>
        </>
      )}

      {error && (
        <ErrorMessage>
          {!shareItem && (
            <TryAgainButton onClick={() => getShareItemSettings()}>
              <p>{localizer.shareCodeSettings.tryAgain}</p>
            </TryAgainButton>
          )}
          {error.message}
          {error.code === 403 && <p>{localizer.loginOnCorrectVault}</p>}
        </ErrorMessage>
      )}
    </Settings>
  );
};

export default ShareCodeSettings;
