import React, { useEffect } from 'react';

// Helpers
import { useUiState } from 'stores/UiStore';
import { useProjectState } from 'stores/ProjectStore';
import localizer from 'localization/localizer';

// Components
import UnitsPage from '../unitList/UnitsPage';
import { NoFavoritesIcon } from 'resources/icons';

// Styling
import styled from 'styled-components';

const NoFavoritesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NoFavoritesText = styled.p`
  margin: 15px 0 10px;
  font-size: 1.25rem;
  color: ${(props) => props.theme.primary100};
  max-width: 320px;
  text-align: center;
`;

const NoFavoritesDescription = styled(NoFavoritesText)`
  margin: 0;
  font-size: 0.875rem;
`;

// FavoritesPage looks almost absolutely identical to UnitsPage
// so we set showFavorites to true when FavoritesPage is rendered
// add return the UnitsPage

const FavoritesPage = ({ onOpenUnitPage }) => {
  // Project state
  const { ProjectState } = useProjectState();
  const { favoritesList } = ProjectState;

  // UI state
  const { UiStateDispatch } = useUiState();
  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: {
        showFavorites: true,
        showMenu: true,
        subMenu: null
      }
    });
    return () => {
      UiStateDispatch({
        type: 'update',
        payload: {
          showFavorites: false
        }
      });
    };
  }, [UiStateDispatch]);

  if (favoritesList.length === 0) {
    return (
      <NoFavoritesWrapper>
        <NoFavoritesIcon />
        <NoFavoritesText>{localizer.noFavorites}</NoFavoritesText>
        <NoFavoritesDescription>
          {localizer.formatString(
            localizer.noFavoritesDescription,
            localizer.units
          )}
        </NoFavoritesDescription>
      </NoFavoritesWrapper>
    );
  }

  return <UnitsPage onOpenUnitPage={onOpenUnitPage} />;
};

export default FavoritesPage;
