import React from 'react';
import { shape, func } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { motion } from 'framer-motion';

// Styles
import styled from 'styled-components';

const Button = styled(motion.div)`
  ${({ styles }) => styles}
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.showcaseWhite};
`;

const ArrowButton = ({ styles, iconSetting, onClick }) => (
  <Button
    styles={styles}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    onClick={onClick}
  >
    {iconSetting && <ArrowIcon {...iconSetting} size="3x" />}
  </Button>
);

ArrowButton.propTypes = {
  style: shape({}),
  iconSetting: shape({}),
  onClick: func
};

ArrowButton.defaultProps = {
  style: null,
  iconSetting: null,
  onClick: () => {}
};

export default ArrowButton;
