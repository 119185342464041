import * as React from 'react';

function SvgGridviewIcon(props) {
  return (
    <svg width={28} height={28} {...props}>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M9.25 23h9.5a1 1 0 001-1V6a1 1 0 00-1-1h-9.5a1 1 0 00-1 1v16a1 1 0 001 1zM3.5 20.6h2.6a1 1 0 001-1V8.4a1 1 0 00-1-1H3.5a1 1 0 00-1 1v11.2a1 1 0 001 1zM20.9 8.4v11.2a1 1 0 001 1h2.6a1 1 0 001-1V8.4a1 1 0 00-1-1h-2.6a1 1 0 00-1 1z"
        opacity={0.8}
      />
    </svg>
  );
}

export default SvgGridviewIcon;
