import { buildAddress } from 'helpers';

const transformAccentedLettersToNormalOnes = (string) =>
  string.normalize('NFD').replace(/\p{Diacritic}/gu, '');

const searchByWordsCombination = (text, searchValue) => {
  if (!text || !searchValue) return false;

  const searchValueWords = searchValue.match(/\b(\w+)\b/g) ?? [];
  const textWords = text.match(/\b(\w+)\b/g) ?? [];

  // check if every word of a search value can be found in target text
  let allWordsFound = true;
  searchValueWords.forEach((word) => {
    if (!textWords.some((x) => x.includes(word))) {
      allWordsFound = false;
    }
  });

  return allWordsFound;
};

const filterBySearchValue = (projects, searchValue) => {
  return projects.filter((project) => {
    const title = project.title?.toLowerCase();
    const description = project.description?.toLowerCase();
    const address = buildAddress(project.address)?.toLowerCase();

    return [title, description, address]
      .filter(Boolean)
      .map((x) => transformAccentedLettersToNormalOnes(x))
      .some((x) => searchByWordsCombination(x, searchValue.toLowerCase()));
  });
};

export const filterProjects = (
  projects,
  filter,
  filteredProjectIds,
  hiddenStatuses
) => {
  const { offset, sortAsc, sortField, searchValue } = filter;

  // Filtering
  const filteredByIds =
    filteredProjectIds.length > 0
      ? projects.filter((x) => filteredProjectIds.includes(x.objectId))
      : projects;
  const filteredByStatus = filteredByIds.filter(
    (x) => !hiddenStatuses.includes(x.state)
  );
  const filteredProjects = searchValue
    ? filterBySearchValue(filteredByStatus, searchValue)
    : filteredByStatus;

  // Sorting
  const sortOrder = sortAsc ? 1 : -1;
  const sortedProjects = filteredProjects.sort(
    (firstProject, secondProject) => {
      if (typeof firstProject[sortField] === 'string') {
        const compareResult = firstProject[sortField].localeCompare(
          secondProject[sortField]
        );
        return compareResult * sortOrder;
      } else {
        const compareResult =
          firstProject[sortField] - secondProject[sortField];
        return compareResult * sortOrder;
      }
    }
  );

  // Slicing
  const slicedProjects = sortedProjects.slice(0, offset);
  const endReached = sortedProjects.length < offset;

  return {
    fullProjectsList: sortedProjects,
    projects: slicedProjects,
    endReached,
    totalProjects: sortedProjects.length
  };
};

export const createRangeFilterValues = (values, unit) => {
  const purifiedValues = [...values]
    .filter(Boolean)
    .map((x) => Number(x))
    .filter((x) => !isNaN(x));
  const min = Math.min(...purifiedValues);
  const max = Math.max(...purifiedValues);
  return {
    limits: { min, max },
    values: { min, max },
    unit
  };
};

export const createPillFilterValues = (values) => {
  const purifiedValues = [...values]
    .filter(Boolean)
    .map((val) => {
      if (!isNaN(Number(val))) {
        return Number(val);
      } else {
        return val;
      }
    })
    .sort((a, b) => {
      if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
      } else {
        return String(a).localeCompare(String(b));
      }
    });
  return {
    all: purifiedValues,
    selected: []
  };
};

export const unitMatchFilter = (unit, filter) => {
  const { surface, price, numberOfBedrooms, numberOfBathrooms, propertyType } =
    filter;

  const isSurfaceFilterActive =
    surface.limits.min !== surface.values.min ||
    surface.limits.max !== surface.values.max;
  const isPriceFilterActive =
    price.limits.min !== price.values.min ||
    price.limits.max !== price.values.max;

  const unitSurface = unit.unitMetadata?.surface;
  const unitPrice = unit.unitPrice?.price;
  const unitBedrooms = unit.unitMetadata?.numberOfBedrooms;
  const unitBathrooms = unit.unitMetadata?.numberOfBathrooms;
  const unitType = unit.unitType?.title;

  const surfaceMathes = isSurfaceFilterActive
    ? unitSurface &&
      unitSurface >= surface.values.min &&
      unitSurface <= surface.values.max
    : true;
  const priceMatches = isPriceFilterActive
    ? unitPrice &&
      unitPrice >= price.values.min &&
      unitPrice <= price.values.max
    : true;
  const bedroomsMatch =
    numberOfBedrooms.selected?.length > 0
      ? unitBedrooms && numberOfBedrooms.selected.includes(unitBedrooms)
      : true;
  const bathroomsMatch =
    numberOfBathrooms.selected?.length > 0
      ? unitBathrooms && numberOfBathrooms.selected.includes(unitBathrooms)
      : true;
  const unitTypeMatches =
    propertyType.selected?.length > 0
      ? unitType && propertyType.selected.includes(unitType)
      : true;

  return (
    surfaceMathes &&
    priceMatches &&
    bedroomsMatch &&
    bathroomsMatch &&
    unitTypeMatches
  );
};

export const stateMatchesFilter = (shouldShowStatuses, state, filter) => {
  if (!shouldShowStatuses) return true;

  const selectedStatuses = filter.projectStatus?.selected;
  return selectedStatuses?.length > 0 && selectedStatuses.includes(state);
};
