import React, { useState, useEffect, forwardRef } from 'react';

// Helpers
import { useProjectState } from 'stores/ProjectStore';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledPolygon = styled(motion.polygon)`
  cursor: pointer;
`;

const convertRelativeLocation = (x, y, parentDimensions, zoomScale) => {
  // Get parent image size and container size
  const parentWidth = parentDimensions?.width || 0;
  const parentHeight = parentDimensions?.height || 0;

  // Calculate the half size of the image and the offset of the marker according to the center of the image
  const parentHalfWidth = parentWidth / 2.0;
  const parentHalfHeight = parentHeight / 2.0;

  const centerOffsetX = parentHalfWidth * x;
  const centerOffsetY = parentHalfHeight * y;

  // Add everything together to get the spot location according to the top left corner of the screen
  let cornerOffsetX = (centerOffsetX + parentHalfWidth) / zoomScale;
  let cornerOffsetY = (centerOffsetY + parentHalfHeight) / zoomScale;

  cornerOffsetX = Math.ceil(cornerOffsetX);
  cornerOffsetY = Math.ceil(cornerOffsetY);

  return { x: cornerOffsetX, y: cornerOffsetY };
};

const drawPolygon = (coords, imageSize, zoomScale) => {
  if (coords.length === 0) return;
  let points = '';
  coords.forEach((coord, i) => {
    const { x, y } = convertRelativeLocation(
      coord.x,
      coord.y,
      imageSize,
      zoomScale
    );
    const stringifiedCoord = `${x} ${y}`;
    points += `${i > 0 ? ', ' : ''}${stringifiedCoord}`;
  });
  return points;
};

const Polygon = forwardRef(({
  coords,
  fill,
  imageSize,
  zoomScale,
  objectId,
  onMouseMove,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  onTouchStart,
  onTouchEnd,
  isHovered
}, ref) => {
  const [stringifiedCoords, setStringifiedCoords] = useState('');
  const [hovered, setHovered] = useState(false);

  const { ProjectState } = useProjectState();
  const { isUserIdle } = ProjectState;

  useEffect(() => {
    setStringifiedCoords(drawPolygon(coords, imageSize, zoomScale));
  }, [coords, imageSize, zoomScale]);

  return (
    <StyledPolygon
      initial={{ opacity: 0 }}
      animate={
        isUserIdle && !(hovered || isHovered) ? { opacity: 0 } : { opacity: 1 }
      }
      transition={isUserIdle ? { duration: 2.5 } : { duration: 0.25 }}
      key={`polygon${objectId}`}
      xmlns="http://www.w3.org/2000/svg"
      points={stringifiedCoords}
      stroke={fill}
      strokeWidth={1}
      strokeOpacity={0.5}
      fill={fill}
      fillOpacity={hovered || isHovered ? 0.6 : 0.25}
      onMouseMove={onMouseMove}
      onMouseEnter={() => {
        setHovered(true);
        onMouseEnter();
      }}
      onMouseLeave={() => {
        setHovered(false);
        onMouseLeave();
      }}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      ref={ref}
    />
  );
});

export default Polygon;
