import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';

// Helpers
import { isMobile } from 'react-device-detect';

// Styling
import styled, { keyframes, css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Wrapper = styled.div`
  margin: ${isMobile ? '0' : '0 15px'};
  ${({ styles }) => styles};
`;

const Button = styled.button`
  position: relative;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: ${isMobile ? '50%' : '2px'};
  cursor: pointer;
  background-color: ${({ theme, switchedOn }) =>
    switchedOn ? theme.grayWhiteOff : theme.showcaseWhite};
  transition: background-color 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.primary300};
  outline: none;
  &:hover {
    background-color: ${({ theme }) => theme.grayWhiteOff};
  }
  ${isMobile &&
  `
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  `}
  ${({ styles }) => styles};
`;

const pulse = (theme) => keyframes`
  0% {
    transform: scale(1);
    color: ${theme.primary300};
  }
  14% {
    transform: scale(1.26);
    color: ${theme.watermelon};
  }
  28% { transform: scale(0.95); }
  46% { transform: scale(1.15); }
  64% { transform: scale(1); }
`;

const HeartIcon = styled(FontAwesomeIcon)`
  color: inherit;
  ${({ animate, theme }) => {
    if (animate) {
      return css`
        animation: ${pulse(theme)} 2s linear infinite;
      `;
    }
  }}
  ${({ styles }) => styles};
`;

const FavoritesCounter = styled(motion.div)`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.defaultBrandSecondaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.showcaseWhite};
  font-size: 0.75rem;
  font-weight: 600;
`;

const ToggleFavoritesButton = ({
  onClick,
  tipText,
  switchedOn,
  isAnimated,
  favoritesCount,
  wrapperStyles,
  buttonStyles,
  iconStyles,
  disabled,
  tipPlacement = 'right-end'
}) => {
  return (
    <Wrapper styles={wrapperStyles}>
      <Tippy
        offset={[0, -10]}
        theme="favoritesToggler"
        placement={tipPlacement}
        content={<div>{tipText}</div>}
        arrow={false}
        touch={false}
      >
        <Button
          onClick={onClick}
          switchedOn={switchedOn}
          styles={buttonStyles}
          disabled={disabled}
        >
          <AnimatePresence>
            {favoritesCount > 0 && (
              <FavoritesCounter
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              >
                {favoritesCount}
              </FavoritesCounter>
            )}
          </AnimatePresence>
          <HeartIcon
            icon={[switchedOn ? 'fas' : 'fal', 'heart']}
            size="1x"
            animate={isAnimated ? 1 : 0}
            styles={iconStyles}
          />
        </Button>
      </Tippy>
    </Wrapper>
  );
};

export default ToggleFavoritesButton;
