import React, { useRef, useState, useEffect, useCallback } from 'react';
import { shape, string } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Video, Transformation } from 'cloudinary-react';
import { PlayThinIcon } from 'resources/icons/index';
import { motion } from 'framer-motion';

// Helpers
import {
  getCurrentEnvironment,
  computeCloudinaryId,
  isMobileDevice,
  isCloudinaryTransformationAvailable
} from 'helpers';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const ContentWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const VideoPlayIcon = styled(PlayThinIcon)`
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const VideoUnavailableWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const UnavailableMessageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const UnavailableText = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0;
`;

const ErrorText = styled.p`
  font-size: 12px;
  margin: 0;
`;

const Thumbnail = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;

  ${(props) =>
    !props.cloudinaryId &&
    `
    filter: blur(10px);
    -moz-filter: blur(10px);
    transform: scale(1.1);  
  `}
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
`;

const VideoContent = ({ content, fallbackThumbnail, onLoad }) => {
  const player = useRef();
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [interacted, setInteracted] = useState(false);
  const [transformationAvailable, setTransformationAvailable] = useState(false);
  const [overlayActive, setOverlayActive] = useState(true);

  const [useFallback, setUseFallback] = useState(false);

  const currentEnvironment = getCurrentEnvironment();

  const [cloudinaryId] = useState(
    computeCloudinaryId(
      decodeURIComponent(content?.contentUri),
      currentEnvironment.googleStorageBucketId
    )
  );

  const wrapIsCloudinaryTransformationAvailable = useCallback(
    isCloudinaryTransformationAvailable,
    []
  );

  useEffect(() => {
    if (isVideoActive) {
      if (!interacted) {
        setInteracted(true);
      }
    }
  }, [isVideoActive, content, interacted]);

  //check if transformation is available async
  useEffect(() => {
    const effectHeight = isMobileDevice() ? 720 : 1080;
    wrapIsCloudinaryTransformationAvailable(
      setTransformationAvailable,
      decodeURIComponent(content?.contentUri),
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryVideoBaseUrl,
      `c_limit,h_${effectHeight}`,
      'mp4'
    );
  }, [
    wrapIsCloudinaryTransformationAvailable,
    content,
    currentEnvironment.googleStorageBucketId,
    currentEnvironment.cloudinaryVideoBaseUrl
  ]);

  useEffect(() => {
    setTimeout(() => setOverlayActive(false), 500);
  }, []);

  // Add handlers for the video player to know when video is active
  let handlers = {
    onPlay: () => {
      setIsVideoActive(true);
    }
  };

  if (!content) {
    return null;
  }

  // Determine max width/height based on mobile: sd (720) on mobile or hd otherwise (1280)
  let maxHeight = isMobileDevice() ? 720 : 1080;
  let transformation = transformationAvailable && (
    <Transformation height={maxHeight} crop="limit" />
  );

  const uri = useFallback ? fallbackThumbnail : content.thumbnailUri;

  let video;
  // Video paused
  video = (
    <VideoWrapper
      onClick={() => {
        if (cloudinaryId) {
          setIsVideoActive(true);
          setInteracted(true);
        }
      }}
    >
      {cloudinaryId ? (
        <VideoPlayIcon />
      ) : (
        <VideoUnavailableWrapper>
          <UnavailableMessageWrapper>
            <FontAwesomeIcon icon={['fal', 'info-circle']} size="2x" />
            <UnavailableText>{localizer.videoUnavailable}</UnavailableText>
            <ErrorText>{localizer.videoFetchError}</ErrorText>
          </UnavailableMessageWrapper>
        </VideoUnavailableWrapper>
      )}
      <Thumbnail
        src={uri}
        onError={() => {
          setUseFallback(true);
        }}
        onLoad={onLoad}
        alt={'thumbnail'}
        cloudinaryId={cloudinaryId}
      />
    </VideoWrapper>
  );

  // Video interacted
  if (isVideoActive) {
    video = (
      <>
        {overlayActive && <Overlay />}
        <Video
          {...handlers}
          innerRef={player}
          cloudName={currentEnvironment.cloudinaryCloudName}
          publicId={cloudinaryId}
          sourceTypes={['mp4', 'ogv']}
          autoPlay={true}
          controls={true}
          width="100%"
          height="100%"
          disablePictureInPicture
          controlsList="nodownload"
        >
          {transformation}
        </Video>
      </>
    );
  }

  return (
    <ContentWrapper
      key={content.objectId}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container>{video}</Container>
    </ContentWrapper>
  );
};

VideoContent.propTypes = {
  content: shape({}),
  fallbackThumbnail: string
};

VideoContent.defaultProps = {
  content: null,
  fallbackThumbnail: null
};

export default VideoContent;
