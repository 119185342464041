import React from 'react';
import { shape, func } from 'prop-types';

// Styling
import styled from 'styled-components';

// Components
import StackedSpotsMarker from 'resources/icons/StackedSpotsMarker';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const UnitsNumber = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.showcaseWhite};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ClusterSpot = ({ spotObject, onSpotClicked }) => {
  return (
    <Wrapper
      onClick={() => {
        setTimeout(() => {
          onSpotClicked(
            spotObject?.nextScaleLevelClusterCenterCoordinates.x,
            spotObject?.nextScaleLevelClusterCenterCoordinates.y
          );
        }, 500);
      }}
    >
      <StackedSpotsMarker width={40} height={40} />
      <UnitsNumber>{spotObject?.numberOfUnits}</UnitsNumber>
    </Wrapper>
  );
};

ClusterSpot.propTypes = {
  spotObject: shape({}).isRequired,
  onSpotClicked: func.isRequired
};

ClusterSpot.defaultProps = {};

export default ClusterSpot;
