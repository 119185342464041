import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { motion } from 'framer-motion';
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import Sublink from './Sublink';
import OnboardingScreens from 'components/share/onboardingScreens/OnboardingScreens';

// Styling
import styled from 'styled-components';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';
import { useUiState } from 'stores/UiStore';
import { useProjectState } from 'stores/ProjectStore';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  background-color: white;
`;

const ScreenTitle = styled.h3`
  width: 100%;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  text-align: center;
`;

const PageContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const SublinksWrapper = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0 0 40px;
  padding: 20px 15px 0;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.gray200};
`;

const ExtraActionsWrapper = styled(SublinksWrapper)`
  box-shadow: none;
`;

const SUBLINKS = [
  {
    icon: ['fal', 'info-circle'],
    text: 'projectInformation',
    link: 'project_information'
  },
  { icon: ['fal', 'folder'], text: 'resources', link: 'resources' },
  { icon: ['fal', 'share'], text: 'shareProject', link: 'share' }
];

const MorePage = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [encodedVaultId, setEncodedVaultId] = useState();

  const { UiStateDispatch } = useUiState();
  const navigate = useNavigate();

  // Project store
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration, project } = ProjectState;
  const vault = project?.vault;

  useEffect(() => {
    if (!vault) return;
    if (vault.vaultSettings?.portfolioIsPublic) {
      setEncodedVaultId(encodeURIComponent(window.btoa(vault.objectId)));
    }
  }, [vault]);

  const EXTRA_ACTIONS = [
    {
      icon: ['fal', 'clone'],
      text: 'showOnboardingAgain',
      action: () => setShowOnboarding(true)
    }
  ];

  if (encodedVaultId && !SUBLINKS.find((x) => x.text === 'openPortfolio')) {
    SUBLINKS.push({
      icon: ['fal', 'city'],
      text: 'openPortfolio',
      action: () => {
        navigate(`/portfolio/${encodedVaultId}${window.location.search}`);
      },
      withArrow: true
    });
  }

  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: { showMenu: true }
    });
  }, [UiStateDispatch]);

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MobileLayoutHeader>
        <ScreenTitle>{capitalize(localizer.more)}</ScreenTitle>
      </MobileLayoutHeader>
      <PageContent>
        <SublinksWrapper>
          {SUBLINKS.map((sublink, idx) => {
            if (
              sublink.link === 'resources' &&
              showcaseConfiguration?.showResourceMenu === false
            ) {
              return null;
            }
            return <Sublink key={idx} item={sublink} />;
          })}
        </SublinksWrapper>
        <ExtraActionsWrapper>
          {EXTRA_ACTIONS.map((action, idx) => (
            <Sublink key={idx} item={action} />
          ))}
        </ExtraActionsWrapper>
      </PageContent>
      <OnboardingScreens
        show={showOnboarding}
        onClose={() => setShowOnboarding(false)}
      />
    </Wrapper>
  );
};

MorePage.propTypes = {};

MorePage.defaultProps = {};

export default MorePage;
