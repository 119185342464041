import React, { useEffect, useState } from 'react';

// Styling
import styled from 'styled-components';

// Helpers
import localizer from 'localization/localizer';
import { useUiState } from 'stores/UiStore';
import { useProjectState } from 'stores/ProjectStore';
import { getValueFromLocalized } from 'helpers';
import { trackUnitAction } from 'components/pages/unitDetail/tracking/UnitTrackingHelper';
import { useAuthState } from 'stores/AuthStore';

const ContactUsButton = styled.div`
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.gray300 : theme.defaultBrandPrimaryColor};
  color: ${({ theme }) => theme.showcaseWhite};
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
  cursor: pointer;
  word-break: break-word;
  text-align: center;
  ${({ styles }) => styles};
`;

const ContactUs = ({ unit, buttonStyles, location }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  // UI state
  const { UiStateDispatch } = useUiState();

  const { AuthState } = useAuthState();

  // Project state
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;
  const embededUnitFormConfiguration =
    showcaseConfiguration?.embededUnitFormConfiguration;

  const setContactUsActiveUnit = () => {
    UiStateDispatch({
      type: 'update',
      payload: { contactUsActiveUnit: unit }
    });
  };

  const buttonName = getValueFromLocalized(
    embededUnitFormConfiguration?.localizedCustomButtonName,
    embededUnitFormConfiguration?.customButtonName || localizer.contactUs
  );

  useEffect(() => {
    if (!unit) return;
    if (ProjectState?.project?.temporaryDisabledUnits) {
      setIsDisabled(
        !!ProjectState.project.temporaryDisabledUnits.find(
          (disabledUnit) => disabledUnit === unit.objectId
        )
      );
    }
  }, [ProjectState, unit]);

  return (
    <>
      <ContactUsButton
        onMouseEnter={() => setContactUsActiveUnit()}
        onTouchStart={() => setContactUsActiveUnit()}
        isDisabled={isDisabled}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isDisabled) return;

          if(unit?.objectId) {
            trackUnitAction(
              'unitContactUsButtonClicked',
              unit.objectId,
              ProjectState,
              AuthState
            );
          }

          UiStateDispatch({
            type: 'update',
            payload: { showContactUsModal: true }
          });
        }}
        styles={buttonStyles}
      >
        {buttonName}
      </ContactUsButton>
    </>
  );
};

export default ContactUs;
