import * as React from 'react';

function SvgMediumSizeIcon(props) {
  return (
    <svg viewBox="0 0 12 9" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.125 0h9.75C11.485 0 12 .516 12 1.125v6.75C12 8.508 11.484 9 10.875 9H.656A.648.648 0 010 8.344V1.125C0 .515.492 0 1.125 0zM7 8.25V4.406c0-.355-.3-.656-.656-.656H.75V1.125C.75.937.914.75 1.125.75h9.75c.188 0 .375.188.375.375v6.75c0 .21-.188.375-.375.375H7z"
      />
    </svg>
  );
}

export default SvgMediumSizeIcon;
