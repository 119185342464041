import React, { useEffect, useState, useCallback, useRef } from 'react';

// Components
import { IdealImage } from '@prompto-ui';
import UnitSpotTooltip from '../unitSpot/UnitSpotTooltip';
import UspSpotTooltip from '../uspSpot/UspSpotTooltip';
import ShowcaseLoader from 'components/other/ShowcaseLoader';

// Helpers
import { motion } from 'framer-motion';
import { useProjectState } from 'stores/ProjectStore';
import { getCurrentEnvironment } from 'helpers';
import { clamp } from '@prompto-helpers';

// Styling
import styled, { css } from 'styled-components';
import { getDisplayDirection } from '../Spot';

const maxSpotPreviewWidth = 250;
const maxSpotPreviewHeight = 150;
const previewMargin = 20;
const NAV_ITEM_FIXED_WIDTH = 250;
const NAV_ITEM_FIXED_HEIGHT = 148;

const PreviewWrapper = styled.div`
  z-index: 150;
  top: ${({ pos }) => pos.y}px;
  left: ${({ pos }) => pos.x}px;
  position: fixed;
  cursor: pointer;
  pointer-events: none;
  ${({ scale, direction }) => {
    if (!direction) {
      return css`
        transform: scale(${scale});
      `;
    }
    const x =
      direction.x === 'left'
        ? `translateX(-${NAV_ITEM_FIXED_WIDTH / scale}px)`
        : direction.x === 'right'
        ? ''
        : `translateX(-${(NAV_ITEM_FIXED_WIDTH * 0.5) / scale}px)`;
    const xOrigin =
      direction.x === 'left' ? 'right' : direction.x === 'right' ? 'left' : '';
    if (direction.y === 'top') {
      return css`
        transform-origin: bottom ${xOrigin};
        transform: scale(${scale})
          translateY(-${(NAV_ITEM_FIXED_HEIGHT + 10) / scale}px) ${x};
      `;
    } else if (direction.y === 'bottom') {
      return css`
        transform-origin: top ${xOrigin};
        transform: scale(${scale}) translateY(10px) ${x};
      `;
    }
  }};
`;

const ThumbContainer = styled(motion.div)`
  max-width: ${maxSpotPreviewWidth}px;
  max-height: ${maxSpotPreviewHeight}px;
  pointer-events: ${(props) => (props.isSpotHovered ? 'initial' : 'none')};
  cursor: ${(props) => (props.isSpotHovered ? 'pointer' : 'default')};
  border: 1px solid white;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  z-index: ${(props) => (props.isSpotHovered ? '5' : '1')};
  transform-origin: bottom;
`;

const TargetThumbnailWrapper = styled.div`
  display: block;
  height: 148px;
  width: 250px;
  object-fit: cover;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const NameWrapper = styled(motion.div)`
  height: 40px;
  margin: 0 ${previewMargin}px;
  margin-top: -20px;
  color: white;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  user-select: none;
  max-width: ${maxSpotPreviewWidth - 2 * previewMargin}px;
  width: auto;
  text-transform: uppercase;
  text-overflow: ellipsis;
  z-index: 50;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  background-color: rgba(175, 175, 175, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
`;

const defaultLocation = { x: 0, y: 0 };

const PolygonSpotTooltip = ({
  item,
  vault,
  project,
  isMobile,
  onUnitTooltipClicked,
  onNavigationTooltipClicked,
  hoveredLocation,
  parentDimensions,
  zoomScale,
  idealImageRelativePosition,
  setPolygonTooltipHovered,
  polygonTooltipHovered
}) => {
  const [tooltipLocation, setTooltipLocation] = useState(defaultLocation);
  const [tooltipAbsoluteLocation, setTooltipAbsoluteLocation] =
    useState(defaultLocation);
  const [unitTooltipLocation, setUnitTooltipLocation] =
    useState(defaultLocation);
  const [uspTooltipLocation, setUspTooltipLocation] = useState(defaultLocation);
  const [uspTooltipDirection, setUspTooltipDirection] = useState();
  const [uspTooltipHeight, setUspTooltipHeight] = useState(0);
  const [uspTooltipWidth, setUspTooltipWidth] = useState(0);

  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  const { ProjectState } = useProjectState();
  const showUnitState =
    ProjectState?.showcaseConfiguration?.showUnitState ?? true;

  // Refs
  const unitSpotTooltipRef = useRef(null);

  const convertRelativeLocation = useCallback(
    (x, y) => {
      // Get parent image size and container size
      const parentWidth = parentDimensions?.width || 0;
      const parentHeight = parentDimensions?.height || 0;

      // Calculate the half size of the image and the offset of the marker according to the center of the image
      const parentHalfWidth = parentWidth / 2.0;
      const parentHalfHeight = parentHeight / 2.0;

      const centerOffsetX = parentHalfWidth * x;
      const centerOffsetY = parentHalfHeight * y;

      // Add everything together to get the spot location according to the top left corner of the screen
      const scale = 1.0 / zoomScale;
      const cornerOffsetX = (centerOffsetX + parentHalfWidth) * scale;
      const cornerOffsetY = (centerOffsetY + parentHalfHeight) * scale;

      return { x: cornerOffsetX, y: cornerOffsetY };
    },
    [parentDimensions, zoomScale]
  );

  const onTooltipSizeComputed = useCallback(
    (size) => {
      if (size.height === 0) {
        setUnitTooltipLocation(null);
      }

      const unitTooltipFinalPosition = tooltipLocation;
      setUnitTooltipLocation(unitTooltipFinalPosition);
    },
    [tooltipAbsoluteLocation, tooltipLocation]
  );

  useEffect(() => {
    if (hoveredLocation) {
      const tooltipLocation = convertRelativeLocation(
        hoveredLocation.x,
        hoveredLocation.y
      );

      setTooltipLocation(tooltipLocation);

      // default tooltip width + padding
      const defaultOffset = 355;

      // Offset to define max right and max bottom position
      // We need to consider usp tooltip size as it is dinamic,
      // for other tooltips we use offsets that are enough to display the whole tooltip
      const verticalOffset =
        item.assignedItem.navigationItemType === 'uspSpot'
          ? uspTooltipHeight + 100
          : defaultOffset;

      const horizontalOffset =
        item.assignedItem.navigationItemType === 'uspSpot'
          ? uspTooltipWidth + 20
          : defaultOffset;

      // Calculate absolute tooltip location
      // It should be displayed in visible part of the Tour image
      // To ensure that me use the clamp finction
      const absoluteLocation = {
        x: clamp(
          tooltipLocation.x,
          (idealImageRelativePosition.offsetX * -1 + 20) / zoomScale, // min possible X
          (idealImageRelativePosition.offsetX * -1 + // max possible X
            window.innerWidth -
            horizontalOffset) /
            zoomScale
        ),
        y: clamp(
          tooltipLocation.y,
          (idealImageRelativePosition.offsetY * -1 + 20) / zoomScale, // min possible Y
          (idealImageRelativePosition.offsetY * -1 + // max possible Y
            window.innerHeight -
            verticalOffset) /
            zoomScale
        )
      };
      setTooltipAbsoluteLocation(absoluteLocation);
    }
  }, [
    hoveredLocation,
    convertRelativeLocation,
    zoomScale,
    idealImageRelativePosition,
    uspTooltipHeight,
    uspTooltipWidth,
    item
  ]);

  const onUspTooltipSizeComputed = useCallback(
    (size) => {
      const direction = getDisplayDirection(
        tooltipAbsoluteLocation,
        size.width,
        size.height,
        window.innerWidth,
        'tourContainer'
      );
      setUspTooltipHeight(size.height);
      setUspTooltipWidth(size.width);

      const uspTooltipFinalPosition = tooltipLocation;

      setUspTooltipDirection({ ...direction, y: 'bottom' });
      setUspTooltipLocation(uspTooltipFinalPosition);
    },
    [tooltipAbsoluteLocation]
  );

  let content = '';

  // We show this tooltip if either a mediaview or turntable is linked and if the polygon or the tooltip itself is hovered
  if (
    (item.assignedItem.navigationItemType === 'mediaView' ||
      item.assignedItem.navigationItemType === 'turntableSpot' ||
      item.assignedItem.navigationItemType === 'album360Spot') &&
    (hoveredLocation || polygonTooltipHovered)
  ) {
    // for that type of navigationItemType size of tooltip is fixed
    const direction = getDisplayDirection(
      tooltipAbsoluteLocation,
      NAV_ITEM_FIXED_WIDTH,
      NAV_ITEM_FIXED_HEIGHT,
      window.innerWidth
    );

    const tooltipFinalPosition = tooltipLocation;

    content = (
      <PreviewWrapper
        pos={tooltipFinalPosition}
        direction={direction}
        onMouseEnter={() => {
          if (!isMobile) {
            setPolygonTooltipHovered(item?.assignedItem?.objectId);
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setPolygonTooltipHovered('');
          }
        }}
        onClick={() => {
          if (!isMobile) {
            onNavigationTooltipClicked(item);
          }
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onNavigationTooltipClicked(item);
        }}
        scale={1 / zoomScale}
      >
        <ThumbContainer
          initial={{ opacity: 0.5, scale: 0.4 }}
          animate={{
            opacity: thumbnailLoaded ? 1 : 0.5,
            scale: thumbnailLoaded ? 1 : 0.4
          }}
          transition={{ duration: 0.25, type: 'tween' }}
        >
          <TargetThumbnailWrapper>
            {!thumbnailLoaded && <ShowcaseLoader size={3} />}
            <IdealImage
              key={`polygonSpotTooltip${item.objectId}`}
              contentUri={item.contentItemToRender?.contentUri}
              fallbackUri={item.contentItemToRender?.contentUri}
              imageSize={item.contentItemToRender?.originalImageSize}
              containerSize={{
                width: NAV_ITEM_FIXED_WIDTH,
                height: NAV_ITEM_FIXED_HEIGHT
              }}
              enableTracking={false}
              vaultId={vault?.objectId}
              projectId={project?.objectId}
              mustFillParent={true}
              onLoad={() => setThumbnailLoaded(true)}
              baseImageUrl={getCurrentEnvironment().baseImageUrl}
            />
          </TargetThumbnailWrapper>
        </ThumbContainer>
        <NameWrapper
          id={item.objectId}
          key={'name'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {item.title.textMap.en}
        </NameWrapper>
      </PreviewWrapper>
    );
  }

  if (
    item.assignedItem.navigationItemType === 'uspSpot' &&
    (hoveredLocation || polygonTooltipHovered)
  ) {
    const uspSpotObject = {
      ...item.assignedItem,
      usp: { ...item.usp }
    };

    content = item.usp ? (
      <PreviewWrapper
        pos={tooltipAbsoluteLocation}
        key={`unitTooltip${item.assignedItem.objectId}`}
        onMouseEnter={() => {
          if (!isMobile) {
            setPolygonTooltipHovered(item?.assignedItem?.objectId);
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setPolygonTooltipHovered('');
          }
        }}
        onClick={() => {
          if (!isMobile) {
            onNavigationTooltipClicked(item);
          }
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onNavigationTooltipClicked(item);
        }}
        scale={1 / zoomScale}
      >
        <UspSpotTooltip
          source="polygon"
          uspSpotLocation={uspTooltipLocation}
          uspSpotAbsoluteLocation={tooltipAbsoluteLocation}
          givenDirection={uspTooltipDirection}
          onSizeComputed={onUspTooltipSizeComputed}
          spotObject={uspSpotObject}
          idealImageRelativePosition={idealImageRelativePosition}
        />
      </PreviewWrapper>
    ) : null;
  }

  if (
    item.assignedItem.navigationItemType === 'unitSpot' &&
    unitTooltipLocation &&
    item.unitItem
  ) {
    content = (
      <PreviewWrapper
        pos={tooltipAbsoluteLocation}
        key={`unitTooltip${item.assignedItem.objectId}`}
        scale={1 / zoomScale}
        ref={unitSpotTooltipRef}
        tooltipType="unit"
      >
        <UnitSpotTooltip
          origin="polygon"
          shouldShow={!!hoveredLocation || polygonTooltipHovered ? true : false}
          onMouseEnter={() => {
            setPolygonTooltipHovered(item?.assignedItem?.objectId);
          }}
          onMouseLeave={() => {
            setPolygonTooltipHovered('');
          }}
          unitSpotLocation={unitTooltipLocation}
          unitSpotAbsoluteLocation={tooltipAbsoluteLocation}
          unit={item.unitItem}
          onClick={() => {
            if (!isMobile) {
              onUnitTooltipClicked(item);
            }
          }}
          onTouchStart={() => {
            onUnitTooltipClicked(item);
          }}
          showUnitState={showUnitState}
          onSizeComputed={onTooltipSizeComputed}
          zoomScale={zoomScale}
        />
      </PreviewWrapper>
    );
  }

  return content;
};

export default PolygonSpotTooltip;
