import { Tracking } from '@prompto-api';

export const trackUnitAction = (
  actionName,
  unitId,
  ProjectState,
  AuthState
) => {
  const { visitorSessionId, visitorId } = AuthState;
  const { project, vault, shareCode } = ProjectState;

  if (
    project &&
    vault &&
    shareCode &&
    visitorId &&
    visitorSessionId &&
    unitId &&
    actionName
  ) {
    const trackParams = {
      actionName: actionName,
      visitorId: visitorId,
      vaultId: vault.objectId,
      projectId: project.objectId,
      unitId: unitId,
      shareCode: shareCode,
      sessionObjectId: visitorSessionId
    };
    Tracking.trackShowcaseUnitVisitor(trackParams);
    return true;
  }
  return false;
};
