import * as React from 'react';

function StackedSpotsMarker(props) {
  return (
    <svg {...props}>
      <path
        d="M23.18 4.5h-6.36c-4.317 0-5.889.432-7.468 1.277a8.593 8.593 0 0 0-3.575 3.575c-.845 1.58-1.277 3.151-1.277 7.467v6.362c0 4.316.432 5.888 1.277 7.467a8.593 8.593 0 0 0 3.575 3.575c1.58.845 3.151 1.277 7.467 1.277h6.362c4.316 0 5.888-.432 7.467-1.277a8.593 8.593 0 0 0 3.575-3.575c.845-1.58 1.277-3.151 1.277-7.467v-6.362c0-4.316-.432-5.888-1.277-7.467a8.593 8.593 0 0 0-3.575-3.575c-1.58-.845-3.151-1.277-7.467-1.277z"
        fill="#C3C2CA"
        stroke="#FFF"
        fillRule="evenodd"
        fillOpacity=".8"
        strokeOpacity=".3"
      />
    </svg>
  );
}

export default StackedSpotsMarker;
