import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

// Components
import { motion } from 'framer-motion';

// Helpers
import { capitalize, isDarkColor } from 'helpers';
import localizer from 'localization/localizer';

// Styling
import styled, { withTheme } from 'styled-components';
import {
  Title,
  PersonWrapper,
  Avatar,
  Name,
  Occupation,
  Contact,
  Contacts
} from './sharedStyledComponents';

const Modal = styled(motion.div)`
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.fadeBlack};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(motion.div)`
  width: calc(100% - 30px);
  height: calc(100% - 60px);
  max-width: 480px;
  max-height: 600px;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  border-radius: 4px;
  padding: 30px 15px 100px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: column;
`;

const ModalFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ cardColor }) => cardColor};
    filter: contrast(0.85);
  }
`;

const DoneButton = styled.div`
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.gray200};
  box-sizing: border-box;
  color: ${({ color }) => color};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.88px;
  line-height: 18px;
  outline: none;
  text-align: center;
  height: 40px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  > p {
    margin: auto 0;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ContactsWrapper = styled.div``;

const imageRequiredHeight = 180;
const infoRequiredHeight = 90;
const margin = 20;

const SalesPersonModal = ({ onClose, salesPerson, theme }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [contentHeight, setContentHeight] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [infoHeight, setInfoHeight] = useState(0);
  const [contactsHeight, setContactsHeight] = useState(0);

  const contentRef = useRef();
  const titleRef = useRef();
  const contactsRef = useRef();
  const infoRef = useRef();

  // calculate heights of components
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
    if (contactsRef.current) {
      setContactsHeight(contactsRef.current.offsetHeight);
    }
    if (titleRef.current) {
      setTitleHeight(titleRef.current.offsetHeight);
    }
    if (showInfo && infoRef.current) {
      setInfoHeight(infoRef.current.offsetHeight);
    }
  }, [showInfo]);

  // display info if possible
  useEffect(() => {
    if (!contentHeight || !contactsHeight || !titleHeight) return;
    const canShowInfo =
      contentHeight - contactsHeight - titleHeight >
      margin + infoRequiredHeight + margin;
    setShowInfo(canShowInfo);
  }, [contentHeight, contactsHeight, titleHeight]);

  // display image if possible
  useEffect(() => {
    // no need for calculation if info is not displayed or if an image is already shown
    if (!showInfo) return;
    if (!contentHeight || !contactsHeight || !titleHeight || !infoHeight)
      return;
    const canShowImage =
      contentHeight -
        contactsHeight -
        titleHeight -
        margin -
        infoHeight -
        margin >
      imageRequiredHeight;
    setShowImage(canShowImage);
  }, [
    contentHeight,
    contactsHeight,
    showInfo,
    titleHeight,
    showImage,
    infoHeight
  ]);

  const cardColor = theme.contactCardBg;
  const bgIsDark = isDarkColor(cardColor);

  return (
    <Modal
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ModalContent
        bg={cardColor}
        color={bgIsDark ? theme.showcaseWhite : theme.showcaseBlack}
      >
        <Content ref={contentRef}>
          <Title ref={titleRef}>
            {localizer.marketplace.contactYourManager}
          </Title>

          {showInfo && (
            <PersonWrapper>
              {showImage && (
                <Avatar
                  rounded={!!salesPerson?.profilePictureUri}
                  src={
                    salesPerson?.profilePictureUri ||
                    salesPerson?.profilePicturePlaceholder
                  }
                  alt=""
                />
              )}
              <Info ref={infoRef}>
                <Name>{`${salesPerson?.firstName ?? ''} ${
                  salesPerson?.lastName ?? ''
                }`}</Name>
                {salesPerson?.role && (
                  <Occupation>{salesPerson.role}</Occupation>
                )}
              </Info>
            </PersonWrapper>
          )}

          <Contacts>
            <ContactsWrapper ref={contactsRef}>
              {salesPerson?.contacts.map((x, i) => (
                <Contact
                  key={i}
                  icon={x.icon}
                  value={x.value}
                  index={i}
                  action={x.action}
                  color={bgIsDark ? theme.showcaseWhite : theme.showcaseBlack}
                  isDarkBg={bgIsDark}
                />
              ))}
            </ContactsWrapper>
          </Contacts>
        </Content>

        <ModalFooter cardColor={cardColor}>
          <DoneButton
            onClick={onClose}
            color={bgIsDark ? theme.showcaseWhite : theme.showcaseBlack}
          >
            <p>{capitalize(localizer.done)}</p>
          </DoneButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default withTheme(SalesPersonModal);
