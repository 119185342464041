import React from 'react';

// Components
import { motion } from 'framer-motion';

// Styling
import styled, { css } from 'styled-components';

// Helpers
import localizer from 'localization/localizer';
import { isMobileOnly } from 'react-device-detect';

const NavigationWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: ${(props) =>
    props.current ? props.theme.primary300 : props.theme.gray300};
`;

const Button = styled(motion.button)`
  min-width: 160px;
  height: 40px;
  margin: 0;
  padding: 10px 0;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.gray200};
  background: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.mainFontFamily};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary300};
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  cursor: pointer;
  ${isMobileOnly &&
  css`
    min-width: 100px;
    color: ${({ theme }) => theme.primary100};
  `}
`;

const LetsStartButton = styled(Button)`
  height: 50px;
  min-width: 200px;
  background: ${({ theme }) => theme.mainAccent};
  color: ${({ theme }) => theme.showcaseWhite};
  ${isMobileOnly &&
  css`
    width: 100%;
    height: 40px;
    margin: 0 15px;
    background: ${({ theme }) => theme.brand500};
  `}
`;

const Navigation = ({
  currentFilterIdx,
  filters,
  onPrev,
  onNext,
  onGetStarted
}) => {
  const isFirst = currentFilterIdx === 0;
  const isLast = currentFilterIdx === filters.length - 1;

  const L = localizer.wizard;

  return (
    <NavigationWrapper>
      {isMobileOnly && isLast ? (
        <LetsStartButton onClick={onGetStarted}>{L.letsStart}</LetsStartButton>
      ) : (
        <>
          <Button
            onClick={onPrev}
            disabled={isFirst}
            hidden={isFirst}
            animate={isFirst ? { x: -250 } : { x: 0 }}
          >
            {L.prev}
          </Button>
          <DotsWrapper>
            {filters.map((p, idx) => (
              <Dot key={idx} current={idx === currentFilterIdx} />
            ))}
          </DotsWrapper>
          {isLast ? (
            <LetsStartButton onClick={onGetStarted}>
              {L.letsStart}
            </LetsStartButton>
          ) : (
            <Button onClick={onNext} disabled={isLast}>
              {isMobileOnly ? L.next : L.nextQuestion}
            </Button>
          )}
        </>
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
