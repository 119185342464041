import React, { forwardRef } from 'react';

// Components
import PortfolioCard from '../PortfolioCard';
import { motion, AnimatePresence } from 'framer-motion';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import { view } from '../PortfolioPage';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import to from 'await-to-js';
import { NavigationCollection } from '@prompto-api';

// Styling
import styled from 'styled-components';

const ProjectCardWrapper = styled(motion.div)`
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
`;

const MobileSelectedProjectCard = forwardRef(
  (
    {
      project,
      shouldShowPrices,
      shouldShowStatuses,
      vaultSettings,
      mapInstance,
      sessionToken,
      onShowcaseSelected
    },
    ref
  ) => {
    // Marketplace state
    const { MarketplaceState, MarketplaceStateDispatch } =
      useMarketplaceState();
    const { projectsData } = MarketplaceState;

    return (
      <AnimatePresence>
        {isMobileOnly && project && (
          <ProjectCardWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PortfolioCard
              ref={ref}
              origin={view.map}
              project={project}
              unitsData={projectsData[project.objectId]?.units}
              configuration={projectsData[project.objectId]?.configuration}
              status={projectsData[project.objectId]?.status}
              vaultSettings={vaultSettings}
              thumbnailUri={project.thumbnailUri}
              highlighted={true}
              onCardClick={async () => {
                const contentCollectionObjectId =
                  project.vmContentCollection?.objectId;

                // get the id of the first navigationItem in the collection
                let navigationItemObjectId;
                const navCollId = project?.navigationCollection?.objectId;
                if (navCollId) {
                  const [, result] = await to(
                    NavigationCollection.get(navCollId, true, sessionToken)
                  );
                  if (result) {
                    const navCollection = result.data?.navigationCollection;
                    const navItemList = navCollection?.vmNavigationItemList;
                    if (navItemList && navItemList.length > 0) {
                      navigationItemObjectId = navItemList[0].objectId;
                    }
                  }
                }

                MarketplaceStateDispatch({
                  type: 'updateSavedUiState',
                  payload: {
                    mapMobile: {
                      clickedMarker: project,
                      bounds: mapInstance.getBounds(),
                      zoom: mapInstance.getZoom()
                    }
                  }
                });

                onShowcaseSelected(
                  contentCollectionObjectId,
                  navigationItemObjectId,
                  project.objectId
                );
              }}
              shouldShowPrices={shouldShowPrices}
              shouldShowStatuses={shouldShowStatuses}
              forceShowUnitsData={true}
            />
          </ProjectCardWrapper>
        )}
      </AnimatePresence>
    );
  }
);

export default MobileSelectedProjectCard;
