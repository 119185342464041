import * as React from 'react';

const SortingIcon = React.forwardRef((props, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      ref={ref}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={props.color} fillRule="nonzero">
          <g>
            <path
              d="M16.905 2.157c.21-.21.549-.21.758 0l4.179 4.2c.209.21.209.549 0 .758l-.316.315c-.21.21-.548.21-.758 0l-2.725-2.746v16.78c0 .296-.24.536-.535.536h-.447c-.296 0-.536-.24-.536-.536V4.684L13.8 7.43c-.209.21-.548.21-.757 0l-.316-.315c-.21-.21-.21-.549 0-.758zM6.938 2c.295 0 .535.24.535.536v16.78l2.725-2.746c.21-.21.549-.21.758 0l.316.315c.209.21.209.549 0 .758l-4.179 4.2c-.21.21-.548.21-.758 0l-4.178-4.2c-.21-.21-.21-.549 0-.758l.316-.315c.209-.21.548-.21.757 0l2.725 2.746V2.536c0-.296.24-.536.536-.536h.447z"
              transform="translate(-353.000000, -38.000000) translate(353.000000, 38.000000) translate(11.999289, 12.000000) scale(-1, 1) translate(-11.999289, -12.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});

export default SortingIcon;
