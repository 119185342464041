import * as React from 'react';

function SvgFullscreenIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M.818 6.224v-4.53c0-.485.391-.876.877-.876h4.53a.44.44 0 01.437.438v.877a.44.44 0 01-.438.438H2.571v3.653a.44.44 0 01-.438.438h-.876a.44.44 0 01-.439-.438zm10.52-4.968v.877a.44.44 0 00.438.438h3.653v3.653a.44.44 0 00.438.438h.877a.44.44 0 00.438-.438v-4.53a.875.875 0 00-.877-.876h-4.529a.44.44 0 00-.438.438zm5.406 10.081h-.877a.44.44 0 00-.438.439v3.652h-3.653a.44.44 0 00-.438.439v.876a.44.44 0 00.438.439h4.53c.485 0 .876-.391.876-.877v-4.53a.44.44 0 00-.438-.438zM6.662 16.743v-.876a.44.44 0 00-.438-.439H2.571v-3.652a.44.44 0 00-.438-.439h-.876a.44.44 0 00-.439.439v4.529c0 .486.391.877.877.877h4.53a.44.44 0 00.437-.439z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgFullscreenIcon;
