import * as React from 'react';

function SvgNoPreviewPlaceholderMasked(props) {
  return (
    <svg width={501} height={186} {...props}>
      <g fill="#FDFDFD" fillRule="evenodd">
        <path
          d="M380.695.66L159.716 190.5h441.958L380.695.66z"
          strokeOpacity={0.35}
          stroke="#A09EAC"
        />
        <path
          d="M59.695 51.66l-160.85 138.84h321.7L59.696 51.66z"
          strokeOpacity={0.35}
          stroke="#A09EAC"
        />
        <path
          d="M235.195 77.659L103.715 190.5h262.96L235.195 77.659z"
          strokeOpacity={0.4}
          stroke="#D9BB4D"
        />
      </g>
    </svg>
  );
}

export default SvgNoPreviewPlaceholderMasked;
