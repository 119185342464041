import React from 'react';

import styled, { css } from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Title = styled(motion.h3)`
  font-size: ${isMobileOnly ? 1.25 : 1.5}rem;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;
  margin: 0;
  width: 100%;
`;

export const PersonWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 20px 0;
  transition: all 250ms ease;
`;

export const Avatar = styled.img`
  height: 160px;
  width: 160px;
  border-radius: ${({ rounded }) => (rounded ? 50 : 0)}%;
  object-position: center;
  object-fit: cover;
  margin-bottom: 20px;
`;

export const Name = styled.div`
  font-size: ${isMobileOnly ? 1.875 : 2.25}rem;
  line-height: 1.33;
  text-align: center;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Occupation = styled.div`
  font-size: ${isMobileOnly ? 1 : 1.25}rem;
  line-height: 1.875rem;
  text-align: center;
`;

export const ContactButton = styled(motion.button)`
  height: 60px;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.3);
  background-color: ${({ theme }) => theme.showcaseWhite};
  color: ${({ theme }) => theme.brand500};
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  ${isMobileOnly &&
  css`
    padding: 10px;
    font-size: 0.875rem;
  `}
`;

export const ContactsWrapper = styled.div`
  width: 100%;
  margin-top: ${({ contactsShown }) => (contactsShown ? '0px' : 'auto')};
  flex-grow: ${({ contactsShown }) => (contactsShown ? 1 : 0)};
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

export const Contacts = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const ContactItem = styled(motion.div)`
  min-height: 50px;
  margin-bottom: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  backdrop-filter: blur(10px);
  background-color: rgba(
    ${({ isDarkBg }) => (isDarkBg ? '255, 255, 255, 0.2' : '0, 0, 0, 0.05')}
  );
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  letter-spacing: 0.03rem;
  &:last-child {
    margin: 0;
  }
  a {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    white-space: nowrap;
    margin: 0 0 0 auto;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ContactItemIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 10px;
`;

const ContactValue = styled.span`
  margin: 0;
  margin-right: 6px;
  word-break: break-all;
  color: inherit;
`;

export const Contact = ({ icon, value, action, index, color, isDarkBg }) => (
  <ContactItem
    initial={{ opacity: 0, x: index % 2 ? -20 : 20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 0.2 * index + 0.4, duration: 0.8 }}
    color={color}
    isDarkBg={isDarkBg}
  >
    <ContactItemIcon icon={['fal', icon]} size="1x" />
    <ContactValue>{value}</ContactValue>
    {action && <a href={`${action.type}:${value}`}>{action.label}</a>}
  </ContactItem>
);
