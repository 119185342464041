import React, { useState, useEffect } from 'react';
import { string, number, shape, func } from 'prop-types';

// Helpers
import localizer from 'localization/localizer';
import { formatUnitfieldValue } from 'helpers/units/VmUnitHelper';
import ReactSlider from 'react-slider';
import { isMobile } from 'react-device-detect';

// Styling
import styled from 'styled-components';

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  flex-shrink: 0;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const Label = styled.div`
  color: ${(props) =>
    isMobile ? props.theme.showcaseBlack : props.theme.primary200};
  font-size: 0.9rem;
`;

const Value = styled.div`
  color: ${(props) => props.theme.gray400};
  font-size: 0.7rem;
`;

const StyledSlider = styled(ReactSlider)`
  height: 25px;
  display: flex;
  align-items: center;
`;

const StyledThumb = styled.div`
  height: 15px;
  width: 15px;
  background-color: white;
  border: 2px solid ${(props) => props.theme.gray300};
  border-radius: 50%;
  outline: none;
  cursor: grab;
  ${isMobile &&
  `
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  `}
`;

const StyledTrack = styled.div`
  height: 7px;
  border-radius: 5px;
  background: ${({ index, theme }) =>
    index === 1 ? (isMobile ? theme.successColor : '#69BF4A') : theme.gray300};
`;

/**
 * Component that displays a slider for a filter with a min and max value
 */
const FilterSlider = ({
  filterObject,
  onUpdateValues,
  className,
  disabled
}) => {
  const [localValues, setLocalValues] = useState(
    filterObject.values ?? filterObject.limits
  );

  const [step, setStep] = useState(
    Math.ceil((filterObject.limits.max - filterObject.limits.min) / 100)
  );

  useEffect(() => {
    setLocalValues(filterObject.values);
  }, [filterObject]);

  useEffect(() => {
    // In some cases, it is not possible to reset back the slider to the maximum value, so in order
    // to prevent these issues with the StyledSlider component, we dynamically calculate the range
    // step by halving the step value
    let numberOfSteps =
      (filterObject.limits.max - filterObject.limits.min) / step;
    let needToFixValues = Math.ceil(numberOfSteps) - numberOfSteps > 0.5;
    if (needToFixValues) {
      setStep(Math.ceil(step / 2));
    }
  }, [step]);

  return (
    <VerticalWrapper
      data-testid={`filterSlider${filterObject.filterOption}`}
      className={className}
      disabled={disabled}
    >
      <Label>{localizer.unitFields[filterObject.filterOption]}</Label>
      <StyledSlider
        min={filterObject.limits.min}
        max={filterObject.limits.max}
        disabled={
          filterObject.limits.min === filterObject.limits.max || disabled
        }
        step={step}
        value={[localValues.min, localValues.max]}
        onChange={(values) => {
          const max =
            values[1] > filterObject.limits.max
              ? filterObject.limits.max
              : Math.max(values[1], filterObject.limits.min);
          const min =
            values[0] < filterObject.limits.min
              ? filterObject.limits.min
              : Math.min(filterObject.limits.max, values[0]);

          onUpdateValues({ min, max });
          setLocalValues({ min, max });
        }}
        renderTrack={(props, state) => {
          return (
            <StyledTrack {...props} index={state.index}>
              {state.valueNow}
            </StyledTrack>
          );
        }}
        renderThumb={(props, state) => {
          return <StyledThumb {...props} />;
        }}
      />
      {!disabled && (
        <ValueWrapper>
          <Value>
            {formatUnitfieldValue(localValues.min, filterObject.filterOption)}
          </Value>
          <Value>
            {formatUnitfieldValue(localValues.max, filterObject.filterOption)}
          </Value>
        </ValueWrapper>
      )}
    </VerticalWrapper>
  );
};

FilterSlider.propTypes = {
  /** The object that holds the data needed to filter */
  filterObject: shape({
    filterOption: string.isRequired,
    values: shape({ min: number.isRequired, max: number.isRequired }),
    limits: shape({ min: number.isRequired, max: number.isRequired })
  }).isRequired,
  /** Callback when the values are updated
   * @callback
   * @param {{min: number, max: number}} updatedValues The values that were updated
   */
  onUpdateValues: func.isRequired
};

export default FilterSlider;
