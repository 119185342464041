import { useState, useEffect } from 'react';

// Helpers
import TagManager from 'react-gtm-module';
import { useProjectState } from 'stores/ProjectStore';

const useGoogleTagManager = () => {
  // Project store
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  const [isGtmScriptInjected, setIsGtmScriptInjected] = useState(false);

  useEffect(() => {
    const gtmId = showcaseConfiguration?.googleTagManager;
    if (!gtmId) return;
    if (isGtmScriptInjected) return;

    const tagManagerArgs = {
      gtmId
    };
    TagManager.initialize(tagManagerArgs);
    setIsGtmScriptInjected(true);
  }, [isGtmScriptInjected, showcaseConfiguration]);
};

export default useGoogleTagManager;
