import React, { forwardRef, useState } from 'react';
import { func, bool, array, string } from 'prop-types';

// Styling
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 11;
  top: 100%;
  right: 0;
  width: 170px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray300};
  background-color: ${(props) => props.theme.showcaseWhite};
  font-weight: 400;
`;

const ItemsList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

const SubList = styled(ItemsList)``;

const SortingItem = styled.li`
  position: relative;
  font-size: 1rem;
  color: ${(props) =>
    props.selected ? props.theme.showcaseBlack : props.theme.primary100};
  width: 100%;
  height: ${(props) => props.height};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px 5px 5px 30px;
  box-sizing: border-box;
  border-top: ${({ chosen, theme }) =>
    chosen ? `1px solid ${theme.gray200}` : 'none'};
  border-bottom: ${({ chosen, theme }) =>
    chosen ? `1px solid ${theme.gray200}` : 'none'};
`;

const CheckedItemIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${(props) => props.theme.primary300};
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(9px, 60%);
`;

const SubItem = styled(SortingItem)``;

const getSortingOptionLabel = (field) => {
  let label;
  switch (field) {
    case 'title':
      label = 'unitName';
      break;
    case 'state':
      label = 'unitStatus';
      break;
    default:
      label = field;
  }
  return localizer.unitFields[label];
};

const MobileSortingPopup = forwardRef(
  (
    {
      unitSortableFields = [],
      currentFieldToSort,
      isSortAscending,
      onSortByChanged,
      onClose
    },
    ref
  ) => {
    const [chosenFieldToSort, setChosenFieldToSort] =
      useState(currentFieldToSort);

    return (
      <AnimatePresence>
        <Wrapper
          ref={ref}
          initial={{
            opacity: 0,
            transform: 'scaleY(0)',
            transformOrigin: 'center top'
          }}
          animate={{ opacity: 1, transform: 'scaleY(1)' }}
          exit={{ opacity: 0, transform: 'scaleY(0)' }}
        >
          {capitalize(localizer.sortBy)}
          <ItemsList>
            {unitSortableFields.map((field) => (
              <SortingItem
                key={field}
                selected={field === chosenFieldToSort}
                onClick={(e) => {
                  e.stopPropagation();
                  setChosenFieldToSort(field);
                }}
                height={field === chosenFieldToSort ? 'auto' : '30px'}
                chosen={field === chosenFieldToSort}
              >
                {field === chosenFieldToSort && (
                  <CheckedItemIcon icon={['far', 'check']} size="1x" />
                )}
                {getSortingOptionLabel(field)}
                {field === chosenFieldToSort && (
                  <SubList>
                    {[
                      { label: capitalize(localizer.ascending), isAsc: true },
                      {
                        label: capitalize(localizer.descending),
                        isAsc: false
                      }
                    ].map((opt) => (
                      <SubItem
                        key={opt.label}
                        selected={
                          field === currentFieldToSort &&
                          isSortAscending === opt.isAsc
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          onSortByChanged(field, opt.isAsc);
                          onClose();
                        }}
                      >
                        {field === currentFieldToSort &&
                          isSortAscending === opt.isAsc && (
                            <CheckedItemIcon
                              icon={['far', 'check']}
                              size="1x"
                            />
                          )}
                        {opt.label}
                      </SubItem>
                    ))}
                  </SubList>
                )}
              </SortingItem>
            ))}
          </ItemsList>
        </Wrapper>
      </AnimatePresence>
    );
  }
);

MobileSortingPopup.propTypes = {
  unitSortableFields: array.isRequired,
  currentFieldToSort: string,
  isSortAscending: bool,
  onSortByChanged: func.isRequired,
  onClose: func.isRequired
};

MobileSortingPopup.defaultProps = {
  currentFieldToSort: '',
  isSortAscending: false
};

export default MobileSortingPopup;
