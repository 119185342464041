import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { useMarketplaceState } from 'stores/MarketplaceStore';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  ${(props) => props.wrapperStyles};
`;

const Button = styled.button`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  background-color: ${({ cardColor }) =>
    cardColor === 'white' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
  transition: background-color 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.noBackground &&
    `
    background-color: transparent;
    border: none;
  `}
`;

const HeartIcon = styled(FontAwesomeIcon)`
  font-size: ${(props) => props.iconsize}px;
  color: ${({ theme }) => theme.showcaseWhite};
`;

const FavoriteProjectButton = (props) => {
  const {
    buttonSize = 40,
    iconSize = 22,
    projectId,
    wrapperStyles,
    noBackground = false,
    cardColor
  } = props;

  // Project state
  const { MarketplaceState, MarketplaceStateDispatch } = useMarketplaceState();
  const { favoritesList, providedFavoritesList, userFavoritesList } =
    MarketplaceState;

  const toggleFavorite = () => {
    if (
      providedFavoritesList.includes(projectId) &&
      !userFavoritesList.includes(projectId)
    ) {
      MarketplaceStateDispatch({
        type: 'setProvidedFavorites',
        payload: [...providedFavoritesList.filter((id) => id !== projectId)]
      });
    } else if (
      providedFavoritesList.includes(projectId) &&
      userFavoritesList.includes(projectId)
    ) {
      MarketplaceStateDispatch({
        type: 'setProvidedFavorites',
        payload: [...providedFavoritesList.filter((id) => id !== projectId)]
      });
      MarketplaceStateDispatch({
        type: 'toggleFavorites',
        payload: projectId
      });
    } else {
      MarketplaceStateDispatch({
        type: 'toggleFavorites',
        payload: projectId
      });
    }
  };

  const isFavorite = favoritesList.includes(projectId);

  return (
    <Wrapper wrapperStyles={wrapperStyles}>
      <Button
        size={buttonSize}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleFavorite();
        }}
        isFavorite={isFavorite}
        noBackground={noBackground}
        cardColor={cardColor}
      >
        <HeartIcon
          icon={[isFavorite ? 'fas' : 'fal', 'heart']}
          iconsize={iconSize}
          size="1x"
          favorite={isFavorite ? 1 : 0}
        />
      </Button>
    </Wrapper>
  );
};

export default FavoriteProjectButton;
