import React from 'react';

// Helpers
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const PreviewWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 50;
  pointer-events: 'none';
  transform: translateX(-50%);
  bottom: calc(100% + 40px);
`;

const DottedLineWrapper = styled.div`
  width: 3px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100%;
`;

const DottedLine = styled.div`
  width: 2px;
  height: 40px;
  background-image: linear-gradient(
    to top,
    white 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: right;
  background-size: 2px 10px;
  background-repeat: repeat-y;
`;

const UnitInfoWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
`;

const UnitCard = styled.div`
  width: 200px;
  max-width: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray300};
  background-color: ${(props) => props.theme.whitePure};
`;

const SimplifiedUnitCard = styled(UnitCard)`
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
`;

const UnitTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const CenteredUnitTitle = styled(UnitTitle)`
  text-align: center;
`;

const UnitNameTooltip = ({ unit }) => {
  return (
    <PreviewWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, type: 'tween' }}
    >
      <DottedLineWrapper>
        <DottedLine />
      </DottedLineWrapper>

      <UnitInfoWrapper>
        <SimplifiedUnitCard>
          <CenteredUnitTitle>{unit?.title}</CenteredUnitTitle>
        </SimplifiedUnitCard>
      </UnitInfoWrapper>
    </PreviewWrapper>
  );
};

export default UnitNameTooltip;
