import React, { useCallback, useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

// Styling
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnitStateBadge from 'components/unit/UnitStateBadge';
import HubspotForm from 'components/share/HubspotForm';
import DefaultForm from '../DefaultForm';

// Helpers
import currencySymbolMap from 'currency-symbol-map';
import { shouldShowUnitPrice } from 'helpers/units/VmUnitHelper';
import localizer, { defaultNumeralLocale } from 'localization/localizer';
import {
  useGetUnitImagePreview,
  useShowUnitGeneralFields
} from 'helpers/customHooks';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import {
  getCurrentEnvironment,
  capitalize,
  fetchSettingsFromURL,
  getValueFromLocalized,
  getUnitPrice
} from 'helpers';
import { isMobileOnly } from 'react-device-detect';
import HubspotProvider from 'helpers/customHooks/useHubspotContext';
import { useUiState } from 'stores/UiStore';
import qs from 'query-string';
import { trackUnitAction } from 'components/pages/unitDetail/tracking/UnitTrackingHelper';

const Modal = styled.div`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  ${(props) =>
    props.show &&
    `
    width: 100%;
    height: 100%;
  `}
`;

const Form = styled(motion.div)`
  position: absolute;
  width: 680px;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  background-color: ${(props) => props.theme.showcaseWhite};
  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 5px;
  padding-bottom: 20px;
  box-sizing: border-box;
  ${isMobileOnly &&
  `
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  `}
`;

const NoFavoritesModalContent = styled(Form)`
  width: 420px;
  padding: 40px;
  align-items: center;
`;

const ModalTitle = styled.div`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.showcaseBlack};
  padding: 20px 20px 0 20px;
  position: relative;
  ${isMobileOnly &&
  `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    border-bottom: 1px solid #edecf0;
    flex-shrink: 0;
    position: relative;
    padding: 0;
  `}
`;

const ModalDescription = styled.div`
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.showcaseBlack};
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
  ${isMobileOnly &&
  `
    text-align: center;
    padding: 10px 40px;
    max-width: 70%;
  `}
`;

const NoFavoritesModalTitle = styled(ModalTitle)`
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0;
  margin: 0 0 10px;
  text-align: center;
`;

const GotItButton = styled.div`
  background: ${(props) => props.theme.showcaseWhite};
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.gray200};
  color: #574f6b;
  font-size: 0.875rem;
  font-weight: 600;
  outline: none;
  text-align: center;
  height: 40px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  > p {
    margin: auto 0;
  }
`;

const NoFavoritesModalText = styled.p`
  font-size: 0.875rem;
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0 0 30px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  background: none;
  outline: none;
  border: none;
  width: fit-content;
  cursor: pointer;
  ${isMobileOnly &&
  `
    left: 15px;
    top: ${(props) => (props.noFavorites ? '15px' : '50%')};
    transform: translateY(${(props) => (props.noFavorites ? '-25px' : '-50%')});
  `}
  ${!isMobileOnly &&
  `
    right: 10px;
    top: 10px;
  `}
`;

const NoFavoritesCloseButton = styled(CloseButton)`
  ${isMobileOnly &&
  `
    left: 15px;
    top: 15px;
  `}
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 21px;
  color: ${(props) => (isMobileOnly ? props.theme.primary300 : '#333')};
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 5px;
  color: ${(props) => props.theme.primary100};
`;

const StyledUnitTypeIcon = styled(StyledPriceIcon)``;

const UnitPreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.gray200};
  border-radius: 2px;
  padding: ${isMobileOnly ? '10px 15px' : '20px'};
`;

const UnitStateTag = styled(UnitStateBadge)`
  position: absolute;
  right: 15px;
  top: ${isMobileOnly ? '-13px' : '-20px'};
  font-size: ${isMobileOnly ? '12px' : '14px'};
  color: ${(props) => props.theme.showcaseWhite};
  border-radius: 20px;
  min-width: 5rem;
  height: ${isMobileOnly ? '26px' : '40px'};
`;

const NoPreviewPlaceholder = styled.div`
  width: 80px;
  height: 80px;
  padding: 8px;
  box-sizing: border-box;
  margin-right: ${isMobileOnly ? '15px' : '20px'};
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  font-size: 0.75rem;
  color: ${(props) => props.theme.fadeGray};
  flex-shrink: 0;
`;

const PreviewImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: ${isMobileOnly ? '15px' : '20px'};
  border-radius: 2px;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
`;

const UnitMainInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnitTypeAndPrice = styled.div`
  display: flex;
  flex-direction: ${isMobileOnly ? 'column' : 'row'};
  align-items: ${isMobileOnly ? 'flex-start' : 'center'};
  flex-wrap: wrap;
`;

const UnitType = styled.div`
  height: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

const UnitPriceStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnitPrice = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
  display: flex;
  align-items: center;
`;

const Price = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding-left: 5px;
  color: inherit;
`;

const UnitTitle = styled.div`
  font-size: ${isMobileOnly ? '1rem' : '1.5rem'};
  font-weight: 700;
  color: ${(props) => props.theme.showcaseBlack};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${isMobileOnly ? '5px' : '10px'};
  word-break: break-word;
`;

const UnitPreviewBlockWrapper = styled.div`
  padding: 30px 20px 20px;
  ${isMobileOnly &&
  `
    padding: 23px 15px 17px;
    border-bottom: 1px solid ${(props) => props.theme.gray200};
  `}
`;

const FormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
`;

const contactFormType = {
  unitSpecific: 'unitSpecific',
  generic: 'generic'
};

const getFavoriteUnitsFieldName = (projectTitle) => {
  const formattedTitle = projectTitle.toLowerCase().replace(/ /g, '_');
  return `${formattedTitle}___units`;
};

const ContactUsModal = () => {
  const params = fetchSettingsFromURL();

  const [showErrorDefaultMsg, setShowErrorDefaultMsg] = useState(false);
  const [showSuccessDefaultMsg, setShowSuccessDefaultMsg] = useState(false);
  const [showDefaultForm, setShowDefaultForm] = useState(false);
  const [canRenderHubspotForm, setCanRenderHubspotForm] = useState(false);
  const [env] = useState(getCurrentEnvironment());
  const [baseLink] = useState(`https://${env.applicationUrl}`);
  const [envParam] = useState(params.env ? `?env=${params.env}` : '');
  const [unitDetailslUrl, setUnitDetailsUrl] = useState('');
  const [favoriteUnitsUrl, setFavoriteUnitsUrl] = useState('');
  const [formType, setFormType] = useState(contactFormType.unitSpecific);
  const [favoriteUnitTitles, setFavoriteUnitTitles] = useState('');

  const [hubspotFormLang, setHubspotFormLang] = useState();

  // Auth state
  const { AuthState } = useAuthState();

  // Project state
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { showcaseConfiguration, vault, project, shareCode, favoritesList } =
    ProjectState;

  const embededUnitFormConfiguration =
    showcaseConfiguration?.embededUnitFormConfiguration;
  const unitList = project?.unitList;

  // UI state
  const { UiState, UiStateDispatch } = useUiState();
  const { showContactUsModal, contactUsActiveUnit, isEmbeddedShowcase } =
    UiState;

  const { previewImageUrl } = useGetUnitImagePreview(
    contactUsActiveUnit,
    null,
    `h=80`
  );
  const { showPrice } = useShowUnitGeneralFields(false, null, [
    contactUsActiveUnit
  ]);

  const showUnitPrice = shouldShowUnitPrice(
    showcaseConfiguration,
    AuthState,
    contactUsActiveUnit?.state
  );

  const showUnitState = showcaseConfiguration?.showUnitState ?? true;

  const currencySymbol = currencySymbolMap(
    vault?.vaultSettings?.defaultCurrencyCode || 'EUR'
  );
  const unitPrice = `${currencySymbol} ${getUnitPrice(contactUsActiveUnit)}`;

  const closeModal = (e) => {
    setShowSuccessDefaultMsg(false);
    setShowErrorDefaultMsg(false);
    e.stopPropagation();
    UiStateDispatch({
      type: 'update',
      payload: { showContactUsModal: false, contactUsActiveUnit: null }
    });
  };

  // build link to Unit details page
  useEffect(() => {
    if (baseLink && shareCode && contactUsActiveUnit?.objectId) {
      setUnitDetailsUrl(
        `${baseLink}/${shareCode}/unit/${contactUsActiveUnit.objectId}${envParam}`
      );
    }
  }, [baseLink, shareCode, contactUsActiveUnit, envParam]);

  // build link to Favorite units page
  useEffect(() => {
    if (baseLink && shareCode) {
      setFavoriteUnitsUrl(
        `${baseLink}/${shareCode}/favorites?${qs.stringify(params)}`
      );
    }
  }, [baseLink, shareCode, params]);

  // create favorite units titles list
  useEffect(() => {
    const favoriteUnitsIDs = params?.fav?.split(',');
    const unitTitles = Array.isArray(favoriteUnitsIDs)
      ? unitList
          ?.filter((unit) => favoriteUnitsIDs.includes(unit.objectId))
          .map((unit) => unit.title)
          .join(';')
      : 'No favorite units';
    setFavoriteUnitTitles(unitTitles);
  }, [unitList, params]);

  useEffect(() => {
    setHubspotFormLang(
      showContactUsModal ? localizer.getLanguage() : undefined
    );
  }, [showContactUsModal]);

  useEffect(() => {
    if (embededUnitFormConfiguration) {
      const {
        contactUsFormId,
        contactUsPortalId,
        showContactUsFormAsGeneric,
        formType
      } = embededUnitFormConfiguration;
      setShowDefaultForm(formType === 'promptoDefaultForm');
      // fallback to form in english if there's no ID for current language
      const formId = getValueFromLocalized(
        contactUsFormId,
        contactUsFormId?.textMap?.en
      );
      setCanRenderHubspotForm(contactUsPortalId && formId);

      // set form type
      if (showContactUsFormAsGeneric === true) {
        setFormType(contactFormType.generic);
      }
    }
  }, [embededUnitFormConfiguration]);

  const renderImagePreviewOrPlaceholder = useCallback(() => {
    if (previewImageUrl) {
      return <PreviewImage src={previewImageUrl} />;
    }
    return (
      <NoPreviewPlaceholder>
        {capitalize(localizer.noAttachmentForUnit)}
      </NoPreviewPlaceholder>
    );
  }, [previewImageUrl]);

  const bannerText = getValueFromLocalized(
    embededUnitFormConfiguration?.localizedContactUsBannerText,
    embededUnitFormConfiguration?.contactUsBannerText ||
      localizer.formatString(
        localizer.greatChoice,
        <span>{localizer.specialOffer}.</span>
      )
  );

  const shouldAskToFillInFavorites =
    favoritesList.length === 0 &&
    embededUnitFormConfiguration?.showContactUsFormAsGeneric === true &&
    embededUnitFormConfiguration?.askToFillInFavorites;

  const modalTitle = getValueFromLocalized(
    embededUnitFormConfiguration?.localizedCustomButtonName,
    embededUnitFormConfiguration?.customButtonName || localizer.contactUs
  );

  const hsFormWrapperRef = useRef();
  const hsFormContentRef = useRef();

  // Hubspot form data
  const contactUsFormId = embededUnitFormConfiguration?.contactUsFormId;
  const contactUsPortalId = embededUnitFormConfiguration?.contactUsPortalId;
  const showContactUsFormAsGeneric =
    embededUnitFormConfiguration?.showContactUsFormAsGeneric;

  const formId =
    contactUsFormId?.textMap?.[hubspotFormLang] || contactUsFormId?.textMap?.en;

  const favoriteUnitsFieldName = getFavoriteUnitsFieldName(
    project?.title ?? ''
  );

  const hubspotFormHiddenFieldsValues =
    showContactUsFormAsGeneric === true
      ? [
          {
            fieldName: 'favorite_units_url',
            fieldValue: favoriteUnitsUrl
          },
          {
            fieldName: favoriteUnitsFieldName,
            fieldValue: favoriteUnitTitles || 'No favorite units'
          }
        ]
      : [
          {
            fieldName: 'su_number',
            fieldValue: contactUsActiveUnit?.unitCrmId
              ? contactUsActiveUnit?.unitCrmId
              : unitDetailslUrl
          },
          {
            fieldName: 'project_referencenumber',
            fieldValue: project?.projectCrmId || 'Project has no CRM ID'
          }
        ];

  const setUnitToDisabled = (unitId) => {
    // if button not generic - but unit specific -> set local state as disabled for this unit button
    // it just for user to see that request sent. after refresh disabled state refreshes
    if (
      !ProjectState?.showcaseConfiguration?.embededUnitFormConfiguration
        ?.showContactUsFormAsGeneric
    ) {
      ProjectStateDispatch({
        type: 'setProjectData',
        payload: {
          ...ProjectState,
          project: {
            ...ProjectState.project,
            temporaryDisabledUnits: [
              ...(ProjectState?.project?.temporaryDisabledUnits ?? []),
              unitId
            ]
          }
        }
      });
    }
  };

  const onFormSubmit = () => {
    setUnitToDisabled(contactUsActiveUnit?.objectId);
    if (contactUsActiveUnit?.objectId) {
      trackUnitAction(
        'unitContactUsFormSubmitted',
        contactUsActiveUnit.objectId,
        ProjectState,
        AuthState
      );
    }
  };

  return createPortal(
    <HubspotProvider>
      <AnimatePresence>
        <Modal onClick={(e) => e.stopPropagation()} show={showContactUsModal}>
          {shouldAskToFillInFavorites ? (
            <NoFavoritesModalContent>
              <NoFavoritesCloseButton
                onClick={closeModal}
                noFavorites={shouldAskToFillInFavorites}
              >
                <CloseIcon icon={['fal', 'times']} size="1x" />
              </NoFavoritesCloseButton>
              <NoFavoritesModalTitle>{modalTitle}</NoFavoritesModalTitle>
              <ModalDescription>{bannerText}</ModalDescription>
              <NoFavoritesModalText>
                {localizer.noFavoritesModalText}
              </NoFavoritesModalText>
              <GotItButton onClick={closeModal}>
                <p>{localizer.gotIt}</p>
              </GotItButton>
            </NoFavoritesModalContent>
          ) : (
            <Form ref={hsFormWrapperRef}>
              <ModalTitle>
                {modalTitle}
                <ModalDescription>{bannerText}</ModalDescription>
                <CloseButton onClick={closeModal}>
                  <CloseIcon icon={['fal', 'times']} size="1x" />
                </CloseButton>
              </ModalTitle>

              <FormContent ref={hsFormContentRef}>
                <>
                  {formType === contactFormType.unitSpecific && (
                    <UnitPreviewBlockWrapper>
                      <UnitPreview>
                        {contactUsActiveUnit && showUnitState && (
                          <UnitStateTag
                            unitState={contactUsActiveUnit?.state}
                            unitFlow={contactUsActiveUnit?.unitFlow}
                          />
                        )}
                        {showcaseConfiguration?.showUnitPreviewImage &&
                          renderImagePreviewOrPlaceholder()}
                        <UnitMainInfo>
                          <UnitTitle>{contactUsActiveUnit?.title}</UnitTitle>
                          <UnitTypeAndPrice>
                            {contactUsActiveUnit?.unitType && (
                              <UnitType>
                                {contactUsActiveUnit?.unitType?.icon && (
                                  <StyledUnitTypeIcon
                                    icon={[
                                      'far',
                                      contactUsActiveUnit.unitType.icon
                                    ]}
                                    size="1x"
                                  />
                                )}
                                {
                                  contactUsActiveUnit?.unitType?.name
                                    ?.textMap?.[defaultNumeralLocale]
                                }
                              </UnitType>
                            )}
                            <UnitPriceStatus>
                              <UnitPrice>
                                <StyledPriceIcon
                                  icon={['far', 'tag']}
                                  size="1x"
                                />
                                <Price>
                                  {showUnitPrice && showPrice
                                    ? unitPrice
                                    : localizer.onRequest}
                                </Price>
                              </UnitPrice>
                            </UnitPriceStatus>
                          </UnitTypeAndPrice>
                        </UnitMainInfo>
                      </UnitPreview>
                    </UnitPreviewBlockWrapper>
                  )}

                  {/* Embedded hubspot form */}
                  {canRenderHubspotForm &&
                    hubspotFormLang &&
                    !showDefaultForm && (
                      <HubspotForm
                        formType="ContactUs"
                        portalId={contactUsPortalId}
                        formId={formId}
                        isEmbeddedShowcase={isEmbeddedShowcase}
                        hiddenFieldsValues={hubspotFormHiddenFieldsValues}
                        formContentRef={hsFormContentRef}
                        onFormSubmit={onFormSubmit}
                      />
                    )}

                  {showDefaultForm ? (
                    <DefaultForm
                      formType="ContactUs"
                      showSuccessDefaultMsg={showSuccessDefaultMsg}
                      setShowSuccessDefaultMsg={setShowSuccessDefaultMsg}
                      showErrorDefaultMsg={showErrorDefaultMsg}
                      setShowErrorDefaultMsg={setShowErrorDefaultMsg}
                      onFormSubmit={onFormSubmit}
                    />
                  ) : null}
                </>
              </FormContent>
            </Form>
          )}
        </Modal>
      </AnimatePresence>
    </HubspotProvider>,
    document.body
  );
};

export default ContactUsModal;
