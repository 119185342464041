import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';

const StyledTextarea = styled.textarea`
  width: 100%;
  border: 1px solid ${({ theme, error }) => error ? theme.errorColor : theme.gray200};
  color: ${({ disabled, theme }) => !disabled ? theme.showcaseBlack : theme.gray300};
  padding: 10px 8px;
  outline: transparent;
  font-size: 1rem;
  transition: 0.3s;
  box-sizing: border-box;
  background: transparent;
  font-family: 'Open Sans', sans-serif;

  &:focus {
    border: 1px solid ${({ theme, error }) => error ? theme.errorColor : theme.gray300};
  }

  &:disabled {
    color: ${({ theme }) => theme.gray300};
  }

  &::placeholder {
    color: ${({ theme, disabled }) => !disabled ? theme.primary100 : theme.gray300};
  }
`;

const Wrapper = styled.div`
  font-family: 'Open Sans', sans-serif;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin-top: 15px;
  position: relative;
  margin-bottom: 20px;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.primary100};
  font-size: 0.875rem;
  margin-bottom: 3px;
  display: block;
  cursor: pointer;
`;

const Count = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.primary100};
  margin-bottom: 4px;
  text-align: right;
  display: block;
`;

export const Text = styled.p`
  color: ${({ error, info, success, theme }) => {
    if (error) return theme.errorColor;
    if (info) return theme.accent;
    if (success) return theme.successColor;
  }};
  margin: 0;
  text-align: ${({ info }) => (info ? 'left' : 'right')};
  position: absolute;
  bottom: -15px;
  right: 0;
`;

export const TextArea = forwardRef(({
  error,
  success,
  placeholder,
  initValue,
  label,
  name,
  width,
  disabled,
  required,
  onChange,
  onFocus,
  onBlur,
  setFieldValue,
  value: formikValue
}, ref) => {
  const [value, setValue] = useState(initValue ?? '');
  const textAreaRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    }
  }));

  const onValueChange = (event) => {
    onChange(event);
    if (value !== event.target.value) {
      setValue(event.target.value);
      setFieldValue(event.target.value);
    }
  };

  useEffect(() => {
    if(formikValue !== value) {
      setValue(formikValue);
    }
  }, [formikValue, value]);

  return (
    <Wrapper
      onClick={() => {
        onFocus();
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      }}
      width={width}
    >
      {!!label && <Label htmlFor={name}>{label}</Label>}
      <StyledTextarea
        maxLength="256"
        id={name}
        value={value}
        name={name}
        placeholder={placeholder || label}
        rows="4"
        onFocus={onFocus}
        success={success}
        onBlur={onBlur}
        onChange={onValueChange}
        ref={textAreaRef}
        error={error}
      />
      <Count>{`${value.length}/256`}</Count>
      {error && <Text error={error}>{error}</Text>}
      {success && <Text success={success}>{success}</Text>}
    </Wrapper>
  );
});

TextArea.propTypes = {
  error: string,
  success: string,
  placeholder: string,
  initValue: string,
  value: string,
  label: string,
  name: string,
  width: string,
  disabled: bool,
  required: bool,
  onChange: func,
  onFocus: func,
  setFieldValue: func,
  onBlur: func
};

TextArea.defaultProps = {
  error: '',
  success: '',
  placeholder: '',
  initValue: '',
  value: '',
  label: '',
  name: '',
  width: '',
  disabled: false,
  required: false,
  onChange: () => {},
  onFocus: () => {},
  setFieldValue: () => {},
  onBlur: () => {}
};

export default TextArea;
