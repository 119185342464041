import React from 'react';

// Components
import { TouchScreenIcon } from 'resources/icons';

// Helpers
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: ${(props) => props.theme.grayWhiteOff};
  padding: 10px 15px;
  min-height: 60px;
  width: 480px;
  max-width: 90vw;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  padding: 0 20px;
  font-size: 0.875rem;
  margin: 0;
  user-select: none;
`;

const GotItButton = styled.button`
  min-width: 60px;
  height: 30px;
  padding: 7px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.showcaseWhite};
  font-family: inherit;
  font-size: 13px;
  color: ${(props) => props.theme.showcaseWhite};
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

const Instruction = ({
  removeInstruction,
  icon: Icon = TouchScreenIcon,
  text
}) => (
  <Wrapper
    initial={{ opacity: 0, y: 60 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 60 }}
  >
    <IconWrapper>
      <Icon />
    </IconWrapper>

    <Text>
      {text ??
        (isMobile
          ? localizer.turntableInstructionMobile
          : localizer.turntableInstruction)}
    </Text>
    <GotItButton onClick={removeInstruction}>{localizer.gotIt}</GotItButton>
  </Wrapper>
);

export default Instruction;
