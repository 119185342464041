import React, { createContext, useContext } from 'react';
import useScript from './useScript';

export const HubspotContext = createContext({
  loaded: false,
  error: false
});

export const useHubspotContext = () => useContext(HubspotContext);

const HubspotProvider = ({ async, addToHead, children }) => {
  // Attach hubspot script to the document
  const [loaded, error] = useScript(
    'https://js.hsforms.net/forms/v2.js',
    async,
    addToHead
  );
  return (
    <HubspotContext.Provider value={{ loaded, error }}>
      {children}
    </HubspotContext.Provider>
  );
};

export default HubspotProvider;
