import React, { useState, useEffect, useRef, useCallback } from 'react';

import UnitSpotTooltip from 'components/pages/tour/tourComponents/unitSpot/UnitSpotTooltip';
import MobileUnitPreview from 'components/pages/tour/tourComponents/MobileUnitPreview';
import TurntableLoader from 'resources/icons/TurntableLoader';
import TurntableMasks from './TurntableMasks';
import { IdealImage } from '@prompto-ui';
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import UnitNameTooltip from './UnitNameTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import {
  getBackendGeneratedHeights,
  getCurrentEnvironment,
  debounce,
  capitalize
} from 'helpers';
import { calculateElementSizeInContainer } from '@prompto-helpers';
import { getColorForUnitState } from 'helpers/units/VmUnitHelper';
import { motion, AnimatePresence } from 'framer-motion';
import { usePrevious } from '@prompto-helpers';
import { useKeyPress } from 'helpers/customHooks';
import { isMobile, isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import isEqual from 'lodash.isequal';
import { useProjectState } from 'stores/ProjectStore';
import { Turntable as TurntableAPI } from '@prompto-api';

// Styling
import styled, { withTheme } from 'styled-components';
import TurntableNavigationControls from './TurntableNavigationControls';
import ZoomIndicator from 'components/pages/tour/tourComponents/ZoomIndicator';
import ResetZoomInstruction from './ResetZoomInstruction';

const Wrapper = styled(motion.div)`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  backdrop-filter: blur(6px);
  position: relative;

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

const DragLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`;

const PreviewWrapper = styled(motion.div)`
  z-index: 99;
  top: ${({ pos }) => pos.y}px;
  left: ${({ pos }) => pos.x}px;
  position: absolute;
`;

const LoaderWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const TurntableLoaderWrapper = styled(motion.div)`
  margin-bottom: 10px;
`;

const LoaderInfo = styled.div`
  position: relative;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary100};
`;

const UnderlayImageWrapper = styled(motion.div)`
  position: absolute;
  z-index: 0;
  top: -15px;
  left: -15px;
  width: calc(100% + 30px);
  height: calc(100vh + 30px);
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-image: linear-gradient(
      60deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6) 35%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0.6) 65%,
      rgba(255, 255, 255, 0)
    );
  }
`;

const LoadingMasks = styled(motion.div)`
  position: absolute;
  z-index: 90;
  right: ${isMobileOnly ? 45 : 15}px;
  top: ${isMobileOnly ? 0 : 15}px;
  background-color: rgba(0, 0, 0, 0.6);
  color: ${(props) => props.theme.grayWhiteOff};
  padding: 10px 15px;
  min-height: 30px;
  width: auto;
  max-width: 400px;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleMasks = styled(LoadingMasks)`
  min-width: 150px;
  transition: all 200ms ease;
  justify-content: flex-start;
  cursor: pointer;
  p {
    padding: 0 0 0 10px;
  }
`;

const Text = styled.p`
  padding: 0 20px;
  font-size: 0.875rem;
  margin: 0;
  user-select: none;
`;

const Circle = styled.div`
  height: 10px;
  display: flex;
  justify-content: center;
  transition: 0.4s;
  align-items: center;
  z-index: 100;
  border: 5px solid;
  border-radius: 50%;
  margin-right: 5px;
  border-color: ${(props) => props.theme.showcaseBlack};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`;

const InnerCircle = styled.div`
  width: 10px;
  height: 10px;
  transition: all 0.4s ease;
  background-color: ${({ theme, hide }) =>
    hide ? 'transparent' : theme.showcaseWhite};
  border-radius: 50%;
`;

const RepeatRotateButton = styled(motion.button)`
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  align-items: center;
  backdrop-filter: blur(3px);
  border: none;
  background-color: ${(props) => props.theme.fadeBlack};
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  height: calc(60px + 1vmin);
  justify-content: center;
  outline: none;
  min-width: 80px;
  cursor: pointer;
  z-index: 15;
  margin-right: 3px;
`;

const StyledCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

const preloadImage = (src, callback) => {
  const img = new Image();
  img.onload = () => callback(img);
  img.src = src;
};

const menuHeight = 60;

const getMoveDirection = (xOffset) =>
  Math.sign(xOffset) > 0 ? 'right' : Math.sign(xOffset) < 0 ? 'left' : '';

const Turntable = ({
  turntable,
  images,
  units,
  onOpenUnitPage,
  showcaseConfiguration,
  theme,
  onLoaded = () => {},
  isInResources = false,
  switchPage = () => {},
  isDragEnabled,
  setIsDragEnabled = () => {},
  toggleInstruction
}) => {
  const [offset, setOffset] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [dragStep, setDragStep] = useState(0);
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);
  const previousIndex = usePrevious(visibleImageIndex);
  const [reachedRotationLimit, setReachedRotationLimit] = useState(false);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [htmlImageElements, setHtmlImageElements] = useState();

  const [originalSize, setOriginalSize] = useState();

  // Masks related
  const [masks, setMasks] = useState([]);
  const [loadingMasks, setLoadingMasks] = useState(false);
  const [navigationItems, setNavigationItems] = useState([]);
  const [masksAreVisible, setMasksAreVisible] = useState(true);

  // Placeholders
  const [placeholderTransform, setPlaceholderTransform] = useState();
  const [placeholderSize, setPlaceholderSize] = useState();
  const [loadingPlaceholders, setLoadingPlaceholders] = useState(false);
  const [placeholdersLoaded, setPlaceHoldersLoaded] = useState(false);
  const [loadedImagesQuantity, setLoadedImagesQuantity] = useState(0);

  // Final images
  const [finalTransform, setFinalTransform] = useState();
  const [finalSize, setFinalSize] = useState();
  const [finalLoadingStarted, setFinalLoadingStarted] = useState(false);

  const [firstImageLoaded, setFirstImageLoaded] = useState(false);
  const [firstHqImageLoaded, setFirstHqImageLoaded] = useState(false);

  const [previousInterval, setPreviousInterval] = useState();
  const [nextInterval, setNextInterval] = useState();

  const [screenSize, setScreenSize] = useState();

  const container = useRef();
  const canvas = useRef();
  const unitPreviewRef = useRef();

  const [containerSize, setContainerSize] = useState();
  const [drawingAreaSize, setDrawingAreaSize] = useState();
  const [svgOverlayTransformation, setSvgOverlayTransformation] =
    useState(null);
  const [svgScale, setSvgScale] = useState(1);

  // Keypress events
  const nextKeyPressed = useKeyPress('ArrowLeft', true, false);
  const previousKeyPressed = useKeyPress('ArrowRight', true, false);
  const keyDelay = 50;

  const [mousePos, setMousePos] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [hoveredUnit, setHoveredUnit] = useState();
  const [hoveredUnitLocation, setHoveredUnitLocation] = useState();
  const [hoveredUnitCard, setHoveredUnitCard] = useState();

  const [clickedMaskUnit, setClickedMaskUnit] = useState(null);

  // Mobile
  const [previousTouch, setPreviousTouch] = useState();
  const [isRotating, setIsRotating] = useState(true);

  // Canvas
  const [canvasIsDrawn, setCanvasIsDrawn] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [startAnimationShown, setStartAnimationShown] = useState(false);

  // SVG layer related
  const [svgPolygons, setSvgPolygons] = useState([]);
  const previousSvgPolygons = usePrevious(svgPolygons);
  const [needAnimatePolygons, setNeedAnimatePolygons] = useState(true);
  const [animatingPolygons, setAnimatingPolygons] = useState(false);
  const [startRotationPosition, setStartRotationPosition] = useState();
  const [highlightAllPolygons, setHighlightAllPolygons] = useState(false);

  const [wasDragged, setWasDragged] = useState(false);
  const [quarterRotationSteps, setQuarterRotationSteps] = useState(0);
  const [highlightedControl, setHighlightedControl] = useState('none');
  const [polygonCenters, setPolygonCenters] = useState({});
  const [screenSizeChanged, setScreenSizeChanged] = useState(false);

  const [rotationDir, setRotationDir] = useState(1);

  // 3D masks related
  const [navItems, setNavItems] = useState([]);

  // turntable in Resources related
  const [unwatchedImages, setUnwatchedImages] = useState();
  const [showRepeatRotateButton, setShowRepeatRotateButton] = useState(false);

  // Project store
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { project, turntableMasks } = ProjectState;

  // Zoom controls
  const [originalImages, setOriginalImages] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    if (!isInResources) return;
    const show = isInResources && placeholdersLoaded && isDragEnabled;
    setShowRepeatRotateButton(show);
    if (show && unwatchedImages?.length === 0 && toggleInstruction) {
      toggleInstruction(false);
    }
  }, [
    isInResources,
    placeholdersLoaded,
    isDragEnabled,
    toggleInstruction,
    unwatchedImages
  ]);

  // set image indices to be watched
  useEffect(() => {
    if (unwatchedImages) return;
    setUnwatchedImages(images.map((_, idx) => idx));
  }, [images, unwatchedImages]);

  useEffect(() => {
    if (isInResources) {
      const index =
        ((Math.floor(offset / dragStep) % images.length) + images.length) %
        images.length;
      setUnwatchedImages((indices) => indices.filter((idx) => idx !== index));
    }
  }, [isInResources, images, dragStep, offset]);

  useEffect(() => {
    if (isInResources && unwatchedImages && unwatchedImages.length === 0) {
      const dir =
        Math.sign(offset) > 0 ? 'left' : Math.sign(offset) < 0 ? 'right' : '';
      if (dir) {
        setReachedRotationLimit(dir);
      }
    }
  }, [unwatchedImages, offset, isInResources]);

  // inverse turntable rotation if needed
  useEffect(() => {
    if (!!turntable?.enableInverseRotation) {
      setRotationDir(-1);
    }
  }, [turntable]);

  // Get turntable masks
  useEffect(() => {
    if (turntable?.turnNavigationTargetList?.length > 0) {
      if (turntableMasks?.[turntable.objectId]) {
        setMasks(turntableMasks[turntable.objectId]);
      } else {
        setLoadingMasks(true);
        TurntableAPI.get(turntable.objectId, {
          includeTurnPointOfViewList: false
        })
          .then((res) => {
            const turntable = res?.data?.vmTurnTable;
            if (turntable?.turnNavigationTargetList?.length > 0) {
              setNavigationItems(turntable?.turnNavigationTargetList);
              const allMasks = [];
              turntable.turnPointOfViewList.forEach((turnPoint) => {
                const masksForPOV = [];
                if (turntable.turnNavigationTargetList) {
                  // Group the masks per POV
                  turntable.turnNavigationTargetList.forEach((mask) => {
                    if (mask.pointOfViewId === turnPoint.objectId) {
                      masksForPOV.push(mask);
                    }
                  });
                }
                allMasks.push(masksForPOV);
              });
              setMasks(allMasks);
              ProjectStateDispatch({
                type: 'setTurntableMasks',
                payload: {
                  [turntable.objectId]: allMasks
                }
              });
            }
            setLoadingMasks(false);
            setHighlightAllPolygons(true);
          })
          .catch(() => {
            setLoadingMasks(false);
            setHighlightAllPolygons(true);
          });
      }
    }
  }, [turntable, turntableMasks, ProjectStateDispatch]);

  useEffect(() => {
    if (navigationItems?.length > 0 && navItems.length === 0) {
      const navItems = navigationItems.map((x) => ({
        position: x.navigationItem.value.split(','),
        itemSize: 3,
        target: x.navigationTargetValue
      }));
      setNavItems(navItems);
    }
  }, [navigationItems, navItems]);

  useEffect(() => {
    if (svgPolygons.length > 0) {
      setTimeout(() => {
        const svgOverlay = document.querySelector('#svg-overlay');
        const svgOverlayCoords = svgOverlay.getBoundingClientRect();
        const svgElements = document.querySelectorAll('#svg-turntable-polygon');
        const centers = {};

        svgElements.forEach((x) => {
          const coords = x.getBoundingClientRect();
          // take into account the svg overlay position

          const center = {
            y: coords.top - svgOverlayCoords.top,
            x: (coords.left + coords.right) / 2 - svgOverlayCoords.left
          };
          centers[x.dataset.objectid] = center;
        });
        setPolygonCenters(centers);
      }, 0);
    }
  }, [svgPolygons, screenSizeChanged, screenSize, offsetX, offsetY, zoomLevel]);

  useEffect(() => {
    if (images?.length > 0) {
      const quarterRotation = Math.ceil(images.length / 4);
      setQuarterRotationSteps(quarterRotation);
      if (!isInResources) {
        setOffset(quarterRotation * dragStep * rotationDir);
      }
    }
  }, [images, dragStep, rotationDir, isInResources]);

  const rotate = useCallback(
    (steps, direction = 'left', speed = 75, cb) => {
      setIsRotating(true);
      const dir = direction === 'right' ? -1 : 1;
      let count = 0;
      const interval = setInterval(() => {
        direction === 'right' ? count-- : count++;
        setOffset((cur) => cur - dragStep * dir * rotationDir);

        if (count === steps * dir) {
          clearInterval(interval);
          if (cb) cb();
        }
      }, speed);
    },
    [dragStep, rotationDir]
  );

  // rotate the turntable to present it to a user
  useEffect(() => {
    if (isInResources) return;
    if (!showCanvas) return;
    if (startAnimationShown) return;

    setHighlightedControl('left');
    rotate(quarterRotationSteps, 'left', 130, () => {
      setStartAnimationShown(true);
      setHighlightedControl('none');
      setNeedAnimatePolygons(true);
      setIsRotating(false);
    });
  }, [
    showCanvas,
    startAnimationShown,
    images,
    dragStep,
    rotate,
    quarterRotationSteps,
    isInResources
  ]);

  // hide unit card while rotating turntable
  useEffect(() => {
    if (isRotating || screenSizeChanged) {
      setClickedMaskUnit(null);
      setSelectedUnit(null);
      setHoveredUnitLocation(null);
      setScreenSizeChanged(false);
    }
  }, [isRotating, screenSizeChanged]);

  useEffect(() => {
    if (progress === 100) {
      const timer = setTimeout(() => {
        setShowCanvas(true);
        clearTimeout(timer);
      }, 100);
    }
  }, [progress]);

  const highlightPolygonsSync = useCallback(() => {
    setHighlightAllPolygons(true);
    const timer = setTimeout(() => {
      setHighlightAllPolygons(false);
      setNeedAnimatePolygons(false);
      setAnimatingPolygons(false);
      clearTimeout(timer);
    }, 50);
  }, []);

  useEffect(() => {
    if (
      startAnimationShown &&
      needAnimatePolygons &&
      !isMobile &&
      !animatingPolygons
    ) {
      setAnimatingPolygons(true);
      highlightPolygonsSync();
    }
  }, [
    highlightPolygonsSync,
    startAnimationShown,
    needAnimatePolygons,
    animatingPolygons
  ]);

  const calculatePlaceholderAndFinalTurntableSize = useCallback(() => {
    // Based on original image size and the size of the container get the ideal size of the image to fit or fill the container
    const { originalImageSize } = turntable.turnPointOfViewList[0]?.contentItem;
    const containerSize = container.current.getBoundingClientRect();
    const { idealWidth, idealHeight } = calculateElementSizeInContainer(
      containerSize.width,
      containerSize.height,
      originalImageSize.width,
      originalImageSize.height,
      'fill'
    );

    setOriginalSize(originalImageSize);
    setDragStep(Math.ceil((idealWidth * 0.25) / images.length));

    // To improve loading experience we first check to get the placeholder sizes
    const backendGeneratedHeights = getBackendGeneratedHeights();
    let heightIndex = backendGeneratedHeights.findIndex(
      (x) => x >= idealHeight
    );
    if (heightIndex === -1) {
      heightIndex = backendGeneratedHeights.length - 1;
    }

    const placeholderHeightIndex = Math.min(Math.max(heightIndex - 3, 0), 3);
    const imageRatio = originalImageSize.width / originalImageSize.height;

    const placeholderHeight = backendGeneratedHeights[placeholderHeightIndex];
    const placeholderWidth = placeholderHeight * imageRatio;

    const placeholderTransform = `h=${placeholderHeight}`;
    const transform = `h=${backendGeneratedHeights[heightIndex]}`;

    const finalHeight = backendGeneratedHeights[heightIndex];
    const finalWidth = finalHeight * imageRatio;

    setPlaceholderSize({
      width: placeholderWidth,
      height: placeholderHeight
    });
    setPlaceholderTransform(placeholderTransform);

    setFinalSize({
      width: finalWidth,
      height: finalHeight
    });
    setFinalTransform(transform);
  }, [images, turntable]);

  useEffect(() => {
    if (
      !placeholderSize ||
      !finalSize ||
      !placeholderTransform ||
      !finalTransform
    )
      return;
    if (turntable && images[0] && container?.current && !firstImageLoaded) {
      const baseUrl = getCurrentEnvironment().baseImageUrl;
      const fullUri = `${baseUrl}/${placeholderTransform}/${images[0]}`;
      preloadImage(fullUri, (img) => {
        setFirstImageLoaded(true);
      });
    }
  }, [
    images,
    firstImageLoaded,
    placeholderTransform,
    finalTransform,
    placeholderSize,
    finalSize,
    turntable
  ]);

  /**
   * Step #1 Calculate the placeholder and final sizes of the turntable
   */
  useEffect(() => {
    if (turntable && images && images.length > 0 && container?.current) {
      calculatePlaceholderAndFinalTurntableSize();
    }
  }, [turntable, images, container, calculatePlaceholderAndFinalTurntableSize]);

  const loadPlaceholderImages = useCallback(() => {
    const htmlElements = [];

    images.forEach((frame) => {
      htmlElements.push(null);
    });
    const baseUrl = getCurrentEnvironment().baseImageUrl;
    let loadedImages = 0;
    images.forEach((frame, index) => {
      const fullUri = `${baseUrl}/${placeholderTransform}/${frame}`;
      preloadImage(fullUri, (img) => {
        loadedImages++;
        setLoadedImagesQuantity(loadedImages);
        setProgress(Math.floor((loadedImages / images.length) * 100));
        // ensure order is correct
        htmlElements.splice(index, 1, { img, size: placeholderSize });
        setHtmlImageElements(htmlElements);
        if (loadedImages === images.length) {
          setPlaceHoldersLoaded(true);
          setLoadingPlaceholders(false);
          setIsLoading(false);
          onLoaded();
        }
      });
    });
  }, [images, placeholderSize, placeholderTransform, onLoaded]);

  /**
   * Step #2 We got the sizes calculated, let's load in the placeholders, these should load fast
   */
  useEffect(() => {
    if (loadingPlaceholders || placeholdersLoaded) return;
    if (placeholderTransform && images && firstImageLoaded) {
      setLoadingPlaceholders(true);
      loadPlaceholderImages();
      let ori = [];
      images.forEach((x) => {
        ori.push(null);
      });
      setOriginalImages(ori);
    }
  }, [
    placeholderTransform,
    images,
    loadPlaceholderImages,
    firstImageLoaded,
    loadingPlaceholders,
    placeholdersLoaded
  ]);

  const loadFinalImages = useCallback(() => {
    const htmlElements = htmlImageElements;

    const baseUrl = getCurrentEnvironment().baseImageUrl;
    images.forEach((frame, index) => {
      const fullUri = `${baseUrl}/${finalTransform}/${frame}`;
      preloadImage(fullUri, (img) => {
        // ensure order is correct
        htmlElements.splice(index, 1, { img, size: finalSize });
        setHtmlImageElements(htmlElements);
        if (index === 0) {
          setFirstHqImageLoaded(true);
        }
      });
    });
  }, [images, finalTransform, finalSize, htmlImageElements]);

  const prepareOriginalImage = useCallback(() => {
    let originals = originalImages;
    const baseUrl = getCurrentEnvironment().baseImageUrl;

    const imageToLoad = images[visibleImageIndex];
    const fullUri = `${baseUrl}/${imageToLoad}`;

    preloadImage(fullUri, (img) => {
      // ensure order is correct
      originals.splice(visibleImageIndex, 1, img);
      setOriginalImages(originals);
    });
  }, [images, originalImages, visibleImageIndex]);

  /**
   * Step #3 Placeholders are loaded, now load the final version
   */
  useEffect(() => {
    if (finalTransform && placeholdersLoaded && !finalLoadingStarted) {
      setFinalLoadingStarted(true);
      loadFinalImages();
    }
  }, [
    finalTransform,
    htmlImageElements,
    placeholdersLoaded,
    finalLoadingStarted,
    loadFinalImages
  ]);

  // Arrow keys binding
  // 1. not applicable for turntables in Resources
  useEffect(() => {
    if (isInResources) return;

    if (previousKeyPressed && !previousInterval) {
      setIsRotating(true);
      setOffset((cur) => cur + dragStep * rotationDir);
      setPreviousInterval(
        setInterval(() => {
          setOffset((cur) => cur + dragStep * rotationDir);
        }, keyDelay)
      );
    } else if (!previousKeyPressed && previousInterval) {
      clearInterval(previousInterval);
      setPreviousInterval(null);
      setIsRotating(false);
      setNeedAnimatePolygons(true);
    }
  }, [
    previousKeyPressed,
    dragStep,
    previousInterval,
    rotationDir,
    isInResources
  ]);

  useEffect(() => {
    if (isInResources) return;

    if (nextKeyPressed && !nextInterval) {
      setIsRotating(true);
      setOffset((cur) => cur - dragStep * rotationDir);
      setNextInterval(
        setInterval(() => {
          setOffset((cur) => cur - dragStep * rotationDir);
        }, keyDelay)
      );
    } else if (!nextKeyPressed && nextInterval) {
      clearInterval(nextInterval);
      setNextInterval(null);
      setIsRotating(false);
      setNeedAnimatePolygons(true);
    }
  }, [nextKeyPressed, dragStep, nextInterval, rotationDir, isInResources]);

  const windowSizeChanged = useCallback(() => {
    setScreenSizeChanged(true);
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      canvas.current &&
      htmlImageElements &&
      (placeholdersLoaded || finalLoadingStarted)
    ) {
      if (!screenSize?.width || !screenSize?.height) {
        windowSizeChanged();
      }
      const ctx = canvas.current.getContext('2d');

      const containerSize = container.current.getBoundingClientRect();
      setContainerSize(containerSize);

      const isLandscape = screenSize?.width > screenSize?.height;
      canvas.current.width = screenSize?.width ?? containerSize.width;
      canvas.current.height = screenSize?.height
        ? screenSize.height - (isLandscape ? 0 : menuHeight)
        : containerSize.height;

      const index =
        ((Math.floor(offset / dragStep) % images.length) + images.length) %
        images.length;

      setVisibleImageIndex(index);
      if (index !== 0 && toggleInstruction) {
        toggleInstruction(false);
      }

      if (htmlImageElements[index]) {
        let imageSize = htmlImageElements[index].size;

        const ratio = imageSize.width / imageSize.height;
        const widthToDraw = canvas.current.height * ratio * zoomLevel;
        const heightToDraw = canvas.current.height * zoomLevel;

        setDrawingAreaSize({ width: widthToDraw, height: heightToDraw });

        let imageToDraw = htmlImageElements[index].img;
        if (zoomLevel >= 1.5 && originalImages[index]) {
          imageToDraw = originalImages[index];
        }

        ctx.drawImage(
          imageToDraw,
          (canvas.current.width - widthToDraw) / 2.0 + offsetX,
          (canvas.current.height - heightToDraw) / 2.0 + offsetY,
          widthToDraw,
          heightToDraw
        );

        if (!canvasIsDrawn) setCanvasIsDrawn(true);

        // We need to translate everything to the center
        //ctx.translate((canvas.current.width - widthToDraw) / 2.0, -(canvas.current.height - heightToDraw) / 2.0);
        if (masks && masks[index]) {
          // We downscaled the image to fit in the canvas, need to do the same for the svg
          ctx.scale(
            (canvas.current.height * ratio) / originalSize.width,
            canvas.current.height / originalSize.height
          );

          // This whole feature depends on a very specific setup we have to render images from blender
          // and then convert them using inkscape to .svg, here inkscape adds this scale of 0.26458 which is a magic number for now.
          // We added logic to store the scale now in the backend, much better
          let scaleModifier = 0.26458;

          if (turntable.turntableMaskScale) {
            scaleModifier = Number(turntable.turntableMaskScale);
          }

          const scale = 1 / scaleModifier;
          ctx.scale(scale, scale);
          setSvgScale(scale);
          setSvgOverlayTransformation(`
          transform: translate(${
            (canvas.current.width - widthToDraw) / 2 + offsetX
          }px, ${
            (canvas.current.height - heightToDraw) / 2.0 + offsetY
          }px) scale(${scale});
          transform-origin: top left;
        `);

          const svgPolygons = [];

          masks[index].forEach((x) => {
            const unitId = x.navigationTargetValue.targetValue;
            const linkedUnit = units?.filter((x) => x.objectId === unitId)[0];

            let unitColor = '#ffffff';
            if (linkedUnit) {
              unitColor = getColorForUnitState(theme, linkedUnit.state);
            } else {
              // if no linked unit found - do not render it (not allowed, draft state and do on)
              return;
            }

            // we want to create a svg clone of every polygon drawn in canvas
            svgPolygons.push({
              path: x.navigationItem.value,
              color: unitColor,
              number: x.objectId,
              target: x.navigationTargetValue.targetValue
            });
          });
          if (!isEqual(svgPolygons, previousSvgPolygons) || screenSizeChanged) {
            setSvgPolygons(svgPolygons);
          }
        }
      }
    }
  }, [
    isLoading,
    offset,
    offsetX,
    offsetY,
    dragStep,
    zoomLevel,
    canvas,
    htmlImageElements,
    mousePos,
    selectedUnit,
    placeholdersLoaded,
    finalLoadingStarted,
    originalSize,
    turntable,
    theme,
    units,
    images,
    masks,
    canvasIsDrawn,
    loadedImagesQuantity,
    firstHqImageLoaded,
    isRotating,
    startAnimationShown,
    previousSvgPolygons,
    screenSize,
    previousIndex,
    originalImages,
    toggleInstruction,
    windowSizeChanged,
    screenSizeChanged
  ]);

  useEffect(() => {
    window.addEventListener('resize', windowSizeChanged);
    return () => window.removeEventListener('resize', windowSizeChanged);
  }, [windowSizeChanged]);

  useEffect(() => {
    if (!hoveredUnitCard) return;
    if (!selectedUnit || !hoveredUnitLocation) {
      setHoveredUnitCard(null);
    }
  }, [selectedUnit, hoveredUnitLocation, hoveredUnitCard]);

  // Update cursor to hand on hover
  useEffect(() => {
    if (hoveredUnit) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }
  }, [hoveredUnit]);

  const checkOutsideClick = useCallback(
    (event) => {
      // if (!clickedMaskUnit) return;
      // if (
      //   unitPreviewRef.current &&
      //   !unitPreviewRef.current.contains(event.target)
      // ) {
      //   setClickedMaskUnit(null);
      // }
    },
    [unitPreviewRef, clickedMaskUnit]
  );

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);
    return () => window.removeEventListener('click', checkOutsideClick);
  }, [checkOutsideClick]);

  // Scroll binding
  const handleScroll = useCallback(
    (e) => {
      if (!container.current?.contains(e.target)) return;
      e.preventDefault();
      e.stopPropagation();

      const useDeltaX = Math.abs(e.deltaX) > Math.abs(e.deltaY);
      const delta = useDeltaX ? -e.deltaX : -e.deltaY;

      let scrollDirection = 0;
      if (delta < -4) {
        scrollDirection = 1;
      } else if (delta > 4) {
        scrollDirection = -1;
      }

      if (isInResources) {
        // enable rotating by mouse wheel if turntable is in resources page
        setOffset((cur) => cur - dragStep * rotationDir * scrollDirection);
      } else {
        // enable zooming by mouse wheel
        if (scrollDirection >= 1) {
          onTurntableZoomOut(0.1);
        } else {
          onTurntableZoomIn(0.1);
        }
      }
    },
    [rotationDir, dragStep, isInResources]
  );

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [handleScroll, isInResources]);

  const unitPreview =
    isMobile && selectedUnit ? (
      <MobileUnitPreview
        unit={selectedUnit}
        onClose={() => {
          setSelectedUnit(null);
        }}
        onExploreDetailsClick={() => onOpenUnitPage(selectedUnit)}
      />
    ) : (
      <PreviewWrapper
        pos={hoveredUnitLocation}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
      >
        <UnitNameTooltip
          shouldShow={true}
          unitSpotLocation={hoveredUnitLocation ?? {}}
          unitSpotAbsoluteLocation={hoveredUnitLocation}
          unit={selectedUnit}
        />
      </PreviewWrapper>
    );

  const mouseEventDebouncedHandler = debounce((target) => {
    const linkedUnit = units?.filter(
      (x) => x.objectId === target?.dataset?.target
    )[0];
    if (target?.dataset?.target === undefined) {
      setSelectedUnit(null);
      setHoveredUnitLocation(null);
      return;
    }
    if (linkedUnit?.objectId !== selectedUnit?.objectId) {
      setHoveredUnit(linkedUnit);
      if (polygonCenters[target?.dataset?.objectid]) {
        setTimeout(() => {
          setSelectedUnit(linkedUnit);
          setHoveredUnitLocation(polygonCenters[target.dataset.objectid]);
        }, 10);
      }
    }
  }, 10);

  const toggleMasksVisibility = useCallback(() => {
    setMasksAreVisible((prev) => !prev);
  }, []);

  const startDrag = (x, y) => {
    const startX = x - offsetX;
    const startY = y - offsetY;

    setDragStartX(startX);
    setDragStartY(startY);
  };

  const doDrag = (x, y) => {
    if (isMouseDown) {
      const offX = x - dragStartX;
      const offY = y - dragStartY;

      setOffsetX(offX);
      setOffsetY(offY);
      setClickedMaskUnit(null);
    }
  };

  const onTurntableMouseMove = (e) => {
    // If user is zoomed in we pan the image instead of rotating the turntable
    if (zoomLevel !== 1) {
      const rect = canvas.current.getBoundingClientRect();
      doDrag(e.clientX - rect.left, e.clientY - rect.top);
      return;
    }

    // Otherwise we rotate the turntable
    if (isInResources && reachedRotationLimit) {
      const { movementX } = e;
      const moveDirection = getMoveDirection(movementX);
      if (reachedRotationLimit === moveDirection) {
        switchPage(moveDirection);
      } else if (moveDirection) {
        setReachedRotationLimit();
      }
      return;
    }

    if (placeholdersLoaded) {
      if (isMouseDown) {
        const dragDirection = containerSize.height / 2 > e.clientY ? -1 : 1;
        const movement = e.movementX * (isInResources ? 1 : dragDirection);
        setOffset((cur) => cur - movement * rotationDir);
        setWasDragged(true);
        setIsRotating(true);
      }
    }
  };

  const onTurntableMouseDown = (e) => {
    if (placeholdersLoaded) {
      setIsMouseDown(true);
    }
    // If user is zoomed we do logic to pan the image
    if (zoomLevel !== 1) {
      const rect = canvas.current.getBoundingClientRect();
      startDrag(e.clientX - rect.left, e.clientY - rect.top);
      return;
    }

    setStartRotationPosition(offset);
  };

  const onTurntableMouseUp = (e) => {
    if (placeholdersLoaded) {
      setIsMouseDown(false);
      if (startRotationPosition !== offset) {
        setNeedAnimatePolygons(true);
      }
      setStartRotationPosition(offset);
      setIsRotating(false);
    }
  };

  const onTurntableTouchMove = (e) => {
    const touch = e.touches[0];

    if (zoomLevel !== 1) {
      const rect = canvas.current.getBoundingClientRect();
      doDrag(touch.clientX - rect.left, touch.clientY - rect.top);
      return;
    }

    if (isInResources && reachedRotationLimit && touch && previousTouch) {
      const movementX = touch.pageX - previousTouch.pageX;
      const moveDirection = getMoveDirection(movementX);
      if (reachedRotationLimit === moveDirection) {
        switchPage(moveDirection);
      } else if (moveDirection) {
        setReachedRotationLimit();
      }
      return;
    }

    if (placeholdersLoaded) {
      setWasDragged(true);
      if (previousTouch) {
        // be aware that these only store the movement of the first touch in the touches array
        const movementX = (touch.pageX - previousTouch.pageX) * rotationDir;
        const dragDirection = containerSize.height / 2 > touch.pageY ? 1 : -1;
        setOffset(
          (cur) => cur + movementX * (isInResources ? 1 : dragDirection)
        );
        setIsRotating(true);
      }

      setPreviousTouch(touch);
    }
  };

  const onTurntableTouchStart = (e) => {
    if (placeholdersLoaded) {
      setIsMouseDown(true);
    }

    const touch = e.touches[0];

    if (zoomLevel !== 1 && touch) {
      const rect = canvas.current.getBoundingClientRect();
      startDrag(touch.clientX - rect.left, touch.clientY - rect.top);
      return;
    }

    setMousePos({ x: touch.clientX, y: touch.clientY });
  };

  const onTurntableTouchEnd = (e) => {
    if (placeholdersLoaded) {
      setPreviousTouch(null);
      setIsRotating(false);
      setIsMouseDown(false);
    }
  };

  const onTurntableZoomIn = (zoomFactor = 0.2) => {
    prepareOriginalImage();
    setZoomLevel((current) => {
      if (current < 3) {
        let newValue = current + zoomFactor;
        newValue = Math.min(newValue, 3);
        return newValue;
      }

      return current;
    });
  };

  const onTurntableZoomOut = (zoomFactor = 0.2) => {
    setZoomLevel((current) => {
      if (current > 1) {
        if (current - zoomFactor === 1) {
          setOffsetX(0);
          setOffsetY(0);
        }

        let newValue = current - zoomFactor;
        newValue = Math.max(newValue, 1);
        return newValue;
      }

      return current;
    });
  };

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AnimatePresence>
        {progress < 100 && (
          <LoaderWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoaderContainer>
              <TurntableLoaderWrapper animate={{ scale: 1.5 }}>
                <TurntableLoader
                  progress={progress}
                  autoanimate={!turntable ? 1 : 0}
                  color={theme.primary100}
                />
              </TurntableLoaderWrapper>
              <LoaderInfo>
                {turntable ? (
                  <>
                    <LoadingText>{localizer.loading}</LoadingText>
                    <LoadingText>{progress}%</LoadingText>
                  </>
                ) : (
                  <LoadingText>{localizer.waitingTurntableData}</LoadingText>
                )}
              </LoaderInfo>
            </LoaderContainer>

            <AnimatePresence>
              {project?.thumbnailUri && container.current && (
                <UnderlayImageWrapper
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <IdealImage
                    contentUri={project?.thumbnailUri}
                    fallbackUri={project?.thumbnailUri}
                    imageSize={{
                      width: container.current.offsetWidth,
                      height: container.current.offsetHeight
                    }}
                    containerSize={{
                      width: window.innerWidth,
                      height: window.innerHeight
                    }}
                    mustFillParent={true}
                    baseImageUrl={getCurrentEnvironment().baseImageUrl}
                  />
                </UnderlayImageWrapper>
              )}
            </AnimatePresence>
          </LoaderWrapper>
        )}
      </AnimatePresence>

      <DragLayer
        ref={container}
        onMouseDown={onTurntableMouseDown}
        onMouseUp={onTurntableMouseUp}
        onMouseMove={onTurntableMouseMove}
        onTouchStart={onTurntableTouchStart}
        onTouchMove={onTurntableTouchMove}
        onTouchEnd={onTurntableTouchEnd}
      >
        {/* An additional layer for easier interaction with SVG masks: animation, etc. */}
        {drawingAreaSize && containerSize && (
          <TurntableMasks
            maskType={turntable?.maskType}
            drawingAreaSize={drawingAreaSize}
            originalSize={originalSize}
            svgOverlayTransformation={svgOverlayTransformation}
            svgPolygons={svgPolygons}
            selectedUnit={selectedUnit}
            onMaskClicked={(unit) =>
              setClickedMaskUnit({ location: hoveredUnitLocation, unit })
            }
            onOpenUnitPage={onOpenUnitPage}
            highlightAllPolygons={highlightAllPolygons}
            isRotating={isRotating}
            mouseEventDebouncedHandler={mouseEventDebouncedHandler}
            setSelectedUnit={setSelectedUnit}
            cameraModel={turntable?.cameraModel}
            visibleImageIndex={visibleImageIndex}
            navItems={navItems}
            units={units}
            setHoveredUnitLocation={setHoveredUnitLocation}
            hoveredUnitCard={hoveredUnitCard}
            scale={svgScale}
            masksAreVisible={masksAreVisible}
          />
        )}
      </DragLayer>

      {zoomLevel !== 1 && (
        <ResetZoomInstruction
          resetZoom={() => {
            setZoomLevel(1);
            setOffsetX(0);
            setOffsetY(0);
          }}
        />
      )}
      {!isLoading && progress === 100 && <StyledCanvas ref={canvas} />}

      <ZoomIndicator
        zoomIn={() => {
          onTurntableZoomIn();
        }}
        zoomOut={() => {
          onTurntableZoomOut();
        }}
        scale={zoomLevel}
        setTransform={() => {}}
        resetTransform={() => {}}
      />

      <AnimatePresence>
        {clickedMaskUnit && (
          <PreviewWrapper
            ref={unitPreviewRef}
            pos={clickedMaskUnit.location}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <UnitSpotTooltip
              shouldShow={true}
              unitSpotLocation={clickedMaskUnit.location ?? {}}
              unitSpotAbsoluteLocation={clickedMaskUnit.location}
              unit={clickedMaskUnit.unit}
              showUnitState={showcaseConfiguration?.showUnitState ?? true}
              onClick={() => {
                if (isMobile) return;
                onOpenUnitPage(clickedMaskUnit.unit);
              }}
              onTouchEnd={() => {
                onOpenUnitPage(clickedMaskUnit.unit);
              }}
              withLineHoverArea={false}
              withCloseIcon={true}
              onClose={() => setClickedMaskUnit(null)}
            />
          </PreviewWrapper>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {selectedUnit &&
          hoveredUnitLocation &&
          selectedUnit.objectId !== clickedMaskUnit?.unit?.objectId &&
          unitPreview}
      </AnimatePresence>

      <TurntableNavigationControls
        onNavigateToPreviousImage={() => {
          if (!placeholdersLoaded || isRotating) return;
          rotate(quarterRotationSteps, 'left', 75, () => {
            setIsRotating(false);
          });
          setWasDragged(true);
        }}
        onNavigateToNextImage={() => {
          if (!placeholdersLoaded || isRotating) return;
          rotate(quarterRotationSteps, 'right', 75, () => {
            setIsRotating(false);
          });
          setWasDragged(true);
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoading ? 0 : 1 }}
        onRotationStop={() => {
          setTimeout(() => {
            setNeedAnimatePolygons(true);
            setIsRotating(false);
          }, 75 * quarterRotationSteps);
        }}
        animateControls={startAnimationShown && !wasDragged}
        highlightedControl={highlightedControl}
      />

      <AnimatePresence>
        {loadingMasks && (
          <LoadingMasks
            initial={{ opacity: 0, x: 250 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 250 }}
          >
            <ShowcaseLoader color={theme.grayWhiteOff} size={1} />
            <Text>{localizer.loadingMasks}</Text>
          </LoadingMasks>
        )}
        {!loadingMasks && masks.length > 0 && (
          <ToggleMasks
            initial={{ opacity: 0, x: 250 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 250 }}
            onClick={toggleMasksVisibility}
          >
            <Circle>
              <InnerCircle hide={!masksAreVisible} />
            </Circle>
            <Text>
              {capitalize(masksAreVisible ? localizer.hide : localizer.show)}{' '}
              {localizer.masks}
            </Text>
          </ToggleMasks>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showRepeatRotateButton && (
          <RepeatRotateButton
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => {
              if (isMobile) return;
              setUnwatchedImages(images.map((_, idx) => idx));
              setIsDragEnabled(false);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              setPreviousTouch(null);
              setIsRotating(false);
              setUnwatchedImages(images.map((_, idx) => idx));
              setIsDragEnabled(false);
            }}
            onTouchEnd={(e) => e.stopPropagation()}
          >
            <FontAwesomeIcon icon={['far', 'sync']} size="sm" />
          </RepeatRotateButton>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default withTheme(Turntable);
