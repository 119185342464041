import React, { useRef } from 'react';
import { func, array } from 'prop-types';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';

// Components
import SelectFilterCard from './SelectFilterCard';
import Tippy from '@tippyjs/react';

// Styling
import styled from 'styled-components';

const Chip = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.defaultBrandSecondaryColor};
  cursor: pointer;
  box-sizing: border-box;
`;

const ActiveFilterMarker = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.successColor};
  position: absolute;
  top: -4px;
  right: -4px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
`;

/**
 * Component used to add a specific filter
 */
const AddFilterChip = ({ active, filterOptions, onConfirmCreate }) => {
  const tippyRef = useRef();

  return (
    <Tippy
      ref={tippyRef}
      key="tooltip"
      placement="bottom"
      theme="unitFilter"
      trigger="click"
      interactive={true}
      hideOnClick={true}
      arrow={false}
      zIndex={500}
      content={
        <SelectFilterCard
          filterOptions={filterOptions}
          onOptionSelected={(option) => {
            tippyRef.current._tippy.hide();

            // Timeout to wait for the tippy to be fully hidden
            setTimeout(() => {
              onConfirmCreate(option);
            }, 200);
          }}
        />
      }
    >
      <Chip data-testid={`filterChipadd`}>
        {active && <ActiveFilterMarker />}
        <Label>{capitalize(localizer.filterUnitsUppercased)}</Label>
      </Chip>
    </Tippy>
  );
};

AddFilterChip.propTypes = {
  /** All the filter options that can be used */
  filterOptions: array.isRequired,
  /** Callback when the user
   * @callback
   * @param {string} filterOption The filter option that was chosen for this chip
   * @param {{min, max}} values The filter values that were chosen
   */
  onConfirmCreate: func.isRequired
};

export default AddFilterChip;
