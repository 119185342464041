import React, { useEffect, useState, memo, useCallback } from 'react';

// Components
import Spot from './Spot';

// Helpers
import clustering from 'density-clustering';
import { isMobile } from 'react-device-detect';
import isEqual from 'lodash.isequal';
import { useTourState } from 'stores/TourStore';

// Constants
import { CLUSTER_CLICK_ZOOM_SCALE_STEP } from './MediaView';

const convertRelativeLocation = (
  relativeX,
  relativeY,
  containerSize,
  zoomScale
) => {
  // Get parent image size and container size
  const parentWidth = containerSize?.width || 0;
  const parentHeight = containerSize?.height || 0;

  // Calculate the half size of the image and the offset of the marker according to the center of the image
  const parentHalfWidth = parentWidth / 2.0;
  const parentHalfHeight = parentHeight / 2.0;

  const centerOffsetX = parentHalfWidth * relativeX;
  const centerOffsetY = parentHalfHeight * relativeY;

  // Add everything together to get the spot location according to the top left corner of the screen
  const scale = 1.0 / zoomScale;
  const cornerOffsetX = (centerOffsetX + parentHalfWidth) * scale;
  const cornerOffsetY = (centerOffsetY + parentHalfHeight) * scale;

  return { x: cornerOffsetX, y: cornerOffsetY };
};

const convertToRelativeLocation = (
  absoluteX,
  absoluteY,
  containerSize,
  zoomScale
) => {
  // Get parent image size and container size
  const parentWidth = containerSize?.width || 0;
  const parentHeight = containerSize?.height || 0;

  // Calculate the half size of the image and the offset of the marker according to the center of the image
  const parentHalfWidth = parentWidth / 2.0;
  const parentHalfHeight = parentHeight / 2.0;

  const scale = 1.0 / zoomScale;

  const centerOffsetX = absoluteX / scale - parentHalfWidth;
  const centerOffsetY = absoluteY / scale - parentHalfHeight;

  const relativeX = centerOffsetX / parentHalfWidth;
  const relativeY = centerOffsetY / parentHalfHeight;

  return { x: relativeX, y: relativeY };
};

const UNITS_CLUSTERING_THRESHOLD = 50;
const MIN_UNITS_TO_FORM_CLUSTER = 2;

const Spots = memo(
  ({
    filteredUnitSpotList,
    zoomScale,
    containerSize,
    containerDimensions,
    clusterSpotClickHandler,
    imageDimensions,
    forceDeselectSpots,
    onSpotHover,
    handleMobileUnitSpotClick,
    handleUnitSpotClick,
    idealImageRelativePosition,
    disableClustering,
    highlightedSpot,
    is360Viewer = false,
    currentRotation,
    canvas = null
  }) => {
    const [spotList, setSpotList] = useState([]);

    const { TourStateDispatch } = useTourState();

    useEffect(() => {
      // There're conditions when clustering is not applied:
      // 1. zoom level is maximum (3)
      // 2. clustering on desktops is not manually enabled (disabled by default)
      // 3. clustering on mobile devices is manually disabled (enabled by default)
      if (zoomScale > 2.9 || disableClustering) {
        setSpotList(filteredUnitSpotList);
        return;
      }

      // first get spots coordinates and prepare them for clustering
      // clustering dataset type is: Array<Array<number, number>>
      const spotsCoordinatesDataset = filteredUnitSpotList
        .filter((spot) => spot.navigationItemType === 'unitSpot')
        .map((spot) => {
          const spotLoc = convertRelativeLocation(
            spot.xCoordinate,
            spot.yCoordinate,
            containerSize,
            zoomScale
          );

          return [spotLoc.x, spotLoc.y];
        });

      // calculate clusters based on spots coordinates
      const dbscan = new clustering.DBSCAN();
      const clusters = dbscan.run(
        spotsCoordinatesDataset,
        UNITS_CLUSTERING_THRESHOLD / zoomScale,
        MIN_UNITS_TO_FORM_CLUSTER
      );

      // get clustered points coordinates
      const pointsToRemove = [];
      const clusteredPoints = [];
      clusters.forEach((cluster) => {
        pointsToRemove.push(...cluster);
        // coordPosition: 0 - for x, 1 - for y
        const avgCoord = (cluster, coordPosition) => {
          return Math.round(
            cluster.reduce(
              (sum, curr) =>
                (sum += spotsCoordinatesDataset[curr][coordPosition]),
              0
            ) / cluster.length
          );
        };

        const avgX = avgCoord(cluster, 0);
        const avgY = avgCoord(cluster, 1);
        clusteredPoints.push([avgX, avgY, cluster.length]);
      });

      // remove spots which were combined in clusters
      const filteredSpots = filteredUnitSpotList.filter(
        (_, idx) => !pointsToRemove.includes(idx)
      );

      const nextScaleLevel =
        zoomScale + CLUSTER_CLICK_ZOOM_SCALE_STEP > 3
          ? 3
          : zoomScale + CLUSTER_CLICK_ZOOM_SCALE_STEP;

      // create spot list combining unit spots and cluster spots
      const updatedUnitSpotList = [
        ...filteredSpots,
        ...clusteredPoints.map(([x, y, numberOfUnits], idx) => {
          const coords = convertToRelativeLocation(
            x,
            y,
            containerSize,
            zoomScale
          );
          const nextScaleLevelClusterCenterCoordinates = {
            x: containerDimensions.width / 2 - x * nextScaleLevel,
            y: containerDimensions.height / 2 - y * nextScaleLevel
          };
          return {
            xCoordinate: coords.x,
            yCoordinate: coords.y,
            numberOfUnits,
            navigationItemType: 'cluster',
            objectId: `cluster-${idx}`,
            nextScaleLevelClusterCenterCoordinates
          };
        })
      ];

      setSpotList(updatedUnitSpotList);
    }, [
      zoomScale,
      containerSize,
      containerDimensions,
      filteredUnitSpotList,
      disableClustering
    ]);

    const handleSpotClick = useCallback(
      (spotObject) => {
        TourStateDispatch({
          type: 'update',
          payload: {
            highlightedSpot: null
          }
        });
        return isMobile
          ? handleMobileUnitSpotClick(spotObject)
          : handleUnitSpotClick(spotObject);
      },
      [handleMobileUnitSpotClick, handleUnitSpotClick, TourStateDispatch]
    );

    return (
      <>
        {spotList.map((spot) => {
          let spotProps = {
            key: `unitSpot${spot.objectId}`,
            parentDimensions: containerSize,
            imageDimensions: imageDimensions,
            disabled: false,
            spotObject: spot,
            forceDeselectSpots,
            zoomScale: zoomScale,
            onSpotHover,
            idealImageRelativePosition,
            is360Viewer,
            canvas,
            currentRotation
          };
          return (
            <Spot
              {...spotProps}
              onClusterSpotClicked={clusterSpotClickHandler}
              onSpotClicked={handleSpotClick}
              onSpotTouched={handleMobileUnitSpotClick}
              spotScale={1 / zoomScale}
              isHighlighted={spot?.value === highlightedSpot?.value}
            />
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => {
    let noUpdate = true;

    Object.entries(prevProps).forEach(([key, value]) => {
      if (
        [
          'containerSize',
          'containerDimensions',
          'clusterSpotClickHandler',
          'idealImageRelativePosition',
          'highlightedSpot'
        ].includes(key)
      ) {
        if (!isEqual(prevProps[key], nextProps[key])) {
          noUpdate = false;
        }
      } else if (prevProps[key] !== nextProps[key]) {
        noUpdate = false;
      }
    });

    return noUpdate;
  }
);

export default Spots;
