import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { shape, array, string, func } from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

// Components
import Spot from './Spot';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ZoomIndicator from './ZoomIndicator';
import { IdealImage } from '@prompto-ui';
import ToggleFilterModalButton from './searchAndFilter/ToggleFilterModalButton';
import ToggleUnitVisibility from './searchAndFilter/ToggleUnitVisibilityButton';
import DayNightToggle from './DayNightToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileUnitPreview from './MobileUnitPreview';
import OutOfViewportSpotMarkers from './OutOfViewportSpotMarkers';
import Spots from './Spots';
import FullscreenTurntablePreview from 'components/pages/turntable/FullscreenTurntablePreview';
import PolygonSpot from './polygonSpot/PolygonSpot';
import PolygonSpotTooltip from './polygonSpot/PolygonSpotTooltip';
import Fullscreen360Viewer from 'components/pages/resources/contentItems/types/album360/Fullscreen360Viewer';
import UspSpotMobileTooltip from './uspSpot/UspSpotMobileTooltip';
import PinnedSpot from './PinnedSpot';
import UnitSpotTooltip from './unitSpot/UnitSpotTooltip';
import MediaView360 from './MediaView360';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import { useProjectState } from 'stores/ProjectStore';
import { useTourState } from 'stores/TourStore';
import { useUiState } from 'stores/UiStore';
import { filterUnits, searchUnits } from 'helpers/units/VmUnitHelper';
import {
  checkIfRenderedInIframe,
  isValidUrl,
  getAbsoluteUrl,
  fetchSettingsFromURL,
  getCurrentEnvironment
} from 'helpers';
import { usePrevious } from '@prompto-helpers';
import localizer from 'localization/localizer';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import IdleTimer from 'react-idle-timer';
import queryParamAbbreviations from 'configs/QueryParamAbbreviations.json';
import isEqual from 'lodash.isequal';
import { NavigationItem } from '@prompto-api';

// Styling
import styled from 'styled-components';

const PreviewWrapper = styled.div``;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const Toast = styled(motion.div)`
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${(props) => props.theme.warning};
  border-radius: 17px;
  width: fit-content;
  height: 34px;
  padding: 0 25px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) => props.theme.whitePure};
  ${isMobile &&
  `
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 90vw;
  `}
`;

const PolygonArea = styled.svg`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ToastIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const getToastMotions = () => {
  return isMobile
    ? {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
      }
    : {
        initial: { opacity: 0, y: 0 },
        animate: { opacity: 1, y: -32 },
        exit: { opacity: 0, y: 0 }
      };
};

export const CLUSTER_CLICK_ZOOM_SCALE_STEP = 0.8;

const defaultSpotColor = '#AFAFAF';

const ignoredFilters = ['buildingObjectId'];
const pinnedSpotPadding = 20;

const filterPolygonSpots = (spots) => {
  return spots.filter((spot) => {
    if (!spot) return false;

    if (spot.assignedItem.navigationItemType === 'uspSpot') {
      return !!spot.usp;
    }

    return true;
  });
};

const MediaView = memo(
  ({
    className,
    spotList,
    mediaView,
    tourId,
    showAllUnits,
    setShowAllUnits,
    onUpdateMediaView,
    onOpenUnitPage,
    onStartImmersion,
    onStopImmersion,
    viewTransition,
    setNumberOfFilteredUnitsForCurrentView,
    setPolygonMediaList
  }) => {
    const wrapperRef = useRef();
    const pannellumRef = useRef();

    const { ProjectState, ProjectStateDispatch } = useProjectState();
    const {
      project,
      turntableList,
      turntableListFetched,
      contentCollection,
      vault,
      uspList,
      uspListFetched,
      unitFilters,
      filterSearchValue,
      nightMode,
      showcaseConfiguration,
      initialUnitList
    } = ProjectState;

    const { TourState, TourStateDispatch } = useTourState();
    const { highlightedSpot, highlightedUnitSpotParams } = TourState;

    const [minZoom, setMinZoom] = useState(0.5);
    const [imageDimensions, setImageDimensions] = useState();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [forceDeselectSpots, setForceDeselectSpots] = useState(false);
    const [forceSpotsRender, setForceSpotsRender] = useState(false);

    const [navigationSpotList, setNavigationSpotList] = useState([]);
    const [polygonSpotList, setPolygonSpotList] = useState([]);
    const [unitSpotList, setUnitSpotList] = useState([]);

    const [canShowNightImage, setCanShowNightImage] = useState(false);
    const [isRenderedInIframe, setIsRenderedInIframe] = useState(false);

    const [contentItemToRender, setContentItemToRender] = useState();
    const [hoveredPolygon, setHoveredPolygon] = useState();
    const [polygonTooltipHovered, setPolygonTooltipHovered] = useState(false);

    const [showTurntable, setShowTurntable] = useState();
    const [show360, setShow360] = useState(false);

    const { UiState, UiStateDispatch } = useUiState();
    const { hideTourControls } = UiState;

    const [firstLoad, setFirstLoad] = useState(false);

    // Filter and search
    const [unitListForThisView, setUnitListForThisView] = useState([]);
    const [filteredUnitSpotList, setFilteredUnitSpotList] = useState([]);
    const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
    const [isFilterButtonHovered, setIsFilterButtonHovered] = useState(false);

    // Unit control management
    const [showUnits, setShowUnits] = useState(isMobile ? true : showAllUnits);
    const [isUnitMobilePreviewVisible, setUnitMobilePreviewVisible] =
      useState(false);
    const [unitClickedOnMobile, setUnitClickedOnMobile] = useState(null);

    const [isInvalidUrlErrorShown, setInvalidUrlErrorShown] = useState(false);

    // Pinned spots
    const [pinnedSpotList, setPinnedSpotList] = useState([]);

    // 360 viewer related
    const [viewer360Dimensions, setViewer360Dimensions] = useState();
    const [isViewer360Loaded, setViewer360Loaded] = useState(false);
    const [currentRotation, setCurrentRotation] = useState();

    const [
      itemsAssignedToPolygonsAreFetched,
      setItemsAssignedToPolygonsAreFetched
    ] = useState(false);
    const [
      fetchingItemsAssignedToPolygons,
      setFetchingItemsAssignedToPolygons
    ] = useState(false);

    const is360Viewer =
      mediaView.contentItem?.contentItemType === 'image360' ||
      mediaView.contentItemToRender?.contentItemType === 'image360';

    const tooltipHovered = useRef(false);

    // screen orientation
    const { isLandscape } = useMobileOrientation();
    const prevOrientation = usePrevious(isLandscape);

    const onSpotHover = useCallback(
      (spot) => {
        const payload = {
          highlightedSpot: spot
        };
        if (!spot) {
          payload.highlightedUnitSpotParams = null;
        }

        if (!spot && highlightedSpot?.objectId) return;

        TourStateDispatch({
          type: 'update',
          payload
        });
      },
      [TourStateDispatch, highlightedSpot]
    );

    const toggleErrorMessage = useCallback(() => {
      setInvalidUrlErrorShown(true);
      const timer = setTimeout(() => {
        setInvalidUrlErrorShown(false);
        clearTimeout(timer);
      }, 3000);
    }, []);

    useEffect(() => {
      setNumberOfFilteredUnitsForCurrentView(filteredUnitSpotList.length);
    }, [filteredUnitSpotList]);

    useEffect(() => {
      if (imageLoaded) {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          setForceSpotsRender(true);
        }, 1500);
      }
    }, [imageLoaded]);

    useEffect(() => {
      setIsRenderedInIframe(checkIfRenderedInIframe());
    }, []);

    useEffect(() => {
      setShowAllUnits(showUnits);
    }, [showUnits, setShowAllUnits]);

    useEffect(() => {
      setCanShowNightImage(mediaView?.nightContentItem);
    }, [mediaView]);

    useEffect(() => {
      if (!mediaView) return;
      if (spotList) {
        // Get a list of units from the current spots
        const unitList = spotList
          .map((spot) => {
            if (spot.navigationItemType === 'unitSpot') {
              return spot.unitItem;
            }
            return null;
          })
          .filter((x) => x !== null);

        setUnitListForThisView(unitList);

        // Separate the unit spots and the navigation spots
        const navSpots = [];
        const unitSpots = [];
        const pinnedSpots = [];

        spotList.forEach((spot) => {
          const type = spot.navigationItemType;
          if (!!spot.pinConfiguration?.position) {
            pinnedSpots.push(spot);
          } else if (type === 'unitSpot') {
            unitSpots.push(spot);
          } else if (
            [
              'navigationSpot',
              'turntableSpot',
              'uspSpot',
              'album360Spot'
            ].includes(type)
          ) {
            navSpots.push(spot);
          }
        });

        setNavigationSpotList(navSpots);
        setUnitSpotList(unitSpots);
        setPinnedSpotList(pinnedSpots);
      }
    }, [spotList, project, turntableList, mediaView]);

    // Calculate polygon spots and assigned items
    useEffect(() => {
      if (fetchingItemsAssignedToPolygons) return;
      if (itemsAssignedToPolygonsAreFetched) return;

      if (initialUnitList && uspListFetched && turntableListFetched) {
        // Get the list of all polygon spots
        const polygonSpots = spotList.filter(
          (spot) => spot.navigationItemType === 'polygonSpot'
        );

        const contentItemList = contentCollection?.vmContentItemList;

        // The polygon spots have certain items assigned to them, here we fetch them all
        const assignedItems = polygonSpots.map(async (spot) => {
          if (spot.assignedNavigationItemObjectId) {
            const result = await NavigationItem.get(
              spot.assignedNavigationItemObjectId
            );

            if (result) {
              const { data } = result;
              const { navigationItem } = data;

              return navigationItem;
            }
          } else {
            return null;
          }
        });

        // We wait till we have gotten all these items and attach them to the polygon spots
        setFetchingItemsAssignedToPolygons(true);
        Promise.all(assignedItems).then((items) => {
          setItemsAssignedToPolygonsAreFetched(true);
          // make sure polygon spots still belong to current media view
          const validPolygonSpots = polygonSpots.filter((spot) =>
            mediaView.navigationCollection?.vmNavigationItemList?.some(
              (x) => x.objectId === spot.objectId
            )
          );

          if (validPolygonSpots.length === 0) return;

          const polygonSpotsWithAssignedItems = validPolygonSpots
            .map((spot) => {
              if (spot.assignedNavigationItemObjectId) {
                return {
                  ...spot,
                  assignedItem: items.filter(
                    (x) => x?.objectId === spot.assignedNavigationItemObjectId
                  )[0]
                };
              } else {
                return null;
              }
            })
            // Filter out the polygons that have nothing assigned
            .filter((x) => x !== null);

          // Now for each type of assigned item we attach as well what we need, be it a content item, a unit or a turntable
          const preparedPolygonSpots = polygonSpotsWithAssignedItems.map(
            (x) => {
              if (x.assignedItem.navigationItemType !== 'mediaView') {
                if (x.assignedItem.navigationItemType === 'unitSpot') {
                  const unit = initialUnitList?.find(
                    (y) => y.objectId === x.assignedItem.value
                  );
                  // Only add spot if unit exists or is available
                  if (unit) {
                    return { ...x, unitItem: unit };
                  }
                } else if (x.assignedItem.navigationItemType === 'uspSpot') {
                  const uspForSpot = uspList.find(
                    (y) => y.objectId === x.assignedItem.value
                  );
                  return { ...x, usp: uspForSpot };
                } else if (
                  x.assignedItem.navigationItemType === 'album360Spot'
                ) {
                  const spotContentItem = contentItemList.find(
                    (y) => y.objectId === x.assignedItem.value
                  );
                  return { ...x, contentItemToRender: spotContentItem };
                } else if (
                  x.assignedItem.navigationItemType === 'turntableSpot'
                ) {
                  const turntable = turntableList?.find(
                    (y) => y.objectId === x.assignedItem.value
                  );
                  return {
                    ...x,
                    turntable: turntable,
                    contentItemToRender: turntable?.contentItem
                  };
                } else {
                  return null;
                }
              } else {
                const mediaViewContentItem = contentItemList.find(
                  (y) => y.objectId === x.assignedItem.value
                );

                return { ...x, contentItemToRender: mediaViewContentItem };
              }
            }
          );

          const filteredPolygonSpots = filterPolygonSpots(preparedPolygonSpots);

          setFetchingItemsAssignedToPolygons(false);
          setPolygonSpotList(filteredPolygonSpots);
          setPolygonMediaList(filteredPolygonSpots);
        });
      }
    }, [
      itemsAssignedToPolygonsAreFetched,
      contentCollection,
      spotList,
      fetchingItemsAssignedToPolygons,
      uspList,
      uspListFetched,
      turntableList,
      turntableListFetched,
      project,
      mediaView,
      initialUnitList
    ]);

    useEffect(() => {
      if (unitListForThisView) {
        let filteredList = filterUnits(
          unitListForThisView,
          unitFilters,
          ignoredFilters
        );
        filteredList = searchUnits(filteredList, filterSearchValue);

        const newFilteredSpots = unitSpotList.filter((spot) => {
          if (filteredList.includes(spot.unitItem)) {
            return true;
          }
          return false;
        });

        setFilteredUnitSpotList(newFilteredSpots);
      }
    }, [
      unitListForThisView,
      unitSpotList,
      unitFilters,
      filterSearchValue,
      spotList
    ]);

    useEffect(() => {
      if (nightMode && canShowNightImage) {
        setContentItemToRender(mediaView?.nightContentItem);
      } else if (mediaView?.contentItemToRender) {
        setContentItemToRender(mediaView?.contentItemToRender);
      } else {
        setContentItemToRender(mediaView?.contentItem);
      }
    }, [nightMode, canShowNightImage, mediaView]);

    // show a 360Image or a turntable if respective query param is presented
    useEffect(() => {
      if (navigationSpotList?.length === 0) return;
      const queryParams = fetchSettingsFromURL();
      const image360Param = queryParamAbbreviations.threeSixtyImage;
      const turntableParam = queryParamAbbreviations.turntable;
      // only one can be opened simultaneously
      if (queryParams[image360Param]) {
        const spot = navigationSpotList.find(
          (x) => x.contentItem?.objectId === queryParams[image360Param]
        );
        if (spot) setShow360(spot.contentItem);
      } else if (queryParams[turntableParam]) {
        const spot = navigationSpotList.find(
          (x) => x.turntable?.objectId === queryParams[turntableParam]
        );
        if (spot) setShowTurntable(spot.turntable);
      }
    }, [navigationSpotList]);

    const enlargeUsp = useCallback(
      (event, uspObjectId, enlargedUspData) => {
        UiStateDispatch({
          type: 'update',
          payload: {
            enlargedUsp: {
              data: enlargedUspData,
              origin: { x: event.clientX, y: event.clientY },
              spotLocation: { x: event.clientX, y: event.clientY }
            }
          }
        });
      },
      [UiStateDispatch]
    );

    const handleNavigationSpotClick = useCallback(
      (spotObject) => {
        switch (spotObject.navigationItemType) {
          default:
          case 'navigationSpot':
            onUpdateMediaView(spotObject);
            break;
          case 'turntableSpot':
            setShowTurntable(spotObject.turntable);
            UiStateDispatch({
              type: 'requestHideMinimap',
              payload: true
            });
            break;
          case 'album360Spot':
            if (spotObject.hasMediaView) {
              onUpdateMediaView(spotObject);
            } else {
              setShow360(spotObject.contentItem);
            }
            break;
        }
      },
      [onUpdateMediaView, UiStateDispatch]
    );

    const createNavigationSpots = useCallback(
      (scale, containerSize, idealImageRelativePosition) => {
        const spots = navigationSpotList.map((spot, index) => {
          let spotProps = {
            key: `navigationSpot${index}-${spot.objectId}`,
            parentDimensions: containerSize,
            imageDimensions: imageDimensions,
            spotObject: {
              ...spot,
              color: spot?.color || defaultSpotColor
            },
            forceDeselectSpots,
            zoomScale: scale,
            onSpotHover,
            idealImageRelativePosition,
            is360Viewer,
            canvas: pannellumRef.current?.getViewer(),
            currentRotation
          };
          return (
            <Spot
              {...spotProps}
              onSpotClicked={handleNavigationSpotClick}
              spotScale={1 / scale}
              enlargeUsp={enlargeUsp}
              isHighlighted={spot?.value === highlightedSpot?.value}
              forceRender={forceSpotsRender}
            />
          );
        });
        return spots;
      },
      [
        enlargeUsp,
        forceDeselectSpots,
        imageDimensions,
        navigationSpotList,
        onSpotHover,
        handleNavigationSpotClick,
        highlightedSpot,
        forceSpotsRender,
        is360Viewer,
        currentRotation
      ]
    );

    const showUnitDetails = useCallback(
      (spotObject) => {
        if (showcaseConfiguration?.showUnitExternalLink) {
          if (!spotObject.unitItem?.unitMetadata?.externalLink) return;
          if (['IN_OPTION', 'SOLD'].includes(spotObject.unitItem?.state))
            return;
          if (isValidUrl(spotObject.unitItem.unitMetadata.externalLink)) {
            window.open(
              getAbsoluteUrl(spotObject.unitItem.unitMetadata.externalLink)
            );
          } else {
            toggleErrorMessage();
          }
        } else {
          onOpenUnitPage(spotObject?.unitItem);
        }
      },
      [showcaseConfiguration, onOpenUnitPage, toggleErrorMessage]
    );

    const handleUnitSpotClick = useCallback(
      (spotObject) => {
        showUnitDetails(spotObject);
      },
      [showUnitDetails]
    );

    const handleMobileUnitSpotClick = useCallback((spotObject) => {
      setUnitClickedOnMobile(spotObject?.unitItem);
      setUnitMobilePreviewVisible(true);
    }, []);

    const handleUnitSpotHover = useCallback(
      (spot) => {
        if (spot) {
          onSpotHover(spot);
          return;
        }

        const timer = setTimeout(() => {
          clearTimeout(timer);
          if (!tooltipHovered.current) {
            onSpotHover(spot);
          }
        }, 30);
      },
      [onSpotHover]
    );

    const setSearchValue = useCallback(
      (value) => {
        ProjectStateDispatch({
          type: 'setProjectData',
          payload: {
            filterSearchValue: value
          }
        });
      },
      [ProjectStateDispatch]
    );

    if (
      !mediaView?.contentItem?.contentUri &&
      !mediaView?.contentItemToRender?.contentUri
    ) {
      return null;
    }

    const polygonSpots = (parentDimensions, scale) =>
      polygonSpotList.map((spot, idx) => {
        return (
          <PolygonSpot
            key={`polygonSpot${spot?.objectId ?? idx}`}
            spot={spot}
            zoomScale={scale}
            imageSize={parentDimensions}
            isMobile={isMobile}
            onHoverPolygon={setHoveredPolygon}
            hoveredPolygon={hoveredPolygon}
            isPolygonTooltipHovered={
              spot?.assignedItem?.objectId === polygonTooltipHovered
            }
            onSpotClicked={() => {
              switch (spot.assignedItem.navigationItemType) {
                default:
                case 'unitSpot':
                  showUnitDetails(spot);
                  break;
                case 'turntableSpot':
                  setShowTurntable(spot.turntable);
                  UiStateDispatch({
                    type: 'requestHideMinimap',
                    payload: true
                  });
                  break;
                case 'mediaView':
                  onUpdateMediaView({ value: spot.assignedItem.objectId });
                  break;
                case 'album360Spot':
                  onUpdateMediaView({
                    value: spot.contentItemToRender.objectId,
                    valuePolygon: spot.assignedItem.objectId
                  });
                  break;
                case 'uspSpot':
                  UiStateDispatch({
                    type: 'update',
                    payload: {
                      showUspMobileTooltip: true,
                      activeUsp: {
                        spot,
                        location: {}
                      }
                    }
                  });
                  break;
              }
            }}
          />
        );
      });

    const disableClustering =
      (!isMobile &&
        showcaseConfiguration.enableUnitClusteringForDesktop !== true) ||
      (isMobile &&
        showcaseConfiguration.enableUnitClusteringForMobile === false);

    let content = (
      <TransformWrapper
        wheel={{ step: 100, disabled: isRenderedInIframe }}
        options={{
          minScale: minZoom,
          maxScale: 3
        }}
        pan={{ paddingSize: 0 }}
        zoomIn={{ step: 5 }}
        zoomOut={{ step: 5 }}
        doubleClick={{ disabled: true }}
      >
        {({
          zoomIn,
          zoomOut,
          scale,
          positionX,
          positionY,
          setTransform,
          resetTransform,
          pan
        }) => {
          // do not let user be able to pan (move) tour if it scaled to 100%
          // + it rendered in iframe
          // + only for tablet and phone devices
          pan.disabled = scale <= 1 && isRenderedInIframe && isMobile;

          const idealImageRelativePosition = {
            offsetX: positionX,
            offsetY: positionY
          };
          const container = document.getElementById('imageTour');
          const parentDimensions = {
            width: container?.getBoundingClientRect().width,
            height: container?.getBoundingClientRect().height
          };

          const tourContainer = document.getElementById('tourContainer');
          const containerDimensions = {
            width: tourContainer?.getBoundingClientRect().width,
            height: tourContainer?.getBoundingClientRect().height
          };

          const centerPosition = {
            centerX: (imageDimensions?.width - containerDimensions.width) / -2,
            centerY: (imageDimensions?.height - containerDimensions.height) / -2
          };

          // keep zoom scale lower than 3 to compliment TransformWrapper options
          const nextScaleLevel =
            scale + CLUSTER_CLICK_ZOOM_SCALE_STEP > 3
              ? 3
              : scale + CLUSTER_CLICK_ZOOM_SCALE_STEP;

          // calculated positions for USPs pinned to the screen corners
          const pinnedSpotTop =
            (idealImageRelativePosition.offsetY * -1 + pinnedSpotPadding) /
            scale;
          const pinnedSpotLeft =
            (idealImageRelativePosition.offsetX * -1 + pinnedSpotPadding) /
            scale;
          const pinnedSpotBottom =
            (parentDimensions.height +
              idealImageRelativePosition.offsetY -
              containerDimensions.height +
              pinnedSpotPadding) /
            scale;
          const pinnedSpotRight =
            (parentDimensions.width +
              idealImageRelativePosition.offsetX -
              containerDimensions.width +
              pinnedSpotPadding) /
            scale;

          const clusterSpotClickHandler = disableClustering
            ? undefined
            : (clusterX, clusterY) => {
                setTransform(clusterX, clusterY, nextScaleLevel, 500);
              };

          return (
            <>
              {!hideTourControls && (
                <ZoomIndicator
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  scale={scale}
                  tourId={tourId}
                  setTransform={setTransform}
                  onStartImmersion={onStartImmersion}
                  onStopImmersion={onStopImmersion}
                  centerPosition={centerPosition}
                  resetTransform={resetTransform}
                />
              )}

              <TransformComponent key="tranformWrapper">
                <Wrapper
                  key="imageWrapper"
                  id="imageTour"
                  ref={wrapperRef}
                  className={className}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={viewTransition}
                  onClick={(e) => {
                    setForceDeselectSpots(true);
                  }}
                  width={imageDimensions?.width}
                  height={imageDimensions?.height}
                >
                  <ReactResizeDetector
                    handleHeight
                    handleWidth
                    skipOnMount
                    onResize={() => {
                      setTimeout(() => {
                        resetTransform();
                      }, 200);
                    }}
                  />

                  <IdealImage
                    parentType={'imageTour'}
                    key={mediaView?.objectId}
                    contentUri={contentItemToRender?.contentUri}
                    fallbackUri={contentItemToRender?.contentUri}
                    imageSize={contentItemToRender?.originalImageSize}
                    containerSize={containerDimensions}
                    onLoad={(iWidth, iHeight) => {
                      if (!firstLoad && imageLoaded) {
                        setTransform(
                          centerPosition.centerX,
                          centerPosition.centerY,
                          1,
                          0
                        );
                        setFirstLoad(true);
                      }
                      setImageDimensions({
                        width: iWidth,
                        height: iHeight
                      });
                      const timer = setTimeout(() => {
                        clearTimeout(timer);
                        setImageLoaded(true);
                      }, 250);

                      if (prevOrientation && isLandscape === prevOrientation) {
                        setTimeout(() => {
                          setTransform(
                            centerPosition.centerX,
                            centerPosition.centerY,
                            1
                          );
                        }, 500);
                      }

                      setMinZoom(
                        Math.min(
                          containerDimensions.width / iWidth,
                          containerDimensions.height / iHeight
                        )
                      );
                    }}
                    enableTracking={true}
                    vaultId={vault?.objectId}
                    projectId={project?.objectId}
                    forceOriginalSize={scale > 1.5}
                    baseImageUrl={getCurrentEnvironment().baseImageUrl}
                    imageFitFillType={
                      showcaseConfiguration?.initialTourImageFitFillType
                    }
                  />

                  {parentDimensions && imageLoaded && (
                    <PolygonArea key={`polygonArea`}>
                      {polygonSpots(parentDimensions, scale)}
                    </PolygonArea>
                  )}
                  {hoveredPolygon && (
                    <PolygonSpotTooltip
                      item={hoveredPolygon.spot}
                      vault={vault}
                      project={project}
                      isMobile={isMobile}
                      onUnitTooltipClicked={showUnitDetails}
                      onNavigationTooltipClicked={(spot) => {
                        switch (spot.assignedItem.navigationItemType) {
                          default:
                          case 'mediaView':
                            onUpdateMediaView({
                              value: spot.assignedItem.objectId
                            });
                            break;
                          case 'turntableSpot':
                            setShowTurntable(spot.turntable);
                            UiStateDispatch({
                              type: 'requestHideMinimap',
                              payload: true
                            });
                            break;
                          case 'album360Spot':
                            setShow360(spot.contentItemToRender);
                            break;
                        }
                      }}
                      hoveredLocation={hoveredPolygon.pos}
                      polygonSize={hoveredPolygon.size}
                      parentDimensions={parentDimensions}
                      zoomScale={scale}
                      idealImageRelativePosition={idealImageRelativePosition}
                      polygonTooltipHovered={polygonTooltipHovered}
                      setPolygonTooltipHovered={setPolygonTooltipHovered}
                    />
                  )}
                  <AnimatePresence>
                    {parentDimensions &&
                      imageLoaded &&
                      !showTurntable &&
                      createNavigationSpots(
                        scale,
                        parentDimensions,
                        idealImageRelativePosition
                      )}
                    {filteredUnitSpotList.length > 0 &&
                      parentDimensions &&
                      imageLoaded &&
                      showUnits && (
                        <Spots
                          filteredUnitSpotList={filteredUnitSpotList}
                          zoomScale={scale}
                          showTurntable={showTurntable}
                          containerSize={parentDimensions}
                          containerDimensions={containerDimensions}
                          clusterSpotClickHandler={clusterSpotClickHandler}
                          imageDimensions={imageDimensions}
                          forceDeselectSpots={forceDeselectSpots}
                          onSpotHover={handleUnitSpotHover}
                          handleMobileUnitSpotClick={handleMobileUnitSpotClick}
                          handleUnitSpotClick={handleUnitSpotClick}
                          idealImageRelativePosition={
                            idealImageRelativePosition
                          }
                          showcaseConfiguration={showcaseConfiguration}
                          disableClustering={disableClustering}
                          highlightedSpot={highlightedSpot}
                          highlightedUnitSpotParams={highlightedUnitSpotParams}
                        />
                      )}
                  </AnimatePresence>
                  {!isMobile &&
                    pinnedSpotList.map((spot) => (
                      <PinnedSpot
                        key={spot.objectId}
                        spot={spot}
                        moveForward={() => {
                          setPinnedSpotList((prev) => [
                            ...prev.filter((x) => x.objectId !== spot.objectId),
                            spot
                          ]);
                        }}
                        offsets={{
                          top: Math.floor(pinnedSpotTop),
                          left: Math.floor(pinnedSpotLeft),
                          bottom: Math.floor(pinnedSpotBottom),
                          right: Math.floor(pinnedSpotRight)
                        }}
                        zoomScale={scale}
                        enlargeUsp={enlargeUsp}
                        showcaseConfiguration={showcaseConfiguration}
                      />
                    ))}
                </Wrapper>
              </TransformComponent>
              {filteredUnitSpotList.length > 0 &&
                parentDimensions &&
                imageLoaded &&
                showUnits && (
                  <OutOfViewportSpotMarkers
                    filteredUnitSpotList={filteredUnitSpotList}
                    scale={scale}
                    parentDimensions={parentDimensions}
                    idealImageRelativePosition={idealImageRelativePosition}
                    containerDimensions={containerDimensions}
                  />
                )}
            </>
          );
        }}
      </TransformWrapper>
    );

    if (is360Viewer)
      content = (
        <MediaView360
          ref={pannellumRef}
          imageSrc={`${getCurrentEnvironment().baseImageUrl}/o=true/${
            contentItemToRender?.contentUri
          }`}
          setViewer360Dimensions={setViewer360Dimensions}
          setViewer360Loaded={setViewer360Loaded}
          setCurrentRotation={setCurrentRotation}
        >
          <AnimatePresence>
            {viewer360Dimensions &&
              isViewer360Loaded &&
              createNavigationSpots(1, viewer360Dimensions)}

            {filteredUnitSpotList.length > 0 &&
              viewer360Dimensions &&
              isViewer360Loaded &&
              showUnits && (
                <Spots
                  filteredUnitSpotList={filteredUnitSpotList}
                  zoomScale={1}
                  showTurntable={showTurntable}
                  containerSize={viewer360Dimensions}
                  containerDimensions={viewer360Dimensions}
                  clusterSpotClickHandler={() => {}}
                  imageDimensions={viewer360Dimensions}
                  forceDeselectSpots={forceDeselectSpots}
                  onSpotHover={handleUnitSpotHover}
                  handleMobileUnitSpotClick={handleMobileUnitSpotClick}
                  handleUnitSpotClick={handleUnitSpotClick}
                  showcaseConfiguration={showcaseConfiguration}
                  disableClustering={true}
                  highlightedSpot={highlightedSpot}
                  highlightedUnitSpotParams={highlightedUnitSpotParams}
                  is360Viewer={true}
                  currentRotation={currentRotation}
                  canvas={pannellumRef.current?.getViewer()}
                />
              )}
          </AnimatePresence>
        </MediaView360>
      );

    return (
      <>
        <IdleTimer
          timeout={1500} // 1.5 seconds
          onActive={() => {
            ProjectStateDispatch({
              type: 'setIsUserIdle',
              payload: false
            });
          }}
          onIdle={() => {
            ProjectStateDispatch({
              type: 'setIsUserIdle',
              payload: true
            });
          }}
        />

        {content}

        {showTurntable && (
          <FullscreenTurntablePreview
            turntable={showTurntable}
            isMobile={isMobile}
            onExit={() => {
              setShowTurntable(null);
            }}
            onOpenUnitPage={onOpenUnitPage}
          />
        )}
        {show360 && (
          <Fullscreen360Viewer
            image360ContentItem={show360}
            onExit={() => {
              setShow360(null);
            }}
          />
        )}
        {isFilterModalOpened && (
          <ToggleUnitVisibility
            onButtonClicked={() => {
              setShowUnits((current) => !current);
            }}
            unitsAreVisible={showUnits}
            isFilterButtonHovered={isFilterButtonHovered}
          />
        )}
        {!isMobile &&
          !showcaseConfiguration?.hideFilterOptions &&
          !hideTourControls && (
            <ToggleFilterModalButton
              amountOfUnitsInFilter={filteredUnitSpotList.length}
              onModalToggled={(isOpened) => {
                setIsFilterModalOpened(isOpened);
                // show  units when opening the filter modal
                if (!showUnits && isOpened) {
                  setShowUnits(true);
                }
              }}
              onSearchChanged={setSearchValue}
              enabled={unitListForThisView.length > 0}
              isFilterButtonHovered={isFilterButtonHovered}
              setIsFilterButtonHovered={setIsFilterButtonHovered}
              showUnits={showUnits}
            />
          )}
        {canShowNightImage && (
          <DayNightToggle
            initialState={nightMode}
            onToggle={(isNight) => {
              setContentItemToRender(null);
              ProjectStateDispatch({
                type: 'setNightMode',
                payload: isNight
              });
            }}
          />
        )}
        {isInvalidUrlErrorShown && (
          <Toast {...getToastMotions()}>
            <ToastIcon icon={['far', 'ban']} size="sm" />
            {localizer.wrongUrlWarning}
          </Toast>
        )}
        {isUnitMobilePreviewVisible && (
          <MobileUnitPreview
            unit={unitClickedOnMobile}
            onClose={() => {
              setUnitClickedOnMobile(null);
              setUnitMobilePreviewVisible(false);
              onSpotHover(null);
            }}
            onExploreDetailsClick={showUnitDetails}
          />
        )}

        <UspSpotMobileTooltip />

        {highlightedSpot?.unitItem && highlightedUnitSpotParams && (
          <PreviewWrapper>
            <UnitSpotTooltip
              shouldShow={!!(highlightedSpot && highlightedUnitSpotParams)}
              unitSpotLocation={highlightedUnitSpotParams?.spotLocation}
              unit={highlightedSpot?.unitItem}
              showUnitState={highlightedUnitSpotParams?.showUnitState}
              onClick={() => {
                TourStateDispatch({
                  type: 'update',
                  payload: {
                    highlightedSpot: null
                  }
                });
                return isMobile
                  ? handleMobileUnitSpotClick(highlightedSpot)
                  : handleUnitSpotClick(highlightedSpot);
              }}
              onTouchStart={() => handleMobileUnitSpotClick(highlightedSpot)}
              unitSpotAbsoluteLocation={
                highlightedUnitSpotParams?.spotAbsoluteLocation
              }
              isSharedTooltip={true}
              parentId={highlightedUnitSpotParams?.objectId}
              onMouseEnter={() => {
                tooltipHovered.current = true;
              }}
              onMouseLeave={(parentId) => {
                if (parentId === highlightedUnitSpotParams?.objectId) {
                  TourStateDispatch({
                    type: 'update',
                    payload: {
                      highlightedUnitSpotParams: null
                    }
                  });
                }
                tooltipHovered.current = false;
              }}
              is360Viewer={is360Viewer}
              canvas={pannellumRef.current?.getViewer()}
              currentRotation={currentRotation}
            />
          </PreviewWrapper>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    let noUpdate = true;

    Object.entries(prevProps).forEach(([key, value]) => {
      if (key === 'spotList') {
        if (!isEqual(prevProps[key], nextProps[key])) {
          noUpdate = false;
        }
      } else if (prevProps[key] !== nextProps[key]) {
        noUpdate = false;
      }
    });

    return noUpdate;
  }
);

MediaView.propTypes = {
  className: string,
  spotList: array.isRequired,
  imageUri: string,
  onUpdateMediaView: func.isRequired,
  viewTransition: shape({}),
  imageTransition: shape({}),
  goToLandingPage: func.isRequired
};

MediaView.defaultProps = {
  className: null,
  imageUri: null,
  viewTransition: { duration: 0.3 },
  imageTransition: null
};

export default MediaView;
