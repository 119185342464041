import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// API
import { User } from '@prompto-api';

// Components
import PortfolioPage from 'components/pages/portfolio/PortfolioPage';
import ShowcaseRouter from 'ShowcaseRouter';
import ConnectPage from 'components/pages/connect/ConnectPage';
import StreamController from 'components/other/StreamController';
import ScreenOrientationDisclaimer from 'components/other/ScreenOrientationDisclaimer';

// Helpers
import { useAuthState } from 'stores/AuthStore';
import { fetchSettingsFromURL, getAuth, canParseJSON } from 'helpers';
import { useStreamState } from 'stores/StreamStore';
import TourStore from 'stores/TourStore';
import { motion } from 'framer-motion';
import { useUiState } from 'stores/UiStore';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const StreamControllerWrapper = styled(motion.div)``;

const Router = () => {
  const { AuthStateDispatch } = useAuthState();
  const [sessionTokenFound, setSessionTokenFound] = useState(true);
  const [vaultIdFound, setVaultIdFound] = useState(true);
  const [, setForcedLanguage] = useState(localizer.getLanguage());

  const { StreamState, StreamStateDispatch } = useStreamState();
  const { streamUrl, streamType, project, useProjectGallery, sessionToken } =
    StreamState;

  const { UiStateDispatch } = useUiState();

  const queryParams = fetchSettingsFromURL();

  const [, setParentWindowLanguage] = useState(localizer.getLanguage());
  // Check if there is a param defining language
  useEffect(() => {
    const { language } = fetchSettingsFromURL();
    if (language) {
      localizer.setLanguage(language);
      setForcedLanguage(language);
    }
  }, []);

  // Check & store the utm source & utm campaign
  useEffect(() => {
    const utmSource = queryParams.utm_source ?? '';
    const utmCampaign = queryParams.utm_campaign ?? '';

    UiStateDispatch({
      type: 'setUTMdata',
      payload: {
        utmSource,
        utmCampaign
      }
    });
  }, []);

  // Create continuous check for sessionToken to see if user is logged in
  useEffect(() => {
    const checkerInterval = setInterval(() => {
      // check if the sessionToken is found
      setSessionTokenFound(!!getAuth().sessionToken);
      setVaultIdFound(!!getAuth().vaultId);
    }, 500);
    return () => {
      clearInterval(checkerInterval);
    };
  }, []);

  // Authenticate or logout the user
  useEffect(() => {
    if (sessionTokenFound) {
      if (vaultIdFound) {
        const user = getAuth();
        // Get user info
        User.getInfo(getAuth().sessionToken)
          .then((result) => {
            const {
              data: {
                includedFeatureDictionary,
                allowedVaultOperationDictionary
              }
            } = result;

            // Appcues Identification
            const { firstName, lastName, email, objectId } = result?.data?.user;
            if (window && window.Appcues) {
              window.Appcues.identify(objectId, {
                email: email,
                first_name: firstName,
                last_name: lastName
              });
            }

            AuthStateDispatch({
              type: 'authenticate',
              payload: {
                user: {
                  ...user,
                  features: includedFeatureDictionary[getAuth().vaultId],
                  allowedOperations:
                    allowedVaultOperationDictionary[getAuth().vaultId]
                },
                authenticating: false,
                authenticated: true
              }
            });
          })
          .catch((err) => {
            AuthStateDispatch({ type: 'logout' });
          });
      }
    } else {
      AuthStateDispatch({ type: 'logout' });
      AuthStateDispatch({ type: 'startCreateVisitorProcess', payload: true });
    }
  }, [sessionTokenFound, vaultIdFound, AuthStateDispatch]);

  // Get the language from parent window
  // and check in the showcase is embedded
  useEffect(() => {
    if (window.self !== window.top) {
      UiStateDispatch({
        type: 'update',
        payload: {
          isEmbeddedShowcase: true
        }
      });
      const handler = (event) => {
        if (event?.data && canParseJSON(event?.data)) {
          const data = JSON.parse(event.data);
          if (data.type === 'SYNC_LOCALIZATION' && data.language) {
            localizer.setLanguage(data.language);
            // by updating the state we force the localizer to apply changes
            setParentWindowLanguage(data.language);
          }
        }
      };

      window.addEventListener('message', handler);
      return () => window.removeEventListener('message', handler);
    }
  }, [UiStateDispatch]);

  const streamComponent = (
    <StreamController
      key={'streamController'}
      type={streamType}
      project={project}
      useProjectGallery={useProjectGallery}
      streamUrl={streamUrl}
      sessionToken={sessionToken}
      onExit={() => {
        StreamStateDispatch({
          type: 'close'
        });
      }}
    />
  );

  return (
    <BrowserRouter>
      <ScreenOrientationDisclaimer />
      <Routes>
        <Route path="/portfolio/*" element={<PortfolioPage />} />

        <Route
          path="/:code/*"
          element={
            <TourStore>
              <ShowcaseRouter />
            </TourStore>
          }
        />

        <Route path="/" element={<ConnectPage />} />
      </Routes>
      <StreamControllerWrapper
        initial={{ opacity: 0 }}
        animate={streamUrl ? { opacity: 1 } : { opacity: 0 }}
      >
        {streamComponent}
      </StreamControllerWrapper>
    </BrowserRouter>
  );
};

export default Router;
