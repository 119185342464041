import React, { useState, Fragment } from 'react';

// Helpers
import { getCurrentEnvironment } from 'helpers';
import { motion } from 'framer-motion';

// Styles
import styled from 'styled-components';

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  pointer-events: none;
`;

const ImageContent = (props) => {
  const { data, pageIndex } = props;

  const [hdLoaded, setHdLoaded] = useState(false);

  // Transform
  let transform = 'q=80:h=1200';
  const placeHolderUri = `${getCurrentEnvironment().baseImageUrl}/q=80:h=120/${
    data.contentUri
  }`;
  const imageUri = `${getCurrentEnvironment().baseImageUrl}/${transform}/${
    data.contentUri
  }`;

  const onImageLoaded = () => {
    setHdLoaded(true);
  };

  return (
    <ImageWrapper>
      {data.contentUri && (
        <Fragment>
          <StyledImage
            alt={`low_res page ${pageIndex}`}
            src={placeHolderUri}
            draggable={false}
          />
          <motion.div
            initial={'hidden'}
            animate={hdLoaded ? 'visible' : 'hidden'}
            variants={variants}
          >
            <StyledImage
              alt={`high_res page ${pageIndex}`}
              src={imageUri}
              onLoad={onImageLoaded}
              draggable={false}
            />
          </motion.div>
        </Fragment>
      )}
    </ImageWrapper>
  );
};

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

export default ImageContent;
