import React, { useState, useEffect, forwardRef } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import { RichTextRenderer } from '@prompto-ui';

// Helpers
import { createDefaultFiltersForUnits } from 'helpers/units/VmUnitHelper';
import { useShowUnitGeneralFields } from 'helpers/customHooks';
import { loadingStatus } from './PortfolioPage';
import currencySymbolMap from 'currency-symbol-map';
import { isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import { view } from './PortfolioPage';
import { getPriceToShowTypeLabel } from 'helpers';

// Styling
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  color: ${({ color }) => color};
`;

const UnitsData = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-bottom: ${isMobileOnly ? 10 : 25}px;
`;

const UnitsDataChip = styled.div`
  display: flex;
  margin: 0 5px 5px 0;
  align-items: center;
  min-height: 36px;
  padding: 8px 17px 8px 10px;
  box-sizing: border-box;
  border-radius: 18px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(
    ${({ color }) => (color === 'white' ? '255, 255, 255' : '125, 125, 125')},
    0.2
  );
  font-size: 0.875rem;
  span {
    margin: 0 0 0 10px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  ${({ origin, theme }) => {
    switch (origin) {
      case view.cards:
        return css`
          background-color: #f3f2f3;
          color: ${theme.primary300};
        `;
      default:
        return ``;
    }
  }}
`;

const UnitsDataChipIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.primary100};
  font-size: 1rem;
`;

const AdditionalInfo = styled.div`
  flex-shrink: 0;
  padding-left: 25px;
  position: relative;
  margin-bottom: ${isMobileOnly ? 15 : 30}px;
  font-size: 0.875rem;
  color: ${({ color, theme }) =>
    color === 'white' ? 'inherit' : theme.primary100};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const AdditionalInfoIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 0.875rem;
`;

const PriceRangeChip = styled.div`
  background-color: ${({ theme }) => theme.mainAccentFade};
  height: 40px;
  width: 100%;
  border-radius: 20px;
  padding: 0 30px;
  box-sizing: border-box;
  font-size: 0.875rem;
  justify-content: center;
  color: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 20px;
  ${isMobileOnly &&
  css`
    margin: 0;
    flex-grow: 1;
    justify-content: center;
  `}
  ${({ origin, theme }) => {
    switch (origin) {
      case view.cards:
        return css`
          background-color: ${theme.mainAccent};
        `;
      default:
        return ``;
    }
  }}
`;

const SalesData = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const SoldItemsData = styled.div`
  flex: 40% 1 1;
  display: flex;
  flex-flow: column;
`;

const SoldItemsMessage = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primary100};
  margin-bottom: 5px;
`;

const SoldItemsPercentage = styled.div`
  height: 4px;
  border-radius: 2px;
  backdrop-filter: blur(10px);
  background-color: rgba(
    ${({ cardColor }) =>
      cardColor === 'white' ? '255, 255, 255' : '125, 125, 125'},
    0.2
  );
  position: relative;
  &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ percent, visible }) => (visible ? percent : 0)}%;
    border-radius: 2px;
    background-color: ${({ color }) => color};
    transition: width 300ms ease-in-out 250ms;
  }
`;

const NoUnitsMessage = styled.div`
  color: ${({ theme }) => theme.primary200};
  text-transform: uppercase;
  margin-bottom: 30px;
`;

const getSoldItemsData = (soldItemsPercent, unitFlow) => {
  const isRentalFlow = unitFlow === 'rental';
  const info = isRentalFlow
    ? localizer.unitStateRented
    : localizer.unitStateSold;

  let data = {};
  switch (true) {
    case soldItemsPercent > 90:
      data = {
        color: 'red',
        text: `${soldItemsPercent}% is ${info}`
      };
      break;
    case soldItemsPercent > 70:
      data = {
        color: '#d16d3a',
        text: `${soldItemsPercent}% is ${info}. Hurry up!`
      };
      break;
    case soldItemsPercent > 30:
      data = {
        color: '#b6a36c',
        text: `${soldItemsPercent}% is ${info}`
      };
      break;
    default:
      data = {
        color: '#15928a',
        text: `${soldItemsPercent}% is ${info}. New!`
      };
  }

  return { ...data, percent: soldItemsPercent };
};

const genericDataKeys = [
  'unitTypes',
  'numberOfBedrooms',
  'surface',
  'numberOfBathrooms'
];
const genericDataIcons = {
  unitTypes: 'building',
  numberOfBedrooms: 'bed',
  surface: 'vector-square',
  numberOfBathrooms: 'bath'
};

const stringifyRange = ({ option, min, max, currency }) => {
  const noRange = min === max;
  if (noRange && !min && !max) return '';

  const formatted = (val) =>
    val.toLocaleString('en-EN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

  const fMax = formatted(Number(max));
  const fMin = formatted(Number(min));
  let string;
  switch (option) {
    case 'surface':
      string = noRange ? `${fMax} m²` : `${fMin} m² - ${fMax} m²`;
      break;
    case 'price':
      string = noRange
        ? `${currency} ${fMax}`
        : `${currency} ${fMin} - ${currency} ${fMax}`;
      break;
    default:
      string = noRange ? max : `${min} - ${max}`;
  }

  return string;
};

const ProjectUnitsInfo = forwardRef(
  (
    {
      color,
      unitsData,
      description,
      configuration,
      status,
      vaultSettings,
      visible,
      origin,
      shouldShowPrices
    },
    ref
  ) => {
    const [unitsGenericData, setUnitsGenericData] = useState([]);
    const [priceRange, setPriceRange] = useState('');
    const [soldItemsPercent, setSoldItemsPercent] = useState(0);
    const [projectHasUnits, setProjectHasUnits] = useState(false);
    const [currencyCode, setCurrencyCode] = useState('EUR');

    const { showPrice, showSurface, showBedrooms, showBathrooms } =
      useShowUnitGeneralFields(false, configuration, unitsData);

    useEffect(() => {
      setCurrencyCode(vaultSettings?.defaultCurrencyCode ?? 'EUR');
    }, [vaultSettings]);

    // calculate units data
    useEffect(() => {
      if (!Array.isArray(unitsData)) return;
      if (unitsData.length === 0) return;
      if (!configuration) return;

      setProjectHasUnits(true);

      // calculate percentage of sold units and group unit types across the project
      const unitTypes = [];
      let soldUnits = 0;
      unitsData.forEach((unit) => {
        if (unit.state === 'SOLD') soldUnits++;
        if (unit.unitType) {
          unitTypes.push(unit.unitType.title);
        }
      });
      setSoldItemsPercent(Math.round((soldUnits / unitsData.length) * 100));

      // calculate units generic data ranges
      const filters = createDefaultFiltersForUnits(unitsData);
      const ranges = filters.reduce((a, c) => {
        if (c.filterOption !== 'state') {
          return {
            ...a,
            [c.filterOption]: stringifyRange({
              option: c.filterOption,
              min: c.values.minToDisplay,
              max: c.values.maxToDisplay,
              currency: currencySymbolMap(currencyCode)
            })
          };
        } else {
          return a;
        }
      }, {});

      if (unitTypes.length > 0) {
        ranges.unitTypes = unitTypes
          .filter((v, i, a) => a.indexOf(v) === i)
          .join(', ');
      }

      const canShowGenericData = {
        unitTypes: true,
        numberOfBedrooms: showBedrooms,
        surface: showSurface,
        numberOfBathrooms: showBathrooms
      };

      if (unitsData[0].unitFlow === 'rental') {
        ranges.price += getPriceToShowTypeLabel(unitsData[0]?.priceToShowType);
      }
      setPriceRange(showPrice && shouldShowPrices ? ranges.price : '');
      setUnitsGenericData(
        genericDataKeys.map((key) => ({
          key,
          show: canShowGenericData[key],
          icon: genericDataIcons[key],
          value: ranges[key]
        }))
      );
    }, [
      unitsData,
      configuration,
      showBedrooms,
      showSurface,
      showBathrooms,
      showPrice,
      currencyCode,
      shouldShowPrices
    ]);

    if (status === loadingStatus.loading) {
      return (
        <div ref={ref}>
          <ShowcaseLoader color={'grey'} />
        </div>
      );
    }

    return (
      <Wrapper color={color} ref={ref}>
        {projectHasUnits ? (
          <UnitsData origin={origin}>
            {unitsGenericData.map((item) => {
              if (!item.show || !item.value) return null;
              return (
                <UnitsDataChip key={item.key} color={color} origin={origin}>
                  <UnitsDataChipIcon icon={['far', item.icon]} size="1x" />
                  <span>{item.value}</span>
                </UnitsDataChip>
              );
            })}
          </UnitsData>
        ) : (
          <NoUnitsMessage>{localizer.marketplace.noUnits}</NoUnitsMessage>
        )}
        {description && (
          <AdditionalInfo color={color} origin={origin}>
            <AdditionalInfoIcon icon={['far', 'info-circle']} size="1x" />
            <RichTextRenderer richText={description} defaultFormatting={true} />
          </AdditionalInfo>
        )}
        {projectHasUnits && (
          <>
            <SalesData>
              {priceRange && (
                <PriceRangeChip origin={origin}>{priceRange}</PriceRangeChip>
              )}
            </SalesData>
            {!isMobileOnly && (
              <SoldItemsData>
                <SoldItemsMessage>
                  {getSoldItemsData(soldItemsPercent, unitsData[0]?.unitFlow)
                    .text ?? ''}
                </SoldItemsMessage>
                <SoldItemsPercentage
                  cardColor={color}
                  visible={visible}
                  percent={soldItemsPercent}
                  color={
                    getSoldItemsData(soldItemsPercent, unitsData[0]?.unitFlow)
                      .color
                  }
                />
              </SoldItemsData>
            )}
          </>
        )}
      </Wrapper>
    );
  }
);

export default ProjectUnitsInfo;
