import React from 'react';

// Components
import RangeFilter from './RangeFilter';

// Helpers
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  overflow: hidden;
  ${({ styles }) => styles};
`;

const FilterUnitTitle = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.showcaseBlack};
  margin: 0 0 8px;
`;

const FilterOptions = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${({ styles }) => styles}
`;

const FilterChip = styled.div`
  height: 40px;
  padding: 10px 15px;
  margin: 0 10px 10px 0;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px
    ${({ theme, chosen }) => (chosen ? theme.brand500 : theme.gray200)};
  background-color: ${({ theme, chosen }) =>
    chosen ? 'rgba(88, 77, 171, 0.1)' : theme.showcaseWhite};
  font-size: 0.875rem;
  font-weight: ${({ chosen }) => (chosen ? '600' : '400')};
  color: ${({ theme, chosen }) =>
    chosen ? theme.brand500 : theme.showcaseBlack};
  cursor: pointer;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
  }
  ${({ styles }) => styles}
`;

const NumberFilterChip = styled(FilterChip)`
  white-space: nowrap;
  span {
    display: inline;
  }
`;

const OverflowOptionsText = styled.span`
  margin: 0 0 0 6px !important;
`;

const NoData = styled.div`
  margin: 8px 0;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.gray200};
`;

const FilterBlock = ({
  title,
  type,
  name,
  options,
  setOptions,
  rangeOptions,
  styles,
  displayMax,
  overflowText,
  hideTitle = false,
  chipStyles,
  optionsWrapperStyles,
  optionToLabelMap
}) => {
  const onPillFilterOptionClick = (option, isCombined) => {
    const optionsToToggle = isCombined
      ? options.all.filter((x) => x >= option)
      : [option];
    let newSelected = [...options.selected];
    optionsToToggle.forEach((option) => {
      if (newSelected.includes(option)) {
        newSelected = newSelected.filter((x) => x !== option);
      } else {
        newSelected = [...newSelected, option];
      }
    });
    setOptions(name, { ...options, selected: newSelected });
  };

  let limitedOptions = options?.all ?? [];
  if (displayMax && options) {
    limitedOptions = options.all.slice(0, displayMax);
  }

  const noData = options?.all?.length < 1;
  const noRangeData =
    rangeOptions?.limits?.min === Infinity ||
    rangeOptions?.limits?.max === -Infinity;

  let content;
  switch (type) {
    case 'range':
      content = noRangeData ? (
        <NoData>{localizer.marketplace.noData}</NoData>
      ) : (
        <RangeFilter
          filterKey={title}
          filterObject={rangeOptions}
          onUpdateValues={(values) => {
            setOptions(name, { ...rangeOptions, values });
          }}
        />
      );
      break;
    case 'drop':
    default:
      content = (
        <FilterOptions styles={optionsWrapperStyles}>
          {noData ? (
            <NoData>{localizer.marketplace.noData}</NoData>
          ) : (
            limitedOptions.map((option, i) => {
              const combinedOption = i >= displayMax - 1;

              return ['propertyType', 'projectStatus'].includes(name) ? (
                <FilterChip
                  key={option}
                  chosen={options.selected?.includes(option)}
                  onClick={() =>
                    onPillFilterOptionClick(option, combinedOption)
                  }
                  styles={chipStyles}
                >
                  <span>{optionToLabelMap?.[option] ?? option}</span>
                  {combinedOption && (
                    <OverflowOptionsText>{overflowText}</OverflowOptionsText>
                  )}
                </FilterChip>
              ) : (
                <NumberFilterChip
                  key={option}
                  chosen={options.selected?.includes(option)}
                  onClick={() =>
                    onPillFilterOptionClick(option, combinedOption)
                  }
                  styles={chipStyles}
                >
                  <span>{option}</span>
                  {combinedOption && (
                    <OverflowOptionsText>{overflowText}</OverflowOptionsText>
                  )}
                </NumberFilterChip>
              );
            })
          )}
        </FilterOptions>
      );
  }

  return (
    <Wrapper styles={styles}>
      {title && !hideTitle && <FilterUnitTitle>{title}</FilterUnitTitle>}
      {content}
    </Wrapper>
  );
};

export default FilterBlock;
