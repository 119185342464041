import React from 'react';
import { array } from 'prop-types';

// Helpers
import { Link } from 'react-router-dom';
import { useProjectState } from 'stores/ProjectStore';
import { isAndroid } from 'react-device-detect';

// Styling
import styled from 'styled-components';

// Components
import DocumentPreview from '../../resources/contentItems/types/document/DocumentPreview';

const GalleryWrapper = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const GalleryItemWrapper = styled.div`
  height: 170px;
  width: calc(50% - 10px);
  margin: 5px;
`;

const Documents = ({ items }) => {
  const { ProjectState } = useProjectState();
  const { shareCode } = ProjectState;

  if (items.length === 0) return null;

  return (
    <GalleryWrapper>
      {items.map((data, idx) => (
        <GalleryItemWrapper key={data.objectId} index={idx}>
          {isAndroid ? (
            <Link
              target="_blank"
              to={{
                pathname: `/${shareCode}/document_viewer/${encodeURIComponent(
                  data.objectId
                )}/${encodeURIComponent(data.contentUri)}`,
                search: window.location.search
              }}
            >
              <DocumentPreview data={data} />
            </Link>
          ) : (
            <a href={data.contentUri} target="_blank" rel="noopener noreferrer">
              <DocumentPreview data={data} />
            </a>
          )}
        </GalleryItemWrapper>
      ))}
    </GalleryWrapper>
  );
};

Documents.propTypes = {
  items: array
};

Documents.defaultProps = {
  items: []
};

export default Documents;
