import React, { useState, useEffect } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import view from 'configs/MarketplaceViews.json';
import { capitalize } from 'helpers';
import localizer from 'localization/localizer';
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Container = styled(motion.div)`
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: ${({ theme }) => theme.showcaseWhite};
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.gray200};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const ViewOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width}%;
  font-size: 0.875rem;
  color: ${({ selected, theme }) =>
    selected ? theme.primary300 : theme.primary100};
  transition: all 200ms ease;
  span {
    margin: 4px 0 0;
  }
`;

const TabIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
`;

const views = [
  { key: view.map, icon: 'map' },
  {
    key: view.gallery,
    icon: 'clone'
  },
  {
    key: view.cards,
    icon: 'grip-horizontal'
  }
];

const MobileNavBar = ({ selectedView, setView, allowedViews, ...props }) => {
  const [availableViews, setAvailableViews] = useState(views);

  useEffect(() => {
    if (!allowedViews) return;
    setAvailableViews(views.filter((v) => allowedViews.includes(v.key)));
  }, [allowedViews]);

  const width = 100 / availableViews.length;

  return (
    <Container {...props}>
      {availableViews.map((v, i) => {
        return (
          <ViewOption
            key={v.key}
            width={width}
            onClick={() => setView(v.key)}
            selected={v.key === selectedView}
          >
            <TabIcon icon={['fal', v.icon]} />
            <span>{capitalize(localizer[v.key])}</span>
          </ViewOption>
        );
      })}
    </Container>
  );
};

export default MobileNavBar;
