import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  forwardRef
} from 'react';
import PropTypes from 'prop-types';

// Components
import UnitStateBadge from 'components/unit/UnitStateBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactUs from 'components/share/ContactUs/ContactUs';

// Helpers
import localizer from 'localization/localizer';
import {
  capitalize,
  getFieldIcon,
  displayLocalizedValue,
  getUnitPrice
} from 'helpers';
import { downloadBinaryWithProvidedFileName } from '@prompto-helpers';
import currencySymbolMap from 'currency-symbol-map';
import { useProjectState } from 'stores/ProjectStore';
import { unitStatesNotArchived } from 'helpers/units/VmUnitHelper';
import { useShowUnitGeneralFields } from 'helpers/customHooks';
import { useGetUnitCustomFields } from 'helpers/customHooks';

// Styling
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

const cardMarginTopBottom = 20;
const cardWidth = 360;
const collapsedCardWidth = 170;

const InfoCard = styled.div`
  // To make it non-static and to be able-to position elements inside it in an absolute way
  position: relative;

  width: ${(props) =>
    props.collapsed ? `${collapsedCardWidth}px` : `${cardWidth}px`};
  padding: 40px 0 0;
  margin: ${cardMarginTopBottom}px 0;
  border-radius: 2px;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.whitePure};
  max-height: calc(100% - 20px);
  transition: all 200ms ease;
`;

const UnitMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 15px;
`;

const UnitFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.gray200};
  padding: 0 10px 10px;
  box-sizing: border-box;
`;

const UnitStateBadgeFloatingTop = styled(UnitStateBadge)`
  position: absolute;
  top: -20px;
  left: 10px;
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
`;

const UnitTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
  margin-bottom: 12px;
  word-break: break-word;
`;

const Price = styled.div`
  font-weight: 700;
  padding-left: 5px;
  color: inherit;
  white-space: nowrap;
`;

const UnitPrice = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 10px;
  color: ${(props) => props.theme.primary100};
`;

const UnitTypeAndPrice = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.showcaseWhite};
`;

const UnitType = styled.div`
  min-width: 50%;
  font-size: 14px;
  color: ${(props) => props.theme.showcaseBlack};
  display: flex;
  align-items: center;
  word-break: break-word;
  padding-right: 10px;
  box-sizing: border-box;
`;

const StyledUnitTypeIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 10px;
  color: ${(props) => props.theme.primary100};
`;

const CustomFieldWrapper = styled.div`
  padding: 10px;
  background-color: ${({ index, theme }) =>
    index % 2 ? theme.grayWhiteOff : theme.showcaseWhite};
  word-break: break-word;
  padding-left: ${({ hasIcon }) => (hasIcon ? '10px' : '35px')};
`;

const StyledCustomFieldIcon = styled(StyledUnitTypeIcon)`
  font-size: 16px;
  margin: 0;
`;

const CustomFieldTitle = styled.span`
  margin: 0 10px 0 0;
  color: ${(props) => props.theme.primary100};
  font-size: 14px;
`;

const CustomFieldValue = styled(CustomFieldTitle)`
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
  line-height: 1.43;
`;

const CustomFieldValueParagraph = styled.p`
  font-size: 0.875rem;
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
  text-align: justify;
`;

const IconWrapper = styled.span`
  width: 20px;
  margin-right: 5px;
  display: inline-block;
`;

const UnitButtons = styled(motion.div)`
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray200};
  background-color: ${(props) => props.theme.showcaseWhite};
`;

const ButtonsGap = styled.div`
  height: 10px;
`;

const DownloadPlansButton = styled.button`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.defaultBrandSecondaryColor};
  background-color: ${(props) => props.theme.showcaseWhite};
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

const CollapseButtonWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.grayWhiteOff};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledCollapseIcon = styled(StyledUnitTypeIcon)`
  margin: 0;
`;

const UnitInfoWrapper = styled.div`
  overflow: auto;
  height: ${(props) => `calc(100% - ${props.heightDifference}px)`};
`;

const UnitInfoCard = forwardRef(
  (
    {
      unit,
      currencyCode,
      showUnitPrice,
      showUnitState,
      showUnitDescription,
      isCollapsedView,
      setCollapsedView,
      floorPlanFile,
      setShouldRecalculateSidebarHeight
    },
    ref
  ) => {
    const [unitInfoHeightDifference, setUnitInfoHeightDifference] = useState(0);
    const [unitFields, setUnitFields] = useState([]);
    const [readablePrice, setReadablePrice] = useState('');

    const { showPrice, showSurface, showBedrooms, showBathrooms } =
      useShowUnitGeneralFields(false, null, [unit]);

    // Project State
    const { ProjectState } = useProjectState();
    const { showcaseConfiguration } = ProjectState;

    const buttonsRef = useRef();

    useEffect(() => {
      if (isCollapsedView) {
        setUnitInfoHeightDifference(0);
      } else if (buttonsRef?.current) {
        setUnitInfoHeightDifference(buttonsRef.current.offsetHeight);
      }
    }, [isCollapsedView]);

    useEffect(() => {
      let priceText = capitalize(localizer.onRequest);
      if (showUnitPrice && showPrice) {
        priceText = `${currencySymbolMap(currencyCode)} ${getUnitPrice(unit)}`;
      }
      setReadablePrice(priceText);
    }, [showUnitPrice, showPrice, unit, currencyCode]);

    const { customFields } = useGetUnitCustomFields(unit, showUnitDescription);

    useEffect(() => {
      if (customFields) {
        setUnitFields(customFields);
        setShouldRecalculateSidebarHeight(true);
      }
    }, [customFields, setShouldRecalculateSidebarHeight]);

    const renderUnitFields = useCallback(
      (showcaseConfiguration) => {
        const fields = [];

        if (showSurface && unit?.unitMetadata?.surface !== undefined) {
          fields.push({
            icon: getFieldIcon(showcaseConfiguration, 'surface'),
            title: capitalize(localizer.surface),
            value: `${unit.unitMetadata.surface} m²`
          });
        }
        if (
          showBedrooms &&
          unit?.unitMetadata?.numberOfBedrooms !== undefined
        ) {
          fields.push({
            icon: getFieldIcon(showcaseConfiguration, 'numberOfBedrooms'),
            title: capitalize(localizer.bedrooms),
            value: unit.unitMetadata.numberOfBedrooms
          });
        }
        if (
          showBathrooms &&
          unit?.unitMetadata?.numberOfBathrooms !== undefined
        ) {
          fields.push({
            icon: getFieldIcon(showcaseConfiguration, 'numberOfBathrooms'),
            title: capitalize(localizer.bathrooms),
            value: unit.unitMetadata.numberOfBathrooms
          });
        }

        fields.push(...unitFields);

        if (fields?.length === 0) return null;

        const fieldsHaveIcons = fields.some((field) => !!field.icon);

        return (
          <UnitFieldsWrapper>
            {fields?.map((field, idx) => (
              <CustomFieldWrapper
                index={idx}
                key={idx}
                hasIcon={fieldsHaveIcons ? !!field.icon : true}
              >
                {!!field.icon && (
                  <IconWrapper>
                    <StyledCustomFieldIcon
                      icon={['far', field.icon]}
                      size="1x"
                    />
                  </IconWrapper>
                )}
                {field.title && (
                  <CustomFieldTitle>{capitalize(field.title)}</CustomFieldTitle>
                )}
                {field.showAsParagraph ? (
                  <CustomFieldValueParagraph>
                    {field.value}
                  </CustomFieldValueParagraph>
                ) : (
                  <CustomFieldValue>{field.value}</CustomFieldValue>
                )}
              </CustomFieldWrapper>
            ))}
          </UnitFieldsWrapper>
        );
      },
      [unitFields, unit, showSurface, showBedrooms, showBathrooms]
    );

    return (
      <InfoCard collapsed={isCollapsedView} ref={ref}>
        {showUnitState && (
          <UnitStateBadgeFloatingTop
            unitState={unit?.state}
            unitFlow={unit?.unitFlow}
          />
        )}
        <CollapseButtonWrapper
          onClick={() => setCollapsedView(!isCollapsedView)}
        >
          <StyledCollapseIcon
            icon={['far', isCollapsedView ? 'plus' : 'minus']}
            size="1x"
          />
        </CollapseButtonWrapper>
        <UnitInfoWrapper heightDifference={unitInfoHeightDifference}>
          <UnitMainInfo>
            <UnitTitle>{capitalize(unit?.title)}</UnitTitle>
            <UnitTypeAndPrice>
              {unit?.unitType && (
                <UnitType>
                  {unit?.unitType?.icon && (
                    <StyledUnitTypeIcon
                      icon={['far', unit.unitType.icon]}
                      size="1x"
                    />
                  )}
                  {displayLocalizedValue(unit?.unitType?.name?.textMap)}
                </UnitType>
              )}
              {!isCollapsedView && showPrice && (
                <UnitPrice>
                  <StyledPriceIcon
                    icon={getFieldIcon(showcaseConfiguration, 'price', 'far')}
                    size="1x"
                  />
                  <Price>{readablePrice}</Price>
                </UnitPrice>
              )}
            </UnitTypeAndPrice>
          </UnitMainInfo>

          {!isCollapsedView && renderUnitFields(showcaseConfiguration)}
        </UnitInfoWrapper>

        <AnimatePresence>
          {!isCollapsedView &&
            (showcaseConfiguration?.showUnitContactUsButton ||
              (showcaseConfiguration?.showUnitDownloadPlanButton &&
                floorPlanFile)) && (
              <UnitButtons
                ref={buttonsRef}
                initial={{ opacity: 0, scale: 0, transformOrigin: 'top left' }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ duration: 0.15, ease: 'linear' }}
              >
                {showcaseConfiguration?.showUnitContactUsButton && (
                  <ContactUs
                    unit={unit}
                    location="Unit details page (desktop)"
                  />
                )}
                {showcaseConfiguration?.showUnitContactUsButton &&
                  showcaseConfiguration?.showUnitDownloadPlanButton &&
                  floorPlanFile && <ButtonsGap />}
                {showcaseConfiguration?.showUnitDownloadPlanButton &&
                  floorPlanFile && (
                    <DownloadPlansButton
                      onClick={() => {
                        downloadBinaryWithProvidedFileName(
                          floorPlanFile.contentUri,
                          floorPlanFile.title.textMap.en
                        );
                      }}
                    >
                      {localizer.downloadPlan}
                    </DownloadPlansButton>
                  )}
              </UnitButtons>
            )}
        </AnimatePresence>
      </InfoCard>
    );
  }
);

UnitInfoCard.propTypes = {
  unit: PropTypes.shape({
    title: PropTypes.string.isRequired,
    state: PropTypes.oneOf(unitStatesNotArchived).isRequired,
    unitMetadata: PropTypes.shape({
      surface: PropTypes.number,
      numberOfBedrooms: PropTypes.number,
      numberOfBathrooms: PropTypes.number
    }),
    unitPrice: PropTypes.shape({
      price: PropTypes.number,
      currencyCode: PropTypes.string
    })
  }),
  /** Show the unit state or not. Is true by default. */
  showUnitState: PropTypes.bool,
  /** Show the unit price or not. It is true by default. */
  showUnitPrice: PropTypes.bool
};

UnitInfoCard.defaultProps = {
  showUnitState: true,
  showUnitPrice: true
};

export default UnitInfoCard;
