import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

// Helpers
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';
import { isDarkColor } from 'helpers';

// Styling
import styled, { keyframes, css } from 'styled-components';

const pulsing = (theme) => keyframes`
to {
  border-radius: 4px;
  box-shadow: 0 0 0 30px ${theme.defaultBrandPrimaryColor};
  opacity: 0;
}
`;

const pulse = keyframes`
  0% {
    transform: scaleX(1) scaleY(1);
    opacity: 1;
  }
  50% {
    transform: scaleX(1.5) scaleY(1.6);
    opacity: 0;
  }
  100% {
    transform: scaleX(1.5) scaleY(1.6);
    opacity: 0;
  }
`;

const slidingFrontSunbeam = keyframes`
    0% { left: -80% }
    40% { left: 80% }
    100% { left: 80% }
`;

const slidingBackSunbeam = keyframes`
    0% { left: -80% }
    40% { left: -80% }
    70% { left: 20% }
    75% { left: 25% }
    90% { left: -80% }
    100% { left: -80% }
`;

const slidingFrontGlare = keyframes`
    0% { left: -30%; opacity: 0.3; width: 60px }
    50% { left: 130%; opacity: 0; width: 30px }
    100% { left: 130%; opacity: 0; width: 30px }
`;

const slidingBackGlare = keyframes`
    0% { left: -30%; opacity: 0.2; width: 30px }
    50% { left: -30%; opacity: 0.2; width: 30px }
    100% { left: 130%; opacity: 0.05; width: 5px }
`;

const pointingArrow = keyframes`
  0% { left: 10px }
  12% { left: 18px }
  24% { left: 6px }
  36% { left: 14px }
  48% { left: 10px }
  100% { left: 10px }
`;

const BaseButton = styled(motion.button)`
  position: relative;
  margin-top: ${isMobile ? '30px' : '40px'};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  max-width: ${isMobile ? '90vw' : '80vw'};
  padding: 10px 24px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) =>
    isDarkColor(theme.defaultBrandPrimaryColor)
      ? theme.showcaseWhite
      : theme.showcaseBlack};
  background-color: ${({ theme }) => theme.defaultBrandPrimaryColor};
  cursor: pointer;
  word-break: break-word;
`;

const ArrowButton = styled(BaseButton)`
  #arrow {
    position: relative;
    animation: ${pointingArrow} 1.6s infinite
      cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const SunbeamButton = styled(BaseButton)`
  overflow: hidden;
  ${({ showAnimations }) => {
    if (showAnimations) {
      const shared = `
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 80%;
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.25) 30%,
          rgba(255, 255, 255, 0.37) 54%,
          rgba(255, 255, 255, 0.25) 70%,
          rgba(255, 255, 255, 0)
        );
        transform: skew(-8deg);
        `;
      return css`
        &:before {
          ${shared}
          animation: ${slidingFrontSunbeam} 8s infinite ease-out;
        }
        &:after {
          ${shared}
          animation: ${slidingBackSunbeam} 8s infinite linear;
        }
      `;
    }
  }}
`;

const GlareButton = styled(BaseButton)`
  overflow: hidden;
  ${({ showAnimations, theme }) => {
    if (showAnimations) {
      const shared = `
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        transform: skew(-20deg);
        background-color: ${theme.showcaseWhite};
        `;
      return css`
        &:before {
          ${shared}
          animation: ${slidingFrontGlare} 4s infinite ease-out;
        }
        &:after {
          ${shared}
          animation: ${slidingBackGlare} 4s infinite ease-in;
        }
      `;
    }
  }}
`;

const FlareButton = styled(BaseButton)`
  ${({ showAnimations, theme }) => {
    if (showAnimations) {
      return css`
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 1px;
          opacity: 1;
          box-shadow: 0 0 0 0 ${theme.defaultBrandPrimaryColor};
          background-color: ${theme.defaultBrandPrimaryColor};
          animation: ${pulsing(theme)} 2s infinite
            cubic-bezier(0.18, 0.49, 0.8, 0.68);
          z-index: -1;
        }
      `;
    }
  }}
`;

const DefaultButton = styled(BaseButton)`
  ${({ showAnimations, theme }) => {
    if (showAnimations) {
      const shared = `
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.defaultBrandPrimaryColor};
        z-index: -1;
      `;
      return css`
        &:before {
          ${shared}
          animation: ${pulse} 6s linear infinite;
          animation-delay: 2s;
        }
        &:after {
          ${shared}
          animation: ${pulse} 6s linear infinite;
          animation-delay: 4s;
        }
        div {
          ${shared}
          animation: ${pulse} 6s linear infinite;
          animation-delay: 6s;
        }
      `;
    }
  }}
`;

const ButtonText = styled(motion.p)`
  margin: 0;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 600;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const ArrowIconWrapper = styled.div``;

const animation = {
  arrow: 'arrow',
  glare: 'glare',
  sunbeam: 'sunbeam',
  flare: 'flare'
};

function getLocalizedValue(showcaseConfiguration, valueToLocalize) {
  const localizedValue =
    showcaseConfiguration?.startScreenSettings?.[valueToLocalize]?.textMap[
      localizer.getLanguage()
    ];
  if (localizedValue?.length > 0) {
    return localizedValue;
  } else {
    return null;
  }
}

const CallToActionButton = ({ showAnimations, showcaseConfiguration }) => {
  const chosenAnimation =
    showcaseConfiguration?.startScreenSettings
      ?.startScreenButtonAnimationName ?? animation.sunbeam;

  const sharedButtonProps = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
    showAnimations
  };

  const text = (
    <ButtonText>
      {getLocalizedValue(showcaseConfiguration, 'startScreenButtonText') ??
        localizer.exploreInteractiveShowcase}
    </ButtonText>
  );
  const compassIcon = <Icon icon={['far', 'compass']} size="1x" />;
  const arrowIcon = <Icon icon={['far', 'arrow-right']} size="1x" />;

  let button;
  switch (chosenAnimation) {
    case animation.arrow:
      button = (
        <ArrowButton key={animation.arrow} {...sharedButtonProps}>
          {text}
          <ArrowIconWrapper id={animation.arrow}>{arrowIcon}</ArrowIconWrapper>
        </ArrowButton>
      );
      break;
    case animation.glare:
      button = (
        <GlareButton key={animation.glare} {...sharedButtonProps}>
          {compassIcon}
          {text}
        </GlareButton>
      );
      break;
    case animation.sunbeam:
      button = (
        <SunbeamButton key={animation.sunbeam} {...sharedButtonProps}>
          {compassIcon}
          {text}
        </SunbeamButton>
      );
      break;
    case animation.flare:
      button = (
        <FlareButton {...sharedButtonProps}>
          {compassIcon}
          {text}
        </FlareButton>
      );
      break;
    default:
      button = (
        <DefaultButton {...sharedButtonProps}>
          <div></div>
          {compassIcon}
          {text}
        </DefaultButton>
      );
  }

  return button;
};

export default CallToActionButton;
