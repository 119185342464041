import {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
  memo
} from 'react';
import { bool, shape, string } from 'prop-types';

// Components
import { PlayThinIcon } from 'resources/icons/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Cloudinary
import { Video, Transformation } from 'cloudinary-react';

// Helpers
import {
  getCurrentEnvironment,
  computeCloudinaryId,
  computeCloudinaryThumbnail,
  isMobileDevice,
  isCloudinaryTransformationAvailable
} from 'helpers';
import localizer from 'localization/localizer';

// Styling
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const VideoContent = memo(
  ({ autoplay, data, showGrid, fallbackThumbnail, transitioning }) => {
    const [isVideoActive, setIsVideoActive] = useState(false);
    const [interacted, setInteracted] = useState(false);
    const [transformationAvailable, setTransformationAvailable] =
      useState(false);
    const [overlayActive, setOverlayActive] = useState(false);

    const player = useRef();

    useEffect(() => {
      setTimeout(() => setOverlayActive(false), 250);
    }, []);

    const currentEnvironment = getCurrentEnvironment();

    const [cloudinaryImageUri] = useState(
      computeCloudinaryThumbnail(
        decodeURIComponent(data.contentUri),
        currentEnvironment.googleStorageBucketId,
        currentEnvironment.cloudinaryVideoBaseUrl
      )
    );

    const [cloudinaryId] = useState(
      computeCloudinaryId(
        decodeURIComponent(data.contentUri),
        currentEnvironment.googleStorageBucketId
      )
    );

    const wrapIsCloudinaryTransformationAvailable = useCallback(
      isCloudinaryTransformationAvailable,
      []
    );

    // Pause the video if current page is transitioning out
    useEffect(() => {
      if (transitioning && player.current) {
        player.current.pause();
      }
    }, [transitioning]);

    useEffect(() => {
      if (isVideoActive) {
        if (!interacted) {
          setInteracted(true);
        }
      }
    }, [isVideoActive, data.objectId, interacted]);

    useEffect(() => {
      if (autoplay) {
        setIsVideoActive(true);
      }
    }, [autoplay]);

    useEffect(() => {
      if (!isVideoActive) {
        return;
      }

      if (showGrid) {
        setIsVideoActive(false);
      }
    }, [showGrid, isVideoActive]);

    //check if transformation is available async
    useEffect(() => {
      const effectHeight = isMobileDevice() ? 720 : 1080;
      wrapIsCloudinaryTransformationAvailable(
        setTransformationAvailable,
        decodeURIComponent(data.contentUri),
        currentEnvironment.googleStorageBucketId,
        currentEnvironment.cloudinaryVideoBaseUrl,
        `c_limit,h_${effectHeight}`,
        'mp4'
      );
    }, [
      wrapIsCloudinaryTransformationAvailable,
      data.contentUri,
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryVideoBaseUrl
    ]);

    //add handlers for the video player to know when video is active
    let handlers = {
      onPlay: () => {
        setIsVideoActive(true);
      }
    };

    let thumbnailStyle = css`
      height: 100%;
      width: 100%;
      object-fit: contain;
    `;

    if (!cloudinaryId) {
      thumbnailStyle = css`
        ${thumbnailStyle};
        height: 100%;
        width: 100%;
        filter: blur(10px);
        -moz-filter: blur(10px);
        transform: scale(1.1);
      `;
    }

    let thumbnailOrVideo = (
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        `}
        onClick={() => {
          if (cloudinaryId) {
            setIsVideoActive(true);
            setInteracted(true);
          }
        }}
      >
        {cloudinaryId ? (
          <PlayThinIcon
            css={css`
              position: absolute;
              border-radius: 50%;
              background-color: rgba(0, 0, 0, 0.4);
              z-index: 1;
            `}
          />
        ) : (
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.7);
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 5;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
              `}
            >
              <FontAwesomeIcon icon={['fal', 'info-circle']} size="2x" />
              <p
                css={css`
                  font-size: 16px;
                  font-weight: 500;
                  margin: 5px 0;
                `}
              >
                {localizer.videoUnavailable}
              </p>
              <p
                css={css`
                  font-size: 12px;
                  margin: 0;
                `}
              >
                {localizer.videoFetchError}
              </p>
            </div>
          </div>
        )}
        <img
          css={thumbnailStyle}
          src={cloudinaryId ? cloudinaryImageUri : fallbackThumbnail}
          alt="thumbnail"
        />
      </div>
    );

    //determine max width/height based on mobile: sd (720) on mobile or hd otherwise (1280)
    let maxHeight = isMobileDevice() ? 720 : 1080;
    let transformation = transformationAvailable && (
      <Transformation height={maxHeight} crop="limit" />
    );

    if (isVideoActive) {
      thumbnailOrVideo = (
        <Fragment>
          {overlayActive && (
            <div
              css={css`
                ${thumbnailStyle};
                height: 100%;
                width: 100%;
                background-color: black;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 10000;
              `}
            />
          )}
          <Video
            {...handlers}
            innerRef={player}
            cloudName={currentEnvironment.cloudinaryCloudName}
            publicId={cloudinaryId}
            sourceTypes={['mp4', 'ogv']}
            autoPlay={true}
            controls={true}
            width="100%"
            height="100%"
            disablePictureInPicture
            controlsList="nodownload"
          >
            {transformation}
          </Video>
        </Fragment>
      );
    }

    return (
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
          color: white;
          background-color: black;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {thumbnailOrVideo}
      </div>
    );
  }
);

VideoContent.propTypes = {
  autoplay: bool,
  data: shape({}),
  fallbackThumbnail: string
};

VideoContent.defaultProps = {
  autoplay: false,
  data: {},
  fallbackThumbnail: null
};

export default VideoContent;
