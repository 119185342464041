import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
  useCallback
} from 'react';
import { array, string } from 'prop-types';

// Styling
import styled from 'styled-components';

// Helpers
import categories from 'configs/SupportedFileCategories.json';
import contentItemStates from 'configs/ContentItemStates.json';

// Components
import DocumentsPreview from './mobilePreviews/Documents';
import ThreeDPreview from './mobilePreviews/ThreeD';
import MediaPreview from './mobilePreviews/Media';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray200};
`;

const TabsWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
`;

const Tab = styled.li`
  flex: 33% 1 1;
  height: 40px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme, active }) =>
    active ? theme.showcaseBlack : theme.primary100};
  background-color: ${({ theme, active }) =>
    active ? theme.showcaseWhite : 'none'};
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.showcaseWhite};
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.gray200};
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
`;

const INITIAL_TABS = [
  { label: 'media', value: 'media' },
  { label: '3D view', value: 'threeD' },
  { label: 'documents', value: 'documents' }
];

const ContentGroupContainer = forwardRef(
  ({ children, setActiveTab, id, parentRef }, ref) => {
    const parentHeight = parentRef.current?.offsetHeight;
    const containerHeight = ref.current?.offsetHeight;
    useEffect(() => {
      if (parentHeight && containerHeight) {
        // To highlight respective tab the content container should take all parent's height (parentHeight < containerHeight)
        // or be fully visible inside the parent (parentHeight > containerHeight)
        const threshold =
          parentHeight > containerHeight ? 1 : parentHeight / containerHeight;
        const observerConfig = {
          root: parentRef.current,
          rootMargin: '0px',
          threshold: threshold - 0.1
        };
        const handleIntersection = function (entries) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveTab(entry.target.id);
            }
          });
        };
        const observer = new IntersectionObserver(
          handleIntersection,
          observerConfig
        );
        observer.observe(ref.current);

        return () => observer.disconnect();
      }
    }, [setActiveTab, ref, parentRef, parentHeight, containerHeight]);

    return (
      <ContentGroupWrapper ref={ref} id={id}>
        {children}
      </ContentGroupWrapper>
    );
  }
);

const UnitDetailMobileContent = ({
  content,
  externalExperienceUrl,
  fallbackThumbnail,
  album360Url,
  sceneContentId
}) => {
  const [tabsContent, setTabsContent] = useState(null);
  const [activeTab, setActiveTab] = useState('');
  const [tabs, setTabs] = useState([]);

  const contentWrapperRef = useRef(null);

  const INITIAL_CONTENT_GROUPS = [
    {
      ref: useRef(null),
      id: 'media',
      content: <MediaPreview items={tabsContent?.media ?? []} />
    },
    {
      ref: useRef(null),
      id: 'threeD',
      content: (
        <ThreeDPreview
          items={tabsContent?.threeD ?? []}
          fallbackThumbnail={fallbackThumbnail}
        />
      )
    },
    {
      ref: useRef(null),
      id: 'documents',
      content: <DocumentsPreview items={tabsContent?.documents ?? []} />
    }
  ];

  const handleTabClick = (tabValue) => {
    const refs = INITIAL_CONTENT_GROUPS.filter(
      ({ id }) => tabsContent[id].length
    ).reduce((refsObj, group) => {
      refsObj[group.id] = group.ref;
      return refsObj;
    }, {});
    refs[tabValue].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const createTabsContent = useCallback(
    (list) => {
      const mediaList = [];
      const threeDList = [];
      const documentsList = [];

      const isMedia = (type) =>
        [
          categories.video,
          categories.image,
          categories.gif,
          categories.showcase,
          categories.image360
        ].includes(type);

      const is3D = (type) =>
        [categories.album360, categories.url].includes(type);

      const isDocument = (type) =>
        [categories.document, categories.floorplan].includes(type);

      list
        .filter(
          (page) =>
            page.contentItemState === contentItemStates.published &&
            page.contentUri
        )
        .map((item) => ({
          ...item,
          name: item.title.textMap.en || item.contentItemType || 'item'
        }))
        .filter(Boolean)
        .forEach((page) => {
          if (isMedia(page.contentItemType)) mediaList.push(page);
          if (is3D(page.contentItemType)) threeDList.push(page);
          if (isDocument(page.contentItemType)) documentsList.push(page);
        });

      // externalExperienceUrl property is deprecated
      // we should use it only if there are no 'url' content items
      const urlContentItems = content.filter(
        (item) => item.contentItemType === 'url'
      );
      if (urlContentItems.length === 0 && externalExperienceUrl) {
        threeDList.push({
          objectId: 'externalExperience',
          contentItemType: 'externalExperience',
          contentUri: externalExperienceUrl
        });
      }

      if (album360Url) {
        threeDList.push({
          objectId: '360',
          contentItemType: 'tour360',
          contentUri: album360Url
        });
      }

      if (sceneContentId) {
        threeDList.push({
          objectId: 'scene',
          contentItemType: 'scene',
          id: sceneContentId
        });
      }

      return { media: mediaList, threeD: threeDList, documents: documentsList };
    },
    [externalExperienceUrl, album360Url, sceneContentId, content]
  );

  useEffect(() => {
    if (!content) return;
    const filteredContentItemList = content.filter(
      (item) => item.contentItemState === contentItemStates.published
    );
    setTabsContent(createTabsContent(filteredContentItemList));
  }, [content, createTabsContent]);

  useEffect(() => {
    if (tabsContent) {
      setTabs(INITIAL_TABS.filter(({ value }) => tabsContent[value].length));
    }
  }, [tabsContent]);

  useEffect(() => {
    tabs.length && setActiveTab(tabs[0].label);
  }, [tabs]);

  return (
    <Wrapper>
      <TabsWrapper>
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            onClick={() => handleTabClick(tab.value)}
            active={tab.value === activeTab}
          >
            {tab.label.toUpperCase()}
          </Tab>
        ))}
      </TabsWrapper>
      <ContentWrapper ref={contentWrapperRef}>
        {tabsContent &&
          INITIAL_CONTENT_GROUPS.filter(({ id }) => tabsContent[id].length).map(
            (group) => (
              <ContentGroupContainer
                key={group.id}
                ref={group.ref}
                parentRef={contentWrapperRef}
                title={group.title}
                id={group.id}
                setActiveTab={setActiveTab}
              >
                {group.content}
              </ContentGroupContainer>
            )
          )}
      </ContentWrapper>
    </Wrapper>
  );
};

UnitDetailMobileContent.propTypes = {
  content: array,
  externalExperienceUrl: string,
  fallbackThumbnail: string,
  album360Code: string,
  sceneContentId: string
};

UnitDetailMobileContent.defaultProps = {
  content: []
};

export default UnitDetailMobileContent;
