import React, { useState, useEffect, useCallback } from 'react';
import { number, func, string } from 'prop-types';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import localizer from 'localization/localizer';
import {
  createDefaultFiltersForUnits,
  unitStatesNotArchived,
  shouldHidePriceWhenShared,
  getColorForUnitState
} from 'helpers/units/VmUnitHelper';
import { capitalize } from 'helpers';
import { useShowUnitGeneralFields } from 'helpers/customHooks';

// Components
import SearchField from 'components/other/SearchField';
import FilterToggleButtons from '../pages/tour/tourComponents/searchAndFilter/FilterToggleButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterSlider from '../pages/tour/tourComponents/searchAndFilter/FilterSlider';

// Styling
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const VerticalWrapper = styled(motion.div)`
  position: absolute;
  width: calc(100% - 30px);
  max-width: 330px;
  height: calc(100% - 30px);
  max-height: 610px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 5px;
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px 0;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 0;
`;

const StyledSearchField = styled(SearchField)`
  margin: 0 0 15px;
`;

const StyledSlider = styled(FilterSlider)`
  margin-bottom: 10px;
`;

const FilterSection = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
`;

const InformationSection = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray100};
`;

const UnitsInFilterIndicator = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-flow: row;
  color: ${(props) => props.theme.showcaseBlack};
`;

const IndicatorBold = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const ResetFiltersButton = styled.div`
  padding: 15px 0;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => props.theme.errorColor};
  user-select: none;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  font-size: 1.5rem;
  color: ${(props) => props.theme.showcaseBlack};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
`;

const CloseButton = styled.button`
  position: absolute;
  right: -5px;
  top: 0;
  background: none;
  outline: none;
  border: none;
  width: fit-content;
  cursor: pointer;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: ${(props) => props.theme.primary100};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  background-color: ${(props) => props.theme.gray100};
`;

const ApplyFiltersButton = styled.button`
  width: 100%;
  height: 70px;
  flex-shrink: 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${(props) => props.theme.showcaseWhite};
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray200};
  font-weight: 500;
  color: ${(props) => props.theme.primary400};
  text-transform: uppercase;
`;

// const ToggleButton = styled.button`
//   border: none;
//   outline: none;
//   font-size: 0.625rem;
//   padding: 10px 15px;
//   background: ${(props) =>
//     props.toggled ? props.theme.primary900 : props.theme.gray100};
//   color: ${(props) =>
//     props.toggled ? props.theme.whitePure : props.theme.primary200};
//   cursor: pointer;
//   margin: 0 1px;
// `;

// const ToggleBuildingButton = styled(ToggleButton)`
//   margin: 0 10px 10px 0;
//   flex-grow: 1;
//   span {
//     word-break: break-word;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//   }
// `;

const MobileToggleButton = styled.button`
  height: 30px;
  flex: 30% 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0 2px;
  background-color: ${({ theme, unitState, toggled }) =>
    toggled ? getColorForUnitState(theme, unitState) : theme.gray100};
  color: ${({ theme, toggled }) =>
    toggled ? theme.showcaseWhite : theme.primary100};
  cursor: pointer;
`;

/**
 * Modal that contains options to filter the units that are currently displayed
 * The filters are directly updated in the project store since they are used in the entire showcase
 */
const MobileUnitFilterModal = ({
  amountOfUnitsInFilter,
  onCloseModalRequest,
  onSearchChanged,
  searchValue
  // ignoredFilters = []
}) => {
  // Project State
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const {
    unitFilters,
    appliedFilters,
    project,
    showcaseConfiguration,
    appliedFilterSearchValue
  } = ProjectState;

  // Auth State
  const { AuthState } = useAuthState();

  const showUnitState = showcaseConfiguration?.showUnitState ?? true;
  const { showPrice, showBathrooms, showBedrooms, showSurface } =
    useShowUnitGeneralFields(false, null, project.unitList);

  const [bathroomFilter, setBathroomFilter] = useState();
  const [bedroomFilter, setBedroomFilter] = useState();
  const [surfaceFilter, setSurfaceFilter] = useState();
  const [priceFilter, setPriceFilter] = useState();
  const [stateFilter, setStateFilter] = useState();
  // const [buildingFilter, setBuildingFilter] = useState();

  const setFilterSliderData = useCallback(
    (filters) => {
      const defaultUnitFilters = createDefaultFiltersForUnits(project.unitList);

      const existingBathroomsFilter = filters?.find(
        (x) => x.filterOption === 'numberOfBathrooms'
      );
      const defaultBathroomsFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'numberOfBathrooms'
      );
      setBathroomFilter(existingBathroomsFilter ?? defaultBathroomsFilter);

      const existingBedroomsFilter = filters?.find(
        (x) => x.filterOption === 'numberOfBedrooms'
      );
      const defaultBedroomsFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'numberOfBedrooms'
      );
      setBedroomFilter(existingBedroomsFilter ?? defaultBedroomsFilter);

      const existingSurfaceFilter = filters?.find(
        (x) => x.filterOption === 'surface'
      );
      const defaultSurfaceFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'surface'
      );
      setSurfaceFilter(existingSurfaceFilter ?? defaultSurfaceFilter);

      const existingPriceFilter = filters?.find(
        (x) => x.filterOption === 'price'
      );
      const defaultPriceFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'price'
      );
      setPriceFilter(existingPriceFilter ?? defaultPriceFilter);

      const existingStateFilter = filters?.find(
        (x) => x.filterOption === 'state'
      );
      const defaultStateFilter = defaultUnitFilters?.find(
        (x) => x.filterOption === 'state'
      );
      setStateFilter(existingStateFilter ?? defaultStateFilter);

      // const existingBuildingFilter = filters?.find(
      //   (x) => x.filterOption === 'buildingObjectId'
      // );
      // const defaultBuildingFilter = defaultUnitFilters?.find(
      //   (x) => x.filterOption === 'buildingObjectId'
      // );
      // setBuildingFilter(existingBuildingFilter ?? defaultBuildingFilter);
    },
    [project]
  );

  // Set filters stored in the showcase
  useEffect(() => {
    if (
      !bathroomFilter &&
      !bedroomFilter &&
      !surfaceFilter &&
      !priceFilter &&
      !stateFilter
      // !buildingFilter
    ) {
      setFilterSliderData(unitFilters);
    }
  }, [
    unitFilters,
    bedroomFilter,
    bathroomFilter,
    surfaceFilter,
    priceFilter,
    stateFilter,
    // buildingFilter,
    setFilterSliderData
  ]);

  const onMobileUpdateFilter = (filter, values) => {
    let newFilters = unitFilters;

    if (unitFilters.find((x) => x.filterOption === filter.filterOption)) {
      newFilters = unitFilters.map((x) => {
        if (x.filterOption !== filter.filterOption) {
          return x;
        } else {
          return { ...filter, values: values };
        }
      });
    } else {
      newFilters.push({ ...filter, values: values });
    }

    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        unitFilters: [...newFilters]
      }
    });
  };

  const onResetFilters = () => {
    setFilterSliderData([]);
    // Update the filters used elsewhere in the showcase
    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        unitFilters: [],
        appliedFilters: [],
        filterSearchValue: '',
        appliedFilterSearchValue: ''
      }
    });

    // Reset the search field
    onSearchChanged('');
    document.getElementById('clearButton').click();
  };

  const onApplyFilters = useCallback(() => {
    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        appliedFilters: [...unitFilters],
        appliedFilterSearchValue: searchValue
      }
    });
    onCloseModalRequest();
  }, [onCloseModalRequest, ProjectStateDispatch, unitFilters, searchValue]);

  const handleCloseModal = useCallback(() => {
    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        unitFilters: [...appliedFilters],
        filterSearchValue: appliedFilterSearchValue
      }
    });
    onCloseModalRequest();
  }, [
    ProjectStateDispatch,
    onCloseModalRequest,
    appliedFilters,
    appliedFilterSearchValue
  ]);

  // const allBuildingOptions = project.unitList
  //   .map((x) => x.buildingObjectId)
  //   .filter((v, i, a) => v && a.indexOf(v) === i);

  return (
    <Backdrop>
      <VerticalWrapper
        data-testid="unitFilterModal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <FiltersWrapper>
          <ModalHeader>
            {capitalize(localizer.filterUnits)}
            <CloseButton onClick={handleCloseModal}>
              <CloseIcon icon={['fal', 'times']} size="1x" />
            </CloseButton>
          </ModalHeader>

          <StyledSearchField
            onChange={onSearchChanged}
            placeholder={capitalize(localizer.searchUnits)}
            initValue={searchValue}
          />
          <FilterSection>
            {bathroomFilter && showBathrooms && (
              <StyledSlider
                key={'sliderBathrooms'}
                filterObject={bathroomFilter}
                onUpdateValues={(values) => {
                  onMobileUpdateFilter(bathroomFilter, values);
                }}
              />
            )}
            {bedroomFilter && showBedrooms && (
              <FilterSlider
                key={'sliderBedrooms'}
                filterObject={bedroomFilter}
                onUpdateValues={(values) => {
                  onMobileUpdateFilter(bedroomFilter, values);
                }}
              />
            )}
            {(showBathrooms || showBedrooms) && <Divider />}
            {surfaceFilter && showSurface && (
              <StyledSlider
                key={'sliderSurface'}
                filterObject={surfaceFilter}
                onUpdateValues={(values) => {
                  onMobileUpdateFilter(surfaceFilter, values);
                }}
              />
            )}
            {priceFilter && showPrice && (
              <FilterSlider
                key={'sliderPrice'}
                filterObject={priceFilter}
                onUpdateValues={(values) => {
                  onMobileUpdateFilter(priceFilter, values);
                }}
                disabled={
                  shouldHidePriceWhenShared(showcaseConfiguration, AuthState) ||
                  !showPrice
                }
              />
            )}

            {stateFilter &&
              showUnitState &&
              showcaseConfiguration?.hideInOptionSoldUnits !== true && (
                <>
                  {(showPrice || showSurface) && <Divider />}
                  <FilterToggleButtons
                    key={'toggleButtonsState'}
                    filterKey="state"
                    toggledOptions={stateFilter}
                    allOptions={unitStatesNotArchived}
                    onUpdateToggledOptions={(toggledOptions) => {
                      onMobileUpdateFilter(stateFilter, toggledOptions);
                    }}
                    button={(props) => (
                      <MobileToggleButton {...props}>
                        {props.children}
                      </MobileToggleButton>
                    )}
                  />
                </>
              )}
            {/* {buildingFilter && !ignoredFilters.includes('buildingObjectId') && (
              <>
                <Divider />
                <FilterToggleButtons
                  key={'toggleButtonsBuilding'}
                  filterKey="buildingObjectId"
                  toggledOptions={buildingFilter}
                  allOptions={allBuildingOptions}
                  onUpdateToggledOptions={(toggledOptions) => {
                    onMobileUpdateFilter(buildingFilter, toggledOptions);
                  }}
                  button={(props) => (
                    <ToggleBuildingButton {...props}>
                      <span>{props.children}</span>
                    </ToggleBuildingButton>
                  )}
                />
              </>
            )} */}
          </FilterSection>
          <InformationSection>
            <ResetFiltersButton onClick={onResetFilters}>
              {localizer.resetAllFilters}
            </ResetFiltersButton>
            <UnitsInFilterIndicator>
              <IndicatorBold>
                {`${amountOfUnitsInFilter} ${
                  amountOfUnitsInFilter === 1
                    ? localizer.unit.toLowerCase()
                    : localizer.units.toLowerCase()
                }`}
              </IndicatorBold>
              <div>{localizer.inFilter}</div>
            </UnitsInFilterIndicator>
          </InformationSection>
        </FiltersWrapper>
        <ApplyFiltersButton onTouchStart={onApplyFilters}>
          {localizer.applyFilters}
        </ApplyFiltersButton>
      </VerticalWrapper>
    </Backdrop>
  );
};

MobileUnitFilterModal.propTypes = {
  /** The amount of units that are in the current filter */
  amountOfUnitsInFilter: number.isRequired,
  /** We ask the parent to be closed
   * @callback
   */
  onCloseModalRequest: func.isRequired,
  /** Callback when search value in the filter modal has been changed
   * @callback
   * @param {string} value The search value
   */
  onSearchChanged: func.isRequired,
  searchValue: string.isRequired
};

export default MobileUnitFilterModal;
