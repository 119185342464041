import React, { useState, useEffect, memo } from 'react';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Helpers
import { isMobile } from 'react-device-detect';

// Components
import {
  OutOfViewportBottomIcon,
  OutOfViewportTopIcon,
  OutOfViewportLeftIcon,
  OutOfViewportRightIcon
} from 'resources/icons';

const OutOfViewportMarker = styled(motion.div)`
  position: fixed;
  z-index: 100;
  color: ${(props) => props.theme.showcaseWhite};
  width: 40px;
  height: 40px;
  div {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 14px;
  }
`;

const OutOfViewportLeft = styled(OutOfViewportMarker)`
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  div {
    top: 50%;
    left: calc(50% + 2px);
  }
`;

const OutOfViewportRight = styled(OutOfViewportMarker)`
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  div {
    top: 50%;
    left: calc(50% - 2px);
  }
`;

const OutOfViewportTop = styled(OutOfViewportMarker)`
  left: 50%;
  top: ${isMobile ? '80px' : '10px'};
  transform: translateX(-50%);
  div {
    top: calc(50% + 2px);
    left: 50%;
  }
`;

const OutOfViewportBottom = styled(OutOfViewportMarker)`
  left: 50%;
  bottom: ${isMobile ? '70px' : '90px'};
  transform: translateX(-50%);
  div {
    top: calc(50% - 2px);
    left: 50%;
  }
`;

// x - spot position relative to parent vertical center line,
//     varies from -1 (very left) to 1 (very right)
// y - spot position relative to parent horizontal center line,
//     varies from -1 (very top) to 1 (very bottom)
const convertRelativeLocation = (x, y, parentDimensions) => {
  // Get parent image size and container size
  const parentWidth = parentDimensions?.width || 0;
  const parentHeight = parentDimensions?.height || 0;

  // Calculate the half size of the image and the offset of the marker according to the center of the image
  const parentHalfWidth = parentWidth / 2.0;
  const parentHalfHeight = parentHeight / 2.0;

  const centerOffsetX = parentHalfWidth * x;
  const centerOffsetY = parentHalfHeight * y;

  const topLeftCornerOffsetX = centerOffsetX + parentHalfWidth;
  const topLeftCornerOffsetY = centerOffsetY + parentHalfHeight;

  return { x: topLeftCornerOffsetX, y: topLeftCornerOffsetY };
};

const sharedAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 }
};

const OutOfViewportSpotMarkers = memo(
  ({
    filteredUnitSpotList,
    scale,
    parentDimensions,
    idealImageRelativePosition,
    containerDimensions
  }) => {
    const [outOfViewportSpotsQuantity, setOutOfViewportQuantity] = useState({});

    useEffect(() => {
      const hiddenSpots = {};
      const screenWidth = isMobile ? window.screen.width : window.innerWidth;

      filteredUnitSpotList.forEach((spot) => {
        const spotLoc = convertRelativeLocation(
          spot.xCoordinate,
          spot.yCoordinate,
          parentDimensions
        );

        if (idealImageRelativePosition.offsetX + spotLoc.x < 0) {
          hiddenSpots.left = hiddenSpots.left ? ++hiddenSpots.left : 1;
        }
        if (idealImageRelativePosition.offsetX + spotLoc.x > screenWidth) {
          hiddenSpots.right = hiddenSpots.right ? ++hiddenSpots.right : 1;
        }
        if (idealImageRelativePosition.offsetY + spotLoc.y < 0) {
          hiddenSpots.top = hiddenSpots.top ? ++hiddenSpots.top : 1;
        }
        if (
          idealImageRelativePosition.offsetY + spotLoc.y >
          containerDimensions.height
        ) {
          hiddenSpots.bottom = hiddenSpots.bottom ? ++hiddenSpots.bottom : 1;
        }
      });

      setOutOfViewportQuantity(hiddenSpots);
    }, [
      filteredUnitSpotList,
      scale,
      parentDimensions,
      idealImageRelativePosition,
      containerDimensions.height
    ]);

    return (
      <>
        {outOfViewportSpotsQuantity?.left && (
          <OutOfViewportLeft {...sharedAnimation}>
            <OutOfViewportLeftIcon />
            <div>{outOfViewportSpotsQuantity?.left}</div>
          </OutOfViewportLeft>
        )}
        {outOfViewportSpotsQuantity?.right && (
          <OutOfViewportRight {...sharedAnimation}>
            <OutOfViewportRightIcon />
            <div>{outOfViewportSpotsQuantity?.right}</div>
          </OutOfViewportRight>
        )}
        {outOfViewportSpotsQuantity?.top && (
          <OutOfViewportTop {...sharedAnimation}>
            <OutOfViewportTopIcon />
            <div>{outOfViewportSpotsQuantity?.top}</div>
          </OutOfViewportTop>
        )}
        {outOfViewportSpotsQuantity?.bottom && (
          <OutOfViewportBottom {...sharedAnimation}>
            <OutOfViewportBottomIcon />
            <div>{outOfViewportSpotsQuantity?.bottom}</div>
          </OutOfViewportBottom>
        )}
      </>
    );
  }
);

export default OutOfViewportSpotMarkers;
