import React, { useReducer, createContext, useContext } from 'react';
export const MarketplaceStoreContext = createContext();

const MarketplaceStore = (props) => {
  const [MarketplaceState, MarketplaceStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'setMarkerplaceData':
          return {
            ...state,
            ...action.payload
          };
        case 'toggleFavorites':
          return {
            ...state,
            userFavoritesList: state.userFavoritesList.includes(action.payload)
              ? state.userFavoritesList.filter((id) => id !== action.payload)
              : [...state.userFavoritesList, action.payload]
          };
        case 'setProvidedFavorites':
          return {
            ...state,
            providedFavoritesList: [...action.payload]
          };
        case 'setInitialUserFavorites':
          return {
            ...state,
            userFavoritesList: [...action.payload]
          };
        case 'setTotalFavorites':
          return {
            ...state,
            favoritesList: [...action.payload]
          };
        case 'setInitialFavorites':
          return {
            ...state,
            favoritesList: [...state.favoritesList, ...action.payload]
          };
        case 'updateSavedUiState':
          return {
            ...state,
            savedUiState: {
              ...state.savedUiState,
              ...action.payload
            }
          };
        case 'resetSavedUiState':
          return {
            ...state,
            savedUiState: {}
          };
        default:
          return state;
      }
    },
    {
      marketplaceInitialized: false,
      allProjects: [],
      projectsData: null,
      projects: [],
      favoritesList: [],
      // providedFavoritesList - taken from "fpr" query param
      providedFavoritesList: [],
      // userFavoritesList - projects chosen by user and saved in cookie
      userFavoritesList: [],
      sharedFilters: {},
      usedFilters: [],
      appliedFilters: [],
      filteredProjectIds: [],
      initialFilter: {},
      wizardFilter: {},
      selectedSortOption: {
        name: 'Newest',
        sortField: 'createdAt',
        sortAsc: false
      },
      // save active marketplace view and related data
      // e.g { map: { activeSliderItem: 6 } }
      savedUiState: {},
      filter: {
        sortField: 'createdAt',
        sortAsc: false,
        searchValue: null,
        offset: 10,
        limit: 10,
        timeStamp: 0,
        includeContentCollection: true
      },
      mapVisibleProjectsMessage: '',
      currentView: ''
    }
  );

  return (
    <MarketplaceStoreContext.Provider
      value={{ MarketplaceState, MarketplaceStateDispatch }}
    >
      {props.children}
    </MarketplaceStoreContext.Provider>
  );
};

export const useMarketplaceState = () => useContext(MarketplaceStoreContext);

export default MarketplaceStore;
