import React, { useState, useRef } from 'react';
import { shape, func } from 'prop-types';

// Components
import { FullscreenIcon, PlusIcon, MinusIcon } from 'resources/icons';
import { Document, Page, pdfjs } from 'react-pdf';

// Helpers
import path from 'path';

// Styling
import styled from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DocumentScrollWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const ScrollView = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: ${(props) => (props.numPages > 1 ? 'flex-start' : 'center')};
`;

const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-flow: column;
  min-height: 100px;
  width: 50px;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0.8;
  border: 1px solid white;
  border-radius: 3px;
  margin-top: 15px;
  backdrop-filter: blur(5px);
  cursor: pointer;
`;

const minZoom = 0.5;
const maxZoom = 10;
const DocumentContent = ({ data, onLoad }) => {
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(0);

  const [interacted, setInteracted] = useState(false);
  const container = useRef();

  let name = data.name || data.title?.textMap?.en;

  const extension = path.extname(name).replace('.', '');
  const isPdf = extension === 'pdf';

  const onInteract = () => {
    if (!interacted) {
      setInteracted(true);
    }
  };

  // Rendering
  if (!isPdf) {
    return null;
  }

  return (
    <DocumentWrapper ref={container} onScroll={onInteract}>
      <DocumentScrollWrapper>
        <ScrollView numPages={numPages}>
          <Document
            key={data.objectId}
            file={data.contentUri}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              onLoad();
            }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                scale={scale}
                pageNumber={index + 1}
                width={container.current?.getBoundingClientRect().width - 15}
              />
            ))}
          </Document>
        </ScrollView>
      </DocumentScrollWrapper>
      <Controls>
        <Icon
          onClick={() => {
            onInteract();
            setScale((cur) => Math.min(cur + 0.5, maxZoom));
          }}
        >
          <PlusIcon />
        </Icon>
        <Icon
          onClick={() => {
            onInteract();
            setScale((cur) => Math.max(cur - 0.5, minZoom));
          }}
        >
          <MinusIcon />
        </Icon>
        <Icon
          onClick={() => {
            onInteract();
            setScale(1);
          }}
        >
          <FullscreenIcon />
        </Icon>
      </Controls>
    </DocumentWrapper>
  );
};

DocumentContent.propTypes = {
  data: shape({}),
  onLoad: func
};

DocumentContent.defaultProps = {
  data: {},
  onLoad: () => {}
};

export default DocumentContent;
