import React from 'react';
import { shape, func } from 'prop-types';

// Helpers
import { getCurrentEnvironment } from 'helpers';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

const TourPreview = styled(motion.div)`
  position: fixed;
  z-index: 1000;
  left: 15px;
  right: 15px;
  bottom: ${({ landscape }) => (landscape ? 15 : 75)}px;
  height: 100px;
  padding: 10px 20px 10px 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray100};
  background-color: ${(props) => props.theme.showcaseWhite};
  display: flex;
  align-items: center;
`;

const TourPreviewImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 15px 0 0;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
`;

const TourPreviewText = styled.div`
  & > p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    -webkit-line-clamp: 2;
    flex-grow: 1;
    font-size: 18px;
    height: 100%;
    color: ${(props) => props.theme.showcaseBlack};
  }
`;

const ArrowRightIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${(props) => props.theme.primary400};
  margin-left: auto;
  padding-left: 16px;
  flex-shrink: 0;
`;

const TourPreviewPopup = ({ show, content, onClick, landscape }) => {
  return (
    <AnimatePresence>
      {show && content && (
        <TourPreview
          onClick={onClick}
          initial={{
            opacity: 0,
            x: -60,
            transform: 'scaleX(0.8)',
            transformOrigin: 'left center'
          }}
          animate={{ opacity: 1, x: 0, transform: 'scaleX(1)' }}
          exit={{ opacity: 0, x: -60, transform: 'scaleX(0.8)' }}
          transition={{ duration: 0.25, ease: 'easeOut' }}
          landscape={landscape ? 1 : 0}
        >
          <TourPreviewImage
            src={`${getCurrentEnvironment().baseImageUrl}/q=50:h=80/${
              content.contentUri
            }`}
            alt="Tour preview"
          />
          <TourPreviewText>
            <p>{content.title.textMap.en}</p>
          </TourPreviewText>
          <ArrowRightIcon icon={['far', 'arrow-right']} size="1x" />
        </TourPreview>
      )}
    </AnimatePresence>
  );
};

TourPreviewPopup.propTypes = {
  content: shape({}),
  onClick: func
};

TourPreviewPopup.defaultProps = {
  onClick: () => {}
};

export default TourPreviewPopup;
