import React, { useState, useRef, useEffect, useCallback } from 'react';
import { shape, number, bool, func } from 'prop-types';

// Components
import Item from './Item';
import ArrowButton from './ArrowButton';
import Scrollbar from './Scrollbar';

// Helpers
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useAnimation
} from 'framer-motion';
import useWindowSize from '@rehooks/window-size';
import useResize from 'use-resize';
import { debounce } from 'helpers';
import { useKeyPress } from 'helpers/customHooks';
import { isMobileOnly } from 'react-device-detect';

// Styles
import styled, { css } from 'styled-components';

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => `0 ${padding}px`};
  box-sizing: border-box;
  position: relative;
  align-items: center;
`;

const Track = styled(motion.div)`
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  min-width: min-content;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const scrollBarStyles = css`
  position: absolute;
  left: 50%;
  bottom: ${isMobileOnly ? '-40px' : '-55px'};
  transform: translateX(-50%);
`;

const sharedArrowStyle = css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
`;

const LeftArrowButton = styled(ArrowButton)``;

const RightArrowButton = styled(ArrowButton)``;

const MotionSlider = ({
  children,
  maxActiveItems,
  minActiveItems,
  minItemWidth,
  padding,
  gap,
  velocity,
  transition,
  disableArrows,
  arrowsOffset,
  disableScroll,
  disableArrowKeys,
  onSliderReachesEnd,
  loadDataEndReached,
  onActiveItemsChanged,
  activeSliderItem,
  hideScrollbar,
  onDrag,
  onScroll,
  enableLockedScroll,
  onSliderLoaded
}) => {
  const [activeItem, setActiveItem] = useState(0);
  const [offsets, setOffsets] = useState([]);

  const startTimeOffset = 500;
  const [hasLoaded, setHasLoaded] = useState(false);

  const trackRef = useRef();
  const containerRef = useRef();

  const [trackWidth, setTrackWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [numActiveItems, setNumActiveItems] = useState(0);

  const [dragDisabled, setDragDisabled] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [previousSize, setPreviousSize] = useState();
  const [sliderEndReached, setSliderEndReached] = useState(false);

  // Keypress events
  const nextKeyPressed = useKeyPress('ArrowRight', true, false);
  const previousKeyPressed = useKeyPress('ArrowLeft', true, false);

  // Scrolling variables
  const x = useMotionValue(0);
  const windowDimensions = useWindowSize();
  const controls = useAnimation();
  const size = useResize();

  useEffect(() => {
    onActiveItemsChanged(activeItem, numActiveItems);
  }, [activeItem, numActiveItems, onActiveItemsChanged]);

  // Recalculate sizes of the items and container
  const calculateItemWidth = useCallback(
    (containerWidth, activeItems) =>
      (containerWidth - (activeItems - 1) * gap) / activeItems,
    [gap]
  );

  const updateSizes = useCallback(() => {
    if (children.length > 0 && containerRef.current) {
      const newTrackWidth = trackRef.current?.offsetWidth;
      const newContainerWidth = containerRef.current?.offsetWidth - 2 * padding;

      let newItemWidth = 0;
      let newNumActiveItems = 0;
      if (minActiveItems === maxActiveItems) {
        newNumActiveItems = minActiveItems;
        newItemWidth = calculateItemWidth(newContainerWidth, newNumActiveItems);
      } else {
        newNumActiveItems = Math.min(
          minActiveItems ? minActiveItems : children.length,
          maxActiveItems
        );
        newItemWidth = calculateItemWidth(newContainerWidth, newNumActiveItems);

        while (newItemWidth < minItemWidth && newNumActiveItems > 1) {
          --newNumActiveItems;
          newItemWidth = calculateItemWidth(
            newContainerWidth,
            newNumActiveItems
          );
        }
      }

      setNumActiveItems(newNumActiveItems);
      setTrackWidth(newTrackWidth);
      setContainerWidth(newContainerWidth);
      setItemWidth(newItemWidth);

      const newOffsets = children.map((child, index) => {
        return -index * (newItemWidth + gap);
      });
      setOffsets(newOffsets);
    }
  }, [
    children,
    gap,
    padding,
    maxActiveItems,
    minActiveItems,
    minItemWidth,
    calculateItemWidth
  ]);

  useEffect(() => {
    setTimeout(() => {
      updateSizes();
      setHasLoaded(true);
      onSliderLoaded();
    }, startTimeOffset);
  }, [updateSizes, onSliderLoaded]);

  // When the slider reaches the end
  useEffect(() => {
    if (
      !sliderEndReached &&
      !loadDataEndReached &&
      children.length > 0 &&
      activeItem > children.length - numActiveItems - 5 &&
      // should only fetch new items if the slider reached the end by dragging
      !(activeSliderItem > -1)
    ) {
      setSliderEndReached(true);
      onSliderReachesEnd();
    }
  }, [
    activeItem,
    children.length,
    numActiveItems,
    onSliderReachesEnd,
    sliderEndReached,
    loadDataEndReached,
    activeSliderItem
  ]);

  useEffect(() => {
    setSliderEndReached(false);
  }, [children.length]);

  // Whenever an arrow button is clicked, disabled dragging for a small time to allow the animation to finish
  useEffect(() => {
    if (dragDisabled) {
      setTimeout(() => {
        setDragDisabled(false);
      }, 400);
    }
  }, [dragDisabled]);

  // On window resize, recalculate the width of the cards
  const onResize = debounce(() => {
    updateSizes();
  }, 150);

  useEffect(() => {
    if (size !== previousSize) {
      setPreviousSize(size);
      onResize();
    }
  }, [size, previousSize, onResize]);

  // Dragging animation
  const moveActiveItem = useCallback(
    (newActiveItem, velocity, enableDelay) => {
      if (enableDelay) {
        setDragDisabled(true);
      }

      if (newActiveItem > children.length - numActiveItems) {
        newActiveItem = children.length - numActiveItems;
      }
      setActiveItem(newActiveItem);
      const nextPosition = offsets[newActiveItem];

      controls.start({
        x: Math.max(
          nextPosition,
          windowDimensions.innerWidth -
            trackRef.current?.offsetWidth -
            (padding + padding)
        ),
        transition: {
          type: 'spring',
          stiffness: transition.stiffness,
          damping: transition.damping,
          velocity,
          mass: transition.mass
        }
      });
    },
    [
      controls,
      offsets,
      padding,
      transition,
      children.length,
      numActiveItems,
      windowDimensions.innerWidth
    ]
  );

  // slide cards imperatively without actual dragging
  useEffect(() => {
    if (numActiveItems === 0) return;
    if (activeSliderItem > -1 && activeItem !== activeSliderItem) {
      moveActiveItem(activeSliderItem, 700);
    }
  }, [activeSliderItem, moveActiveItem, activeItem, numActiveItems]);

  // On next / previous events
  const onNext = useCallback(
    (offset, enableDelay) => {
      let newActiveItem = activeItem + offset;
      if (newActiveItem > children.length - 1) {
        newActiveItem = children.length - 1;
      }

      moveActiveItem(newActiveItem, 1, enableDelay);
    },
    [activeItem, children.length, moveActiveItem]
  );

  const onPrevious = useCallback(
    (offset, enableDelay) => {
      let newActiveItem = activeItem - offset;
      if (newActiveItem < 0) {
        newActiveItem = 0;
      }

      moveActiveItem(newActiveItem, 1, enableDelay);
    },
    [activeItem, moveActiveItem]
  );

  // Scroll binding
  const handleScroll = useCallback(
    (e) => {
      if (enableLockedScroll && !containerRef.current?.contains(e.target))
        return;
      e.preventDefault();
      e.stopPropagation();

      if (disableScroll) {
        return;
      }
      onScroll();
      const useDeltaX = Math.abs(e.deltaX) > Math.abs(e.deltaY);
      const delta = useDeltaX ? -e.deltaX : -e.deltaY;

      if (delta < -4 && !dragDisabled) {
        onNext(1, true);
      } else if (delta > 4 && !dragDisabled) {
        onPrevious(1, true);
      }
    },
    [
      dragDisabled,
      disableScroll,
      onNext,
      onPrevious,
      enableLockedScroll,
      onScroll
    ]
  );

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [handleScroll]);

  // Arrow keys binding
  useEffect(() => {
    if (!disableArrowKeys && previousKeyPressed && !dragDisabled) {
      onPrevious(1, true);
    }
  }, [disableArrowKeys, previousKeyPressed, dragDisabled, onPrevious]);

  useEffect(() => {
    if (!disableArrowKeys && nextKeyPressed && !dragDisabled) {
      onNext(1, true);
    }
  }, [disableArrowKeys, nextKeyPressed, dragDisabled, onNext]);

  const onDragEnd = (event, info) => {
    const offset = info.offset.x;
    const correctedVelocity = info.velocity.x * velocity;
    const direction = correctedVelocity < 0 || offset < 0 ? 1 : -1;
    const startPosition = info.point.x - offset;

    const endOffset =
      direction === 1
        ? Math.min(correctedVelocity, offset)
        : Math.max(correctedVelocity, offset);
    const endPosition = startPosition + endOffset;

    const selectableOffsets = offsets.slice(
      0,
      offsets.length - (numActiveItems - 1)
    );
    if (selectableOffsets.length === 0) return;
    const closestPosition = selectableOffsets.reduce((prev, curr) =>
      Math.abs(curr - endPosition) < Math.abs(prev - endPosition) ? curr : prev
    );
    const activeSlide = selectableOffsets.indexOf(closestPosition);
    moveActiveItem(activeSlide, info.velocity.x);
  };

  // Previous/Next arrow UI buttons
  const showPrevious = !disableArrows && activeItem > 0 && children?.length > 1;
  const showNext =
    !disableArrows &&
    activeItem < children.length - numActiveItems &&
    children?.length > 1;

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { isDragging })
  );

  return (
    <Wrapper
      ref={containerRef}
      initial={{ opacity: 0 }}
      animate={hasLoaded ? { opacity: 1 } : { opacity: 0 }}
      padding={padding}
    >
      <AnimatePresence>
        {showPrevious && (
          <LeftArrowButton
            key={'previous'}
            arrowsOffset={arrowsOffset}
            iconSetting={{ icon: ['fal', 'angle-left'] }}
            onClick={() => {
              onPrevious(1, true);
            }}
            styles={`
              ${sharedArrowStyle}
              left: ${arrowsOffset}px;
            `}
          />
        )}
        {showNext && (
          <RightArrowButton
            key={'next'}
            iconSetting={{ icon: ['fal', 'angle-right'] }}
            onClick={() => {
              onNext(1, true);
            }}
            styles={`
              ${sharedArrowStyle}
              right: ${arrowsOffset}px;
            `}
          />
        )}
      </AnimatePresence>
      <Track
        key={'track'}
        ref={trackRef}
        style={{ x }}
        animate={controls}
        drag={!dragDisabled && 'x'}
        dragConstraints={{
          left:
            windowDimensions.innerWidth -
            trackRef.current?.offsetWidth -
            (padding + padding),
          right: 0
        }}
        onDragStart={() => {
          onDrag();
          setIsDragging(true);
        }}
        onDragEnd={(event, info) => {
          onDragEnd(event, info);
          setTimeout(() => {
            setIsDragging(false);
          }, 10);
        }}
      >
        {childrenWithProps.map((child, i) => {
          return (
            <Item
              key={i}
              gap={gap}
              containerWidth={containerWidth}
              itemWidth={itemWidth}
              index={i}
              offset={x}
              visible={
                i <= activeItem + numActiveItems + 2 && i >= activeItem - 2
              }
            >
              {child}
            </Item>
          );
        })}
      </Track>
      {children.length > 1 && !hideScrollbar && (
        <Scrollbar
          trackWidth={trackWidth}
          containerWidth={containerWidth}
          activeItem={activeItem}
          itemCount={children.length}
          moveActiveItem={moveActiveItem}
          styles={scrollBarStyles}
        />
      )}
    </Wrapper>
  );
};

MotionSlider.propTypes = {
  maxActiveItems: number,
  minActiveItems: number,
  minItemWidth: number,
  padding: number,
  gap: number,
  velocity: number,
  transition: shape({}),
  disableScroll: bool,
  disableArrows: bool,
  disableArrowKeys: bool,
  arrowsOffset: number,
  onSliderReachesEnd: func,
  loadDataEndReached: bool,
  onActiveItemsChanged: func,
  hideScrollbar: bool,
  onDrag: func,
  onScroll: func,
  enableLockedScroll: bool,
  onSliderLoaded: func
};

MotionSlider.defaultProps = {
  maxActiveItems: 3,
  minActiveItems: 0,
  minItemWidth: 300,
  padding: 100,
  gap: 10,
  velocity: 0.9,
  transition: { stiffness: 300, damping: 600, mass: 3 },
  disableScroll: false,
  disableArrows: false,
  disableArrowKeys: false,
  arrowsOffset: 50,
  onSliderReachesEnd: () => {},
  loadDataEndReached: false,
  onActiveItemsChanged: () => {},
  hideScrollbar: false,
  onDrag: () => {},
  onScroll: () => {},
  enableLockedScroll: false.valueOf,
  onSliderLoaded: () => {}
};

export default MotionSlider;
