import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

// Components
import {
  GoogleMap,
  useLoadScript,
  OverlayView,
  StreetViewPanorama
} from '@react-google-maps/api';
import { motion, AnimatePresence } from 'framer-motion';
import MapViewSlider from './MapViewSlider';
import HighlightedMarker from './HighlightedMarker';
import MobileSelectedProjectCard from './MobileSelectedProjectCard';
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChosenLocationMarker from './ChosenLocationMarker';
import Markers from './Markers';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import isEqual from 'lodash.isequal';
import uniqid from 'uniqid';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import { usePrevious } from '@prompto-helpers';
import { getCurrentEnvironment } from 'helpers';
import localizer from 'localization/localizer';
import useResize from 'use-resize';

// Styling
import mapStyling from './marketplaceMapStyling';
import styled, { css } from 'styled-components';

import { contentState } from '../PortfolioPage';

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: ${({ withmobilemenu }) =>
    isMobileOnly && withmobilemenu ? 'calc(100% - 60px)' : '100%'};
  width: 100%;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const StyledOverlayView = styled(OverlayView)`
  & > div {
    z-index: 1000;
  }
`;

const minZoomLevel = 3;
const defaultZoomLevel = 6;

const sliderHeight = 30; // % of screen height

const LoadingProjectCards = styled.div`
  position: fixed;
  z-index: 5;
  bottom: ${isMobileOnly ? 20 : 10}px;
  left: 0;
  right: 0;
  width: 100%;
  height: ${sliderHeight}vh;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
`;

const ToggleMapTypeButton = styled(motion.button)`
  position: absolute;
  left: calc(100% - 55px);
  top: 80px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  border: solid 1px ${({ theme }) => theme.gray150};
  background-color: rgba(
    255,
    255,
    255,
    ${({ theme }) => theme.controlsOpacity}
  );
  color: ${({ theme }) => theme.primary100};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ position }) =>
    position &&
    css`
      color: #666666;
      top: ${position.top}px;
      left: ${position.left}px;
      background-color: rgb(255, 255, 255);
      box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
      border-radius: 2px;
      width: 40px;
      height: 40px;
      &:hover {
        color: ${({ theme }) => theme.showcaseBlack};
      }
    `}
`;

const ToggleMapTypeIcon = styled(FontAwesomeIcon)`
  pointer-events: none;
  font-size: 1.125rem;
`;
const getProjectCoords = (project, geocoder) => {
  let coords = null;
  const addressObject = project?.address;

  if (addressObject) {
    if (addressObject.latitude && addressObject.longitude) {
      const latLong = {
        lat: parseFloat(addressObject.latitude),
        lng: parseFloat(addressObject.longitude)
      };
      coords = latLong;
    } else {
      const addressString = `${addressObject.addressLine1} ${addressObject.city} ${addressObject.country} ${addressObject.zipCode}`;
      geocoder.geocode({ address: addressString }, (results) => {
        if (results && results.length > 0) {
          const location = results[0].geometry.location;
          const newLatLong = {
            lat: location.lat(),
            lng: location.lng()
          };
          coords = newLatLong;
        }
      });
    }
  }
  return coords;
};

const isEqualOrder = (arrOne, arrTwo) => {
  let same = true;
  for (let i = 0; i < arrOne.length; i++) {
    if (!isEqual(arrOne[1], arrTwo[i])) {
      same = false;
      break;
    }
  }
  return same;
};

const mapTypes = ['roadmap', 'satellite'];
const googleMapsLibraries = ['drawing', 'visualization', 'places'];

const MapView = memo(
  ({
    fullList,
    activeContent,
    show,
    mapInstance,
    setMapInstance,
    chosenLocation,
    ...props
  }) => {
    const [loaderId] = useState(uniqid('loader-'));
    const [mapId] = useState(uniqid('map-'));

    const [mapTypeId, setMapTypeId] = useState(mapTypes[0]);
    const [customMapTypeControlPosition, setCustomMapTypeControlPosition] =
      useState(null);

    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [isMapReady, setIsMapReady] = useState(false);
    const [centerLatLng, setCenterLatLng] = useState();

    // slider related
    const [sliderLoaded, setSliderLoaded] = useState(false);
    const [activeSliderItems, setActiveSliderItems] = useState([]);
    const [activeSliderItem, setActiveSliderItem] = useState(-1);
    const [savedSliderItemApplied, setSavedSliderItemApplied] = useState(false);
    const [sliderDataRefreshed, setSliderDataRefreshed] = useState(true);
    const [savedClickedMarkerApplied, setSavedClickedMarkerApplied] =
      useState(false);

    // markers related
    const [projects, setProjects] = useState([]);
    const [highlightedProject, setHighlightedProject] = useState(null);
    const [clickedMarker, setClickedMarker] = useState(null);
    const [visibleProjectsIDs, setVisibleProjectsIDs] = useState([]);

    // map interaction related
    const [isDraggingMap, setIsDraggingMap] = useState(false);
    const [bounds, setBounds] = useState(null);
    const [projectsCurrVisibleInMap, setProjectsCurrVisibleInMap] = useState(
      []
    );

    const previousList = usePrevious(fullList);
    const previousShow = usePrevious(show);
    const previousProjectsCurrVisibleInMap = usePrevious(
      projectsCurrVisibleInMap
    );

    // street view related
    const [streetViewVisible, setStreetViewVisible] = useState(true);

    // Marketplace state
    const { MarketplaceState, MarketplaceStateDispatch } =
      useMarketplaceState();
    const { savedUiState, mapVisibleProjectsMessage } = MarketplaceState;

    const size = useResize();

    const resetSliderRelatedStates = useCallback(() => {
      setSliderLoaded(false);
      setActiveSliderItems([]);
      setActiveSliderItem(-1);
      setHighlightedProject(null);
    }, []);

    // persist UI state related effects START
    useEffect(() => {
      if (!show) return;
      if (!isMobileOnly && !sliderLoaded) return;
      if (savedSliderItemApplied && !isMobileOnly) {
        setActiveSliderItem(-1);
        return;
      }
      if (savedClickedMarkerApplied && isMobileOnly) {
        return;
      }
      if (!isMapReady) return;

      if (isMobileOnly) {
        if (savedUiState.mapMobile?.clickedMarker) {
          setTimeout(() => {
            setClickedMarker(savedUiState.mapMobile.clickedMarker);
            mapInstance.fitBounds(savedUiState.mapMobile.bounds);
            mapInstance.setZoom(savedUiState.mapMobile.zoom);
            setMapInstance(mapInstance);
          }, 200);
          setSavedClickedMarkerApplied(true);
        }
      } else if (savedUiState.map) {
        setTimeout(() => {
          setProjectsCurrVisibleInMap(savedUiState.map.visibleProjects);
          setActiveSliderItem(savedUiState.map.activeSliderItem);
          if (savedUiState.map.bounds) {
            mapInstance.fitBounds(savedUiState.map.bounds);
          }
          if (savedUiState.map?.zoom) {
            mapInstance.setZoom(savedUiState.map.zoom);
          }
          setMapInstance(mapInstance);
        }, 200);
        setSavedSliderItemApplied(true);
      }
    }, [
      savedUiState,
      sliderLoaded,
      savedSliderItemApplied,
      activeSliderItems,
      show,
      savedClickedMarkerApplied,
      isMapReady,
      mapInstance,
      setMapInstance
    ]);

    const getControlCoords = useCallback(() => {
      const timer = setTimeout(() => {
        const zoomControl = document.querySelector('.gm-bundled-control');
        if (zoomControl) {
          const bounds = zoomControl.getBoundingClientRect();
          setCustomMapTypeControlPosition({
            left: bounds.left,
            top: bounds.top - 70
          });
          clearTimeout(timer);
        } else {
          getControlCoords();
        }
      }, 500);
    }, []);

    useEffect(() => {
      if (!isMapLoaded) return;
      if (customMapTypeControlPosition) {
        return;
      }
      getControlCoords();
    }, [isMapLoaded, customMapTypeControlPosition, getControlCoords]);

    useEffect(() => {
      getControlCoords();
    }, [size, getControlCoords]);

    useEffect(() => {
      if (!!previousShow && !show && activeSliderItems[0]) {
        MarketplaceStateDispatch({
          type: 'updateSavedUiState',
          payload: {
            map: {
              activeSliderItem: activeSliderItems[0],
              visibleProjects: projectsCurrVisibleInMap
            }
          }
        });
      }
    }, [
      show,
      activeSliderItems,
      previousShow,
      MarketplaceStateDispatch,
      projectsCurrVisibleInMap
    ]);

    useEffect(() => {
      if (!show) {
        resetSliderRelatedStates();
        setSavedSliderItemApplied(false);
        setClickedMarker(null);
      }
    }, [show, resetSliderRelatedStates]);
    // persist UI state related effects END

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: getCurrentEnvironment().googleMapsApiKey,
      id: loaderId,
      version: 'weekly',
      // if set to true it breaks the Emotion styles
      preventGoogleFontsLoading: false,
      libraries: googleMapsLibraries
    });

    const highlightedMarkerRef = useRef();
    const projectCardWrapperRef = useRef();
    const mapWrapperRef = useRef();

    // if there is a clicked marker slide the slider to a proper position
    // else reset the activeSliderItem so that the slider can be slided manually
    useEffect(() => {
      if (!sliderDataRefreshed || !sliderLoaded) return;
      if (clickedMarker) {
        const targetProjects =
          projectsCurrVisibleInMap.length > 0
            ? projectsCurrVisibleInMap
            : projects.map((x) => x.objectId);
        const activeSliderItem = targetProjects.findIndex(
          (id) => id === clickedMarker.objectId
        );
        setActiveSliderItem(activeSliderItem ?? -1);
      } else {
        setActiveSliderItem(-1);
      }
    }, [
      clickedMarker,
      projects,
      projectsCurrVisibleInMap,
      sliderDataRefreshed,
      sliderLoaded
    ]);

    useEffect(() => {
      if (
        isMapReady &&
        isEqualOrder(fullList, previousList) &&
        fullList?.length > 0
      )
        return;
      if (isLoaded && window.google && mapInstance) {
        const geocoder = new window.google.maps.Geocoder();

        if (fullList?.length > 0) {
          const latitudes = [],
            longitudes = [];
          const projects = fullList
            .filter((project) => project.vmContentCollection)
            .map((project) => {
              const coords = getProjectCoords(project, geocoder);
              if (coords) {
                latitudes.push(coords.lat);
                longitudes.push(coords.lng);
              }
              return { ...project, coords };
            })
            // display only projects with coordinates
            .filter(({ coords }) => !!coords);

          const bounds = {
            north: Math.max(...latitudes),
            south: Math.min(...latitudes),
            east: Math.max(...longitudes),
            west: Math.min(...longitudes)
          };
          const { north, south, west, east } = bounds;
          let googleMapBounds = new window.google.maps.LatLngBounds(
            { lat: south, lng: west },
            { lat: north, lng: east }
          );

          let centerLatLng = {
            lat: (north + south) / 2,
            lng: (east + west) / 2
          };

          // for desktops: apply correction to not show markers behind the slider
          if (!isMobileOnly) {
            const currScreenLatitudeCapacity = bounds.north - bounds.south;
            const fullScreenLatitudeCapacity =
              (currScreenLatitudeCapacity / (100 - sliderHeight)) * 100;
            googleMapBounds = new window.google.maps.LatLngBounds(
              {
                lat: north - fullScreenLatitudeCapacity,
                lng: west
              },
              { lat: north, lng: east }
            );
            centerLatLng = {
              lat: north - fullScreenLatitudeCapacity / 2,
              lng: (east + west) / 2
            };
          }
          setCenterLatLng(centerLatLng);

          setProjects(projects);

          mapInstance.fitBounds(googleMapBounds);
          setMapInstance(mapInstance);
          setIsMapReady(true);
        } else {
          setProjects([]);
        }
      }
    }, [
      isLoaded,
      fullList,
      previousList,
      mapInstance,
      isMapReady,
      setMapInstance,
      MarketplaceStateDispatch
    ]);

    const handleOutsideTouch = ({ target }) => {
      if (
        highlightedMarkerRef?.current &&
        !highlightedMarkerRef?.current?.contains(target) &&
        !projectCardWrapperRef?.current?.contains(target)
      ) {
        setHighlightedProject(null);
        setClickedMarker(null);
      }
    };

    useEffect(() => {
      window.addEventListener('touchstart', handleOutsideTouch);
      return () => window.removeEventListener('touchstart', handleOutsideTouch);
    }, []);

    // calculate projects currently visible in map
    useEffect(() => {
      // wait until map dragging is done
      if (isDraggingMap) return;
      if (!bounds) return;
      const visibleProjectsIDs = projects
        .filter(({ coords }) => coords && bounds?.contains(coords))
        .map((x) => x.objectId);
      setVisibleProjectsIDs(visibleProjectsIDs);
      if (!isEqual(visibleProjectsIDs, previousProjectsCurrVisibleInMap)) {
        setSliderDataRefreshed(false);
        resetSliderRelatedStates();
        setProjectsCurrVisibleInMap(visibleProjectsIDs);
        setTimeout(() => setSliderDataRefreshed(true), 100);
      }
    }, [
      bounds,
      projects,
      previousProjectsCurrVisibleInMap,
      isDraggingMap,
      resetSliderRelatedStates
    ]);

    useEffect(() => {
      if (!projects || projects.length === 0 || !projectsCurrVisibleInMap) {
        MarketplaceStateDispatch({
          type: 'setMarkerplaceData',
          payload: {
            mapVisibleProjectsMessage: ''
          }
        });
        return;
      }
      let message = localizer.formatString(
        localizer.currentlyVisibleProjects,
        projectsCurrVisibleInMap.length,
        projects.length
      );
      if (projectsCurrVisibleInMap.length !== projects.length) {
        message += localizer.zoomOutToSeeMore;
      }
      if (mapVisibleProjectsMessage === message) return;
      MarketplaceStateDispatch({
        type: 'setMarkerplaceData',
        payload: {
          mapVisibleProjectsMessage: message
        }
      });
    }, [
      projects,
      projectsCurrVisibleInMap,
      MarketplaceStateDispatch,
      mapVisibleProjectsMessage
    ]);

    const highlightActiveItems = useCallback(
      (firstActiveItemIndex, numberOfDisplayedItems) => {
        const indices = [];
        let i = -1;
        do {
          i++;
          indices.push(firstActiveItemIndex + i);
        } while (i < numberOfDisplayedItems);
        setActiveSliderItems(indices);
      },
      []
    );

    useEffect(() => {
      if (!isMobileOnly || !clickedMarker || !mapInstance) return;
      const projectCardHeight = projectCardWrapperRef.current?.offsetHeight;
      const mapHeight = mapWrapperRef.current?.offsetHeight;
      // calculate screen capacity in terms of latitude
      const mapInstanceBounds = mapInstance.getBounds();
      const parsedBounds = mapInstanceBounds.toJSON();
      const screenLatitudeCapacity = parsedBounds.north - parsedBounds.south;
      // shift initial center by half of the height taken by project card
      const correction =
        (screenLatitudeCapacity * (projectCardHeight / mapHeight)) / 2;

      if (!clickedMarker?.coords) return;

      const nextCenterCoords = { ...clickedMarker.coords };
      if (correction) {
        nextCenterCoords.lat = nextCenterCoords.lat - correction;
      }
      mapInstance.panTo(nextCenterCoords);
    }, [clickedMarker, mapInstance]);

    // if there is a chosen location provided put it in the center
    useEffect(() => {
      if (chosenLocation?.viewport && mapInstance) {
        setTimeout(() => {
          mapInstance.fitBounds(chosenLocation.viewport);
          setMapInstance(mapInstance);
        }, 100);
      }
    }, [chosenLocation, mapInstance, setMapInstance]);

    if (!isLoaded || loadError) {
      return null;
    }

    const onMapLoad = (instance) => {
      instance.streetView.setVisible(false);
      setMapInstance(instance);
      setTimeout(() => {
        setIsMapLoaded(true);
      }, 200);
    };

    // show highlighted marker
    let highlighted;
    const coords = highlightedProject?.coords || clickedMarker?.coords;
    if (coords)
      highlighted = (
        <StyledOverlayView
          position={coords}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <HighlightedMarker
            ref={highlightedMarkerRef}
            project={highlightedProject || clickedMarker}
            onHovered={(project) => {
              setHighlightedProject(project);
            }}
            onClick={(project) => {
              setClickedMarker(project);
            }}
          />
        </StyledOverlayView>
      );

    // map options reference:
    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const mapOptions = {
      fullscreenControl: false,
      clickableIcons: false,
      disableDefaultUI: true,
      styles: mapStyling,
      streetViewControl: !isMobileOnly,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      zoomControl: !isMobileOnly,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      mapTypeControl: false,
      mapTypeId: mapTypeId,
      controlSize: 40,
      draggableCursor: 'default',
      gestureHandling: 'greedy',
      keyboardShortcuts: false,
      minZoom: minZoomLevel
    };

    return (
      <AnimatePresence>
        <Wrapper
          ref={mapWrapperRef}
          initial={{ opacity: 0 }}
          animate={
            isMapLoaded && show
              ? { opacity: 1, zIndex: 3 }
              : { opacity: 0, zIndex: -1 }
          }
          exit={{ opacity: 0 }}
          onClick={(e) => (clickedMarker ? handleOutsideTouch(e) : null)}
          withmobilemenu={props.showMobileNavMenu}
        >
          <GoogleMap
            id={mapId}
            zoom={defaultZoomLevel}
            options={mapOptions}
            center={centerLatLng ?? { lat: 50.59, lng: 6.2 }} // border between Belgium and Germany
            mapContainerStyle={{
              height: '100%',
              width: '100%'
            }}
            onLoad={onMapLoad}
            onBoundsChanged={() => {
              if (!mapInstance) return;
              const newBounds = mapInstance.getBounds();
              if (!isEqual(bounds, newBounds)) {
                setBounds(mapInstance.getBounds());
              }
            }}
            onDragStart={() => setIsDraggingMap(true)}
            onDragEnd={() => setIsDraggingMap(false)}
          >
            <ChosenLocationMarker location={chosenLocation?.location} />
            <Markers
              projects={projects}
              onHoverMarker={(project) => {
                setHighlightedProject(project);
                setClickedMarker(null);
              }}
              onClickMarker={setClickedMarker}
              visibleProjectsIDs={visibleProjectsIDs}
            />
            {highlighted}

            <StreetViewPanorama
              position={centerLatLng ?? { lat: 46, lng: 46 }}
              visible={streetViewVisible}
              onVisibleChanged={() => {
                const isVisible = !!mapInstance?.streetView?.visible;
                setStreetViewVisible(isVisible);
                props.setMapStreetViewEnabled(isVisible);
              }}
              options={{
                disableDefaultUI: true,
                enableCloseButton: true
              }}
            />
          </GoogleMap>

          {(isMobileOnly ||
            (!isMobileOnly && customMapTypeControlPosition)) && (
            <ToggleMapTypeButton
              position={customMapTypeControlPosition}
              onClick={() => {
                const currMapType = mapInstance.getMapTypeId();
                const nextMapTypeIdx =
                  (mapTypes.findIndex((x) => x === currMapType) + 1) %
                  mapTypes.length;
                const nextMapType = mapTypes[nextMapTypeIdx];
                setMapTypeId(nextMapType);
                props.setSatelliteViewEnabled(nextMapType === 'satellite');
                mapInstance.setMapTypeId(nextMapType);
                setMapInstance(mapInstance);
              }}
            >
              <ToggleMapTypeIcon
                icon={[isMobileOnly ? 'fal' : 'fas', 'layer-group']}
                size="1x"
              />
            </ToggleMapTypeButton>
          )}

          {/* Show slider in desktop */}
          {!isMobileOnly && projects?.length > 0 && (
            <AnimatePresence>
              {activeContent === contentState.content &&
              show &&
              sliderDataRefreshed &&
              isMapLoaded ? (
                <MapViewSlider
                  projects={projects}
                  setHighlightedProject={setHighlightedProject}
                  onActiveItemsChanged={highlightActiveItems}
                  onSliderDrag={() => {
                    setHighlightedProject(null);
                  }}
                  onScrollSlider={() => {
                    setHighlightedProject(null);
                  }}
                  activeSliderItem={activeSliderItem}
                  highlightedMarker={clickedMarker}
                  sliderHeight={sliderHeight}
                  onlyVisibleItems={projectsCurrVisibleInMap}
                  updateCurrentUiState={() => {
                    MarketplaceStateDispatch({
                      type: 'updateSavedUiState',
                      payload: {
                        map: {
                          activeSliderItem: activeSliderItems[0],
                          visibleProjects: projectsCurrVisibleInMap,
                          bounds: mapInstance.getBounds(),
                          zoom: mapInstance.getZoom()
                        }
                      }
                    });
                  }}
                  onSliderLoaded={() => setSliderLoaded(true)}
                  visible={!streetViewVisible}
                  {...props}
                />
              ) : (
                <LoadingProjectCards>
                  <ShowcaseLoader color={'gray'} size={2} />
                </LoadingProjectCards>
              )}
            </AnimatePresence>
          )}

          {/* Show single project card in mobile */}
          <MobileSelectedProjectCard
            ref={projectCardWrapperRef}
            project={clickedMarker}
            shouldShowPrices={props.shouldShowPrices}
            shouldShowStatuses={props.shouldShowStatuses}
            vaultSettings={props.vaultSettings}
            mapInstance={mapInstance}
            sessionToken={props.sessionToken}
            onShowcaseSelected={props.onShowcaseSelected}
          />
        </Wrapper>
      </AnimatePresence>
    );
  }
);

MapView.propTypes = {};

MapView.defaultProps = {};

export default MapView;
