import React from 'react';

// Components
import { Marker } from '@react-google-maps/api';

// Helpers
import { getCurrentEnvironment } from 'helpers';
import { isMobileOnly } from 'react-device-detect';

// Styling
import { withTheme } from 'styled-components';

import NoThumbnailMarker from 'resources/images/NoThumbnailMarker.png';

const Markers = ({
  projects,
  onHoverMarker,
  onClickMarker,
  visibleProjectsIDs
}) => {
  const markers = projects
    .filter((project) => visibleProjectsIDs.includes(project?.objectId))
    .map((project, idx) => {
      if (!project.coords) return null;
      let imageTag = '#custom-marker-image';

      const imageUrl = project?.thumbnailUri
        ? `${getCurrentEnvironment().baseImageUrl}/h=60/${
            project?.thumbnailUri
          }${imageTag}`
        : NoThumbnailMarker;

      const image = {
        url: imageUrl,
        size: new window.google.maps.Size(60, 60),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(30, 30)
      };

      return (
        <Marker
          key={`${project.objectId}-clustered`}
          position={project.coords}
          icon={image}
          onMouseOver={() => onHoverMarker(project)}
          onClick={() => onClickMarker(project)}
        />
      );
    });

  return markers;
};

export default withTheme(Markers);
