import React, { forwardRef, useState } from 'react';
import { func, shape } from 'prop-types';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import NoThumbnailMarker from 'resources/images/NoThumbnailMarker.png';

// Helpers
import { motion } from 'framer-motion';
import { getCurrentEnvironment } from 'helpers';

// Styling
import styled, { keyframes } from 'styled-components';

const markerSize = 60;

const wave = keyframes`
  0% {
    width: 100%;
    height: 100%;
  }
  50% {
    width: 200%;
    height: 200%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
`;

const Marker = styled(motion.div)`
  position: absolute;
  top: -30px;
  left: -50%;
  z-index: 100;
  width: ${markerSize}px;
  height: ${markerSize}px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 150ms ease;
  transform-origin: center;
  cursor: pointer;
  &::before,
  &::after {
    position: absolute;
    z-index: 0;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.mainAccent};
    opacity: 0.3;
  }
  &::before {
    animation: ${wave} 3s linear infinite;
  }
  &::after {
    animation: ${wave} 4s linear 1s infinite;
  }
`;

const MarkerBody = styled.div`
  position absolute;
  z-index: 2;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Thumbnail = styled.img`
  postion: absolute;
  width: ${markerSize - 4}px;
  height: ${markerSize - 4}px;
  border: 2px solid ${({ theme }) => theme.showcaseWhite};
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  z-index: 2;
`;

const StyledLoader = styled(ShowcaseLoader)`
  margin: 0;
  flex-grow: 0;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HighlightedMarker = forwardRef(({ project, onHovered, onClick }, ref) => {
  const [loaded, setLoaded] = useState(false);

  // bring the highlighted marker in front of all others
  if (ref?.current) {
    ref.current.parentNode.parentNode.style.zIndex = 1000;
  }

  const imageUrl = project?.thumbnailUri
    ? `${getCurrentEnvironment().baseImageUrl}/h=60/${project?.thumbnailUri}`
    : NoThumbnailMarker;

  return (
    <Marker
      ref={ref}
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1 }}
      onMouseLeave={() => onHovered(null)}
      onClick={() => onClick(project)}
    >
      <MarkerBody>
        {!loaded && (
          <LoaderWrapper>
            <StyledLoader width={30} />
          </LoaderWrapper>
        )}
        <Thumbnail
          src={imageUrl}
          alt={`project thumbnail`}
          onLoad={() => setLoaded(true)}
        />
      </MarkerBody>
    </Marker>
  );
});

HighlightedMarker.propTypes = {
  project: shape({}),
  onHovered: func.isRequired,
  onClick: func.isRequired
};

HighlightedMarker.defaultProps = {
  project: null,
  onHovered: () => {},
  onClick: () => {}
};

export default HighlightedMarker;
