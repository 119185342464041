import * as React from 'react';

function SvgShareIcon(props) {
  return (
    <svg width={15} height={15} {...props}>
      <path
        fill="currentColor"
        d="M10.454 8.977a2.2 2.2 0 00-1.526.61L6.66 8.166a2.229 2.229 0 000-1.334l2.268-1.42a2.216 2.216 0 10-.588-.94l-2.268 1.42a2.216 2.216 0 100 3.212l2.268 1.42a2.216 2.216 0 102.114-1.548zm0-6.277a1.108 1.108 0 110 2.216 1.108 1.108 0 010-2.216zM4.546 8.608a1.108 1.108 0 110-2.216 1.108 1.108 0 010 2.216zm5.908 3.692a1.108 1.108 0 110-2.216 1.108 1.108 0 010 2.216z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgShareIcon;
