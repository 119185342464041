import React, { useState, useEffect } from 'react';

// Components
import Router from 'Router';
import CookieConsent from 'react-cookie-consent';
import { Checkbox } from 'components/ui';

// Helpers
import { getCurrentEnvironment } from 'helpers';
import localizer from 'localization/localizer';
import { useAuthState } from 'stores/AuthStore';

// Styling
import styled, { withTheme } from 'styled-components';

const MainBannerWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const ExternalLinkButton = styled.a`
  font-size: 12px;
  margin-right: 10px;
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
`;

const StatisticsButton = styled.div`
  display: flex;
  flex-flow: row;
  margin-right: 10px;
  align-items: center;
`;

// The main app component 2
const App = (props) => {
  const { AuthState } = useAuthState();
  const { user } = AuthState;

  const [useStatistics, setUseStatistics] = useState(true);
  const [isEmbedded, setIsEmbedded] = useState(false);

  // Check if the showcase is embedded
  useEffect(() => {
    setIsEmbedded(window.self !== window.top);
  }, []);

  const showCookieConsent = !isEmbedded && !user.sessionToken && !user.vaultId;

  return (
    <div>
      {showCookieConsent && (
        <CookieConsent
          location="top"
          buttonText="Accept"
          cookieName="CookiePromptoShowcaseConsent"
          sameSite="none"
          style={{ background: props.theme.primary900, zIndex: 1000 }}
          buttonStyle={{
            background: props.theme.accent,
            fontSize: '13px',
            marginRight: '50px'
          }}
          onAccept={() => {
            if (!useStatistics) {
              // TODO: should internal tracking be disabled if consent was not given?
            }
          }}
        >
          <MainBannerWrapper>
            <div>{localizer.cookieConsentMessage}</div>
            <ButtonWrapper>
              <StatisticsButton>
                <Checkbox
                  size={2}
                  tickSize={1}
                  isChecked={true}
                  onChecked={() => {
                    setUseStatistics(true);
                  }}
                  onUnchecked={() => {
                    setUseStatistics(false);
                  }}
                  colorObject={{
                    backgroundColor: props.theme.accent,
                    borderColor: props.theme.accent,
                    tickColor: props.theme.showcaseBlack
                  }}
                />
                <div>{localizer.statistics}</div>
              </StatisticsButton>
              <ExternalLinkButton
                onClick={() => {
                  window.open(
                    getCurrentEnvironment().privacyPolicyUrl,
                    '_blank'
                  );
                }}
              >
                {localizer.privacyPolicy}
              </ExternalLinkButton>
              <ExternalLinkButton
                onClick={() => {
                  window.open(getCurrentEnvironment().termsOfService, '_blank');
                }}
              >
                {localizer.termsOfService}
              </ExternalLinkButton>
            </ButtonWrapper>
          </MainBannerWrapper>
        </CookieConsent>
      )}
      <Router />
    </div>
  );
};
export default withTheme(App);
