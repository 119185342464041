import React, { useState, useEffect, Fragment } from 'react';
import { bool, func, shape } from 'prop-types';

// Helpers
import localizer from 'localization/localizer';
import { motion } from 'framer-motion';
import qs from 'query-string';
import urlJoin from 'url-join';
import { getCurrentEnvironment } from 'helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import { Misc } from '@prompto-api';

// Styling
import styled from 'styled-components';

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ViewerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ viewerActive }) => (viewerActive ? 10 : 1)};
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const TapAnywhere = styled.p`
  font-family: 'proxima-nova', sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 0;
`;

const View360 = styled.p`
  font-family: 'proxima-nova', sans-serif;
  font-size: 18px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  margin: 0;
  padding-top: 13px;
  padding-bottom: 7px;
`;

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const AnimationWrapper = styled(motion.div)`
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  user-select: none;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding: 24px 60px;
  width: 100%;
  height: 100%;
`;

const CoverImage = styled.img`
  position: absolute;
  left: -5%;
  top: -5%;
  height: 110%;
  width: 110%;
  object-fit: cover;
  pointer-events: none;
  filter: ${({ blurNeeded }) => (blurNeeded ? '   blur(30px);' : 'none')};
`;

const Album360Content = ({ data, autoplay }) => {
  const [viewerActive, setViewerActive] = useState(false);
  const [iconActive, setIconActive] = useState(true);
  const [coverImage, setCoverImage] = useState();
  const [blurNeeded, setBlurNeeded] = useState(false);

  useEffect(() => {
    if (autoplay) {
      setViewerActive(true);
      setIconActive(false);
    }
  }, [autoplay]);

  // Fetch tour album
  useEffect(() => {
    if (data?.contentUri) {
      const code = qs.parse(data.contentUri.split('?')[1], {
        parseBooleans: true,
        parseNumbers: true
      }).id;

      let transform = 'h1200';

      if (data?.thumbnailUri) {
        setCoverImage(
          urlJoin(
            getCurrentEnvironment().base360ImageUrl,
            transform,
            data.thumbnailUri
          )
        );
      } else {
        Misc.getAlbumByCode(code).then(({ data }) => {
          setBlurNeeded(true);
          setCoverImage(`data:image/jpeg;base64, ${data.image}`);
        });
      }
    }
  }, [data]);

  // 360 viewer
  let viewer = (
    <ViewerWrapper viewerActive={viewerActive}>
      <FullScreenIframe
        title={'Prompto 360'}
        src={data.contentUri}
        allowFullScreen
      ></FullScreenIframe>
    </ViewerWrapper>
  );

  // Placeholder
  const placeholder = (
    <PlaceholderWrapper
      onClick={() => {
        setIconActive(false);
        setTimeout(() => {
          setViewerActive(true);
        }, 500);
      }}
    >
      <CoverImage
        src={coverImage}
        blurNeeded={blurNeeded}
        style={{ height: '110%' }}
        draggable={false}
        alt="360 tour"
      />
      <AnimationWrapper
        key="icon"
        animate={iconActive ? 'visible' : 'hidden'}
        variants={iconVariants}
      >
        <FontAwesomeIcon icon={['fal', 'street-view']} size="lg" />
        <Fragment>
          <View360>{localizer.view360}</View360>
          <TapAnywhere>{localizer.tapAnywhere}</TapAnywhere>
        </Fragment>
      </AnimationWrapper>
    </PlaceholderWrapper>
  );

  return (
    <MainWrapper>
      {placeholder}
      {viewer}
    </MainWrapper>
  );
};

Album360Content.propTypes = {
  autoplay: bool,
  data: shape({}),
  showUI: func
};

Album360Content.defaultProps = {
  autoplay: false,
  data: {},
  showUI: () => {}
};

export default Album360Content;

const iconVariants = {
  visible: isMobile
    ? {
        opacity: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }
    : {
        opacity: 1,
        display: 'block',
        zIndex: 11,
        bottom: 170
      },
  hidden: isMobile
    ? {
        opacity: 0
      }
    : {
        opacity: 0,
        zIndex: 11,
        transitionEnd: {
          display: 'none'
        },
        bottom: -100
      }
};
