import React from 'react';

// Helpers
import { useParams } from 'react-router-dom';

// Components
import { PdfViewer } from '@prompto-ui';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

// we need to modify url to retrieve back "%2F" signs
// which were lost by decodeURIComponent()
// The goal is to get an url like this:
// https://www.googleapis.com/download/storage/v1/b/devassetsvxgi.vr-tual.media/o/vaults%2Fh5zNR5hVnf%2Funit%2FXMJn5XrKC1%2Fcollection%2Fg0EnIijEeK%2Fcc2cb49d-0d0c-49af-93fd-3bbfaf648e2c.pdf?generation=1630329385335596&alt=media
// Will use '/0/ as divider
const getCorrectUrl = (url) => {
  const [goodPart, partToBeFixed] = url.split('/o/');
  return `${goodPart}/o/${partToBeFixed.replaceAll('/', '%2F')}`;
};

const DocumentViewerPage = () => {
  const params = useParams();

  const viewerProps =
    params?.objectId && params?.contentUri
      ? {
          objectId: decodeURIComponent(params.objectId),
          url: decodeURIComponent(params.contentUri)
        }
      : undefined;

  if (viewerProps) {
    return (
      <Wrapper>
        <PdfViewer
          {...viewerProps}
          url={getCorrectUrl(viewerProps.url)}
          onLoad={() => {}}
          onToggleSidebar={() => {}}
        />
      </Wrapper>
    );
  }

  return <p>File is empty</p>;
};

export default DocumentViewerPage;
