import React, { useRef, useEffect, useState } from 'react';
import { string, number, array, shape, bool } from 'prop-types';

// Styles
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

const GridItem = styled(motion.li)`
  margin: 5px;
  text-decoration: none;
  list-style: none;
  ${({ styles }) => styles}
`;

const GridDivItem = styled(motion.div)`
  ${({ styles }) => styles}
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
  ${({ styles }) => styles}
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
  ${({ styles }) => styles}
`;

const HorizontalMasonry = (props) => {
  const {
    rows,
    children,
    enableLayoutTransition,
    layoutTransition,
    gridStyles,
    gridItemStyles,
    gridRowStyles
  } = props;
  const [mappedChildren, setMappedChildren] = useState([]);
  const grid = useRef();

  const defaultTransition = {
    type: 'spring',
    damping: 20,
    stiffness: 300
  };

  useEffect(() => {
    let rowArr = [];
    for (let i = 0; i < rows; i++) {
      rowArr.push([]);
    }

    // Map the children to fill up the space horizontally in this pattern
    // 0  2  4  6  8
    // 1  3  5  7  9
    setMappedChildren(
      children.reduce((parent, row, index) => {
        parent[index % rows].push(row);
        return parent;
      }, rowArr)
    );
  }, [children, rows]);

  const createChildElement = (child) => {
    const childProps = {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      key: child.key
    };

    if (enableLayoutTransition) {
      return (
        <GridItem
          {...childProps}
          styles={gridItemStyles}
          layoutTransition={layoutTransition || defaultTransition}
        >
          {child}
        </GridItem>
      );
    } else {
      return (
        <GridDivItem {...childProps} styles={gridItemStyles}>
          {child}
        </GridDivItem>
      );
    }
  };

  const createRows = () =>
    mappedChildren.map((row, rowIndex) => (
      <GridRow key={`row${rowIndex}`} styles={gridRowStyles}>
        {row.map((child) => createChildElement(child))}
      </GridRow>
    ));

  return (
    <Grid ref={grid} key="grid" styles={gridStyles}>
      <AnimatePresence>{createRows()}</AnimatePresence>
    </Grid>
  );
};

HorizontalMasonry.propTypes = {
  rows: number.isRequired,
  className: string,
  gridRowStyles: string,
  gridItemStyles: string,
  children: array.isRequired,
  enableLayoutTransition: bool,
  layoutTransition: shape({})
};

HorizontalMasonry.defaultProps = {
  className: null,
  enableLayoutTransition: false,
  layoutTransition: null
};

export default HorizontalMasonry;
