import React, { useState, useEffect, useRef } from 'react';
import { number, func, shape } from 'prop-types';

// Helpers
import {
  motion,
  useMotionValue,
  useAnimation,
  useDragControls
} from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';

// Styles
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  ${({ styles }) => styles}
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 10px 0;
`;

const Track = styled.div`
  width: ${isMobileOnly ? 'calc(100vw - 200px)' : '350px'};
  height: 1px;
  position: relative;
  background: ${({ theme }) => theme.gray400};
`;

const Thumb = styled(motion.div)`
  position: absolute;
  width: ${({ barWidth }) => barWidth}px;
  height: 3px;
  background: ${({ theme }) => theme.primary400};
  left: 0;
  top: -1px;
  border-radius: 1px;
  ${isMobileOnly &&
  `
  height: 6px;
  top: -2px;
  border-radius: 3px;
`}
`;

const Scrollbar = ({
  styles,
  trackWidth,
  containerWidth,
  activeItem,
  itemCount,
  moveActiveItem
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const x = useMotionValue(0);
  const controls = useAnimation();
  const dragControls = useDragControls();

  const barRef = useRef();
  const [barWidth, setBarWidth] = useState(0);
  //const [barOffset, setBarOffset] = useState(0);

  useEffect(() => {
    const refWidth = barRef.current?.offsetWidth;
    setBarWidth(refWidth * (containerWidth / trackWidth));
    controls.start({
      x: Math.floor(refWidth * (activeItem / itemCount)),
      transition: {
        type: 'tween'
      }
    });
  }, [controls, trackWidth, containerWidth, activeItem, itemCount]);

  const startDrag = (event) => {
    dragControls.start(event, { snapToCursor: true });
  };

  const updateActiveItem = (pageX) => {
    const refWidth = barRef.current?.offsetWidth;
    const refOffset = barRef.current?.offsetLeft;
    const xOffset = pageX - (refOffset - refWidth / 2);
    const result = xOffset / refWidth;
    const newActiveItem = Math.max(Math.floor(result * itemCount), 0);
    moveActiveItem(newActiveItem, 1, true);
  };

  const onClick = (e) => {
    if (!isDragging) {
      updateActiveItem(e.pageX);
    }
  };

  // There is no need for scrolling in this case
  if (containerWidth === trackWidth) {
    return null;
  }

  return (
    <Wrapper
      styles={styles}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onMouseDown={startDrag}
      ref={barRef}
    >
      <Container onClick={onClick}>
        <Track>
          <Thumb
            style={{ x }}
            animate={controls}
            dragControls={dragControls}
            barWidth={barWidth}
            drag={'x'}
            dragConstraints={barRef}
            dragElastic={false}
            onDragStart={() => {
              setIsDragging(true);
            }}
            onDragEnd={(e) => {
              updateActiveItem(e.pageX);
              setTimeout(() => {
                setIsDragging(false);
              }, 10);
            }}
          />
        </Track>
      </Container>
    </Wrapper>
  );
};

Scrollbar.propTypes = {
  style: shape({}),
  trackWidth: number,
  containerWidth: number,
  activeItem: number,
  itemCount: number,
  moveActiveItem: func
};

Scrollbar.defaultProps = {
  style: null,
  trackWidth: 0,
  containerWidth: 0,
  activeItem: 0,
  itemCount: 0,
  moveActiveItem: () => {}
};

export default Scrollbar;
