import React from 'react';
import styled from 'styled-components';

//Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Helpers
import { motion, AnimatePresence } from 'framer-motion';
import localizer from 'localization/localizer';

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 1px solid ${(props) => props.theme.whitePure};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  pointer-events: none;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.whitePure};
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 2px;
    height: 23px;
    background-color: ${(props) => props.theme.whitePure};
  }
`;

const CTAText = styled.div`
  position: absolute;
  bottom: calc(100% + 23px);
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: no-wrap;
  border-radius: 5px;
  background-color: ${(props) => props.theme.whitePure};
  font-size: 1rem;
  color: ${(props) => props.theme.primary300};
  width: 182px;
  height: 37px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
`;

const HomeIcom = styled(FontAwesomeIcon)`
  margin: 0 4px;
`;

const UnitSearchCTA = ({ show }) => {
  return (
    <AnimatePresence>
      {show && (
        <Wrapper
          initial={{ opacity: 1, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 5 }}
          transition={{ duration: 0.25 }}
        >
          <CTAText>
            {localizer.formatString(
              localizer.searchForHome,
              <HomeIcom icon={['fas', 'home']} size="1x" />
            )}
          </CTAText>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default UnitSearchCTA;
