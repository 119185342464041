import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import UnitStateBadge from 'components/unit/UnitStateBadge';
import ContactUs from 'components/share/ContactUs/ContactUs';
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import FavoriteUnitButton from 'components/share/FavoriteUnitButton';
import ContactUsCard from './ContactUsCard';

// Helpers
import { isTablet, isIPhone13 } from 'react-device-detect';
import currencySymbolMap from 'currency-symbol-map';
import {
  unitStatesNotArchived,
  shouldShowUnitPrice
} from 'helpers/units/VmUnitHelper';
import localizer from 'localization/localizer';
import { motion, AnimatePresence } from 'framer-motion';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import { useUiState } from 'stores/UiStore';
import {
  isValidUrl,
  getAbsoluteUrl,
  capitalize,
  getFieldIcon,
  displayLocalizedValue,
  getUnitPrice
} from 'helpers';
import {
  useGetUnitImagePreview,
  useShowUnitGeneralFields,
  useGetUnitCustomFields
} from 'helpers/customHooks';

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const buttonSectionWidth = 201;
const imagePreviewWidth = 95;

const UnitCardWrapper = styled(motion.div)`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  box-sizing: border-box;
`;

const UnitCard = styled(motion.div)`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.whitePure};
  cursor: ${(props) => (props.hovered ? 'pointer' : 'default')};
`;

const UnitDetails = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.horizontalLayout.gridTemplateColumns};
  align-items: center;
  margin-top: 8px;
  height: 40px;
`;

const UnitBasicInfoWrapper = styled.div`
  width: ${(props) =>
    props.withImagePreview
      ? `calc(100% - ${imagePreviewWidth + buttonSectionWidth}px)`
      : `calc(100% - ${buttonSectionWidth}px)`};
  ${({ embedded, horizontalLayout }) =>
    embedded
      ? `
    display: flex;
    flex-direction: column;
    min-height: 50px;
    justify-content: space-between;
  `
      : `
  display: grid;
  grid-template-columns: ${horizontalLayout.gridTemplateColumns};
  align-items: center;

  `}
`;

const UnitTitleAndType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UnitInfo = styled.div`
  font-size: 14px;
  color: black;
  white-space: nowrap;
  padding-right: 10px;
  box-sizing: border-box;
  word-break: break-all;
`;

const UnitTitle = styled(UnitInfo)`
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0;
  white-space: normal;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const UnitPrice = styled(UnitInfo)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
  padding-right: 0;
  padding-left: ${({ embedded }) => (embedded ? '0' : '15px')};
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 5px;
  color: ${(props) => props.theme.primary100};
`;

const Price = styled(UnitInfo)`
  font-weight: 700;
  padding-left: 5px;
  color: inherit;
  white-space: nowrap;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnitMetaInfo = styled.div`
  flex-basis: ${(props) => props.flexBasis}%;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const UnitMetaIcon = styled(FontAwesomeIcon)`
  color: black;
  opacity: 0.5;
  margin-right: 5px;
`;

const UnitStateTag = styled(UnitStateBadge)`
  position: absolute;
  z-index: 1;
  left: -10px;
  top: -10px;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) => props.theme.showcaseWhite};
  border-radius: 20px;
  min-width: 5rem;
  height: 26px;
`;

const MoreInfoButton = styled.div`
  width: 120px;
  height: 40px;
  box-sizing: border-box;
  margin-left: 20px;
  border: 1px solid ${(props) => props.theme.gray200};
  border-radius: 2px;
  color: ${(props) => props.theme.primary100};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const ContactUsButtonWrapper = styled.div`
  width: 120px;
  height: 40px;
  margin-left: 20px;
  flex-shrink: 0;
`;

const Divider = styled.div`
  width: 1px;
  height: 10px;
  background-color: rgba(0, 0, 0, ${(props) => (props.visible ? '0.1' : '0')});
`;

const DividerWithMargin = styled(Divider)`
  margin-right: 20px;
  height: 100%;
  background-color: ${(props) => props.theme.gray200};
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
`;

const Toast = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${(props) => props.theme.warning};
  border-radius: 17px;
  width: fit-content;
  height: 34px;
  padding: 0 25px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) => props.theme.whitePure};
`;

const ToastIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const UnitCardContainer = styled.div`
  padding: 16px 20px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100px;
`;

const CustomFieldsWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.gray200};
  padding: 15px 15px 15px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 20px;
`;

const CustomFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props) => props.flexBasis}%;
`;

const StyledCustomFieldIcon = styled(FontAwesomeIcon)`
  margin-top: 3px;
  font-size: 16px;
  margin-right: 5px;
  color: ${(props) => props.theme.primary100};
`;

const StyledCustomFieldValue = styled.p`
  margin: 0;
  padding: 0 10px 5px 0;
  box-sizing: border-box;
  word-break: break-all;
`;

const UnitType = styled.div`
  height: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const StyledUnitTypeIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 5px;
  color: ${(props) => props.theme.primary100};
`;

const UnitGenericFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const PreviewImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 15px;
  flex-shrink: 0;
`;

const LoaderWrapper = styled(motion.div)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${buttonSectionWidth}px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const UnitListCard = ({
  index,
  unit,
  hoveredUnit,
  onHoverUnit,
  onOpenUnitPage,
  horizontalLayout,
  fallbackContent
}) => {
  const [customFields, setCustomFields] = useState([]);

  const { ProjectState } = useProjectState();
  const { AuthState } = useAuthState();

  const { showcaseConfiguration, vault } = ProjectState;
  const {
    embededUnitFormConfiguration,
    showUnitContactUsButton,
    showUnitExternalLink,
    showUnitPreviewImage,
    showUnitCustomFields,
    hideFavouriteOptions
  } = showcaseConfiguration;

  // UI state
  const { UiState } = useUiState();
  const { isEmbeddedShowcase } = UiState;

  const [isInvalidUrlErrorShown, setInvalidUrlErrorShown] = useState(false);
  const [previewImageLoaded, setPreviewImageLoaded] = useState(false);

  const { previewImageUrl, isPreparingURL } = useGetUnitImagePreview(
    unit,
    fallbackContent,
    `h=80`
  );

  const {
    showPrice,
    showSurface,
    showBedrooms,
    showBathrooms,
    showPriceForProject,
    showPriceForUnitType
  } = useShowUnitGeneralFields(false, null, [unit]);
  const showUnitGeneralFields = showSurface || showBedrooms || showBathrooms;

  const toggleErrorMessage = useCallback(() => {
    setInvalidUrlErrorShown(true);
    const timer = setTimeout(() => {
      setInvalidUrlErrorShown(false);
      clearTimeout(timer);
    }, 3000);
  }, []);

  const showUnitPrice = shouldShowUnitPrice(
    showcaseConfiguration,
    AuthState,
    unit?.state
  );

  const showUnitState = showcaseConfiguration?.showUnitState ?? true;

  const showContactUsButton =
    showUnitContactUsButton &&
    embededUnitFormConfiguration?.showContactUsFormAsGeneric !== true;

  const currencySymbol = currencySymbolMap(
    vault?.vaultSettings?.defaultCurrencyCode || 'EUR'
  );
  const unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;

  const handleUnitClick = useCallback(
    (unitItem) => {
      if (showUnitExternalLink) {
        if (!unitItem?.unitMetadata?.externalLink) return;
        if (['IN_OPTION', 'SOLD'].includes(unitItem?.state)) return;
        if (isValidUrl(unitItem.unitMetadata.externalLink)) {
          window.open(getAbsoluteUrl(unitItem.unitMetadata.externalLink));
        } else {
          toggleErrorMessage();
        }
      } else {
        onOpenUnitPage(unitItem);
      }
    },
    [showUnitExternalLink, onOpenUnitPage, toggleErrorMessage]
  );

  const renderImagePreviewOrPlaceholder = useCallback(() => {
    if (previewImageUrl) {
      return (
        <PreviewImageWrapper>
          <AnimatePresence>
            {!previewImageLoaded ||
              (isPreparingURL && (
                <LoaderWrapper
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <ShowcaseLoader />
                </LoaderWrapper>
              ))}
          </AnimatePresence>
          <PreviewImage
            src={previewImageUrl}
            onLoad={() => {
              setPreviewImageLoaded(true);
            }}
          />
        </PreviewImageWrapper>
      );
    }
    return (
      <PreviewImageWrapper>
        <LoaderWrapper>
          <ShowcaseLoader />
        </LoaderWrapper>
      </PreviewImageWrapper>
    );
  }, [previewImageUrl, previewImageLoaded, isPreparingURL]);

  const renderCustomFields = useCallback(() => {
    let flexBasis = 100;
    if (unit?.customFieldValues?.length === 2) {
      flexBasis = 50;
    } else if (unit?.customFieldValues?.length > 2) {
      flexBasis = 33.3;
    }

    return customFields?.map((field, idx) => {
      const needDivider =
        !!(customFields?.length === 2 && idx !== 0) ||
        !!(customFields?.length > 2 && idx % 3);
      return (
        <CustomFieldWrapper key={idx} first={idx === 0} flexBasis={flexBasis}>
          {needDivider && <DividerWithMargin />}
          {field.icon && (
            <StyledCustomFieldIcon icon={['far', field.icon]} size="1x" />
          )}
          <StyledCustomFieldValue>
            {!!field.title ? `${capitalize(field.title)}: ` : ''} {field.value}
          </StyledCustomFieldValue>
        </CustomFieldWrapper>
      );
    });
  }, [customFields, unit]);

  const renderGeneralFields = (showcaseConfiguration) => {
    const metaInfo = [];
    if (showSurface) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'surface', 'fal'),
        info: `${unit.unitMetadata?.surface} m²`
      });
    }
    if (showBedrooms) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBedrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBedrooms
      });
    }
    if (showBathrooms) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBathrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBathrooms
      });
    }
    const flexBasis = 100 / metaInfo.length;
    return (
      <UnitGenericFieldsWrapper>
        {metaInfo.map((item, idx) => (
          <UnitMetaInfo key={idx} flexBasis={flexBasis}>
            {!!item.icon && <UnitMetaIcon icon={item.icon} size="1x" />}
            <UnitInfo>{item.info}</UnitInfo>
          </UnitMetaInfo>
        ))}
      </UnitGenericFieldsWrapper>
    );
  };

  const unitBasicInfo = (
    <>
      {isEmbeddedShowcase && (
        <UnitBasicInfoWrapper
          withImagePreview={showcaseConfiguration?.showUnitPreviewImage}
          embedded={true}
        >
          <UnitTitleAndType>
            <UnitTitle>{unit.title}</UnitTitle>
            {unit?.unitType && (
              <UnitType>
                {unit?.unitType?.icon && (
                  <StyledUnitTypeIcon
                    icon={['far', unit.unitType.icon]}
                    size="1x"
                  />
                )}
                {displayLocalizedValue(unit?.unitType?.name?.textMap)}
              </UnitType>
            )}
          </UnitTitleAndType>
          <UnitDetails horizontalLayout={horizontalLayout}>
            <UnitPrice embedded={true}>
              <StyledPriceIcon
                icon={getFieldIcon(showcaseConfiguration, 'price', 'far')}
                size="1x"
              />
              <Price>
                {showUnitPrice && showPrice ? unitPrice : localizer.onRequest}
              </Price>
            </UnitPrice>
            {showUnitGeneralFields && (
              <>
                <Divider visible={true} />
                {renderGeneralFields(showcaseConfiguration)}
              </>
            )}
          </UnitDetails>
        </UnitBasicInfoWrapper>
      )}
      {!isEmbeddedShowcase && (
        <UnitBasicInfoWrapper
          withImagePreview={showcaseConfiguration?.showUnitPreviewImage}
          horizontalLayout={horizontalLayout}
        >
          <UnitTitleAndType>
            <UnitTitle>{unit.title}</UnitTitle>
            {unit?.unitType && (
              <UnitType>
                {unit?.unitType?.icon && (
                  <StyledUnitTypeIcon
                    icon={['far', unit.unitType.icon]}
                    size="1x"
                  />
                )}
                {displayLocalizedValue(unit?.unitType?.name?.textMap)}
              </UnitType>
            )}
          </UnitTitleAndType>
          {showPriceForProject && (
            <UnitPrice>
              {showPriceForUnitType !== false && (
                <>
                  <StyledPriceIcon
                    icon={getFieldIcon(showcaseConfiguration, 'price', 'far')}
                    size="1x"
                  />

                  <Price>
                    {showUnitPrice ? unitPrice : localizer.onRequest}
                  </Price>
                </>
              )}
            </UnitPrice>
          )}
          {showUnitGeneralFields && (
            <>
              <Divider visible={true} />
              {renderGeneralFields(showcaseConfiguration)}
            </>
          )}
        </UnitBasicInfoWrapper>
      )}
    </>
  );

  const { customFields: unitCustomFields } = useGetUnitCustomFields(unit);

  useEffect(() => {
    if (unitCustomFields) {
      const filteredFields =
        unitCustomFields?.filter((field) => field.isVisibleInUnitList) ?? [];
      setCustomFields(filteredFields);
    }
  }, [unitCustomFields]);

  if (unit.type === 'contact-us-card') {
    return <ContactUsCard />;
  }

  return (
    <>
      <UnitCardWrapper key={index}>
        <UnitCard
          hovered={unit.objectId === hoveredUnit?.objectId}
          whileHover={{ scale: 1.03 }}
          onClick={() => {
            if (!isTablet && !isIPhone13) {
              handleUnitClick(hoveredUnit);
            }
          }}
          onTouchEnd={() => {
            if (hoveredUnit === unit) {
              handleUnitClick(hoveredUnit);
            }
            setTimeout(() => {
              onHoverUnit(unit);
            }, [100]);
          }}
          onHoverStart={() => {
            if (!isTablet && !isIPhone13) {
              onHoverUnit(unit);
            }
          }}
          horizontalLayout={horizontalLayout}
        >
          <UnitCardContainer>
            {showUnitState && (
              <UnitStateTag unitState={unit?.state} unitFlow={unit?.unitFlow} />
            )}
            {showUnitPreviewImage && renderImagePreviewOrPlaceholder()}

            {unitBasicInfo}

            <ButtonSection>
              {!hideFavouriteOptions && (
                <FavoriteUnitButton
                  unit={unit}
                  wrapperStyles={`margin-left: 20px`}
                />
              )}
              {showContactUsButton ? (
                <ContactUsButtonWrapper>
                  <ContactUs unit={unit} location="Units list (desktop)" />
                </ContactUsButtonWrapper>
              ) : (
                <MoreInfoButton>
                  {capitalize(localizer.moreInfo)}
                </MoreInfoButton>
              )}
            </ButtonSection>
          </UnitCardContainer>
          {showUnitCustomFields &&
            unit?.customFieldValues?.length > 0 &&
            customFields?.length > 0 && (
              <CustomFieldsWrapper>{renderCustomFields()}</CustomFieldsWrapper>
            )}
        </UnitCard>
      </UnitCardWrapper>
      {isInvalidUrlErrorShown && (
        <Toast
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: -90 }}
          exit={{ opacity: 0, y: 0 }}
        >
          <ToastIcon icon={['far', 'ban']} size="sm" />
          {localizer.wrongUrlWarning}
        </Toast>
      )}
    </>
  );
};

UnitListCard.propTypes = {
  /** vmUnit object that will get its information displayed in this card */
  unit: PropTypes.shape({
    title: PropTypes.string,
    unitPrice: PropTypes.shape({
      price: PropTypes.number
    }),
    state: PropTypes.oneOf(unitStatesNotArchived),
    unitMetadata: PropTypes.shape({
      numberOfBedrooms: PropTypes.number,
      numberOfBathrooms: PropTypes.number
    })
  }).isRequired,
  /** Style properties that define the way the unit card should be layed out */
  horizontalLayout: PropTypes.shape({
    paddingLeft: PropTypes.string,
    paddingRight: PropTypes.string,
    gridTemplateColumns: PropTypes.string
  }).isRequired
};

export default UnitListCard;
