import React from 'react';

// Styling
import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { useNavigate } from 'react-router-dom';
import { useProjectState } from 'stores/ProjectStore';
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';

const SublinkWrapper = styled.li`
  padding: 13px 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${(props) => props.theme.showcaseBlack};
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.gray200};

  $:last-of-type {
    box-shadow: none;
  }
`;

const IconWrapper = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.theme.primary100};
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${(props) => props.theme.primary100};
  margin-left: auto;
  margin-right: 8px;
`;

const SublinkText = styled.span`
  padding: 0 8px 0 12px;
  word-break: break-all;
`;

const Sublink = ({ item }) => {
  // Project store
  const { ProjectState } = useProjectState();
  const { shareCode } = ProjectState;

  const navigate = useNavigate();
  const handleClick = () => {
    if (item.link) {
      navigate(`/${shareCode}/${item.link}${window.location.search}`);
    } else if (item.action) {
      item.action();
    } else {
      return;
    }
  };
  return (
    <SublinkWrapper onClick={handleClick}>
      <IconWrapper icon={item.icon} size="1x" />
      <SublinkText>{capitalize(localizer[item.text])}</SublinkText>
      {(item.link || item.withArrow) && (
        <ChevronIcon icon={['far', 'chevron-right']} size="1x" />
      )}
    </SublinkWrapper>
  );
};

export default Sublink;
