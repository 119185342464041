import React, { useReducer, createContext, useContext } from 'react';

export const Context = createContext();

const MapStore = (props) => {
  const [MapState, MapStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'update':
          return {
            ...state,
            ...action.payload
          };
        default:
          return state;
      }
    },
    {
      mapMode: 'roadmap'
    }
  );
  return (
    <Context.Provider value={{ MapState, MapStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useMapState = () => useContext(Context);

export default MapStore;
