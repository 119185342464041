import { useEffect, useState, useCallback } from 'react';

// Helpers
import { fetchSettingsFromURL } from 'helpers';
import { useProjectState } from 'stores/ProjectStore';

const MAP_MENUBAR_KEYS_TO_SHOWCASE_SETTINGS = {
  tour: 'showImageTourMenu',
  map: 'showMapMenu',
  units: 'showUnitListMenu',
  resources: 'showResourceMenu'
};

export const MENUBAR_CONFIGURABLE_OPTIONS = [
  'tour',
  'resources',
  'map',
  'units',
  'turntable'
];

const useMenuOptionVisibilityCheck = () => {
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  const [customMenuParams, setCustomMenuParams] = useState(null);

  useEffect(() => {
    const qParams = fetchSettingsFromURL();
    const customMenuParam = qParams['customMenu'];
    if (customMenuParam && typeof customMenuParam === 'string') {
      const paramsArray = customMenuParam
        .toLowerCase()
        .split('-')
        .filter((param) => MENUBAR_CONFIGURABLE_OPTIONS.includes(param));
      setCustomMenuParams(paramsArray);
    } else {
      setCustomMenuParams([]);
    }
  }, []);

  const shouldShowMenuOption = useCallback(
    (option) => {
      const optionSettingValue =
        showcaseConfiguration?.[MAP_MENUBAR_KEYS_TO_SHOWCASE_SETTINGS[option]];
      const shouldShowOptionFromConfiguration =
        optionSettingValue === undefined ? true : optionSettingValue;

      // customMenu param has priority over the showcase settings
      if (customMenuParams.length) {
        return customMenuParams.includes(option);
      } else {
        return shouldShowOptionFromConfiguration;
      }
    },
    [customMenuParams, showcaseConfiguration]
  );

  return { customMenuParams, shouldShowMenuOption };
};

export default useMenuOptionVisibilityCheck;
