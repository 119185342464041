import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import PropTypes from 'prop-types';

// Helpers
import useHubspotForm from 'helpers/customHooks/useHubspotForm';
import localizer from 'localization/localizer';
import { useAuthState } from 'stores/AuthStore';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import { Lead } from '@prompto-api';

// Styling
import styled from 'styled-components';
import './hubspotFormStyles.css';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';

const FormWrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const FormErrorWrapper = styled.div`
  padding: 15px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 300;
`;
const FormSubmittedMessage = styled(FormErrorWrapper)`
  color: ${({ theme }) => theme.showcaseBlack};
`;

const validateSelector = (selector) => {
  try {
    document.querySelectorAll(selector);
  } catch {
    return false;
  }
  return true;
};

// As we can't intercept HubspotForm events from embedded showcases
// we will try to populate hidden fields until we succeed or the maximum number of tries is reached
const maxTriesToPopulateHiddenFields = 10;

const HubspotForm = memo(
  ({
    portalId,
    formId,
    isEmbeddedShowcase,
    hiddenFieldsValues,
    onFormSubmit
  }) => {
    const [leadPreparedData, setLeadPreparedData] = useState();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isFormReady, setIsFormReady] = useState(false);
    const [hiddenFieldsArePopulated, setHiddenFieldsArePopulated] =
      useState(false);

    const [triesExceeded, setTriesExceeded] = useState(false);
    const [isPopulatingInProgress, setIsPopulatingInProgress] = useState(false);

    // Auth state
    const { AuthState } = useAuthState();
    const { authenticated, visitorId } = AuthState;

    // Project state
    const { ProjectState } = useProjectState();
    const { shareCode, project, vault, favoritesList } = ProjectState;

    // UI state
    const { UiState } = useUiState();
    const { contactUsActiveUnit } = UiState;

    const { error, formCreated } = useHubspotForm({
      portalId,
      formId,
      target: '#hubspot-form',
      cssClass: 'custom-hs-form',
      submitButtonClass: 'custom-hs-button',
      errorClass: 'custom-error',
      errorMessageClass: 'custom-error-message'
    });

    const formRef = useRef();

    const populateHiddenFields = useCallback(
      (count) => {
        // Return if all the intended hidden fields are populated
        if (hiddenFieldsArePopulated) return;
        if (count > maxTriesToPopulateHiddenFields) {
          setTriesExceeded(true);
          return;
        }

        // should check if the form is rendered inside an iframe or not
        // the way the form is rendered depends on a Hubspot Account Plan
        const hubspotForm =
          document.getElementById('hs-form-iframe-0')?.contentDocument;

        const target = hubspotForm || document;

        let populatedFieldsCount = 0;
        hiddenFieldsValues.forEach((x) => {
          const selector = `input[name=${x.fieldName}]`;
          const isValidSelector = validateSelector(selector);
          if (isValidSelector) {
            const field = target.querySelector(selector);
            if (field) {
              field.value = x.fieldValue;
              populatedFieldsCount++;
            }
          }
        });
        if (populatedFieldsCount === hiddenFieldsValues.length) {
          setHiddenFieldsArePopulated(true);
        } else {
          // try one more time as not all hidden fields were populated
          // the fields could be not rendered yet or they could be absent in this Hubspot form at all
          setTimeout(() => {
            populateHiddenFields(count + 1);
          }, 500);
        }
      },
      [hiddenFieldsArePopulated, hiddenFieldsValues]
    );

    const handleFormMessage = useCallback(
      (event) => {
        if (
          event.data?.type === 'hsFormCallback' &&
          event.data?.eventName === 'onFormReady'
        ) {
          setIsFormReady(true);
        }

        const formIsSubmitted = isEmbeddedShowcase
          ? event.data?.data?.formGuid === formId &&
            event.data?.eventName === 'onFormSubmitted'
          : event.data?.type === 'hsFormCallback' &&
            event.data?.eventName === 'onFormSubmitted';

        if (
          event.data?.type === 'hsFormCallback' &&
          event.data?.eventName === 'onFormSubmit' &&
          !authenticated
        ) {
          const capturedHSFormData = event.data?.data?.reduce(
            (accum, currentValue) => {
              if (currentValue.name === 'hs_context') return accum;
              return {
                ...accum,
                [currentValue.name]: currentValue.value
              };
            },
            {}
          );
          const { email, firstname, lastname } = capturedHSFormData;
          const preparedDataFromForm = {
            ...leadPreparedData,
            email: email ?? '',
            name: firstname && lastname ? firstname + ' ' + lastname : '',
            formLoad: JSON.stringify(capturedHSFormData),
            utmSource: 'hubspot_form',
            visitorObjectId: visitorId,
            language: localizer.getLanguage()
          };
          // this call have no any handlers as it works on background
          // and no special logic if it fails
          Lead.create({
            leadCreateShowcaseParams: preparedDataFromForm
          }).catch(() => {});
        }

        if (formIsSubmitted) {
          setTimeout(() => {
            setFormSubmitted(true);
            onFormSubmit();
          }, 2000);
        }
      },
      [
        formId,
        isEmbeddedShowcase,
        onFormSubmit,
        authenticated,
        leadPreparedData,
        visitorId
      ]
    );

    useEffect(() => {
      const { pathname } = window.location;
      const currentUnit = pathname.includes('/unit/')
        ? // if pathname /AAU7P/unit/l6Zh4UVui3 then we get third elem which is unit id
          pathname.split('/')[3]
        : null;
      // favorite unit might be unit from current page.
      // and also it could be an active unit (hovered or clicked)
      // that reason array might have same ids, using Set - only unique values left in array
      const uniqueIDs = new Set(favoritesList);
      if (currentUnit) uniqueIDs.add(currentUnit);
      if (contactUsActiveUnit?.objectId)
        uniqueIDs.add(contactUsActiveUnit?.objectId);
      if (uniqueIDs.size) {
        const uniqueUnitObjectIds = [...uniqueIDs];
        setLeadPreparedData((prev) => ({
          ...prev,
          unitObjectIds: uniqueUnitObjectIds
        }));
      }
    }, [ProjectState, contactUsActiveUnit, favoritesList]);

    useEffect(() => {
      if (project && vault && shareCode) {
        setLeadPreparedData((prevState) => ({
          ...prevState,
          shareCode,
          projectId: project.objectId,
          vaultId: vault.objectId
        }));
      }
    }, [project, vault, shareCode]);

    useEffect(() => {
      if (isPopulatingInProgress) return;
      if (isFormReady || (isEmbeddedShowcase && !triesExceeded)) {
        populateHiddenFields(1);
        setIsPopulatingInProgress(true);
      }
    }, [
      isFormReady,
      populateHiddenFields,
      isEmbeddedShowcase,
      triesExceeded,
      isPopulatingInProgress
    ]);

    useEffect(() => {
      window.addEventListener('message', handleFormMessage);
      return () => {
        window.removeEventListener('message', handleFormMessage);
      };
    }, [handleFormMessage]);

    if (error) return <FormErrorWrapper>{localizer.noForm}</FormErrorWrapper>;

    if (formSubmitted)
      return (
        <FormSubmittedMessage>
          {localizer.contactUsOnFormSubmitMessage}
        </FormSubmittedMessage>
      );

    return (
      <FormWrapper ref={formRef}>
        {!formCreated ? (
          <ShowcaseLoader color="gray" />
        ) : (
          <div id="hubspot-form" />
        )}
      </FormWrapper>
    );
  }
);

HubspotForm.propTypes = {
  /** defines what is the purpose of the form */
  formType: PropTypes.oneOf(['ContactUs', 'InfoWall']),
  /** portalId is used to identify the form */
  portalId: PropTypes.string.isRequired,
  /** formId is used to identify the form */
  formId: PropTypes.string.isRequired,
  /** defines whether the showcase is rendered inside an iframe */
  isEmbeddedShowcase: PropTypes.bool,
  shareCode: PropTypes.string,
  hiddenFieldsValues: PropTypes.array,
  onFormSubmit: PropTypes.func
};

HubspotForm.defaultProps = {
  isEmbeddedShowcase: false,
  hiddenFieldsValues: [],
  onFormSubmit: () => {}
};

export default HubspotForm;
