import React, { useState, useCallback, useEffect } from 'react';

// Components
import { AnimatePresence } from 'framer-motion';
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import ShareCodeSettings from 'components/share/ShareCodeSettings';
import RegenerateShareCodeButton from 'components/share/RegenerateShareCodeButton';
import NativeSharing from 'components/other/NativeSharing';

// Styling
import styled from 'styled-components';
import { Wrapper, PageTitle, PageContent, Toast, ToastIcon } from './styles';

// Helpers
import { useNavigate } from 'react-router-dom';
import localizer from 'localization/localizer';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import { useUiState } from 'stores/UiStore';
import {
  capitalize,
  getCurrentEnvironment,
  isShareCodeInvalid,
  fetchSettingsFromURL
} from 'helpers';
import {
  getLocalizedProjectDescription,
  getLocalizedProjectTitle
} from 'helpers/project/VmProjectSectionHelper';
import to from 'await-to-js';
import { filteredUnitListForProjectStore } from 'helpers/units/VmUnitHelper';
import {
  ShowcaseConfiguration,
  ShareItem,
  Lead,
  LeadComment,
  BoardTicket
} from '@prompto-api';
import { getFormattedName } from '../../share/ShareModal';

const SettingsContainer = styled.div`
  flex-shrink: 0;
  margin-top: 20px;
  width: calc(100% + 30px);
`;

const DoneButton = styled.div`
  margin-top: 30px;
  flex-shrink: 0;
  background: ${({ theme, disabled }) =>
    disabled ? theme.grayWhiteOff : theme.accentAlt500};
  border-radius: 3px;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.grayWhiteOff : theme.accentAlt500};
  box-sizing: border-box;
  color: ${({ theme, disabled }) =>
    disabled ? theme.primary300 : theme.showcaseWhite};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.88px;
  line-height: 18px;
  outline: none;
  text-align: center;
  height: 40px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  transition: all 0.2s ease;
  > p {
    margin: auto 0;
  }
`;

const ShareProjectPage = () => {
  const navigate = useNavigate();
  const params = fetchSettingsFromURL();

  const { AuthState } = useAuthState();
  const { authenticated, user } = AuthState;
  const { sessionToken, userId } = user;

  // Project store
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const {
    shareCode,
    project,
    vault,
    favoritesList,
    shareItemShowcaseConfiguration,
    contentCollection,
    showcaseConfiguration,
    initialUnitList,
    activeShareItem
  } = ProjectState;

  // UI store
  const { UiStateDispatch } = useUiState();

  // share item setting related
  const [sharecodeSettings, setSharecodeSettings] = useState(null);
  const [isUpdatingShareItem, setIsUpdatingShareItem] = useState(false);
  const [shareItemError, setShareItemError] = useState(null);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [sendEmailToContact, setSendEmailToContact] = useState(false);
  const [isShareOnlyFavorites, setShareOnlyFavorites] = useState(false);
  const [emailLanguage, setEmailLanguage] = useState();
  const [shouldCreateLead, setShouldCreateLead] = useState();

  const [localShareCode, setLocalShareCode] = useState('');
  const [localShareItemId, setLocalShareItemId] = useState('');
  const [env] = useState(getCurrentEnvironment());
  const [baseLink] = useState(`https://${env.applicationUrl}`);
  const [error, setError] = useState(null);

  const [isRegeneratingCode, setIsRegeneratingCode] = useState(false);

  // Features
  let features = {
    multipleSharecodes: false,
    archiveSharecodes: false
  };

  if (user?.features) {
    Object.keys(features).forEach((feature) => {
      const featureActive = user?.features?.includes(feature);
      features[feature] = featureActive;
    });
  }

  useEffect(() => {
    const shouldShowSettings = authenticated && features.multipleSharecodes;
    if (!shouldShowSettings) {
      setShareItemError(null);
    }
  }, [authenticated, features.multipleSharecodes]);

  useEffect(() => {
    if (!localShareCode) setLocalShareCode(shareCode);
  }, [shareCode, localShareCode]);

  // Is triggered when share code successfully regenerated
  useEffect(() => {
    if (localShareCode && localShareCode !== shareCode) {
      navigate(`/${localShareCode}/share${window.location.search}`);
    }
  }, [localShareCode, shareCode, navigate]);

  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: { showMenu: true }
    });
  }, [UiStateDispatch]);

  const url = `${baseLink}/${localShareCode}${window.location.search}`;

  const toggleError = useCallback((error) => {
    setError(error);
    const timer = setTimeout(() => {
      setError(null);
      clearTimeout(timer);
    }, 3000);
  }, []);

  const handleShareProject = () => {
    navigator
      .share({
        title: getLocalizedProjectTitle(project) ?? 'PROMPTO SHOWCASE',
        text: getLocalizedProjectDescription(project),
        url
      })
      .then(() => {})
      .catch(() =>
        toggleError({ type: 'error', text: localizer.sharingAborted })
      );
  };

  const updateShareCode = ({ shareCode, objectId }) => {
    toggleError({ type: 'success', text: localizer.regenerateCodeSuccess });
    setLocalShareCode(shareCode);
    setLocalShareItemId(objectId);
    // its a good to turn saving by default only favorite units if new shareitem was created
    setShareOnlyFavorites(false);
    // after creating a new shareitem, we need to filter out units again
    // according to the old showcase configuration
    const preparedUnits = filteredUnitListForProjectStore({
      unitList: initialUnitList,
      contentCollection,
      showcaseConfiguration
    });
    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        project: {
          ...project,
          unitList: preparedUnits
        }
      }
    });
  };

  const onChangeShareItemData = useCallback(
    async (updatedShareItem) => {
      setShareItemError(null);
      setIsUpdatingShareItem(true);

      const updateParams = { ...updatedShareItem };

      if (updateParams.hasOwnProperty('customerName')) {
        delete updateParams.customerName;
      }

      if (sendEmailToContact) {
        updateParams.sendEmailToContact = true;
      }
      updateParams.contactCreateParams = {
        language:
          params.language && ['fr', 'de', 'nl'].includes(params.language)
            ? params.language
            : 'en'
      };
      if (updateParams.hasOwnProperty('customerEmail')) {
        updateParams.contactCreateParams.email = updateParams.customerEmail;
      }
      if (updateParams.hasOwnProperty('firstName')) {
        updateParams.contactCreateParams.firstName = updateParams.firstName;
      }
      if (updateParams.hasOwnProperty('lastName')) {
        updateParams.contactCreateParams.lastName = updateParams.lastName;
      }

      ShareItem.update(updatedShareItem.objectId, updateParams, sessionToken);

      let shareItemConfigObjectId = shareItemShowcaseConfiguration?.objectId;

      if (favoritesList.length && isShareOnlyFavorites) {
        const handleCallResponse = (response) => {
          const { data } = response;
          const { vmShowcaseConfiguration } = data;
          const unitList = project.unitList.filter((unit) => {
            return favoritesList.includes(unit.objectId);
          });
          // if new configuration was created - its id will be added to shareItem
          // if it just updated - its id will be same as before
          shareItemConfigObjectId = vmShowcaseConfiguration.objectId;
          ProjectStateDispatch({
            type: 'setProjectData',
            payload: {
              shareItemShowcaseConfiguration: vmShowcaseConfiguration,
              project: {
                ...project,
                unitList
              }
            }
          });
        };
        if (shareItemShowcaseConfiguration) {
          // update shareItemShowcaseConfiguration
          const [, response] = await to(
            ShowcaseConfiguration.update(
              shareItemShowcaseConfiguration.objectId,
              { allowedUnitsIdList: favoritesList },
              sessionToken
            )
          );
          if (response) {
            handleCallResponse(response);
          }
        } else {
          // or create new one and add allowed units to it
          const [, response] = await to(
            ShowcaseConfiguration.create(
              {
                entityObjectId: env.entityObjectId,
                vaultObjectId: vault.objectId,
                projectObjectId: project.objectId,
                allowedUnitsIdList: favoritesList
              },
              sessionToken
            )
          );
          if (response) {
            handleCallResponse(response);
          }
        }
      }

      ShareItem.update(
        updatedShareItem.objectId,
        {
          ...updatedShareItem,
          showcaseConfigurationObjectId: shareItemConfigObjectId
        },
        sessionToken
      )
        .then((result) => {
          setIsUpdatingShareItem(false);
          const shareItem = result?.data?.shareItem;
          const updatedCodeIsInvalid = isShareCodeInvalid(shareItem);
          setIsInvalidCode(updatedCodeIsInvalid);

          if (shouldCreateLead) {
            const name = getFormattedName(updateParams);
            const leadCreateShowcaseParams = {
              shareCode: updateParams.shareCode,
              projectId: updateParams.projectObjectId,
              vaultId: updateParams.vaultObjectId,
              name,
              email: updateParams.customerEmail,
              utmSource: 'showcase',
              language: updateParams.contactCreateParams?.language ?? 'en'
            };

            if (favoritesList.length && isShareOnlyFavorites) {
              leadCreateShowcaseParams.unitObjectIds = favoritesList;
            }

            // A lead is being created silently, we only catch an error to not break the app
            Lead.create({
              leadCreateShowcaseParams
            })
              .then((res) => {
                // Create lead comment if neeeded
                if (updateParams.note) {
                  const createCommentData = {
                    commentAuthor: {
                      userObjectId: userId,
                      authenticated: true,
                      name: ''
                    },
                    leadObjectId: res.data.leadObjectId,
                    leadCommentType: 'log_an_email',
                    value: updateParams.note
                  };
                  LeadComment.create(createCommentData, sessionToken).catch(
                    () => {}
                  );
                }

                // Assign lead to its' creator
                BoardTicket.assign(
                  res.data.boardTicketObjectId,
                  { assignUserId: userId },
                  sessionToken
                ).catch(() => {});
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          setShareItemError({
            message: localizer.shareCodeSettings.errorWhileUpdating,
            code: error.response?.status
          });
          setIsUpdatingShareItem(false);
        });
    },
    [
      sessionToken,
      setShareItemError,
      ProjectStateDispatch,
      env,
      favoritesList,
      isShareOnlyFavorites,
      shareItemShowcaseConfiguration,
      project,
      vault.objectId,
      sendEmailToContact,
      params.language,
      shouldCreateLead
    ]
  );

  const onDoneClicked = () => {
    if (
      sharecodeSettings &&
      sharecodeSettings.customerEmail &&
      !sharecodeSettings.isValidEmail
    )
      return;

    onChangeShareItemData(sharecodeSettings);
  };

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MobileLayoutHeader>
        <PageTitle>{capitalize(localizer.shareProject)}</PageTitle>
      </MobileLayoutHeader>
      <PageContent>
        <NativeSharing
          path={localShareCode}
          onShare={handleShareProject}
          shareShowcase={true}
        />
        {features.multipleSharecodes && (
          <RegenerateShareCodeButton
            isUserAuthenticated={authenticated}
            data={{
              sessionToken: user?.sessionToken,
              vaultObjectId: vault?.objectId,
              projectObjectId: project?.objectId
            }}
            setErrorWhileRegeneratingCode={(flag) =>
              flag &&
              toggleError({
                type: 'error',
                text: localizer.regenerateCodeError
              })
            }
            setIsRegeneratingCode={setIsRegeneratingCode}
            isRegeneratingCode={isRegeneratingCode}
            updateShareCode={updateShareCode}
          />
        )}

        {authenticated && features.multipleSharecodes && (
          <>
            <SettingsContainer>
              <ShareCodeSettings
                shareItemId={localShareItemId || activeShareItem?.objectId}
                code={shareCode}
                canArchiveSharecodes={features.archiveSharecodes}
                setSharecodeSettings={setSharecodeSettings}
                isInvalidCode={isInvalidCode}
                processing={isUpdatingShareItem}
                error={shareItemError}
                setShareItemError={setShareItemError}
                favoritesList={favoritesList}
                isShareOnlyFavorites={isShareOnlyFavorites}
                setShareOnlyFavorites={setShareOnlyFavorites}
                sendEmailToContact={sendEmailToContact}
                setSendEmailToContact={setSendEmailToContact}
                emailLanguage={emailLanguage}
                onLanguageChanged={setEmailLanguage}
                shouldCreateLead={shouldCreateLead}
                setShouldCreateLead={setShouldCreateLead}
              />
            </SettingsContainer>

            <DoneButton
              onClick={onDoneClicked}
              disabled={
                sharecodeSettings &&
                ((sharecodeSettings.customerEmail &&
                  !sharecodeSettings.isValidEmail) ||
                  (sendEmailToContact &&
                    (!sharecodeSettings.customerEmail ||
                      !sharecodeSettings.isValidEmail)))
              }
            >
              <p>
                {capitalize(
                  sendEmailToContact
                    ? localizer.shareCodeSettings.sendEmail
                    : localizer.done
                )}
              </p>
            </DoneButton>
          </>
        )}
      </PageContent>
      {error && (
        <AnimatePresence>
          <Toast
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: -32 }}
            exit={{ opacity: 0, y: 0 }}
            error={error.type === 'error' ? 1 : 0}
          >
            <ToastIcon
              icon={['far', error.type === 'error' ? 'ban' : 'check']}
              size="sm"
            />
            {error?.text}
          </Toast>
        </AnimatePresence>
      )}
    </Wrapper>
  );
};

ShareProjectPage.propTypes = {};

ShareProjectPage.defaultProps = {};

export default ShareProjectPage;
