import React, { useState, useEffect, memo, useMemo, useCallback } from 'react';
import { shape, bool } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShowcaseBuilderInfoSpotIcons } from '@prompto-ui';
import UspSpotTooltip from './UspSpotTooltip';

// Helpers
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import { ExpandDirections } from '../Spot';

// Styling
import styled, { css } from 'styled-components';

const spotSize = 40;

const Wrapper = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 100;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: ${({ br, size }) => (br ? (size / 100) * br : 2)}px;
  cursor: default;
  backdrop-filter: blur(5px);
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  overflow: hidden;
  flex-shrink: 0;

  ${({ horizontalExpandDirection, verticalExpandDirection, size }) => {
    const expandToLeft = horizontalExpandDirection === ExpandDirections.left;
    const expandToTop = verticalExpandDirection === ExpandDirections.top;

    const xShift = ((size - spotSize) / 2) * (expandToLeft ? 1 : -1);
    const yShift = ((size - spotSize) / 2) * (expandToTop ? 1 : -1);

    return css`
      transform: translateX(${xShift}px) translateY(${yShift}px);
      ${expandToLeft ? 'right: 0;' : 'left: 0;'}
      ${expandToTop ? 'bottom: 0;' : 'top: 0;'}
    `;
  }}

  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.color};
    border-radius: ${({ br, size }) => (br ? (size / 100) * br : 2)}px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const FontAwesomeIconWrapper = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${(props) => props.color};
  transform: rotate(${(props) => props.rotate}deg);
`;

const IconWrapper = styled(motion.div)`
  width: ${spotSize}px;
  height: ${spotSize}px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const PreviewWrapper = styled(motion.div)`
  z-index: ${isMobile ? 1 : -2};
  position: absolute;
`;

const UspSpot = memo(
  ({
    spotObject,
    spotLocation,
    isSpotHovered,
    spotAbsoluteLocation,
    enlargeUsp,
    horizontalExpandDirection,
    verticalExpandDirection,
    parentDimensions
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [showMobileTooltip, setShowMobileTooltip] = useState(false);

    const [enlargedUspData, setEnlargedUspData] = useState({});

    // UI state
    const { UiState, UiStateDispatch } = useUiState();
    const { showUspMobileTooltip } = UiState;

    // Project store
    const { ProjectState } = useProjectState();
    const { showcaseConfiguration } = ProjectState;

    const spotIsPinned = !!spotObject?.pinConfiguration?.pinned;

    const elementId = 'uspSpot-' + spotObject.objectId;

    const activeUsp = useMemo(
      () => ({ spot: spotObject, location: spotLocation }),
      [spotObject, spotLocation]
    );

    useEffect(() => {
      setShowTooltip(isSpotHovered);
    }, [isSpotHovered]);

    useEffect(() => {
      if (showMobileTooltip) return;
      if (showTooltip) {
        UiStateDispatch({
          type: 'update',
          payload: {
            showUspMobileTooltip: true,
            activeUsp: { ...activeUsp }
          }
        });
        setShowMobileTooltip(true);
      }
    }, [showTooltip, showMobileTooltip, UiStateDispatch, activeUsp]);

    useEffect(() => {
      if (!showUspMobileTooltip) {
        setShowMobileTooltip(false);
        setShowTooltip(false);
      }
    }, [showUspMobileTooltip]);

    const canBeEnlarged =
      spotObject?.usp?.uspVisibilitySettings?.showMedia !== false &&
      showcaseConfiguration &&
      ['image', 'video'].includes(enlargedUspData?.mediaType);

    const onEnlargeUsp = useCallback(
      (event) => {
        if (!canBeEnlarged) return;
        enlargeUsp(event, spotObject?.usp?.objectId, enlargedUspData);
      },
      [enlargedUspData, spotObject, canBeEnlarged, enlargeUsp]
    );

    const selectedIcon = spotObject.iconId || 'infoCircle';
    const icon =
      isSpotHovered && canBeEnlarged ? (
        <FontAwesomeIconWrapper
          icon={['far', 'expand']}
          size="1x"
          color="white"
        />
      ) : (
        ShowcaseBuilderInfoSpotIcons.filter(
          (item) => item.id === selectedIcon
        ).map((item) => (
          <FontAwesomeIconWrapper
            key={item.id}
            icon={[item.library, item.iconId]}
            color={item.color}
            rotate={item.rotation || 0}
            size="1x"
          />
        ))
      );

    const shouldShowTooltip = (showTooltip && !isMobile) || spotIsPinned;

    return (
      <>
        <Wrapper
          key="wrapper"
          color={spotObject?.color}
          hidden={!!spotIsPinned}
          br={spotObject?.borderRadius}
          size={spotObject?.spotSize ?? spotSize}
          onClick={onEnlargeUsp}
          horizontalExpandDirection={horizontalExpandDirection}
          verticalExpandDirection={verticalExpandDirection}
          id={elementId}
        >
          <IconWrapper
            key={'icon'}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              scale: spotObject?.spotSize ? spotObject.spotSize / spotSize : 1
            }}
          >
            {icon}
          </IconWrapper>
        </Wrapper>

        <PreviewWrapper
          animate={{
            scale: shouldShowTooltip ? 1 : 0,
            opacity: shouldShowTooltip ? 1 : 0
          }}
          transition={{ duration: 0.3 }}
        >
          <UspSpotTooltip
            uspSpotLocation={spotLocation}
            spotObject={spotObject}
            uspSpotAbsoluteLocation={spotAbsoluteLocation}
            isPinned={spotIsPinned}
            setEnlargedUspData={setEnlargedUspData}
            enlargeUsp={onEnlargeUsp}
            showTooltip={shouldShowTooltip}
            parentDimensions={parentDimensions}
          />
        </PreviewWrapper>
      </>
    );
  }
);

UspSpot.propTypes = {
  spotObject: shape({}).isRequired,
  spotLocation: shape({}).isRequired,
  isSpotHovered: bool
};

UspSpot.defaultProps = {
  isSpotHovered: false
};

export default UspSpot;
