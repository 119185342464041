import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './app/serviceWorker';

// Stores
import AuthStore from './app/stores/AuthStore';
import ProjectStore from './app/stores/ProjectStore';
import StreamStore from './app/stores/StreamStore';
import UiStore from './app/stores/UiStore';
import MarketplaceStore from './app/stores/MarketplaceStore';

// Helpers
import registerSentry from 'helpers/registerSentry';
import { isMobile } from 'react-device-detect';

// Styling
import 'react-tippy/dist/tippy.css';
import './app/index.css';
import { ThemeProvider } from 'styled-components';

// Registering the Font awesome icons
import registerIcons from './app/resources/icons/registerFaIcons';
registerIcons();

export const theme = {
  language: 'en',
  brand: '#96C13A',
  brand500: '#584dab',
  showcaseWhite: '#ffffff',
  showcaseBlack: '#000000',
  primaryColor: '#3384f3',
  secondaryColor: '#100a2b',
  hightLightColor: '#00e8cb',
  primary100: '#9f9caa',
  primary200: '#706a81',
  primary300: '#574f6b',
  primary400: '#403957',
  primary700: '#1c1636',
  primary900: '#11082d',
  accentMenuDefault: '#635cf7',
  accentMenuDark: '#6f67b7',
  gray30: '#4D4D4D',
  gray100: '#f3f2f4',
  gray150: '#e4e3e8',
  gray200: '#edecf0',
  gray300: '#e7e6ea',
  gray400: '#c3c2ca',
  accentAlt100: '#adaaf8',
  accentAlt300: '#635cf7',
  accentAlt400: '#6f67b7',
  accentAlt500: '#584dab',
  accentAlt700: '#4236a1',
  whitePure: '#ffffff',
  whiteOff: '#f7f8fa',
  grayWhiteOff: '#f9f7fa',
  accent: '#d6b959',
  accentLight: '#ebdcac',
  successColor: '#4ca760',
  successLight: '#a6d3b0',
  warning: '#d16d3a',
  warningLight: '#e6b095',
  errorColor: '#be4a49',
  errorLight: '#dfa5a4',
  placeholder: '#eeaeae',
  unitSold: isMobile ? '#9f9caa' : '#C4C4C4',
  unitInOption: '#D16D3A',
  unitAvailable: '#4CA760',
  unitDefault: '#26C6DA',
  fadeBlack: 'rgba(0, 0, 0, 0.6)',
  fadeGray: 'rgba(50, 50, 50, 0.6)',
  fadeLightGray: 'rgba(144, 144, 144, 0.4)',
  fadeWhite: 'rgba(255, 255, 255, 0.6)',
  defaultBrandPrimaryColor: '#403957',
  defaultBrandSecondaryColor: '#b6a36c',
  defaultFloatingContactUsButtonColor: '#584dab',
  mainAccentFade: 'rgba(182, 163, 108, 0.6)',
  mainAccent: '#b6a36c',
  watermelon: '#bd5555',
  mainFontFamily: 'futura-pt'
};
export const showcaseContext = React.createContext(theme);

registerSentry();

// Trigger 4
ReactDOM.render(
  <AuthStore>
    <MarketplaceStore>
      <ProjectStore>
        <StreamStore>
          <UiStore>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </UiStore>
        </StreamStore>
      </ProjectStore>
    </MarketplaceStore>
  </AuthStore>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
