import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Components
import MapView from './tourComponents/MapView';
import MediaView from './tourComponents/MediaView';
import styled, { css } from 'styled-components';
import { IdealImage } from '@prompto-ui';
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectDescription from 'components/share/ProjectDescription';
import FullscreenButton from 'components/other/FullscreenButton';
import MobileFilterModal from 'components/share/MobileUnitFilterModal';

// Helpers
import { AnimatePresence, motion } from 'framer-motion';
import { useProjectState } from 'stores/ProjectStore';
import { getCurrentEnvironment, fetchSettingsFromURL } from 'helpers';
import { useTourState } from 'stores/TourStore';
import {
  useUiState,
  navigationFloatingButtonsBottomPosition
} from 'stores/UiStore';
import screenfull from 'screenfull';
import urlJoin from 'url-join';
import LoadingMessage from 'components/other/LoadingMessage';
import {
  isMobile,
  isMobileOnly,
  useMobileOrientation
} from 'react-device-detect';
import { localizeUnitFieldValues } from 'helpers/units/VmUnitHelper';
import { getLocalizedProjectTitle } from 'helpers/project/VmProjectSectionHelper';
import queryParamAbbreviations from 'configs/QueryParamAbbreviations.json';
import { usePrevious } from '@prompto-helpers';
import isEqual from 'lodash.isequal';
import qs from 'query-string';

// Styling

const Wrapper = styled(motion.div)`
  display: flex;
  height: 100%;
  width: 100%;
`;

const MainViewWrapper = styled.div`
  position: relative;
  height: ${({ islandscape }) =>
    isMobile && !islandscape ? 'calc(100% - 70px)' : '100%'};
  width: 100%;
  z-index: 51; // bigger for 1 than for navigationMenu element
`;

const FirstImageWrapper = styled(motion.div)`
  position: absolute;
  width: ${(props) => `${props.width}px` || '100%'};
  height: ${(props) => `${props.height}px` || '100%'};
  top: ${(props) => `-${(props.height - window.innerHeight) / 2 + 40}px`};
  left: ${(props) => `-${(props.width - window.innerWidth) / 2}px`};
  display: flex;
`;

const MediaViewWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const UnderlayImage = styled.img`
  position: absolute;
  top: -15px;
  left: -15px;
  width: calc(100% + 30px);
  height: calc(100vh + 30px);
  object-fit: cover;
  object-position: center;
  ${({ styles }) => styles}
`;

const MobileColoredUnderlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 70px);
  background-color: ${({ theme }) => theme.showcaseBlack};
`;

const ProjectTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 30px;
`;

const ProjectTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  margin-right: 4px;
  white-space: nowrap;
`;

const ButtonWrapper = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.primary100};
`;

const FilterButtonWrapper = styled(ButtonWrapper)`
  color: ${({ theme }) => theme.primary300};
  position: absolute;
  z-index: 100;
  left: 10px;
  bottom: 10px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.showcaseWhite};
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 5%);
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const ActiveFilterMarker = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.theme.successColor};
`;

const ToggleShowDescriptionIcon = styled(FontAwesomeIcon)`
  transition: all 150ms ease;
  transform: scaleY(${(props) => (props.show ? '-1' : '1')})
    translateY(${(props) => (props.show ? '7px' : '0')});
  transform-origin: center bottom;
`;

const FullscreenButtonWrapper = styled.div`
  visibility: hidden;
`;

const ignoredFilters = ['buildingObjectId'];

const TourPage = () => {
  const navigate = useNavigate();

  // Tour Store
  const { TourState, TourStateDispatch } = useTourState();
  const { activeMediaView, showUnits } = TourState;

  // UI Store
  const { UiState, UiStateDispatch } = useUiState();
  const { stackLength } = UiState;

  // Project Store
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const {
    project,
    vault,
    turntableList,
    uspList,
    contentCollection,
    navigationItem,
    firstImage,
    shareCode,
    unitFilters,
    filterSearchValue,
    showcaseConfiguration,
    nightMode
  } = ProjectState;

  const [mediaViews, setMediaViews] = useState(null);
  const previousMediaViews = usePrevious(mediaViews);

  const [currentMediaSpotList, setCurrentMediaSpotList] = useState([]);

  const [imageTourLoaded, setImageTourLoaded] = useState(false);
  const [imageDimensions, setImageDimensions] = useState();

  const [map, setMap] = useState();

  const [isShowProjectDesc, setShownProjectDesc] = useState(false);
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  const [imageBgStyles, setImageBgStyles] = useState();

  const [backgroundImage, setBackgroundImage] = useState();

  const [mediaViewToRender, setMediaViewToRender] = useState();

  const [polygonMediaList, setPolygonMediaList] = useState([]);

  const [
    numberOfFilteredUnitsForCurrentView,
    setNumberOfFilteredUnitsForCurrentView
  ] = useState(0);

  // Refs
  const mediaImageWrapperRef = useRef();

  const toggleProjectDesc = () => {
    setShownProjectDesc((prev) => !prev);
  };

  const { pathname, search, state: locationState, location } = useLocation();

  const { isLandscape } = useMobileOrientation();

  useEffect(() => {
    setMediaViewToRender(activeMediaView);
  }, [activeMediaView]);

  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: { showMenu: !isMobileOnly || (isMobileOnly && !isLandscape) }
    });
  }, [isLandscape, UiStateDispatch]);

  useEffect(() => {
    // this logic is only applicable to the Tour page
    if (!pathname.includes('tour')) return;
    let position = navigationFloatingButtonsBottomPosition.overZoomIndicator;
    UiStateDispatch({
      type: 'update',
      payload: {
        navigationFloatingButtonsBottomPosition: position
      }
    });
  }, [UiStateDispatch, pathname]);

  // set styles for the image background if a respective setting is presented in the showcase configuration
  useEffect(() => {
    if (!showcaseConfiguration?.imageTourBackgroundType) return;
    let styles = '';
    switch (showcaseConfiguration.imageTourBackgroundType) {
      case 'dark':
        styles = css`
          filter: brightness(0.25) blur(3px);
        `;
        break;
      case 'light':
        styles = css`
          filter: brightness(1) blur(10px);
        `;
        break;
      default:
    }
    setImageBgStyles(styles);
  }, [showcaseConfiguration]);

  // respond to browser Back arrow click
  useEffect(() => {
    if (mediaViews?.length === 0) return;

    if (!pathname.includes('tour')) return;

    const queryParams = fetchSettingsFromURL();
    const activeMediaViewParam = queryParamAbbreviations.activeMediaView;
    const activeMediaViewId = queryParams?.[activeMediaViewParam];
    if (activeMediaViewId && activeMediaViewId !== activeMediaView?.objectId) {
      const newActiveMediaView = mediaViews?.find(
        (m) => m.objectId === activeMediaViewId
      );
      setCurrentMediaSpotList(
        newActiveMediaView?.navigationCollection?.vmNavigationItemList ?? []
      );
      TourStateDispatch({
        type: 'update',
        payload: { activeMediaView: newActiveMediaView }
      });
    } else if (mediaViews) {
      setCurrentMediaSpotList(
        mediaViews[0]?.navigationCollection?.vmNavigationItemList ?? []
      );
      TourStateDispatch({
        type: 'update',
        payload: { activeMediaView: mediaViews[0] }
      });
    }
  }, [
    mediaViews,
    TourStateDispatch,
    activeMediaView,
    pathname,
    search,
    locationState
  ]);

  // Set the default Contact Us button position when leaving the Tour page
  useEffect(() => {
    return () => {
      UiStateDispatch({
        type: 'update',
        payload: {
          navigationFloatingButtonsBottomPosition:
            navigationFloatingButtonsBottomPosition.default
        }
      });
    };
  }, [UiStateDispatch]);

  useEffect(() => {
    if (navigationItem) {
      // Add the contentItem to all mediaViews
      const contentItemList = contentCollection?.vmContentItemList;

      let mediaViewItemList =
        navigationItem?.navigationCollection?.vmNavigationItemList.filter(
          (item) => item.navigationItemType === 'mediaView'
        );
      const mapViewItemList =
        navigationItem?.navigationCollection?.vmNavigationItemList.filter(
          (item) => item.navigationItemType === 'mapView'
        );

      if (mapViewItemList && mapViewItemList.length > 0) {
        setMap(mapViewItemList[0]);
      }

      if (contentItemList && mediaViewItemList && turntableList) {
        mediaViewItemList = mediaViewItemList.map((mediaView) => {
          const mediaViewContentItem = contentItemList.find(
            (x) => x.objectId === mediaView.value
          );

          let nightViewContentItem;
          if (mediaView.nightValue) {
            nightViewContentItem = contentItemList.find(
              (x) => x.objectId === mediaView.nightValue
            );
          }

          let spotItemList =
            mediaView.navigationCollection.vmNavigationItemList;
          if (spotItemList) {
            spotItemList = spotItemList
              .map((spot) => {
                if (spot.navigationItemType === 'unitSpot') {
                  // Add the unit to all unit spots
                  let unitFound = null;
                  if (project?.unitList) {
                    project.unitList
                      .map((unit) => localizeUnitFieldValues(project, unit))
                      .forEach((unit) => {
                        if (unit.objectId === spot.value) {
                          unitFound = unit;
                        }
                      });
                  }

                  if (unitFound) {
                    return {
                      ...spot,
                      unitItem: {
                        ...unitFound
                      }
                    };
                  } else {
                    return null;
                  }
                } else if (spot.navigationItemType === 'navigationSpot') {
                  // Add the contentItem to all navigation spots
                  const spotMediaView = mediaViewItemList.find(
                    (x) => x.objectId === spot.value
                  );
                  const spotContentItem = contentItemList.find(
                    (x) => x.objectId === spotMediaView?.value
                  );

                  let spotNightContentItem;
                  if (spotMediaView?.nightValue) {
                    spotNightContentItem = contentItemList.find(
                      (x) => x.objectId === spotMediaView?.nightValue
                    );
                  }

                  let fullSpotContentUri;
                  const transform = 'q=100';
                  const spotContentUri = spotContentItem?.contentUri;
                  if (spotContentUri) {
                    fullSpotContentUri = urlJoin(
                      getCurrentEnvironment().baseImageUrl,
                      transform,
                      spotContentUri
                    );
                  }

                  let fullSpotNightContentUri;
                  const spotNightContentUri = spotNightContentItem?.contentUri;
                  if (spotNightContentUri) {
                    fullSpotNightContentUri = urlJoin(
                      getCurrentEnvironment().baseImageUrl,
                      transform,
                      spotNightContentUri
                    );
                  }

                  // If there is no media view for the spot or no content item we don't show the spot
                  // This should perhaps be cleaned up then
                  if (spotMediaView && spotContentItem) {
                    const newObject = {
                      ...spot,
                      contentItem: {
                        ...spotContentItem,
                        fullContentUri: fullSpotContentUri
                      }
                    };

                    if (spotNightContentItem) {
                      newObject.nightContentItem = {
                        ...spotNightContentItem,
                        fullContentUri: fullSpotNightContentUri
                      };
                    }

                    return newObject;
                  }

                  return null;
                } else if (spot.navigationItemType === 'turntableSpot') {
                  const turntableForSpot = turntableList.find(
                    (x) => x.objectId === spot.value
                  );

                  if (turntableForSpot) {
                    return {
                      ...spot,
                      contentItem: turntableForSpot.contentItem,
                      turntable: turntableForSpot
                    };
                  }
                } else if (spot.navigationItemType === 'uspSpot') {
                  if (uspList) {
                    const uspForSpot = uspList.find(
                      (x) => x.objectId === spot.value
                    );

                    if (uspForSpot) {
                      return {
                        ...spot,
                        usp: uspForSpot
                      };
                    } else {
                      return null;
                    }
                  }
                } else if (spot.navigationItemType === 'album360Spot') {
                  let spotContentItem = contentItemList.find(
                    (x) => x.objectId === spot?.value
                  );
                  const mediaViewForSpot = mediaViewItemList.find(
                    (view) => view.objectId === spot.value
                  );
                  if (mediaViewForSpot) {
                    spotContentItem = contentItemList.find(
                      (x) => x.objectId === mediaViewForSpot?.value
                    );
                  }

                  if (spotContentItem) {
                    const newSpot = {
                      ...spot,
                      contentItem: spotContentItem
                    };

                    // to separate 360 image spots that have respective media views
                    // from those that do not
                    if (mediaViewForSpot) {
                      newSpot.hasMediaView = true;
                    }

                    return newSpot;
                  } else {
                    return null;
                  }
                }
                return spot;
              })
              .filter((item) => item !== null);
          }

          const newObject = {
            ...mediaView,
            contentItem: mediaViewContentItem,
            navigationCollection: {
              ...mediaView.navigationCollection,
              vmNavigationItemList: spotItemList
            }
          };

          if (nightViewContentItem) {
            newObject.nightContentItem = nightViewContentItem;
          }

          return newObject;
        });
      }

      if (!isEqual(previousMediaViews, mediaViewItemList)) {
        setMediaViews(mediaViewItemList);
        TourStateDispatch({
          type: 'update',
          payload: {
            mediaViews: mediaViewItemList,
            highlightedSpot: null,
            highlightedUnitSpotParams: null
          }
        });
      }

      UiStateDispatch({
        type: 'update',
        payload: {
          showMenu: !isMobileOnly || (isMobileOnly && !isLandscape)
        }
      });

      setImageTourLoaded(true);
    }
  }, [
    shareCode,
    project,
    turntableList,
    uspList,
    contentCollection,
    navigationItem,
    firstImage,
    navigate,
    TourStateDispatch,
    UiStateDispatch,
    isLandscape,
    previousMediaViews
  ]);

  useEffect(() => {
    if (activeMediaView) {
      setCurrentMediaSpotList(
        activeMediaView?.navigationCollection?.vmNavigationItemList
      );
    }
  }, [activeMediaView]);

  useEffect(() => {
    if (!activeMediaView && mediaViews && mediaViews.length > 0) {
      // if start image is specified in query params set it as an activeMediaView
      // otherwise use the first image out of mediaViews
      const queryParams = fetchSettingsFromURL();
      const activeMediaViewParam = queryParamAbbreviations.activeMediaView;
      const startMediaViewId = queryParams?.[activeMediaViewParam];
      const activeMediaView = startMediaViewId
        ? mediaViews.find((m) => m.objectId === startMediaViewId)
        : mediaViews[0];

      TourStateDispatch({
        type: 'update',
        payload: { activeMediaView }
      });
    } else if (mediaViews && activeMediaView) {
      TourStateDispatch({
        type: 'update',
        payload: {
          activeMediaView: [...mediaViews, ...polygonMediaList].find(
            (view) => view.objectId === activeMediaView.objectId
          )
        }
      });
    }
  }, [activeMediaView, mediaViews, TourStateDispatch]);

  const onStartImmersion = useCallback(() => {
    screenfull.toggle();
    UiStateDispatch({
      type: 'update',
      payload: { showMenu: false }
    });
  }, [UiStateDispatch]);

  const onStopImmersion = useCallback(() => {
    screenfull.toggle();
    UiStateDispatch({
      type: 'update',
      payload: { showMenu: true }
    });
  }, [UiStateDispatch]);

  const onUpdateMediaView = useCallback(
    (spotClicked) => {
      const selectedView = [...mediaViews, ...polygonMediaList].find(
        (view) =>
          (spotClicked.valuePolygon &&
            view.assignedItem?.objectId === spotClicked?.valuePolygon) ||
          view.contentItem?.objectId === spotClicked.value ||
          view.objectId === spotClicked.value
      );
      if (selectedView) {
        TourStateDispatch({
          type: 'update',
          payload: { activeMediaView: { ...selectedView } }
        });
        setCurrentMediaSpotList(
          selectedView.navigationCollection?.vmNavigationItemList
        );
        const activeMediaViewParam = queryParamAbbreviations.activeMediaView;
        const parsed = qs.parse(window.location.search);

        [{ [activeMediaViewParam]: selectedView.objectId }].forEach((param) => {
          const key = Object.keys(param)[0];
          parsed[key] = param[key];
        });

        UiStateDispatch({
          type: 'update',
          payload: { enableShowcaseBackButton: true }
        });
        UiStateDispatch({
          type: 'increaseStack'
        });
        navigate(`${window.location.pathname}?${qs.stringify(parsed)}`);
      }
    },
    [
      navigate,
      mediaViews,
      TourStateDispatch,
      location,
      UiStateDispatch,
      stackLength,
      polygonMediaList
    ]
  );

  const onOpenUnitPage = useCallback(
    (unit) => {
      const unitId = unit?.objectId;
      if (unitId) {
        UiStateDispatch({
          type: 'update',
          payload: { enableShowcaseBackButton: true }
        });
        UiStateDispatch({
          type: 'increaseStack'
        });
        navigate(`/${shareCode}/unit/${unitId}${window.location.search}`);
      }
    },
    [navigate, shareCode, UiStateDispatch]
  );

  const updateShowAllUnits = useCallback(
    (show) => {
      if (show !== showUnits) {
        TourStateDispatch({ type: 'update', payload: { showUnits: show } });
      }
    },
    [TourStateDispatch, showUnits]
  );

  const goToLandingPage = useCallback(() => {
    TourStateDispatch({
      type: 'update',
      payload: { activeMediaView: mediaViews[0] }
    });
    setCurrentMediaSpotList(
      mediaViews[0]?.navigationCollection?.vmNavigationItemList
    );
  }, [TourStateDispatch, mediaViews]);

  useEffect(() => {
    // Background image should respect chosen day-night mode
    // But if there is no image for chosen mode, fallback to day mode image
    const imageUri = nightMode
      ? activeMediaView?.nightContentItem?.contentUri
      : activeMediaView?.contentItem?.contentUri;

    let underlayImageUri = `${
      getCurrentEnvironment().baseImageUrl
    }/q=50:h=600/${imageUri ?? activeMediaView?.contentItem?.contentUri}`;

    setBackgroundImage(underlayImageUri);
  }, [nightMode, activeMediaView]);

  const setSearchValue = useCallback(
    (value) => {
      ProjectStateDispatch({
        type: 'setProjectData',
        payload: {
          filterSearchValue: value
        }
      });
    },
    [ProjectStateDispatch]
  );

  let mediaView = null;
  if (mediaViewToRender) {
    mediaView = (
      <MediaViewWrapper
        id={mediaViewToRender?.objectId}
        initial={{ opacity: 0 }}
        animate={activeMediaView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.5 }}
        ref={mediaImageWrapperRef}
      >
        {imageBgStyles && backgroundImage ? (
          <UnderlayImage src={backgroundImage} styles={imageBgStyles} />
        ) : (
          isMobile && <MobileColoredUnderlay />
        )}
        <MediaView
          key={mediaViewToRender?.objectId}
          mediaView={mediaViewToRender}
          tourId={navigationItem.objectId}
          spotList={currentMediaSpotList}
          showAllUnits={showUnits}
          setShowAllUnits={updateShowAllUnits}
          onUpdateMediaView={onUpdateMediaView}
          onOpenUnitPage={onOpenUnitPage}
          onStartImmersion={onStartImmersion}
          onStopImmersion={onStopImmersion}
          goToLandingPage={goToLandingPage}
          isMobileFilterVisible={isMobileFilterVisible}
          setMobileFilterVisible={setMobileFilterVisible}
          setNumberOfFilteredUnitsForCurrentView={
            setNumberOfFilteredUnitsForCurrentView
          }
          setPolygonMediaList={setPolygonMediaList}
        />
      </MediaViewWrapper>
    );
  }

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MainViewWrapper id="tourContainer" islandscape={isLandscape}>
        <MobileLayoutHeader
          rightAction={
            <FullscreenButtonWrapper>
              <FullscreenButton />
            </FullscreenButtonWrapper>
          }
        >
          <ProjectTitleWrapper onTouchStart={toggleProjectDesc}>
            <ProjectTitle>{getLocalizedProjectTitle(project)}</ProjectTitle>
            <ButtonWrapper>
              <ToggleShowDescriptionIcon
                show={isShowProjectDesc ? 1 : 0}
                icon={['fas', 'sort-down']}
                size="lg"
              />
            </ButtonWrapper>
          </ProjectTitleWrapper>
          {/* As "toggle full screen" functionality implementation is postponed
              we make FullscreenButton invisible by wrapping it with invisible wrapper */}
          <FullscreenButtonWrapper>
            <FullscreenButton />
          </FullscreenButtonWrapper>
        </MobileLayoutHeader>
        {isShowProjectDesc && <ProjectDescription project={project} />}

        <AnimatePresence>{mediaView}</AnimatePresence>
        {!activeMediaView && <LoadingMessage />}
        {!imageTourLoaded && (
          <FirstImageWrapper
            initial={{ opacity: 1 }}
            animate={activeMediaView ? { opacity: 0 } : { opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: 'easeOut', delay: 0.5 }}
            width={imageDimensions?.width}
            height={imageDimensions?.height}
          >
            <IdealImage
              key={firstImage?.objectId}
              contentUri={firstImage?.contentUri}
              fallbackUri={firstImage?.contentUri}
              imageSize={firstImage?.originalImageSize}
              containerSize={{
                width: window.innerWidth,
                height: window.innerHeight
              }}
              onLoad={(iWidth, iHeight) => {
                setImageDimensions({ width: iWidth, height: iHeight });
              }}
              vaultId={vault?.objectId}
              projectId={project?.objectId}
              mustFillParent={true}
              baseImageUrl={getCurrentEnvironment().baseImageUrl}
            />
          </FirstImageWrapper>
        )}
      </MainViewWrapper>

      {isMobile && (
        <FilterButtonWrapper
          filterisVisible={isMobileFilterVisible}
          isHidden={!!showcaseConfiguration?.hideFilterOptions}
          onTouchEnd={(e) => {
            setTimeout(() => setMobileFilterVisible(true), 100);
          }}
        >
          <FontAwesomeIcon icon={['fal', 'filter']} size="2x" />
          {(unitFilters.filter((x) => !ignoredFilters.includes(x.filterOption))
            ?.length > 0 ||
            filterSearchValue) && <ActiveFilterMarker />}
        </FilterButtonWrapper>
      )}

      {map && !isMobile && (
        <MapView
          mapView={map}
          tourId={navigationItem.objectId}
          onNavigateToMediaView={onUpdateMediaView}
          currentMediaView={activeMediaView}
          startMediaView={mediaViews[0]}
          initialUiState={showcaseConfiguration?.minimapInitialUiState}
        />
      )}

      {isMobileFilterVisible && (
        <MobileFilterModal
          amountOfUnitsInFilter={numberOfFilteredUnitsForCurrentView}
          onCloseModalRequest={() => setMobileFilterVisible(false)}
          onSearchChanged={setSearchValue}
          searchValue={filterSearchValue}
          ignoredFilters={ignoredFilters}
        />
      )}
    </Wrapper>
  );
};

TourPage.propTypes = {};

TourPage.defaultProps = {};

export default TourPage;
