import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { array } from 'prop-types';

// Components
import SwitcherViewGrid from './SwitcherViewGrid';
import PageSwiper from '../contentViewer/PageSwiper';
import Navigation from '../contentViewer/Navigation';
import Div100vh from 'react-div-100vh';

// Helpers
import { AnimatePresence, motion } from 'framer-motion';
import { getCurrentEnvironment } from 'helpers';
import { useSwitcherState } from 'stores/SwitcherStore';
import { useProjectState } from 'stores/ProjectStore';

// Styles
import styled from 'styled-components';

const VerticalWrapper = styled(motion.div)`
  width: 100vw;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 1);
`;

const PageSwiperWrapper = styled(motion.div)`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 130;
`;

const VerticalSwitcherViewWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const HorizontalGridWrapper = styled(motion.div)`
  width: 100vw;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 1);
  touch-action: none;
  -ms-touch-action: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const FixedBackground = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
`;

const SwitcherView = (props) => {
  const {
    pages,
    folders,
    showFolders,
    foldersRefs,
    localDragOffset,
    dragControls,
    setShowFolderNavigation
  } = props;

  const { subroute } = useParams();

  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  const { SwitcherState, SwitcherStateDispatch } = useSwitcherState();
  const { activePage, showGrid, projectInfoDeepDived } = SwitcherState;

  useEffect(() => {
    SwitcherStateDispatch({
      type: 'setPages',
      payload: pages
    });
  }, [pages, SwitcherStateDispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (subroute === 'media' || subroute === 'documents') {
        SwitcherStateDispatch({
          type: 'setShowGrid',
          payload: true,
          grid: subroute
        });
      }
    }, 200);
  }, [subroute, SwitcherStateDispatch]);

  let transform = 'q=80:h=400';
  const videoFallbackThumbnail = `${
    getCurrentEnvironment().baseImageUrl
  }/${transform}/${pages[0].contentUri}`;

  // Events
  const startDrag = (event) => {
    dragControls.start(event, { snapToCursor: false });
  };

  const onNextPage = useCallback(
    (eventName) => {
      let newPageIndex = activePage + 1;
      if (newPageIndex >= pages.length) {
        newPageIndex = 0;
      }

      SwitcherStateDispatch({ type: 'setActivePage', payload: newPageIndex });
    },
    [activePage, pages, SwitcherStateDispatch]
  );

  const onPreviousPage = useCallback(
    (eventName) => {
      let newPageIndex = activePage - 1;
      if (newPageIndex < 0) {
        newPageIndex = pages.length - 1;
      }

      SwitcherStateDispatch({ type: 'setActivePage', payload: newPageIndex });
    },
    [activePage, pages, SwitcherStateDispatch]
  );

  const onCloseSwiper = useCallback(() => {
    SwitcherStateDispatch({ type: 'setShowGrid', payload: true });
    setShowFolderNavigation(true);
  }, [SwitcherStateDispatch, setShowFolderNavigation]);

  const onPageLoaded = useCallback(() => {
    () => {
      SwitcherStateDispatch({ type: 'setGridItemClicked', payload: false });
    };
  }, [SwitcherStateDispatch]);

  const switcherGridComponent = (
    <SwitcherViewGrid
      pages={pages}
      dragControls={dragControls}
      videoFallbackThumbnail={videoFallbackThumbnail}
      folders={folders}
      showFolders={showFolders}
      foldersRefs={foldersRefs}
      localDragOffset={localDragOffset}
      setShowFolderNavigation={setShowFolderNavigation}
    />
  );

  // The grid is persistant
  // Doesn't seem to impact runtime now and allows for great transitions.
  // In case of performance issues, this is probably the place to start optimizing
  const grid = showcaseConfiguration?.isVerticalScroll ? (
    <VerticalWrapper
      animate={showGrid ? 'visible' : 'hidden'}
      transition={{ type: 'tween', duration: 0.25 }}
    >
      {switcherGridComponent}
    </VerticalWrapper>
  ) : (
    <HorizontalGridWrapper
      animate={showGrid ? 'visible' : 'hidden'}
      key="grid"
      variants={gridVariants}
      transition={{ type: 'tween', duration: 0.25 }}
      onMouseDown={startDrag}
    >
      <FixedBackground />
      {switcherGridComponent}
    </HorizontalGridWrapper>
  );

  const pageSwiper = (
    <PageSwiper
      {...props}
      activePage={activePage}
      pages={pages}
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      onClose={onCloseSwiper}
      videoFallbackThumbnail={videoFallbackThumbnail}
      CustomNavigation={<Navigation />}
      onPageLoaded={onPageLoaded}
      isActive={!projectInfoDeepDived}
    />
  );

  const children = (
    <>
      <AnimatePresence>
        {!showGrid && (
          <PageSwiperWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {pageSwiper}
          </PageSwiperWrapper>
        )}
      </AnimatePresence>
      {grid}
    </>
  );

  let contentToRender = <Div100vh>{children}</Div100vh>;

  if (showcaseConfiguration?.isVerticalScroll) {
    contentToRender = (
      <VerticalSwitcherViewWrapper>{children}</VerticalSwitcherViewWrapper>
    );
  }

  return contentToRender;
};

SwitcherView.propTypes = {
  pages: array
};

SwitcherView.defaultProps = {
  pages: []
};

export default SwitcherView;

const gridVariants = {
  visible: { opacity: 1, scale: 1 },
  hidden: {
    opacity: 0,
    scale: 1.35,
    transitionEnd: {
      scale: 1
    }
  }
};
