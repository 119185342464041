import React, { useRef, useEffect, useCallback, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

// Components
import PortfolioCard from './PortfolioCard';
import { motion } from 'framer-motion';
import SalesPersonCard from './SalesPersonCard';

// Helpers
import to from 'await-to-js';
import { debounce } from 'helpers';
import { isMobileOnly } from 'react-device-detect';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import { NavigationCollection } from '@prompto-api';

// Styling
import styled, { css } from 'styled-components';

import { view } from './PortfolioPage';

// 6 means that every five project cards should be followed by the Sales Person card
const salesPersonCardAppearanceFrequency = 5;

// cards view configuration
const gridGap = isMobileOnly ? 15 : 30;
const numberOfColumns = isMobileOnly ? 1 : window.innerWidth < 800 ? 2 : 3;
const gridShift = 0;

const mobileSpecificStyles = {
  wrapper: css`
    max-height: calc(100vh - 80px);
    padding: 15px;
  `,
  column: css`
    padding-right: 0;
  `
};

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 110px);
  padding: 30px 30px 30px 60px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  ${isMobileOnly && mobileSpecificStyles.wrapper}
`;

const GridItem = styled(motion.div)`
  position: relative;
  flex-shrink: 0;
  padding-bottom: ${gridGap}px;
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  flex: ${100 / numberOfColumns}% 0 0;
  padding-right: ${gridGap}px;
  box-sizing: border-box;
  &:nth-child(2) {
    padding-top: ${gridShift}px;
  }
  ${isMobileOnly && mobileSpecificStyles.column}
`;

export const onScroll = debounce((event, loadNext = () => {}) => {
  if (!event || (event && !event.target)) {
    return;
  }
  const { offsetHeight, scrollTop, scrollHeight } = event.target;
  if (offsetHeight + scrollTop >= scrollHeight * 0.8) {
    loadNext();
  }
}, 100);

const debouncedScrollHandler = debounce((event, dispatch) => {
  dispatch({
    type: 'updateSavedUiState',
    payload: {
      cards: {
        scrollTop: event.target?.scrollTop ?? 0
      }
    }
  });
}, 200);

const PortfolioCardsView = ({
  list,
  sessionToken,
  loadNext,
  endReached,
  onShowcaseSelected,
  vaultSettings,
  projectsData,
  setShowSalesPersonModal,
  salesPersonEnabled,
  salesPerson,
  shouldShowPrices,
  shouldShowStatuses
}) => {
  const [savedStateApplied, setSavedStateApplied] = useState(false);

  // Marketplace state
  const { MarketplaceState, MarketplaceStateDispatch } = useMarketplaceState();
  const { savedUiState } = MarketplaceState;

  const cardsContainer = useRef();

  const updateSavedUiState = useCallback(() => {
    MarketplaceStateDispatch({
      type: 'updateSavedUiState',
      payload: {
        cards: {
          scrollTop: cardsContainer.current?.scrollTop ?? 0
        }
      }
    });
  }, [MarketplaceStateDispatch]);

  useEffect(() => {
    if (savedUiState.cards) {
      setTimeout(() => {
        setSavedStateApplied(true);
        return cardsContainer.current?.scrollTo({
          left: 0,
          top: savedUiState.cards.scrollTop
        });
      }, 200);
    } else {
      setSavedStateApplied(true);
    }
  }, [savedUiState]);

  // Create the cards
  let cards = [];
  if (list) {
    let items = [...list];
    // add the Sales Person cards
    if (salesPersonEnabled) {
      if (list.length <= salesPersonCardAppearanceFrequency) {
        items.push({ type: 'salesPerson' });
      } else {
        let cardsAdded = 0;
        list.forEach((_, i) => {
          if (i && !(i % salesPersonCardAppearanceFrequency)) {
            items.splice(i + cardsAdded, 0, { type: 'salesPerson' });
            cardsAdded++;
          }
        });
      }
    }

    // split items into columns
    const columns = items.reduce((cols, currCard, index) => {
      const columnIndex = index % numberOfColumns;
      const col = cols[columnIndex] ?? [];
      col.push(currCard);
      cols[columnIndex] = col;
      return cols;
    }, []);

    cards = columns.map((column, colIndex) => (
      <Column key={`column-${colIndex}`}>
        {column.map((project, index) => (
          <GridItem
            key={index}
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
          >
            {project?.type === 'salesPerson' ? (
              <SalesPersonCard
                origin={view.cards}
                setShowSalesPersonModal={setShowSalesPersonModal}
                salesPerson={salesPerson}
              />
            ) : (
              <PortfolioCard
                origin={view.cards}
                project={project}
                unitsData={projectsData?.[project.objectId]?.units}
                configuration={projectsData?.[project.objectId]?.configuration}
                status={projectsData?.[project.objectId]?.status}
                vaultSettings={vaultSettings}
                thumbnailUri={project.thumbnailUri}
                onCardClick={async () => {
                  const contentCollectionObjectId =
                    project.vmContentCollection?.objectId;

                  // get the id of the first navigationItem in the collection
                  let navigationItemObjectId;
                  const navCollId = project?.navigationCollection?.objectId;
                  if (navCollId) {
                    const [, result] = await to(
                      NavigationCollection.get(navCollId, true, sessionToken)
                    );
                    if (result) {
                      const navCollection = result.data?.navigationCollection;
                      const navItemList = navCollection?.vmNavigationItemList;
                      if (navItemList && navItemList.length > 0) {
                        navigationItemObjectId = navItemList[0].objectId;
                      }
                    }
                  }

                  updateSavedUiState();

                  onShowcaseSelected(
                    contentCollectionObjectId,
                    navigationItemObjectId,
                    project.objectId
                  );
                }}
                shouldShowPrices={shouldShowPrices}
                shouldShowStatuses={shouldShowStatuses}
              />
            )}
          </GridItem>
        ))}
      </Column>
    ));
  }

  return (
    <Wrapper
      ref={cardsContainer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onScroll={(event) => {
        event.persist();
        savedStateApplied &&
          debouncedScrollHandler(event, MarketplaceStateDispatch);
        !endReached && onScroll(event, loadNext);
      }}
    >
      {cards}
      <ReactResizeDetector
        handleHeight
        handleWidth
        skipOnMount
        onResize={() => {
          // Simulating an onScroll event to make the lazy loading
          // check if it needs to load more, when resizing
          !endReached && onScroll({ target: cardsContainer.current }, loadNext);
        }}
      />
    </Wrapper>
  );
};

export default PortfolioCardsView;
