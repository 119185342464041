import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

// Styling
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useTourState } from 'stores/TourStore';
import localizer from 'localization/localizer';
import {
  getLocalizedProjectTitle,
  getLocalizedProjectDescription
} from 'helpers/project/VmProjectSectionHelper';
import { isMobile } from 'react-device-detect';
import { getCurrentEnvironment } from 'helpers';

// Components
import { IdealImage } from '@prompto-ui';
import CallToActionButton from './CallToActionButton';
import { RichTextRenderer } from '@prompto-ui';

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${({ theme }) => `linear-gradient(
    244deg,
    ${theme.showcaseBlack},
    ${theme.defaultBrandPrimaryColor}
  )`};
  color: ${(props) => props.theme.showcaseWhite};
  display: flex;
  font-family: inherit;
`;

const Overlay = styled(motion.div)`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    244deg,
    rgba(20, 20, 25, 0),
    rgba(20, 20, 25, 0.9)
  );
`;

const ProjectTitle = styled.p`
  font-size: ${isMobile ? '1.875rem' : '3.125rem'};
  font-weight: 600;
  letter-spacing: ${isMobile ? '0.5px' : '0.83px'};
  margin: 0;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.showcaseWhite};
  max-width: ${isMobile ? '90vw' : '80vw'};
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
`;

const ProjectDescription = styled(ProjectTitle)`
  font-size: ${isMobile ? '1.125rem' : '1.5rem'};
  font-size: 1.125rem;
  letter-spacing: ${isMobile ? '0.3px' : '0.4px'};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
`;

const ImageWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  ${({ isImageShown }) =>
    isImageShown &&
    css`
      z-index: 2;
    `}
`;

const ProjectInfoWrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

function getLocalizedValue(showcaseConfiguration, valueToLocalize) {
  const localizedValue =
    showcaseConfiguration?.startScreenSettings?.[valueToLocalize]?.textMap[
      localizer.getLanguage()
    ];
  if (localizedValue?.length > 0) {
    return localizedValue;
  } else {
    return null;
  }
}

const EmbeddedShowcaseCoverImage = ({ show, onClose }) => {
  // Project state
  const { ProjectState } = useProjectState();
  const { project, showcaseConfiguration, navigationItem } = ProjectState;

  // Tour state
  const { TourState } = useTourState();
  const { activeMediaView, mediaViews } = TourState;
  const contentUri = activeMediaView?.contentItem?.contentUri;

  const [mediaViewsUri, setMediaViewsUri] = useState('');
  const [showCoverImage, setShowCoverImage] = useState(true);
  const [showAnimations, setShowAnimations] = useState(true);
  const [indexImageShown, setIndexImageShown] = useState(0);

  const closeSmoothly = () => {
    setShowCoverImage(false);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      onClose();
    }, 250);
  };

  useEffect(() => {
    setShowAnimations(
      showcaseConfiguration?.startScreenSettings?.enableStartScreenAnimations ??
        true
    );
  }, [showcaseConfiguration]);

  useEffect(() => {
    if (showAnimations) {
      const usedImages =
        navigationItem?.navigationCollection?.vmNavigationItemList
          .map((item) => {
            return item?.navigationCollection?.vmNavigationItemList;
          })
          .flat()
          .filter((item) => item.navigationItemType === 'navigationSpot')
          .map((item) => {
            return item.value;
          });
      setMediaViewsUri(
        mediaViews?.filter(
          (item, index) => index === 0 || usedImages.includes(item.objectId)
        )
      );
    }
  }, [mediaViews, navigationItem, showAnimations]);

  const nextImageInterval = useRef(null);

  useEffect(() => {
    if (showAnimations) {
      let index = 0;
      nextImageInterval.current = setInterval(() => {
        if (mediaViewsUri) {
          setIndexImageShown(index);
          index++;
          if (index === mediaViewsUri.length) {
            index = 0;
          }
        }
      }, 3000);
      return () => clearInterval(nextImageInterval.current);
    }
  }, [mediaViewsUri, showAnimations]);

  const wrapperRef = useRef();

  if (!show || !showcaseConfiguration?.showStartScreenForEmbeddedView)
    return null;

  return createPortal(
    <AnimatePresence>
      {showCoverImage && (
        <>
          <Wrapper
            ref={wrapperRef}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {contentUri && (
              <>
                {mediaViewsUri ? (
                  mediaViewsUri.map((item, index) => {
                    return (
                      <ImageWrapper
                        key={index}
                        isImageShown={indexImageShown === index}
                        initial={{ opacity: index ? 0 : 1 }}
                        animate={{
                          opacity: indexImageShown === index ? 1 : 0
                        }}
                        transition={{ duration: 0.5 }}
                        exit={{ opacity: 0 }}
                      >
                        <IdealImage
                          key={index}
                          contentUri={
                            item?.contentItem?.contentUri ?? contentUri
                          }
                          fallbackUri={
                            item?.contentItem?.contentUri ?? contentUri
                          }
                          imageSize={{
                            width: wrapperRef.current.offsetWidth,
                            height: wrapperRef.current.offsetHeight
                          }}
                          containerSize={{
                            width: window.innerWidth,
                            height: window.innerHeight
                          }}
                          mustFillParent={true}
                          baseImageUrl={getCurrentEnvironment().baseImageUrl}
                        />
                      </ImageWrapper>
                    );
                  })
                ) : (
                  <IdealImage
                    key={`embeddedShowcaseCoverImage`}
                    contentUri={contentUri}
                    fallbackUri={contentUri}
                    imageSize={{
                      width: wrapperRef.current.offsetWidth,
                      height: wrapperRef.current.offsetHeight
                    }}
                    containerSize={{
                      width: window.innerWidth,
                      height: window.innerHeight
                    }}
                    mustFillParent={true}
                    baseImageUrl={getCurrentEnvironment().baseImageUrl}
                  />
                )}
              </>
            )}
            <Overlay
              onClick={() => {
                closeSmoothly();
                clearInterval(nextImageInterval.current);
              }}
            >
              <AnimatePresence>
                {(project?.title || project?.description) && (
                  <ProjectInfoWrapper
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <ProjectTitle>
                      {getLocalizedValue(
                        showcaseConfiguration,
                        'startScreenTitle'
                      ) ?? getLocalizedProjectTitle(project)}
                    </ProjectTitle>
                    <ProjectDescription>
                      <RichTextRenderer
                        richText={
                          getLocalizedValue(
                            showcaseConfiguration,
                            'startScreenDescription'
                          ) ?? getLocalizedProjectDescription(project)
                        }
                        defaultFormatting={true}
                        fontSize={'inherit'}
                      />
                    </ProjectDescription>
                  </ProjectInfoWrapper>
                )}
              </AnimatePresence>
              <CallToActionButton
                showAnimations={showAnimations}
                showcaseConfiguration={showcaseConfiguration}
              />
            </Overlay>
          </Wrapper>
        </>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default EmbeddedShowcaseCoverImage;
