import React, { useCallback, useState, useEffect } from 'react';
import { func, number, array, string } from 'prop-types';

// Styling
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

// Components
import MobilePage from '../contentItems/MobilePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import fileCategories from 'configs/SupportedFileCategories.json';
import { isMobileOnly } from 'react-device-detect';

const GalleryWrapper = styled.div`
  position: fixed;
  z-index: 130;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.showcaseBlack};
`;

const Button = styled.button`
  border: none;
  background: ${(props) => props.theme.fadeBlack};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled(Button)`
  padding: 11px;
  position: absolute;
  z-index: 131;
  top: 0;
  right: 0;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: ${(props) => props.theme.primary100};
`;

const NavigationButtons = styled(motion.div)`
  position: absolute;
  z-index: 57;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MobilePageWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const shouldDisablePageSwipingForItemTypes = [fileCategories.image360];

const MobileMediaGallery = ({
  pages,
  activePage,
  videoFallbackThumbnail,
  onClose
}) => {
  const [[page, direction], setPage] = useState([activePage, 0]);
  const [dragAxis, setDragAxis] = useState();
  const [disablePageSwiping, setDisablePageSwiping] = useState(false);
  const [isDragEnabled, setIsDragEnabled] = useState(true);

  // watch a contentItemType of currently visible item
  // if an image360 item is shown we'll disable page swiping and show navigation buttons instead
  useEffect(() => {
    setDisablePageSwiping(
      shouldDisablePageSwipingForItemTypes.includes(
        pages[page]?.contentItemType
      )
    );
  }, [page, pages]);

  const paginate = useCallback(
    (newDirection) => {
      setPage([page + newDirection, newDirection]);
    },
    [page]
  );

  const goToNextPage = useCallback(() => {
    if (page + 1 < pages.length) {
      setTimeout(() => {
        paginate(1);
      }, 0);
    }
  }, [paginate, pages, page]);

  const goToPreviousPage = useCallback(() => {
    if (page > 0) {
      setTimeout(() => {
        paginate(-1);
      }, 0);
    }
  }, [paginate, page]);

  return (
    <AnimatePresence initial={false} custom={direction}>
      <GalleryWrapper>
        {isMobileOnly && (
          <CloseButton onClick={onClose}>
            <StyledIcon icon={['fal', 'times']} size="1x" />
          </CloseButton>
        )}

        <AnimatePresence>
          {disablePageSwiping && (
            <NavigationButtons
              initial={{ y: -30, x: -40 }}
              animate={{ y: 0, x: -40 }}
              exit={{ y: -30, x: -40 }}
            >
              <Button onClick={() => goToPreviousPage()}>
                <StyledIcon icon={['fal', 'arrow-left']} size="1x" />
              </Button>
              <Button onClick={() => goToNextPage()}>
                <StyledIcon icon={['fal', 'arrow-right']} size="1x" />
              </Button>
            </NavigationButtons>
          )}
        </AnimatePresence>

        <MobilePageWrapper
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 }
          }}
          drag={!disablePageSwiping && isDragEnabled}
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          dragElastic={0.5}
          dragDirectionLock={true}
          onDirectionLock={(axis) => setDragAxis(axis)}
          onDragEnd={(e, info) => {
            const { offset, velocity } = info;

            // Horizontal swipe
            if (dragAxis === 'x') {
              const horizontalSwipe = swipePower(offset.x, velocity.x);
              if (horizontalSwipe < -swipeConfidenceThreshold) {
                goToNextPage();
              } else if (horizontalSwipe > swipeConfidenceThreshold) {
                goToPreviousPage();
              }
            }
            // Vertical swipe
            else if (dragAxis === 'y') {
              const verticalSwipe = swipePower(offset.y, velocity.y);
              if (
                verticalSwipe < -swipeConfidenceThreshold ||
                verticalSwipe > swipeConfidenceThreshold
              ) {
                onClose();
              }
            }
          }}
        >
          <MobilePage
            data={pages[page ?? 0]}
            videoFallbackThumbnail={videoFallbackThumbnail}
            onClose={onClose}
            autoplay={true}
            onNextPage={goToNextPage}
            onPreviousPage={goToPreviousPage}
            setIsDragEnabled={setIsDragEnabled}
            isDragEnabled={isDragEnabled}
          />
        </MobilePageWrapper>
      </GalleryWrapper>
    </AnimatePresence>
  );
};

MobileMediaGallery.propTypes = {
  pages: array,
  activePage: number,
  videoFallbackThumbnail: string,
  onClose: func
};

MobileMediaGallery.defaultProps = {
  pages: [],
  activePage: 0,
  videoFallbackThumbnail: '',
  onClose: () => {}
};

export default MobileMediaGallery;

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  }
};
