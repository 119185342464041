import React, { useState, useEffect } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';
import { useUiState } from 'stores/UiStore';
import { useProjectState } from 'stores/ProjectStore';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { getValueFromLocalized } from 'helpers';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const buttonSize = isMobileOnly ? 50 : 60;

const StyledButton = styled(motion.button)`
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.3);
  background-color: ${({ theme, limited }) =>
    limited ? theme.gray400 : theme.defaultFloatingContactUsButtonColor};
  transition: background-color 150ms ease;
  &:hover {
    cursor: pointer;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    color: ${(props) => props.theme.showcaseWhite};
    margin: 0 0 0 15px;
    padding: 0;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.showcaseWhite};
  font-size: ${isMobileOnly ? 20 : 26}px;
`;

const ContactUsFloatingButton = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [shouldAnimateButton, setShouldAnimateButton] = useState(false);

  // UI state
  const { UiStateDispatch } = useUiState();

  // Project state
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration, favoritesList } = ProjectState;
  const embededUnitFormConfiguration =
    showcaseConfiguration?.embededUnitFormConfiguration;

  useEffect(() => {
    if (isHovered) {
      setShouldAnimateButton(isHovered && !isMobile);
    }
  }, [isHovered]);

  const openContactUsForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    UiStateDispatch({
      type: 'update',
      payload: { showContactUsModal: true }
    });
  };

  const shouldAskToFillInFavorites =
    favoritesList.length === 0 &&
    embededUnitFormConfiguration?.askToFillInFavorites;

  const buttonName = getValueFromLocalized(
    embededUnitFormConfiguration?.localizedCustomButtonName,
    embededUnitFormConfiguration?.customButtonName || localizer.contactUs
  );

  if (embededUnitFormConfiguration?.showContactUsFormAsGeneric !== true)
    return null;

  return (
    <StyledButton
      initial={{ width: buttonSize }}
      animate={{ width: isHovered ? 'auto' : buttonSize }}
      onClick={openContactUsForm}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      limited={shouldAskToFillInFavorites}
    >
      <StyledIcon icon={['fal', 'envelope']} size="1x" />
      {isHovered && (
        <span
          initial={{ scaleX: 0 }}
          animate={{
            scaleX: shouldAnimateButton ? 1 : 0
          }}
        >
          {buttonName}
        </span>
      )}
    </StyledButton>
  );
};

export default ContactUsFloatingButton;
