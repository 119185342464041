import React, { useState, Fragment } from 'react';

// Components
import { NoPreviewPlaceholderMasked } from 'resources/icons';
import { motion } from 'framer-motion';

// Helpers
import { getCurrentEnvironment } from 'helpers';

// Styling
import styled, { css, keyframes } from 'styled-components';

import { view } from './PortfolioPage';

const sharedThumbnailStyle = `
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const Thumbnail = styled.div`
  ${sharedThumbnailStyle}
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const StyledNoPreviewPlaceholderMasked = styled(NoPreviewPlaceholderMasked)`
  width: 100%;
`;

const slidingFrontSunbeam = keyframes`
    0% { left: -80% }
    100% { left: 120% }
`;

const ThumbnailImageOverlay = styled.div`
  ${sharedThumbnailStyle}
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  ${({ origin, hovered, animate }) => {
    let styles = css`
      background-image: linear-gradient(
        to bottom,
        transparent ${hovered && origin !== view.cards ? '0%' : '65%'},
        rgba(0, 0, 0, 0.8) 100%
      );
      transition: all 150ms ease;
    `;
    if (animate) {
      styles = css`
        ${styles}
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 80%;
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.25) 30%,
            rgba(255, 255, 255, 0.37) 54%,
            rgba(255, 255, 255, 0.25) 70%,
            rgba(255, 255, 255, 0)
          );
          transform: skew(-8deg);
          animation: ${slidingFrontSunbeam} 2s infinite ease-out;
        }
      `;
    }
    return styles;
  }}
`;

const ThumbnailImage = styled(motion.img)`
  ${sharedThumbnailStyle}
  object-fit: cover;
  z-index: 1;
`;

const HdThumbnailImage = styled(ThumbnailImage)`
  z-index: 2;
`;

const PortfolioCardThumbnail = ({ uri, height, hovered = false, origin }) => {
  const [hdThumbnailLoaded, setHdThumbnailLoaded] = useState(false);
  const [lowQualityThumbnailLoaded, setLowQualityThumbnailLoaded] =
    useState(false);

  let thumbnail = (
    <Thumbnail>
      <StyledNoPreviewPlaceholderMasked />
    </Thumbnail>
  );

  if (uri && height) {
    const hqImageHeight = origin === view.map ? height * 3 : height;
    thumbnail = (
      <Fragment>
        <HdThumbnailImage
          initial={{ opacity: 0 }}
          animate={{ opacity: hdThumbnailLoaded ? 1 : 0 }}
          src={`${
            getCurrentEnvironment().baseImageUrl
          }/q=100:h=${hqImageHeight}/${uri}`}
          alt={`Project HD thumbnail`}
          onLoad={() => {
            setHdThumbnailLoaded(true);
          }}
        />
        <ThumbnailImage
          initial={{ opacity: 0 }}
          animate={{ opacity: lowQualityThumbnailLoaded ? 1 : 0 }}
          src={`${
            getCurrentEnvironment().baseImageUrl
          }/q=50:h=${height}/${uri}`}
          alt={`Project thumbnail`}
          onLoad={() => {
            setLowQualityThumbnailLoaded(true);
          }}
        />
        <ThumbnailImageOverlay
          hovered={hovered}
          origin={origin}
          animate={!lowQualityThumbnailLoaded}
        />
      </Fragment>
    );
  }

  return thumbnail;
};

export default PortfolioCardThumbnail;
