import React, { useRef, useEffect, useState } from 'react';
import {
  shape,
  string,
  number,
  boolean,
  func,
  arrayOf,
  oneOfType
} from 'prop-types';

// Helpers
import localizer from 'localization/localizer';
import { unitStatesNotArchived } from 'helpers/units/VmUnitHelper';

// Components
import FilterSliderCard from './FilterSliderCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';

// Styling
import styled from 'styled-components';
import FilterArrayToggleCard from './FilterArrayToggleCard';

const ChipContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

const Chip = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 12px 15px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.primary100};
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;
`;

const ClearIcon = styled(FontAwesomeIcon)`
  position: relative;
  width: 20px;
  left: -10px;
  top: -5px;
  font-size: 14px;
  color: ${(props) => props.theme.gray400};
  background: ${(props) => props.theme.whitePure};
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.theme.primary100};
`;

/**
 * Component used to manage a specific filter it opens and closes the edit filter card and allows the filter to be deleted
 */
const FilterChip = ({ chip, onUpdateValues, onDelete }) => {
  const [currentValues, setCurrentValues] = useState();
  const [isShown, setIsShown] = useState(false);

  const tippyRef = useRef();

  useEffect(() => {
    if (chip.new) {
      tippyRef.current._tippy.show();
    }
  }, [chip]);

  let contentToDisplay;
  if (chip.filterOption === 'state') {
    contentToDisplay = (
      <FilterArrayToggleCard
        arrayToFilter={unitStatesNotArchived}
        initialValues={chip.values}
        onUpdateValues={(newValues) => {
          setCurrentValues(newValues);
        }}
        onConfirm={(newValues) => {
          onUpdateValues(chip.filterOption, newValues);
          tippyRef.current._tippy.hide();
        }}
      />
    );
  } else {
    contentToDisplay = (
      <FilterSliderCard
        key={`filterSliderCard${chip.filterOption}`}
        filterOption={chip.filterOption}
        initialValues={chip.values}
        isShown={isShown}
        limits={chip.limits}
        onUpdateValues={(newValues) => {
          setCurrentValues(newValues);
        }}
        onConfirm={(newValues) => {
          onUpdateValues(chip.filterOption, newValues);
          tippyRef.current._tippy.hide();
        }}
      />
    );
  }

  return (
    <ChipContainer>
      <Tippy
        ref={tippyRef}
        key="tooltip"
        placement="bottom"
        theme="unitFilter"
        trigger="click"
        interactive={true}
        hideOnClick={true}
        onTrigger={() => {
          setIsShown(true);
        }}
        onHidden={() => {
          setIsShown(false);
        }}
        arrow={false}
        zIndex={500}
        content={contentToDisplay}
        onClickOutside={() => {
          onUpdateValues(chip.filterOption, currentValues);
        }}
      >
        <Chip data-testid={`filterChip${chip.filterOption}`}>
          <Label>{localizer.unitFields[chip.filterOption].toUpperCase()}</Label>
        </Chip>
      </Tippy>
      <ClearIcon
        data-testid="clearIcon"
        icon={['fas', 'times-circle']}
        size="1x"
        onClick={() => {
          onDelete(chip);
        }}
      />
    </ChipContainer>
  );
};

FilterChip.propTypes = {
  /** The chip that holds the data needed to filter */
  chip: shape({
    // The filter option is the field choosen by the user to filter on, for example 'price' or 'surface'
    filterOption: string.isRequired,
    limits: shape({ min: number.isRequired, max: number.isRequired }),
    values: oneOfType([
      shape({ min: number.isRequired, max: number.isRequired }),
      arrayOf(string).isRequired
    ]),
    // If this chip is newly created or has already been editted, used to display the tippy by default or not
    new: boolean
  }).isRequired,
  /** Callback when the user confirmed the filter values
   * @callback
   * @param {{min, max}} values The filter values that were chosen
   */
  onUpdateValues: func.isRequired,
  /** Callback when the user clicks on the delete button
   * @callback
   * @param {object} chip The current chip we want to delete
   */
  onDelete: func.isRequired
};

export default FilterChip;
