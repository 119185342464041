import { useState, useEffect } from 'react';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import {
  addQueryParams,
  removeQueryParams,
  fetchSettingsFromURL
} from 'helpers';

const stringifyUnitFiltersAndSearchValue = (unitFilters, filterSearchValue) => {
  let stringifiedParams = '';
  if (filterSearchValue) {
    stringifiedParams += `[search=${filterSearchValue}]`;
  }
  if (unitFilters) {
    // unit statuses are listed in array
    // other filter values are objects with the shape of
    // { min: number, max: number }
    unitFilters.forEach(({ values, filterOption, limits }) => {
      if (Array.isArray(values)) {
        stringifiedParams += `[${filterOption}=${values.join(',')}]`;
      } else if (typeof values === 'object') {
        stringifiedParams += `[${filterOption}=${values.min}_${values.max}|${limits.min}_${limits.max}]`;
      }
    });
  }
  return stringifiedParams;
};

const getMinMaxRange = (stringifiedValue) => {
  const [min, max] = stringifiedValue.split('_');
  return { min, max };
};

const convertQueryStringToFiltersAndSearchValue = (queryParam) => {
  const initialFilters = [];
  let initialSearchValue = '';
  queryParam
    .split('[')
    .filter(Boolean)
    .forEach((param) => {
      const trimmedParam = param.replace(']', '');
      const [filterOption, value] = trimmedParam.split('=');
      if (filterOption === 'search') {
        initialSearchValue = value;
      } else if (['state', 'buildingObjectId'].includes(filterOption)) {
        initialFilters.push({
          filterOption,
          values: [...value.split(',')]
        });
      } else {
        const [values, limits] = value.split('|');
        initialFilters.push({
          filterOption,
          values: getMinMaxRange(values),
          limits: getMinMaxRange(limits)
        });
      }
    });
  return { initialFilters, initialSearchValue };
};

const useUnitsFilterParams = (hideFilters = false) => {
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { unitFilters, filterSearchValue } = ProjectState;

  const [unitsFilterStringified, setUnitsFilterStringified] = useState('');
  const [areInitialFilterValuesApplied, setInitialFilterValuesApplied] =
    useState(false);

  useEffect(() => {
    if (hideFilters) {
      ProjectStateDispatch({
        type: 'setProjectData',
        payload: {
          unitFilters: [],
          filterSearchValue: ''
        }
      });
    }
  }, [hideFilters, ProjectStateDispatch]);

  // when page is rendered check if there is 'filter' query param presented
  // if it is set unitFilters and filterSearchValue accordingly
  // it should be done just once
  useEffect(() => {
    if (hideFilters) return;
    if (areInitialFilterValuesApplied) return;
    const queryParams = fetchSettingsFromURL();
    const filterParam = queryParams?.filter;
    if (filterParam) {
      const { initialFilters, initialSearchValue } =
        convertQueryStringToFiltersAndSearchValue(filterParam);
      ProjectStateDispatch({
        type: 'setProjectData',
        payload: {
          unitFilters: [...initialFilters],
          filterSearchValue: initialSearchValue
        }
      });
    }
    setInitialFilterValuesApplied(true);
  }, [areInitialFilterValuesApplied, ProjectStateDispatch, hideFilters]);

  useEffect(() => {
    setUnitsFilterStringified(
      stringifyUnitFiltersAndSearchValue(unitFilters, filterSearchValue)
    );
  }, [unitFilters, filterSearchValue]);

  // add filter query param if units filter params are set
  // and remove when filter params are reset
  useEffect(() => {
    if (hideFilters) return;
    if (unitsFilterStringified) {
      addQueryParams([{ filter: unitsFilterStringified }]);
    } else {
      removeQueryParams([{ filter: '' }]);
    }
  }, [unitsFilterStringified, hideFilters]);
};

export default useUnitsFilterParams;
