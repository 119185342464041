import React, { useReducer, createContext, useContext } from 'react';
import { isMobile } from 'react-device-detect';

export const Context = createContext();

const UiStore = (props) => {
  const [UiState, UiStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'update':
          return {
            ...state,
            ...action.payload
          };
        case 'requestHideMinimap':
          return {
            ...state,
            showMinimap: false
          };
        case 'increaseStack':
          return {
            ...state,
            stackLength: state.stackLength + 1
          };
        case 'decreaseStack':
          return {
            ...state,
            stackLength: state.stackLength - 1
          };
        case 'setUTMdata': {
          return {
            ...state,
            utmSource: action.payload.utmSource,
            utmCampaign: action.payload.utmCampaign
          };
        }
        default:
          return state;
      }
    },
    {
      showMenu: false,
      isResourcesActive: true,
      hasMinimap: false,
      showMinimap: true,
      minimapSize: { width: 200, height: 200 },
      requestHideMinimap: false,
      subMenu: null,
      showContactUsModal: false,
      contactUsActiveUnit: null,
      showFavorites: false,
      navigationFloatingButtonsBottomPosition:
        navigationFloatingButtonsBottomPosition.default,
      showUspMobileTooltip: false,
      activeUsp: null,
      enlargedUsp: null,
      isEmbeddedShowcase: false,
      shouldRemoveOrientationDisclaimer: false,
      resourcesHasDocuments: true,
      hideTourControls: false,
      enableShowcaseBackButton: false,
      disableMenu: false,
      stackLength: 0,
      utmSource: '',
      utmCampaign: ''
    }
  );
  return (
    <Context.Provider value={{ UiState, UiStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useUiState = () => useContext(Context);

export default UiStore;

export const navigationFloatingButtonsBottomPosition = {
  default: isMobile ? 70 : 95,
  overTourPreview: 185,
  unitDetailsPage: 15,
  overZoomIndicator: isMobile ? 70 : 220,
  mobileLandscape: 10,
  overUnitFilterTooltip: isMobile ? 70 : 280
};
