import React from 'react';
import { shape, number, string, oneOfType } from 'prop-types';

// Components
import Album360Preview from './types/album360/Album360Preview';
import DocumentPreview from './types/document/DocumentPreview';
import ImagePreview from './types/image/ImagePreview';
import VideoPreview from './types/video/VideoPreview';

// Helpers
import fileCategories from 'configs/SupportedFileCategories.json';

// Styles
import styled from 'styled-components';

const PreviewWrapper = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
`;

const Preview = (props) => {
  const { data, pageIndex, size, videoFallbackThumbnail, isVerticalLayout } =
    props;
  const sharedProps = { data, size, isVerticalLayout };

  // Preview
  const createPreview = () => {
    switch (data.contentItemType) {
      case fileCategories.image:
      case fileCategories.turntable: {
        let icon;
        if (data.contentItemType === fileCategories.turntable) {
          icon = 'cube';
        }
        return (
          <ImagePreview pageIndex={pageIndex} {...sharedProps} icon={icon} />
        );
      }
      case fileCategories.video: {
        return (
          <VideoPreview
            {...sharedProps}
            fallbackThumbnail={videoFallbackThumbnail}
          />
        );
      }
      case fileCategories.document: {
        return <DocumentPreview {...sharedProps} />;
      }
      case fileCategories.album360: {
        return <Album360Preview {...sharedProps} />;
      }
      default: {
        return <ImagePreview {...sharedProps} />;
      }
    }
  };

  const preview = createPreview();

  return <PreviewWrapper key={data.name}>{preview}</PreviewWrapper>;
};

Preview.propTypes = {
  data: shape({}).isRequired,
  pageIndex: number,
  pageHeight: oneOfType([number, string]),
  videoFallbackThumbnail: string
};

Preview.defaultProps = {
  pageIndex: 0,
  pageHeight: 400,
  videoFallbackThumbnail: null
};

export default Preview;
