import React, {
  useState,
  useEffect,
  useCallback,
  createRef,
  useRef,
  Fragment
} from 'react';
import { array, shape, string } from 'prop-types';

// Components
import Preview from '../contentItems/Preview';
import ProjectInfo from './ProjectInfo';
import VerticalGrid from './VerticalGrid';
import ContentSection from './ContentSection';

// Helpers
import { debounce, roundUpToNearestEvenNumber } from 'helpers';
import { clamp } from '@prompto-helpers';
import { HorizontalMasonry } from 'components/ui';
import { useSwitcherState } from 'stores/SwitcherStore';
import { useKeyPress } from 'helpers/customHooks';
import { motion } from 'framer-motion';
import useResize from 'use-resize';
import { useProjectState } from 'stores/ProjectStore';
import { isMobile, isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';

// Styles
import styled, { css } from 'styled-components';

// Constants
const gridGap = 60;
const arrowKeyStepSize = 500;
const scrollSpeedMultiplier = 0.66;

const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BigPreviewWrapper = styled(PreviewWrapper)`
  margin-right: ${(props) => (props.showFolders ? 0 : gridGap / 2)}px;
  ${(props) =>
    props.isVerticalLayout &&
    `
    width: 100%;
    margin: 0;
    margin-bottom: ${gridGap / 2}px;
    box-sizing: border-box;
    justify-content: flex-start;
  `}
`;

const HorizontalMasonryWrapper = styled.div`
  height: ${isMobile && !isMobileOnly ? 'calc(100% - 70px)' : '100%'};
  padding: ${({ showFolders }) =>
    showFolders ? '20px 45px 20px 90px' : '20px 45px'};
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  position: relative;
  align-self: ${isMobile && !isMobileOnly ? 'flex-end' : 'center'};
`;

const MasonryGridWrapper = styled.div`
  margin-left: 30px;
`;

const AnimationWrapper = styled(motion.div)`
  height: calc(100% - ${isMobile ? 60 : 80}px);
  display: flex;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const VerticalAnimationWrapper = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
`;

const VerticalGridWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px 90px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  position: relative;
  min-height: calc(100vh - 200px);
`;

const Row = styled.div`
  display: flex;
  height: 100%;
`;

const RowWithTitle = styled(Row)`
  height: calc(100% - 60px);
  position: relative;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const VDivider = styled.div`
  height: calc(100% - 100px);
  margin: 0 40px 20px;
  width: 1px;
  background: ${(props) => props.theme.gray300};
  align-self: flex-end;
`;

const masonryNoGapGridStyles = css`
  width: auto !important;
  height: 100%;
  overflow: visible;
  margin-left: ${({ isFirstFolder }) => (isFirstFolder ? 30 : 0)}px;
`;

const masonryGridStyles = css`
  ${masonryNoGapGridStyles}
  gap: 30px;
`;

const masonryGridRowStyles = `
  width: fit-content;
  flex-grow: 0 !important;
  max-height: calc(50% - 15px);
  min-height: calc(50% - 15px);
`;

const masonryGridItemStyles = `
  margin: 0 30px 0 0;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const verticalLayoutBigImageHeight = window.innerHeight - 200;

const SwitcherViewGrid = (props) => {
  const {
    pages,
    folders,
    showFolders,
    dragControls,
    videoFallbackThumbnail,
    foldersRefs,
    localDragOffset,
    setShowFolderNavigation
  } = props;

  const { SwitcherState, SwitcherStateDispatch } = useSwitcherState();
  const { ProjectState } = useProjectState();

  const { showcaseConfiguration } = ProjectState;

  const {
    activePage,
    activeFilter,
    showGrid,
    masonryGrid,
    mediaMasonryGrid,
    dragXOffsetValue,
    verticalGrid,
    activeFolderRef,
    enableOnUpdateEvent
  } = SwitcherState;

  const [indexedPreviewList, setIndexedPreviewList] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [gridHeight, setGridHeight] = useState();
  const [defaultPageSize, setDefaultPageSize] = useState(0);
  const size = useResize();

  const projectInfoRef = useRef();

  useEffect(() => {
    localDragOffset.set(dragXOffsetValue);
  }, [dragXOffsetValue, localDragOffset]);

  // Grid refs
  const masonryGridItems = useRef(
    [...Array(pages.length)].map(() => createRef())
  );

  // Keypress events
  const nextKeyPressed = useKeyPress('ArrowRight', false, false);
  const previousKeyPressed = useKeyPress('ArrowLeft', false, false);
  const escapeKeyPressed = useKeyPress('Escape', false, false);

  // Callbacks
  const updateDragXOffset = useCallback(
    (offset, delay) => {
      if (activePage === 0 && delay) {
        localDragOffset.set(0);
        return;
      }

      const gridWidth = masonryGrid.current?.offsetWidth;
      const maxXOffset = gridWidth > 0 ? -(gridWidth - window.innerWidth) : 0;
      const newOffset = clamp(localDragOffset.current + offset, maxXOffset, 0);

      // Added delay so the position does not instantly move when
      // the grid fades out.
      if (delay) {
        setTimeout(() => {
          localDragOffset.set(newOffset);
        }, 500);
      } else {
        localDragOffset.set(newOffset);
      }
    },
    [localDragOffset, activePage, masonryGrid]
  );

  const recalculateGridHeights = useCallback(() => {
    const refHeight = masonryGrid.current.offsetHeight;
    const gridHeight = roundUpToNearestEvenNumber(refHeight) - gridGap;
    const gridItemHeight = Math.floor((gridHeight - gridGap) / 2);

    setGridHeight(gridHeight);
    setDefaultPageSize(gridItemHeight);
  }, [masonryGrid]);

  const handleMouseWheel = useCallback(
    (e) => {
      SwitcherStateDispatch({
        type: 'toggleOnUpdateEvent',
        payload: true
      });
      if (
        !showGrid ||
        showcaseConfiguration?.isVerticalScroll ||
        projectInfoRef.current?.contains(e.target)
      ) {
        return;
      }

      updateDragXOffset(e.wheelDelta * scrollSpeedMultiplier);
    },
    [updateDragXOffset, showGrid, showcaseConfiguration, SwitcherStateDispatch]
  );

  const handleScroll = useCallback(
    debounce((e) => {
      SwitcherStateDispatch({
        type: 'toggleOnUpdateEvent',
        payload: true
      });
      if (
        !showGrid ||
        !showcaseConfiguration?.isVerticalScroll ||
        projectInfoRef.current?.contains(e.target)
      ) {
        return;
      }

      const foldersBounds = foldersRefs
        ? Object.entries(foldersRefs).map(([uuid, ref]) => ({
            uuid,
            bounds: ref.current.getBoundingClientRect()
          }))
        : [];

      const visibleFolder = foldersBounds.find(
        (folder) =>
          folder.bounds.y <= window.innerHeight / 2 &&
          folder.bounds.y + folder.bounds.height >= window.innerHeight / 2
      );
      if (visibleFolder) {
        // we need timeout to set active folder only after it was scrolled into view
        const timer = setTimeout(() => {
          clearTimeout(timer);
          SwitcherStateDispatch({
            type: 'setActiveFolder',
            payload: visibleFolder.uuid
          });
        }, 120);
      }
    }, 100),
    [updateDragXOffset, showGrid, showcaseConfiguration]
  );

  const onResize = debounce(() => {
    if (masonryGrid.current) {
      recalculateGridHeights();
    }
  }, 150);

  // Close page swiper on escape key press
  useEffect(() => {
    if (escapeKeyPressed && !showGrid) {
      SwitcherStateDispatch({ type: 'setShowGrid', payload: true });
    }
  }, [escapeKeyPressed, showGrid, SwitcherStateDispatch]);

  useEffect(() => {
    onResize();
  }, [size, onResize]);

  // Scroll section into view when respective navigation item is clicked
  useEffect(() => {
    if (activeFolderRef && showcaseConfiguration?.isVerticalScroll) {
      activeFolderRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [activeFolderRef, showcaseConfiguration]);

  // Make sure the grid is focussed on the currently active page
  useEffect(() => {
    const ref = masonryGridItems.current;
    if (!ref || activePage >= ref.length || activePage < 0) {
      return;
    }
    const smallPageElement = ref[activePage].current;
    if (smallPageElement) {
      if (showcaseConfiguration?.isVerticalScroll) {
        smallPageElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      } else {
        const bounds = smallPageElement.getBoundingClientRect();
        updateDragXOffset(
          -(bounds.left + bounds.width / 2) + window.innerWidth / 2,
          true
        );
      }
    }
  }, [activePage, updateDragXOffset, masonryGrid, showcaseConfiguration]);

  // Whenever the grid ref updates, recalculate the height of the grid
  useEffect(() => {
    if (masonryGrid.current) {
      recalculateGridHeights();
    }
  }, [masonryGrid, recalculateGridHeights, dragControls]);

  // Whenever the pages or the filter changes, recalculate the gridItems
  useEffect(() => {
    const indexedPages = pages.map((page, index) => ({
      ...page,
      pageIndex: index
    }));

    setIndexedPreviewList(indexedPages);
  }, [activeFilter, pages]);

  // Arrow keys binding
  useEffect(() => {
    if (previousKeyPressed && showGrid) {
      updateDragXOffset(arrowKeyStepSize);
    }
  }, [previousKeyPressed, updateDragXOffset, showGrid]);

  useEffect(() => {
    if (nextKeyPressed && showGrid) {
      updateDragXOffset(-arrowKeyStepSize);
    }
  }, [nextKeyPressed, updateDragXOffset, showGrid]);

  // Mouse wheel binding
  useEffect(() => {
    window.addEventListener('mousewheel', handleMouseWheel);
    return () => {
      window.removeEventListener('mousewheel', handleMouseWheel);
    };
  }, [handleMouseWheel]);

  // Scroll binding
  useEffect(() => {
    if (!showcaseConfiguration?.isVerticalScroll) return;
    const verticalGridRef = verticalGrid.current;
    verticalGridRef.addEventListener('scroll', handleScroll, true);
    return () => {
      verticalGridRef.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll, masonryGrid, verticalGrid, showcaseConfiguration]);

  // Events
  const onPageClick = (e, index) => {
    if (isDragging) {
      return;
    }
    SwitcherStateDispatch({ type: 'setGridItemClicked', payload: true });
    SwitcherStateDispatch({ type: 'setActivePage', payload: index });
    SwitcherStateDispatch({ type: 'setShowGrid', payload: false });
  };

  const createMasonryGrids = (indexedPageList) => {
    return indexedPageList
      .map((page) => {
        const content = (
          <Preview
            key={page.pageIndex}
            data={page}
            pageIndex={page.pageIndex}
            pageHeight={defaultPageSize}
            size={defaultPageSize}
            videoFallbackThumbnail={videoFallbackThumbnail}
            isVerticalLayout={showcaseConfiguration?.isVerticalScroll}
          />
        );

        return (
          <PreviewWrapper
            key={page.pageIndex}
            onClick={(e) => onPageClick(e, page.pageIndex)}
            ref={masonryGridItems.current[page.pageIndex]}
          >
            {content}
          </PreviewWrapper>
        );
      })
      .filter((x) => x);
  };

  const allPreviews = createMasonryGrids(indexedPreviewList);

  const bigMediaPreview = (preview) => {
    if (!preview) return null;

    return (
      <BigPreviewWrapper
        key={preview.pageIndex}
        onClick={(e) => onPageClick(e, preview.pageIndex)}
        ref={masonryGridItems.current[preview.pageIndex]}
        isVerticalLayout={showcaseConfiguration?.isVerticalScroll}
        showFolders={showFolders}
      >
        <Preview
          key={preview.pageIndex}
          data={preview}
          pageIndex={preview.pageIndex}
          size={
            showcaseConfiguration?.isVerticalScroll
              ? verticalLayoutBigImageHeight
              : defaultPageSize * 2 + 26
          }
          videoFallbackThumbnail={videoFallbackThumbnail}
          isVerticalLayout={showcaseConfiguration?.isVerticalScroll}
        />
      </BigPreviewWrapper>
    );
  };

  const horizontalLayout = showFolders ? (
    <>
      {folders.map((folder, idx) => {
        const [start, end] = folder.itemsRange;
        if (start === end) return null;
        const isFirstFolder = idx === 0;
        const isLastFolder = idx === folders.length - 1;
        const previews = allPreviews.slice(start, end);
        return (
          <Fragment key={folder.title}>
            <ContentSection
              layoutType="horizontal"
              title={folder.title}
              ref={foldersRefs?.[folder.uuid]}
              noGap
            >
              <RowWithTitle>
                {isFirstFolder && bigMediaPreview(indexedPreviewList[start])}
                <MasonryGridWrapper>
                  {previews.length > 0 && (
                    <HorizontalMasonry
                      rows={2}
                      isFirstFolder={isFirstFolder}
                      gridStyles={masonryGridStyles}
                      gridRowStyles={masonryGridRowStyles}
                      gridItemStyles={masonryGridItemStyles}
                      gridItemHeight={(gridHeight - gridGap) / 2}
                    >
                      {previews.slice(isFirstFolder ? 1 : 0)}
                    </HorizontalMasonry>
                  )}
                </MasonryGridWrapper>
              </RowWithTitle>
            </ContentSection>

            {!isLastFolder && <VDivider />}
          </Fragment>
        );
      })}
    </>
  ) : (
    <ContentSection layoutType="horizontal">
      <Row>
        {bigMediaPreview(indexedPreviewList[0])}
        <MasonryGridWrapper ref={mediaMasonryGrid}>
          <HorizontalMasonry
            key="mediaMasonryGrid"
            rows={2}
            gridStyles={masonryNoGapGridStyles}
            gridRowStyles={masonryGridRowStyles}
            gridItemStyles={masonryGridItemStyles}
            gridItemHeight={(gridHeight - gridGap) / 2}
          >
            {allPreviews.slice(1)}
          </HorizontalMasonry>
        </MasonryGridWrapper>
      </Row>
    </ContentSection>
  );

  const verticalIndexedPreviewList = indexedPreviewList.map((x) => ({
    ...x,
    ref: masonryGridItems.current[x.pageIndex]
  }));

  const verticalLayout = showFolders ? (
    <>
      {folders.map((folder) => {
        const [start, end] = folder.itemsRange;
        const previews = verticalIndexedPreviewList.slice(start, end);
        return (
          <ContentSection
            layoutType="vertical"
            key={folder.title}
            title={folder.title}
            ref={foldersRefs?.[folder.uuid]}
          >
            <Column>
              <VerticalGrid
                indexedPageList={previews}
                videoFallbackThumbnail={videoFallbackThumbnail}
                onPageClick={onPageClick}
              />
            </Column>
          </ContentSection>
        );
      })}
    </>
  ) : (
    <ContentSection layoutType="vertical">
      <Column>
        <VerticalGrid
          indexedPageList={verticalIndexedPreviewList}
          videoFallbackThumbnail={videoFallbackThumbnail}
          onPageClick={onPageClick}
        />
      </Column>
    </ContentSection>
  );

  let content = (
    <AnimationWrapper
      style={{
        x: localDragOffset,
        transition: enableOnUpdateEvent ? 'none' : `all 500ms ease`
      }}
      drag="x"
      dragControls={dragControls}
      dragConstraints={{
        left: masonryGrid.current
          ? -(masonryGrid.current?.offsetWidth - window.innerWidth)
          : 0,
        right: 0
      }}
      onDragStart={() => {
        SwitcherStateDispatch({
          type: 'toggleOnUpdateEvent',
          payload: true
        });
        setIsDragging(true);
      }}
      onDragEnd={() => {
        setTimeout(() => {
          setIsDragging(false);
        }, 10);
      }}
      onAnimationStart={() => setShowFolderNavigation(false)}
      onAnimationComplete={() => setShowFolderNavigation(true)}
      onUpdate={(latest) => {
        if (!enableOnUpdateEvent) return;
        if (!foldersRefs) return;
        // Update active folder based on scroll info

        const foldersBounds = foldersRefs
          ? Object.entries(foldersRefs).map(([uuid, ref]) => ({
              uuid,
              bounds: ref.current?.getBoundingClientRect()
            }))
          : [];

        const visibleFolder = foldersBounds.find(
          (folder) =>
            folder.bounds.x <= window.innerWidth / 2 &&
            folder.bounds.x + folder.bounds.width >= window.innerWidth / 2
        );
        if (visibleFolder) {
          SwitcherStateDispatch({
            type: 'setActiveFolder',
            payload: visibleFolder.uuid
          });
        }
      }}
    >
      <HorizontalMasonryWrapper ref={masonryGrid} showFolders={showFolders}>
        {/* 20px to compensate vertical offset between ProjectInfo and bigMediaPreview */}
        <ContentSection
          layoutType="horizontal"
          title={showFolders ? capitalize(localizer.information) : ''}
        >
          <ProjectInfo
            ref={projectInfoRef}
            maxHeight={gridHeight - 20}
            maxWidth={'430px'}
          />
        </ContentSection>

        {horizontalLayout}
      </HorizontalMasonryWrapper>
    </AnimationWrapper>
  );

  if (showcaseConfiguration?.isVerticalScroll) {
    content = (
      <VerticalAnimationWrapper ref={verticalGrid}>
        <VerticalGridWrapper ref={masonryGrid}>
          <ContentSection
            layoutType="vertical"
            title={showFolders ? capitalize(localizer.information) : ''}
          >
            <ProjectInfo ref={projectInfoRef} maxWidth={`60vw`} />
          </ContentSection>

          {verticalLayout}
        </VerticalGridWrapper>
      </VerticalAnimationWrapper>
    );
  }

  return content;
};

SwitcherViewGrid.propTypes = {
  dragControls: shape({}).isRequired,
  pages: array,
  videoFallbackThumbnail: string
};

SwitcherViewGrid.defaultProps = {
  pages: [],
  videoFallbackThumbnail: null
};

export default SwitcherViewGrid;
