import { useState, useEffect } from 'react';

// Helpers
import { useMarketplaceState } from 'stores/MarketplaceStore';
import {
  addQueryParams,
  removeQueryParams,
  getCurrentEnvironment,
  fetchSettingsFromURL
} from 'helpers';
import Cookies from 'universal-cookie';

const favoriteProjectsParam = 'fpr';

const useFavoriteProjectsList = () => {
  const { MarketplaceState, MarketplaceStateDispatch } = useMarketplaceState();
  const { projects, providedFavoritesList, userFavoritesList } =
    MarketplaceState;

  const [favoritesQueryParam, setFavoritesQueryParam] = useState('');
  const [areFavoritesFromQueryParamApplied, setFavoritesFromQueryParamApplied] =
    useState(false);

  const cookies = new Cookies();
  const favoriteProjectsCookie = `prompto/${
    getCurrentEnvironment().env
  }/favoriteProjects`;

  // when page is rendered check if there is 'fpr' query param presented
  // also check if the list of favorites is saved in cookies
  // combine them and put in the Marketplace State
  // it should be done just once
  useEffect(() => {
    if (areFavoritesFromQueryParamApplied) return;

    const savedFavorites = cookies.get(favoriteProjectsCookie);
    if (Array.isArray(savedFavorites)) {
      MarketplaceStateDispatch({
        type: 'setInitialUserFavorites',
        payload: savedFavorites
      });
    }

    const queryParams = fetchSettingsFromURL();
    const favoritesParam = queryParams?.[favoriteProjectsParam];
    if (favoritesParam) {
      const favoritesFromQuery = [...favoritesParam.split(',')].filter(Boolean);
      if (Array.isArray(favoritesFromQuery)) {
        MarketplaceStateDispatch({
          type: 'setProvidedFavorites',
          payload: favoritesFromQuery
        });
      }
    }
    setFavoritesFromQueryParamApplied(true);
  }, [
    areFavoritesFromQueryParamApplied,
    MarketplaceStateDispatch,
    cookies,
    favoriteProjectsCookie
  ]);

  // update query params and respective cookie
  // on every change of favorites list
  // providedFavoritesList is ignored
  useEffect(() => {
    // set the cookie expiration date
    // otherwise it is set as session cookie
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 365); // 1 year in milliseconds
    cookies.set(favoriteProjectsCookie, userFavoritesList, {
      expires: date
    });
  }, [userFavoritesList, cookies, favoriteProjectsCookie]);

  // use both userFavoritesList and providedFavorites
  // to form the favorite projects list
  useEffect(() => {
    // combine favorites and remove duplications

    // filter out duplicated favorite projects and the ones
    // which do not exist in current vault
    const projectIDs = projects.map((pr) => pr.objectId);
    const totalFavorites = [...userFavoritesList, ...providedFavoritesList]
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter((projectId) =>
        projectIDs?.length > 0 ? projectIDs.includes(projectId) : true
      );
    setFavoritesQueryParam(totalFavorites.join(','));
    MarketplaceStateDispatch({
      type: 'setTotalFavorites',
      payload: totalFavorites
    });
  }, [
    userFavoritesList,
    providedFavoritesList,
    MarketplaceStateDispatch,
    projects
  ]);

  // add query params to url
  useEffect(() => {
    if (favoritesQueryParam) {
      addQueryParams([{ [favoriteProjectsParam]: favoritesQueryParam }]);
      // don't remove "fpr" query param before its value was applied to Marketplace State
    } else if (areFavoritesFromQueryParamApplied) {
      removeQueryParams([{ [favoriteProjectsParam]: '' }]);
    }
  }, [favoritesQueryParam, areFavoritesFromQueryParamApplied]);
};

export default useFavoriteProjectsList;
