import React, { useEffect, useState } from 'react';
import { bool, shape, string } from 'prop-types';

// Components
import MobileMediaContent from './MobileMediaContent';
import MobileDocumentsContent from './MobileDocumentsContent';

// Helpers
import categories from 'configs/SupportedFileCategories.json';
import contentItemStates from 'configs/ContentItemStates.json';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100% - 130px);
  overflow: hidden;
  background-color: ${(props) => props.theme.whiteOff};
`;

const Tabs = styled.ul`
  list-style: none;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 10px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Tab = styled.li`
  padding: 11px 0;
  margin: 0;
  flex: 50% 1 1;
  color: ${(props) =>
    props.active ? props.theme.showcaseBlack : props.theme.primary100};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const TABS = [
  { key: 'media', label: localizer.media },
  { key: 'documents', label: localizer.documents }
];

const MobileContentView = ({
  content,
  project,
  shareCode,
  contentReady,
  resourcesHasDocuments
}) => {
  const [tabs, setTabs] = useState({});
  const [activeTab, setActiveTab] = useState('media');

  const createTabsContent = (list) => {
    const mediaList = [];
    const documentsList = [];

    const isMedia = (type) =>
      [
        categories.video,
        categories.image,
        categories.gif,
        categories.album360,
        categories.showcase,
        categories.image360,
        categories.turntable
      ].includes(type);

    const isDocument = (type) => [categories.document].includes(type);

    list
      .filter(
        (page) =>
          page.contentItemState === contentItemStates.published &&
          page.contentUri
      )
      .map((item) => ({
        ...item,
        name: item.title.textMap.en || item.contentItemType || 'item'
      }))
      .filter(Boolean)
      .forEach((page) => {
        if (isMedia(page.contentItemType)) mediaList.push(page);
        if (isDocument(page.contentItemType)) documentsList.push(page);
      });

    return { media: mediaList, documents: documentsList };
  };

  useEffect(() => {
    if (!content) return;

    const filteredContentItemList = content?.vmContentItemList.filter(
      (item) => item.contentItemState === contentItemStates.published
    );

    setTabs(createTabsContent(filteredContentItemList));
  }, [content, project, shareCode]);

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={contentReady ? { opacity: 1.0 } : { opacity: 0.0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: 'easeOut' }}
    >
      {resourcesHasDocuments && (
        <Tabs>
          {TABS.map((t) => (
            <Tab
              key={t.key}
              onClick={() => setActiveTab(t.key)}
              active={activeTab === t.key}
            >
              {t.label}
            </Tab>
          ))}
        </Tabs>
      )}
      <AnimatePresence>
        {activeTab === 'media' && (
          <MobileMediaContent
            items={tabs['media']}
            noFilesMessage={localizer.noFiles}
          />
        )}
        {activeTab === 'documents' && (
          <MobileDocumentsContent
            items={tabs['documents']}
            noFilesMessage={localizer.noFiles}
          />
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

MobileContentView.propTypes = {
  content: shape({}),
  project: shape({}),
  shareCode: string,
  contentReady: bool
};

MobileContentView.defaultProps = {
  content: null,
  project: null,
  shareCode: '',
  contentReady: false
};

export default MobileContentView;
