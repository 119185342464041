import React, { useEffect, useState } from 'react';
import { oneOf, shape, number, func } from 'prop-types';

// Helpers
import {
  unitFilterOptions,
  formatUnitfieldValue,
  convertUserNumberInputToBackendDouble
} from 'helpers/units/VmUnitHelper';
import { clamp } from '@prompto-helpers';
import localizer from 'localization/localizer';

//Components
import ReactSlider from 'react-slider';

// Styling
import styled from 'styled-components';

const CardWrapper = styled.div`
  padding: 25px 0px;
  padding-bottom: 15px;
  display: flex;
  flex-flow: column;
  border: 1px solid ${(props) => props.theme.gray400};
  border-radius: 10px;
  background: white;
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin: 0px 25px;
  margin-bottom: 15px;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  margin: 0px 15px;
  justify-content: space-between;
`;

const InputField = styled.input`
  width: inherit;
  width: 100px;
  height: 35px;
  border: 1px solid ${(props) => props.theme.gray300};
  color: ${(props) => props.theme.primary200};
  border-radius: 5px;
  text-align: center;
  outline: none;
`;

const MinInput = styled(InputField)``;
const MaxInput = styled(InputField)``;

const Dash = styled.div`
  width: 5px;
  height: 2px;
  background-color: ${(props) => props.theme.gray300};
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledSlider = styled(ReactSlider)`
  height: 25px;
  margin: 0px 25px;
  display: flex;
  align-items: center;
`;

const StyledThumb = styled.div`
  height: 15px;
  width: 15px;
  line-height: 15px;
  background-color: white;
  border: 5px solid ${(props) => props.theme.primary100};
  border-radius: 50%;
  outline: none;
  cursor: grab;
`;

const StyledTrack = styled.div`
  height: 2px;
  background: ${(props) =>
    props.index === 1 ? props.theme.primary100 : props.theme.gray300};
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 5px 10px;
`;

const ClearButton = styled(StyledButton)`
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
`;
const ConfirmButton = styled(StyledButton)`
  font-size: 14px;
  color: ${(props) => props.theme.primary400};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  background-color: ${(props) => props.theme.gray100};
`;

/**
 * Filter card with a slider to set the min and max values between limits
 */
const FilterSliderCard = ({
  filterOption,
  limits,
  initialValues,
  isShown,
  onConfirm,
  onUpdateValues
}) => {
  const [localValues, setLocalValues] = useState({ min: null, max: null });

  useEffect(() => {
    if (localValues.min === null && localValues.max === null) {
      if (initialValues) {
        setLocalValues(initialValues);
      } else if (limits) {
        setLocalValues(limits);
      }
    }
  }, [initialValues, limits, localValues]);

  useEffect(() => {
    if (isShown) {
      setLocalValues((current) => {
        return { min: current.min, max: current.max };
      });
    }
  }, [isShown]);

  useEffect(() => {
    if (localValues) {
      const minInput = document.getElementById(`${filterOption}minInput`);
      if (minInput) {
        minInput.value = formatUnitfieldValue(localValues.min, filterOption);
      }

      const maxInput = document.getElementById(`${filterOption}maxInput`);
      if (maxInput) {
        maxInput.value = formatUnitfieldValue(localValues.max, filterOption);
      }
    }
  }, [localValues, filterOption]);

  useEffect(() => {
    onUpdateValues(localValues);
  }, [localValues, onUpdateValues]);

  const tryUpdateMinValue = (e) => {
    const newValue = convertUserNumberInputToBackendDouble(e.target.value);
    const clamped = clamp(Number(newValue), limits.min, limits.max);

    setLocalValues((current) => {
      return { min: clamped, max: current.max };
    });
  };

  const tryUpdateMaxValue = (e) => {
    const newValue = convertUserNumberInputToBackendDouble(e.target.value);
    const clamped = clamp(Number(newValue), limits.min, limits.max);

    setLocalValues((current) => {
      return { min: current.min, max: clamped };
    });
  };

  // When the user presses clear we want to set the filter to the limits
  const clearValues = () => {
    setLocalValues(limits);
  };

  const onChangeValues = (newValues) => {
    setLocalValues({
      min: newValues[0],
      max: newValues[1]
    });
  };

  return (
    <CardWrapper
      data-testid={`filterSliderCard${filterOption}`}
      key={`filterSliderCard${filterOption}wrapper`}
    >
      <InputFieldWrapper>
        <MinInput
          data-testid={`minInput`}
          key={`filterSliderCard${filterOption}minInput`}
          id={`${filterOption}minInput`}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              tryUpdateMinValue(e);
            }
            return false;
          }}
          onBlur={(e) => {
            tryUpdateMinValue(e);
          }}
        />
        <Dash />
        <MaxInput
          data-testid={`maxInput`}
          key={`filterSliderCard${filterOption}maxInput`}
          id={`${filterOption}maxInput`}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              tryUpdateMaxValue(e);
            }
            return false;
          }}
          onBlur={(e) => {
            tryUpdateMaxValue(e);
          }}
        />
      </InputFieldWrapper>
      <StyledSlider
        isShown={isShown}
        min={limits.min}
        max={limits.max}
        disabled={limits.min === limits.max}
        step={Math.ceil((limits.max - limits.min) / 100)}
        value={[localValues.min, localValues.max]}
        onChange={onChangeValues}
        renderTrack={(props, state) => {
          return (
            <StyledTrack {...props} index={state.index}>
              {state.valueNow}
            </StyledTrack>
          );
        }}
        renderThumb={(props, state) => {
          return <StyledThumb {...props} />;
        }}
      />
      <Divider />
      <ButtonWrapper>
        <ClearButton onClick={clearValues}>
          {localizer.clear.toUpperCase()}
        </ClearButton>
        <ConfirmButton
          data-testid={`confirm${filterOption}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onConfirm(localValues);
          }}
        >
          {localizer.confirm.toUpperCase()}
        </ConfirmButton>
      </ButtonWrapper>
    </CardWrapper>
  );
};

FilterSliderCard.propTypes = {
  /** The option this component will filter on */
  filterOption: oneOf(unitFilterOptions).isRequired,
  /** The filter limits, the min and max values that can be set */
  limits: shape({ min: number, max: number }).isRequired,
  /** Initial values that we use if they are passed */
  initialValues: shape({ min: number, max: number }),
  /** Callback when the user confirms the current values
   * @callback
   * @param {{min: number, max: number}} updatedValues The values that were choosen by the user
   */
  onConfirm: func.isRequired,
  /** Callback when the values are updated but not confirmed
   * @callback
   * @param {{min: number, max: number}} updatedValues The values that were updated
   */
  onUpdateValues: func.isRequired
};

export default FilterSliderCard;
