import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//Helpers
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { motion, AnimatePresence } from 'framer-motion';

// Styling
import styled, { css } from 'styled-components';

const MobileHeaderWrapper = styled(motion.div)`
  position: relative;
  z-index: 20;
  height: 70px;
  background-color: ${(props) => props.theme.whitePure};
  padding: 15px;
  box-sizing: border-box;
  color: ${(props) => props.theme.showcaseBlack};
  font-size: 30px;
  font-weight: 300;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.gray200};
  flex-shrink: 0;
`;

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const sharedActionStyles = css`
  position: absolute;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LeftAction = styled.div`
  ${sharedActionStyles}
  left: 15px;
  justify-content: flex-start;
`;

const RightAction = styled.div`
  ${sharedActionStyles}
  right: 15px;
  justify-content: flex-end;
`;

const subRoutes = {
  tour: 'tour',
  turntable: 'turntable',
  units: 'units',
  stream: 'stream',
  resources: 'resources',
  contracts: 'contracts',
  map: 'map',
  more: 'more',
  share: 'share',
  projectInformation: 'project_information',
  favorites: 'favorites'
};

const MobileLayoutHeader = ({ children, leftAction, rightAction }) => {
  const [activeSubRoute, setActiveSubRoute] = useState();

  const location = useLocation();
  const { isLandscape } = useMobileOrientation();

  useEffect(() => {
    const subRoute = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    setActiveSubRoute(subRoute);
  }, [location]);

  const shouldHideHeader =
    isMobile &&
    [subRoutes.tour, subRoutes.map].includes(activeSubRoute) &&
    isLandscape;

  if (!isMobile) return null;

  return (
    <AnimatePresence>
      {!shouldHideHeader && (
        <MobileHeaderWrapper
          initial={{ height: 70, opacity: 1 }}
          animate={
            shouldHideHeader
              ? { height: 0, opacity: 0 }
              : { height: 70, opacity: 1 }
          }
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.5, type: 'tween', ease: 'easeIn' }}
        >
          <LeftAction>{leftAction}</LeftAction>
          <Content>{children}</Content>
          <RightAction>{rightAction}</RightAction>
        </MobileHeaderWrapper>
      )}
    </AnimatePresence>
  );
};

export default MobileLayoutHeader;
