import * as React from 'react';

function SvgHideIcon(props) {
  return (
    <svg viewBox="0 0 16 13" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.238 10.576L.57 11.891c-.07.046-.093.164-.023.257l.234.282a.19.19 0 00.258.046l1.893-1.492c.063.01.127.016.193.016h9.75c.61 0 1.125-.492 1.125-1.125v-6.75c0-.253-.089-.49-.236-.68l1.666-1.312a.19.19 0 00.046-.258L15.242.57a.213.213 0 00-.281-.023L13.09 2.022A1.08 1.08 0 0012.875 2h-9.75C2.492 2 2 2.516 2 3.125v6.75c0 .268.088.51.238.701zm1.626-.326h9.011a.385.385 0 00.375-.375v-6.75c0-.075-.03-.15-.078-.213L3.864 10.25zm8.302-7.5L2.83 10.11a.376.376 0 01-.08-.235v-6.75c0-.188.164-.375.375-.375h9.041z"
      />
    </svg>
  );
}

export default SvgHideIcon;
