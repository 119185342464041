import React, { useState, useEffect, useRef } from 'react';

// Helpers
import { isMobile } from 'react-device-detect';

// Styling
import styled, { keyframes, css } from 'styled-components';

const fadeout = keyframes`
0% { opacity: 0.3 }
40% { opacity: 0.7 }
80% {opacity: 0.7 }
100 % { opacity: 0 }
`;

const getPolygonOpacity = ({ active, visible }) => {
  let opacity;
  if (active) {
    opacity = isMobile ? 0.7 : 0.85;
  } else {
    opacity = visible ? 0.15 : 0;
  }
  return opacity;
};

const StyledPolygonPath = styled.path`
  opacity: ${getPolygonOpacity};
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
  transition: ${isMobile ? 0.2 : 0}s;
  ${({ highlighted }) =>
    highlighted &&
    css`
      animation-name: ${fadeout};
      animation-duration: 2.5s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    `}
`;

const TurntableSvgMask = ({ maskData, maskToShow, active, visible, scale }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const timerRef = useRef();

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    if (isHighlighted) return;
    if (maskToShow === maskData?.number) {
      setIsHighlighted(true);
    }
  }, [maskToShow, maskData, isHighlighted]);

  useEffect(() => {
    if (isHighlighted) {
      timerRef.current = setTimeout(() => {
        setIsHighlighted(false);
        clearTimeout(timerRef.current);
      }, 2500);
    }
  }, [isHighlighted]);

  return (
    <StyledPolygonPath
      id="svg-turntable-polygon"
      xmlns="http://www.w3.org/2000/svg"
      d={maskData.path}
      fill={maskData.color}
      fillOpacity={0.6}
      stroke={maskData.color}
      strokeWidth={5 / scale}
      highlighted={isHighlighted}
      active={active}
      visible={visible}
      data-target={maskData.target}
      data-objectid={maskData.number}
    />
  );
};

export default TurntableSvgMask;
