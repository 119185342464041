import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';

// Helpers
import localizer from 'localization/localizer';
import { getCurrentEnvironment } from 'helpers';
import Cookies from 'universal-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import HubspotProvider from 'helpers/customHooks/useHubspotContext';
import { isMobileOnly } from 'react-device-detect';

// Components
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfowallIcon } from 'resources/icons';
import HubspotForm from 'components/share/HubspotForm';
import DefaultForm from '../share/DefaultForm';

// Styling
import styled from 'styled-components';
import { useAuthState } from 'stores/AuthStore';
import { trackUnitAction } from 'components/pages/unitDetail/tracking/UnitTrackingHelper';

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  color: ${(props) => props.theme.showcaseWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${(props) =>
    props.show &&
    `
    width: 100%;
    height: 100%;
  `}
`;

const ModalContent = styled(motion.div)`
  background: ${(props) => props.theme.showcaseWhite};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${({ formsubmitted }) => (formsubmitted ? 'auto' : '300px')};
  max-height: 90vh;
  overflow: hidden;
  position: relative;
  width: 500px;
  padding: 40px 0;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray300};
  ${isMobileOnly &&
  `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    border-radius: 0;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  heigth: 100%;
  overflow-y: auto;
  ${isMobileOnly &&
  `
      padding: 15px;
      box-sizing: border-box;
    `}
`;

const CloseButton = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 22px;
  color: #333;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 1.25rem;
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
`;

const ExplanatoryText = styled(Text)`
  font-size: 0.875rem;
  margin-bottom: 30px;
  word-break: break-word;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const StyledInfowallIcon = styled(InfowallIcon)`
  flex-shrink: 0;
`;

const FormWrapper = styled.div`
  padding: 0 5px;
`;

export const showInfowallCookie = (code) =>
  `prompto/${getCurrentEnvironment().env}/showInfowall-${code}`;

const InfoWall = () => {
  const [showErrorDefaultMsg, setShowErrorDefaultMsg] = useState(false);
  const [showSuccessDefaultMsg, setShowSuccessDefaultMsg] = useState(false);
  const [showDefaultForm, setShowDefaultForm] = useState(false);
  const [isInfowallEnabled, setInfowallEnabled] = useState(false);
  const [showInfoWall, setShowInfoWall] = useState(false);
  const [pagesBlockedByInfowall, setPagesBlockedByInfowall] = useState([]);
  const [showContent, setShowContent] = useState(false);

  const [env] = useState(getCurrentEnvironment());
  const [baseLink] = useState(`https://${env.applicationUrl}`);

  const [hubspotFormLang, setHubspotFormLang] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Auth state
  const { AuthState } = useAuthState();
  const { authenticated, visitorSessionId, visitorId } = AuthState;

  // Project Store
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration, shareCode } = ProjectState;
  const infowallConfiguration = showcaseConfiguration?.infowallConfiguration;

  // UI state
  const { UiState } = useUiState();
  const { isEmbeddedShowcase } = UiState;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentUrl = `${baseLink}/${shareCode}${window.location.search}`;

  const hsFormContentRef = useRef();

  // Only track unit action if infowall is shown for a unit
  const checkAndTrackUnitAction = useCallback(
    (actionName) => {
      const subRoute = pathname.split('/').filter(Boolean)[1];
      const unitId = pathname.split('/').filter(Boolean)[2];

      if (subRoute === 'unit') {
        trackUnitAction(actionName, unitId, ProjectState, AuthState);
      }
    },
    [pathname, ProjectState, AuthState]
  );

  useEffect(() => {
    if (showInfoWall && visitorSessionId && visitorId) {
      checkAndTrackUnitAction('unitInfoWallShown');
    }
  }, [
    showInfoWall,
    shareCode,
    currentUrl,
    checkAndTrackUnitAction,
    visitorSessionId,
    visitorId
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(showInfoWall);
      clearTimeout(timer);
    }, 150);
  }, [showInfoWall]);

  useEffect(() => {
    setInfowallEnabled(!!infowallConfiguration?.enableInfowall);
    setShowDefaultForm(
      infowallConfiguration?.formType === 'promptoDefaultForm'
    );

    if (infowallConfiguration?.pagesBlockedByInfowall?.length > 0) {
      setPagesBlockedByInfowall(infowallConfiguration.pagesBlockedByInfowall);
    }
  }, [infowallConfiguration]);

  // check if an Infowall should be shown
  // show only on specified pages
  // but do not show if user data were already sent (check cookies)
  useEffect(() => {
    if (!shareCode) return;
    if (!isInfowallEnabled) return;
    if (pagesBlockedByInfowall.length === 0) return;

    const cookies = new Cookies();
    const infowallCookie = cookies.get(showInfowallCookie(shareCode));
    if (infowallCookie || formSubmitted) {
      setShowInfoWall(false);
    } else {
      // check if current page should be blocked by Infowall
      // pathname always starts with "/code/..."
      const subRoute = pathname.split('/').filter(Boolean)[1];
      setShowInfoWall(pagesBlockedByInfowall.includes(subRoute));
    }
  }, [
    pathname,
    pagesBlockedByInfowall,
    isInfowallEnabled,
    shareCode,
    formSubmitted
  ]);

  useEffect(() => {
    setHubspotFormLang(showInfoWall ? localizer.getLanguage() : undefined);
  }, [showInfoWall]);

  const closeInfoWall = () => {
    navigate(-1);
  };

  const infowallText =
    localizer.getLanguage() === 'en'
      ? infowallConfiguration?.infowallText
      : infowallConfiguration?.localizedInfowallText?.textMap?.[
          localizer.getLanguage()
        ];

  const hubspotFormHiddenFieldsValues = [
    { fieldName: 'url', fieldValue: currentUrl } // what field name should be used?
  ];

  const onFormSubmit = () => {
    setFormSubmitted(true);
    if (authenticated) setShowSuccessDefaultMsg(true);

    checkAndTrackUnitAction('unitInfoWallSubmitted');

    // save to cookies
    // add a small delay so that a user can read a confirmation message
    setTimeout(() => {
      setShowInfoWall(false);
      const date = new Date();
      date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 30); // 30 days in milliseconds
      const cookies = new Cookies();
      cookies.set(showInfowallCookie(shareCode), 'false', {
        expires: date,
        sameSite: 'none',
        secure: true
      });
    }, 2000);
  };

  if (authenticated) return null;

  return createPortal(
    <HubspotProvider>
      <Wrapper show={showInfoWall}>
        <ModalContent
          initial={{ opacity: 0 }}
          animate={{ opacity: showContent ? 1 : 0 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'tween' }}
          formsubmitted={formSubmitted}
        >
          <CloseButton
            onClick={closeInfoWall}
            icon={['fal', 'times']}
            size="1x"
          />
          <Content ref={hsFormContentRef}>
            {!formSubmitted && (
              <TextWrapper>
                <StyledInfowallIcon />
                {infowallText !== undefined ? (
                  <ExplanatoryText>{infowallText}</ExplanatoryText>
                ) : (
                  <>
                    <Text>Hey! Hey!</Text>
                    <ExplanatoryText>{localizer.infowallText}</ExplanatoryText>
                  </>
                )}
              </TextWrapper>
            )}

            {/* Hubspot form */}
            {hubspotFormLang && !showDefaultForm && (
              <FormWrapper>
                <HubspotForm
                  formType="InfoWall"
                  portalId={infowallConfiguration?.hubspotPortalId}
                  formId={
                    infowallConfiguration?.localizedHubspotFormId?.textMap?.[
                      hubspotFormLang
                    ] || infowallConfiguration?.hubspotFormId
                  }
                  isEmbeddedShowcase={isEmbeddedShowcase}
                  shareCode={shareCode}
                  hiddenFieldsValues={hubspotFormHiddenFieldsValues}
                  onFormSubmit={onFormSubmit}
                  formContentRef={hsFormContentRef}
                />
              </FormWrapper>
            )}
            {showDefaultForm ? (
              <DefaultForm
                formType="InfoWall"
                onFormSubmit={onFormSubmit}
                showSuccessDefaultMsg={showSuccessDefaultMsg}
                setShowSuccessDefaultMsg={setShowSuccessDefaultMsg}
                showErrorDefaultMsg={showErrorDefaultMsg}
                setShowErrorDefaultMsg={setShowErrorDefaultMsg}
              />
            ) : null}
          </Content>
        </ModalContent>
      </Wrapper>
    </HubspotProvider>,
    document.body
  );
};

export default InfoWall;
