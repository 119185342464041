import React, { useState } from 'react';
import { array, string } from 'prop-types';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Helpers
import categories from 'configs/SupportedFileCategories.json';
import { getCurrentEnvironment } from 'helpers';

// Components
import ImagePreview from '../contentItems/types/image/ImagePreview';
import Album360Preview from '../contentItems/types/album360/Album360Preview';
import VideoPreview from '../contentItems/types/video/VideoPreview';
import MobileMediaGallery from './MobileMediaGallery';

const GalleryWrapper = styled(motion.div)`
  width: 100%;
  height: calc(100% - 50px);
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const GalleryItemWrapper = styled.div`
  height: ${(props) => (props.index === 0 ? '200px' : '100px')};
  width: ${(props) => (props.index === 0 ? '100%' : 'calc(50% - 10px)')};
  margin: 5px;
  position: relative;
`;

const MobileMediaContent = ({ items = [], noFilesMessage = '' }) => {
  const [shownItem, setShownItem] = useState(0);
  const [openGallery, setOpenGallery] = useState(false);

  if (items.length === 0) {
    return <p>{noFilesMessage}</p>;
  }

  let transform = 'q=80:h=400';
  const videoFallbackThumbnail = `${
    getCurrentEnvironment().baseImageUrl
  }/${transform}/${items[0].contentUri}`;

  const openGalleryHandler = (itemIndex) => {
    setShownItem(itemIndex);
    setOpenGallery(true);
  };

  return (
    <GalleryWrapper
      initial={{ x: -250, opacity: 0.0 }}
      animate={{ x: 0, opacity: 1.0 }}
      exit={{ x: -250, opacity: 0.0 }}
      transition={{ ease: 'easeOut' }}
    >
      {items.map((data, idx) => {
        const sharedProps = {
          data,
          key: data.objectId
        };
        let item;

        switch (data.contentItemType) {
          case categories.image:
          case categories.image360:
          case categories.turntable:
            let icon;
            if (data.contentItemType === categories.turntable) {
              icon = 'cube';
            }
            item = (
              <ImagePreview
                {...sharedProps}
                pageIndex={idx}
                isMobile={true}
                icon={icon}
              />
            );
            break;
          case categories.album360:
            item = <Album360Preview {...sharedProps} />;
            break;
          case categories.video:
            item = (
              <VideoPreview
                {...sharedProps}
                fallbackThumbnail={videoFallbackThumbnail}
              />
            );
            break;
          default:
            item = null;
        }
        return (
          <GalleryItemWrapper
            key={idx}
            index={idx}
            onClick={() => openGalleryHandler(idx)}
          >
            {item}
          </GalleryItemWrapper>
        );
      })}

      {openGallery && (
        <MobileMediaGallery
          pages={items}
          activePage={shownItem}
          videoFallbackThumbnail={videoFallbackThumbnail}
          onClose={() => setOpenGallery(false)}
        />
      )}
    </GalleryWrapper>
  );
};

MobileMediaContent.propTypes = {
  items: array,
  noFilesMessage: string
};

MobileMediaContent.defaultProps = {
  items: [],
  noFilesMessage: ''
};

export default MobileMediaContent;
