import * as Sentry from '@sentry/react';

// Helpers
import { getCurrentEnvironment, getPackageVersion } from 'helpers';

export default () => {
  const { env } = getCurrentEnvironment();
  const isProd = env === 'production';

  const initObject = {
    dsn: 'https://e7e1184511bc47b69e6907a7aa49c1b7@o23101.ingest.sentry.io/5433750',
    release: getPackageVersion(),
    debug: !isProd,
    environment: env,

    /**
     * We add this error to the ignore list since it keeps showing up when users use specific/older browsers
     * More explanation here: https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
     *
     * The 'ResizeObserver loop completed with undelivered notifications.' error is being produced
     * by react-virtuoso. It doesn't break the application, but spams the sentry channels with thousands of errors.
     */
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ],

    integrations: [],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  };

  Sentry.init(initObject);
};
