import React, { useState, memo, useEffect } from 'react';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';

// Styling
import styled, { css } from 'styled-components';

const Button = styled(motion.button)`
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: solid 1px ${({ theme }) => theme.gray150};
  color: ${({ theme }) => theme.primary100};
  cursor: ${({ searchValue }) => (searchValue ? 'normal' : 'pointer')};
  font-family: ${({ theme }) => theme.mainFontFamily};

  padding: ${({ searchValue }) =>
    searchValue && !isMobile ? '5px 30px 5px 10px' : '5px'};
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  &:hover {
    cursor: pointer;
  }
  ${isMobile &&
  css`
    border: none;
  `}
`;

const iconSize = isMobile ? 24 : 40;

const IconWrapper = styled(motion.div)`
  width: ${iconSize}px;
  height: ${iconSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ hovered }) => (hovered ? 5 : 0)}px;
  transition: all 100ms ease 100ms;
  font-size: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.375rem;
`;

const Text = styled(motion.div)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.03rem;
  font-size: 1rem;
  font-weight: 500;
  color: black;
  width: 200px;
`;

const TextInput = styled(motion.div)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 1rem;
  outline: none;
  color: ${(props) => props.theme.primary100};
  margin-right: 10px;
`;

const InputWrapper = styled(motion.div)`
  overflow: hidden;
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-grow: ${isMobile ? '1' : '0'};
`;

const ClearIcon = styled(Icon)`
  font-size: 0.875rem;
`;

const ClearIconWrapper = styled(motion.div)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  cursor: ${({ disabled, isfocussed }) =>
    !disabled && isfocussed ? 'pointer' : 'default'};
  margin-left: ${isMobile ? '10px' : '0'};
  &:hover {
    cursor: pointer;
  }
`;

const SearchByProjectButton = memo(
  ({ onClick, searchValue, setSearchValue, setSmartSearchValue }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [localSearchValue, setLocalSearchValue] = useState('');

    useEffect(() => {
      setLocalSearchValue(searchValue);
    }, [searchValue]);

    const showSearchValue = !isMobile;
    const showSearchPlaceholder = isHovered && !isMobile;

    let searchFieldContent;
    if (localSearchValue) {
      searchFieldContent = (
        <InputWrapper
          initial={{ width: 0 }}
          animate={{
            opacity: showSearchValue ? 1 : 0,
            width: showSearchValue ? 'auto' : 0
          }}
          transition={{ duration: 0.14, type: 'tween', ease: 'linear' }}
        >
          <Text>{localSearchValue}</Text>
          <ClearIconWrapper
            data-testid="clearButton"
            id="clearButton"
            initial={{ opacity: 0 }}
            animate={
              localSearchValue
                ? { opacity: 1, width: 'auto' }
                : { opacity: 0, width: 0 }
            }
            onClick={(e) => {
              if (localSearchValue) {
                e.preventDefault();
                e.stopPropagation();
                setSearchValue('');
                setSmartSearchValue('');
                setTimeout(() => {
                  setIsHovered(false);
                }, 100);
              }
            }}
          >
            <ClearIcon icon={['fal', 'times-circle']} />
          </ClearIconWrapper>
        </InputWrapper>
      );
    } else {
      searchFieldContent = (
        <TextInput
          initial={{ width: 0 }}
          animate={{
            opacity: showSearchPlaceholder ? 1 : 0,
            width: showSearchPlaceholder ? 'auto' : 0
          }}
          transition={{ duration: 0.14, type: 'tween', ease: 'linear' }}
        >
          {localizer.searchByLocationOrProject}
        </TextInput>
      );
    }

    return (
      <>
        <AnimatePresence>
          <Button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            searchValue={localSearchValue}
            hovered={isHovered || showSearchValue}
          >
            <IconWrapper
              hovered={
                (showSearchValue && localSearchValue) || showSearchPlaceholder
              }
            >
              <Icon icon={['fal', 'search']} size="1x" />
            </IconWrapper>

            {((showSearchValue && localSearchValue) || showSearchPlaceholder) &&
              searchFieldContent}
          </Button>
        </AnimatePresence>
      </>
    );
  }
);

export default SearchByProjectButton;
