import React, { useEffect, useState, useReducer } from 'react';

// Components
import ClampLines from 'react-clamp-lines';
import { Tooltip } from 'react-tippy';
import { ReactComponent as PdfPlaceholder } from 'resources/svgs/pdf-placeholder.svg';

// Helpers
import { getCurrentEnvironment, computeCloudinaryThumbnail } from 'helpers';
import { shape, number, string, oneOfType } from 'prop-types';
import path from 'path';
import { isMobile } from 'react-device-detect';
import 'react-tippy/dist/tippy.css';

// Styles
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainMobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-family: open-sans;
  position: absolute;
  top: 25%;
  left: 10%;
  width: 80%;
  font-size: ${({ isLargeSize }) => (isLargeSize ? '1.5rem' : '0.75rem')};
  line-height: ${({ isLargeSize }) => (isLargeSize ? '3rem' : '1.5rem')};
  font-weight: normal;
  color: black;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
`;

const NoPreviewWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;

const StyledPdfPlaceholder = styled(PdfPlaceholder)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledClampLines = styled(ClampLines)`
  > p {
    font-size: 12px;
    font-weight: ${isMobile ? '400' : '600'};
    color: white;
    margin: 0;
    word-break: break-all;
    text-align: center;
  }
`;

const TooltipContent = styled.p`
  width: 100%;
  max-width: none;
  height: auto;
  word-break: break-all;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ${isMobile &&
  `width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);`}
`;

const MobileClampedLinesWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  padding: 6px 5px;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 35px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClampedLinesWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px 20px;
  box-sizing: border-box;
  p {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 600;
  }
`;

function eventReducer(state, action) {
  switch (action.type) {
    case 'trackThumbnailLoadedFailed':
      return { ...state };
    default:
      throw new Error();
  }
}

const RATIO = 0.7; // standart A4 format ratio

const DocumentPreview = ({ data, size, isVerticalLayout }) => {
  const dispatch = useReducer(eventReducer, { data })[1];
  const currentEnvironment = getCurrentEnvironment();

  // calculate size
  let height = size;
  let width = height * RATIO;

  if (isVerticalLayout) {
    width = size;
    height = width / RATIO;
  }

  const [thumbnailError, setThumbnailError] = useState(false);

  const [cloudinaryImageUri] = useState(
    computeCloudinaryThumbnail(
      decodeURIComponent(data.contentUri),
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryImageBaseUrl,
      'h_600,c_fill,dn_50'
    )
  );

  const extension = path.extname(data.name).replace('.', '');
  const isPdf = extension === 'pdf';

  // track when the thumbnail fails to load
  useEffect(() => {
    if (thumbnailError) {
      dispatch({ type: 'trackThumbnailLoadedFailed' });
    }
  }, [thumbnailError, dispatch]);

  // Rendering
  if (!isPdf) {
    return null;
  }

  const lines = (
    <StyledClampLines
      text={data.title.textMap.en}
      id="really-unique-id"
      lines={2}
      ellipsis="..."
      innerElement="p"
      buttons={false}
    />
  );

  let linesWrapper = (
    <ClampedLinesWrapper>
      <p>{data.title.textMap.en}</p>
    </ClampedLinesWrapper>
  );
  if (isMobile) {
    linesWrapper = (
      <MobileClampedLinesWrapper>{lines}</MobileClampedLinesWrapper>
    );
  }

  let mainContent = (
    <NoPreviewWrapper height={height - 8} width={height - 8}>
      <StyledPdfPlaceholder />
      <Title isLargeSize={size > 1000}>{data.title.textMap.en}</Title>
    </NoPreviewWrapper>
  );

  if (cloudinaryImageUri && !thumbnailError) {
    mainContent = (
      <ImageWrapper>
        <img
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          src={cloudinaryImageUri}
          alt={data.title.textMap.en}
          onError={() => {
            setThumbnailError(true);
          }}
          height={height}
          width={width}
        />

        <StyledTooltip
          html={<TooltipContent>{data.title.textMap.en}</TooltipContent>}
          position="top"
        >
          {linesWrapper}
        </StyledTooltip>
      </ImageWrapper>
    );
  } else {
    // in new design placeholder has square ratio
    width = height;
  }

  let contentWrapper = (
    <MainWrapper height={height} width={width}>
      {mainContent}
    </MainWrapper>
  );
  if (isMobile) {
    contentWrapper = <MainMobileWrapper>{mainContent}</MainMobileWrapper>;
  }

  return contentWrapper;
};

DocumentPreview.propTypes = {
  data: shape({}),
  pageWidth: number,
  size: oneOfType([number, string])
};

DocumentPreview.defaultProps = {
  data: {},
  pageWidth: null,
  size: null
};

export default DocumentPreview;
