import React, { memo, useRef, useState, useEffect } from 'react';

// Components
import Polygon from './Polygon';

// Helpers
import { stringToPolygonCoords } from './PolygonHelper';
import { useProjectState } from 'stores/ProjectStore';
import { getColorForUnitState } from 'helpers/units/VmUnitHelper';

// Styling
import { withTheme } from 'styled-components';

const PolygonSpot = memo(
  ({
    spot,
    imageSize,
    zoomScale,
    onHoverPolygon,
    isMobile,
    onSpotClicked,
    isPolygonTooltipHovered,
    theme
  }) => {
    const coords = stringToPolygonCoords(spot.value);
    const polygonShape = useRef();
    const [spotFill, setSpotFill] = useState('transparent');

    const { ProjectState } = useProjectState();
    const { project, showcaseConfiguration } = ProjectState;

    useEffect(() => {
      if (!spot.assignedNavigationItemObjectId) {
        setSpotFill(spot.color);
        return;
      }

      if (spot.assignedItem?.navigationItemType === 'unitSpot') {
        const assignedUnit = project.unitList?.find(
          (unit) => unit.objectId === spot.assignedItem?.value
        );
        if (assignedUnit) {
          if (spot.useUnitColor) {
            const assignedUnitState = assignedUnit.state;
            if (assignedUnitState) {
              const unitColor = getColorForUnitState(theme, assignedUnitState);
              setSpotFill(unitColor);
            }
          } else {
            setSpotFill(spot.color);
          }
        } else {
          setSpotFill(spot.color);
        }
      } else {
        setSpotFill(spot.color);
      }
    }, [spot, showcaseConfiguration, theme, project]);

    const getIdealTooltipLocation = () => {
      // Some random logic to provide a decent location of the tooltip on the polygon spots
      // We take the average of the x position and the lowest y position
      let x = 0;
      let y = 0;
      let lowestY = 500000;
      let highestY = -500000;
      coords.forEach((coord) => {
        x += coord.x;

        if (coord.y < lowestY) {
          lowestY = coord.y;
        }
        if (coord.y > highestY) {
          highestY = coord.y;
        }
      });
      x /= coords.length;
      y = (highestY + lowestY) / 2;

      return { x, y, yL: lowestY, yH: highestY };
    };

    return (
      <Polygon
        ref={polygonShape}
        key={spot.objectId}
        coords={coords}
        fill={spotFill}
        imageSize={imageSize}
        zoomScale={zoomScale}
        objectId={spot.objectId}
        onMouseEnter={(e) => {
          if (!isMobile) {
            const { x, y, yL, yH } = getIdealTooltipLocation();
            onHoverPolygon({
              spot: spot,
              pos: { x: x, y: y, yL, yH },
              size: {
                width: polygonShape.current.getBoundingClientRect().width,
                height: polygonShape.current.getBoundingClientRect().height
              }
            });
          }
        }}
        onMouseLeave={(e) => {
          if (!isMobile) {
            onHoverPolygon({ spot: spot, pos: null });
          }
        }}
        onMouseDown={(e) => {
          if (!isMobile) {
            onSpotClicked(e);
          }
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
          onSpotClicked(e);
        }}
        isHovered={isPolygonTooltipHovered}
      />
    );
  }
);

export default withTheme(PolygonSpot);
