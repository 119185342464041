import React, { useState, useEffect, useCallback } from 'react';
import { string, func, bool, array, shape } from 'prop-types';

// Components
import MotionSlider from 'components/motionSlider/MotionSlider';
import PortfolioCard from './PortfolioCard';
import SalesPersonCard from './SalesPersonCard';

// Helpers
import { motion } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';
import to from 'await-to-js';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import { NavigationCollection } from '@prompto-api';

// Styling
import styled from 'styled-components';

const SliderWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  user-select: none;
  padding-top: ${isMobileOnly ? '30px' : 0};
  padding-bottom: ${isMobileOnly ? '50px' : '90px'};
  box-sizing: border-box;
`;

const PortfolioSlider = ({
  list,
  sessionToken,
  endReached,
  loadNext,
  onShowcaseSelected,
  disableScroll,
  disableArrowKeys,
  vaultSettings,
  projectsData,
  setShowSalesPersonModal,
  salesPersonEnabled = false,
  salesPerson,
  shouldShowPrices,
  shouldShowStatuses
}) => {
  const [activeSliderItem, setActiveSliderItem] = useState(-1);
  const [activeSliderItems, setActiveSliderItems] = useState([]);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [savedSliderItemApplied, setSavedSliderItemApplied] = useState(false);

  // Marketplace state
  const { MarketplaceState, MarketplaceStateDispatch } = useMarketplaceState();
  const { savedUiState } = MarketplaceState;

  // persist UI state related effects START
  useEffect(() => {
    if (!sliderLoaded) return;
    if (savedSliderItemApplied) {
      setActiveSliderItem(-1);
      return;
    }

    if (
      savedUiState.gallery?.activeSliderItem &&
      savedUiState.gallery.activeSliderItem !== activeSliderItems[0]
    ) {
      setActiveSliderItem(savedUiState.gallery.activeSliderItem);
      setSavedSliderItemApplied(true);
    }
  }, [savedUiState, sliderLoaded, savedSliderItemApplied, activeSliderItems]);

  useEffect(() => {
    if (!sliderLoaded) return;

    if (!!activeSliderItems[0]) {
      MarketplaceStateDispatch({
        type: 'updateSavedUiState',
        payload: {
          gallery: {
            activeSliderItem: activeSliderItems[0]
          }
        }
      });
    }
  }, [activeSliderItems, sliderLoaded, MarketplaceStateDispatch]);

  useEffect(() => {
    return () => {
      setSavedSliderItemApplied(false);
      setSliderLoaded(false);
    };
  }, []);
  // persist UI state related effects END

  const setActiveItems = useCallback(
    (firstActiveItemIndex, numberOfDisplayedItems) => {
      const indices = [];
      let i = -1;
      do {
        i++;
        indices.push(firstActiveItemIndex + i);
      } while (i < numberOfDisplayedItems);
      setActiveSliderItems(indices);
    },
    []
  );

  // Create the cards
  let cards = [];
  if (list) {
    cards = list.map((project, index) => (
      <PortfolioCard
        key={index}
        project={project}
        unitsData={projectsData?.[project.objectId]?.units}
        configuration={projectsData?.[project.objectId]?.configuration}
        status={projectsData?.[project.objectId]?.status}
        vaultSettings={vaultSettings}
        thumbnailUri={project.thumbnailUri}
        onCardClick={async () => {
          const contentCollectionObjectId =
            project.vmContentCollection?.objectId;

          // get the id of the first navigationItem in the collection
          let navigationItemObjectId;
          const navCollId = project?.navigationCollection?.objectId;
          if (navCollId) {
            const [, result] = await to(
              NavigationCollection.get(navCollId, true, sessionToken)
            );
            if (result) {
              const navCollection = result.data?.navigationCollection;
              const navItemList = navCollection?.vmNavigationItemList;
              if (navItemList && navItemList.length > 0) {
                navigationItemObjectId = navItemList[0].objectId;
              }
            }
          }

          MarketplaceStateDispatch({
            type: 'updateSavedUiState',
            payload: {
              gallery: {
                activeSliderItem: activeSliderItems[0]
              }
            }
          });

          onShowcaseSelected(
            contentCollectionObjectId,
            navigationItemObjectId,
            project.objectId
          );
        }}
        shouldShowPrices={shouldShowPrices}
        shouldShowStatuses={shouldShowStatuses}
        origin="gallery"
      />
    ));
  }

  if (salesPersonEnabled) {
    cards.push(
      <SalesPersonCard
        key="sales-person-card"
        setShowSalesPersonModal={setShowSalesPersonModal}
        salesPerson={salesPerson}
        canCalculateContent={sliderLoaded}
      />
    );
  }

  // The amound of max active images depends on the total number of cards.
  let maxActiveItems = 1;
  if (cards.length > 3 && !isMobileOnly) {
    maxActiveItems = 3;
  } else if (isMobileOnly && window.innerWidth > 400) {
    maxActiveItems = 2;
  }

  return (
    <SliderWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MotionSlider
        padding={isMobileOnly ? 45 : 170}
        gap={isMobileOnly ? 15 : 40}
        onSliderReachesEnd={loadNext}
        loadDataEndReached={endReached}
        maxActiveItems={maxActiveItems}
        disableScroll={disableScroll}
        disableArrows={!isMobileOnly}
        disableArrowKeys={disableArrowKeys}
        arrowsOffset={10}
        velocity={0.7}
        onActiveItemsChanged={setActiveItems}
        onSliderLoaded={() => setSliderLoaded(true)}
        activeSliderItem={activeSliderItem}
        enableLockedScroll={true}
      >
        {cards}
      </MotionSlider>
    </SliderWrapper>
  );
};

PortfolioSlider.propTypes = {
  list: array,
  sessionToken: string,
  loadNext: func,
  endReached: bool,
  onShowcaseSelected: func,
  disableScroll: bool,
  disableArrowKeys: bool,
  vaultSettings: shape({})
};

PortfolioSlider.defaultProps = {
  list: [],
  sessionToken: '',
  loadNext: () => {},
  endReached: false,
  onShowcaseSelected: () => {},
  disableScroll: false,
  disableArrowKeys: false
};

export default PortfolioSlider;
