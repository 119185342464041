import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandDirections, getDisplayDirection } from '../Spot';
import UnitStateBadge from 'components/unit/UnitStateBadge';
import ContactUs from 'components/share/ContactUs/ContactUs';
import FavoriteUnitButton from 'components/share/FavoriteUnitButton';

// Helpers
import { motion } from 'framer-motion';
import {
  unitStatesNotArchived,
  shouldShowUnitPrice
} from 'helpers/units/VmUnitHelper';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import {
  useGetUnitImagePreview,
  useShowUnitGeneralFields,
  useGetUnitCustomFields
} from 'helpers/customHooks';
import localizer from 'localization/localizer';
import {
  capitalize,
  getFieldIcon,
  displayLocalizedValue,
  getUnitPrice,
  getValueFromLocalized
} from 'helpers';
import currencySymbolMap from 'currency-symbol-map';
import zIndices from 'configs/TourImageZIndices.json';

// Styling
import styled from 'styled-components';

const maxTooltipWidth = 330;
const dottedLineHeight = 60;

const getOffsetInX = (displayDirectionInX) => {
  let horizontalOffset = '-50%';
  if (displayDirectionInX === ExpandDirections.left) {
    horizontalOffset = '-100%';
  } else if (displayDirectionInX === ExpandDirections.right) {
    horizontalOffset = '0%';
  }
  return horizontalOffset;
};

const UnitInfoPreviewWrapper = styled(motion.div).attrs(({ navTransform }) => ({
  style: {
    top: `${navTransform?.y ?? 0}px`,
    left: `${navTransform?.x ?? 0}px`
  }
}))`
  width: ${(props) => props.tooltipWidth}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: ${(props) => (props.isShown ? 'initial' : 'none')};
  cursor: ${(props) => (props.isShown ? 'pointer' : 'default')};
  z-index: ${(props) => (props.isShown ? zIndices.spotTooltip : '1')};

  ${({
    isSharedTooltip,
    initialPosition,
    verticalExpandDirection,
    tooltipWidth,
    tooltipHeight,
    navTransform,
    origin
  }) => {
    if (origin === 'polygon') {
      return `
        top: 0;
        left: 0;
      `;
    }
    if (navTransform) return '';
    if (isSharedTooltip) {
      const x = initialPosition.x;
      let y = initialPosition.y;
      if (verticalExpandDirection === ExpandDirections.top) {
        y -= tooltipHeight + 30;
      }

      return `
      left: ${x}px !important;
      top: ${y}px !important;
    `;
    } else {
      return verticalExpandDirection === ExpandDirections.top
        ? {
            bottom: '20px !important'
          }
        : {
            top: '20px !important'
          };
    }
  }}
`;

const DottedLineWrapper = styled.div`
  width: ${({ withLineHoverArea }) => (!withLineHoverArea ? '3px' : '100%')};
  height: 100%;
  display: ${({ origin }) => (origin === 'polygon' ? 'none' : 'flex')};
  justify-content: center;
  position: absolute;

  ${({ verticalExpandDirection, origin }) =>
    verticalExpandDirection === ExpandDirections.bottom &&
    `bottom: ${origin === 'unit' ? 30 : 60}px`};
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top && 'top: 60px'};

  ${(props) =>
    props.horizontalExpandDirection === ExpandDirections.right && {
      width: !props.withLineHoverArea ? '3px' : '130px',
      left: '-20px',
      paddingLeft: '20px',
      justifyContent: 'flex-start'
    }};
  ${(props) =>
    props.horizontalExpandDirection === ExpandDirections.left && {
      width: !props.withLineHoverArea ? '3px' : '130px',
      right: '-20px',
      paddingRight: '20px',
      justifyContent: 'flex-end'
    }}};
`;

const DottedLineHoverArea = styled.div`
  position: absolute;
  width: 150px;
  height: 75%;

  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.bottom && 'bottom: 0'}
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top && 'top: 0'}
`;

const DottedLineHoverAreaTwo = styled.div`
  position: absolute;
  width: 250px;
  height: 65%;

  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.bottom && 'bottom: 0'}
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top && 'top: 0'};
`;

const DottedLine = styled.div`
  width: 2px;
  height: calc(
    100% - 20px - ${({ yPositionCorrection }) => yPositionCorrection}px
  );
  background-image: linear-gradient(
    to top,
    white 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: right;
  background-size: 2px 10px;
  background-repeat: repeat-y;

  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.bottom &&
    'margin-top: 20px'}
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top &&
    'margin-bottom: 20px'};
`;

const UnitInfoWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top
      ? {
          top: 0
        }
      : {
          bottom: 0
        }}
`;

const FavoriteUnitButtonWrapper = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  flex-shrink: 0;
  padding: 8px 5px 0 0;
`;

const UnitCard = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray300};
  background-color: ${(props) => props.theme.whitePure};
`;

const SimplifiedUnitCard = styled(UnitCard)`
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  align-items: center;
`;

const UnitInfo = styled.div`
  font-size: 0.875rem;
  color: black;
`;

const UnitTitle = styled(UnitInfo)`
  font-size: 1rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const CenteredUnitTitle = styled(UnitTitle)`
  text-align: center;
`;

const UnitPriceStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnitPrice = styled(UnitInfo)`
  font-weight: 500;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
  display: flex;
  align-items: center;
`;

const Price = styled(UnitInfo)`
  font-weight: 700;
  padding-left: 5px;
  color: inherit;
`;

const UnitMetaInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: ${(props) => props.flexBasis}%;
`;

const UnitMetaInfo = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: auto;
  white-space: nowrap;
`;

const UnitMetaIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.showcaseBlack};
  opacity: 0.5;
  margin-right: 14px;
`;

const UnitStateTag = styled(UnitStateBadge)`
  position: absolute;
  right: 15px;
  top: -15px;
  font-size: 0.875rem;
  color: ${(props) => props.theme.showcaseWhite};
  border-radius: 20px;
  min-width: 100px;
  height: 30px;
`;

const Divider = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${(props) => props.theme.gray200};
`;

const HDivider = styled(Divider)`
  width: 100%;
  height: 1px;
`;

const BlockWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
`;

const MainInfoBlockWrapper = styled(BlockWrapper)`
  padding-right: 0;
`;

const GeneralFieldsBlockWrapper = styled(BlockWrapper)`
  justify-content: center;
`;

const CustomFieldsBlockWrapper = styled(BlockWrapper)`
  flex-direction: column;
`;

const NoPreviewPlaceholder = styled.div`
  width: 80px;
  height: 80px;
  padding: 8px;
  box-sizing: border-box;
  margin-right: 15px;
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  font-size: 0.75rem;
  color: ${(props) => props.theme.fadeGray};
  flex-shrink: 0;
`;

const PreviewImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 2px;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
`;

const UnitMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  margin-right: 10px;
`;

const UnitTypeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnitType = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 5px;
  color: ${(props) => props.theme.primary100};
`;

const StyledUnitTypeIcon = styled(StyledPriceIcon)``;
const StyledCustomFieldIcon = styled(StyledPriceIcon)`
  margin-top: 2px;
`;

const CustomFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${(props) => (props.first ? '0' : '12px')};
  word-break: break-word;
`;

const CustomFieldTitle = styled.span`
  margin: 0 10px 0 0;
  color: ${(props) => props.theme.showcaseBlack};
  font-size: 0.75rem;
`;

const CustomFieldValue = styled(CustomFieldTitle)`
  margin: 0;
  line-height: 1.43;
`;

const CustomFieldValueParagraph = styled.p`
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
  text-align: justify;
`;

const IconWrapper = styled.span`
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: -2px;
`;

const MoreFieldsCounter = styled.p`
  margin: 0;
  margin-top: 5px;
  font-weight: 600;
  align-self: center;
`;

const ShowDetailsButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.defaultBrandPrimaryColor};
  border-radius: 2px;
  color: ${(props) => props.theme.showcaseWhite};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  letter-spacing: normal;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  word-break: break-word;
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 5;
  top: -1px;
  right: -3px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${(props) => props.theme.showcaseBlack};
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: ${(props) => props.theme.primary100};
  padding: 0;
`;

/**
 * Tooltip to display next to a unit spot containing information on the unit.
 * Depending on where this spot is on the screen, the tooltip will position itself to fit.
 */
const UnitSpotTooltip = ({
  unitSpotLocation,
  unit,
  showUnitState,
  shouldShow,
  onClick,
  onTouchStart,
  onMouseEnter,
  onMouseLeave = () => {},
  unitSpotAbsoluteLocation,
  isSharedTooltip = false,
  parentId,
  withLineHoverArea = true,
  withCloseIcon = false,
  onClose = () => {},
  onSizeComputed,
  origin = 'unit',
  is360Viewer = false,
  canvas = null,
  currentRotation
}) => {
  const [customFields, setCustomFields] = useState([]);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [displayDirection, setDisplayDirection] = useState({
    x: null,
    y: null
  });
  const [offsetInX, setOffsetInX] = useState(0);
  const [yPositionCorrection, setYPositionCorrection] = useState(0);

  // Auth State
  const { AuthState } = useAuthState();

  // Project Store
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  const { showPrice, showSurface, showBedrooms, showBathrooms } =
    useShowUnitGeneralFields(true, null, [unit]);
  const showUnitGeneralFields = showSurface || showBedrooms || showBathrooms;

  const showUnitPrice = shouldShowUnitPrice(
    ProjectState?.showcaseConfiguration,
    AuthState,
    unit?.state
  );

  const currencySymbol = currencySymbolMap(
    ProjectState?.vault?.vaultSettings?.defaultCurrencyCode || 'EUR'
  );
  let unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;

  const { previewImageUrl } = useGetUnitImagePreview(unit, null, `h=80`);

  const containerRef = useRef();

  // panellum related
  const [container, setContainer] = useState();
  const [navTransform, setNavTransform] = useState();

  useEffect(() => {
    if (container && is360Viewer) {
      const transform = window.getComputedStyle(container).transform;
      const splitted = transform.split(',');

      const x = splitted[12];
      const y = splitted[13];
      const z = splitted[14];

      setNavTransform({ x, y, z });
    } else {
      const base = window.document.getElementsByClassName(`spot${parentId}`);

      if (base[0]) {
        setContainer(base[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRotation, container, canvas, parentId, is360Viewer]);

  useEffect(() => {
    setTooltipHeight(containerRef.current.offsetHeight);
    if (!onSizeComputed) return;
    onSizeComputed({
      height: containerRef.current.offsetHeight,
      width: maxTooltipWidth
    });
  }, [containerRef, shouldShow, customFields, onSizeComputed]);

  useEffect(() => {
    const displayDirection = getDisplayDirection(
      unitSpotAbsoluteLocation,
      maxTooltipWidth,
      tooltipHeight,
      window.innerWidth
    );

    if (displayDirection.y === 'top') {
      const tooltipIsFullyVisible =
        unitSpotAbsoluteLocation.y - dottedLineHeight - tooltipHeight > 0;
      if (!tooltipIsFullyVisible) {
        // 5px is to add a visible gap between a tooltip and a tour image border
        setYPositionCorrection(
          tooltipHeight - unitSpotAbsoluteLocation.y + dottedLineHeight + 5
        );
        // since we move the tooltip down we need to place it next to the spot
        // here we calculate a proper side position, 12 - is a radius of the spot
        displayDirection.x =
          unitSpotAbsoluteLocation.x + maxTooltipWidth / 2 + 12 >
          window.innerWidth
            ? 'left'
            : 'right';
      } else {
        setYPositionCorrection(0);
      }
    } else {
      setYPositionCorrection(0);
    }

    setDisplayDirection(displayDirection);
  }, [tooltipHeight, unitSpotLocation, unitSpotAbsoluteLocation]);

  useEffect(() => {
    setOffsetInX(getOffsetInX(displayDirection.x));
  }, [displayDirection]);

  const renderImagePreviewOrPlaceholder = useCallback(() => {
    if (previewImageUrl) {
      return <PreviewImage src={previewImageUrl} />;
    }
    return (
      <NoPreviewPlaceholder>
        {capitalize(localizer.noAttachmentForUnit)}
      </NoPreviewPlaceholder>
    );
  }, [previewImageUrl]);

  const renderMetaInfo = (showcaseConfiguration) => {
    const metaInfo = [];
    if (showSurface) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'surface', 'fal'),
        info: `${unit.unitMetadata?.surface} m²`
      });
    }
    if (showBedrooms) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBedrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBedrooms
      });
    }
    if (showBathrooms) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBathrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBathrooms
      });
    }
    const flexBasis = 100 / metaInfo.length;
    return metaInfo.map((item, idx) => {
      const needDivider = metaInfo.length > 1 && idx !== 0;
      return (
        <UnitMetaInfoWrapper key={idx} flexBasis={flexBasis}>
          {needDivider && <Divider />}
          <UnitMetaInfo>
            {!!item.icon && <UnitMetaIcon icon={item.icon} size="1x" />}
            <UnitInfo>{item.info}</UnitInfo>
          </UnitMetaInfo>
        </UnitMetaInfoWrapper>
      );
    });
  };

  const renderCustomFields = useCallback(() => {
    if (customFields?.length === 0) return null;

    return (
      <CustomFieldsBlockWrapper>
        {customFields?.slice(0, 3)?.map((field, idx) => (
          <CustomFieldWrapper first={idx === 0} key={idx}>
            {field.icon && (
              <IconWrapper>
                <StyledCustomFieldIcon icon={['far', field.icon]} size="1x" />
              </IconWrapper>
            )}
            {field.title && (
              <CustomFieldTitle>{capitalize(field.title)}:</CustomFieldTitle>
            )}
            {field.showAsParagraph ? (
              <CustomFieldValueParagraph>
                {field.value}
              </CustomFieldValueParagraph>
            ) : (
              <CustomFieldValue>{field.value}</CustomFieldValue>
            )}
          </CustomFieldWrapper>
        ))}
        {customFields?.length > 3 && (
          <MoreFieldsCounter>
            + {customFields?.length - 3} {localizer.more}
          </MoreFieldsCounter>
        )}
      </CustomFieldsBlockWrapper>
    );
  }, [customFields]);

  const { customFields: unitCustomFields } = useGetUnitCustomFields(unit);

  useEffect(() => {
    if (unitCustomFields) {
      const filteredFields =
        unitCustomFields?.filter((field) => field.isVisibleInTourView) ?? [];
      setCustomFields(filteredFields);
    }
  }, [unitCustomFields]);

  const unitTitle = getValueFromLocalized(
    unit?.localizedTitle,
    unit?.title ?? ''
  );

  return (
    <UnitInfoPreviewWrapper
      ref={containerRef}
      tooltipWidth={maxTooltipWidth}
      tooltipHeight={tooltipHeight}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => onMouseLeave(parentId)}
      initial={{
        opacity: 0.0,
        x: origin === 'polygon' ? 0 : offsetInX,
        y:
          origin === 'polygon'
            ? 0
            : displayDirection.y === ExpandDirections.top
            ? -20 + yPositionCorrection
            : 20
      }}
      animate={
        shouldShow
          ? {
              opacity: 1.0,
              x: origin === 'polygon' ? 0 : offsetInX,
              y:
                origin === 'polygon'
                  ? 0
                  : displayDirection.y === ExpandDirections.top
                  ? -20 + yPositionCorrection
                  : 20
            }
          : {
              opacity: 0,
              x: offsetInX,
              y:
                origin === 'polygon'
                  ? 0
                  : displayDirection.y === ExpandDirections.top
                  ? -20 + yPositionCorrection
                  : 20
            }
      }
      transition={{ duration: 0.1, type: 'tween' }}
      onClick={() => {
        onClick();
      }}
      onTouchStart={onTouchStart}
      isShown={shouldShow}
      horizontalExpandDirection={displayDirection.x}
      verticalExpandDirection={displayDirection.y}
      initialPosition={unitSpotAbsoluteLocation}
      isSharedTooltip={isSharedTooltip}
      navTransform={navTransform}
      origin={origin}
    >
      <DottedLineWrapper
        horizontalExpandDirection={displayDirection.x}
        verticalExpandDirection={displayDirection.y}
        withLineHoverArea={withLineHoverArea}
        origin={origin}
      >
        <DottedLine
          verticalExpandDirection={displayDirection.y}
          yPositionCorrection={yPositionCorrection}
        />
        {withLineHoverArea && (
          <>
            <DottedLineHoverArea verticalExpandDirection={displayDirection.y} />
            <DottedLineHoverAreaTwo
              verticalExpandDirection={displayDirection.y}
            />
          </>
        )}
      </DottedLineWrapper>

      <UnitInfoWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {withCloseIcon && (
          <CloseButton
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon icon={['fal', 'times']} size="1x" />
          </CloseButton>
        )}

        {!!showcaseConfiguration?.enableSimplifiedUnitCard ? (
          <SimplifiedUnitCard>
            <CenteredUnitTitle>{unit?.title}</CenteredUnitTitle>
            <ShowDetailsButton>{localizer.showDetails}</ShowDetailsButton>
          </SimplifiedUnitCard>
        ) : (
          <UnitCard>
            {showUnitState && (
              <UnitStateTag unitState={unit?.state} unitFlow={unit?.unitFlow} />
            )}

            <MainInfoBlockWrapper>
              {showcaseConfiguration?.showUnitPreviewImage &&
                renderImagePreviewOrPlaceholder()}
              <UnitMainInfo>
                <UnitTitle>{unitTitle}</UnitTitle>
                <UnitTypeAndPrice>
                  {unit?.unitType && (
                    <UnitType>
                      {unit?.unitType?.icon && (
                        <StyledUnitTypeIcon
                          icon={['far', unit.unitType.icon]}
                          size="1x"
                        />
                      )}
                      {displayLocalizedValue(unit?.unitType?.name?.textMap)}
                    </UnitType>
                  )}
                  {showUnitPrice && showPrice && (
                    <UnitPriceStatus>
                      <UnitPrice>
                        <StyledPriceIcon
                          icon={getFieldIcon(
                            showcaseConfiguration,
                            'price',
                            'far'
                          )}
                          size="1x"
                        />
                        <Price>{unitPrice}</Price>
                      </UnitPrice>
                    </UnitPriceStatus>
                  )}
                </UnitTypeAndPrice>
              </UnitMainInfo>
              {!showcaseConfiguration?.hideFavouriteOptions && (
                <FavoriteUnitButtonWrapper>
                  <FavoriteUnitButton unit={unit} buttonSize="36" />
                </FavoriteUnitButtonWrapper>
              )}
            </MainInfoBlockWrapper>

            {showUnitGeneralFields && (
              <>
                <HDivider />
                <GeneralFieldsBlockWrapper>
                  {renderMetaInfo(showcaseConfiguration)}
                </GeneralFieldsBlockWrapper>
              </>
            )}

            {showcaseConfiguration?.showUnitCustomFields &&
              unit?.customFieldValues && (
                <>
                  <HDivider />
                  {renderCustomFields()}
                </>
              )}

            {showcaseConfiguration?.showUnitContactUsButton && (
              <>
                <HDivider />
                <BlockWrapper>
                  <ContactUs unit={unit} location="Tour page (desktop)" />
                </BlockWrapper>
              </>
            )}
          </UnitCard>
        )}
      </UnitInfoWrapper>
    </UnitInfoPreviewWrapper>
  );
};

UnitSpotTooltip.propTypes = {
  /** Location of the spot that this tooltip is attached to */
  unitSpotLocation: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }).isRequired,
  /** Unit represented by the spot, it's information will be displayed in this tooltip */
  unit: PropTypes.shape({
    title: PropTypes.string,
    state: PropTypes.oneOf(unitStatesNotArchived),
    unitMetadata: PropTypes.shape({
      surface: PropTypes.number,
      numberOfBedrooms: PropTypes.number
    })
  }),
  /** Show the state of the unit or not. Default is true. */
  showUnitState: PropTypes.bool,
  /** Should the tool tip be shown or not? */
  shouldShow: PropTypes.bool,
  /** Callback to execute when a certain area round the tooltip and the line leading to it is clicked */
  onClick: PropTypes.func
};

UnitSpotTooltip.defaultProps = {
  showUnitState: true
};

export default UnitSpotTooltip;
