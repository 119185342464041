/**
 * Convert a string containing coordinates into an array of coordinates.
 * @param {string} coordsInString
 * @returns Array of coordinates
 */
export const stringToPolygonCoords = (coordsInString) => {
  let coordGroups = coordsInString.split(';');
  let newCoords = [];
  coordGroups.forEach((coordGroup) => {
    let group = coordGroup.split(',');
    newCoords.push({
      x: Number(group[0]),
      y: Number(group[1])
    });
  });

  return newCoords;
};
