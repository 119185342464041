import React, { useState, useEffect } from 'react';
import { shape, array, arrayOf, string, func } from 'prop-types';

// Helpers
import { getLocalizedTextForUnitState } from 'helpers/units/VmUnitHelper';
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';

// Styling
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
`;

const Label = styled.div`
  color: ${(props) =>
    isMobile ? props.theme.showcaseBlack : props.theme.primary200};
  font-size: 0.9rem;
  margin-bottom: 5px;
  flex-shrink: 0;
`;

const lang = localizer.getLanguage();

/**
 * Component that displays toggleable buttons for a filter with multiple options
 */
const FilterToggleButtons = ({
  filterKey,
  toggledOptions,
  allOptions,
  onUpdateToggledOptions,
  mapper,
  button: Button
}) => {
  const [localValues, setLocalValues] = useState(toggledOptions.values);

  const onToggleOption = (option) => {
    let newValues = localValues;
    if (localValues.includes(option)) {
      // Toggle off
      newValues = localValues.filter((x) => x !== option);
    } else {
      //Toggle on
      newValues.push(option);
    }

    setLocalValues(newValues);
    onUpdateToggledOptions(newValues);
  };

  useEffect(() => {
    setLocalValues(toggledOptions.values);
  }, [toggledOptions]);

  return (
    <>
      <Label>{localizer.unitFields[filterKey]}</Label>
      <ButtonWrapper>
        {allOptions.map((option) => {
          const sharedProps = {
            key: `toggle${option}`,
            toggled: localValues.includes(option),
            onClick: () => {
              onToggleOption(option);
            }
          };

          if (isMobile) {
            sharedProps.unitState = option;
          }
          return (
            <Button {...sharedProps}>
              {mapper
                ? (mapper[option][lang] || mapper[option].en) ?? ''
                : getLocalizedTextForUnitState(option).toUpperCase()}
            </Button>
          );
        })}
      </ButtonWrapper>
    </>
  );
};

FilterToggleButtons.propTypes = {
  /** The options that are currently toggled */
  toggledOptions: shape({ values: arrayOf(string).isRequired }).isRequired,
  /** All the options that are possible */
  allOptions: array.isRequired,
  /** Callback when the buttons have been toggled
   * @callback
   * @param {array} updatedValues The values that were updated
   */
  onUpdateToggledOptions: func.isRequired
};

export default FilterToggleButtons;
