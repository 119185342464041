import localizer from 'localization/localizer';

/**
 * Get the project related data in current language or fallback to default value
 * @param {Object} project The entire project data
 * @param {string} defaultValue Default value
 * @param {{ textMap: Object }} localizedValues Localized values are stored in the textMap object under respective keys: 'de', 'fr', 'nl'
 */
const getLocalizedOrDefaultValue = (project, defaultValue, localizedValues) => {
  let value = defaultValue ?? '';
  if (project.useLocalizedStandardFields) {
    const localizedValue = localizedValues?.textMap?.[localizer.getLanguage()];
    if (localizedValue) value = localizedValue;
  }
  return value;
};

/**
 * Get localized project title
 * @param {Object} project The entire project data
 */
export const getLocalizedProjectTitle = (project) => {
  if (!project) return;
  const title = getLocalizedOrDefaultValue(
    project,
    project.title,
    project.localizedTitle
  );
  return title;
};

/**
 * Get localized project description
 * @param {Object} project The entire project data
 */
export const getLocalizedProjectDescription = (project) => {
  if (!project) return;
  const description = getLocalizedOrDefaultValue(
    project,
    project.description,
    project.localizedDescription
  );
  return description;
};
