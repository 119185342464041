import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Styling
import { withTheme } from 'styled-components';

const StyledScrollbarWrapper = ({ size, children, theme, ...props }) => {
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: theme.gray150
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars
      autoHeight
      autoHeightMax={size}
      renderThumbVertical={renderThumb}
      {...props}
    >
      {children}
    </Scrollbars>
  );
};

export default withTheme(StyledScrollbarWrapper);
