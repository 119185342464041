import React from 'react';

// Styling
import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';

const NavigationWrapper = styled.div`
  align-self: stretch;
  flex: 60px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${isMobile ? '10px 15px' : '20px 30px'};
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray200};
  justify-content: ${(props) => (props.isLastPage ? 'center' : 'none')}; ;
`;

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: ${(props) =>
    props.current ? props.theme.primary300 : props.theme.gray300};
`;

const SkipButton = styled.button`
  font-weight: 500;
  letter-spacing: normal;
  text-transform: ${isMobile ? 'uppercase' : 'capitalize'};
  color: ${(props) => props.theme.primary100};
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  height: 100%;
  border: ${(props) =>
    isMobile ? 'none' : 'solid 1px ' + props.theme.gray200};
  border-radius: 2px;
  padding: ${isMobile ? 'none' : '10px 0'};
  line-height: 1.43;
  width: ${isMobile ? 'none' : '120px'};
  height: 40px;
  flex-shrink: 0;
`;

const NextButton = styled(SkipButton)`
  color: ${(props) =>
    isMobile ? props.theme.primary400 : props.theme.mainAccent};
  border: ${(props) =>
    isMobile ? 'none' : 'solid 1px ' + props.theme.mainAccent};
  max-width: 360px;
`;

const GetStartedButton = styled(NextButton)`
  flex-grow: 1;
  text-align: center;
`;

const ArrowRightIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const Navigation = ({
  currentPageIdx,
  pages,
  onSkip,
  onNext,
  onGetStarted
}) => {
  const isLastPage = currentPageIdx === pages.length - 1;
  return (
    <NavigationWrapper isLastPage={isLastPage}>
      {isLastPage ? (
        <GetStartedButton onClick={onGetStarted}>
          {localizer.getStarted}
        </GetStartedButton>
      ) : (
        <>
          <SkipButton onClick={onSkip}>{localizer.skip}</SkipButton>
          <DotsWrapper>
            {pages.map((p, idx) => (
              <Dot key={idx} current={idx === currentPageIdx} />
            ))}
          </DotsWrapper>
          <NextButton onClick={onNext}>
            {localizer.next}
            <ArrowRightIcon icon={['fal', 'arrow-right']} size="1x" />
          </NextButton>
        </>
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
