import React, { useMemo } from 'react';
import { bool, func, number } from 'prop-types';

// Components
import SearchField from 'components/other/SearchField';
import ViewSwitcher from './ViewSwitcher';
import PortfolioFilter from './portfolioFilter/PortfolioFilter';
import { motion, AnimatePresence } from 'framer-motion';
import SearchByProjectButton from './SearchByProjectButton';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import { makeResponsive } from 'helpers/breakpoints';

// Styling
import styled, { css, withTheme } from 'styled-components';
import { view } from './PortfolioPage';

const Wrapper = styled.div`
  position: fixed;
  z-index: 31;
  top: 30px;
  left: 40px;
  right: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  ${isMobileOnly &&
  css`
    top: 15px;
    right: 15px;
    left: 15px;
    grid-template-columns: 1fr;
  `}
`;

const ViewSwitcherWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  position: relative;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  align-self: flex-start;
`;

const SearchFieldWrapper = styled(ViewSwitcherWrapper)`
  max-width: 250px;
  margin-right: 20px;
  justify-content: flex-start;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const MobileSearchWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  left: 0;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  order: 1;
  transition: all 200ms ease;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const VisibleItemsMessage = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  top: calc(100% + 4px);
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
  font-size: ${makeResponsive(['0.75rem', '1rem', '1.1rem'])};
  white-space: ${makeResponsive(['normal', 'nowrap'])};
  color: ${({ theme, isSatelliteView }) =>
    isSatelliteView ? theme.showcaseWhite : theme.primary300};
`;

const PortfolioToolbar = ({
  onSearchChange,
  onSearchFocus,
  selectedView,
  setView,
  showPortfolioFilter,
  setShowPortfolioFilter,
  disableSort,
  disableFilter,
  setSelectedSortOption,
  selectedSortOption,
  onSetFilteredProjectIds,
  vaultId,
  searchedProjects,
  searchValue,
  setSearchValue,
  setSmartSearchValue,
  smartSearchValue,
  setShowSalesPersonModal,
  isVisible,
  allowedViews,
  shouldShowPrices,
  resetUiState,
  salesPersonEnabled,
  salesPerson,
  setShowWizard,
  showWizardLaunchButton,
  mapStreetViewEnabled,
  satelliteViewEnabled,
  theme,
  setShowSmartSearch,
  shouldShowStatuses
}) => {
  // Marketplace state
  const { MarketplaceState } = useMarketplaceState();
  const { mapVisibleProjectsMessage, appliedFilters } = MarketplaceState;

  const searchBarProps = useMemo(
    () => ({
      placeholder: localizer.locationOrProject,
      inputFieldStyles: searchInputFieldStyles,
      searchIconStyles: searchIconStyles,
      wrapperStyles: searchWrapperStyles(theme),
      showResultsInside: false,
      onFocus: onSearchFocus,
      onChange: onSearchChange,
      initValue: smartSearchValue ?? '',
      disabled: !smartSearchValue && disableSort
    }),
    [theme, disableSort, onSearchChange, onSearchFocus, smartSearchValue]
  );

  const searchField = isMobileOnly ? (
    <MobileSearchWrapper visible={isVisible}>
      <SearchField
        onOpenFilter={() => setShowPortfolioFilter(true)}
        filtersCount={appliedFilters?.length ?? 0}
        canGrow={true}
        disabled={!searchValue && disableSort}
        {...searchBarProps}
      />
      {selectedView === view.map && (
        <VisibleItemsMessage isSatelliteView={satelliteViewEnabled}>
          {mapVisibleProjectsMessage}
        </VisibleItemsMessage>
      )}
    </MobileSearchWrapper>
  ) : (
    <SearchFieldWrapper visible={isVisible}>
      <SearchByProjectButton
        onClick={() => setShowSmartSearch(true)}
        searchValue={smartSearchValue}
        setSearchValue={setSearchValue}
        setSmartSearchValue={setSmartSearchValue}
      />
    </SearchFieldWrapper>
  );

  return (
    <Wrapper>
      {searchField}

      <PortfolioFilter
        show={showPortfolioFilter}
        setShow={setShowPortfolioFilter}
        disableSort={disableSort}
        disableFilter={disableFilter}
        setSelectedSortOption={setSelectedSortOption}
        selectedSortOption={selectedSortOption}
        onSetFilteredProjectIds={onSetFilteredProjectIds}
        vaultId={vaultId}
        {...sharedTransition}
        shouldShowPrices={shouldShowPrices}
        visible={isVisible}
        resetUiState={resetUiState}
        setShowSalesPersonModal={setShowSalesPersonModal}
        selectedView={selectedView}
        setView={setView}
        allowedViews={allowedViews}
        salesPersonEnabled={salesPersonEnabled}
        salesPerson={salesPerson}
        setShowWizard={setShowWizard}
        showWizardLaunchButton={showWizardLaunchButton}
        shouldShowStatuses={shouldShowStatuses}
      />

      <AnimatePresence>
        {!isMobileOnly && (
          <ViewSwitcherWrapper
            visible={!mapStreetViewEnabled}
            {...sharedTransition}
          >
            <ViewSwitcher
              selectedView={selectedView}
              setView={setView}
              allowedViews={allowedViews}
            />
            {selectedView === view.map && (
              <VisibleItemsMessage isSatelliteView={satelliteViewEnabled}>
                {mapVisibleProjectsMessage}
              </VisibleItemsMessage>
            )}
          </ViewSwitcherWrapper>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const sharedTransition = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { delay: 0.1 }
};

const searchWrapperStyles = (theme) => `
  height: 50px;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  border: solid 1px ${theme.gray150};
  background-color: rgba(255, 255, 255, ${theme.controlsOpacity});
  padding: 13px 15px;
  box-sizing: border-box;
`;

const searchInputFieldStyles = `
  background-color: transparent;
  color: black;
  font-weight: 500;
  text-align: left;
  &::placeholder {
    text-transform: none;
    letter-spacing: normal;
    font-weight: 400;
  }
`;

const searchIconStyles = `
  margin-right: 10px;
`;

PortfolioToolbar.propTypes = {
  onSearchChange: func,
  disabled: bool,
  showPortfolioFilter: bool,
  setShowPortfolioFilter: func,
  foundProjects: number
};

PortfolioToolbar.defaultProps = {
  onSearchChange: () => {},
  disabled: false,
  showPortfolioFilter: false,
  setShowPortfolioFilter: () => {}
};

export default withTheme(PortfolioToolbar);
