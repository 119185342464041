import React, { useRef } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayView } from '@react-google-maps/api';

// Helpers
import { isDarkColor } from 'helpers';
import { motion, AnimatePresence } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Marker = styled(motion.div)`
  transform-origin: bottom;
  position: relative;
  top: -48px;
  left: -18px;
`;

const LocationIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: ${({ theme }) =>
    isDarkColor(theme.contactCardBg) ? theme.contactCardBg : theme.brand500};
`;

const ChosenLocationMarker = ({ location }) => {
  const markerRef = useRef();

  if (markerRef?.current) {
    markerRef.current.parentNode.parentNode.style.zIndex = 1010;
  }

  if (!location) return null;

  return (
    <OverlayView
      position={location}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <AnimatePresence>
        {!!location && (
          <Marker
            ref={markerRef}
            // -130 is added to provide a drop-like animation
            initial={{ opacity: 0, y: -100, scale: 0.4 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{ delay: 0.2, type: 'tween' }}
          >
            <LocationIcon icon={['fas', 'map-marker-alt']} size="1x" />
          </Marker>
        )}
      </AnimatePresence>
    </OverlayView>
  );
};

export default ChosenLocationMarker;
