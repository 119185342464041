import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';

// Components
import { motion, AnimatePresence } from 'framer-motion';

// Heplers
import { view } from './PortfolioPage';
import { isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import { isDarkColor } from 'helpers';

// Styling
import styled, { css, withTheme } from 'styled-components';
import {
  Title,
  PersonWrapper,
  Avatar,
  Name,
  Occupation,
  Contact,
  Contacts
} from './sharedStyledComponents';

const Wrapper = styled.div`
  width: 100%;
  height: 210px;
  padding: 20px;
  margin-top: 25px;
  box-sizing: border-box;
  display: flex;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${({ theme }) => theme.gray200};
  transition: all 200ms ease;
  &:hover {
    box-shadow: 7px 6px 19px 2px rgba(0, 0, 0, 0.44);
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 600px;
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  ${isMobileOnly &&
  css`
    padding: 10px;
  `}
  ${({ origin }) => {
    switch (origin) {
      case view.cards:
        return css`
          overflow: hidden;
          ${isMobileOnly &&
          css`
            min-height: fit-content;
          `}
        `;
      default:
    }
  }}
`;

const ContactButton = styled(motion.button)`
  height: 60px;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.3);
  background-color: ${({ theme }) => theme.showcaseWhite};
  color: ${({ color }) => color};
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  ${isMobileOnly &&
  css`
    padding: 10px;
    font-size: 0.875rem;
  `}
`;

const ContactsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: 60px;
`;

const Info = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
`;

const imageRequiredHeight = 180;
const infoRequiredHeight = 90;
const titleRequiredHeight = 60;
const margin = 20;

const SalesPersonCard = ({
  origin,
  setShowSalesPersonModal,
  salesPerson,
  canCalculateContent = true,
  theme
}) => {
  const [showContacts, setShowContacts] = useState(false);

  const isInitiallyVisible = origin !== view.gallery;

  const [showTitle, setShowTitle] = useState(isInitiallyVisible);
  const [showInfo, setShowInfo] = useState(isInitiallyVisible);
  const [showImage, setShowImage] = useState(isInitiallyVisible);

  const [contentHeight, setContentHeight] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [infoHeight, setInfoHeight] = useState(0);
  const [contactsHeight, setContactsHeight] = useState(0);

  const cardColor = theme.contactCardBg;
  const bgIsDark = isDarkColor(cardColor);

  const contentRef = useRef();
  const titleRef = useRef();
  const contactsRef = useRef();
  const infoRef = useRef();

  // calculate heights of components
  useLayoutEffect(() => {
    if (!canCalculateContent) return;
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
    if (contactsRef.current) {
      setContactsHeight(contactsRef.current.offsetHeight);
    }
    if (titleRef.current) {
      setTitleHeight(titleRef.current.offsetHeight);
    }
    if (showInfo && infoRef.current) {
      setInfoHeight(infoRef.current.offsetHeight);
    }
  }, [showInfo, canCalculateContent]);

  // display title if possible
  useEffect(() => {
    if (origin && origin !== view.gallery) {
      return;
    }
    if (!contentHeight || !contactsHeight) return;
    const canShowTitle = contentHeight - contactsHeight > titleRequiredHeight;
    setShowTitle(canShowTitle);
  }, [contentHeight, contactsHeight, origin]);

  // display info if possible
  useEffect(() => {
    if (origin && origin !== view.gallery) {
      return;
    }
    if (!showTitle) return;
    if (!contentHeight || !contactsHeight || !titleHeight) return;
    const canShowInfo =
      contentHeight - contactsHeight - titleHeight >
      margin + infoRequiredHeight + margin;
    setShowInfo(canShowInfo);
  }, [contentHeight, contactsHeight, showTitle, titleHeight, origin]);

  // display image if possible
  useEffect(() => {
    if (origin && origin !== view.gallery) {
      return;
    }
    // no need for calculation if info is not displayed
    if (!showInfo || infoHeight === 0) return;
    if (!contentHeight || !contactsHeight || !titleHeight || !infoHeight)
      return;
    const canShowImage =
      contentHeight -
        contactsHeight -
        titleHeight -
        margin -
        infoHeight -
        margin >
      imageRequiredHeight;
    setShowImage(canShowImage);
  }, [
    contentHeight,
    contactsHeight,
    showInfo,
    titleHeight,
    showImage,
    infoHeight,
    origin
  ]);

  const showContactsHandler = () => {
    if (isMobileOnly || origin === view.map) {
      setShowSalesPersonModal(true);
    } else {
      setShowContacts(true);
    }
  };

  return (
    <>
      <Wrapper
        bg={cardColor}
        color={bgIsDark ? theme.showcaseWhite : theme.showcaseBlack}
        onClick={!showContacts ? () => showContactsHandler() : undefined}
      >
        <Content ref={contentRef} origin={origin} contactsShown={showContacts}>
          {showTitle && (
            <Title
              ref={titleRef}
              initial={{ opacity: 0, height: 'auto' }}
              animate={{
                opacity: showContacts ? 0 : 1,
                height: showContacts ? 0 : 'auto'
              }}
              transition={{ duration: 0.8 }}
              onAnimationComplete={
                showContacts ? () => setShowTitle(false) : undefined
              }
            >
              {localizer.marketplace.cantFindMatch}
            </Title>
          )}

          {origin !== view.map && showInfo && (
            <PersonWrapper
              animate={{ y: showContacts ? -20 : 0 }}
              transition={{ duration: 0.8, ease: 'linear' }}
              contactsShown={showContacts}
            >
              {showImage && (
                <Avatar
                  rounded={!!salesPerson?.profilePictureUri}
                  src={
                    salesPerson?.profilePictureUri ||
                    salesPerson?.profilePicturePlaceholder
                  }
                  alt=""
                />
              )}
              <Info ref={infoRef}>
                <Name>{`${salesPerson?.firstName ?? ''} ${
                  salesPerson?.lastName ?? ''
                }`}</Name>
                {salesPerson?.role && (
                  <Occupation>{salesPerson.role}</Occupation>
                )}
              </Info>
            </PersonWrapper>
          )}

          <ContactsWrapper ref={contactsRef} contactsShown={showContacts}>
            <AnimatePresence>
              {!showContacts && (
                <ContactButton
                  color={bgIsDark ? cardColor : theme.showcaseBlack}
                  onClick={showContactsHandler}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: showContacts ? 0 : 1,
                    y: 0
                  }}
                  exit={{ opacity: 0, y: 60 }}
                  transition={{ duration: 0 }}
                >
                  {localizer.marketplace.contactYourManager}
                </ContactButton>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {showContacts && (
                <Contacts
                  initial={{ opacity: 0, height: 0 }}
                  animate={{
                    opacity: 1,
                    height: 'auto'
                  }}
                  exit={{ opacity: 0 }}
                >
                  {salesPerson?.contacts.map((x, i) => (
                    <Contact
                      key={i}
                      icon={x.icon}
                      value={x.value}
                      index={i}
                      action={x.action}
                      isDarkBg={bgIsDark}
                    />
                  ))}
                </Contacts>
              )}
            </AnimatePresence>
          </ContactsWrapper>
        </Content>
      </Wrapper>
    </>
  );
};

export default withTheme(SalesPersonCard);
