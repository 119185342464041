import React, { useState } from 'react';
import { array, func } from 'prop-types';

import localizer from 'localization/localizer';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import {
  getIconForUnitField,
  shouldHidePriceWhenShared
} from 'helpers/units/VmUnitHelper';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import { useShowUnitGeneralFields } from 'helpers/customHooks';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const CardWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.gray400};
  border-radius: 10px;
  background: white;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const FilterOption = styled(motion.div)`
  display: flex;
  flex-flow: row;
  align-items: center;
  user-select: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: 10px 20px;
  background: ${(props) =>
    props.hovered ? 'rgba(127, 127, 127, 0.1)' : 'white'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  &:first-child {
    padding-top: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    padding-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const FilterLabel = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.gray30};
  cursor: pointer;
`;

const FilterIcon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  width: 25px !important;
  font-size: 16px;
  color: ${(props) => props.theme.primary200};
  cursor: pointer;
`;

/**
 * Card to display all the possible filter options, allowing the user to click on one to select it
 */
const SelectFilterCard = ({ filterOptions, onOptionSelected }) => {
  const [hoveredOption, setHoveredOption] = useState();

  // Project State
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration, project } = ProjectState;

  // Auth State
  const { AuthState } = useAuthState();

  const { showPrice, showSurface, showBedrooms, showBathrooms } =
    useShowUnitGeneralFields(false, null, project.unitList);

  return (
    <CardWrapper data-testid={`selectFilterCard`}>
      <VerticalWrapper>
        {filterOptions.map((option) => {
          const shouldDisablePriceOption =
            shouldHidePriceWhenShared(showcaseConfiguration, AuthState) ||
            !showPrice;

          if (option === 'numberOfBedrooms' && !showBedrooms) return null;
          if (option === 'numberOfBathrooms' && !showBathrooms) return null;
          if (option === 'surface' && !showSurface) return null;
          if (option === 'price' && !showPrice) return null;

          return (
            <FilterOption
              hovered={hoveredOption === option}
              disabled={option === 'price' && shouldDisablePriceOption}
              onHoverStart={() => {
                setHoveredOption(option);
              }}
              onHoverEnd={() => {
                setHoveredOption(null);
              }}
              key={`option${option}`}
              onClick={() => {
                onOptionSelected(option);
              }}
            >
              <FilterIcon icon={getIconForUnitField(option)} size="1x" />
              <FilterLabel />
              {localizer.unitFields[option].toUpperCase()}
            </FilterOption>
          );
        })}
      </VerticalWrapper>
    </CardWrapper>
  );
};

SelectFilterCard.propTypes = {
  /** List with all the fields that can be filtered on */
  filterOptions: array.isRequired,
  /** Callback when the user picks a filter option
   * @callback
   * @param {string} fieldToFilter The field the user wants to filter on
   */
  onOptionSelected: func.isRequired
};

export default SelectFilterCard;
