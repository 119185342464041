import React from 'react';
import { bool, func } from 'prop-types';

import localizer from 'localization/localizer';

// Styling
import { motion } from 'framer-motion';
import styled from 'styled-components';

const MainIndicator = styled(motion.button)`
  position: absolute;
  z-index: 500;
  min-width: 82px;
  width: fit-content;
  height: 50px;
  right: ${(props) => (props.isFilterButtonHovered ? '145px' : '107px')};
  bottom: 80px;
  outline: none;
  border: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: right 0.3s ease-in-out;
`;

const Circle = styled.div`
  width: 20%;
  height: 10px;
  display: flex;
  justify-content: center;
  transition: 0.4s;
  align-items: center;
  z-index: 100;
  border: 5px solid;
  border-radius: 50%;
  margin-right: 5px;
  border-color: ${(props) => props.theme.showcaseBlack};
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const InnerCircle = styled.div`
  width: 10px;
  height: 10px;
  transition: 0.4s;
  background-color: ${(props) => props.theme.showcaseWhite};
  border-radius: 50%;
`;

const StyledText = styled.div`
  width: 80%;
  font-size: 16px;
  color: white;
`;

/**
 * Button to toggle the visibility of the units in the image tour
 */
const ToggleUnitVisibilityButton = ({ unitsAreVisible, onButtonClicked, isFilterButtonHovered }) => {
  return (
    <MainIndicator
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onButtonClicked}
      isFilterButtonHovered={isFilterButtonHovered}
    >
      <Circle>
        <InnerCircle />
      </Circle>
      <StyledText>
        {unitsAreVisible ? localizer.hide : localizer.show}
      </StyledText>
    </MainIndicator>
  );
};

ToggleUnitVisibilityButton.propTypes = {
  /** If the units are currently visible */
  unitsAreVisible: bool.isRequired,
  /** Callback when the button has been clicked to change the visibility
   * @callback
   */
  onButtonClicked: func.isRequired
};

export default ToggleUnitVisibilityButton;
