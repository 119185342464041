import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { func, string } from 'prop-types';

// Components
import { Button } from 'components/ui';

// Helpers
import localizer from 'localization/localizer';
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 40;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
`;

const MoreInfo = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.primary100};
  margin: 0;
  cursor: pointer;
`;

const ShowMoreError = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.errorColor};
  margin: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TryAgainButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.accentAlt500};
  margin: 0;
  margin-bottom: 15px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const SomethingWrongText = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.primary400};
  margin: 0;
  margin-bottom: 10px;
`;

const LoadProjectsError = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.primary100};
  margin: 0;
  margin-bottom: 20px;
`;

const PortfolioError = ({ onTryAgain, errorMessage }) => {
  const navigate = useNavigate();

  const [showMore, setShowMore] = useState(false);

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <SomethingWrongText>{localizer.somethingWentWrong}</SomethingWrongText>
      <LoadProjectsError>{localizer.couldntLoadProjects}</LoadProjectsError>
      <TryAgainButton onClick={onTryAgain}>{localizer.tryAgain}</TryAgainButton>
      {!showMore && (
        <MoreInfo
          onClick={() => {
            setShowMore(true);
          }}
        >
          {localizer.moreInfo}
        </MoreInfo>
      )}

      {showMore && <ShowMoreError>{errorMessage}</ShowMoreError>}

      <ButtonWrapper>
        <Button
          onClickAction={() => {
            setTimeout(() => navigate(`/${window.location.search}`), 100);
          }}
          label={localizer.goToConnectPage}
          dataTestId="connectButton"
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

PortfolioError.propTypes = {
  onTryAgain: func,
  errorMessage: string
};

PortfolioError.defaultProps = {
  onTryAgain: () => {},
  errorMessage: ''
};

export default PortfolioError;
