import React, { useCallback, useState, useEffect } from 'react';
import { shape, bool, array, string, oneOf, number } from 'prop-types';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Components
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnitDetailMobileContent from './UnitDetailMobileContent';
import UnitStateBadge from 'components/unit/UnitStateBadge';
import ContactUs from 'components/share/ContactUs/ContactUs';
import FavoriteUnitButton from 'components/share/FavoriteUnitButton';

// Helpers
import localizer from 'localization/localizer';
import {
  capitalize,
  getFieldIcon,
  displayLocalizedValue,
  getUnitPrice
} from 'helpers';
import { downloadBinaryWithProvidedFileName } from '@prompto-helpers';
import currencySymbolMap from 'currency-symbol-map';
import { unitStatesNotArchived } from 'helpers/units/VmUnitHelper';
import { useProjectState } from 'stores/ProjectStore';
import {
  useShowUnitGeneralFields,
  useGetUnitCustomFields
} from 'helpers/customHooks';

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: ${(props) => props.theme.grayWhiteOff};
  overflow: auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: hidden;
`;

const PageTitle = styled.span`
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MobileLayoutContent = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const NoContentNotification = styled.div`
  margin: auto;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.primary100};
`;

const UnitTypeAndPrice = styled.div`
  position: relative;
  padding: 20px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.showcaseWhite};
  flex-shrink: 0;
`;

const StyledUnitStateBadge = styled(UnitStateBadge)`
  position: absolute;
  z-index: 30;
  top: -15px;
  right: 15px;
  border-radius: 20px;
  padding: 0 6px;
  width: min-content;
  min-width: 6rem;
  font-size: 0.75rem;
`;

const UnitButtons = styled.div`
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray200};
  background-color: ${(props) => props.theme.showcaseWhite};
  flex-shrink: 0;
`;

const DownloadPlansWrapper = styled.div`
  padding-top: 10px;
`;

const DownloadPlansButton = styled.button`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.defaultBrandSecondaryColor};
  background-color: ${(props) => props.theme.showcaseWhite};
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const UnitType = styled.div`
  min-width: 50%;
  font-size: 14px;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  word-break: break-word;
  padding-right: 10px;
`;

const StyledUnitTypeIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 10px;
  color: ${(props) => props.theme.primary100};
`;

const IconWrapper = styled.span`
  width: 20px;
  margin-right: 5px;
  display: inline-block;
`;

const CustomFieldWrapper = styled.div`
  padding: 10px 15px;
  background-color: ${({ index, theme }) =>
    index % 2 ? theme.grayWhiteOff : theme.showcaseWhite};
  word-break: break-word;
`;

const StyledCustomFieldIcon = styled(StyledUnitTypeIcon)`
  font-size: 16px;
  margin: 0;
`;

const CustomFieldTitle = styled.span`
  margin: 0 10px 0 0;
  color: ${(props) => props.theme.primary100};
  font-size: 0.875rem;
`;

const CustomFieldValue = styled(CustomFieldTitle)`
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
`;

const CustomFieldValueParagraph = styled.p`
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
  text-align: justify;
  font-size: 0.875rem;
`;

const UnitPrice = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 10px;
  color: ${(props) => props.theme.primary100};
`;

const Price = styled.div`
  font-weight: 700;
  padding-left: 5px;
  color: inherit;
  white-space: nowrap;
`;

const UnitFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.gray200};
  background-color: ${(props) => props.theme.showcaseWhite};
  padding-bottom: 10px;
  flex-shrink: 0;
`;

const UnitDetailMobilePage = ({
  unit,
  contentItemList,
  unitHasContent,
  currencyCode,
  showUnitPrice,
  showUnitState,
  showUnitDescription,
  externalExperienceUrl,
  fallbackThumbnail,
  album360Url,
  sceneContentId,
  hasSceneStreaming,
  floorPlanFile
}) => {
  const { ProjectState } = useProjectState();

  const { showcaseConfiguration } = ProjectState;

  const [unitFields, setUnitFields] = useState([]);

  const { showPrice, showSurface, showBedrooms, showBathrooms } =
    useShowUnitGeneralFields(false, null, [unit]);

  const currencySymbol = currencySymbolMap(currencyCode);
  const unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;

  const { customFields } = useGetUnitCustomFields(unit, showUnitDescription);

  useEffect(() => {
    if (customFields) {
      setUnitFields(customFields);
    }
  }, [customFields]);

  const renderUnitFields = useCallback(
    (showcaseConfiguration) => {
      const fields = [];

      if (showSurface && unit?.unitMetadata?.surface !== undefined) {
        fields.push({
          icon: getFieldIcon(showcaseConfiguration, 'surface'),
          title: capitalize(localizer.surface),
          value: `${unit.unitMetadata.surface} m²`
        });
      }
      if (showBedrooms && unit?.unitMetadata?.numberOfBedrooms !== undefined) {
        fields.push({
          icon: getFieldIcon(showcaseConfiguration, 'numberOfBedrooms'),
          title: capitalize(localizer.bedrooms),
          value: unit.unitMetadata.numberOfBedrooms
        });
      }
      if (
        showBathrooms &&
        unit?.unitMetadata?.numberOfBathrooms !== undefined
      ) {
        fields.push({
          icon: getFieldIcon(showcaseConfiguration, 'numberOfBathrooms'),
          title: capitalize(localizer.bathrooms),
          value: unit.unitMetadata.numberOfBathrooms
        });
      }

      fields.push(...unitFields);

      if (fields?.length === 0) return null;

      return fields.map((field, idx) => (
        <CustomFieldWrapper index={idx} key={idx}>
          {field.icon && (
            <IconWrapper>
              <StyledCustomFieldIcon icon={['far', field.icon]} size="1x" />
            </IconWrapper>
          )}
          {field.title && (
            <CustomFieldTitle>{capitalize(field.title)}:</CustomFieldTitle>
          )}
          {field.showAsParagraph ? (
            <CustomFieldValueParagraph>{field.value}</CustomFieldValueParagraph>
          ) : (
            <CustomFieldValue>{field.value}</CustomFieldValue>
          )}
        </CustomFieldWrapper>
      ));
    },
    [unit, unitFields, showSurface, showBedrooms, showBathrooms]
  );

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MobileLayoutHeader
        rightAction={
          !showcaseConfiguration?.hideFavouriteOptions && (
            <FavoriteUnitButton
              unit={unit}
              buttonSize={24}
              wrapperStyles={favoriteIconStyles}
              noBackground
            />
          )
        }
      >
        <TitleWrapper>
          <PageTitle>{unit?.title}</PageTitle>
        </TitleWrapper>
      </MobileLayoutHeader>

      <MobileLayoutContent>
        <UnitTypeAndPrice>
          {showUnitState && (
            <StyledUnitStateBadge
              unitState={unit?.state}
              unitFlow={unit?.unitFlow}
            />
          )}
          {unit?.unitType && (
            <UnitType>
              {unit?.unitType?.icon && (
                <StyledUnitTypeIcon
                  icon={['far', unit.unitType.icon]}
                  size="1x"
                />
              )}
              {displayLocalizedValue(unit?.unitType?.name?.textMap)}
            </UnitType>
          )}
          {showPrice && (
            <UnitPrice>
              <StyledPriceIcon
                icon={getFieldIcon(showcaseConfiguration, 'price', 'far')}
                size="1x"
              />
              <Price>{showUnitPrice ? unitPrice : localizer.onRequest}</Price>
            </UnitPrice>
          )}
        </UnitTypeAndPrice>

        <UnitFieldsWrapper>
          {renderUnitFields(showcaseConfiguration)}
        </UnitFieldsWrapper>

        {(showcaseConfiguration?.showUnitContactUsButton ||
          (showcaseConfiguration?.showUnitDownloadPlanButton &&
            floorPlanFile)) && (
          <UnitButtons>
            {showcaseConfiguration?.showUnitContactUsButton && (
              <ContactUs unit={unit} location="Unit details page (mobile)" />
            )}
            {showcaseConfiguration?.showUnitDownloadPlanButton &&
              floorPlanFile && (
                <DownloadPlansWrapper>
                  <DownloadPlansButton
                    onClick={() => {
                      downloadBinaryWithProvidedFileName(
                        floorPlanFile.contentUri,
                        floorPlanFile.title.textMap.en
                      );
                    }}
                  >
                    {localizer.downloadPlan}
                  </DownloadPlansButton>
                </DownloadPlansWrapper>
              )}
          </UnitButtons>
        )}

        {unitHasContent ||
        album360Url ||
        hasSceneStreaming ||
        externalExperienceUrl ? (
          <UnitDetailMobileContent
            content={contentItemList}
            externalExperienceUrl={externalExperienceUrl}
            fallbackThumbnail={fallbackThumbnail}
            album360Url={album360Url}
            sceneContentId={sceneContentId}
          />
        ) : (
          <NoContentNotification>
            {localizer.unitHasNoAttachments}
          </NoContentNotification>
        )}
      </MobileLayoutContent>
    </Wrapper>
  );
};

const favoriteIconStyles = `
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

UnitDetailMobilePage.propTypes = {
  unit: shape({
    title: string.isRequired,
    state: oneOf(unitStatesNotArchived).isRequired,
    unitMetadata: shape({
      surface: number,
      numberOfBedrooms: number,
      numberOfBathrooms: number
    }),
    unitPrice: shape({
      price: number,
      currencyCode: string
    })
  }),
  contentItemList: array,
  unitHasContent: bool,
  currencyCode: string,
  showUnitPrice: bool,
  showUnitState: bool,
  showUnitDescription: bool,
  externalExperienceUrl: string,
  fallbackThumbnail: string,
  album360Url: string,
  sceneContentId: string,
  hasSceneStreaming: bool
};

UnitDetailMobilePage.defaultProps = {
  contentItemList: [],
  unitHasContent: false,
  showUnitPrice: false,
  showUnitState: false,
  showUnitDescription: false,
  hasSceneStreaming: false
};

export default UnitDetailMobilePage;
