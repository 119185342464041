import localizer from 'localization/localizer';

const UNSORTED_FOLDER = 'unsorted-items-folder';

// order content collection based on the folders structure
export const orderContentCollection = (
  collection,
  folderStructure,
  showcaseConfiguration,
  dispatch,
  createRef
) => {
  const showFolders = showcaseConfiguration?.enableResourcesSections !== false;
  const list = collection?.vmContentItemList;

  if (!folderStructure || folderStructure.length === 0) {
    dispatch({
      type: 'setOrderedCollection',
      payload: {
        orderedContentCollection: {
          ...collection,
          vmContentItemList: list
        }
      }
    });
    return;
  }

  // reorder initial folder structure to make sure
  // that unsorted folder is in the end of the list
  let reorderedFolderStructure;
  const unsortedFolderIndex = folderStructure.findIndex(
    (f) => f.uuid === UNSORTED_FOLDER
  );

  // no need to reorder if the unsorted folder
  // does not exist or it is already in the last position
  if (
    unsortedFolderIndex > -1 &&
    unsortedFolderIndex !== folderStructure.length - 1
  ) {
    const unsortedFolder = folderStructure.find(
      (f) => f.uuid === UNSORTED_FOLDER
    );
    const otherFolders = folderStructure.filter(
      (f) => f.uuid !== UNSORTED_FOLDER
    );
    reorderedFolderStructure = [...otherFolders, unsortedFolder];
  } else {
    reorderedFolderStructure = folderStructure;
  }

  let startFolderItemIndex = 0;

  const ordered = reorderedFolderStructure.reduce(
    (acc, folder) => {
      const updAcc = { ...acc };

      const folderFiles = folder.projectFiles ?? [];
      const title =
        folder.name === UNSORTED_FOLDER
          ? localizer.otherResources
          : folder.name;
      if (folderFiles.length > 0) {
        let addedFilesNumber = 0;

        const handleFolderFiles = (files) => {
          files.forEach((file) => {
            if (file.type === 'CONTENT_ITEM') {
              const fileInCollection = list.find(
                (x) => x.objectId === file.contentItemId
              );
              if (
                fileInCollection &&
                fileInCollection.contentItemState === 'published'
              ) {
                updAcc.contentItemlist.push(fileInCollection);
                addedFilesNumber += 1;
              }
            } else {
              handleFolderFiles(file.projectFiles ?? []);
            }
          });
        };

        handleFolderFiles(folderFiles);

        if (
          addedFilesNumber > 0 &&
          (folder.name !== UNSORTED_FOLDER ||
            (folder.name === UNSORTED_FOLDER &&
              folder.uuid === UNSORTED_FOLDER))
        ) {
          updAcc.folders.push({
            title,
            uuid: folder.uuid,
            itemsRange: [
              startFolderItemIndex,
              startFolderItemIndex + addedFilesNumber
            ]
          });
          startFolderItemIndex += addedFilesNumber;
          if (showFolders) {
            updAcc.foldersRefs[folder.uuid] = createRef();
          }
        }
      }

      return updAcc;
    },
    {
      contentItemlist: [],
      folders: [],
      foldersRefs: {}
    }
  );

  dispatch({
    type: 'setOrderedCollection',
    payload: {
      orderedContentCollection: {
        ...collection,
        vmContentItemList: ordered.contentItemlist
      },
      folders: ordered.folders,
      showFolders,
      foldersRefs: ordered.foldersRefs
    }
  });
};
