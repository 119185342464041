import * as React from 'react';

function SvgCameraMarkerIcon(props) {
  return (
    <svg width={32} height={32} {...props}>
      <path d="M21 8l-5-8-5 8h10z" fill="#4D4D4D" />
      <circle cx={16} cy={16} r={8} fill="#4D4D4D" stroke="#fff" />
      <circle cx={16} cy={16} r={4} fill="#fff" />
    </svg>
  );
}

export default SvgCameraMarkerIcon;
