import React, { useState } from 'react';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';

// Helpers
// https://www.npmjs.com/package/pannellum-react
import { Pannellum } from 'pannellum-react';
import { isMobile } from 'react-device-detect';
import localizer from 'localization/localizer';

// Styling
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.showcaseWhite};
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 3;
  background-color: ${(props) => props.theme.showcaseWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div`
  position: absolute;
  z-index: 1;
  width: 360px;
  bottom: 0;
  right: 0;
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.875rem;
  line-height: 1.1;
  letter-spacing: 1px;
  text-align: center;
`;

const LoadingText = styled.p`
  color: ${(props) => props.showcaseBlack};
  letter-spacing: 1px;
  text-align: center;
  max-width: 70%;
  margin: 1rem;
  font-size: 1rem;
`;

const StyledLoader = styled(ShowcaseLoader)`
  flex-grow: 0;
  margin: 0 0 1rem;
  height: auto;
`;

const ThreeSixtyImageContent = ({ contentUri, onLoad }) => {
  const [loading, setLoading] = useState(true);

  return (
    <Wrapper>
      {loading && (
        <LoadingOverlay>
          <StyledLoader />
          <LoadingText>{localizer.threeSixtyImagePreparing}</LoadingText>
        </LoadingOverlay>
      )}
      <Instructions>
        {isMobile
          ? localizer.threeSixtyImageNavigateMobile
          : localizer.threeSixtyImageNavigateDesktop}
      </Instructions>
      <Pannellum
        width="100%"
        height="100%"
        image={contentUri}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
        onLoad={() => {
          setLoading(false);
          onLoad();
        }}
        showControls={false}
      />
    </Wrapper>
  );
};

export default withTheme(ThreeSixtyImageContent);
