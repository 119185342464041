import React, { useState, useEffect } from 'react';
import { array, func } from 'prop-types';

// Components
import AddFilterChip from './AddFilterChip';
import FilterChip from './FilterChip';

// Helpers
import {
  getMinAndMaxValuesForField,
  unitStatesNotArchived
} from 'helpers/units/VmUnitHelper';

// Styling
import styled from 'styled-components';

const ChipWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

/**
 * Container to hold all the filter chips, manages which filter has been picked already
 */
const FilterChipContainer = ({
  filterOptions,
  units,
  initialFilterValues,
  onFiltersUpdated
}) => {
  const [availableFilterOptions, setAvailableFilterOptions] =
    useState(filterOptions);

  const [filterChips, setFilterChips] = useState();

  useEffect(() => {
    if (initialFilterValues && initialFilterValues.length > 0 && !filterChips) {
      const newFilterOptionsList = availableFilterOptions.filter(
        (x) => !initialFilterValues.map((x) => x.filterOption).includes(x)
      );
      setAvailableFilterOptions(newFilterOptionsList);

      setFilterChips(initialFilterValues);
    } else if (!filterChips) {
      setFilterChips([]);
    }
  }, [initialFilterValues, availableFilterOptions, filterChips]);

  const onConfirmChipCreate = (option) => {
    const newFilterOptionsList = availableFilterOptions.filter(
      (x) => x !== option
    );
    setAvailableFilterOptions(newFilterOptionsList);

    const mapKeyToValues = {
      state: unitStatesNotArchived
    };

    if (!['state'].includes(option)) {
      const limits = getMinAndMaxValuesForField(units, option);
      setFilterChips((current) => {
        const isDuplication =
          current.findIndex((opt) => opt.filterOption === option) > -1;

        return isDuplication
          ? current
          : [
              ...current,
              {
                filterOption: option,
                values: limits,
                limits: limits,
                new: true
              }
            ];
      });
    } else {
      setFilterChips((current) => {
        const isDuplication =
          current.findIndex((opt) => opt.filterOption === option) > -1;

        return isDuplication
          ? current
          : [
              ...current,
              {
                filterOption: option,
                values: mapKeyToValues[option],
                new: true
              }
            ];
      });
    }
  };

  const onUpdateValues = (option, values) => {
    const chip = filterChips.find((x) => x.filterOption === option);
    chip.values = values;
    chip.new = false;

    setFilterChips((current) => {
      return current.map((x) => {
        if (x.filterOption !== option) {
          return x;
        } else {
          return chip;
        }
      });
    });
  };

  const onDeleteChip = (chip) => {
    let indexOfChip = filterOptions.indexOf(chip.filterOption);

    const newFilterOptionsList = availableFilterOptions;
    newFilterOptionsList.splice(indexOfChip, 0, chip.filterOption);

    setAvailableFilterOptions(newFilterOptionsList);

    setFilterChips((current) => [
      ...current.filter((x) => x.filterOption !== chip.filterOption)
    ]);
  };

  useEffect(() => {
    onFiltersUpdated(filterChips || []);
  }, [filterChips, onFiltersUpdated]);

  return (
    <ChipWrapper>
      {filterChips?.map((chip, index) => {
        return (
          <FilterChip
            key={`chip${chip.filterOption}`}
            chip={chip}
            units={units}
            onUpdateValues={onUpdateValues}
            onDelete={onDeleteChip}
          />
        );
      })}
      {availableFilterOptions.length > 0 && (
        <AddFilterChip
          active={filterOptions.length > availableFilterOptions.length}
          filterOptions={availableFilterOptions}
          onConfirmCreate={onConfirmChipCreate}
        />
      )}
    </ChipWrapper>
  );
};

FilterChipContainer.propTypes = {
  /** List with all the fields that can be filtered on */
  filterOptions: array.isRequired,
  /** The units we want to filter on, only used to get min and max values*/
  units: array.isRequired,
  /** The initial filter values if there are any, this is passed when we had set filters before and navigate back */
  initialFilterValues: array,
  /** Callback to update parent when anything changed in the filter values
   * @callback
   * @param {array} filterChips The filter values
   */
  onFiltersUpdated: func.isRequired
};

export default FilterChipContainer;
