import React, { useState } from 'react';
import { array, string } from 'prop-types';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Helpers
import localizer from 'localization/localizer';

// Components
import Album360Preview from 'components/pages/resources/contentItems/types/album360/Album360Preview';
import Album360Content from 'components/pages/resources/contentItems/types/album360/Album360Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GalleryWrapper = styled(motion.div)`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const GalleryItemWrapper = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
  margin: 5px;
  overflow: hidden;
`;

const ImageContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ImageContent = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  filter: ${(props) => (props.blur ? 'blur(15px)' : 'none')};
  overflow: hidden;
`;

const CardOverlayWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.2;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.whitePure};
  z-index: 55;
  margin-bottom: 20px;
`;

const ArrowBackIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.whitePure};
  margin-right: 5px;
`;

const Text = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.whitePure};
  position: absolute;
  margin: 0;
  margin-top: 40px;
`;

const Album360ContentWrapper = styled.div`
  position: fixed;
  z-index: 56;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.showcaseBlack};
`;

const BackButton = styled.button`
  padding: 11px;
  position: absolute;
  z-index: 57;
  bottom: 0;
  left: 0;
  border: none;
  background: ${(props) => props.theme.fadeBlack};
  color: ${(props) => props.theme.showcaseWhite};
  text-transform: uppercase;
  letter-spacing: 1px;
  width: min-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const ThreeD = ({ items, fallbackThumbnail }) => {
  const [activeAlbum360, setActiveAlbum360] = useState(null);

  if (items.length === 0) return null;

  return (
    <GalleryWrapper>
      {items.map((data, idx) => {
        if (['externalExperience', 'url'].includes(data.contentItemType)) {
          return (
            <GalleryItemWrapper
              key={idx}
              index={idx}
              onClick={() => window.open(data.contentUri)}
            >
              <ImageContentWrapper>
                <ImageContent
                  src={fallbackThumbnail}
                  blur
                  alt="External experience"
                />
              </ImageContentWrapper>
              <CardOverlayWrapper>
                <Overlay />
                <Icon icon={['fal', 'sparkles']} size={'2x'} />
                <Text>{localizer.www}</Text>
              </CardOverlayWrapper>
            </GalleryItemWrapper>
          );
        }
        return (
          <GalleryItemWrapper
            key={idx}
            index={idx}
            onClick={() => setActiveAlbum360(data)}
          >
            <Album360Preview data={data} key={data.objectId} />
          </GalleryItemWrapper>
        );
      })}

      {activeAlbum360 && (
        <Album360ContentWrapper>
          <BackButton onClick={() => setActiveAlbum360(null)}>
            <ArrowBackIcon icon={['fal', 'arrow-left']} size={'1x'} />
            {localizer.backToUnit}
          </BackButton>
          <Album360Content data={activeAlbum360} />
        </Album360ContentWrapper>
      )}
    </GalleryWrapper>
  );
};

ThreeD.propTypes = {
  items: array,
  fallbackThumbnail: string
};

ThreeD.defaultProps = {
  items: []
};

export default ThreeD;
