import React, { Fragment, useState, useEffect } from 'react';

// Components
import FallbackSharing from './FallbackSharing';
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';
import {
  capitalize,
  getCurrentEnvironment,
  fetchSettingsFromURL
} from 'helpers';

// Styling
import styled from 'styled-components';

export const QrWrapper = styled.div`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 4px;
`;

export const Motto = styled.p`
  font-size: 1.5rem;
  margin: 0;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.primary100};
  margin: 0 0 30px;
  text-align: center;
`;

export const ShareButton = styled.button`
  flex-shrink: 0;
  width: 200px;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: ${(props) => props.theme.grayWhiteOff};
  color: ${(props) => props.theme.primary400};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShareIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: ${(props) => props.theme.primary400};
  margin-right: 9px;
`;

const NativeSharing = ({ path, onShare, ...props }) => {
  const params = fetchSettingsFromURL();
  const [env] = useState(getCurrentEnvironment());
  const [baseLink] = useState(`https://${env.applicationUrl}`);
  const [envParam] = useState(params.env ? `?env=${params.env}` : '');
  const [canShare, setCanShare] = useState(false);

  const url = `${baseLink}/${path}${window.location.search}`;

  useEffect(() => {
    setCanShare(!!navigator.share);
  }, []);

  return (
    <Fragment>
      <QrWrapper>
        <QRCode value={url} renderAs="svg" size={180} fgColor={'#11082d'} />
      </QrWrapper>
      <Motto>{localizer.takeItWithYou}</Motto>
      {canShare ? (
        <Fragment>
          <Text>{localizer.scanTheCode}</Text>
          <ShareButton onClick={onShare}>
            <ShareIcon icon={['fal', 'share']} size="1x" />
            {capitalize(localizer.share)}
          </ShareButton>
        </Fragment>
      ) : (
        <FallbackSharing
          path={path}
          url={url}
          baseLink={baseLink}
          envParam={envParam}
          {...props}
        />
      )}
    </Fragment>
  );
};

export default NativeSharing;
