import React from 'react';
import styled from 'styled-components';

// Components
import { RichTextRenderer } from '@prompto-ui';

// Helpers
import { motion } from 'framer-motion';
import localizer from 'localization/localizer';
import { capitalize, buildAddress, displayLocalizedValue } from 'helpers';

// Styling
const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 600;
  top: 70px;
  left: 0;
  right: 0;
  height: calc(100% + 60px);
  width: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  overflow: auto;
  background-color: ${(props) => props.theme.showcaseWhite};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  color: ${(props) => props.theme.primary100};
`;

const SectionText = styled.p`
  margin: 0;
  line-height: 1.25;
  color: ${(props) => props.theme.showcaseBlack};
  word-break: break-word;
  text-align: justify;
`;

const ProjectName = styled(SectionText)`
  font-size: 1.25rem;
  line-height: 1.5;
  word-break: break-all;
`;

const ProjectDescription = ({ project }) => {
  const projectTitle = displayLocalizedValue({
    en: project?.title,
    ...project?.localizedTitle?.textMap
  });
  const projectDescription = displayLocalizedValue({
    en: project?.description,
    ...project?.localizedDescription?.textMap
  });

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Section>
        <SectionTitle>{capitalize(localizer.project)}</SectionTitle>
        <ProjectName>{projectTitle}</ProjectName>
      </Section>
      <Section>
        <SectionTitle>{capitalize(localizer.address)}</SectionTitle>
        <SectionText>{buildAddress(project?.address)}</SectionText>
      </Section>
      <Section>
        <SectionTitle>{capitalize(localizer.description)}</SectionTitle>
        <SectionText>
          <RichTextRenderer richText={projectDescription} />
        </SectionText>
      </Section>
    </Wrapper>
  );
};

export default ProjectDescription;
