import styled from 'styled-components';
import { motion } from 'framer-motion';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  background-color: white;
`;

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding: 40px 15px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const ArrowBackIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: ${(props) => props.theme.primary300};
`;

export const QrWrapper = styled.div`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 4px;
`;

export const Motto = styled.p`
  font-size: 1.5rem;
  margin: 0;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.primary100};
  margin: 0 0 30px;
  text-align: center;
`;

export const ShareButton = styled.button`
  flex-shrink: 0;
  width: 200px;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: ${(props) => props.theme.grayWhiteOff};
  color: ${(props) => props.theme.primary400};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShareIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.theme.primary400};
  margin-right: 9px;
`;

export const Toast = styled(motion.div)`
  position: fixed;
  bottom: 32px;
  transform: translate(-50%, 0);
  background-color: ${({ theme, error }) =>
    error ? theme.warning : theme.successColor};
  border-radius: 17px;
  width: fit-content;
  height: 34px;
  padding: 0 25px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.whitePure};
`;

export const ToastIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export const LinkWrapper = styled.div`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.88px;
  padding: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${(props) => props.theme.gray100};
  user-select: none;
  cursor: pointer;
`;

export const CopyWrapper = styled(motion.div)`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.88px;
  padding: 18px 0px;
  width: 100%;
  justify-content: center;
  display: flex;
  background: ${(props) => props.theme.gray100};
  user-select: none;
  cursor: pointer;
`;

export const CopyMessage = styled.p`
  color: ${(props) => props.theme.successColor};
  font-weight: 500;
  font-size: 12px;
`;
