import React, { useEffect, useState, useContext, useCallback } from 'react';

// Components
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Polygon,
  OverlayView
} from '@react-google-maps/api';

import SvgHideIcon from 'resources/icons/HideIcon';
import SvgSmallSizeIcon from 'resources/icons/SmallSizeIcon';
import SvgMediumSizeIcon from 'resources/icons/MediumSizeIcon';
import LocationMarker from 'resources/svgs/locationMarker.svg';
import SvgCameraMarkerIcon from 'resources/icons/CameraMarkerIcon';
import StartLocationMarker from 'resources/svgs/startLocationMarker.svg';
import HighlightedLocationMarker from 'resources/svgs/highlightedLocationMarker.svg';
import HighlightedStartLocationMarker from 'resources/svgs/highlightedStartLocationMarker.svg';
import { motion, AnimatePresence } from 'framer-motion';

// Helpers
import uniqid from 'uniqid';
import mapStyling from './mapStyling';
import { getCurrentEnvironment } from 'helpers';
import { useMapState } from 'stores/MapStore';
import { useUiState } from 'stores/UiStore';
import { useTourState } from 'stores/TourStore';

// Styling
import styled from 'styled-components';
import { showcaseContext } from '../../../../../main';

const smallSize = { width: 200, height: 200 };
const mediumSize = { width: 475, height: 340 };
const smallIconButtonSize = { width: 12, height: 9 };
const defaultZoomLevel = 16;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1100;
  display: flex;
  bottom: 80px;
  left: 0;
`;

const MapWrapper = styled(motion.div)`
  position: absolute;
  border: 1px solid white;
  width: ${(props) => props.size.width}px;
  height: ${(props) => props.size.height}px;
  left: ${(props) => (props.size.width === '100%' ? '0px' : '15px')};
  bottom: ${(props) => (props.size.height === '100%' ? '0px' : '15px')};
  display: flex;
`;

const SizeOptionsWrapper = styled(motion.div)`
  display: flex;
  position: absolute;
  bottom: ${(props) => (props.size.width === '100%' ? '20px' : '0px')};
  left: ${(props) => (props.size.width === '100%' ? '20px' : '0px')};
  gap: 1px;
  background: ${(props) => props.theme.whitePure};
`;

const SizeButton = styled(motion.div)`
  width: ${(props) => (props.size.width === '100%' ? '45px' : '30px')};
  height: ${(props) => (props.size.height === '100%' ? '45px' : '30px')};
  background-color: ${(props) =>
    props.active ? props.theme.gray100 : props.theme.whitePure};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HideButton = styled(SizeButton)``;
const SmallSizeButton = styled(SizeButton)``;
const MediumSizeButton = styled(SizeButton)``;

const CameraIndicator = styled.div`
  transform: translate(-16px, -25px) rotate(${(props) => props.rotation}deg);
`;

const MapView = ({
  mapView,
  tourId,
  onNavigateToMediaView,
  currentMediaView,
  startMediaView,
  initialUiState
}) => {
  const { accentAlt400, gray30 } = useContext(showcaseContext);

  const { MapState, MapStateDispatch } = useMapState();
  const { mapMode } = MapState;

  const { UiState, UiStateDispatch } = useUiState();
  const { showMinimap, minimapSize } = UiState;

  const { TourState, TourStateDispatch } = useTourState();
  const { highlightedSpot } = TourState;

  const [loaderId] = useState(uniqid('loader-'));
  const [mapId] = useState(uniqid('map-'));
  const [mapInstance, setMapInstance] = useState();
  const googleMapsLibraries = ['drawing', 'visualization', 'places'];

  const [latLng, setLatLng] = useState();
  const [mapZoom, setMapZoom] = useState(defaultZoomLevel);

  const [selectedSpot, setSelectedSpot] = useState();

  const [cameraTransform, setCameraTransform] = useState({
    position: { lat: 0, lng: 0 },
    rotation: 0
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: getCurrentEnvironment().googleMapsApiKey,
    id: loaderId,
    version: 'weekly',
    preventGoogleFontsLoading: true,
    googleMapsLibraries
  });

  useEffect(() => {
    if (isLoaded) {
      UiStateDispatch({
        type: 'update',
        payload: { hasMinimap: true }
      });
    }
  }, [isLoaded, UiStateDispatch]);

  useEffect(() => {
    if (
      currentMediaView &&
      mapView?.navigationCollection?.vmNavigationItemList
    ) {
      mapView.navigationCollection.vmNavigationItemList.forEach((item) => {
        if (item.value === currentMediaView.objectId) {
          const position = {
            lat: parseFloat(item.yCoordinate),
            lng: parseFloat(item.xCoordinate)
          };

          setCameraTransform({
            position,
            rotation: parseInt(item.rotationAngle || '0')
          });

          setSelectedSpot(item);
        }
      });
    }
  }, [mapView, currentMediaView]);

  useEffect(() => {
    if (isLoaded && window.google) {
      if (mapView.offsetLocation) {
        const latLong = {
          lat: parseFloat(mapView.offsetLocation.yCoordinate),
          lng: parseFloat(mapView.offsetLocation.xCoordinate)
        };
        setLatLng(latLong);
      } else if (mapView.xCoordinate && mapView.yCoordinate) {
        const latLong = {
          lat: parseFloat(mapView.yCoordinate),
          lng: parseFloat(mapView.xCoordinate)
        };
        setLatLng(latLong);
      }

      if (mapView.zoomLevel) {
        setMapZoom(Number(mapView.zoomLevel));
      }
    }
  }, [isLoaded, mapView]);

  const onMapTypeIdChanged = () => {
    if (mapInstance) {
      const newType = mapInstance?.getMapTypeId() || 'roadmap';
      if (mapMode !== newType) {
        MapStateDispatch({
          type: 'update',
          payload: { mapMode: newType }
        });
      }
    }
  };

  const onMapLoad = (instance) => {
    setMapInstance(instance);
  };

  const onHideMap = useCallback(() => {
    UiStateDispatch({
      type: 'update',
      payload: { showMinimap: false }
    });
  }, [UiStateDispatch]);

  // apply initial UI state
  useEffect(() => {
    if (!initialUiState) return;
    switch (initialUiState) {
      case 'hidden':
        onHideMap();
        break;
      case 'large':
        UiStateDispatch({
          type: 'update',
          payload: { minimapSize: mediumSize }
        });
        break;
      default:
    }
  }, [initialUiState, onHideMap, UiStateDispatch]);

  if (!isLoaded || loadError || !latLng) {
    return null;
  }

  const mapOptions = {
    fullscreenControl: false,
    clickableIcons: false,
    disableDefaultUI: true,
    styles: mapStyling,
    zoomControl: minimapSize !== smallSize ? true : false,
    streetViewControl: minimapSize !== smallSize ? true : false,
    mapTypeControl: minimapSize !== smallSize ? true : false,
    mapTypeId: mapMode || 'roadmap',
    controlSize: 25,
    draggableCursor: minimapSize === smallSize ? 'default' : ''
  };

  if (minimapSize === smallSize) {
    mapOptions.gestureHandling = 'none';
  } else {
    mapOptions.gestureHandling = 'default';
  }

  const polygonOptions = {
    fillColor: '#96C13A',
    fillOpacity: 0.6,
    strokeColor: '#96C13A',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  };

  const setHighlightedSpot = (value) => {
    TourStateDispatch({
      type: 'update',
      payload: {
        highlightedSpot: value
      }
    });
  };

  // Create markers
  const markers = mapView?.navigationCollection?.vmNavigationItemList.map(
    (spot, index) => {
      if (spot.navigationItemType === 'navigationSpot') {
        const position = {
          lat: parseFloat(spot.yCoordinate),
          lng: parseFloat(spot.xCoordinate)
        };

        const isStartSpot = spot.value === startMediaView.objectId;
        let marker = isStartSpot ? StartLocationMarker : LocationMarker;
        if (spot?.value === highlightedSpot?.value) {
          marker = isStartSpot
            ? HighlightedStartLocationMarker
            : HighlightedLocationMarker;
        }

        if (spot !== selectedSpot) {
          return (
            <Marker
              key={index}
              position={position}
              icon={marker}
              zIndex={spot?.value === highlightedSpot?.value ? 2 : 1}
              onMouseOver={() => setHighlightedSpot(spot)}
              onMouseOut={() => setHighlightedSpot(null)}
              onClick={() => {
                setCameraTransform({
                  position,
                  rotation: parseInt(spot.rotationAngle)
                });
                setSelectedSpot(spot);
                onNavigateToMediaView(spot);
              }}
            />
          );
        }

        return null;
      }

      if (spot.navigationItemType === 'polygonSpot') {
        const paths = JSON.parse(spot.value);
        return <Polygon key={index} paths={paths} options={polygonOptions} />;
      }

      return null;
    }
  );

  // determine button size
  const buttonHeight = smallIconButtonSize.height;
  const buttonWidth = smallIconButtonSize.width;

  return (
    <Wrapper>
      <AnimatePresence>
        {showMinimap && (
          <MapWrapper
            key="map"
            size={minimapSize}
            initial={{ width: 0, height: 0, opacity: 0 }}
            animate={{
              width: minimapSize.width,
              height: minimapSize.height,
              opacity: 1
            }}
            exit={{ width: 0, height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <GoogleMap
              id={mapId}
              center={latLng}
              onLoad={onMapLoad}
              onMapTypeIdChanged={onMapTypeIdChanged}
              mapContainerStyle={{
                height: '100%',
                width: '100%'
              }}
              options={mapOptions}
              zoom={mapZoom}
            >
              {markers}
              <OverlayView
                position={cameraTransform.position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <CameraIndicator rotation={cameraTransform.rotation}>
                  <SvgCameraMarkerIcon />
                </CameraIndicator>
              </OverlayView>
            </GoogleMap>
            <SizeOptionsWrapper
              size={minimapSize}
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: 'auto', opacity: 1 }}
              exit={{ width: 0, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <HideButton size={minimapSize} onClick={onHideMap}>
                <SvgHideIcon
                  fill={gray30}
                  width={buttonWidth + 4}
                  height={buttonHeight + 4}
                />
              </HideButton>
              <SmallSizeButton
                size={minimapSize}
                onClick={() => {
                  mapInstance.zoom = mapZoom;
                  mapInstance.panTo(latLng);
                  UiStateDispatch({
                    type: 'update',
                    payload: { minimapSize: smallSize }
                  });
                }}
                active={minimapSize === smallSize}
              >
                <SvgSmallSizeIcon
                  fill={minimapSize === smallSize ? accentAlt400 : gray30}
                  width={buttonWidth}
                  height={buttonHeight}
                />
              </SmallSizeButton>
              <MediumSizeButton
                size={minimapSize}
                onClick={() => {
                  UiStateDispatch({
                    type: 'update',
                    payload: { minimapSize: mediumSize }
                  });
                }}
                active={minimapSize === mediumSize}
              >
                <SvgMediumSizeIcon
                  fill={minimapSize === mediumSize ? accentAlt400 : gray30}
                  width={buttonWidth}
                  height={buttonHeight}
                />
              </MediumSizeButton>
            </SizeOptionsWrapper>
          </MapWrapper>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default MapView;
