import { useState, useEffect } from 'react';

// Helpers
import to from 'await-to-js';
import { useAuthState } from 'stores/AuthStore';
import { useProjectState } from 'stores/ProjectStore';
import { prepareUnitCustomFieldsForRender } from 'helpers/units/VmUnitHelper';

const useGetUnitCustomFields = (unit, showUnitDescription = false) => {
  const [customFields, setCustomFields] = useState();

  // Project State
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration, customFieldsMap } = ProjectState;

  // Auth State
  const { AuthState } = useAuthState();
  const { user } = AuthState;
  const { sessionToken } = user;

  useEffect(() => {
    if (!customFieldsMap) return;

    async function getCustomFields() {
      const [, fields] = await to(
        prepareUnitCustomFieldsForRender(
          unit,
          showcaseConfiguration,
          showUnitDescription,
          sessionToken,
          customFieldsMap,
          AuthState
        )
      );

      setCustomFields(fields);
    }

    getCustomFields();
  }, [
    unit,
    sessionToken,
    showcaseConfiguration,
    showUnitDescription,
    customFieldsMap,
    AuthState
  ]);

  return { customFields };
};

export default useGetUnitCustomFields;
