import React, { useRef } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import Tippy from '@tippyjs/react';

// Helpers
import { coloredPulse } from 'styles/animations';
import localizer from 'localization/localizer';

// Styling
import styled, { css } from 'styled-components';

const Arrow = styled(motion.div)`
  position: absolute;
  z-index: 52;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  top: calc(50% - 30px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ shouldAnimate, theme }) => {
    if (shouldAnimate) {
      return css`
        animation: ${coloredPulse(theme.defaultBrandSecondaryColor)} 2.5s linear
          infinite;
      `;
    }
  }}
`;

const LeftArrow = styled(Arrow)`
  margin-left: 20px;
`;

const RightArrow = styled(Arrow)`
  margin-right: 20px;
  right: 0;
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: ${(props) => props.theme.whitePure};
`;

const TurntableNavigationControls = ({
  onNavigateToPreviousImage,
  onNavigateToNextImage,
  onRotationStop,
  animateControls,
  highlightedControl,
  ...props
}) => {
  const longPressTimer = useRef();
  const turningInterval = useRef();

  const timeForLongPressInMs = 300;
  const turnIntervalSpeedinMs = 100;

  return (
    <>
      <LeftArrow
        onClick={onNavigateToPreviousImage}
        onMouseDown={() => {
          longPressTimer.current = setTimeout(() => {
            turningInterval.current = setInterval(() => {
              onNavigateToPreviousImage();
            }, turnIntervalSpeedinMs);
          }, [timeForLongPressInMs]);
        }}
        onTouchStart={() => {
          longPressTimer.current = setTimeout(() => {
            turningInterval.current = setInterval(() => {
              onNavigateToPreviousImage();
            }, turnIntervalSpeedinMs);
          }, [timeForLongPressInMs]);
        }}
        onTouchEnd={() => {
          clearTimeout(longPressTimer.current);
          clearInterval(turningInterval.current);
        }}
        onMouseUp={() => {
          clearTimeout(longPressTimer.current);
          clearInterval(turningInterval.current);
          onRotationStop();
        }}
        shouldAnimate={!!animateControls}
        {...props}
      >
        <Tippy
          offset={[0, 15]}
          theme="favoritesToggler"
          placement="bottom"
          content={<div>{localizer.rotateLeft}</div>}
          arrow={false}
          touch={false}
        >
          <div>
            <ChevronIcon icon={['far', 'chevron-left']} size="1x" />
          </div>
        </Tippy>
      </LeftArrow>

      <RightArrow
        onClick={onNavigateToNextImage}
        onMouseDown={() => {
          longPressTimer.current = setTimeout(() => {
            turningInterval.current = setInterval(() => {
              onNavigateToNextImage();
            }, turnIntervalSpeedinMs);
          }, [timeForLongPressInMs]);
        }}
        onTouchStart={() => {
          longPressTimer.current = setTimeout(() => {
            turningInterval.current = setInterval(() => {
              onNavigateToNextImage();
            }, turnIntervalSpeedinMs);
          }, [timeForLongPressInMs]);
        }}
        onTouchEnd={() => {
          clearTimeout(longPressTimer.current);
          clearInterval(turningInterval.current);
          onRotationStop();
        }}
        onMouseUp={() => {
          clearTimeout(longPressTimer.current);
          clearInterval(turningInterval.current);
          onRotationStop();
        }}
        shouldAnimate={!!animateControls}
        {...props}
      >
        <Tippy
          offset={[0, 15]}
          theme="favoritesToggler"
          placement="bottom"
          content={<div>{localizer.rotateRight}</div>}
          arrow={false}
          touch={false}
        >
          <div>
            <ChevronIcon icon={['far', 'chevron-right']} size="1x" />
          </div>
        </Tippy>
      </RightArrow>
    </>
  );
};

export default TurntableNavigationControls;
