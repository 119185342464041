import React from 'react';
import { number, bool, shape } from 'prop-types';

// Components
import { GridviewIcon } from 'resources/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Button = styled.button`
  align-items: center;
  backdrop-filter: blur(3px);
  border: none;
  background-color: ${(props) => props.theme.fadeLightGray};
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  height: calc(60px + 1vmin);
  justify-content: center;
  outline: none;
  min-width: 80px;
  cursor: pointer;
  z-index: 15;
  margin-right: 3px;
`;

const Navigator = styled.div`
  display: flex;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 70px;
  width: auto;
  z-index: 10;
`;

function Navigation({
  activePage,
  onGridView,
  onNextPage,
  onPreviousPage,
  pageLength,
  showGrid,
  showUI,
  centerButtonContent
}) {
  // UI
  const uiMotionState = showUI && !showGrid;
  const showPrevious = activePage > 0;
  const showNext = activePage < pageLength - 1;

  let nextButtonState = showNext ? 'visible' : 'disabled';
  let previousButtonState = showPrevious ? 'visible' : 'disabled';
  let gridViewButtonState = 'visible';

  if (!uiMotionState) {
    nextButtonState = 'hidden';
    previousButtonState = 'hidden';
    gridViewButtonState = 'hidden';
  }

  let centerButton = <GridviewIcon />;
  if (centerButtonContent) {
    centerButton = centerButtonContent;
  }

  return (
    <Navigator>
      <motion.div
        key="prevButton"
        initial={'hidden'}
        animate={previousButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onPreviousPage('ShowcaseOnPagePreviousArrowNavigation');
          }}
        >
          <FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="sm" />
        </Button>
      </motion.div>
      <motion.div
        key="gridButton"
        initial={'hidden'}
        animate={gridViewButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onGridView();
          }}
        >
          {centerButton}
        </Button>
      </motion.div>
      <motion.div
        key="nextButton"
        initial={'hidden'}
        animate={nextButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onNextPage('ShowcaseOnPageNextArrowNavigation');
          }}
        >
          <FontAwesomeIcon icon={['fal', 'long-arrow-right']} size="sm" />
        </Button>
      </motion.div>
    </Navigator>
  );
}

Navigation.propTypes = {
  activePageIndex: number,
  pageLength: number,
  showGrid: bool,
  showUI: bool,
  centerButtonContent: shape({})
};

export default Navigation;

// Motion
const buttonVariants = {
  visible: {
    opacity: 1,
    display: 'block',
    pointerEvents: 'auto'
  },
  disabled: {
    opacity: 0.5,
    display: 'block',
    pointerEvents: 'none'
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    transitionEnd: {
      display: 'none'
    }
  }
};
