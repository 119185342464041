import React, { useState, Fragment } from 'react';
import { shape, number, oneOfType, string } from 'prop-types';

// Components
import { Svg360Icon } from 'resources/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { getCurrentEnvironment } from 'helpers';
import { motion } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';
import fileCategories from 'configs/SupportedFileCategories.json';

// Styles
import styled, { css, keyframes } from 'styled-components';

const MainWrapper = styled.div`
  position: relative;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        height: 100%;
        width: 100%;
      `;
    }
  }}
`;

const ImageOverlay = styled.div`
  position: absolute;
  z-index: 3
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageOverlayBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: black;
  opacity: ${isMobileOnly ? '0.2' : '0.3'};
`;

const IconBackground = styled(ImageOverlayBackground)`
  background-color: transparent;
  z-index: 3;
  opacity: 0.5;
  background-image: linear-gradient(
    -30deg,
    ${({ theme }) => theme.defaultBrandPrimaryColor},
    transparent
  );
`;

const StyledSvg360Icon = styled(Svg360Icon)`
  z-index: 4;
  ${isMobileOnly &&
  `
    transform: scale(0.4);
  `}
`;

const sharedThumbnailStyle = `
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const slidingFrontSunbeam = keyframes`
    0% { left: -80% }
    100% { left: 120% }
`;

const ThumbnailImageOverlay = styled.div`
  ${sharedThumbnailStyle}
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  ${({ animate }) => {
    if (animate) {
      return css`
        background-color: rgba(0, 0, 0, 0.08);
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 80%;
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.25) 30%,
            rgba(255, 255, 255, 0.37) 54%,
            rgba(255, 255, 255, 0.25) 70%,
            rgba(255, 255, 255, 0)
          );
          transform: skew(-8deg);
          animation: ${slidingFrontSunbeam} 2s infinite ease-out;
        }
      `;
    }
  }}
`;

const ThumbnailImage = styled(motion.img)`
  ${sharedThumbnailStyle}
  object-fit: cover;
  z-index: 1;
`;

const HdThumbnailImage = styled(ThumbnailImage)`
  z-index: 2;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  z-index: 4;
  color: ${({ theme }) => theme.showcaseWhite};
  font-size: 2rem;
`;

const ImagePreview = (props) => {
  const { data, size, isMobile, isVerticalLayout, icon } = props;

  // calculate sizes
  let height = size;
  let width = size;
  if (data.originalImageSize) {
    const { width: w, height: h } = data.originalImageSize;
    const originalRatio = w / h;
    if (isVerticalLayout) {
      height = width / originalRatio;
    } else {
      width = height * originalRatio;
    }
  }

  const [hdThumbnailLoaded, setHdThumbnailLoaded] = useState(false);
  const [lowQualityThumbnailLoaded, setLowQualityThumbnailLoaded] =
    useState(false);

  let thumbnail = null;
  if (data.contentUri && (size || isMobile)) {
    const height = !!size ? Math.round(size) : 200;
    thumbnail = (
      <Fragment>
        {lowQualityThumbnailLoaded && (
          <HdThumbnailImage
            initial={{ opacity: 0 }}
            animate={{ opacity: hdThumbnailLoaded ? 1 : 0 }}
            src={`${getCurrentEnvironment().baseImageUrl}/q=100:h=${height}/${
              data.contentUri
            }`}
            alt={`HD Preview`}
            onLoad={() => {
              setHdThumbnailLoaded(true);
            }}
          />
        )}
        <ThumbnailImage
          initial={{ opacity: 0 }}
          animate={{ opacity: lowQualityThumbnailLoaded ? 1 : 0 }}
          src={`${getCurrentEnvironment().baseImageUrl}/q=50:h=200/${
            data.contentUri
          }`}
          alt={`Preview`}
          onLoad={() => {
            setLowQualityThumbnailLoaded(true);
          }}
        />
        <ThumbnailImageOverlay
          animate={!(lowQualityThumbnailLoaded || hdThumbnailLoaded)}
        />
      </Fragment>
    );
  }

  return (
    <MainWrapper height={height} width={width} isMobile={isMobile}>
      {thumbnail}
      {data.contentItemType === fileCategories.image360 && (
        <ImageOverlay>
          <ImageOverlayBackground />
          <StyledSvg360Icon />
        </ImageOverlay>
      )}
      {icon && (
        <ImageOverlay>
          <IconBackground />
          <StyledIcon
            icon={['far', 'cube']}
            size={isMobileOnly ? '1x' : '2x'}
          />
        </ImageOverlay>
      )}
    </MainWrapper>
  );
};

ImagePreview.propTypes = {
  data: shape({}).isRequired,
  pageWidth: number,
  size: oneOfType([number, string])
};

ImagePreview.defaultProps = {
  pageWidth: null,
  size: null
};

export default ImagePreview;
