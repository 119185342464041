import React, { useEffect, useState } from 'react';
import { string, shape, bool, func } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdealImage } from '@prompto-ui';
import { ShowcaseBuilderNavigationSpotIcons } from '@prompto-ui';
import Custom360Icon from 'resources/icons/360Custom';
import ShowcaseLoader from 'components/other/ShowcaseLoader';

// Helpers
import { ExpandDirections } from './Spot';
import { useProjectState } from 'stores/ProjectStore';
import { motion } from 'framer-motion';
import { getCurrentEnvironment, displayLocalizedValue } from 'helpers';
import { isMobile } from 'react-device-detect';
import { ContentItem } from '@prompto-api';

// Styling
import styled, { css } from 'styled-components';

const maxSpotPreviewWidth = isMobile ? 200 : 250;
const maxSpotPreviewHeight = isMobile ? 120 : 150;
const previewMargin = 20;
const tourBottomBarHeight = isMobile ? 120 : 80;
const navigationSpotSize = 40;
const customLabelMargin = 20;

const ThumbContainer = styled(motion.div)`
  max-width: ${maxSpotPreviewWidth}px;
  max-height: ${maxSpotPreviewHeight}px;
  position: absolute;
  pointer-events: ${(props) => (props.isSpotHovered ? 'initial' : 'none')};
  cursor: ${(props) => (props.isSpotHovered ? 'pointer' : 'default')};
  border: 1px solid white;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  z-index: ${(props) => (props.isSpotHovered ? '5' : '1')};

  ${({
    isCustomLabel,
    horizontalExpandDirection,
    verticalExpandDirection,
    customLabelSize,
    offset,
    iconScale
  }) => {
    const isTop = verticalExpandDirection === ExpandDirections.top;
    const isLeft = horizontalExpandDirection === ExpandDirections.left;
    let styles = `transform-origin: ${isTop ? 'bottom' : 'top'} ${
      isLeft ? 'right' : 'left'
    };`;
    if (isCustomLabel) {
      if (!customLabelSize || !offset) return ``;
      const verticalOffset = (offset) =>
        `${offset * -1 + customLabelSize.height - customLabelMargin}px`;
      const horizontalOffset = (offset) =>
        `${offset * -1 + customLabelSize.width - customLabelMargin}px`;
      const verticalPosition = isTop
        ? `bottom: ${verticalOffset(offset.yTop)};`
        : `top: ${verticalOffset(offset.yBottom)};`;
      const horizontalPosition = isLeft
        ? `right: ${horizontalOffset(offset.xLeft)};`
        : `left: ${horizontalOffset(offset.xRight)};`;
      styles = `
        ${styles}
        ${verticalPosition}
        ${horizontalPosition}
      `;
    } else {
      const offset = Math.max(0, navigationSpotSize * (iconScale - 1.5));
      styles = `
        ${styles}
        ${isLeft ? `right: ${offset}px;` : `left: ${offset}px;`}
        ${isTop ? `bottom: ${10 + offset}px;` : `top: ${offset}px;`}
      `;
    }
    return styles;
  }}
`;

const TargetThumbnailWrapper = styled.div`
  display: block;
  width: ${maxSpotPreviewWidth}px;
  height: ${maxSpotPreviewHeight}px;
  object-fit: cover;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const Wrapper = styled(motion.div)`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 100;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: ${({ br, size }) => (br ? (size / 100) * br : 2)}px;
  overflow: hidden;
  backdrop-filter: blur(5px);

  ${({ horizontalExpandDirection, verticalExpandDirection, size }) => {
    const expandToLeft = horizontalExpandDirection === ExpandDirections.left;
    const expandToTop = verticalExpandDirection === ExpandDirections.top;

    const xShift = ((size - navigationSpotSize) / 2) * (expandToLeft ? 1 : -1);
    const yShift = ((size - navigationSpotSize) / 2) * (expandToTop ? 1 : -1);

    return css`
      transform: translateX(${xShift}px) translateY(${yShift}px);
      ${expandToLeft ? 'right: 0;' : 'left: 0;'}
      ${expandToTop ? 'bottom: 0;' : 'top: 0;'}
    `;
  }}

  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.color};
    border-radius: ${({ br, size }) => (br ? (size / 100) * br : 2)}px;
  }
`;

const CustomLabelWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${({ size }) => size.height}px;
  width: ${({ size }) => size.width}px;
  cursor: pointer;
  ${(props) =>
    props.size &&
    props.offset &&
    (props.verticalExpandDirection === ExpandDirections.top
      ? {
          bottom: -props.offset.yTop + 'px'
        }
      : {
          top: -props.offset.yBottom + 'px'
        })}

  ${(props) =>
    props.size &&
    props.offset &&
    (props.horizontalExpandDirection === ExpandDirections.left
      ? {
          left: props.offset.xLeft - props.size.width + customLabelMargin + 'px'
        }
      : {
          left: -props.offset.xRight + customLabelMargin + 'px'
        })}
`;

const CustomLabel = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const FontAwesomeIconWrapper = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${(props) => props.color};
  transform: rotate(${(props) => props.rotate}deg);
`;

const IconWrapper = styled(motion.div)`
  width: ${navigationSpotSize}px;
  height: ${navigationSpotSize}px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const NameWrapper = styled(motion.div)`
  margin: 0 ${previewMargin}px;
  color: white;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  max-width: ${maxSpotPreviewWidth - 2 * previewMargin}px;
  width: auto;
  text-transform: uppercase;
  text-overflow: ellipsis;
  z-index: 50;
  pointer-events: none;
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 10px;
  color: ${(props) => props.theme.whitePure};
`;

const LeftArrow = styled(ChevronIcon)`
  left: 2px;
`;

const RightArrow = styled(ChevronIcon)`
  right: 2px;
`;

const PreviewWrapper = styled.div`
  z-index: -1;
`;

const Styled360 = styled(Custom360Icon)`
  color: white;
`;

const calculateMaxWidth = (spotObject) => {
  const maxWidth = (() => {
    const imageWidth = window.innerWidth;
    switch (spotObject?.customLabelConfiguration?.customLabelSize) {
      case 'small': {
        return 50;
      }
      case 'medium': {
        return 100;
      }
      case 'large': {
        return 150;
      }
      default: {
        return (imageWidth - maxSpotPreviewWidth) * 0.9;
      }
    }
  })();
  return maxWidth;
};

const NavigationSpot = ({
  spotObject,
  spotLocation,
  spotAbsoluteLocation,
  isSpotHovered,
  onSpotClicked,
  parentDimensions,
  horizontalExpandDirection,
  verticalExpandDirection
}) => {
  const { ProjectState } = useProjectState();
  const { project, vault, nightMode, showcaseConfiguration } = ProjectState;

  const [canShowNightImage, setCanShowNightImage] = useState(false);

  const [spotName, setSpotName] = useState('');
  const [customLabelOffset, setCustomLabelOffset] = useState(null);
  const [customLabel, setCustomLabel] = useState(null);
  const [fetchingCustomlabel, setFetchingCustomLabel] = useState(false);
  // if error occured while loading a custom label we show regular icon
  const [forceUsingRegularIcon, setForceUsingRegularIcon] = useState(false);

  const defaultIcon =
    spotObject?.navigationItemType === 'album360Spot'
      ? 'threeSixty'
      : 'defaultLongArrowDown';
  const [selectedIconId, setSelectedIconId] = useState(defaultIcon);

  const [customLabelSize, setCustomLabelSize] = useState();
  const [spotAbsoluteCalculated, setSpotAbsoluteCalculated] = useState(false);
  const [originalSpotAbsoluteX, setOriginalSpotAbsoluteX] = useState(null);
  const [originalSpotAbsoluteY, setOriginalSpotAbsoluteY] = useState(null);

  const [spotSize, setSpotSize] = useState(navigationSpotSize); // basic spot size is 40px
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  const [
    horizontalExpandDirectionCustomLabel,
    setHorizontalExpandDirectionCustomLabel
  ] = useState(ExpandDirections.right);

  const [
    verticalExpandDirectionCustomLabel,
    setVerticalExpandDirectionCustomLabel
  ] = useState(ExpandDirections.top);

  let imageFitFillType = 'fill';

  if (showcaseConfiguration?.initialTourImageFitFillType) {
    imageFitFillType = showcaseConfiguration.initialTourImageFitFillType;
  }

  // check if there's a custom spot size
  useEffect(() => {
    if (spotObject?.spotSize && spotObject.spotSize !== spotSize) {
      setSpotSize(spotObject.spotSize);
    }
  }, [spotObject, spotSize]);

  useEffect(() => {
    if (
      spotAbsoluteLocation &&
      spotAbsoluteLocation.x !== 0 &&
      !spotAbsoluteCalculated
    ) {
      setSpotAbsoluteCalculated(true);
      setOriginalSpotAbsoluteX(spotAbsoluteLocation.x);
      setOriginalSpotAbsoluteY(spotAbsoluteLocation.y);
    }
  }, [spotAbsoluteLocation, spotAbsoluteCalculated]);

  // get custom label
  useEffect(() => {
    if (fetchingCustomlabel) return;
    if (
      !!spotObject?.customLabelConfiguration?.useCustomLabel &&
      !customLabel
    ) {
      setFetchingCustomLabel(true);
      ContentItem.get(spotObject.customLabelConfiguration.customLabelObjectId)
        .then((result) => {
          const customLabel = result.data?.vmContentItem;
          const transform = `h=${customLabel.originalImageSize.height}`;
          const baseUrl = getCurrentEnvironment().baseImageUrl;
          customLabel.contentUri = `${baseUrl}/${transform}/${customLabel.contentUri}`;
          if (customLabel) {
            setCustomLabel(customLabel);
          }
          setFetchingCustomLabel(false);
        })
        .catch(() => {
          setForceUsingRegularIcon(true);
          setFetchingCustomLabel(false);
        });
    }
  }, [spotObject, customLabel, fetchingCustomlabel]);

  useEffect(() => {
    if (spotObject && spotObject.iconId) {
      setSelectedIconId(spotObject.iconId);
    }
  }, [spotObject]);

  useEffect(() => {
    setCanShowNightImage(spotObject?.nightContentItem);
  }, [spotObject]);

  useEffect(() => {
    if (spotObject) {
      switch (spotObject.navigationItemType) {
        default:
        case 'navigationSpot':
          setSpotName(
            displayLocalizedValue(
              spotObject.contentItem?.displayName?.textMap
            ) ||
              displayLocalizedValue(spotObject.contentItem?.title?.textMap) ||
              'empty'
          );
          break;
        case 'turntableSpot':
        case 'album360Spot':
          setSpotName(
            displayLocalizedValue(spotObject.title?.textMap) || 'empty'
          );
          break;
      }
    }
  }, [spotObject]);

  useEffect(() => {
    // Ratio height-width
    const iRatio =
      customLabel?.originalImageSize.height /
      customLabel?.originalImageSize.width;
    // Ratio customLabelHeight - height of the screen
    const ratioHeight =
      (customLabel?.originalImageSize.height + maxSpotPreviewHeight) /
      (window.innerHeight - tourBottomBarHeight);
    // Ratio customLabelWidth - width of the screen
    const ratioWidth =
      (customLabel?.originalImageSize.width + maxSpotPreviewWidth) /
      window.innerWidth;
    const maxHeight =
      Math.min(
        window.innerHeight - tourBottomBarHeight - maxSpotPreviewHeight,
        customLabel?.originalImageSize.height
      ) * 0.9;
    const maxWidth = Math.min(
      calculateMaxWidth(spotObject),
      customLabel?.originalImageSize.width * 0.9
    );
    if (ratioHeight > ratioWidth) {
      // If ratioHeight > ratioWidth, the customLabel will fill the height of the screen first than the width
      const height =
        maxWidth * iRatio > maxHeight ? maxHeight : maxWidth * iRatio;
      const width = maxWidth * iRatio > maxHeight ? height / iRatio : maxWidth;
      setCustomLabelSize({ height, width });
    } else {
      const width = maxWidth;
      const height = maxWidth * iRatio;
      setCustomLabelSize({ height, width });
    }
    if (originalSpotAbsoluteX <= window.innerWidth / 2) {
      setHorizontalExpandDirectionCustomLabel(ExpandDirections.right);
    } else {
      setHorizontalExpandDirectionCustomLabel(ExpandDirections.left);
    }
    if (originalSpotAbsoluteY <= window.innerHeight / 2) {
      setVerticalExpandDirectionCustomLabel(ExpandDirections.bottom);
    } else {
      setVerticalExpandDirectionCustomLabel(ExpandDirections.top);
    }
  }, [
    customLabel,
    spotObject,
    originalSpotAbsoluteX,
    originalSpotAbsoluteY,
    imageFitFillType
  ]);

  useEffect(() => {
    if (!customLabel) return;
    if (customLabelSize) {
      // Calculate offset to the left of the screen
      const xLeft = Math.min(
        Math.max(
          customLabelSize.width + maxSpotPreviewWidth - spotAbsoluteLocation.x,
          0
        ),
        customLabelSize.width
      );
      // Calculate offset to the right of the screen
      const xRight = Math.min(
        Math.max(
          customLabelSize.width +
            maxSpotPreviewWidth +
            spotAbsoluteLocation.x -
            window.innerWidth,
          0
        ),
        customLabelSize.width
      );
      // Calculate offset to the top of the screen
      const yTop = Math.min(
        Math.max(
          customLabelSize.height +
            maxSpotPreviewHeight -
            spotAbsoluteLocation.y,
          0
        ),
        customLabelSize.height
      );
      // Calculate offset to the bottom of the screen
      const yBottom = Math.min(
        Math.max(
          customLabelSize.height +
            maxSpotPreviewHeight +
            tourBottomBarHeight +
            spotAbsoluteLocation.y -
            window.innerHeight,
          0
        ),
        customLabelSize.height - navigationSpotSize
      );
      setCustomLabelOffset({ xLeft, xRight, yTop, yBottom });
    }
  }, [
    parentDimensions,
    spotLocation,
    customLabel,
    customLabelSize,
    spotAbsoluteLocation,
    originalSpotAbsoluteY
  ]);

  const icon = ShowcaseBuilderNavigationSpotIcons.filter(
    (item) => item.id === selectedIconId
  ).map((item) => {
    switch (item.type) {
      default:
      case 'fontAwesome':
        return (
          <FontAwesomeIconWrapper
            key={item.id}
            icon={[item.library, item.iconId]}
            color={item.color}
            rotate={item.rotation || 0}
            size="1x"
          />
        );
      case 'custom':
        switch (item.id) {
          default:
          case 'threeSixty':
            const iconSize =
              spotObject?.navigationItemType === 'turntableSpot'
                ? '24px'
                : '28px';
            return (
              <Styled360
                key={item.id}
                style={{ height: iconSize, width: iconSize }}
              />
            );
        }
    }
  });

  let contentItemToRender;
  if (nightMode && canShowNightImage) {
    contentItemToRender = spotObject?.nightContentItem;
  } else {
    contentItemToRender = spotObject?.contentItem;
  }

  return (
    <>
      {!!spotObject?.customLabelConfiguration?.useCustomLabel &&
      !forceUsingRegularIcon ? (
        <>
          {customLabel && (
            <CustomLabelWrapper
              size={customLabelSize}
              verticalExpandDirection={verticalExpandDirectionCustomLabel}
              horizontalExpandDirection={horizontalExpandDirectionCustomLabel}
              offset={customLabelOffset}
              onClick={() => {
                if (isSpotHovered) {
                  onSpotClicked(spotObject);
                }
              }}
            >
              <CustomLabel src={customLabel.contentUri} alt="" />
            </CustomLabelWrapper>
          )}
        </>
      ) : (
        <Wrapper
          key="wrapper"
          initial={{ width: spotSize }}
          animate={isSpotHovered ? { width: 'auto' } : { width: spotSize }}
          transition={{ type: 'tween' }}
          horizontalExpandDirection={
            !!customLabel
              ? horizontalExpandDirectionCustomLabel
              : horizontalExpandDirection
          }
          verticalExpandDirection={
            !!customLabel
              ? verticalExpandDirectionCustomLabel
              : verticalExpandDirection
          }
          onClick={() => {
            if (isSpotHovered) {
              onSpotClicked(spotObject);
            }
          }}
          color={spotObject?.color}
          br={spotObject?.borderRadius}
          size={spotSize}
        >
          <NameWrapper
            id={spotObject.objectId}
            key={'name'}
            initial={{ opacity: 0 }}
            animate={
              isSpotHovered
                ? { opacity: 1 }
                : { opacity: 0, transition: { duration: 0.2 } }
            }
          >
            {spotName}
          </NameWrapper>
          <IconWrapper
            key={'icon'}
            initial={{ opacity: 0 }}
            animate={
              isSpotHovered
                ? {
                    opacity: 0,
                    scale: spotSize / navigationSpotSize,
                    transition: { duration: 0.2 }
                  }
                : { opacity: 1, scale: spotSize / navigationSpotSize }
            }
          >
            {icon}
            {spotObject?.navigationItemType === 'turntableSpot' && (
              <>
                <LeftArrow icon={['far', 'chevron-left']} size="1x" />
                <RightArrow icon={['far', 'chevron-right']} size="1x" />
              </>
            )}
          </IconWrapper>
        </Wrapper>
      )}

      <PreviewWrapper>
        <ThumbContainer
          iconScale={spotSize / navigationSpotSize}
          initial={{ opacity: 0.0, scale: 0 }}
          animate={
            isSpotHovered
              ? {
                  opacity: thumbnailLoaded ? 1 : 0.5,
                  scale: thumbnailLoaded ? 1 : 0.4,
                  x:
                    horizontalExpandDirectionCustomLabel ===
                    ExpandDirections.left
                      ? -10
                      : 10
                }
              : { opacity: 0, x: 0 }
          }
          onClick={() => {
            onSpotClicked(spotObject);
          }}
          transition={{ duration: 0.25, type: 'tween' }}
          isSpotHovered={isSpotHovered}
          horizontalExpandDirection={
            !!customLabel
              ? horizontalExpandDirectionCustomLabel
              : horizontalExpandDirection
          }
          verticalExpandDirection={
            !!customLabel
              ? verticalExpandDirectionCustomLabel
              : verticalExpandDirection
          }
          customLabelSize={customLabelSize}
          offset={customLabelOffset}
          isCustomLabel={!!customLabel}
        >
          <TargetThumbnailWrapper>
            {!thumbnailLoaded && <ShowcaseLoader size={3} />}
            <IdealImage
              key={spotObject.objectId}
              contentUri={contentItemToRender?.contentUri}
              fallbackUri={contentItemToRender?.contentUri}
              imageSize={
                contentItemToRender?.originalImageSize ?? {
                  width: maxSpotPreviewWidth,
                  height: maxSpotPreviewHeight
                }
              }
              containerSize={{
                width: maxSpotPreviewWidth,
                height: maxSpotPreviewHeight
              }}
              enableTracking={false}
              vaultId={vault?.objectId}
              projectId={project?.objectId}
              mustFillParent={true}
              onLoad={() => setThumbnailLoaded(true)}
              baseImageUrl={getCurrentEnvironment().baseImageUrl}
            />
          </TargetThumbnailWrapper>
        </ThumbContainer>
      </PreviewWrapper>
    </>
  );
};

NavigationSpot.propTypes = {
  spotObject: shape({}).isRequired,
  spotLocation: shape({}).isRequired,
  isSpotHovered: bool,
  horizontalExpandDirection: string,
  onSpotClicked: func.isRequired
};

NavigationSpot.defaultProps = {
  isSpotHovered: false,
  horizontalExpandDirection: 'left'
};

export default NavigationSpot;
