import React from 'react';

// Styling
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const ScreenWrap = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

const ScreenWrapper = ({ firstScreen, direction, children }) => {
  return (
    <AnimatePresence>
      <ScreenWrap
        initial={{
          x: firstScreen ? 0 : 640 * direction,
          opacity: 0.6
        }}
        animate={{ x: 0, opacity: 1.0 }}
        exit={{ x: -640 * direction, opacity: 0.6 }}
        transition={{ type: 'tween', duration: 0.15 }}
      >
        {children}
      </ScreenWrap>
    </AnimatePresence>
  );
};

export default ScreenWrapper;
