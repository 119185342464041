import React, { Fragment, useState, useContext } from 'react';

// Helpers
import localizer from 'localization/localizer';
import { showcaseContext } from '../../../main';
import copy from 'copy-to-clipboard';
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

export const QrWrapper = styled.div`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 4px;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.primary100};
  margin: 0 0 30px;
  text-align: center;
`;

export const LinkWrapper = styled.div`
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.88px;
  padding: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${(props) => props.theme.gray100};
  user-select: none;
  cursor: pointer;
`;

export const CopyWrapper = styled(motion.div)`
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.88px;
  padding: 18px 0px;
  width: 100%;
  justify-content: center;
  display: flex;
  background: ${(props) => props.theme.gray100};
  user-select: none;
  cursor: pointer;
`;

export const CopyMessage = styled.p`
  color: ${(props) => props.theme.successColor};
  font-weight: 500;
  font-size: 0.75rem;
`;

const FallbackSharing = ({ path, url, baseLink, envParam, shareShowcase }) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const { accentAlt700 } = useContext(showcaseContext);

  const copyToClipboard = () => {
    copy(url);
    setShowCopyMessage(true);
    const timer = setTimeout(() => {
      setShowCopyMessage(false);
      clearTimeout(timer);
    }, 2000);
  };
  return (
    <Fragment>
      <Text>{localizer.shareDetailText}</Text>
      <LinkWrapper onClick={copyToClipboard}>
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {baseLink}
        </span>
        {'/'}
        <span
          style={{
            color: accentAlt700
          }}
        >
          {path}
        </span>
        {envParam && <span>{`/${envParam}`}</span>}
      </LinkWrapper>
      <CopyWrapper
        initial={'hidden'}
        animate={showCopyMessage ? 'visible' : 'hidden'}
        variants={{ hidden: { opacity: 0.0 }, visible: { opacity: 1.0 } }}
      >
        <CopyMessage>{localizer.linkCopied}</CopyMessage>
      </CopyWrapper>
    </Fragment>
  );
};

export default FallbackSharing;
