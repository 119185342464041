import React from 'react';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';

// Helpers
import localizer from 'localization/localizer';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
`;

const StyledLoader = styled(ShowcaseLoader)`
  color: ${({ theme }) => theme.primary300};
`;

const PortfolioLoader = () => (
  <Wrapper>
    <StyledLoader description={localizer.loadingProjects} />
  </Wrapper>
);

PortfolioLoader.propTypes = {};

PortfolioLoader.defaultProps = {};

export default PortfolioLoader;
