import React, { useState, useEffect, useRef, useCallback } from 'react';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterBlock from './FilterBlock';
import PortfolioSort from '../PortfolioSort';
import ToggleFavoritesButton from 'components/other/ToggleFavoritesButton';
import LaunchWizardButton from '../LaunchWizardButton';
import SalesPersonToggle from '../SalesPersonToggle';

// Helpers
import {
  createRangeFilterValues,
  createPillFilterValues,
  unitMatchFilter,
  stateMatchesFilter
} from '../PortfolioPageHelpers';
import isEqual from 'lodash.isequal';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import localizer from 'localization/localizer';
import {
  addQueryParams,
  removeQueryParams,
  fetchSettingsFromURL,
  capitalize,
  getCurrentEnvironment
} from 'helpers';
import Cookies from 'universal-cookie';
import settings from '../PortfolioSettings.json';

// Styling
import styled, { css, withTheme } from 'styled-components';

const mobileSpecificStyles = {
  filterToggle: css`
    display: none;
  `,

  filterIcon: css`
    position: static;
    left: 0;
    top: 0;
    transform: translateY(0);
  `,
  header: css`
    min-height: 60px;
    padding: 15px;
  `,
  filterTitle: css`
    font-size: 1.125rem;
  `,
  footer: css`
    min-height: 60px;
    padding: 10px 15px;
    div {
      width: 100%;
    }
  `,
  filters: css`
    padding: 15px;
    flex-flow: column;
  `,
  filterUnit: css`
    display: flex;
    flex-flow: column;
    padding: 15px 0;
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.gray300};
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.gray300};
    }
  `,
  filtersContainer: css`
    max-height: calc(100vh - 120px);
  `,
  button: css`
    min-width: 100px;
  `,
  clearAllButton: css`
    font-size: 0.875rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 0;
    border: none;
    height: auto;
    min-width: 40px;
  `,
  showMoreFilters: css`
    width: auto;
    height: auto;
  `,
  foundProjects: css`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.primary100};
  `
};

const TogglesWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  order: 3;
`;

const FilterToggle = styled(motion.button)`
  position: relative;
  height: 50px;
  width: 50px;
  padding: 0;
  margin-left: 20px;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  border: solid 1px ${({ theme }) => theme.gray300};
  background-color: rgba(
    255,
    255,
    255,
    ${({ theme }) => theme.controlsOpacity}
  );
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 1rem;
  color: ${({ theme }) => theme.primary100};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  transition: all 200ms ease;
  ${isMobileOnly && mobileSpecificStyles.filterToggle}
`;

const ToggleFavoritesWrapper = styled(motion.div)`
  margin-left: 20px;
  ${({ landscape }) => {
    if (!isMobileOnly) return;
    return css`
      position: fixed;
      bottom: ${landscape ? 20 : 80}px;
      left: 10px;
      margin-left: 0;
    `;
  }}
`;

const ApppliedFiltersBadge = styled.div`
  position: absolute;
  top: ${isMobileOnly ? -2 : -3}px;
  right: ${isMobileOnly ? -2 : -3}px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary100};
  color: ${({ theme }) => theme.showcaseWhite};
  font-size: 0.75rem;
  font-weight: ${isMobileOnly ? 400 : 600};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 24;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const FilterForm = styled(motion.div)`
  display: flex;
  flex-flow: column;
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border-bottom: solid 1px ${({ theme }) => theme.gray200};
`;

const Block = styled(motion.div)`
  min-height: 60px;
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.gray300};
  background-color: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  padding: 0 30px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const Header = styled(Block)`
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  border-bottom: 1px solid ${({ theme }) => theme.gray300};
  ${isMobileOnly && mobileSpecificStyles.header}
`;

const FilterTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.showcaseBlack};
  margin: 0 5px 0 0;
  ${isMobileOnly && mobileSpecificStyles.filterTitle}
`;

const Filters = styled(Block)`
  padding: 20px 0;
  box-shadow: none;
  ${isMobileOnly && mobileSpecificStyles.filters}
`;

const FilterUnit = styled.div`
  flex: 25% 1 0;
  display: flex;
  flex-flow: column;
  padding: 0 30px;
  box-sizing: border-box;
  box-shadow: inset -1px 0 0 0 ${({ theme }) => theme.gray300};
  &:last-child {
    box-shadow: none;
  }
  ${isMobileOnly && mobileSpecificStyles.filterUnit}
`;

const AdditionalFilters = styled(Block)`
  padding: 30px;
  transform-origin: bottom;
  div {
    width: 100%;
    height: 100px;
    background-color: ${({ theme }) => theme.grayWhiteOff};
    color: ${({ theme }) => theme.primary100};
    border: 1px solid ${({ theme }) => theme.primary100};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Footer = styled(Block)`
  align-items: center;
  justify-content: space-between;
  ${isMobileOnly && mobileSpecificStyles.footer}
`;

const FooterGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  min-width: 160px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.gray300};
  background-color: ${({ theme }) => theme.showcaseWhite};
  font-family: ${({ theme }) => theme.mainFontFamily};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  transition: all 200ms ease;
  &:hover {
    box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.05);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  ${isMobileOnly && mobileSpecificStyles.button}
`;

const ClearAllButton = styled(Button)`
  color: ${({ theme }) => theme.warning};
  margin-right: 30px;
  ${isMobileOnly && mobileSpecificStyles.clearAllButton}
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.primary100};
`;

const ApplyFiltersButton = styled(Button)`
  margin-left: ${isMobileOnly ? 'auto' : '30px'};
  background-color: ${({ theme }) => theme.mainAccent};
  color: ${({ theme }) => theme.showcaseWhite};
`;

const ShowMoreFilters = styled.button`
  height: 30px;
  width: 100%;
  flex-shrink: 0;
  border: none;
  background-color: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.accentAlt500};
  cursor: pointer;
  ${isMobileOnly && mobileSpecificStyles.showMoreFilters}
`;

const FilterIcon = styled(FontAwesomeIcon)`
  pointer-events: none;
  font-size: 1.125rem;
  ${isMobileOnly && mobileSpecificStyles.filterIcon}
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-flow: column;
  max-height: calc(100vh - 200px);
  overflow: auto;
  ${isMobileOnly && mobileSpecificStyles.filtersContainer}
`;

const FoundProjects = styled.span`
  margin: 0;
  font-size: 0.875;
  color: ${({ theme }) => theme.primary300};
  ${isMobileOnly && mobileSpecificStyles.foundProjects}
`;

const MoreFiltersMobile = styled.div`
  width: 100%;
  flex-shrink: 0;
  padding: 0 15px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const MobileContactSales = styled(MoreFiltersMobile)``;

const screenHeight = window.innerHeight;

const cookies = new Cookies();
const marketplaceSortingOptionCookie = `prompto/${
  getCurrentEnvironment().env
}/marketplaceSortingOption`;

const PortfolioFilter = ({
  show,
  setShow,
  disableSort = false,
  disableFilter = true,
  setSelectedSortOption,
  selectedSortOption,
  onSetFilteredProjectIds,
  vaultId,
  theme,
  setShowSalesPersonModal,
  visible,
  shouldShowPrices,
  resetUiState,
  selectedView,
  setView,
  allowedViews,
  salesPersonEnabled,
  salesPerson,
  setShowWizard,
  showWizardLaunchButton,
  shouldShowStatuses,
  ...props
}) => {
  const [hasAdditionalFilters] = useState(false);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  const [isStartFilterSet, setIsStartFilterSet] = useState(false);
  const [savedOptionChecked, setSavedOptionChecked] = useState(false);

  const startFilterState = useRef();

  const { sortOptions } = settings;
  const { isLandscape } = useMobileOrientation();

  const L = localizer.marketplace;

  const mapStateToLabel = {
    DRAFT: localizer.draft.toUpperCase(),
    COMPLETED: localizer.completed.toUpperCase(),
    IN_PROGRESS: localizer.ongoing.toUpperCase(),
    UPCOMING: localizer.upcoming.toUpperCase()
  };

  // Marketplace state
  const { MarketplaceState, MarketplaceStateDispatch } = useMarketplaceState();
  const {
    favoritesList,
    showFavorites,
    sharedFilter,
    usedFilters,
    appliedFilters,
    filteredProjectIds,
    initialFilter,
    projectsData
  } = MarketplaceState;

  const saveSortingOptionToCookie = (sortOption) => {
    if (savedOptionChecked) {
      const date = new Date();
      date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 365); // 1 year in milliseconds
      cookies.set(marketplaceSortingOptionCookie, JSON.stringify(sortOption), {
        expires: date,
        sameSite: 'none',
        secure: true
      });
    }
  };

  const updateMarketplaceState = useCallback(
    (payload) =>
      MarketplaceStateDispatch({
        type: 'setMarkerplaceData',
        payload
      }),
    [MarketplaceStateDispatch]
  );

  const updateActiveFilter = (filterName, filterValues) => {
    updateMarketplaceState({
      sharedFilter: {
        ...sharedFilter,
        [filterName]: filterValues
      }
    });
  };

  // Reset filters when switching to favorite projects and backwards
  const clearFilters = () => {
    startFilterState.current = null;
    onSetFilteredProjectIds([]);
    setIsStartFilterSet(false);
    updateMarketplaceState({
      usedFilters: [],
      appliedFilters: [],
      filteredProjectIds: []
    });
    resetUiState();
  };

  const navigateToFavoriteProjects = () => {
    showFavorites
      ? removeQueryParams([{ showFpr: '' }])
      : addQueryParams([{ showFpr: true }]);
    updateMarketplaceState({
      showFavorites: !showFavorites,
      projectsData: null
    });
    clearFilters();
  };

  // Automate the favorite projects toggling
  useEffect(() => {
    const queryParams = fetchSettingsFromURL();
    if (queryParams.showFpr) {
      updateMarketplaceState({
        showFavorites: favoritesList.length > 0
      });
      if (favoritesList.length === 0) {
        removeQueryParams([{ showFpr: '' }]);
      }
    }
  }, [updateMarketplaceState, favoritesList]);

  useEffect(() => {
    if (show) {
      if (isStartFilterSet) return;
      startFilterState.current = sharedFilter;
      setIsStartFilterSet(true);
    } else {
      setIsStartFilterSet(false);
    }
  }, [show, sharedFilter, isStartFilterSet]);

  // keep track of what filters are currently in use
  useEffect(() => {
    if (!sharedFilter) return;
    const usedFilters = [];
    for (let key in sharedFilter) {
      if (
        sharedFilter[key].selected?.length > 0 ||
        sharedFilter[key].limits?.min !== sharedFilter[key].values?.min ||
        sharedFilter[key].limits?.max !== sharedFilter[key].values?.max
      ) {
        usedFilters.push(key);
      }
    }

    updateMarketplaceState({
      usedFilters: usedFilters
    });
  }, [sharedFilter, updateMarketplaceState]);

  // calculate initial filter data
  useEffect(() => {
    if (!projectsData) return;

    const calculateFilterData = async () => {
      const allProjects = Object.values(projectsData);
      if (allProjects.length > 0) {
        const allUnits = allProjects.reduce(
          (all, x) => all.concat(x.units),
          []
        );
        const prices = new Set();
        const surfaces = new Set();
        const bedrooms = new Set();
        const bathrooms = new Set();
        const unitTypeIds = new Set();
        const projectStatuses = new Set();

        if (shouldShowStatuses) {
          allProjects.forEach((project) => {
            if (project?.state) {
              projectStatuses.add(project.state);
            }
          });
        }

        allUnits.forEach((unit) => {
          if (unit.unitMetadata) {
            surfaces.add(unit.unitMetadata.surface);
            bedrooms.add(unit.unitMetadata.numberOfBedrooms);
            bathrooms.add(unit.unitMetadata.numberOfBathrooms);
          }
          if (unit.unitPrice) {
            prices.add(unit.unitPrice.price);
          }
          if (unit.unitType) {
            unitTypeIds.add(unit.unitType.title);
          }
        });

        const filterObject = {
          price: createRangeFilterValues(prices, '€'),
          surface: createRangeFilterValues(surfaces, 'm²'),
          numberOfBedrooms: createPillFilterValues(bedrooms),
          numberOfBathrooms: createPillFilterValues(bathrooms),
          propertyType: createPillFilterValues(unitTypeIds),
          projectStatus: createPillFilterValues(projectStatuses)
        };

        if (isEqual(initialFilter, filterObject)) return;

        updateMarketplaceState({
          sharedFilter: filterObject,
          wizardFilter: filterObject,
          initialFilter: filterObject
        });
      }
    };

    calculateFilterData();
  }, [projectsData, initialFilter, updateMarketplaceState, shouldShowStatuses]);

  useEffect(() => {
    if (!sharedFilter || !projectsData) return;
    if (isEqual(sharedFilter, initialFilter)) {
      updateMarketplaceState({
        filteredProjectIds: []
      });
      return;
    }

    const filteredProjectIds = Object.entries(projectsData)
      .filter(([, { state, units }]) => {
        const unitsMatch =
          units?.length > 0
            ? units.some((unit) => unitMatchFilter(unit, sharedFilter))
            : true;
        const stateMatches = stateMatchesFilter(
          shouldShowStatuses,
          state,
          sharedFilter
        );
        return unitsMatch && stateMatches;
      })
      .map(([projectId]) => projectId);

    updateMarketplaceState({
      filteredProjectIds
    });
  }, [
    sharedFilter,
    projectsData,
    initialFilter,
    updateMarketplaceState,
    shouldShowStatuses
  ]);

  // check if there is a previously saved sorting option
  useEffect(() => {
    const savedSortingOption = cookies.get(marketplaceSortingOptionCookie);
    setSavedOptionChecked(true);
    if (savedSortingOption) {
      const allOptions = sortOptions.reduce(
        (options, curr) => options.concat(curr.value),
        []
      );
      const opt = allOptions.find(
        (opt) => opt.name === savedSortingOption.name
      );
      if (opt && opt.name !== selectedSortOption.name) {
        setSelectedSortOption(opt);
      }
    }
  }, [selectedSortOption, setSelectedSortOption, sortOptions]);

  const sharedAnimation = (duration) => ({
    initial: { y: -screenHeight },
    animate: { y: 0 },
    exit: { y: -screenHeight },
    transition: { duration, ease: 'easeInOut' }
  });

  const salesPersonTab = (
    <SalesPersonToggle
      salesPerson={salesPerson}
      onClick={() => setShowSalesPersonModal(true)}
      localizer={L}
    />
  );

  const foundProjectsCounter = usedFilters.length > 0 && (
    <FoundProjects>
      {filteredProjectIds.length > 0
        ? localizer.formatString(L.foundProjects, filteredProjectIds.length)
        : L.noMatchesFound}
    </FoundProjects>
  );

  const clearAllButton = (text) => (
    <ClearAllButton
      disabled={usedFilters.length === 0}
      onClick={() => {
        onSetFilteredProjectIds([]);
        updateMarketplaceState({
          sharedFilter: initialFilter,
          appliedFilters: []
        });
        startFilterState.current = initialFilter;
        setShow(false);
        resetUiState();
      }}
    >
      {text}
    </ClearAllButton>
  );

  const hasBedroomData = sharedFilter?.numberOfBedrooms?.all?.length >= 1;
  const hasBathroomData = sharedFilter?.numberOfBathrooms?.all?.length >= 1;

  return (
    <>
      <TogglesWrapper visible={visible}>
        {!disableSort && !isMobileOnly && (
          <PortfolioSort
            key={'sort'}
            selectedSortOption={selectedSortOption}
            onSortChanged={(opt) => {
              resetUiState();
              setSelectedSortOption(opt);
              saveSortingOptionToCookie(opt);
            }}
            vaultId={vaultId}
          />
        )}

        <FilterToggle
          onClick={() => setShow(true)}
          disabled={disableFilter}
          {...props}
        >
          <FilterIcon icon={['fal', 'filter']} size="1x" />
          {appliedFilters.length > 0 && (
            <ApppliedFiltersBadge>{appliedFilters.length}</ApppliedFiltersBadge>
          )}
        </FilterToggle>

        <AnimatePresence>
          {favoritesList?.length > 0 && (
            <ToggleFavoritesWrapper
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                scale: favoritesList?.length > 0 ? 1 : 0
              }}
              exit={{ opacity: 0 }}
              landscape={isLandscape}
            >
              <ToggleFavoritesButton
                onClick={navigateToFavoriteProjects}
                tipText={localizer.favorites}
                switchedOn={showFavorites}
                tipPlacement={'bottom'}
                isAnimated={false}
                favoritesCount={favoritesList?.length ?? 0}
                disabled={favoritesList?.length === 0}
                wrapperStyles={css`
                  margin: 0;
                  border-radius: 50%;
                `}
                buttonStyles={css`
                  color: ${theme.primary100};
                  border-radius: 50%;
                  border: 1px solid ${theme.gray150};
                  background-color: rgba(
                    255,
                    255,
                    255,
                    ${theme.controlsOpacity}
                  );
                  backdrop-filter: blur(2px);
                  font-size: 1.25rem;
                  &:hover {
                    background-color: rgba(
                      255,
                      255,
                      255,
                      ${theme.controlsOpacity}
                    ) !important;
                  }
                `}
              />
            </ToggleFavoritesWrapper>
          )}
        </AnimatePresence>
      </TogglesWrapper>

      <AnimatePresence>
        {show && (
          <>
            <Backdrop
              onClick={() => {
                if (filteredProjectIds.length === 0) return;
                onSetFilteredProjectIds(filteredProjectIds);
                updateMarketplaceState({
                  appliedFilters: usedFilters
                });
                startFilterState.current = sharedFilter;
                setShow(false);
                resetUiState();
              }}
              {...sharedAnimation(0)}
            />
            <FilterForm {...sharedAnimation(0.3)}>
              <Header>
                <FilterTitle>{L.findProperty}</FilterTitle>
                {isMobileOnly && showWizardLaunchButton && (
                  <LaunchWizardButton onClick={() => setShowWizard(true)} />
                )}
              </Header>

              <FiltersContainer>
                <Filters>
                  {shouldShowPrices && (
                    <FilterUnit>
                      <FilterBlock
                        title={capitalize(localizer.price)}
                        type="range"
                        name="price"
                        rangeOptions={sharedFilter?.price}
                        setOptions={updateActiveFilter}
                      />
                    </FilterUnit>
                  )}
                  <FilterUnit>
                    <FilterBlock
                      title={capitalize(localizer.surface)}
                      type="range"
                      name="surface"
                      rangeOptions={sharedFilter?.surface}
                      setOptions={updateActiveFilter}
                    />
                  </FilterUnit>
                  {(hasBedroomData || hasBathroomData) && (
                    <FilterUnit>
                      {hasBedroomData && (
                        <FilterBlock
                          title={capitalize(localizer.bedrooms)}
                          name="numberOfBedrooms"
                          options={sharedFilter?.numberOfBedrooms}
                          setOptions={updateActiveFilter}
                          styles={`margin-bottom: 20px;`}
                          displayMax={4}
                          overflowText={L.orMore}
                        />
                      )}
                      {hasBathroomData && (
                        <FilterBlock
                          title={capitalize(localizer.bathrooms)}
                          name="numberOfBathrooms"
                          options={sharedFilter?.numberOfBathrooms}
                          setOptions={updateActiveFilter}
                          displayMax={3}
                          overflowText={L.orMore}
                        />
                      )}
                    </FilterUnit>
                  )}
                  <FilterUnit>
                    {shouldShowStatuses && (
                      <FilterBlock
                        title={L.projectStatus}
                        name="projectStatus"
                        options={sharedFilter?.projectStatus}
                        setOptions={updateActiveFilter}
                        optionToLabelMap={mapStateToLabel}
                      />
                    )}
                    <FilterBlock
                      title={L.propertyType}
                      name="propertyType"
                      options={sharedFilter?.propertyType}
                      setOptions={updateActiveFilter}
                    />
                  </FilterUnit>
                </Filters>

                {/* TODO: need a setting to choose which custom fields should be used in additional filters? */}
                {!isMobileOnly && hasAdditionalFilters && (
                  <>
                    {showAdditionalFilters && (
                      <AdditionalFilters>
                        <div>{L.additionalFilters}</div>
                      </AdditionalFilters>
                    )}

                    <ShowMoreFilters
                      onClick={() => setShowAdditionalFilters((prev) => !prev)}
                    >
                      {showAdditionalFilters
                        ? L.hideAdditionalFilters
                        : L.showMoreFilters}
                    </ShowMoreFilters>
                  </>
                )}

                {isMobileOnly && (
                  <>
                    <MoreFiltersMobile>
                      {clearAllButton(L.clearAll)}
                      {foundProjectsCounter}
                      {hasAdditionalFilters && (
                        <ShowMoreFilters
                          onClick={() =>
                            setShowAdditionalFilters((prev) => !prev)
                          }
                        >
                          {showAdditionalFilters
                            ? L.hideFilters
                            : L.moreFilters}
                        </ShowMoreFilters>
                      )}
                    </MoreFiltersMobile>
                    {salesPersonEnabled && (
                      <MobileContactSales>{salesPersonTab}</MobileContactSales>
                    )}
                  </>
                )}
              </FiltersContainer>

              <Footer>
                {!isMobileOnly && (
                  <>
                    <FooterGroup>
                      {clearAllButton(L.clearAllFilters)}
                      {foundProjectsCounter}
                    </FooterGroup>
                    {salesPersonEnabled && (
                      <FooterGroup>{salesPersonTab}</FooterGroup>
                    )}
                  </>
                )}

                <FooterGroup>
                  <CancelButton
                    onClick={() => {
                      updateMarketplaceState({
                        sharedFilter: startFilterState.current ?? initialFilter,
                        usedFilters: appliedFilters
                      });
                      setShow(false);
                    }}
                  >
                    {capitalize(localizer.cancel)}
                  </CancelButton>
                  <ApplyFiltersButton
                    disabled={
                      (filteredProjectIds.length === 0 &&
                        usedFilters?.length > 0) ||
                      (usedFilters?.length === 0 &&
                        appliedFilters?.length === 0)
                    }
                    onClick={() => {
                      onSetFilteredProjectIds(filteredProjectIds);
                      updateMarketplaceState({
                        appliedFilters: usedFilters
                      });
                      startFilterState.current = sharedFilter;
                      setShow(false);
                      resetUiState();
                    }}
                  >
                    {L.applyFilters}
                  </ApplyFiltersButton>
                </FooterGroup>
              </Footer>
            </FilterForm>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default withTheme(PortfolioFilter);
