import React, { useState } from 'react';
import { string } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import screenfull from 'screenfull';
import { isMobile } from 'react-device-detect';

// Styling
import styled from 'styled-components';

const ShrinkExpandButton = styled.button`
  margin: ${isMobile ? '0' : '15px'};
  padding: ${isMobile ? '0' : '10px'};
  font-size: ${isMobile ? '10px' : 'inherit'};
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  ${(props) => props.wrapperStyle};
`;

const StyledFullscreenButton = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
  ${({ styles }) => styles};
`;

const FullscreenButton = ({ iconStyle, wrapperStyle }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  if (screenfull.isEnabled) {
    screenfull.on('change', () => {
      setIsFullscreen(screenfull.isFullscreen);
    });
  }

  const toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  return (
    <ShrinkExpandButton
      id="fullscreenButton"
      onClick={toggleFullscreen}
      onTouchStart={toggleFullscreen}
      wrapperStyle={wrapperStyle}
    >
      <StyledFullscreenButton
        styles={iconStyle}
        icon={['fal', isFullscreen ? 'compress' : 'expand']}
        size="1x"
      />
    </ShrinkExpandButton>
  );
};

FullscreenButton.propTypes = {
  iconStyle: string
};

FullscreenButton.defaultProps = {
  iconStyle: ``
};

export default FullscreenButton;
