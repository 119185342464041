import React, { memo } from 'react';

// Components
import { motion } from 'framer-motion';
import MotionSlider from 'components/motionSlider/MotionSlider';
import PortfolioCard from '../PortfolioCard';
import SalesPersonCard from '../SalesPersonCard';

// Helpers
import to from 'await-to-js';
import { view } from '../PortfolioPage';
import { isMobileOnly } from 'react-device-detect';
import { NavigationCollection } from '@prompto-api';

// Styling
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 5;
  bottom: ${isMobileOnly ? 20 : 10}px;
  left: 0;
  right: 0;
  width: 100%;
  height: ${({ height }) => height}vh;
  user-select: none;
  box-sizing: border-box;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const MapViewSlider = memo((props) => {
  const {
    projects,
    sessionToken,
    endReached,
    loadNext,
    onShowcaseSelected,
    vaultSettings,
    projectsData,
    setHighlightedProject,
    onActiveItemsChanged,
    activeSliderItem,
    highlightedMarker,
    onSliderDrag,
    sliderHeight,
    salesPersonEnabled,
    salesPerson,
    setShowSalesPersonModal,
    onlyVisibleItems,
    shouldShowPrices,
    updateCurrentUiState,
    onSliderLoaded,
    onScrollSlider,
    visible,
    shouldShowStatuses
  } = props;
  // Create the cards
  let cards = [];
  if (projects && projectsData) {
    cards = projects
      .filter((project) => onlyVisibleItems.includes(project.objectId))
      .map((project, index) => (
        <PortfolioCard
          key={project.objectId}
          origin={view.map}
          project={project}
          unitsData={projectsData[project.objectId]?.units}
          configuration={projectsData[project.objectId]?.configuration}
          status={projectsData[project.objectId]?.status}
          vaultSettings={vaultSettings}
          thumbnailUri={project.thumbnailUri}
          onToggleHoverState={setHighlightedProject}
          highlighted={highlightedMarker?.objectId === project?.objectId}
          index={index}
          onCardClick={async () => {
            const contentCollectionObjectId =
              project.vmContentCollection?.objectId;

            // get the id of the first navigationItem in the collection
            let navigationItemObjectId;
            const navCollId = project?.navigationCollection?.objectId;
            if (navCollId) {
              const [, result] = await to(
                NavigationCollection.get(navCollId, true, sessionToken)
              );
              if (result) {
                const navCollection = result.data?.navigationCollection;
                const navItemList = navCollection?.vmNavigationItemList;
                if (navItemList && navItemList.length > 0) {
                  navigationItemObjectId = navItemList[0].objectId;
                }
              }
            }

            updateCurrentUiState();

            onShowcaseSelected(
              contentCollectionObjectId,
              navigationItemObjectId,
              project.objectId
            );
          }}
          shouldShowPrices={shouldShowPrices}
          shouldShowStatuses={shouldShowStatuses}
        />
      ));
  }

  if (salesPersonEnabled) {
    cards.push(
      <SalesPersonCard
        key="sales-person-card"
        origin={view.map}
        setShowSalesPersonModal={setShowSalesPersonModal}
        salesPerson={salesPerson}
      />
    );
  }

  // Always show the same number of items
  const maxActiveItems = isMobileOnly ? 1 : 4;
  const minActiveItems = isMobileOnly ? 1 : 4;

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0 }}
      height={sliderHeight}
      visible={visible}
    >
      <MotionSlider
        padding={isMobileOnly ? 45 : 80}
        gap={isMobileOnly ? 15 : 30}
        onSliderReachesEnd={loadNext}
        loadDataEndReached={endReached}
        maxActiveItems={maxActiveItems}
        minActiveItems={minActiveItems}
        disableScroll={false}
        disableArrows={!isMobileOnly}
        disableArrowKeys={false}
        arrowsOffset={10}
        onActiveItemsChanged={onActiveItemsChanged}
        onDrag={onSliderDrag}
        onScroll={onScrollSlider}
        activeSliderItem={activeSliderItem}
        hideScrollbar={true}
        velocity={0.7}
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 100,
          mass: 1,
          duration: 0.1
        }}
        enableLockedScroll={true}
        onSliderLoaded={onSliderLoaded}
      >
        {cards}
      </MotionSlider>
    </Wrapper>
  );
});

export default MapViewSlider;
