import React from 'react';
import { array, string } from 'prop-types';

// Helpers
import { Link } from 'react-router-dom';
import { useProjectState } from 'stores/ProjectStore';
import { isAndroid } from 'react-device-detect';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Components
import DocumentPreview from '../contentItems/types/document/DocumentPreview';

const SIZE = 170;

const GalleryWrapper = styled(motion.div)`
  width: 100%;
  height: calc(100% - 50px);
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const GalleryItemWrapper = styled.div`
  height: ${SIZE}px;
  width: calc(50% - 10px);
  margin: 5px;
`;

const MobileDocumentsContent = ({ items = [], noFilesMessage }) => {
  const { ProjectState } = useProjectState();
  const { shareCode } = ProjectState;

  if (items.length === 0) {
    return <p>{noFilesMessage}</p>;
  }

  return (
    <GalleryWrapper
      initial={{ x: 250, opacity: 0.0 }}
      animate={{ x: 0, opacity: 1.0 }}
      exit={{ x: 250, opacity: 0.0 }}
      transition={{ ease: 'easeOut' }}
    >
      {items.map((data, idx) => (
        <GalleryItemWrapper key={data.objectId} index={idx}>
          {isAndroid ? (
            <Link
              target="_blank"
              to={{
                pathname: `/${shareCode}/document_viewer/${encodeURIComponent(
                  data.objectId
                )}/${encodeURIComponent(data.contentUri)}`,
                search: window.location.search
              }}
            >
              <DocumentPreview data={data} size={SIZE} />
            </Link>
          ) : (
            <a href={data.contentUri} target="_blank" rel="noopener noreferrer">
              <DocumentPreview data={data} size={SIZE} />
            </a>
          )}
        </GalleryItemWrapper>
      ))}
    </GalleryWrapper>
  );
};

MobileDocumentsContent.propTypes = {
  items: array,
  noFilesMessage: string
};

MobileDocumentsContent.defaultProps = {
  items: [],
  noFilesMessage: ''
};

export default MobileDocumentsContent;
