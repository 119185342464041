import React from 'react';

// Components
import { NoFavoritesIcon } from 'resources/icons';

// Helpers
import localizer from 'localization/localizer';
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const NoProjectsText = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.primary400};
  margin: 0;
  margin-bottom: 10px;
`;

const CheckSearchTermsText = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.primary100};
  margin: 0 15px;
  margin-bottom: 20px;
`;

const PortfolioEmpty = ({ showFavorites }) => {
  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {showFavorites && <NoFavoritesIcon />}
      <NoProjectsText>
        {showFavorites ? localizer.noFavorites : localizer.noProjectsFound}
      </NoProjectsText>
      <CheckSearchTermsText>
        {showFavorites
          ? localizer.formatString(
              localizer.noFavoritesDescription,
              localizer.projects
            )
          : localizer.areYouSureSearchTerms}
      </CheckSearchTermsText>
    </Wrapper>
  );
};

PortfolioEmpty.propTypes = {};

PortfolioEmpty.defaultProps = {};

export default PortfolioEmpty;
