import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

// Styling
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import {
  getCurrentEnvironment,
  computeCloudinaryThumbnail,
  computeTransformUri
} from 'helpers';
import { isMobile } from 'react-device-detect';
import localizer from 'localization/localizer';

// Components
import { PlayThinIcon } from 'resources/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowcaseLoader from 'components/other/ShowcaseLoader';

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.showcaseBlack};
  color: ${(props) => props.theme.showcaseWhite};
  display: flex;
`;

const Button = styled.button`
  position: absolute;
  z-index: 4;
  right: 1rem;
  height: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 1rem;
`;

const SkipButton = styled(Button)`
  top: 1rem;
  min-width: 100px;
`;

const ToggleVolumeButton = styled(Button)`
  bottom: 1rem;
  width: 40px;
  font-size: 1.2rem;
`;

const Overlay = styled(motion.div)`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.fadeGray};
`;

const LoaderWrapper = styled(Overlay)``;

const VideoThumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const VideoPlayIcon = styled(PlayThinIcon)`
  transform: scale(1.1);
  position: absolute;
  border-radius: 50%;
  color: ${(props) => props.theme.whitePure};
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  cursor: pointer;
`;

const StyledVideo = styled.video`
  object-position: center;
  width: 100%;
  height: 100%;
`;

const IntroVideo = ({ show, onClose }) => {
  const currentEnvironment = getCurrentEnvironment();

  // Project Store
  const { ProjectState } = useProjectState();
  const { introVideo, showcaseConfiguration } = ProjectState;

  // Into video state
  const [showIntroVideo, setShowIntroVideo] = useState(false);
  const [videoFitType, setVideoFitType] = useState('fill');
  const [autoPlay, setAutoPlay] = useState(null);
  const [isVideoStartedManually, setVideoStartedManually] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [isThumbnailLoaded, setThumbnailLoaded] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');

  const player = useRef();

  useEffect(() => {
    if (show) setShowIntroVideo(true);
  }, [show]);

  useEffect(() => {
    const isInIframe = window.self !== window.top;

    if (isInIframe) {
      setAutoPlay(false);
    } else {
      const shouldVideoAutoplay = isMobile
        ? showcaseConfiguration?.autoplayStartingVideo === undefined ||
          showcaseConfiguration?.autoplayStartingVideo
        : true;
      setAutoPlay(shouldVideoAutoplay);
    }
  }, [showcaseConfiguration]);

  useEffect(() => {
    if (showcaseConfiguration?.introVideoPlaybackFitFillType) {
      setVideoFitType(showcaseConfiguration.introVideoPlaybackFitFillType);
    }
  }, [showcaseConfiguration]);

  useEffect(() => {
    if (introVideo) {
      const effectHeight = isMobile ? 720 : 1080;
      const videoSrc = computeTransformUri(
        decodeURIComponent(introVideo?.contentUri),
        currentEnvironment.googleStorageBucketId,
        currentEnvironment.cloudinaryVideoBaseUrl,
        `c_limit,h_${effectHeight}`,
        'mp4'
      );
      setVideoSrc(videoSrc);
    }
  }, [
    introVideo,
    currentEnvironment.googleStorageBucketId,
    currentEnvironment.cloudinaryVideoBaseUrl
  ]);

  if (!introVideo && show) {
    onClose();
  }

  const closeIntroVideoSmoothly = () => {
    setShowIntroVideo(false);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      onClose();
    }, 250);
  };

  // Add handlers for the video player to know when video is active
  let handlers = {
    onEnded: () => {
      closeIntroVideoSmoothly();
    },
    onError: (error) => {
      closeIntroVideoSmoothly();
    },
    onAbort: () => {
      closeIntroVideoSmoothly();
    }
  };

  // Determine max width/height based on mobile: sd (720) on mobile or hd otherwise (1280)
  let maxHeight = isMobile ? 720 : 1080;
  const cloudinaryThumbnailUri = computeCloudinaryThumbnail(
    decodeURIComponent(introVideo?.contentUri),
    currentEnvironment.googleStorageBucketId,
    currentEnvironment.cloudinaryVideoBaseUrl,
    `q_auto,vc_h265,h_${maxHeight},c_fill,dn_50`
  );

  if (!show) return null;

  return createPortal(
    <AnimatePresence>
      {showIntroVideo && (
        <Wrapper
          id="intro-video-container"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <SkipButton
            onClick={() => {
              closeIntroVideoSmoothly();
            }}
          >
            {localizer.skip}
          </SkipButton>
          <ToggleVolumeButton
            onClick={() => {
              setMuted(!isMuted);
            }}
          >
            <FontAwesomeIcon
              icon={['fal', isMuted ? 'volume-slash' : 'volume-up']}
              size="1x"
            />
          </ToggleVolumeButton>
          {autoPlay === null ? (
            <LoaderWrapper id={'video-loader'} {...sharedMotions}>
              <ShowcaseLoader />
            </LoaderWrapper>
          ) : (
            <>
              {!autoPlay && !isVideoStartedManually && (
                <Overlay>
                  <AnimatePresence>
                    {!isThumbnailLoaded && (
                      <LoaderWrapper id={'loader'} {...sharedMotions}>
                        <ShowcaseLoader />
                      </LoaderWrapper>
                    )}
                  </AnimatePresence>
                  <VideoThumbnail
                    src={cloudinaryThumbnailUri}
                    onLoad={(e) => setThumbnailLoaded(true)}
                  />
                  <VideoPlayIcon
                    onClick={() => {
                      setVideoStartedManually(true);
                      player.current.play();
                    }}
                  />
                </Overlay>
              )}
              <StyledVideo
                style={{
                  objectFit: videoFitType === 'fit' ? 'contain' : 'cover'
                }}
                {...handlers}
                src={videoSrc}
                muted={isMuted}
                autoPlay={autoPlay}
                controls={false}
                preload="auto"
                playsInline={true}
                type="video/mp4"
                ref={player}
              />
            </>
          )}
        </Wrapper>
      )}
    </AnimatePresence>,
    document.body
  );
};

const sharedMotions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

export default IntroVideo;
