import React, { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Button, Divider, Modal } from 'components/ui';
import PortfolioSlider from './PortfolioSlider';

// Helpers
import { getCurrentEnvironment } from 'helpers';
import localizer from 'localization/localizer';
import settings from './PortfolioSettings.json';

// Styling
import { css } from '@emotion/core';
import { showcaseContext } from '../../../../main';

const PortfolioLoggedOut = () => {
  const navigate = useNavigate();
  const { primary200, primary900 } = useContext(showcaseContext);
  const { loggedOutProjectList } = settings;

  return (
    <Fragment>
      <Modal
        open={true}
        onClose={() => {}}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
      >
        <div
          css={css`
            max-width: 300px;
          `}
        >
          <strong
            css={css`
              font-size: 16px;
              font-weight: 600;
              color: ${primary900};
            `}
          >
            {localizer.loggedOutTitle}
          </strong>
          <p
            css={css`
              margin: 0;
              margin-top: 10px;
              font-size: 14px;
              color: ${primary200};
            `}
          >
            {localizer.loggedOutDescription}
          </p>
          <Divider marginSize={25} />
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Button
              onClickAction={() => {
                window.open(getCurrentEnvironment().portalUrl, '_blank');
              }}
              label={localizer.login}
              dataTestId="loginButton"
            />
            <Button
              onClickAction={() => {
                setTimeout(() => navigate(`/${window.location.search}`), 100);
              }}
              label={localizer.goToConnectPage}
              dataTestId="connectButton"
            />
          </div>
        </div>
      </Modal>
      <PortfolioSlider
        key={'demoSlider'}
        list={loggedOutProjectList}
        endReached={true}
        disableArrows={true}
        disableScroll={true}
        disableArrowKeys={true}
      />
    </Fragment>
  );
};

PortfolioLoggedOut.propTypes = {};

PortfolioLoggedOut.defaultProps = {};

export default PortfolioLoggedOut;
