export const breakpoints = [576, 768, 992, 1200];

export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const makeResponsive = (values) => {
  if (!Array.isArray(values)) return ``;
  const screenWidth = window.innerWidth;
  let currScreen = 0;
  for (let i = 0; i < breakpoints.length; i++) {
    if (screenWidth > breakpoints[i]) currScreen = i;
  }
  const properStyle = Math.min(currScreen, values.length - 1);
  return values[properStyle];
};
