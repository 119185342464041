import React, { forwardRef } from 'react';

// Components
import { RichTextRenderer } from '@prompto-ui';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { capitalize, displayLocalizedValue } from 'helpers';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  min-width: 430px;
  max-width: ${({ maxWidth }) => maxWidth};
  flex-shrink: 0;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: ${({ maxHeight }) => (maxHeight ? maxHeight / 2 : 0)}px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : `auto`)};
  color: ${({ theme }) => theme.showcaseBlack};
`;

const InfoLabel = styled.p`
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 20px;
  margin: 20px 0 0;
  color: ${({ theme }) => theme.primary100};
`;

const Title = styled.h1`
  font-size: 1.875rem;
  line-height: 40px;
  font-weight: normal;
  margin: 0;
  word-break: break-word;
`;

const Address = styled.p`
  font-size: 1rem;
  font-weight: normal;
  line-height: 24px;
  margin: 0;
`;

const Info = styled.p`
  font-size: 0.875rem;
  line-height: 1.57;
  margin: 0;
  text-align: left;
  word-break: break-word;
  width: 100%;
`;

const ProjectInfo = forwardRef(({ maxHeight, maxWidth }, ref) => {
  const { ProjectState } = useProjectState();
  const { project } = ProjectState;

  if (!project) {
    return null;
  }
  const { address } = project;

  const projectTitle = displayLocalizedValue({
    en: project?.title,
    ...project?.localizedTitle?.textMap
  });
  const projectDescription = displayLocalizedValue({
    en: project?.description,
    ...project?.localizedDescription?.textMap
  });

  const addressString = `${address?.addressLine1} ${address?.city} ${address?.country} ${address?.zipCode}`;

  return (
    <Wrapper ref={ref} maxHeight={maxHeight} maxWidth={maxWidth}>
      <Title>{projectTitle}</Title>

      <InfoLabel>{capitalize(localizer.address)}</InfoLabel>
      <Address>{addressString}</Address>
      {projectDescription && (
        <>
          <InfoLabel>{capitalize(localizer.description)}</InfoLabel>
          <Info>
            <RichTextRenderer richText={projectDescription} />
          </Info>
        </>
      )}
    </Wrapper>
  );
});

ProjectInfo.propTypes = {};

ProjectInfo.defaultProps = {};

export default ProjectInfo;
