import React, { useState, useCallback, useEffect } from 'react';

// Styling
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import Navigation from './Navigation';
import ScreenBase from './ScreenBase';
import TourImage from 'resources/images/onboarding/tour.png';
import FilterImage from 'resources/images/onboarding/filter.png';
import UnitsImage from 'resources/images/onboarding/units.png';
import MoreImage from 'resources/images/onboarding/more.png';
import TourImageDesktop from 'resources/images/onboarding/tourDesktop.png';
import FilterImageDesktop from 'resources/images/onboarding/filterDesktop.png';
import UnitsImageDesktop from 'resources/images/onboarding/unitsDesktop.png';
import StartScreen from './StartScreen';

// Helpers
import { isMobile } from 'react-device-detect';
import localizer from 'localization/localizer';
import { useProjectState } from 'stores/ProjectStore';
import Cookies from 'universal-cookie';
import { capitalize, getCurrentEnvironment } from 'helpers';

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ScreensWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  max-width: ${isMobile ? '320px' : '640px'};
  max-height: ${isMobile ? '610px' : '800px'};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.whitePure};
`;

const SingleScreenWrapper = styled(motion.div)`
  height: ${isMobile ? 'calc(100% - 60px)' : 'calc(100% - 80px)'};
`;

const BoldText = styled.div`
  font-weight: bold;
  display: inline;
`;

const SCREENS = [
  {
    image: isMobile ? TourImage : TourImageDesktop,
    title: localizer.onBoarding.tour.title,
    text: localizer.onBoarding.tour.text
  },
  {
    image: isMobile ? FilterImage : FilterImageDesktop,
    title: localizer.onBoarding.filter.title,
    text: localizer.formatString(
      localizer.onBoarding.filter.text,
      <BoldText>{localizer.filter}</BoldText>
    )
  },
  {
    image: isMobile ? UnitsImage : UnitsImageDesktop,
    title: localizer.onBoarding.units.title,
    text: localizer.formatString(
      localizer.onBoarding.units.text,
      <BoldText>{localizer.units}</BoldText>
    )
  }
];

const MoreScreen = {
  image: isMobile ? MoreImage : UnitsImageDesktop,
  title: localizer.onBoarding.more.title,
  text: localizer.formatString(
    localizer.onBoarding.more.text,
    <BoldText>{capitalize(localizer.more)}</BoldText>
  )
};

if (isMobile) SCREENS.push(MoreScreen);

export const showOnboardingScreensCookie = `prompto/${
  getCurrentEnvironment().env
}/showOnboardingScreens`;

const OnboardingScreens = ({ show, showEmbeddedView, onClose }) => {
  const { ProjectState } = useProjectState();
  const { project, showcaseConfiguration, activeShareItem } = ProjectState;

  const [[currPage, direction], setCurrPage] = useState([0, 0]);
  const [dragAxis, setDragAxis] = useState();
  const [allowScroll, setAllowScroll] = useState(false);

  const onBoardingScreenEnabled =
    showcaseConfiguration?.onboardingScreenSettings?.enableOnboardingScreen !==
    false;
  const onBoardingScreenDesktopEnabled =
    showcaseConfiguration?.onboardingScreenSettings?.enableOnboardingDesktop ===
    true;
  const onBoardingScreenMobileEnabled =
    showcaseConfiguration?.onboardingScreenSettings?.enableOnboardingMobile !==
    false;
  const onBoardingScreenEmbeddedEnabled =
    showcaseConfiguration?.onboardingScreenSettings
      ?.enableOnboardingEmbedded === true;

  const PAGES = [
    <StartScreen
      project={project}
      customerName={activeShareItem?.customerName}
    />,
    ...SCREENS.map((screen) => (
      <ScreenBase
        direction={direction}
        key={screen.title}
        image={screen.image}
        title={screen.title}
        text={screen.text}
        onClose={onClose}
      />
    ))
  ];

  const cookies = new Cookies();

  const handleGetStarted = useCallback(() => {
    cookies.set(showOnboardingScreensCookie, 'false');
    onClose();
  }, [onClose, cookies]);

  useEffect(() => {
    if (!show) {
      setCurrPage([0, 0]);
    }
  }, [show]);

  useEffect(() => {
    if (allowScroll) {
      const handleTouch = (event) => {
        event.stopPropagation();
      };
      document.documentElement.addEventListener('touchmove', handleTouch);
      return () => {
        document.documentElement.removeEventListener('touchmove', handleTouch);
      };
    }
  }, [allowScroll]);

  const disableOnboardingScreen = () => {
    if (!onBoardingScreenEnabled) return true;
    if (showEmbeddedView && !onBoardingScreenEmbeddedEnabled) return true;
    if (!isMobile && !onBoardingScreenDesktopEnabled) return true;
    if (isMobile && !onBoardingScreenMobileEnabled) return true;
    return false;
  };

  if (!show || disableOnboardingScreen()) return null;

  return (
    <AnimatePresence initial={false}>
      <Backdrop
        initial={{ x: 0, opacity: 0.0 }}
        animate={{ x: 0, opacity: 1.0 }}
        exit={{ x: 0, opacity: 0.0 }}
        transition={{ duration: 0.15 }}
      >
        <ScreensWrapper>
          <SingleScreenWrapper
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 300 },
              opacity: { duration: 0.2 }
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
            dragElastic={0.5}
            dragDirectionLock={true}
            onDirectionLock={(axis) => setDragAxis(axis)}
            onDragStart={(e, info) => {
              setAllowScroll(Math.abs(info.delta.y) > Math.abs(info.delta.x));
            }}
            onDragEnd={(e, info) => {
              const { offset, velocity } = info;

              // Horizontal swipe
              if (dragAxis === 'x') {
                const horizontalSwipe = swipePower(offset.x, velocity.x);
                if (
                  horizontalSwipe < -swipeConfidenceThreshold &&
                  currPage < PAGES.length - 1
                ) {
                  setCurrPage(([prevPage]) => [prevPage + 1, 1]);
                } else if (
                  horizontalSwipe > swipeConfidenceThreshold &&
                  currPage > 0
                ) {
                  setCurrPage(([prevPage]) => [prevPage - 1, -1]);
                }
              }
            }}
          >
            {PAGES[currPage]}
          </SingleScreenWrapper>
          <Navigation
            currentPageIdx={currPage}
            pages={PAGES}
            onSkip={() => {
              onClose();
            }}
            onNext={() => setCurrPage(([prevPage]) => [prevPage + 1, 1])}
            onGetStarted={handleGetStarted}
          />
        </ScreensWrapper>
      </Backdrop>
    </AnimatePresence>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export default OnboardingScreens;
