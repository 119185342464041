import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Splash from 'resources/images/splash_living_room.jpg';

// Helpers
import localizer from 'localization/localizer';
import { capitalize, debounce, isShareCodeInvalid } from 'helpers';
import { shape } from 'prop-types';
import to from 'await-to-js';
import { motion } from 'framer-motion';
import useResize from 'use-resize';
import { ContentCollection, ShareItem } from '@prompto-api';

// Styling
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { showcaseContext } from '../../../../../main';

const MobileConnectPage = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState();
  const [height, setHeight] = useState('100%');
  const size = useResize();

  const {
    primary100,
    primary400,
    primary700,
    primary900,
    gray200,
    gray400,
    errorColor
  } = useContext(showcaseContext);

  const imageUri = Splash;

  useEffect(() => {
    setHeight(`${window.innerHeight * 0.01}px`);
  }, []);

  useEffect(() => {
    setInputValid(code?.length === 5);
  }, [code]);

  const onResize = debounce(() => {
    setHeight(`${window.innerHeight * 0.01}px`);
  }, 150);

  useEffect(() => {
    onResize();
  }, [size, onResize]);

  const onChange = (e) => {
    let value = e.target.value.replace(' ', '').toUpperCase();

    if (value.length > 5) {
      value = value.substring(0, 4) + value.charAt(5);
    }

    setError(null);
    setCode(value);
    e.target.value = value;
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmitCode();
    }
  };

  const onSubmitCode = async () => {
    if (!inputValid) {
      return;
    }

    const [shareItemError, shareItemResult] = await to(
      ShareItem.getByCode(code)
    );

    if (shareItemError) {
      setError(shareItemError);
    } else if (shareItemResult) {
      const shareItem = shareItemResult?.data?.shareItem;

      if (isShareCodeInvalid(shareItem)) {
        setError(true);
      } else {
        const [collectionError, collectionResult] = await to(
          ContentCollection.getByCode(code)
        );

        if (collectionError) {
          setError(collectionError);
        } else if (collectionResult) {
          window.scrollTo(0, 0);
          let link = `/${code}${window.location.search}`;
          navigate(link, { replace: true });
        }
      }
    }
  };

  // Styles
  let inputStyles = css`
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    border: none;
    outline: none;
    color: ${primary900};
    text-transform: uppercase;
    letter-spacing: 12px;
    transition: 0.3s;
    -user-select: all;
    -webkit-user-select: all;
    -webkit-user-select: text;
    -moz-user-select: all;
    -ms-user-select: all;

    &::placeholder {
      color: ${primary100};
      letter-spacing: 0px;
    }
  `;

  let inputButtonStyles = css`
    background: ${primary700};
    box-sizing: border-box;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    text-align: center;
    height: 40px;
    width: 125px;
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    margin-top: 10px;
    > p {
      margin: auto 0;
    }
  `;

  let inputWrapperStyles = css`
    position: relative;
    width: 100%;
    height: 70px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-flow: row;
    z-index: 10;
    transition: 0.3s;
  `;

  if (error) {
    inputStyles = css`
      ${inputStyles};
      color: ${errorColor};
    `;

    inputButtonStyles = css`
      ${inputButtonStyles};
      background-color: ${gray400} !important;
    `;

    inputWrapperStyles = css`
      ${inputWrapperStyles};
      border: 1px solid ${errorColor} !important;
    `;
  }

  if (!inputValid) {
    inputButtonStyles = css`
      ${inputButtonStyles};
      background-color: ${gray400} !important;
    `;
  }

  return (
    <div
      css={css`
        width: 100vw;
        height: 100%;
        height: calc((${height}, 1vh) * 100);
        display: flex;
        flex-flow: column;
      `}
    >
      {/* Image and text at the top */}
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-grow: 1;
        `}
      >
        <div
          css={css`
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
          `}
        >
          <div
            css={css`
              position: absolute;
              width: 120%;
              height: 120%;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            `}
            style={{
              backgroundImage: `url(${imageUri})`
            }}
            draggable={false}
          />
          <div
            css={css`
              position: absolute;
              background-color: black;
              opacity: 0.5;
              width: 100%;
              height: 100%;
            `}
          />
        </div>
        <div
          css={css`
            z-index: 5;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
          `}
        >
          <p
            css={css`
              font-size: 28px;
              line-height: 1.4;
              letter-spacing: 2.25px;
              text-align: center;
              color: white;
              margin: 0;
              margin-bottom: 35px;
              padding: 0 40px;
              text-transform: uppercase;
            `}
          >
            {localizer.exploreYourFutureHome}
          </p>
        </div>
      </div>

      {/* Actual page */}
      <div
        css={css`
          display: flex;
          flex-flow: column;
          align-items: center;
          position: relative;
          background: white;
          padding: 45px 0;
        `}
      >
        <p
          css={css`
            font-size: 14px;
            line-height: 1.75;
            text-align: center;
            color: ${primary400};
            margin: 0;
            margin-bottom: 30px;
            padding: 0 50px;
          `}
        >
          {localizer.exploreHomeDescription}
        </p>
        <div
          css={css`
            width: 100%;
            border: solid 1px ${gray200};
          `}
        >
          <div css={inputWrapperStyles}>
            <input
              type="text"
              css={inputStyles}
              placeholder={localizer.enterYourCode}
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          </div>
        </div>
        <motion.div
          css={css`
            font-size: 14px;
            color: ${errorColor};
            text-align: center;
            margin-top: 5px;
            padding: 0 50px;
          `}
          initial={{ opacity: 0 }}
          animate={error ? { opacity: 1 } : { opacity: 0 }}
        >
          {localizer.invalidCode}
        </motion.div>
        <button css={inputButtonStyles} onClick={onSubmitCode}>
          <p>{capitalize(localizer.viewNow)}</p>
        </button>
      </div>
    </div>
  );
};

MobileConnectPage.propTypes = {
  data: shape({})
};

MobileConnectPage.defaultProps = {
  data: {}
};

export default MobileConnectPage;
