import React, { useState } from 'react';

// Helpers
import { motion } from 'framer-motion';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 10px 105px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${(props) => props.theme.showcaseWhite};
  width: 160px;
  transition: width 200ms ease;
  &:hover {
    width: auto;
  }
`;

const Menu = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  transition: all 350ms ease;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ hoveredNavigation, active }) =>
    hoveredNavigation ? (active ? 1 : 0.875) : 0}rem;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  padding: ${({ hoveredNavigation }) => (hoveredNavigation ? '0 10px' : '0')};
  margin: ${({ hoveredNavigation }) => (hoveredNavigation ? '0' : '10px 5px')};
  color: ${({ theme, active, hoveredNavigation }) =>
    !hoveredNavigation
      ? 'transparent'
      : active
      ? theme.accentAlt500
      : theme.primary300};
  width: ${({ hoveredNavigation, active }) =>
    hoveredNavigation ? 'auto' : active ? '20px' : '10px'};
  height: ${({ hoveredNavigation }) => (hoveredNavigation ? '40px' : '2px')};
  background: ${({ theme, active, hoveredNavigation }) =>
    hoveredNavigation
      ? 'transparent'
      : active
      ? theme.accentAlt500
      : '#dfdee3'};
  transition: color 0s ease, height 0s ease, width 300ms ease,
    background 0s ease, font-size 320ms ease;
  &:hover {
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }
`;

const Tick = styled.div`
  width: 10px;
  height: 2px;
  background: ${({ theme }) => theme.accentAlt500};
  margin-right: 6px;
`;

const MenuTitle = styled(motion.div)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primary100};
  padding: 10px;
  white-space: nowrap;
`;

const FoldersNavigation = ({ folders, activeFolder, setActiveFolder }) => {
  const [navigationHovered, setNavigationHovered] = useState(false);

  const navTab = (folder, idx) => {
    const active = folder.uuid === activeFolder;
    const props = {
      key: folder.uuid,
      active,
      hoveredNavigation: navigationHovered,
      onClick: () => setActiveFolder(folder.uuid, idx)
    };

    return (
      <Tab {...props}>
        {active && navigationHovered && <Tick />}
        {folder.title}
      </Tab>
    );
  };
  return (
    <Wrapper
      initial={{ x: -200, opacity: 0 }}
      animate={{ x: -100, opacity: 1 }}
      exit={{ x: -200, opacity: 0 }}
      onMouseOver={() => setNavigationHovered(true)}
      onMouseLeave={() => setNavigationHovered(false)}
    >
      <MenuTitle
        initial={{ x: -200 }}
        animate={{ x: navigationHovered ? 0 : -200 }}
        transition={{ ease: 'linear', type: 'tween' }}
      >
        {localizer.goToFolders}
      </MenuTitle>
      <Menu>{folders.map(navTab)}</Menu>
    </Wrapper>
  );
};

export default FoldersNavigation;
