import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';
import { useUiState } from 'stores/UiStore';
import { capitalize } from 'helpers';

// Styling
import styled, { css } from 'styled-components';

const Button = styled(motion.button)`
  position: fixed;
  z-index: 120;
  top: 15px;
  left: 20px;
  border: none;
  border-radius: 4px;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
  color: ${({ theme }) => theme.showcaseWhite};
  cursor: pointer;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${isMobile &&
  css`
    left: 15px;
    top: 20px;
    padding: 0;
    background-color: ${({ theme }) => theme.showcaseWhite};
    color: ${({ theme }) => theme.primary300};
    backdrop-filter: none;
  `}
`;

const IconWrapper = styled(motion.div)`
  width: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ hovered }) => (hovered ? 10 : 0)}px;
  transition: all 100ms ease 100ms;
  font-size: ${isMobile ? 1.25 : 1.5}rem;
  ${isMobile &&
  css`
    width: 30px;
    height: 30px;
  `}
`;

const Text = styled(motion.div)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

const ShowcaseBackButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  // UI state
  const { UiState, UiStateDispatch } = useUiState();
  const { enableShowcaseBackButton, stackLength } = UiState;

  const navigate = useNavigate();
  const location = useLocation();
  const { code } = useParams();

  useEffect(() => {
    if (stackLength === 0) {
      UiStateDispatch({
        type: 'update',
        payload: {
          enableShowcaseBackButton: location?.pathname.includes('/unit/')
            ? true
            : false
        }
      });
    }
  }, [stackLength, UiStateDispatch, location]);

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (stackLength === 0 && location?.pathname.includes('/unit/')) {
        navigate(`/${code}/units`);
      } else if (stackLength > 0) {
        UiStateDispatch({
          type: 'decreaseStack'
        });
        navigate(-1);
      }
    },
    [navigate, UiStateDispatch, stackLength]
  );

  return (
    <AnimatePresence>
      {enableShowcaseBackButton && (
        <Button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClick}
        >
          <IconWrapper hovered={isHovered}>
            <FontAwesomeIcon icon={['fal', 'arrow-left']} size="1x" />
          </IconWrapper>
          <AnimatePresence>
            {isHovered && !isMobile && (
              <Text
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
              >
                {capitalize(localizer.back)}
              </Text>
            )}
          </AnimatePresence>
        </Button>
      )}
    </AnimatePresence>
  );
};

export default ShowcaseBackButton;
