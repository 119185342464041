import { useState, useEffect } from 'react';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import {
  addQueryParams,
  removeQueryParams,
  getCurrentEnvironment,
  fetchSettingsFromURL
} from 'helpers';
import Cookies from 'universal-cookie';

const useFavoriteUnitsList = () => {
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { shareCode, providedFavoritesList, userFavoritesList, project } =
    ProjectState;

  const [favoritesQueryParam, setFavoritesQueryParam] = useState('');
  const [areFavoritesFromQueryParamApplied, setFavoritesFromQueryParamApplied] =
    useState(false);

  const cookies = new Cookies();
  const favoriteUnitsCookie = `prompto/${
    getCurrentEnvironment().env
  }/${shareCode}`;

  // When page is rendered check if there is 'fav' query param presented.
  // Also check if the list of favorites is saved in cookies,
  // combine them and put in the Project State.
  // It should be done just once.
  useEffect(() => {
    if (areFavoritesFromQueryParamApplied) return;
    if (!shareCode) return;

    const savedFavorites = cookies.get(favoriteUnitsCookie);
    if (Array.isArray(savedFavorites)) {
      ProjectStateDispatch({
        type: 'setInitialUserFavorites',
        payload: savedFavorites
      });
    }

    const queryParams = fetchSettingsFromURL();
    const favoritesParam = queryParams?.fav;
    if (favoritesParam) {
      const favoritesFromQuery = [...favoritesParam.split(',')].filter(Boolean);
      if (Array.isArray(favoritesFromQuery)) {
        ProjectStateDispatch({
          type: 'setProvidedFavorites',
          payload: favoritesFromQuery
        });
      }
    }
    setFavoritesFromQueryParamApplied(true);
  }, [
    areFavoritesFromQueryParamApplied,
    shareCode,
    ProjectStateDispatch,
    cookies,
    favoriteUnitsCookie
  ]);

  // Update query params and respective cookie
  // on every change of favorites list
  // providedFavoritesList is ignored
  useEffect(() => {
    // we need the shareCode to be confident that we set correct cookie
    if (!shareCode) return;
    // set the cookie expiration date
    // otherwise it is set as session cookie
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 365); // 1 year in milliseconds
    cookies.set(favoriteUnitsCookie, userFavoritesList, {
      expires: date
    });
  }, [userFavoritesList, cookies, favoriteUnitsCookie, shareCode]);

  // use both userFavoritesList and providedFavorites
  // to form the favorite units list
  useEffect(() => {
    // combine favorites and remove duplications

    // filter out duplicated favorite units and the ones
    // which do not exist in current project
    const projectUnitIDs = project?.unitList?.map((unit) => unit.objectId);
    const totalFavorites = [...userFavoritesList, ...providedFavoritesList]
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter((unitId) =>
        projectUnitIDs?.length > 0 ? projectUnitIDs.includes(unitId) : true
      );
    setFavoritesQueryParam(totalFavorites.join(','));
    ProjectStateDispatch({
      type: 'setTotalFavorites',
      payload: totalFavorites
    });
  }, [userFavoritesList, providedFavoritesList, ProjectStateDispatch, project]);

  // add query params to url
  useEffect(() => {
    if (favoritesQueryParam) {
      addQueryParams([{ fav: favoritesQueryParam }]);
      // don't remove "fav" query param before its value was applied to Project State
    } else if (areFavoritesFromQueryParamApplied) {
      removeQueryParams([{ fav: '' }]);
    }
  }, [favoritesQueryParam, areFavoritesFromQueryParamApplied]);
};

export default useFavoriteUnitsList;
