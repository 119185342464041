import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import UnitStateBadge from 'components/unit/UnitStateBadge';
import ContactUs from 'components/share/ContactUs/ContactUs';
import FavoriteUnitButton from 'components/share/FavoriteUnitButton';

// Helpers
import { isMobile } from 'react-device-detect';
import currencySymbolMap from 'currency-symbol-map';
import {
  unitStatesNotArchived,
  shouldShowUnitPrice
} from 'helpers/units/VmUnitHelper';
import localizer from 'localization/localizer';
import { motion } from 'framer-motion';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import {
  capitalize,
  getFieldIcon,
  displayLocalizedValue,
  getUnitPrice
} from 'helpers';
import {
  useGetUnitImagePreview,
  useShowUnitGeneralFields,
  useGetUnitCustomFields
} from 'helpers/customHooks';

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const UnitCardWrapper = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-width: 330px;
  max-height: calc(100% - 30px);
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 21px;
`;

const UnitCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray300};
  background-color: ${(props) => props.theme.whitePure};
`;

const UnitInfo = styled.div`
  font-size: 0.875rem;
  color: black;
`;

const UnitTitle = styled(UnitInfo)`
  font-size: 1.5rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  word-break: break-word;
  flex-shrink: 0;
`;

const CenteredUnitTitle = styled(UnitTitle)`
  text-align: center;
`;

const UnitPriceStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

const UnitPrice = styled(UnitInfo)`
  font-weight: 500;
  color: ${(props) => props.theme.defaultBrandSecondaryColor};
  display: flex;
  align-items: center;
`;

const Price = styled(UnitInfo)`
  font-weight: 700;
  padding-left: 5px;
  color: inherit;
`;

const UnitMetaInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: ${(props) => props.flexBasis}%;
`;

const UnitMetaInfo = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: auto;
  white-space: nowrap;
`;

const UnitMetaIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.showcaseBlack};
  opacity: 0.5;
  margin-right: 0.875rem;
  flex-shrink: 0;
  transform: rotate(0.03deg);
`;

const UnitStateTag = styled(UnitStateBadge)`
  position: absolute;
  left: 10px;
  top: -15px;
  font-size: 0.75rem;
  color: ${(props) => props.theme.showcaseWhite};
  border-radius: 20px;
  min-width: 5rem;
  height: 26px;
`;

const Divider = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${(props) => props.theme.gray200};
`;

const HDivider = styled(Divider)`
  width: 100%;
  height: 1px;
  flex-shrink: 0;
`;

const BlockWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.showcaseWhite};
`;

const MainInfoBlockWrapper = styled(BlockWrapper)`
  align-items: center;
  padding: 24px 20px 15px;
`;

const GeneralFieldsBlockWrapper = styled(BlockWrapper)`
  justify-content: center;
`;

const CustomFieldsBlockWrapper = styled(BlockWrapper)`
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
`;

const NoPreviewPlaceholder = styled.div`
  width: 80px;
  height: 80px;
  padding: 8px;
  box-sizing: border-box;
  margin-right: 15px;
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  font-size: 0.75rem;
  color: ${(props) => props.theme.fadeGray};
`;

const PreviewImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 2px;
  object-fit: cover;
  object-position: center;
`;

const UnitMainInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnitTypeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const UnitType = styled.div`
  height: 20px
  font-size: 0.875rem;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  word-break: break-word;
  flex-shrink: 0;
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 5px;
  color: ${(props) => props.theme.primary100};
`;

const StyledUnitTypeIcon = styled(StyledPriceIcon)``;
const StyledCustomFieldIcon = styled(StyledPriceIcon)`
  margin-top: 2px;
  flex-shrink: 0;
`;

const CustomFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${(props) => (props.first ? '0' : '12px')};
  word-break: break-word;
`;

const ExploreDetailsButton = styled.button`
  height: 70px;
  width: 100%;
  padding: 10px;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray200};
  background-color: ${(props) => props.theme.showcaseWhite};
  color: ${(props) => props.theme.primary400};
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  flex-shrink: 0;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: ${(props) => props.theme.primary400};
  margin-left: 11px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: ${(props) => props.theme.primary100};
  padding: 0;
`;

const CustomFieldTitle = styled.span`
  margin: 0 10px 0 0;
  color: ${(props) => props.theme.showcaseBlack};
  font-size: 0.75rem;
`;

const CustomFieldValue = styled(CustomFieldTitle)`
  margin: 0;
  line-height: 1.43;
`;

const CustomFieldValueParagraph = styled.p`
  color: ${(props) => props.theme.showcaseBlack};
  margin: 0;
  text-align: justify;
`;

const IconWrapper = styled.span`
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: -2px;
`;

const SimplifiedUnitCard = styled(UnitCard)`
  height: 100%;
  padding: 30px 15px 15px;
  box-sizing: border-box;
  align-items: center;
`;

const ShowDetailsButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.defaultBrandPrimaryColor};
  border-radius: 2px;
  color: ${(props) => props.theme.showcaseWhite};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  letter-spacing: normal;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  word-break: break-word;
`;

const MobileUnitPreview = ({ unit, onClose, onExploreDetailsClick }) => {
  const [customFields, setCustomFields] = useState([]);

  const { ProjectState } = useProjectState();
  const { AuthState } = useAuthState();

  const { showcaseConfiguration } = ProjectState;

  const { previewImageUrl } = useGetUnitImagePreview(unit, null, `h=80`);
  const { showPrice, showSurface, showBedrooms, showBathrooms } =
    useShowUnitGeneralFields(true, null, [unit]);
  const showUnitGenericFields = showSurface || showBedrooms || showBathrooms;

  const showUnitPrice = shouldShowUnitPrice(
    ProjectState?.showcaseConfiguration,
    AuthState,
    unit?.state
  );

  const showUnitState =
    ProjectState?.showcaseConfiguration?.showUnitState ?? true;

  const currencySymbol = currencySymbolMap(
    ProjectState?.vault?.vaultSettings?.defaultCurrencyCode || 'EUR'
  );
  const unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;

  const renderImagePreviewOrPlaceholder = useCallback(() => {
    if (previewImageUrl) {
      return <PreviewImage src={previewImageUrl} />;
    }
    return (
      <NoPreviewPlaceholder>
        {capitalize(localizer.noAttachmentForUnit)}
      </NoPreviewPlaceholder>
    );
  }, [previewImageUrl]);

  const renderGeneralFields = (showcaseConfiguration) => {
    const metaInfo = [];
    if (showSurface) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'surface', 'fal'),
        info: `${unit.unitMetadata?.surface} m²`
      });
    }
    if (showBedrooms !== false) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBedrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBedrooms
      });
    }
    if (showBathrooms) {
      metaInfo.push({
        icon: getFieldIcon(showcaseConfiguration, 'numberOfBathrooms', 'fal'),
        info: unit.unitMetadata?.numberOfBathrooms
      });
    }
    const flexBasis = 100 / metaInfo.length;
    return metaInfo.map((item, idx) => {
      const needDivider = metaInfo.length > 1 && idx !== 0;
      return (
        <UnitMetaInfoWrapper key={idx} flexBasis={flexBasis}>
          {needDivider && <Divider />}
          <UnitMetaInfo>
            {!!item.icon && <UnitMetaIcon icon={item.icon} size="1x" />}
            <UnitInfo>{item.info}</UnitInfo>
          </UnitMetaInfo>
        </UnitMetaInfoWrapper>
      );
    });
  };

  const renderCustomFields = useCallback(() => {
    if (customFields?.length === 0) return null;

    return (
      <CustomFieldsBlockWrapper>
        {customFields?.map((field, idx) => (
          <CustomFieldWrapper first={idx === 0} key={idx}>
            {field.icon && (
              <IconWrapper>
                <StyledCustomFieldIcon icon={['far', field.icon]} size="1x" />
              </IconWrapper>
            )}
            {field.title && (
              <CustomFieldTitle>{capitalize(field.title)}:</CustomFieldTitle>
            )}
            {field.showAsParagraph ? (
              <CustomFieldValueParagraph>
                {field.value}
              </CustomFieldValueParagraph>
            ) : (
              <CustomFieldValue>{field.value}</CustomFieldValue>
            )}
          </CustomFieldWrapper>
        ))}
      </CustomFieldsBlockWrapper>
    );
  }, [customFields]);

  const { customFields: unitCustomFields } = useGetUnitCustomFields(unit);

  useEffect(() => {
    if (unitCustomFields) {
      const filteredFields =
        unitCustomFields?.filter((field) => field.isVisibleInTourView) ?? [];
      setCustomFields(filteredFields);
    }
  }, [unitCustomFields]);

  return (
    <Backdrop
      onClick={() => {
        if (isMobile) return;
        onClose();
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <UnitCardWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {!!showcaseConfiguration?.enableSimplifiedUnitCard ? (
          <SimplifiedUnitCard>
            <CloseButton onClick={onClose}>
              <CloseIcon icon={['fal', 'times']} size="1x" />
            </CloseButton>
            <CenteredUnitTitle>{unit?.title}</CenteredUnitTitle>
            <ShowDetailsButton
              onClick={() => onExploreDetailsClick({ unitItem: unit })}
            >
              {localizer.showDetails}
            </ShowDetailsButton>
          </SimplifiedUnitCard>
        ) : (
          <UnitCard>
            {showUnitState && (
              <UnitStateTag unitState={unit?.state} unitFlow={unit?.unitFlow} />
            )}
            <CloseButton onClick={onClose}>
              <CloseIcon icon={['fal', 'times']} size="1x" />
            </CloseButton>

            <MainInfoBlockWrapper>
              {showcaseConfiguration?.showUnitPreviewImage &&
                renderImagePreviewOrPlaceholder()}
              <UnitMainInfo>
                <UnitTitle>{unit?.title}</UnitTitle>
                <UnitTypeAndPrice>
                  {unit?.unitType && (
                    <UnitType>
                      {unit?.unitType?.icon && (
                        <StyledUnitTypeIcon
                          icon={['far', unit.unitType.icon]}
                          size="1x"
                        />
                      )}
                      {displayLocalizedValue(unit?.unitType?.name?.textMap)}
                    </UnitType>
                  )}
                  {showUnitPrice && showPrice && (
                    <UnitPriceStatus>
                      <UnitPrice>
                        <StyledPriceIcon
                          icon={getFieldIcon(
                            showcaseConfiguration,
                            'price',
                            'fal'
                          )}
                          size="1x"
                        />
                        <Price>{unitPrice}</Price>
                      </UnitPrice>
                    </UnitPriceStatus>
                  )}
                </UnitTypeAndPrice>
              </UnitMainInfo>
              {!showcaseConfiguration?.hideFavouriteOptions && (
                <FavoriteUnitButton
                  unit={unit}
                  buttonSize={36}
                  wrapperStyles={`margin-left: auto; min-height: 60px; display: flex; align-items: center`}
                />
              )}
            </MainInfoBlockWrapper>

            {showUnitGenericFields && (
              <>
                <HDivider />
                <GeneralFieldsBlockWrapper>
                  {renderGeneralFields(showcaseConfiguration)}
                </GeneralFieldsBlockWrapper>
              </>
            )}

            {showcaseConfiguration?.showUnitCustomFields &&
              unit?.customFieldValues && (
                <>
                  <HDivider />
                  {renderCustomFields()}
                </>
              )}

            {showcaseConfiguration?.showUnitContactUsButton && (
              <>
                <HDivider />
                <BlockWrapper>
                  <ContactUs unit={unit} location="Tour page (mobile)" />
                </BlockWrapper>
              </>
            )}

            <ExploreDetailsButton
              onClick={() => onExploreDetailsClick({ unitItem: unit })}
            >
              {capitalize(localizer.exploreDetails)}
              <ArrowIcon icon={['fal', 'arrow-right']} size="1x" />
            </ExploreDetailsButton>
          </UnitCard>
        )}
      </UnitCardWrapper>
    </Backdrop>
  );
};

MobileUnitPreview.propTypes = {
  /** vmUnit object that will get its information displayed in this card */
  unit: PropTypes.shape({
    title: PropTypes.string,
    unitPrice: PropTypes.shape({
      price: PropTypes.number
    }),
    state: PropTypes.oneOf(unitStatesNotArchived),
    unitMetadata: PropTypes.shape({
      numberOfBedrooms: PropTypes.number,
      numberOfBathrooms: PropTypes.number
    })
  }).isRequired
};

export default MobileUnitPreview;
