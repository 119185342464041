import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import {
  getLocalizedTextForUnitState,
  getColorForUnitState
} from 'helpers/units/VmUnitHelper';

// Styling
import styled from 'styled-components';

const Badge = styled.div`
  // Fixed size because it should always have the same size no matter what the unit state is
  width: 6rem;
  height: 2rem;

  font-size: 0.95rem;
  font-family: 'proxima-nova' sans-serif;
  color: ${(props) => props.theme.showcaseWhite};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

  background-color: ${(props) =>
    getColorForUnitState(props.theme, props.unitState)};
`;

const UnitStateBadge = ({ unitState, className, unitFlow }) => {
  const isRentalFlow = unitFlow === 'rental';
  return (
    <Badge unitState={unitState} className={className}>
      {getLocalizedTextForUnitState(unitState, isRentalFlow).toUpperCase()}
    </Badge>
  );
};

UnitStateBadge.propTypes = {
  /** State of the unit to display in the badge. This will impact both text and color. */
  unitState: PropTypes.oneOf(['SOLD', 'AVAILABLE', 'IN_OPTION', 'DRAFT'])
    .isRequired,
  /** Extra styles to apply on the badge. This will be combined with the style already in it. */
  className: PropTypes.string
};

export default UnitStateBadge;
