import React, { useState } from 'react';
import { string } from 'prop-types';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import { motion, AnimatePresence } from 'framer-motion';

// Styling
import styled from 'styled-components';

const FadeWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const LoaderWrapper = styled(FadeWrapper)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const ImageWrapper = styled(FadeWrapper)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  filter: blur(${(props) => (props.blur ? '15px' : '0')});
`;

const IdealCloudinaryImage = ({ contentUri, disableFade }) => {
  const [isHdLoaded, setIsHdLoaded] = useState(false);

  const fadeProps = {
    variants: fadeVariant,
    initial: disableFade ? 'visible' : 'hidden',
    animate: 'visible',
    exit: disableFade ? 'visible' : 'hidden'
  };

  return (
    <FadeWrapper key={contentUri} id={contentUri} {...fadeProps}>
      {/* LOADER */}
      <AnimatePresence>
        {!isHdLoaded && (
          <LoaderWrapper id={'loader'} {...fadeProps}>
            <ShowcaseLoader />
          </LoaderWrapper>
        )}
      </AnimatePresence>

      <ImageWrapper
        id={'image'}
        {...fadeProps}
        animate={isHdLoaded ? 'visible' : 'hidden'}
      >
        <Image
          alt={`high_res image`}
          src={contentUri}
          onLoad={(e) => {
            setIsHdLoaded(true);
          }}
        />
      </ImageWrapper>
    </FadeWrapper>
  );
};

IdealCloudinaryImage.propTypes = {
  contentUri: string
};

IdealCloudinaryImage.defaultProps = {};

export default IdealCloudinaryImage;

const fadeVariant = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};
