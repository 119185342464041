import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// Styling
import styled from 'styled-components';

// Helpers
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import localizer from 'localization/localizer';
import { usePrevious } from '@prompto-helpers';
import { useUiState } from 'stores/UiStore';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import { useProjectState } from 'stores/ProjectStore';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.fadeGray};
  display: none;
  @media only screen and (min-width: 320px) and (max-width: 1023px) {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Disclaimer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  background-color: ${(props) => props.theme.showcaseWhite};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
`;

const allowedViewOnLandscape = (pathname, currentView) => {
  const isInMarketplace = pathname.includes('portfolio');
  let shouldRemove = true;
  if (isInMarketplace) {
    shouldRemove = currentView === 'map';
  } else {
    const allowedLandscapeViews = ['tour', 'map', 'turntable'];
    shouldRemove = allowedLandscapeViews.some((view) =>
      pathname.includes(view)
    );
  }
  return shouldRemove;
};

// ScreenOrientationDisclaimer is shown on mobile screens if orientation is landscape

const ScreenOrientationDisclaimer = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [originalScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const { isLandscape } = useMobileOrientation();

  const { pathname } = useLocation();

  // UI Store
  const { UiState, UiStateDispatch } = useUiState();
  const {
    navigationFloatingButtonsBottomPosition,
    shouldRemoveOrientationDisclaimer
  } = UiState;

  // Marketplace state
  const { MarketplaceState } = useMarketplaceState();
  const { currentView } = MarketplaceState;

  // Project store
  const { ProjectState } = useProjectState();
  const { project } = ProjectState;

  const windowSizeChanged = useCallback(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, []);

  const previousScreenSize = usePrevious(screenSize);

  // when a keyboard appers the screen width stays the same
  // but the height changes
  useEffect(() => {
    if (
      screenSize?.width === previousScreenSize?.width &&
      !allowedViewOnLandscape(pathname, currentView)
    ) {
      let shouldRemove = true;
      if (
        screenSize?.height ===
        Math.max(originalScreenSize.width, originalScreenSize.height)
      ) {
        shouldRemove = false;
      }
      UiStateDispatch({
        type: 'update',
        payload: { shouldRemoveOrientationDisclaimer: shouldRemove }
      });
    }
    // eslint-disable-next-line
  }, [screenSize, UiStateDispatch, pathname, currentView, originalScreenSize]);

  useEffect(() => {
    window.addEventListener('resize', windowSizeChanged);
    return () => window.removeEventListener('resize', windowSizeChanged);
  }, [windowSizeChanged]);

  useEffect(() => {
    let shouldRemove = allowedViewOnLandscape(pathname, currentView);
    UiStateDispatch({
      type: 'update',
      payload: { shouldRemoveOrientationDisclaimer: shouldRemove }
    });
  }, [
    pathname,
    UiStateDispatch,
    navigationFloatingButtonsBottomPosition,
    isLandscape,
    currentView,
    project,
    shouldRemoveOrientationDisclaimer
  ]);

  if (!(isLandscape && isMobileOnly)) return null;
  if (shouldRemoveOrientationDisclaimer) return null;

  return (
    <Wrapper>
      <Disclaimer>{localizer.usePortraitOrientation}</Disclaimer>
    </Wrapper>
  );
};

export default ScreenOrientationDisclaimer;
