import React, { useRef, useEffect, useState } from 'react';

// Components
import SearchField from 'components/other/SearchField';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SalesPersonToggle from '../SalesPersonToggle';
import ProjectCard from './ProjectCard';
import FoundLocations from './FoundLocations';

// Helpers
import localizer from 'localization/localizer';
import { isDarkColor } from 'helpers';
import { isMobile } from 'react-device-detect';
import { useMarketplaceState } from 'stores/MarketplaceStore';

// Styling
import styled, { withTheme } from 'styled-components';

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 31;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  flex-direction: column;
  transform-origin: ${isMobile ? 'top' : 'top left'};
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled(FlexBox)``;
const Content = styled(FlexBox)`
  align-items: center;
  max-height: calc(100vh - ${isMobile ? 160 : 200}px);
  overflow: auto;
  margin-top: 20px;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  padding: 0 ${isMobile ? 10 : 70}px 0 ${isMobile ? 50 : 70}px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${isMobile ? 80 : 120}px;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.gray300};
`;

const BackButton = styled.button`
  position: absolute;
  left: ${isMobile ? 10 : 30}px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const ArrowBackIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: #333;
`;

const ContactManagerWrapper = styled.div`
  width: 600px;
  max-width: 90vw;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const FoundItemsHeader = styled.div`
  margin-bottom: 20px;
  width: 600px;
  max-width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  span {
    margin: 0;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.primary100};
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0;
    font-family: ${({ theme }) => theme.mainFontFamily};
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) =>
      isDarkColor(theme.contactCardBg) ? theme.contactCardBg : theme.brand500};
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
`;

const ShowMoreButton = styled.button`
  height: 20px;
  border: none;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.mainFontFamily};
  color: ${({ theme }) =>
    isDarkColor(theme.contactCardBg) ? theme.contactCardBg : theme.brand500};
  cursor: pointer;
  flex-shrink: 0;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const NUMBER_OF_INITIALLY_SHOWN_PROJECTS = 4;

const SmartSearch = ({
  theme,
  hideSmartSearch,
  setShowSalesPersonModal,
  salesPerson,
  salesPersonEnabled,
  onSearchChange,
  searchValue,
  list,
  onShowcaseSelected,
  sessionToken,
  onGoToViewClick,
  mapInstance,
  showLocationOnMap,
  ...props
}) => {
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [numberOfLocations, setNumberOfLocations] = useState(0);

  // Marketplace state
  const { MarketplaceState } = useMarketplaceState();
  const { currentView } = MarketplaceState;

  const searchRef = useRef();
  const L = localizer.marketplace;

  let goToViewButtonText;
  switch (currentView) {
    case 'gallery':
      goToViewButtonText = L.viewInGallery;
      break;
    case 'cards':
      goToViewButtonText = L.viewInCards;
      break;
    default:
      goToViewButtonText = L.viewOnMap;
      break;
  }

  useEffect(() => {
    return searchRef.current?.focus();
  }, []);

  // filter out projects without a content collection.
  // they are dead to us.
  const projectsWithShowcases = list.filter(
    (project) => project.vmContentCollection
  );

  const moreProjectsToShow = Math.max(
    projectsWithShowcases.length - NUMBER_OF_INITIALLY_SHOWN_PROJECTS,
    0
  );

  const projectsToShow = showAllProjects
    ? projectsWithShowcases
    : projectsWithShowcases.slice(0, NUMBER_OF_INITIALLY_SHOWN_PROJECTS);

  return (
    <Wrapper {...props}>
      <Header>
        <SearchBarWrapper>
          <BackButton onClick={hideSmartSearch}>
            <ArrowBackIcon icon={['fal', 'arrow-left']} size="1x" />
          </BackButton>
          <SearchField
            ref={searchRef}
            canGrow={true}
            initValue={searchValue ?? ''}
            onChange={onSearchChange}
            placeholder={localizer.locationOrProject}
            disabled={false}
            inputFieldStyles={searchInputFieldStyles}
            searchIconStyles={searchIconStyles}
            wrapperStyles={searchWrapperStyles(theme)}
            clearIconStyles={searchClearIconStyles}
          />
        </SearchBarWrapper>
        {salesPersonEnabled && (
          <ContactManagerWrapper>
            <SalesPersonToggle
              salesPerson={salesPerson}
              onClick={() => setShowSalesPersonModal(true)}
              localizer={L}
            />
          </ContactManagerWrapper>
        )}
      </Header>
      <Content>
        <FoundItemsHeader>
          <span>
            {L.locationsNearby} ({numberOfLocations})
          </span>
        </FoundItemsHeader>
        {mapInstance && (
          <FoundLocations
            value={searchValue ?? ''}
            mapInstance={mapInstance}
            showLocationOnMap={showLocationOnMap}
            setNumberOfLocations={setNumberOfLocations}
          />
        )}
        <FoundItemsHeader>
          {projectsToShow.length > 0 ? (
            <>
              <span>
                {L.smartSearchProjectsFound} ({projectsWithShowcases.length})
              </span>
              <button onClick={onGoToViewClick}>{goToViewButtonText}</button>
            </>
          ) : (
            <ErrorMessage>{L.noMatchesFound}</ErrorMessage>
          )}
        </FoundItemsHeader>
        {projectsToShow.map((project) => (
          <ProjectCard
            key={project.objectId}
            project={project}
            onShowcaseSelected={onShowcaseSelected}
            sessionToken={sessionToken}
          />
        ))}
        {!!moreProjectsToShow && (
          <ShowMoreButton onClick={() => setShowAllProjects((prev) => !prev)}>
            {showAllProjects
              ? L.showLess
              : localizer.formatString(L.showMore, moreProjectsToShow)}
          </ShowMoreButton>
        )}
      </Content>
    </Wrapper>
  );
};

const searchWrapperStyles = (theme) => `
  height: ${isMobile ? 50 : 60}px;
  max-width: 600px;
  border-radius: ${isMobile ? 25 : 30}px;
  backdrop-filter: blur(10px);
  border: solid 1px ${theme.gray150};
  background-color: rgba(255, 255, 255, ${theme.controlsOpacity});
  padding: 13px 15px;
  box-sizing: border-box;
  justify-content: flex-start;
  #search-field-input-wrapper {
    flex-grow: 1 !important;
  }
`;

const searchInputFieldStyles = `
  background-color: transparent;
  color: black;
  font-size: ${isMobile ? 1 : 1.25}rem;
  font-weight: 500;
  text-align: left;
  &::placeholder {
    text-transform: none;
    letter-spacing: normal;
    font-size: ${isMobile ? 1 : 1.25}rem;
    font-weight: 400;
  }
`;

const searchIconStyles = `
  margin: 0 ${isMobile ? 5 : 10}px;
  font-size: ${isMobile ? 1 : 1.25}rem;
`;

const searchClearIconStyles = `
  font-size: ${isMobile ? 1 : 1.25}rem;
`;

export default withTheme(SmartSearch);
