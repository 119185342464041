import * as React from 'react';

function RotateAngleMarker() {
  return (
    <svg width="86" height="60" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-17)" fill="none" fillRule="evenodd">
        <rect width="120" height="120" rx="60" />
        <path
          d="M17.574 17.574c23.197-23.198 60.663-23.43 84.145-.696l.707.696L60 60 17.574 17.574z"
          fillOpacity=".15"
          fill="#635CF7"
          fillRule="nonzero"
        />
        <path
          d="M31.716 31.716c15.464-15.465 40.442-15.62 56.097-.464l.471.464L60 60 31.716 31.716z"
          fillOpacity=".2"
          fill="#635CF7"
          fillRule="nonzero"
        />
        <path
          d="M42.322 42.322c9.666-9.665 25.277-9.762 35.061-.29l.295.29L60 60 42.322 42.322z"
          fillOpacity=".2"
          fill="#635CF7"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default RotateAngleMarker;
