import { keyframes } from 'styled-components';

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.26);
  }
  28% { transform: scale(0.95); }
  46% { transform: scale(1.15); }
  64% { transform: scale(1); }
`;

export const coloredPulse = (color) => keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.26);
  }
  28% { transform: scale(0.95); background-color: ${color}; opacity: 0.9 }
  46% { transform: scale(1.15); }
  64% { transform: scale(1); }
`;
