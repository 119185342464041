import React, { useEffect, memo } from 'react';
import { bool, func, shape, array, string } from 'prop-types';

// Components
import Album360Content from './types/album360/Album360Content';
import ImageContent from './types/image/ImageContent';
import VideoContent from './types/video/VideoContent';
import { PdfViewer } from '@prompto-ui';
import Fullscreen360Viewer from './types/album360/Fullscreen360Viewer';
import TurntableViewer from './types/turntable/TurntableViewer';

// Helpers
import fileCategories from 'configs/SupportedFileCategories.json';

// Styles
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100%;
  background: black;
  z-index: 10;
`;

// TODO: Remove comment

const Page = memo((props) => {
  const {
    data,
    videoFallbackThumbnail,
    setIsDragEnabled,
    onNextPage,
    onPreviousPage,
    isDragEnabled,
    showGrid,
    transitioning,
    autoplay
  } = props;

  useEffect(() => {
    if (!isDragEnabled && data.contentItemType !== fileCategories.turntable) {
      setIsDragEnabled(true);
    }
    if (data.contentItemType === fileCategories.image360) {
      setIsDragEnabled(false);
    }
  }, [isDragEnabled, setIsDragEnabled, data]);

  const sharedProps = {
    ...props,
    key: data.objectId
  };

  // Content
  let content = <ImageContent {...sharedProps} />;
  switch (data.contentItemType) {
    default:
    case fileCategories.image: {
      content = <ImageContent {...sharedProps} />;
      break;
    }
    case fileCategories.video: {
      content = (
        <VideoContent
          fallbackThumbnail={videoFallbackThumbnail}
          autoplay={autoplay}
          transitioning={transitioning}
          data={data}
          showGrid={showGrid}
        />
      );
      break;
    }
    case fileCategories.document: {
      content = (
        <PdfViewer
          key={data.objectId}
          url={data.contentUri}
          objectId={data.objectId}
          onLoad={() => {}}
          onToggleSidebar={() => {}}
        />
      );
      break;
    }
    case fileCategories.album360: {
      content = <Album360Content {...sharedProps} />;
      break;
    }
    case fileCategories.image360: {
      content = (
        <Fullscreen360Viewer
          key={data.objectId}
          image360ContentItem={data}
          hideBackButton={true}
        />
      );
      break;
    }
    case fileCategories.turntable: {
      content = (
        <TurntableViewer
          key={data.objectId}
          turntableId={data.value}
          setIsDragEnabled={setIsDragEnabled}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          isDragEnabled={isDragEnabled}
        />
      );
      break;
    }
  }

  return <Wrapper key={data.objectId}>{content}</Wrapper>;
});

Page.propTypes = {
  allPages: array,
  autoplay: bool,
  data: shape({}).isRequired,
  onNextPage: func,
  switcherState: shape({}),
  switcherDispatch: func,
  videoFallbackThumbnail: string
};

Page.defaultProps = {
  allPages: [],
  autoplay: false,
  switcherDispatch: () => {},
  switcherState: null,
  onNextPage: () => {},
  videoFallbackThumbnail: null
};

export default Page;
