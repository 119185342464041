import React, { useEffect } from 'react';

// Components
import { motion } from 'framer-motion';
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import { RichTextRenderer } from '@prompto-ui';

// Styling
import styled from 'styled-components';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import localizer from 'localization/localizer';
import { capitalize, buildAddress, displayLocalizedValue } from 'helpers';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  background-color: white;
`;

const PageTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const InformationWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  overflow: auto;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  color: ${(props) => props.theme.primary100};
`;

const SectionText = styled.p`
  margin: 0;
  line-height: 1.25;
  color: ${(props) => props.theme.showcaseBlack};
  word-break: break-word;
  text-align: justify;
`;

const ProjectName = styled(SectionText)`
  font-size: 1.5rem;
  line-height: 1.25;
  word-break: break-all;
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.gray200};
  width: 100%;
  height: 1px;
  margin: 10px 0;
`;

const ProjectInformationPage = () => {
  const { ProjectState } = useProjectState();
  const { project } = ProjectState;
  const { UiStateDispatch } = useUiState();

  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: { showMenu: true }
    });
  }, [UiStateDispatch]);

  const projectTitle = displayLocalizedValue({
    en: project?.title,
    ...project?.localizedTitle?.textMap
  });
  const projectDescription = displayLocalizedValue({
    en: project?.description,
    ...project?.localizedDescription?.textMap
  });

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MobileLayoutHeader>
        <PageTitle>{localizer.projectInformation}</PageTitle>
      </MobileLayoutHeader>
      <InformationWrapper>
        <Section>
          <SectionTitle>{capitalize(localizer.project)}</SectionTitle>
          <ProjectName>{projectTitle}</ProjectName>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>{capitalize(localizer.address)}</SectionTitle>
          <SectionText>{buildAddress(project?.address)}</SectionText>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>{capitalize(localizer.description)}</SectionTitle>
          <SectionText>
            <RichTextRenderer richText={projectDescription} />
          </SectionText>
        </Section>
      </InformationWrapper>
    </Wrapper>
  );
};

export default ProjectInformationPage;
