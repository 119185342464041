import * as React from 'react';

function SvgMinusIcon(props) {
  return (
    <svg width={18} height={4} fill="none" {...props}>
      <path
        d="M16.5.636h-15a.682.682 0 00-.682.682v1.364c0 .376.305.682.682.682h15a.682.682 0 00.682-.682V1.318A.682.682 0 0016.5.636z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgMinusIcon;
