import React from 'react';

// Components
import ScreenWrapper from './ScreenWrapper';
import { RichTextRenderer } from '@prompto-ui';

// Helpers
import localizer from 'localization/localizer';
import { capitalize, getCurrentEnvironment, buildAddress } from 'helpers';
import {
  getLocalizedProjectDescription,
  getLocalizedProjectTitle
} from 'helpers/project/VmProjectSectionHelper';
import { isMobile } from 'react-device-detect';
import { AnimatePresence, motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Image = styled(motion.img)`
  flex-grow: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ProjectDescription = styled.div`
  height: ${(props) => (props.hasThumbnail ? 'calc(100% - 220px)' : '100%')};
  overflow: auto;
  padding: ${isMobile ? '20px 15px' : '20px 30px'};
  box-sizing: border-box;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-family: ${isMobile ? 'inherit' : 'open-sans'};
  font-size: ${isMobile ? '1rem' : '0.875rem'};
  font-weight: normal;
  margin: 0;
  color: ${(props) => props.theme.primary100};
`;

const SectionText = styled.p`
  font-family: ${isMobile ? 'inherit' : 'open-sans'};
  margin: 0;
  line-height: 1.25;
  color: ${(props) => props.theme.showcaseBlack};
  word-break: break-word;
  text-align: justify;
  font-size: 1rem;
`;

const ProjectName = styled(SectionText)`
  font-size: ${isMobile ? '1.5rem' : '1.75rem'};
  line-height: 1.5;
  margin-bottom: 10px;
  word-break: break-all;
`;

const PersonalGreetings = styled(motion.div)`
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.gray300};
  color: ${({ theme }) => theme.showcaseBlack};

  h3 {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.25;
    margin: 0 0 6px;
    word-break: break-word;
  }

  span {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const StartScreen = ({ project, customerName }) => {
  const hasThumbnail = !!project?.thumbnailUri;
  const customerNameTrimmed = customerName ? customerName.trim() : '';

  return (
    <ScreenWrapper firstScreen>
      <AnimatePresence>
        {hasThumbnail && (
          <Image
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: isMobile ? 220 : 360 }}
            transition={{ duration: 0.2, ease: 'linear' }}
            src={`${getCurrentEnvironment().baseImageUrl}/${
              project.thumbnailUri
            }`}
            alt={`project ${getLocalizedProjectTitle(project)} thumbnail`}
            height={isMobile ? 220 : 360}
          />
        )}
      </AnimatePresence>

      <ProjectDescription hasThumbnail={hasThumbnail}>
        <AnimatePresence>
          {customerNameTrimmed ? (
            <PersonalGreetings
              initial={{ opacity: 0, x: -40 }}
              animate={{ opacity: 1, x: 0 }}
            >
              <h3>
                {localizer.formatString(
                  localizer.onBoarding.greetingsName,
                  customerNameTrimmed
                )}
              </h3>
              <span>{localizer.onBoarding.greetingsText}</span>
            </PersonalGreetings>
          ) : null}
        </AnimatePresence>

        <ProjectName>{getLocalizedProjectTitle(project)}</ProjectName>
        <Section>
          <SectionTitle>{capitalize(localizer.address)}</SectionTitle>
          <SectionText>
            {project?.address ? buildAddress(project.address) : ''}
          </SectionText>
        </Section>
        <Section>
          <SectionTitle>{capitalize(localizer.description)}</SectionTitle>
          <SectionText>
            <RichTextRenderer
              richText={getLocalizedProjectDescription(project)}
              fontSize={'inherit'}
            />
          </SectionText>
        </Section>
      </ProjectDescription>
    </ScreenWrapper>
  );
};

export default StartScreen;
