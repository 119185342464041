import { useState, useEffect } from 'react';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';

const defaultTourPageSettings = {
  showPrice: false,
  showSurface: true,
  showBedrooms: true,
  showBathrooms: false
};

const defaultUnitsPageSettings = {
  showPrice: true,
  showSurface: true,
  showBedrooms: true,
  showBathrooms: true
};

const mapFieldToSetting = {
  price: 'showPrice',
  surface: 'showSurface',
  numberOfBedrooms: 'showBedrooms',
  numberOfBathrooms: 'showBathrooms'
};

const calculateVisibilityByPage = (
  isTourPage,
  showcaseConfiguration,
  field
) => {
  if (isTourPage) {
    let showField = true;
    switch (field) {
      case 'price':
        if (showcaseConfiguration?.showUnitPriceOnTour === false) {
          showField = false;
        }
        break;
      case 'surface':
        if (showcaseConfiguration?.showUnitSurfaceOnTour === false) {
          showField = false;
        }
        break;
      case 'numberOfBathrooms':
        if (showcaseConfiguration?.showUnitNumberOfBathroomsOnTour === false) {
          showField = false;
        }
        break;
      case 'numberOfBedrooms':
        if (showcaseConfiguration?.showUnitNumberOfBedroomsOnTour === false) {
          showField = false;
        }
        break;
      default:
    }
    return showField ?? defaultTourPageSettings[mapFieldToSetting[field]];
  } else {
    let showField;
    switch (field) {
      case 'price':
        if (showcaseConfiguration.showUnitPrice === false) {
          showField = false;
        }
        break;
      case 'surface':
        if (showcaseConfiguration.showUnitSurface === false) {
          showField = false;
        }
        break;
      case 'numberOfBathrooms':
        if (showcaseConfiguration.showUnitNumberOfBathrooms === false) {
          showField = false;
        }
        break;
      case 'numberOfBedrooms':
        if (showcaseConfiguration.showUnitNumberOfBedrooms === false) {
          showField = false;
        }
        break;
      default:
    }
    return showField ?? defaultUnitsPageSettings[mapFieldToSetting[field]];
  }
};

const GENERAL_FIELDS = [
  'price',
  'surface',
  'numberOfBedrooms',
  'numberOfBathrooms'
];

// General fields visibility is being controlled on several levels:
// 1. unit type - be it applied to a unit, it defines its general fields visibility
// 2. project - general fields switched off on the project level,
//              should not be visible even being switched on on the unit type level
const useShowUnitGeneralFields = (
  isTourPage = false,
  configuration = null,
  unitsToCheck = []
) => {
  const [fieldsVisibilityChecked, setFieldsVisibilityChecked] = useState(false);
  const [fieldsToShow, setFieldsToShow] = useState(
    isTourPage ? defaultTourPageSettings : defaultUnitsPageSettings
  );

  // ProjectState
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  // AuthState
  const { AuthState } = useAuthState();
  const { authenticated } = AuthState;

  useEffect(() => {
    if (fieldsVisibilityChecked) return;
    if (!showcaseConfiguration) return;
    if (unitsToCheck.length === 0) return;

    // by default we use a showcaseConfiguration of the current project
    // but when the hook is used outside the project we need to pass the configuration
    const config = configuration ?? showcaseConfiguration;

    if (config) {
      const updatedFieldsToShow = {};

      // check visibility being set on the project level
      GENERAL_FIELDS.forEach((field) => {
        if (config.showGeneralFieldSettings?.[field] === false) {
          updatedFieldsToShow[mapFieldToSetting[field]] = false;
        } else if (
          config.fieldVisibilitySettings?.[field] === 'onlyAdmins' &&
          !authenticated
        ) {
          updatedFieldsToShow[mapFieldToSetting[field]] = false;
        } else {
          updatedFieldsToShow[mapFieldToSetting[field]] =
            calculateVisibilityByPage(isTourPage, config, field);
        }
      });

      // check visibility being set on the unit type level
      if (unitsToCheck.length > 0) {
        GENERAL_FIELDS.forEach((field) => {
          const setting = mapFieldToSetting[field];

          // if field is switched off on the project level,
          // we ignore unit type settings
          if (updatedFieldsToShow[setting] !== false) {
            if (field === 'price') {
              updatedFieldsToShow.showPriceForProject = true;
            }
            const fieldIsHiddenForAllUnitsByUnitType = unitsToCheck.every(
              (unit) =>
                unit?.unitType?.showGeneralFieldSettings?.[field] === false
            );
            if (fieldIsHiddenForAllUnitsByUnitType) {
              updatedFieldsToShow[setting] = false;
              // we need hidePriceByUnitType in addition to hidePriceForProject due to UI
              // requirements in UnitListCard component
              if (field === 'price') {
                updatedFieldsToShow.showPriceForUnitType = false;
              }
            }
          }
        });
      }
      setFieldsVisibilityChecked(true);
      setFieldsToShow(updatedFieldsToShow);
    }
  }, [
    showcaseConfiguration,
    isTourPage,
    authenticated,
    configuration,
    unitsToCheck,
    fieldsVisibilityChecked
  ]);

  return fieldsToShow;
};

export default useShowUnitGeneralFields;
