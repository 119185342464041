import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as THREE from 'three';

// Helpers
import { initialize3DScene } from './helpers/ThreeJSHelper';
import { usePrevious } from '@prompto-helpers';
import isEqual from 'lodash.isequal';
import { getColorForUnitState } from 'helpers/units/VmUnitHelper';
import { isMobile } from 'react-device-detect';

// Styling
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

const MainViewer = styled.div`
  position: absolute;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const calculatePointer = (e, mainViewer, offset, leftOffset) => {
  const pointer = new THREE.Vector2();

  const viewerWidth = mainViewer.current.offsetWidth;
  const viewerHeight = mainViewer.current.offsetHeight;

  const diffX = leftOffset;
  const diffY = offset;

  const mouseInViewX = e.clientX - diffX;
  const mouseInViewY = e.clientY - diffY;

  pointer.x = (mouseInViewX / viewerWidth) * 2 - 1;
  pointer.y = -(mouseInViewY / viewerHeight) * 2 + 1;

  return pointer;
};

const ModelViewer = ({
  currentImageIdx = 0,
  cameraModel,
  turnNavItems = [],
  units = [],
  onShowLinkedUnit,
  onHideLinkedUnit,
  offset,
  leftOffset,
  theme,
  selectedUnit,
  hoveredUnitCard,
  setIs3DMaskHovered,
  onOpenUnitPage
}) => {
  // Three.js components
  const [sceneInititalized, setSceneInitialized] = useState(false);
  const [scene, setScene] = useState();

  const [camera, setCamera] = useState();
  const [orbitControls, setOrbitControls] = useState();

  const [renderer, setRenderer] = useState();
  const [labelRenderer, setLabelRenderer] = useState();

  const [eventsInit, setEventsInit] = useState(false);

  // Drawing
  const [pickableObjects, setPickableObjects] = useState([]);

  const mainViewer = useRef();

  const [mousePos, setMousePos] = useState(null);
  const [clickPos, setClickPos] = useState(null);
  const [currentPos, setCurrentPos] = useState();
  const previousCurrentPos = usePrevious(currentPos);

  const previousCurrentImageIdx = usePrevious(currentImageIdx);
  const [cameraAnimations, setCameraAnimations] = useState(null);
  const [intersect, setIntersect] = useState(null);
  const [raycaster, setRaycaster] = useState(null);
  const [hoveredPolygon, setHoveredPolygon] = useState();
  const previousHoveredPolygon = usePrevious(hoveredPolygon);

  const [linkedUnitsColorMap, setLinkedUnitsColorMap] = useState({});

  const onClick = (e) => {
    if (mainViewer.current) {
      const pointer = calculatePointer(e, mainViewer, offset, leftOffset);
      setClickPos(pointer);
      setMousePos({ x: e.clientX, y: e.clientY });
    }
  };

  const onClickUp = useCallback(
    (e) => {
      setClickPos(null);
      if (mainViewer.current) {
        const pointer = calculatePointer(e, mainViewer, offset, leftOffset);
        setCurrentPos(pointer);
        setMousePos({ x: e.clientX, y: e.clientY });
      }
    },
    [offset, leftOffset]
  );

  // Method bound to the pointermove event
  const onPointerMove = (e) => {
    if (mainViewer.current) {
      const pointer = calculatePointer(e, mainViewer, offset, leftOffset);
      setCurrentPos(pointer);
      setMousePos({ x: e.clientX, y: e.clientY });
    }
  };

  const animate = (timestamp) => {
    requestAnimationFrame(animate);
    labelRenderer.render(scene, camera);

    renderer.clear();
    renderer.render(scene, camera);
  };

  // Update cursor to hand on hover
  useEffect(() => {
    if (hoveredPolygon) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }
  }, [hoveredPolygon]);

  // rotate the model based on the currently shown 2D render
  useEffect(() => {
    if (!orbitControls) return;
    if (previousCurrentImageIdx && currentImageIdx === previousCurrentImageIdx)
      return;
    orbitControls.setExactCameraPosition(cameraAnimations, currentImageIdx);
  }, [
    currentImageIdx,
    previousCurrentImageIdx,
    orbitControls,
    cameraAnimations,
    scene
  ]);

  // open unit details on mask click
  useEffect(() => {
    if (isMobile) return;
    if (!raycaster) return;
    if (!clickPos) return;
    raycaster.setFromCamera(clickPos, camera);
    const clickableObjects = scene.children.filter(
      (x) => x.type === 'Mesh' && x.userData.clickable
    );
    const intersects = raycaster.intersectObjects(clickableObjects, false);
    const isClickableMesh =
      intersects[0]?.object.toJSON()?.object?.userData?.clickable;
    if (isClickableMesh) {
      const intersectJSON = intersects[0].object.toJSON();
      const intersect = intersectJSON?.object;
      if (intersect?.userData?.linkedItemId === selectedUnit?.objectId) {
        onOpenUnitPage(selectedUnit);
      }
    }
  }, [clickPos, raycaster, camera, scene, selectedUnit, onOpenUnitPage]);

  // hover interaction
  useEffect(() => {
    if (!raycaster) return;
    if (selectedUnit && isMobile) {
      setIntersect(null);
      return;
    }
    if (!currentPos || isEqual(currentPos, previousCurrentPos)) return;
    raycaster.setFromCamera(currentPos, camera);
    const clickableObjects = scene.children.filter((x) => x.type === 'Mesh');
    const intersects = raycaster.intersectObjects(clickableObjects, false);
    const isClickableMesh =
      intersects[0]?.object.toJSON()?.object?.userData?.clickable;
    if (isClickableMesh) {
      const intersectJSON = intersects[0].object.toJSON();
      const intersect = intersectJSON?.object;
      setIntersect(intersect);
    } else {
      setIntersect(null);
    }
  }, [
    camera,
    currentPos,
    pickableObjects,
    previousCurrentPos,
    raycaster,
    scene,
    selectedUnit
  ]);

  useEffect(() => {
    if (intersect) {
      if (!!intersect?.userData?.linkedItemId) {
        const canProcessNewIntersect =
          (!hoveredUnitCard &&
            !!intersect?.uuid &&
            intersect?.uuid !== previousHoveredPolygon &&
            !isMobile) ||
          (isMobile && !selectedUnit);
        if (canProcessNewIntersect) {
          setHoveredPolygon(intersect?.uuid);
          const timer = setTimeout(() => {
            onShowLinkedUnit(mousePos, intersect?.userData?.linkedItemId);
            clearTimeout(timer);
          }, 0);
          setIs3DMaskHovered(true);
          // highlight hovered mask and slightly show the others
          setPickableObjects((prev) => {
            const updProjects = [...prev].map((proj) => {
              if (intersect?.uuid !== proj?.uuid) {
                proj.material.color.set(0x0000ff);
                proj.material.colorWrite = false;
              } else {
                proj.material.color.set(linkedUnitsColorMap[proj.uuid]);
                proj.material.colorWrite = true;
              }
              return proj;
            });
            return updProjects;
          });
        }
      }
    }
  }, [
    intersect,
    mousePos,
    onHideLinkedUnit,
    onShowLinkedUnit,
    hoveredPolygon,
    previousHoveredPolygon,
    hoveredUnitCard,
    selectedUnit,
    setIs3DMaskHovered,
    linkedUnitsColorMap
  ]);

  useEffect(() => {
    if (!intersect && !hoveredUnitCard) {
      setHoveredPolygon('');
      onHideLinkedUnit();
      setIs3DMaskHovered(false);
      // show all masks again
      setPickableObjects((prev) => {
        const updProjects = [...prev].map((proj) => {
          proj.material.color.set(linkedUnitsColorMap[proj.uuid]);
          proj.material.colorWrite = true;
          return proj;
        });
        return updProjects;
      });
    }
  }, [
    intersect,
    hoveredUnitCard,
    onHideLinkedUnit,
    setIs3DMaskHovered,
    linkedUnitsColorMap
  ]);

  // Start animation loop
  useEffect(() => {
    if (labelRenderer && renderer && camera && scene) {
      animate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelRenderer, renderer, scene, camera]);

  // Initialize Three Js scene
  useEffect(() => {
    if (!sceneInititalized && mainViewer.current !== null) {
      const camAnimations = cameraModel.animation;
      const animation = {
        position: camAnimations.positions.reduce((a, c) => {
          const { x, y, z } = c;
          return [...a, [x, z, y]];
        }, []),
        quaternion: camAnimations.quaternions.reduce((a, c) => {
          const { w, x, y, z } = c;
          return [...a, [x, y, z, w]];
        }, [])
      };
      setCameraAnimations(animation);

      const width = mainViewer.current.offsetWidth;
      const height = mainViewer.current.offsetHeight;

      initialize3DScene(
        width,
        height,
        cameraModel,
        animation,
        (initializedData) => {
          const { nScene, PC, orbitControls, renderer, labelRenderer } =
            initializedData;
          setScene(nScene);
          setCamera(PC);

          setOrbitControls(orbitControls);

          setRenderer(renderer);
          setLabelRenderer(labelRenderer);
          mainViewer.current.appendChild(renderer.domElement);
          mainViewer.current.appendChild(labelRenderer.domElement);

          setRaycaster(new THREE.Raycaster());

          setSceneInitialized(true);
        }
      );
    }
  }, [sceneInititalized, mainViewer, cameraModel]);

  useEffect(() => {
    if (scene && turnNavItems.length > 0) {
      const objectsToPick = [];
      const colorMap = {};
      turnNavItems.forEach(({ position, itemSize, target }, idx) => {
        const unitId = target.targetValue;
        const linkedUnit = units?.find((x) => x.objectId === unitId);

        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          'position',
          new THREE.BufferAttribute(Float32Array.from(position), itemSize)
        );

        const material = new THREE.MeshBasicMaterial();
        const mesh = new THREE.Mesh(geometry, material);
        mesh.scale.set(0.975, 0.975, 0.975);

        if (linkedUnit) {
          const color = getColorForUnitState(theme, linkedUnit.state);
          colorMap[mesh.uuid] = color;
          mesh.material.color.set(color);
          mesh.userData = {
            linkedItemId: unitId,
            index: idx,
            clickable: true
          };
          objectsToPick.push(mesh);
        } else {
          mesh.material.color.set(0x0000ff);
          mesh.material.colorWrite = false;
          mesh.userData = {
            clickable: false
          };
        }
        // set render order to support proper occlusion
        mesh.renderOrder = idx * -1;

        scene.add(mesh);
      });
      setPickableObjects(objectsToPick);
      setLinkedUnitsColorMap(colorMap);
    }
  }, [scene, turnNavItems, theme, units]);

  // Update renderer after resize
  useEffect(() => {
    if (renderer) {
      renderer.setSize(
        mainViewer.current.offsetWidth,
        mainViewer.current.offsetHeight
      );
    }
  }, [renderer, mainViewer]);

  // Trigger once to bind to certain events
  useEffect(() => {
    if (!eventsInit && scene) {
      window.addEventListener('pointerdown', onClick, false);
      window.addEventListener('pointerup', onClickUp);
      window.addEventListener('pointermove', onPointerMove);
      setEventsInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsInit, scene]);

  const mainContent = (
    <div id="modelViewer">
      <MainViewer
        ref={mainViewer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </div>
  );

  return <Wrapper>{mainContent}</Wrapper>;
};

export default withTheme(ModelViewer);
