import React, { useState, useEffect, useCallback, memo } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import PortfolioLoader from './PortfolioLoader';
import PortfolioError from './PortfolioError';
import PortfolioEmpty from './PortfolioEmpty';
import PortfolioSlider from './PortfolioSlider';
import PortfolioToolbar from './PortfolioToolbar';
import PortfolioLoggedOut from './PortfolioLoggedOut';
import Div100vh from 'react-div-100vh';
import MapView from './mapView/MapView';
import PortfolioCardsView from './PortolioCardsView';
import SalesPersonModal from './SalesPersonModal';
import FullscreenButton from 'components/other/FullscreenButton';
import PortfolioWizard from './portfolioWizard/PortfolioWizard';
import SharePortfolioButton from './SharePortfolioButton';
import MobileNavBar from './MobileNavBar';
import LaunchWizardButton from './LaunchWizardButton';
import SmartSearch from './smartSearch/SmartSearch';

// Helpers
import { usePrevious } from '@prompto-helpers';
import { useFavoriteProjectsList } from 'helpers/customHooks';
import { AnimatePresence } from 'framer-motion';
import { useAuthState } from 'stores/AuthStore';
import { useMarketplaceState } from 'stores/MarketplaceStore';
import to from 'await-to-js';
import { filterProjects } from './PortfolioPageHelpers';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import Cookies from 'universal-cookie';
import {
  getCurrentEnvironment,
  fetchSettingsFromURL,
  addQueryParams,
  removeQueryParams,
  isDarkColor
} from 'helpers';
import localizer from 'localization/localizer';
import { ShareItem, Project, Vault } from '@prompto-api';

import SalesPersonAvatar from 'resources/images/sales_person_avatar.png';

// Styling
/** @jsxFrag React.Fragment */
import styled, { css, withTheme, ThemeProvider } from 'styled-components';

const StyledDiv100vh = styled(Div100vh)`
  overflow: hidden;
  width: 100vw;
  background-color: ${({ theme, bg }) => bg ?? theme.showcaseWhite};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: ${({ ismarketplaceuivisible }) =>
    isMobileOnly ? (ismarketplaceuivisible ? '80' : 0) : '90'}px;
  padding-bottom: ${({ withmobilemenu }) =>
    isMobileOnly && withmobilemenu ? '60' : '0'}px;
  box-sizing: border-box;
  transition: all 200ms ease;
`;

const PortfolioContent = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  place-items: center;
`;

const AdditionalButtons = styled.div`
  position: fixed;
  z-index: 5;
  margin: 0;
  bottom: 15px;
  right: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const view = {
  gallery: 'gallery',
  map: 'map',
  cards: 'cards'
};

export const loadingStatus = {
  success: 'success',
  loading: 'loading',
  failed: 'failed'
};

export const contentState = {
  loggedOut: 'loggedOut',
  loader: 'loader',
  error: 'error',
  emptyList: 'emptyList',
  emptyFavoritesList: 'emptyFavoritesList',
  content: 'content'
};

const cookies = new Cookies();
const showMarketplaceWizardCookie = `prompto/${
  getCurrentEnvironment().env
}/showMarketplaceWizard`;

const PortfolioPage = memo(({ theme }) => {
  const { AuthState } = useAuthState();
  const { useDeeplinks, user, authenticated } = AuthState;
  const { vaultId, sessionToken } = user;

  // Marketplace state
  const { MarketplaceState, MarketplaceStateDispatch } = useMarketplaceState();
  const {
    allProjects,
    projectsData,
    showFavorites,
    favoritesList,
    appliedFilters,
    selectedSortOption,
    filteredProjectIds: savedFilteredProjectIds,
    filter
  } = MarketplaceState;

  const [projectsFetched, setProjectsFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [endReached, setEndReached] = useState(false);

  const [list, setList] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [totalProjects, setTotalProjects] = useState(allProjects.length);

  const [searchValue, setSearchValue] = useState('');
  const previousSearchValue = usePrevious(searchValue);
  const previousSortOption = usePrevious(selectedSortOption);

  const [forceShowLoader, setForceShowLoader] = useState();

  const [currentVault] = useState(null);
  const [isPortfolioPublic, setIsPortfolioPublic] = useState(false);
  const [encodedVaultId, setEncodedVaultId] = useState('');
  const [decodedVaultId, setDecodedVaultid] = useState('');
  const [projectsView, setProjectsView] = useState(view.gallery);
  const [portfolioVisibilityChecked, setPortfolioVisibilityChecked] =
    useState(false);

  const [activeContent, setActiveContent] = useState();

  const [showPortfolioFilter, setShowPortfolioFilter] = useState(false);
  const [filteredProjectIds, setFilteredProjectIds] = useState([]);
  const [filteredProjectIdsApplied, setFilteredProjectIdsApplied] =
    useState(false);
  const previousfilteredProjectIds = usePrevious(filteredProjectIds);

  const [showSalesPersonModal, setShowSalesPersonModal] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [preShowWizard, setPreShowWizard] = useState(false);

  const [marketplaceSettings, setMarketplaceSettings] = useState();

  const [salesPersonEnabled, setSalesPersonEnabled] = useState(false);
  const [salesPerson, setSalesPerson] = useState(null);

  const [mapStreetViewEnabled, setMapStreetViewEnabled] = useState(false);
  const [satelliteViewEnabled, setSatelliteViewEnabled] = useState(false);
  const [mapInstance, setMapInstance] = useState();

  const [showMobileNavMenu, setShowMobileNavMenu] = useState(isMobileOnly);

  // smart search related
  const [smartSearchValue, setSmartSearchValue] = useState('');
  const previousSmartSearchValue = usePrevious(searchValue);
  const [showSmartSearch, setShowSmartSearch] = useState(false);
  const [chosenLocation, setChosenLocation] = useState(null);

  // projects statuses related
  const [shouldShowStatuses, setShouldShowStatuses] = useState(false);
  // projects with hidden statuses should not be displayed in the portfolio
  const [hiddenStatuses, setHiddenStatuses] = useState([]);

  const { isLandscape } = useMobileOrientation();

  let openInSameTab = marketplaceSettings?.openProjectsInSameTab;

  // check if project statuses should be shown
  useEffect(() => {
    if (!marketplaceSettings) return;

    const visibilitySettings =
      marketplaceSettings?.projectStatusesSettings?.visibilitySettings;
    if (visibilitySettings) {
      const showStatuses =
        (!!visibilitySettings.onlyAdmins && authenticated) ||
        (!!visibilitySettings.onlyNotAdmins && !authenticated);
      setShouldShowStatuses(showStatuses);
    }

    const hiddenStatusesSettings =
      marketplaceSettings?.projectStatusesSettings?.hiddenStatusesSettings;
    if (hiddenStatusesSettings) {
      const hiddenStatuses = [];
      Object.entries(hiddenStatusesSettings).forEach(([status, hidden]) => {
        if (hidden) {
          hiddenStatuses.push(status);
        }
      });
      if (hiddenStatusesSettings?.['DRAFT'] === undefined) {
        hiddenStatuses.push('DRAFT');
      }
      setHiddenStatuses(hiddenStatuses);
    } else {
      setHiddenStatuses(['DRAFT']);
    }
  }, [marketplaceSettings, authenticated]);

  // remove chosen location if search value is cleared
  useEffect(() => {
    if (!smartSearchValue && chosenLocation) {
      setChosenLocation(null);
    }
  }, [smartSearchValue, chosenLocation]);

  useEffect(() => {
    const isFewViewOptions =
      marketplaceSettings?.allowedViews &&
      marketplaceSettings.allowedViews.length < 2;
    const shouldHideMenu =
      !isMobileOnly ||
      isFewViewOptions ||
      (projectsView === view.map && isLandscape);
    setShowMobileNavMenu(!shouldHideMenu);
  }, [isLandscape, projectsView, marketplaceSettings]);

  // the saved marketplace UI state should be reset
  // when searching, sorting or filtering options are changed
  // or when switching between favorite projects and regular ones
  const resetSavedUiState = useCallback(() => {
    MarketplaceStateDispatch({
      type: 'resetSavedUiState'
    });
  }, [MarketplaceStateDispatch]);

  // apply saved filtered projects e.g. when coming back from a Showcase
  useEffect(() => {
    if (filteredProjectIdsApplied) return;
    setFilteredProjectIds(savedFilteredProjectIds);
    setFilteredProjectIdsApplied(true);
  }, [filteredProjectIdsApplied, savedFilteredProjectIds]);

  useEffect(() => {
    if (salesPerson?.cardBackgroundColor) {
      document.documentElement.style.setProperty(
        '--clusterBgColor',
        salesPerson.cardBackgroundColor
      );
      if (!isDarkColor(salesPerson.cardBackgroundColor)) {
        document.documentElement.style.setProperty(
          '--clusterTextColor',
          theme.primary300
        );
      }
    }
  }, [salesPerson, theme]);

  // get sales person information if respective setting is ON
  useEffect(() => {
    if (!portfolioVisibilityChecked) return;
    if (!salesPersonEnabled) return;
    if (salesPerson) return;

    const vaultObjectId = isPortfolioPublic ? decodedVaultId : vaultId;

    Vault.getContactPerson(vaultObjectId)
      .then((res) => {
        const contactPersonData = res.data;
        contactPersonData.role = contactPersonData.contactPerson?.role;
        contactPersonData.cardBackgroundColor =
          contactPersonData.contactPerson?.cardBackgroundColor;
        contactPersonData.profilePictureUri =
          contactPersonData.profilePictureUri
            ? `${getCurrentEnvironment().baseImageUrl}/${
                contactPersonData.profilePictureUri
              }`
            : '';
        contactPersonData.profilePicturePlaceholder = SalesPersonAvatar;
        const contacts = [];
        for (let key in contactPersonData) {
          if (contactPersonData[key]) {
            switch (key) {
              case 'email':
                contacts.push({
                  icon: 'envelope',
                  value: contactPersonData[key],
                  action: {
                    type: 'mailto',
                    label: localizer.marketplace.sendEmail
                  }
                });
                break;
              case 'phoneNumber':
                contacts.push({
                  icon: 'phone-alt',
                  value: contactPersonData[key],
                  action: isMobileOnly
                    ? { type: 'tel', label: localizer.marketplace.startCall }
                    : undefined
                });
                break;
              default:
            }
          }
        }
        setSalesPerson({
          ...contactPersonData,
          contacts
        });
      })
      .catch(() => {});
  }, [
    portfolioVisibilityChecked,
    vaultId,
    isPortfolioPublic,
    decodedVaultId,
    salesPersonEnabled,
    salesPerson
  ]);

  useFavoriteProjectsList();

  useEffect(() => {
    if (!filter) return;
    setEndReached(allProjects.length <= filter.offset + filter.limit);
  }, [filter, allProjects]);

  // get markeplace settings
  useEffect(() => {
    if (!portfolioVisibilityChecked) return;
    const vaultObjectId = isPortfolioPublic ? decodedVaultId : vaultId;
    const queryParams = fetchSettingsFromURL();
    Vault.getPortfolioSettings(vaultObjectId)
      .then((res) => {
        const settings = res.data?.vmMarketplaceSettings;
        const uiSettingsKey =
          window.self !== window.top
            ? 'embeddedModeSettings'
            : 'standardModeSettings';
        setMarketplaceSettings(
          settings
            ? {
                ...settings,
                uiSettings: settings[uiSettingsKey]
              }
            : {}
        );

        // check if the view query param is presented
        // but the view it holds is no longer allowed
        let viewQueryParam;
        if (
          !!queryParams.view &&
          settings.allowedViews?.includes(queryParams.view)
        ) {
          viewQueryParam = queryParams.view;
        } else {
          removeQueryParams([{ view: '' }]);
        }

        const nextView =
          (viewQueryParam || settings?.defaultView) ?? view.gallery;
        setProjectsView(nextView);
        MarketplaceStateDispatch({
          type: 'setMarkerplaceData',
          payload: {
            currentView: nextView
          }
        });

        if (!!settings.enableContactPerson) {
          setSalesPersonEnabled(true);
        }
      })
      .catch(() => {
        // need to define the next view even if settings weren't fetched
        const nextView = queryParams.view || view.gallery;
        setProjectsView(nextView);
        MarketplaceStateDispatch({
          type: 'setMarkerplaceData',
          payload: {
            currentView: nextView
          }
        });
        setMarketplaceSettings({});
      });
  }, [
    portfolioVisibilityChecked,
    isPortfolioPublic,
    decodedVaultId,
    vaultId,
    MarketplaceStateDispatch
  ]);

  // get all projects
  useEffect(() => {
    // avoid unnecessary call if data was previously fetched
    if (allProjects.length > 0) {
      setProjectsFetched(true);
      return;
    }

    const vaultObjectId = isPortfolioPublic ? decodedVaultId : vaultId;
    Vault.getFeatureFlags(vaultObjectId)
      .then((response) => {
        const featureFlags = response?.data?.vaultFeatureFlagList;
        // check if portfolio feature is available for current vault
        if (featureFlags && !featureFlags.includes('portfolio')) {
          setProjectsFetched(true);
          setError(true);
        } else {
          if (!portfolioVisibilityChecked) return;
          if (fetching || projectsFetched || error) return;

          setFetching(true);
          setError(false);

          const requestParams = isPortfolioPublic
            ? [
                decodedVaultId,
                {
                  checkIfPortfolioIsPublic: true,
                  includeUnitList: true,
                  includeUnitType: true,
                  includeShowcaseConfiguration: true
                },
                ''
              ]
            : [
                vaultId,
                {
                  includeUnitList: true,
                  includeUnitType: true,
                  includeShowcaseConfiguration: true
                },
                sessionToken
              ];

          Project.getAll(...requestParams)
            .then((result) => {
              const projects = result?.data?.projectSectionList ?? [];
              MarketplaceStateDispatch({
                type: 'setMarkerplaceData',
                payload: {
                  projects,
                  allProjects: projects,
                  marketplaceInitialized: true
                }
              });
              setProjectsFetched(true);
              setFetching(false);
            })
            .catch((error) => {
              setError(true);
              setFetching(false);
            });
        }
      })
      .catch(() => {
        setProjectsFetched(true);
        setError(true);
      });
  }, [
    vaultId,
    sessionToken,
    allProjects,
    isPortfolioPublic,
    decodedVaultId,
    projectsFetched,
    fetching,
    portfolioVisibilityChecked,
    error,
    MarketplaceStateDispatch
  ]);

  // filter projects
  useEffect(() => {
    if (filteredProjectIds !== previousfilteredProjectIds) {
      setForceShowLoader(true);
    }

    const filteredByStatus = showFavorites
      ? allProjects.filter((pr) => favoritesList.includes(pr.objectId))
      : allProjects;

    const { fullProjectsList, projects, endReached, totalProjects } =
      filterProjects(
        filteredByStatus,
        filter,
        filteredProjectIds,
        hiddenStatuses
      );

    setFullList([...fullProjectsList]);
    setList(projects);
    setEndReached(endReached);
    setTotalProjects(totalProjects);

    setTimeout(() => {
      setForceShowLoader(false);
    }, 500);
  }, [
    allProjects,
    filter,
    filteredProjectIds,
    previousfilteredProjectIds,
    favoritesList,
    showFavorites,
    hiddenStatuses
  ]);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const splittedPath = pathname.split('/').filter(Boolean).reverse();
    if (splittedPath?.length > 1) {
      const [encodedVaultId] = splittedPath;
      setEncodedVaultId(encodedVaultId);
      setIsPortfolioPublic(true);
      setDecodedVaultid(window.atob(decodeURIComponent(encodedVaultId)));
    }
    setPortfolioVisibilityChecked(true);
  }, [pathname]);

  useEffect(() => {
    if (projectsData) return;
    if (allProjects?.length > 0) {
      const filteredByStatus = showFavorites
        ? allProjects.filter((pr) => favoritesList.includes(pr.objectId))
        : allProjects;

      const filteredByState = filteredByStatus.filter(
        (x) => !hiddenStatuses.includes(x.state)
      );

      const projectsData = filteredByState.reduce(
        (all, project) => ({
          ...all,
          [project.objectId]: {
            state: project.state,
            units: project.unitList?.filter(
              (unit) => unit.state !== 'ARCHIVED' && unit.state !== 'DRAFT'
            ),
            configuration: project.showcaseConfiguration
          }
        }),
        {}
      );

      MarketplaceStateDispatch({
        type: 'setMarkerplaceData',
        payload: {
          projectsData
        }
      });
    }
  }, [
    allProjects,
    showFavorites,
    favoritesList,
    projectsData,
    MarketplaceStateDispatch,
    hiddenStatuses
  ]);

  // Filter changed
  useEffect(() => {
    if (
      previousSearchValue !== searchValue ||
      previousSortOption !== selectedSortOption
    ) {
      setForceShowLoader(true);
      const { sortField, sortAsc } = selectedSortOption;
      MarketplaceStateDispatch({
        type: 'setMarkerplaceData',
        payload: {
          filter: {
            ...filter,
            searchValue: searchValue.trim(),
            sortField,
            sortAsc
          }
        }
      });
      setTimeout(() => {
        setForceShowLoader(false);
      }, 500);
    }
  }, [
    filter,
    previousSearchValue,
    searchValue,
    previousSortOption,
    selectedSortOption,
    MarketplaceStateDispatch
  ]);

  useEffect(() => {
    let activeContent;
    if (
      portfolioVisibilityChecked &&
      !isPortfolioPublic &&
      (!user.sessionToken || !user.vaultId)
    ) {
      activeContent = contentState.loggedOut;
    } else if (
      forceShowLoader ||
      (list.length === 0 && fetching) ||
      (list.length === 0 && !error && !endReached)
    ) {
      activeContent = contentState.loader;
    } else if (error) {
      activeContent = contentState.error;
    } else if (list.length === 0 && !error && !fetching && endReached) {
      activeContent = showFavorites
        ? contentState.emptyFavoritesList
        : contentState.emptyList;
    } else {
      activeContent = contentState.content;
    }
    setActiveContent(activeContent);
  }, [
    user,
    list,
    fetching,
    error,
    endReached,
    forceShowLoader,
    isPortfolioPublic,
    portfolioVisibilityChecked,
    showFavorites
  ]);

  // Load more projects if possible
  const loadNext = () => {
    MarketplaceStateDispatch({
      type: 'setMarkerplaceData',
      payload: {
        filter: {
          ...filter,
          offset: filter.offset + filter.limit
        }
      }
    });
  };

  // filter out projects without a content collection.
  // they are dead to us.
  const projectsWithShowcases = list.filter(
    (project) => project.vmContentCollection
  );

  const onShowcaseSelected = async (
    collectionId,
    navigationItemId,
    projectObjectId
  ) => {
    const createParams = {
      stringTargetType: 'showcaseItem',
      vaultObjectId: isPortfolioPublic ? decodedVaultId : vaultId,
      projectObjectId
    };

    if (navigationItemId) {
      createParams.navigationItemObjectId = navigationItemId;
    }

    let createError;
    let createResult;

    if (
      !isPortfolioPublic &&
      (!sessionToken || typeof sessionToken !== 'string')
    ) {
      createError = `Can't send createShareItem call: invalid sessionToken.`;
    } else {
      [createError, createResult] = await to(
        ShareItem.create(createParams, sessionToken)
      );
    }

    if (!createError) {
      const { shareCode } = createResult.data?.shareItem;
      addQueryParams([{ view: projectsView }]);

      const isLocalhost =
        window.location.host.match(/localhost/g) ||
        window.location.hostname.match(
          /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        );

      const isTestingLink =
        window.location.host.includes('ds-') &&
        window.location.host.includes('-dot-prompto-showcase');

      const showcaseUrl = `${
        isLocalhost || isTestingLink
          ? window.location.origin
          : getCurrentEnvironment().showcaseUrl
      }/${shareCode}${window.location.search}`;

      return showcaseUrl;
    }
  };

  // we use this handler to open new tabs in Mobile Safari
  const onShowcaseSelectedHandler = (
    collectionId,
    navigationItemId,
    projectObjectId
  ) => {
    if (openInSameTab) {
      onShowcaseSelected(collectionId, navigationItemId, projectObjectId).then(
        function (url) {
          if (url) {
            window.location.assign(url);
          }
        }
      );
    } else {
      const windowReference = window.open();
      onShowcaseSelected(collectionId, navigationItemId, projectObjectId).then(
        function (url) {
          if (url) {
            windowReference.location = url;
          }
        }
      );
    }
  };

  // Wizard
  const onCloseWizard = () => {
    setShowWizard(false);
    setPreShowWizard(false);
    const date = new Date();
    // 7 - week, 365 - year
    const wizardCookieValidity =
      marketplaceSettings?.wizardSettings?.hideWizardCookieValidity === 'week'
        ? 7
        : 365;
    date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * wizardCookieValidity);
    cookies.set(showMarketplaceWizardCookie, 'false', {
      expires: date
    });
  };

  // check if wizard should be displayed
  useEffect(() => {
    if (
      !marketplaceSettings ||
      marketplaceSettings.wizardSettings?.showWizard !== true
    )
      return;
    const showWizard = cookies.get(showMarketplaceWizardCookie);
    if (!showWizard) {
      setPreShowWizard(true);
      setShowWizard(true);
    }
  }, [marketplaceSettings]);

  const uiElementsAreVisible =
    !mapStreetViewEnabled &&
    (marketplaceSettings?.uiSettings?.showAdditionalUiElements ?? true);
  const shouldShowPrices = marketplaceSettings?.uiSettings?.showPrices ?? true;
  const showWizardLaunchButton =
    marketplaceSettings?.wizardSettings?.showWizard === true &&
    (marketplaceSettings?.wizardSettings?.showLaunchWizardButton ?? true);

  // Shared view props
  const viewSharedProps = {
    list: projectsWithShowcases,
    fullList: fullList,
    sessionToken: user.sessionToken,
    vaultSettings: currentVault?.settings,
    loadNext,
    endReached,
    onShowcaseSelected: onShowcaseSelectedHandler,
    projectsData,
    setShowSalesPersonModal,
    salesPersonEnabled: salesPersonEnabled && !!salesPerson,
    salesPerson,
    shouldShowPrices,
    showMobileNavMenu,
    shouldShowStatuses
  };

  return (
    <ThemeProvider
      theme={{
        ...theme,
        controlsOpacity: satelliteViewEnabled ? 0.9 : 0.7,
        contactCardBg: salesPerson?.cardBackgroundColor ?? theme.brand500
      }}
    >
      <StyledDiv100vh
        bg={marketplaceSettings?.uiSettings?.backgroundColor}
        ismarketplaceuivisible={uiElementsAreVisible ? 1 : 0}
        withmobilemenu={showMobileNavMenu ? 1 : 0}
      >
        {!showSmartSearch && marketplaceSettings && (
          <PortfolioToolbar
            key={'toolbar'}
            onSearchChange={(newSearchValue) => {
              if (newSearchValue !== smartSearchValue) {
                setSearchValue(newSearchValue);
                setSmartSearchValue(newSearchValue);
                resetSavedUiState();
              }
            }}
            onSearchClearDesktop={() => {
              setSmartSearchValue('');
            }}
            onSearchFocus={() => {
              setShowSmartSearch(true);
            }}
            useDeeplinks={useDeeplinks}
            selectedView={projectsView}
            setView={(view) => {
              setProjectsView(view);
              addQueryParams([{ view }]);
              MarketplaceStateDispatch({
                type: 'setMarkerplaceData',
                payload: {
                  currentView: view
                }
              });
            }}
            showPortfolioFilter={showPortfolioFilter}
            setShowPortfolioFilter={setShowPortfolioFilter}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSmartSearchValue={setSmartSearchValue}
            smartSearchValue={smartSearchValue}
            disableSort={
              activeContent === 'error' || activeContent === 'emptyList'
            }
            disableFilter={allProjects.length === 0}
            setSelectedSortOption={(opt) => {
              MarketplaceStateDispatch({
                type: 'setMarkerplaceData',
                payload: {
                  selectedSortOption: opt
                }
              });
            }}
            selectedSortOption={selectedSortOption}
            projectsData={projectsData}
            onSetFilteredProjectIds={setFilteredProjectIds}
            vaultId={vaultId}
            searchedProjects={searchValue ? totalProjects : 0}
            foundProjects={appliedFilters?.length > 0 ? totalProjects : 0}
            setShowSalesPersonModal={setShowSalesPersonModal}
            isVisible={uiElementsAreVisible}
            allowedViews={marketplaceSettings?.allowedViews}
            shouldShowPrices={shouldShowPrices}
            resetUiState={resetSavedUiState}
            salesPersonEnabled={salesPersonEnabled && !!salesPerson}
            salesPerson={salesPerson}
            setShowWizard={(flag) => {
              setPreShowWizard(flag);
              setShowWizard(flag);
            }}
            showWizardLaunchButton={showWizardLaunchButton}
            mapStreetViewEnabled={mapStreetViewEnabled}
            satelliteViewEnabled={satelliteViewEnabled}
            setShowSmartSearch={setShowSmartSearch}
            shouldShowStatuses={shouldShowStatuses}
          />
        )}

        <PortfolioContent>
          <AnimatePresence>
            {activeContent === contentState.loggedOut && (
              <PortfolioLoggedOut key={'loggedOut'} />
            )}
            {activeContent === contentState.loader && (
              <PortfolioLoader key={'loader'} />
            )}
            {activeContent === contentState.error && (
              <PortfolioError
                key={'error'}
                onTryAgain={() => {
                  setForceShowLoader(true);
                  MarketplaceStateDispatch({
                    type: 'setMarkerplaceData',
                    payload: {
                      filter: {
                        ...filter,
                        timeStamp: performance.now()
                      }
                    }
                  });

                  setTimeout(() => {
                    setForceShowLoader(false);
                  }, 1000);
                }}
                errorMessage={error.message}
              />
            )}
            {(activeContent === contentState.emptyList ||
              activeContent === contentState.emptyFavoritesList) && (
              <PortfolioEmpty key={'empty'} showFavorites={showFavorites} />
            )}

            {activeContent === contentState.content && (
              <>
                {projectsView === view.gallery && (
                  <PortfolioSlider
                    key={showFavorites ? 'favSlider' : 'slider'}
                    {...viewSharedProps}
                  />
                )}
                {projectsView === view.cards && (
                  <PortfolioCardsView key={'cards'} {...viewSharedProps} />
                )}
              </>
            )}
          </AnimatePresence>
        </PortfolioContent>

        {(!marketplaceSettings?.allowedViews ||
          (marketplaceSettings?.allowedViews?.length > 0 &&
            marketplaceSettings?.allowedViews?.includes(view.map))) && (
          <MapView
            key={'map'}
            show={projectsView === view.map}
            activeContent={activeContent}
            setMapStreetViewEnabled={setMapStreetViewEnabled}
            setSatelliteViewEnabled={setSatelliteViewEnabled}
            {...viewSharedProps}
            // map should display all the projects initially
            endReached={true}
            mapInstance={mapInstance}
            setMapInstance={setMapInstance}
            chosenLocation={chosenLocation}
          />
        )}

        {isPortfolioPublic && projectsFetched && uiElementsAreVisible && (
          <SharePortfolioButton
            encodedVaultId={encodedVaultId}
            view={projectsView}
          />
        )}

        <AnimatePresence>
          {showSalesPersonModal && (
            <SalesPersonModal
              onClose={() => setShowSalesPersonModal(false)}
              salesPerson={salesPerson}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showSalesPersonModal && (
            <SalesPersonModal
              onClose={() => setShowSalesPersonModal(false)}
              salesPerson={salesPerson}
            />
          )}
        </AnimatePresence>

        {showMobileNavMenu && (
          <MobileNavBar
            initial={{ y: 60, opacity: 0 }}
            animate={{
              y: showMobileNavMenu ? 0 : 60,
              opacity: showMobileNavMenu ? 1 : 0
            }}
            selectedView={projectsView}
            setView={(view) => {
              setProjectsView(view);
              addQueryParams([{ view }]);
              MarketplaceStateDispatch({
                type: 'setMarkerplaceData',
                payload: {
                  currentView: view
                }
              });
            }}
            allowedViews={marketplaceSettings?.allowedViews}
          />
        )}

        {!isMobileOnly && uiElementsAreVisible && marketplaceSettings && (
          <AdditionalButtons>
            {showWizardLaunchButton && (
              <LaunchWizardButton
                onClick={() => setShowWizard(true)}
                onHover={() => setPreShowWizard(true)}
                onUnhover={() => {
                  if (showWizard) return;
                  setPreShowWizard(false);
                }}
              />
            )}
            <FullscreenButton
              iconStyle={`
                color: ${theme.primary100},
                font-size: 22px;
              `}
              wrapperStyle={css`
                width: 50px;
                height: 50px;
                border-radius: 50%;
                backdrop-filter: blur(10px);
                border: solid 1px ${({ theme }) => theme.gray150};
                background-color: rgba(
                  255,
                  255,
                  255,
                  ${({ theme }) => theme.controlsOpacity}
                );
                display: flex;
                visibility: ${uiElementsAreVisible ? 'visible' : 'hidden'};
              `}
            />
          </AdditionalButtons>
        )}

        <PortfolioWizard
          preShow={preShowWizard}
          show={showWizard}
          onClose={onCloseWizard}
          shouldShowPrices={shouldShowPrices}
          applyFilters={(filteredProjectIds) => {
            setFilteredProjectIds(filteredProjectIds);
            if (isMobileOnly) {
              setShowPortfolioFilter(false);
            }
          }}
        />

        <AnimatePresence>
          {showSmartSearch && (
            <SmartSearch
              {...smartSearchAnimation}
              transition={{ duration: 0.25, type: 'tween' }}
              hideSmartSearch={() => setShowSmartSearch(false)}
              salesPerson={salesPerson}
              salesPersonEnabled={salesPersonEnabled}
              setShowSalesPersonModal={setShowSalesPersonModal}
              onSearchChange={(newSearchValue) => {
                if (newSearchValue !== previousSmartSearchValue) {
                  setSmartSearchValue(newSearchValue);
                  setSearchValue(newSearchValue);
                }
              }}
              searchValue={smartSearchValue}
              list={fullList}
              onShowcaseSelected={onShowcaseSelectedHandler}
              sessionToken={user?.sessionToken}
              onGoToViewClick={() => setShowSmartSearch(false)}
              mapInstance={mapInstance}
              showLocationOnMap={(newLocation) => {
                // reset search value so that all projects are visible
                setShowSmartSearch(false);
                setSearchValue('');
                setChosenLocation(newLocation);
                setProjectsView(view.map);
              }}
            />
          )}
        </AnimatePresence>
      </StyledDiv100vh>
    </ThemeProvider>
  );
});

const smartSearchAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

PortfolioPage.propTypes = {};

PortfolioPage.defaultProps = {};

export default withTheme(PortfolioPage);
