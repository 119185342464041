import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Splash from 'resources/images/splash_living_room.jpg';
import Div100vh from 'react-div-100vh';

// Helpers
import { useAuthState } from 'stores/AuthStore';
import localizer from 'localization/localizer';
import to from 'await-to-js';
import { pingPrompto, isShareCodeInvalid } from 'helpers';
import { mq } from 'helpers/breakpoints';
import { ContentCollection, ShareItem } from '@prompto-api';

// Styling
import styled from 'styled-components';

const getInputButtonBackgroundColor = ({
  theme,
  inputValid,
  error,
  isHover
}) => {
  const { accentAlt300, gray400, accentAlt700, errorColor } = theme;

  if (error) {
    return errorColor;
  }
  if (!inputValid) {
    return gray400;
  }
  return isHover ? accentAlt700 : accentAlt300;
};

const Page = styled(Div100vh)`
  width: 100vw;
  padding: 40px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  grid-template-areas:
    '.'
    't'
    'c'
    '.';

  ${mq[2]} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      '. i'
      't i'
      'c i'
      '. i';
  }
  overflow: hidden;
`;

const Content = styled.div`
  grid-area: t;
  padding-left: 0px;
  ${mq[3]} {
    padding-left: 80px;
  }
`;

const Title = styled.p`
  font-size: 60px;
  font-weight: bold;
  color: black;
  text-align: left;
  margin: 40px 0;
  letter-spacing: 8.75px;
  font-family: 'proxima-nova';
`;

const Description = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 40px;
  font-family: 'proxima-nova';
`;

const InputButton = styled.button`
  width: 33%;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: ${(props) =>
    getInputButtonBackgroundColor({ ...props, isHover: false })} !important;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: 0.3s;

  &:hover {
    background-color: ${(props) =>
      getInputButtonBackgroundColor({ ...props, isHover: true })} !important;
  }
`;

const Input = styled.input`
  width: 67%;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  border: none;
  outline: none;
  color: ${(props) => props.theme.primary900};
  text-transform: uppercase;
  letter-spacing: 12px;
  transition: 0.3s;
  -user-select: all;
  -webkit-user-select: all;
  -webkit-user-select: text;
  -moz-user-select: all;
  -ms-user-select: all;

  &::placeholder {
    color: ${(props) => props.theme.primary100};
    letter-spacing: 0px;
  }
`;

const InputWrapper = styled.div`
  grid-area: c;
  position: relative;
  width: 100%;
  height: 100px;
  border-radius: 3px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px
    ${({ error, theme }) => (error ? theme.errorColor : 'rgba(0, 0, 0, 0.1)')} !important;
  display: flex;
  flex-flow: row;
  z-index: 10;
  transition: 0.3s;
  ${mq[3]} {
    margin-left: 80px;
  }
  ${mq[2]} {
    width: 125%;
  }
`;

const BackButton = styled.button`
  border: none;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.56px;
  line-height: 18px;
  opacity: 0.3;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  height: 40px;
  background: none;
  font-family: 'proxima-nova';
`;

const BackButtonLong = styled(BackButton)`
  display: flex;
  justify-content: left;
  align-items: center;

  ${mq[3]} {
    margin-left: 80px;
  }
  > p {
    margin: auto 0;
    margin-left: 20px;
  }
`;

const Image = styled.img`
  grid-area: i;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
  ${mq[2]} {
    display: block;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  bottom: -36px;
  width: 100%;
  font-size: 14px;
  color: ${(props) => props.theme.errorColor};
`;

const DesktopConnectPage = () => {
  const { AuthState } = useAuthState();
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState();
  const { useDeeplinks, user, authenticated } = AuthState;
  const imageUri = Splash;

  useEffect(() => {
    setInputValid(code?.length === 5);
  }, [code]);

  const onChange = (e) => {
    let value = e.target.value.replace(' ', '').toUpperCase();

    if (value.length > 5) {
      value = value.substring(0, 4) + value.charAt(5);
    }

    setError(null);
    setCode(value);
    e.target.value = value;
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmitCode();
    }
  };

  const onSubmitCode = async () => {
    if (!inputValid) {
      return;
    }

    const [shareItemError, shareItemResult] = await to(
      ShareItem.getByCode(code, user.sessionToken)
    );

    if (shareItemError) {
      setError(shareItemError);
    } else if (shareItemResult) {
      const shareItem = shareItemResult?.data?.shareItem;

      if (isShareCodeInvalid(shareItem)) {
        setError(true);
      } else {
        const [collectionError, collectionResult] = await to(
          ContentCollection.getByCode(code, user.sessionToken)
        );

        if (collectionError) {
          setError(collectionError);
        } else if (collectionResult) {
          let link = `/${code}${window.location.search}`;
          navigate(link, {
            replace: true
          });
        }
      }
    }
  };

  const onBackButton = () => {
    if (useDeeplinks) {
      pingPrompto([{ returnToProjects: true }]);
    } else {
      navigate(`/portfolio${window.location.search}`);
    }
  };

  return (
    <Page>
      {authenticated ||
        (useDeeplinks && (
          <BackButtonLong
            onClick={onBackButton}
            style={{ textDecoration: 'none' }}
          >
            <FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="lg" />
            <p>{useDeeplinks ? localizer.projects : localizer.portfolio}</p>
          </BackButtonLong>
        ))}

      <Content>
        <Title>{localizer.exploreYourFutureHome}</Title>
        <Description>{localizer.exploreHomeDescription}</Description>
      </Content>

      <InputWrapper error={error}>
        <Input
          type="text"
          placeholder={localizer.enterYourCode}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <InputButton
          onClick={onSubmitCode}
          inputValid={inputValid}
          error={error}
        >
          {localizer.viewNow}
        </InputButton>
        {error && <ErrorMessage>{localizer.invalidCode}</ErrorMessage>}
      </InputWrapper>

      <Image src={imageUri} alt="appartment" />
    </Page>
  );
};

DesktopConnectPage.propTypes = {};

DesktopConnectPage.defaultProps = {};

export default DesktopConnectPage;
