import localizer from 'localization/localizer';
import numeral from 'numeral';
import currencySymbolMap from 'currency-symbol-map';
import to from 'await-to-js';
import {
  getDDMMYYYDate,
  capitalize,
  displayLocalizedValue,
  filterUnitsByShowcaseConfiguration
} from 'helpers';
import defaultSortingOrder from 'configs/DefaultSortinfOrder';

// Logic to keep the desired currencycode
let currencyCode = 'EUR';
export const setCurrencyCode = (code) => {
  currencyCode = code;
};
export const getCurrencyCode = () => {
  return currencyCode;
};

export const unitStatesNotArchived = ['AVAILABLE', 'IN_OPTION', 'SOLD'];
export const unitSortableFields = [
  'title',
  'price',
  'surface',
  'numberOfBedrooms',
  'numberOfBathrooms',
  'state'
];
export const unitFilterOptions = [
  'price',
  'surface',
  'numberOfBedrooms',
  'numberOfBathrooms',
  'state'
];

/**
 * We need to store a double in the backend, we will localize the double when displaying it to the user
 * User can input anything with , and . and it will convert it to a double
 * @param {string} value
 */
export const convertUserNumberInputToBackendDouble = (value) => {
  // Format the value to the localized format
  let result = numeral(value).format();

  // Replace all the commas with a dot
  // Example: 1,200,300.00 becomes 1.200.300.00
  result = result.replace(/,/g, '.');

  // Remove all the spaces
  // Example 1 200.30 becomes 1200.30
  result = result.replace(/\s/g, '');

  // Replace all but last dots with nothing
  // Example: 1.200.300.00 becomes 1200300.00
  result = result.replace(/\.(?=.*\.)/gm, '');

  return result;
};

/**
 * Get the icon we want to use for a given unit field
 * @param {string} field
 */
export const getIconForUnitField = (field) => {
  switch (field) {
    default:
    case 'price':
      return ['far', 'coins'];
    case 'surface':
      return ['far', 'ruler-combined'];
    case 'numberOfBedrooms':
      return ['far', 'bed'];
    case 'numberOfBathrooms':
      return ['far', 'bath'];
    case 'state':
      return ['far', 'clipboard-check'];
  }
};

/**
 * Format the value of a unit field, for example the price should be displayed with the currency symbol
 * @param {string} value The value we want to format
 * @param {string} field The field of the unit this value is from
 */
export const formatUnitfieldValue = (value, field) => {
  switch (field) {
    default:
    case 'price':
      const currencySymbol = currencySymbolMap(currencyCode || 'EUR');
      const price = numeral(value).format();
      return `${currencySymbol} ${price}`;
    case 'surface':
      return `${numeral(value).format()} m²`;
    case 'numberOfBedrooms':
      return value;
    case 'numberOfBathrooms':
      return value;
  }
};

/**
 * Get the minimum and maximum values of a specific field over all the units given
 * @param {array} units The list with all the units to check in
 * @param {string} field The field for which we want the minimum and maximum values
 */
export const getMinAndMaxValuesForField = (units, field) => {
  if (!units || units.length === 0) {
    return { min: 0, max: 0 };
  }

  const firstUnitWithNonZeroValue = units.find((unit) =>
    getUnitField(unit, field)
  );

  if (!firstUnitWithNonZeroValue) return { min: 0, max: 0 };

  let minToDisplay = getUnitField(firstUnitWithNonZeroValue, field);
  let maxToDisplay = getUnitField(firstUnitWithNonZeroValue, field);

  let min = getUnitField(units[0], field);
  let max = getUnitField(units[0], field);

  units.forEach((unit) => {
    const fieldIsHiddenByUnitType =
      unit.unitType?.showGeneralFieldSettings?.[field] === false;
    if (!fieldIsHiddenByUnitType) {
      const value = getUnitField(unit, field);
      if (
        (value < minToDisplay && value > 0) ||
        (minToDisplay === 0 && value <= max)
      ) {
        minToDisplay = value;
      } else if (value > maxToDisplay) {
        maxToDisplay = value;
      }

      if (value < min) {
        min = value;
      } else if (value > max) {
        max = value;
      }
    }
  });

  return { min, max, minToDisplay, maxToDisplay };
};

/**
 * Get the color from the theme that matches the unit state
 * @param {Object} theme theme object created by using ThemeProvider of StyledComponent
 * @param {String} unitState 'state' field of the 'vmUnit' object. The values are: AVAILABLE, IN_OPTION, SOLD and ARCHIVED.
 *                            You can see the documentation here: https://devapiv2.vr-tual.media/asciidoc/index.html#_vmunit_model
 * @returns return the color as a string or magenta if the state is not one of the expected values
 */
export const getColorForUnitState = (theme, unitState) => {
  const colorOnError = theme.primary300;

  switch (unitState) {
    case 'AVAILABLE':
      return theme.unitAvailable;
    case 'IN_OPTION':
      return theme.unitInOption;
    case 'SOLD':
      return theme.unitSold;
    default:
      return colorOnError;
  }
};

/**
 *
 * @param {String} unitState 'state' field of the 'vmUnit' object. The values are: AVAILABLE, IN_OPTION, SOLD and ARCHIVED.
 *                            You can see the documentation here: https://devapiv2.vr-tual.media/asciidoc/index.html#_vmunit_model
 * @returns return a localized string for that unit state or an empty string if the state is ARCHIVED or invalid
 */
export const getLocalizedTextForUnitState = (unitState, isRentalFlow) => {
  switch (unitState) {
    case 'AVAILABLE':
      return isRentalFlow
        ? localizer.unitStateAvailableForRent
        : localizer.unitStateAvailable;
    case 'IN_OPTION':
      return localizer.unitStateInOption;
    case 'SOLD':
      return isRentalFlow ? localizer.unitStateRented : localizer.unitStateSold;
    default:
      return '';
  }
};

/**
 * Get the specified field in the given unit
 * @param {object} unit The unit to get the field from
 * @param {string} field The field we want
 */
export const getUnitField = (unit, field) => {
  if (!unit) return null;

  const fieldIsHiddenByUnitType =
    unit.unitType?.showGeneralFieldSettings?.[field] === false;
  if (fieldIsHiddenByUnitType) return null;

  switch (field) {
    default:
    case 'title':
    case 'state':
      return unit[field];
    case 'price':
      if (unit.priceToShow) {
        return unit.priceToShow[field];
      } else if (unit.unitPrice) {
        return unit.unitPrice[field];
      }
      return null;
    case 'surface':
    case 'numberOfBedrooms':
    case 'numberOfBathrooms':
      if (unit.unitMetadata) {
        return unit.unitMetadata[field];
      }
      return null;
  }
};

/**
 *
 * @param {Object} showcaseConfiguration The showcase configuration
 * @param {Object} authState The entire auth state
 * @returns returns true if the price should be hidden when shared, returns false otherwise
 */
export const shouldHidePriceWhenShared = (showcaseConfiguration, authState) => {
  const { authenticated } = authState;

  if (showcaseConfiguration) {
    const { hidePriceWhenShared } = showcaseConfiguration;
    if (hidePriceWhenShared !== null && !authenticated) {
      return hidePriceWhenShared;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 *
 * @param {Object} showcaseConfiguration The showcase configuration
 * @param {Object} unitState The state of the unit on which we check if the price should be hidden
 * @returns returns true if the price should be hidden when the unit is sold or in option, returns false otherwise
 */
export const shouldHidePriceWhenUnitIsSold = (
  showcaseConfiguration,
  unitState
) => {
  if (showcaseConfiguration) {
    const { hidePriceWhenUnitSold } = showcaseConfiguration;
    if (hidePriceWhenUnitSold !== null) {
      if (
        hidePriceWhenUnitSold &&
        (unitState === 'IN_OPTION' || unitState === 'SOLD')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 *
 * @param {Object} configuration The showcase configuration
 * @param {Object} authState The entire auth state
 * @param {Object} unitState The state of the unit on which we check if the price should be hidden
 * @returns returns true if the price should be shown, returns false otherwise
 */
export const shouldShowUnitPrice = (configuration, AuthState, unitState) => {
  return (
    !shouldHidePriceWhenShared(configuration, AuthState) &&
    !shouldHidePriceWhenUnitIsSold(configuration, unitState)
  );
};

/**
 * Go over all the units and their content items and only keep the items with state = 'published'
 * @param {Array} unitList
 */
export const keepOnlyPublishedContentForUnits = (unitList) => {
  const newList = unitList.map((unit) => {
    // The main content collection of the unit
    if (unit?.vmContentCollection?.vmContentItemList) {
      unit.vmContentCollection.vmContentItemList =
        unit.vmContentCollection.vmContentItemList.filter(
          (x) => x.contentItemState === 'published'
        );
    }

    // The linked content collection of the unit
    if (unit?.linkedContentCollection?.vmContentItemList) {
      unit.linkedContentCollection.vmContentItemList =
        unit.linkedContentCollection.vmContentItemList.filter(
          (x) => x.contentItemState === 'published'
        );
    }

    return unit;
  });

  return newList;
};

/**
 * Go over all the units and find the linked content items in the collection.
 * The units come with loaded content items, but the linked items are not loaded since we already have these items in the project collection.
 * @param {Array} unitList The list with units that might contain some unloaded linked content
 * @param {Object} contentCollection The collection to use to find the loaded version of the linked content
 */
export const addLinkedContentForUnits = (unitList, contentCollection) => {
  const newList = unitList.map((unit) => {
    if (unit?.linkedContentCollection?.vmContentItemList) {
      unit.linkedContentCollection.vmContentItemList =
        unit.linkedContentCollection.vmContentItemList.map((item) => {
          const foundInProject = contentCollection.vmContentItemList.find(
            (content) => content.objectId === item.objectId
          );

          if (foundInProject) {
            return foundInProject;
          }

          return item;
        });
    }
    return unit;
  });

  return newList;
};

/**
 * Does the important fields of the units contain the provided text
 * @param {{title: string, unitMetadata: string, state: string, unitPrice: {price: number}}} unit
 * @param {string} text
 * @returns {boolean}
 */
export const doesUnitContainText = (unit, text) => {
  if (!unit) return false;
  const lowerCaseText = text.toLocaleLowerCase();
  return (
    unit.title?.toLowerCase().includes(lowerCaseText) ||
    unit.unitPrice?.price.toString().includes(lowerCaseText) ||
    unit.unitMetadata?.surface.toString().includes(lowerCaseText)
  );
};

/**
 *
 * @param {array} units The units we want to filter
 * @param {array} filterValues The values we want to use for filtering
 * @param {array} ignoredFilters The values that should be ignored in specific view (eg. we filter units by buildings in Unit List page but not in Tour page)
 * @returns {array} Returns the filtered units
 */
export const filterUnits = (units, filterValues, ignoredFilters = []) => {
  const acceptedFilterValues = filterValues.filter(
    (x) => !ignoredFilters.includes(x.filterOption)
  );
  if (acceptedFilterValues.length === 0) {
    return units;
  }

  const filteredList = units.filter((unit) => {
    let unitIsAMatch = true;
    acceptedFilterValues.forEach((filter) => {
      const unitField = getUnitField(unit, filter.filterOption);

      if (['state'].includes(filter.filterOption)) {
        if (!filter.values.includes(unitField)) {
          unitIsAMatch = false;
        }
      } else if (
        Number(unitField) < filter.values.min ||
        Number(unitField) > filter.values.max
      ) {
        unitIsAMatch = false;
      }
    });

    return unitIsAMatch;
  });

  return filteredList;
};

/**
 * Filter the units with the search filter.
 * @param {{uuid: string}} units The unit list to search in
 * @param {string} searchFilter The search value to match on
 * @returns {{uuid: string}}[]} a new array of units containing only the ones that match the filter
 */
export const searchUnits = (units, searchFilter) => {
  return units.filter((unit) => doesUnitContainText(unit, searchFilter));
};

/**
 * Sort the unit based on the sortfield in ascending or descending order
 * @param {array} units The units to be sorted
 * @param {string} sortField The field we want to sort on
 * @param {boolean} isAscending If we need to sort in an ascending or descending order
 */
export const sortUnits = (units, sortField, isAscending) => {
  const sorted = units.sort((unitA, unitB) => {
    const a = getUnitField(unitA, sortField);
    const b = getUnitField(unitB, sortField);

    if (typeof a === 'string') {
      const compareResult = a.localeCompare(b);

      return isAscending ? compareResult : -1 * compareResult;
    }

    if (a < b) {
      return isAscending ? -1 : 1;
    }
    if (a > b) {
      return isAscending ? 1 : -1;
    }

    return isAscending;
  });

  return sorted;
};

/**
 * Create default filters for the units based on their data
 * By default we set the filters to the maximum values so all units are included
 * @param {array} units The units we have in this showcase
 * @returns {array} defaultFilters The default filters set
 */
export const createDefaultFiltersForUnits = (units) => {
  const filters = [];

  const mapOptionToDefaultValues = {
    state: unitStatesNotArchived
  };

  unitFilterOptions.forEach((option) => {
    if (!['state'].includes(option)) {
      const limits = getMinAndMaxValuesForField(units, option);
      filters.push({ filterOption: option, values: limits, limits: limits });
    } else {
      filters.push({
        filterOption: option,
        values: mapOptionToDefaultValues[option]
      });
    }
  });

  return filters;
};

export const getUnitCustomFields = async (
  unit,
  showcaseConfiguration,
  customFieldsMap,
  authState
) => {
  const { authenticated } = authState;
  const unitTypeRelatedCustomFields = unit.unitType?.customFieldTypeList.map(
    (x) => x.objectId
  );

  const validCustomFields = unitTypeRelatedCustomFields?.reduce(
    (validFields, fieldId) => {
      const validField = unit.customFieldValues?.find(
        (value) => value.customFieldTypeObjectId === fieldId
      );
      if (validField) {
        return [...validFields, validField];
      } else {
        return validFields;
      }
    },
    []
  );

  if (showcaseConfiguration?.showUnitCustomFields && validCustomFields.length) {
    const customFields = [];
    const customFieldTypes = [];
    validCustomFields.forEach((field) => {
      customFieldTypes.push(customFieldsMap[field.customFieldTypeObjectId]);
      customFields.push(field);
    });

    const getFieldValue = (field) => {
      let value = '';
      switch (field?.fieldType) {
        case 'customBoolean':
          value = field?.booleanValue ? localizer.yes : localizer.no;
          break;
        case 'date':
          value = field?.dateValue
            ? getDDMMYYYDate(field.dateValue)
            : undefined;
          break;
        default:
          value = field?.value;
          if (field?.localizableValue) {
            value =
              displayLocalizedValue(field?.localizableValue?.textMap) ??
              field?.value;
          }
      }
      return value;
    };

    customFieldTypes.forEach((field, index) => {
      if (field) {
        customFields[index].icon =
          field?.icon && field?.icon !== 'icon_value' ? field?.icon : undefined;
        customFields[index].fieldName =
          field?.isDisplayFieldName !== false
            ? displayLocalizedValue(field?.localizedFieldName?.textMap) ??
              field?.customFieldName
            : null;
        customFields[index].fieldType = field?.fieldType;
        customFields[index].isVisibleInTourView = field?.isVisibleInTourView;
        customFields[index].isVisibleInUnitList = field?.isVisibleInUnitList;
        customFields[index].isActive = field?.isActive;
        customFields[index].isVisibleOnlyToAdmins =
          field?.isVisibleOnlyToAdmins;
      }
    });

    return customFields
      .filter((field) => {
        if (field.isActive === false) return false;
        const visibleToAdminsOnly = !!field.isVisibleOnlyToAdmins;
        if (visibleToAdminsOnly && !authenticated) return false;

        return true;
      })
      .map((field, i) => {
        return {
          ...validCustomFields[i],
          icon: field?.icon,
          title: field?.fieldName,
          value: getFieldValue(field)
        };
      });
  } else {
    return [];
  }
};

/**
 * Create array of unit fields (generic and custom) based on unit data and showcase configuration
 * @param {{unitMetadata: {surface: string, numberOfBedrooms: string, numberOfBathrooms: string}, customFieldValues: array}} unit
 * @param {Object} showcaseConfiguration The showcase configuration
 * @param {boolean} showUnitDescription defines whether unit description should be displayed
 * @returns {array} unit fields to be rendered
 */
export const prepareUnitCustomFieldsForRender = async (
  unit,
  showcaseConfiguration,
  showUnitDescription,
  sessionToken,
  customFieldsMap,
  authState
) => {
  const fields = [];

  const [, customFields] = await to(
    getUnitCustomFields(unit, showcaseConfiguration, customFieldsMap, authState)
  );

  if (customFields) {
    fields.push(...customFields);
  }

  if (showUnitDescription && unit?.description) {
    const description =
      displayLocalizedValue(unit?.localizedDescription?.textMap) ??
      unit?.description;
    fields.push({
      icon: undefined,
      title: capitalize(localizer.description),
      value: description,
      showAsParagraph: true
    });
  }

  return fields;
};

/**
 * Localize unit field values if localization is enabled for current project
 * @param {Object} project The entire project data
 * @param {{unitMetadata: {reference: string, localizedReference: string, externalLink: string, localizedExternalLink: string}, title: string, description: string, localizedTitle: string, localizedDescription: string}} unit
 */
export const localizeUnitFieldValues = (project, unit) => {
  if (!unit) return;
  if (!project) return unit;
  const language = localizer.getLanguage();
  const localizedUnit = { ...unit };

  const localizedTitle =
    localizedUnit.localizedTitle?.textMap?.[language] ??
    localizedUnit.localizedTitle?.textMap?.['en'];
  if (localizedTitle) localizedUnit.title = localizedTitle;

  const localizedDescription =
    localizedUnit.localizedDescription?.textMap?.[language];
  if (localizedDescription) localizedUnit.description = localizedDescription;

  const localizedReference =
    localizedUnit.unitMetadata?.localizedReference?.textMap?.[language];
  if (localizedReference) {
    localizedUnit.unitMetadata.reference = localizedReference;
  }

  const localizedExternalLink =
    localizedUnit.unitMetadata?.localizedExternalLink?.textMap?.[language];
  if (localizedExternalLink) {
    localizedUnit.unitMetadata.externalLink = localizedExternalLink;
  }

  return localizedUnit;
};

export const sortBySortingOrders = (
  rawUnitContent,
  customOrder,
  defaultOrder = defaultSortingOrder
) => {
  if (customOrder && customOrder.length > 0) {
    const sortedItems = [];
    customOrder.forEach((itemObjectId) => {
      const contentItem = rawUnitContent.find(
        (item) => item.objectId === itemObjectId
      );
      if (contentItem) {
        sortedItems.push(contentItem);
      }
    });
    return sortedItems;
  } else if (defaultOrder) {
    const sortedItemsObj = defaultOrder.reduce(
      (sorted, curr) => ({ ...sorted, [curr]: [] }),
      {}
    );
    rawUnitContent.forEach((item) => {
      let category;
      switch (item.contentItemType) {
        case 'image360':
          category = item.isLinked ? 'image360Linked' : 'image360';
          break;
        case 'image':
          category = item.isLinked ? 'imageLinked' : 'image';
          break;
        case 'video':
          category = item.isLinked ? 'videoLinked' : 'video';
          break;
        case 'document':
          category = item.isLinked ? 'documentLinked' : 'document';
          break;
        default:
          category = item.contentItemType;
      }
      if (sortedItemsObj[category]) {
        sortedItemsObj[category].push(item);
      }
    });
    const sortedItems = Object.values(sortedItemsObj).reduce(
      (res, curr) => res.concat(...curr),
      []
    );
    return sortedItems;
  } else {
    return rawUnitContent;
  }
};

// filtering project units based on provided filters
export const filteredUnitListForProjectStore = ({
  unitList,
  allowedUnitsIdList,
  contentCollection,
  showcaseConfiguration
}) => {
  // filter out only allowed units
  if (allowedUnitsIdList?.length) {
    unitList = unitList.filter((unit) =>
      allowedUnitsIdList.includes(unit.objectId)
    );
  }

  // We add the linked content thats needed for all the units
  unitList = addLinkedContentForUnits(unitList, contentCollection);

  // Filter out non published content from the units
  unitList = keepOnlyPublishedContentForUnits(unitList);

  // Filter out units according to the showcase configuration
  unitList = filterUnitsByShowcaseConfiguration(
    unitList,
    showcaseConfiguration
  );

  // Filter out DRAFT units
  unitList = unitList.filter((unit) => unit.state !== 'DRAFT');

  return unitList;
};
