import React, { useState, useEffect, useCallback } from 'react';

// Components
import Turntable from 'components/pages/turntable/components/Turntable';
import Instruction from 'components/pages/turntable/components/Instruction';

// Helpers
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import Cookies from 'universal-cookie';
import { getCurrentEnvironment } from 'helpers';
import localizer from 'localization/localizer';
import { useNavigate } from 'react-router-dom';
import { Turntable as TurntableAPI } from '@prompto-api';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const cookies = new Cookies();
const hideTurntableInstructionCookie = `prompto/${
  getCurrentEnvironment().env
}/hideResourcesTurntableInstruction`;

const getTurntableImages = (turntable) => {
  const allImages = [];
  turntable.turnPointOfViewList.forEach((turnPoint) => {
    allImages.push(turnPoint?.contentItem?.contentUri);
  });
  return allImages.filter(Boolean);
};

const TurntableViewer = ({ turntableId, setIsDragEnabled, isDragEnabled }) => {
  const navigate = useNavigate();

  const { ProjectState } = useProjectState();
  const { project, showcaseConfiguration, shareCode, turntableList } =
    ProjectState;

  // UI Store
  const { UiStateDispatch } = useUiState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [turntable, setTurntable] = useState(null);
  const [turntableImages, setTurntableImages] = useState(null);

  const [showInstruction, setShowInstruction] = useState(true);

  useEffect(() => {
    const hideInstruction = cookies.get(hideTurntableInstructionCookie);
    if (hideInstruction) {
      setShowInstruction(false);
    }
  }, []);

  useEffect(() => {
    if (turntable || loading || error) return;

    const localTurntable = turntableList.find(
      (turntable) => turntable.objectId === turntableId
    );
    if (localTurntable) {
      setTurntable(localTurntable);
      setTurntableImages(getTurntableImages(localTurntable));
    } else {
      setLoading(true);
      TurntableAPI.get(turntableId, {
        includeTurnPointOfViewList: true
      })
        .then((res) => {
          setLoading(false);
          const turntable = res?.data?.vmTurnTable;
          setTurntable(turntable);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }
  }, [turntableId, turntable, loading, error, turntableList]);

  useEffect(() => {
    if (!turntable) return;
    if (turntableImages) return;
    if (turntable.turnPointOfViewList) {
      setTurntableImages(getTurntableImages(turntable));
    }
  }, [turntable, turntableImages]);

  const onOpenUnitPage = useCallback(
    (unit) => {
      const unitId = unit?.objectId;
      if (unitId) {
        UiStateDispatch({
          type: 'update',
          payload: { enableShowcaseBackButton: true }
        });
        UiStateDispatch({
          type: 'increaseStack'
        });

        navigate(`/${shareCode}/unit/${unitId}${window.location.search}`);
      }
    },
    [navigate, shareCode, UiStateDispatch]
  );

  const onLoaded = useCallback(() => {
    setIsDragEnabled(false);
  }, [setIsDragEnabled]);

  const onSwitchPage = useCallback(() => {
    setIsDragEnabled(true);
  }, [setIsDragEnabled]);

  const onRemoveInstruction = useCallback(() => {
    setShowInstruction(false);
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 7); // one week in milliseconds
    cookies.set(hideTurntableInstructionCookie, 'false', {
      expires: date,
      sameSite: 'none',
      secure: true
    });
  }, []);

  return (
    <Wrapper>
      {error && <div>{localizer.turntableError}</div>}
      {turntable && turntableImages && !error && !loading && (
        <Turntable
          turntable={turntable}
          images={turntableImages}
          units={project?.unitList}
          onOpenUnitPage={onOpenUnitPage}
          showcaseConfiguration={showcaseConfiguration}
          onLoaded={onLoaded}
          isInResources={true}
          switchPage={onSwitchPage}
          isDragEnabled={isDragEnabled}
          setIsDragEnabled={setIsDragEnabled}
          toggleInstruction={onRemoveInstruction}
        />
      )}
      {turntable && showInstruction && !error && (
        <Instruction removeInstruction={onRemoveInstruction} />
      )}
    </Wrapper>
  );
};

export default TurntableViewer;
