import React, { useReducer, createContext, useContext } from 'react';

// Helpers
import { getAuth, fetchSettingsFromURL } from 'helpers';

export const AuthStoreContext = createContext();

/**
 * Store containing the information of the currently logged in user.
 * All children element under this component will get access to this information.
 * To get those from a functional component, you can use the useAuthState function.
 * @param {Object} props Only used to get all the children element of this component
 */
const AuthStore = (props) => {
  const user = getAuth();

  const { useDeeplinks, shownInScene } = fetchSettingsFromURL();

  const [AuthState, AuthStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'authenticate':
          return action.payload;
        case 'startCreateVisitorProcess':
          return {
            ...state,
            startCreateVisitorProcess: action.payload
          };
        case 'startVisitorSession':
          return {
            ...state,
            visitorSessionId: action.payload
          };
        case 'setVisitor':
          return {
            ...state,
            visitorId: action.payload
          };
        case 'logout':
          return {
            user: {},
            authenticated: false,
            authenticating: false
          };
        default:
          return state;
      }
    },
    {
      useDeeplinks: useDeeplinks !== undefined,
      shownInScene,
      user,
      authenticating: false,
      authenticated: false,
      startCreateVisitorProcess: false,
      visitorSessionId: '',
      visitorId: ''
    }
  );

  return (
    <AuthStoreContext.Provider value={{ AuthState, AuthStateDispatch }}>
      {props.children}
    </AuthStoreContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStoreContext);

export default AuthStore;
