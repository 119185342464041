import React, { useState, useEffect, useCallback } from 'react';
import { number, func } from 'prop-types';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import screenfull from 'screenfull';

// Styling
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const mobileSpecificStyles = {
  wrapper: css`
    height: 36px;
    right: 12px;
    bottom: calc(100% - 118px);
    flex-flow: column;
  `
};

const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 500;
  width: auto;
  height: 50px;
  right: 15px;
  bottom: ${isMobile ? '-58px' : '20px'};
  display: flex;
  flex-flow: row-reverse;
  ${({ landscape }) => {
    if (!isMobile) return '';
    return css`
      ${mobileSpecificStyles.wrapper}
      ${landscape && `bottom: calc(100% - 48px);`}
    `;
  }}
`;

const MainIndicator = styled(motion.div)`
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.6);
  width: ${isMobile ? '36px' : '80px'};
  height: 100%;
  font-size: ${isMobile ? '14px' : '16px'};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const ZoomControls = styled(motion.div)`
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100%;
  direction: rtl;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.whitePure};
  margin-right: 5px;
  ${isMobile &&
  `
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    flex-direction: column-reverse;
  `};
`;

const Button = styled(motion.div)`
  padding: 18px;
  cursor: pointer;
  user-select: none;
  ${isMobile &&
  `
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.whitePure};
  opacity: 0.7;
  font-size: 14px;
  user-select: none;
`;

const ZoomInButton = styled(Button)``;
const ZoomInIcon = styled(Icon)``;

const ZoomOutButton = styled(Button)``;
const ZoomOutIcon = styled(Icon)``;

const ZoomResetButton = styled(Button)``;
const ZoomResetIcon = styled(Icon)``;

const ImmersionButton = styled(Button)``;
const ImmersionIcon = styled(Icon)``;

const CompressButton = styled(Button)``;
const CompressIcon = styled(Icon)``;

const Divider = styled.div`
  width: 1px;
  height: 50%;
  background: ${(props) => props.theme.whitePure};
  opacity: 0.15;
`;

const DividerH = styled.div`
  width: 24px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1);
`;

const getMotions = (isMobile) => {
  const changableParam = isMobile ? 'height' : 'width';
  return {
    initial: { [changableParam]: 0, opacity: 0 },
    animate: { [changableParam]: 'auto', opacity: 1 },
    exit: { [changableParam]: 0, opacity: 0 },
    transition: { duration: 0.25 }
  };
};

const ZoomIndicator = ({
  zoomIn,
  zoomOut,
  scale,
  onStartImmersion,
  onStopImmersion,
  setTransform,
  resetTransform,
  className,
  centerPosition
}) => {
  const scalePercentage = scale * 100;

  const [menuOpened, setMenuOpened] = useState(true);
  const [extraContolsVisible, setExtraControlsVisible] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const { isLandscape } = useMobileOrientation();

  const onWindowResize = useCallback(() => {
    setTimeout(() => {
      if (centerPosition.centerX > 0) resetTransform();
      else setTransform(centerPosition.centerX, centerPosition.centerY, 1);
    }, 100);
  }, [resetTransform, setTransform, centerPosition]);

  useEffect(() => {
    if (!initialized && !isNaN(centerPosition?.centerX)) {
      window.addEventListener('resize', onWindowResize);
      setInitialized(true);
    }
  }, [initialized, onWindowResize, centerPosition]);

  return (
    <Wrapper
      className={className}
      menuOpened={menuOpened}
      onHoverEnd={() => {
        setExtraControlsVisible(false);
      }}
      landscape={isLandscape}
    >
      <MainIndicator
        clickable={extraContolsVisible}
        onClick={() => {
          if (extraContolsVisible && !isMobile) {
            if (centerPosition.centerX > 0) resetTransform();
            else
              setTransform(centerPosition.centerX, centerPosition.centerY, 1);
          }
        }}
        onHoverStart={() => {
          setExtraControlsVisible(true);
        }}
        onTouchEnd={(e) => {
          setTimeout(() => {
            setExtraControlsVisible((cur) => !cur);
          }, [100]);
        }}
      >{`${Math.round(scalePercentage)}%`}</MainIndicator>
      <AnimatePresence>
        {extraContolsVisible && (
          <ZoomControls {...getMotions(isMobile)}>
            <ZoomOutButton
              onClick={(e) => {
                if (extraContolsVisible) {
                  zoomOut(e);
                }
              }}
            >
              <ZoomInIcon icon={['far', 'minus']} size="1x" />
            </ZoomOutButton>
            {isMobile && <DividerH />}
            <ZoomInButton
              onClick={(e) => {
                if (extraContolsVisible) {
                  zoomIn(e);
                }
              }}
            >
              <ZoomOutIcon icon={['far', 'plus']} size="1x" />
            </ZoomInButton>
            {isMobile && (
              <>
                <DividerH />
                <ZoomResetButton
                  onClick={(e) => {
                    if (extraContolsVisible) {
                      setTransform(
                        centerPosition.centerX,
                        centerPosition.centerY,
                        1
                      );
                    }
                  }}
                >
                  <ZoomResetIcon
                    icon={['far', 'compress-arrows-alt']}
                    size="1x"
                  />
                </ZoomResetButton>
              </>
            )}
            {onStopImmersion && onStartImmersion && !isMobile && (
              <>
                <Divider />
                {!screenfull.isFullscreen ? (
                  <ImmersionButton
                    onClick={() => {
                      setMenuOpened(false);
                      onStartImmersion();
                      setTimeout(() => {
                        if (centerPosition.centerX > 0) resetTransform();
                        else
                          setTransform(
                            centerPosition.centerX,
                            centerPosition.centerY,
                            1
                          );
                      }, 500);
                    }}
                  >
                    <ImmersionIcon icon={['far', 'expand-alt']} size="1x" />
                  </ImmersionButton>
                ) : (
                  <CompressButton
                    onClick={() => {
                      setMenuOpened(true);
                      onStopImmersion();
                      setTransform(
                        centerPosition.centerX,
                        centerPosition.centerY,
                        1
                      );
                    }}
                  >
                    <CompressIcon icon={['far', 'compress-alt']} size="1x" />
                  </CompressButton>
                )}
              </>
            )}
          </ZoomControls>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

ZoomIndicator.propTypes = {
  zoomIn: func.isRequired,
  zoomOut: func.isRequired,
  scale: number.isRequired,
  onStartImmersion: func,
  onStopImmersion: func,
  setTransform: func.isRequired
};

ZoomIndicator.defaultProps = {
  onStartImmersion: null,
  onStopImmersion: null
};

export default ZoomIndicator;
