import React, { useState, useEffect, useCallback } from 'react';
import { string, number, array, func, shape, bool } from 'prop-types';

// Components
import Page from '../contentItems/Page';
import MobilePage from '../contentItems/MobilePage';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import { useKeyPress } from 'helpers/customHooks';
import { usePrevious } from '@prompto-helpers';
import { wrap } from '@popmotion/popcorn';
import useResize from 'use-resize';

// Styles
import styled from 'styled-components';
import { isMobileDevice } from 'helpers/index';

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const PageCounter = styled.p`
  position: absolute;
  font-family: ${({ theme }) => theme.mainFontFamily};
  font-size: 16px;
  text-align: right;
  top: 20px;
  right: 20px;
  margin: 0;
  color: white;
  z-index: 10;
`;

const AnimationWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

const PageSwiper = (props) => {
  const {
    activePage,
    pages,
    onNextPage,
    onPreviousPage,
    onClose,
    videoFallbackThumbnail,
    CustomNavigation,
    onPageLoaded,
    isActive
  } = props;
  const [[page, direction], setPage] = useState([activePage, 0]);
  const [dragAxis, setDragAxis] = useState();
  const [isMobile, setIsMobileDevice] = useState(isMobileDevice());
  const [transitioning, setTransitioning] = useState(false);

  const previousActivePage = usePrevious(activePage);
  const nextKeyPressed = useKeyPress('ArrowRight', false, false);
  const previousKeyPressed = useKeyPress('ArrowLeft', false, false);
  const [isDragEnabled, setIsDragEnabled] = useState(true);
  const size = useResize();

  // Only do this when activePage changes
  useEffect(() => {
    if (
      activePage !== previousActivePage &&
      activePage !== page % pages.length
    ) {
      setPage([activePage, 0]);
    }
  }, [activePage, pages, page, previousActivePage]);

  const pageIndex = wrap(0, pages.length, page);
  const paginate = useCallback(
    (newDirection) => {
      setPage([page + newDirection, newDirection]);
      onPageLoaded();
    },
    [page, onPageLoaded]
  );

  const goToNextPage = useCallback(
    (eventName) => {
      if (isMobile || activePage + 1 < pages.length) {
        setTransitioning(true);
        setTimeout(() => {
          paginate(1);
          onNextPage(eventName);
          setTransitioning(false);
        }, 0);
      }
    },
    [paginate, onNextPage, isMobile, activePage, pages]
  );

  const goToPreviousPage = useCallback(
    (eventName) => {
      if (isMobile || activePage > 0) {
        setTransitioning(true);
        setTimeout(() => {
          paginate(-1);
          onPreviousPage(eventName);
          setTransitioning(false);
        }, 0);
      }
    },
    [paginate, onPreviousPage, isMobile, activePage]
  );

  // Arrow key presses
  useEffect(() => {
    if (
      nextKeyPressed &&
      isActive &&
      pages[pageIndex]?.contentItemType !== 'image360'
    ) {
      goToNextPage('ShowcaseOnPageNextKeyboardNavigation');
    }
  }, [nextKeyPressed, goToNextPage, isActive, pageIndex, pages]);

  useEffect(() => {
    if (
      previousKeyPressed &&
      isActive &&
      pages[pageIndex]?.contentItemType !== 'image360'
    ) {
      goToPreviousPage('ShowcaseOnPagePreviousKeyboardNavigation');
    }
  }, [previousKeyPressed, goToPreviousPage, isActive, pageIndex, pages]);

  // Resize
  useEffect(() => {
    setIsMobileDevice(isMobileDevice());
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setIsDragEnabled('x');
    }
  }, [isMobile]);

  useEffect(() => {
    if (pages[pageIndex]?.contentItemType === 'image360') {
      setIsDragEnabled(false);
    } else {
      setIsDragEnabled(true);
    }
  }, [pageIndex, pages]);

  let showPageCounter = isMobile;

  // UI
  let navigationControlsProps = {
    ...CustomNavigation.props
  };
  if (!isMobile) {
    navigationControlsProps = {
      ...navigationControlsProps,
      showUI: true,
      onNextPage: goToNextPage,
      onPreviousPage: goToPreviousPage,
      pageLength: pages.length,
      activePage: pageIndex,
      onGridView: onClose
    };
  }

  let navigationControls;
  if (CustomNavigation) {
    navigationControls = {
      ...CustomNavigation,
      props: navigationControlsProps
    };
  }

  return (
    <MainWrapper>
      {/* Page counter */}
      {showPageCounter && (
        <PageCounter>{`${pageIndex + 1}/${pages.length}`}</PageCounter>
      )}

      {/* Swiper */}
      <AnimatePresence initial={false} custom={direction}>
        <AnimationWrapper
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 }
          }}
          drag={isDragEnabled}
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          dragElastic={0.5}
          dragDirectionLock={true}
          onDirectionLock={(axis) => setDragAxis(axis)}
          onDragEnd={(e, info) => {
            const { offset, velocity } = info;

            // Horizontal swipe
            if (dragAxis === 'x') {
              const horizontalSwipe = swipePower(offset.x, velocity.x);
              if (horizontalSwipe < -swipeConfidenceThreshold) {
                goToNextPage('ShowcaseOnPageSwipeNavigation');
              } else if (horizontalSwipe > swipeConfidenceThreshold) {
                goToPreviousPage('ShowcaseOnPageSwipeNavigation');
              }
            }
            // Vertical swipe
            else if (dragAxis === 'y') {
              const verticalSwipe = swipePower(offset.y, velocity.y);
              if (
                verticalSwipe < -swipeConfidenceThreshold ||
                verticalSwipe > swipeConfidenceThreshold
              ) {
                onClose();
              }
            }
          }}
          dragPropagation={true}
        >
          {isMobile ? (
            <MobilePage
              {...props}
              pageIndex={pageIndex}
              data={pages[pageIndex]}
              allPages={pages}
              videoFallbackThumbnail={videoFallbackThumbnail}
              autoplay={true}
              onNextPage={goToNextPage}
              onPreviousPage={goToPreviousPage}
              setIsDragEnabled={setIsDragEnabled}
              isDragEnabled={isDragEnabled}
            />
          ) : (
            <Page
              {...props}
              key={pages[activePage]?.objectId}
              onNextPage={goToNextPage}
              onPreviousPage={goToPreviousPage}
              data={pages[pageIndex]}
              allPages={pages}
              autoplay={true}
              transitioning={transitioning}
              setIsDragEnabled={setIsDragEnabled}
              isDragEnabled={isDragEnabled}
            />
          )}
        </AnimationWrapper>
      </AnimatePresence>

      {navigationControls}
    </MainWrapper>
  );
};

PageSwiper.propsTypes = {
  className: string,
  activePage: number,
  pages: array,
  videoFallbackThumbnail: string,
  onNextPage: func,
  onPreviousPage: func,
  onClose: func,
  onShareClick: func,
  CustomNavigation: func,
  switcherState: shape({}).isRequired,
  onPageLoaded: func,
  isActive: bool
};

PageSwiper.defaultProps = {
  className: '',
  activePage: 0,
  pages: [],
  videoFallbackThumbnail: null,
  onNextPage: () => {},
  onPreviousPage: () => {},
  onClose: () => {},
  onShareClick: () => {},
  CustomNavigation: null,
  onPageLoaded: () => {},
  isActive: false
};

export default PageSwiper;

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
