import React from 'react';

// Components
import ShowcaseLoader from './ShowcaseLoader';

// Helpers
import { motion } from 'framer-motion';
import localizer from 'localization/localizer';

//Styles
import styled from 'styled-components';

const MainWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageWrapper = styled(motion.div)`
  width: 100%;
  justify-content: center;
  padding: 15px 30px;
  z-index: 100;
  font-size: 50px;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFontFamily};
  font-weight: 300;
  color: ${(props) => props.theme.showcaseWhite};
  user-select: none;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const Loader = styled(ShowcaseLoader)`
  margin-left: 30px;
`;

const Message = styled.div`
  margin-top: -15px;
`;

const LoadingMessage = () => {
  return (
    <MainWrapper>
      <MessageWrapper>
        <Message>{localizer.loading}</Message>
        <Loader color={'white'} />
      </MessageWrapper>
    </MainWrapper>
  );
};

export default LoadingMessage;
