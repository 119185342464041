import React, { useState, useEffect, useCallback } from 'react';
import { func, bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShareCodeSettings from './ShareCodeSettings';
import RegenerateShareCodeButton from './RegenerateShareCodeButton';
import NativeSharing from 'components/other/NativeSharing';

// Helpers
import {
  getCurrentEnvironment,
  fetchSettingsFromURL,
  capitalize,
  isShareCodeInvalid
} from 'helpers';
import { useProjectState } from 'stores/ProjectStore';
import { useAuthState } from 'stores/AuthStore';
import localizer from 'localization/localizer';
import { motion, AnimatePresence } from 'framer-motion';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import { isMobileOnly } from 'react-device-detect';
import to from 'await-to-js';
import { filteredUnitListForProjectStore } from 'helpers/units/VmUnitHelper';
import {
  ShowcaseConfiguration,
  ShareItem,
  Lead,
  LeadComment,
  BoardTicket
} from '@prompto-api';

// Styles
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 500;
`;

const ModalWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const Overlay = styled(motion.div)`
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
`;

const ModalContent = styled(motion.div)`
  align-items: center;
  background: ${(props) => props.theme.showcaseWhite};
  border-radius: 4px;
  min-height: 300px;
  max-height: 95%;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  width: 700px;
  max-width: calc(100% - 20px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 30px;
  box-sizing: border-box;
`;

const QrCodeWrapper = styled.div`
  flex-shrink: 0;
`;

const ModalTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.88px;
  color: ${(props) => props.theme.showcaseBlack};
  padding: 0;
  padding-bottom: 10px;
`;

const ModalDescription = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${(props) => props.theme.showcaseBlack};
  padding: 0;
  padding-bottom: 30px;
  text-align: center;
`;

const LinkWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  margin: 0;
  padding: 0 15px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
  letter-spacing: normal;
  width: 100%;
  min-height: 48px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background: ${(props) => props.theme.grayWhiteOff};
  user-select: none;
  cursor: pointer;
`;

const LinkContent = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
`;

const CopyWrapper = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: calc(100% + 4px);
  transform: translateX(-50%);
  margin: 0;
  text-align: center;
  letter-spacing: 0.88px;
  width: 100%;
  justify-content: center;
  display: flex;
  user-select: none;
  cursor: pointer;
`;

const CopyMessage = styled.p`
  color: ${(props) => props.theme.successColor};
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0;
`;

const DoneButton = styled.div`
  margin-top: 30px;
  flex-shrink: 0;
  background: ${({ theme, disabled }) =>
    disabled ? theme.grayWhiteOff : theme.accentAlt500};
  border-radius: 3px;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.grayWhiteOff : theme.accentAlt500};
  box-sizing: border-box;
  color: ${({ theme, disabled }) =>
    disabled ? theme.primary300 : theme.showcaseWhite};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.88px;
  line-height: 18px;
  outline: none;
  text-align: center;
  height: 40px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  transition: all 0.2s ease;
  > p {
    margin: auto 0;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled(FontAwesomeIcon)`
  font-size: 22px;
  color: #333333;
  cursor: pointer;
`;

const SettingsContainer = styled.div`
  flex-shrink: 0;
  width: calc(100% + 80px);
`;

export const getFormattedName = (updateParams) =>
  [
    updateParams?.contactCreateParams?.firstName,
    updateParams?.contactCreateParams?.lastName
  ]
    .filter(Boolean)
    .join(' ');

const ErrorMessage = styled.div`
  padding: 8px 15px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.errorColor};
`;

const ShareModal = ({
  onCloseModal,
  providedPath,
  shareShowcase,
  withMobileOption,
  nativeSharingHandler
}) => {
  const params = fetchSettingsFromURL();
  const navigate = useNavigate();

  const { AuthState } = useAuthState();
  const { authenticated, user } = AuthState;
  const { sessionToken, userId } = user;

  // Project store
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const {
    shareCode,
    project,
    vault,
    favoritesList,
    shareItemShowcaseConfiguration,
    contentCollection,
    showcaseConfiguration,
    initialUnitList,
    activeShareItem
  } = ProjectState;

  const [localShareCode, setLocalShareCode] = useState('');
  const [localShareItemId, setLocalShareItemId] = useState('');
  const [successMessage, showSuccessMessage] = useState(false);
  const [env] = useState(getCurrentEnvironment());
  const [baseLink] = useState(`https://${env.applicationUrl}`);
  const [envParam] = useState(params.env ? `?env=${params.env}` : '');

  const [isRegeneratingCode, setIsRegeneratingCode] = useState(false);
  const [errorWhileRegeneratingCode, setErrorWhileRegeneratingCode] =
    useState(null);

  // share item setting related
  const [showSettings, setShowSettings] = useState(false);
  const [sharecodeSettings, setSharecodeSettings] = useState(null);
  const [isUpdatingShareItem, setIsUpdatingShareItem] = useState(false);
  const [shareItemError, setShareItemError] = useState(null);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [sendEmailToContact, setSendEmailToContact] = useState(false);
  const [isShareOnlyFavorites, setShareOnlyFavorites] = useState(false);
  const [emailLanguage, setEmailLanguage] = useState();
  const [shouldCreateLead, setShouldCreateLead] = useState();

  const path = providedPath || localShareCode;
  const url = `${baseLink}/${path}${window.location.search}`;

  // Features
  let features = {
    multipleSharecodes: false,
    archiveSharecodes: false
  };

  if (user?.features) {
    Object.keys(features).forEach((feature) => {
      const featureActive = user?.features?.includes(feature);
      features[feature] = featureActive;
    });
  }

  // Check if we can show share item settings
  useEffect(() => {
    const shouldShow =
      shareShowcase && authenticated && features.multipleSharecodes;
    setShowSettings(shouldShow);
    if (!shouldShow) {
      setShareItemError(null);
    }
  }, [shareShowcase, authenticated, features.multipleSharecodes]);

  useEffect(() => {
    if (!localShareCode) setLocalShareCode(shareCode);
  }, [shareCode, localShareCode]);

  // Is triggered when share code successfully regenerated
  useEffect(() => {
    if (localShareCode && localShareCode !== shareCode) {
      navigate(`/${localShareCode}${window.location.search}`);
    }
  }, [localShareCode, shareCode, navigate]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        showSuccessMessage('');
        clearTimeout(timer);
      }, 2000);
    }
  }, [successMessage]);

  const copyToClipboard = () => {
    copy(url);
    showSuccessMessage(localizer.linkCopied);
  };

  const updateShareCode = ({ shareCode, objectId }) => {
    showSuccessMessage(localizer.regenerateCodeSuccess);
    setLocalShareCode(shareCode);
    setLocalShareItemId(objectId);
    // Its a good to turn saving by default only favorite units if new shareitem was created
    setShareOnlyFavorites(false);
    // after creating a new shareitem, we need to filter out units again
    // according to the old showcase configuration
    const preparedUnits = filteredUnitListForProjectStore({
      unitList: initialUnitList,
      contentCollection,
      showcaseConfiguration
    });
    ProjectStateDispatch({
      type: 'setProjectData',
      payload: {
        project: {
          ...project,
          unitList: preparedUnits
        }
      }
    });
  };

  const onChangeShareItemData = useCallback(
    async (updatedShareItem) => {
      setShareItemError(null);
      setIsUpdatingShareItem(true);

      const updateParams = { ...updatedShareItem };

      if (updateParams.hasOwnProperty('customerName')) {
        delete updateParams.customerName;
      }

      // prepare update customer params
      if (sendEmailToContact) {
        updateParams.sendEmailToContact = true;
      }
      updateParams.contactCreateParams = {
        language: emailLanguage.value ?? 'en'
      };

      if (updateParams.hasOwnProperty('customerEmail')) {
        updateParams.contactCreateParams.email = updateParams.customerEmail;
      }
      if (updateParams.hasOwnProperty('firstName')) {
        updateParams.contactCreateParams.firstName = updateParams.firstName;
      }
      if (updateParams.hasOwnProperty('lastName')) {
        updateParams.contactCreateParams.lastName = updateParams.lastName;
      }

      let shareItemConfigObjectId = shareItemShowcaseConfiguration?.objectId;

      if (favoritesList.length && isShareOnlyFavorites) {
        const handleCallResponse = (response) => {
          const { data } = response;
          const { vmShowcaseConfiguration } = data;
          const unitList = project.unitList.filter((unit) => {
            return favoritesList.includes(unit.objectId);
          });
          // if new configuration was created - its id will be added to shareItem
          // if it just updated - its id will be same as before
          shareItemConfigObjectId = vmShowcaseConfiguration.objectId;
          ProjectStateDispatch({
            type: 'setProjectData',
            payload: {
              shareItemShowcaseConfiguration: vmShowcaseConfiguration,
              project: {
                ...project,
                unitList
              }
            }
          });
        };
        if (shareItemShowcaseConfiguration) {
          // update shareItemShowcaseConfiguration
          const [, response] = await to(
            ShowcaseConfiguration.update(
              shareItemShowcaseConfiguration.objectId,
              { allowedUnitsIdList: favoritesList },
              sessionToken
            )
          );
          if (response) {
            handleCallResponse(response);
          }
        } else {
          // or create new one and add allowed units to it
          const [, response] = await to(
            ShowcaseConfiguration.create(
              {
                entityObjectId: env.entityObjectId,
                vaultObjectId: vault.objectId,
                projectObjectId: project.objectId,
                allowedUnitsIdList: favoritesList
              },
              sessionToken
            )
          );
          if (response) {
            handleCallResponse(response);
          }
        }
      }

      ShareItem.update(
        updatedShareItem.objectId,
        {
          ...updateParams,
          showcaseConfigurationObjectId: shareItemConfigObjectId
        },
        sessionToken
      )
        .then((result) => {
          setIsUpdatingShareItem(false);
          const shareItem = result?.data?.shareItem;
          const updatedCodeIsInvalid = isShareCodeInvalid(shareItem);
          setIsInvalidCode(updatedCodeIsInvalid);
          if (!updatedCodeIsInvalid) {
            onCloseModal();
          }

          if (shouldCreateLead) {
            const name = getFormattedName(updateParams);
            const leadCreateShowcaseParams = {
              shareCode: updateParams.shareCode,
              projectId: updateParams.projectObjectId,
              vaultId: updateParams.vaultObjectId,
              name,
              email: updateParams.customerEmail,
              utmSource: 'showcase',
              language: updateParams.contactCreateParams?.language ?? 'en'
            };

            if (favoritesList.length && isShareOnlyFavorites) {
              leadCreateShowcaseParams.unitObjectIds = favoritesList;
            }

            // A lead is being created silently, we only catch an error to not break the app
            Lead.create({
              leadCreateShowcaseParams
            })
              .then((res) => {
                // Create lead comment if neeeded
                if (updateParams.note) {
                  const createCommentData = {
                    commentAuthor: {
                      userObjectId: userId,
                      authenticated: true,
                      name: ''
                    },
                    leadObjectId: res.data.leadObjectId,
                    leadCommentType: 'log_an_email',
                    value: updateParams.note
                  };
                  LeadComment.create(createCommentData, sessionToken).catch(
                    () => {}
                  );
                }

                // Assign lead to its' creator
                BoardTicket.assign(
                  res.data.boardTicketObjectId,
                  { assignUserId: userId },
                  sessionToken
                ).catch(() => {});
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          setShareItemError({
            message: localizer.shareCodeSettings.errorWhileUpdating,
            code: error.response?.status
          });
          setIsUpdatingShareItem(false);
        });
    },
    [
      sessionToken,
      setShareItemError,
      onCloseModal,
      sendEmailToContact,
      ProjectStateDispatch,
      env,
      favoritesList,
      isShareOnlyFavorites,
      shareItemShowcaseConfiguration,
      project,
      vault,
      emailLanguage,
      shouldCreateLead
    ]
  );

  const onDoneClicked = () => {
    if (
      sharecodeSettings &&
      ((sharecodeSettings.customerEmail && !sharecodeSettings.isValidEmail) ||
        (sendEmailToContact &&
          (!sharecodeSettings.customerEmail ||
            !sharecodeSettings.isValidEmail)))
    )
      return;

    if (authenticated) {
      onChangeShareItemData(sharecodeSettings);
    } else {
      onCloseModal();
    }
  };

  return (
    <Wrapper>
      <ModalWrapper>
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onCloseModal}
        />

        <ModalContent
          initial={{ opacity: 0.0, y: -30 }}
          animate={{ opacity: 1.0, y: 0 }}
          exit={{ opacity: 0.0, y: -30 }}
          transition={{ type: 'tween' }}
        >
          <ContentWrapper>
            {isMobileOnly && withMobileOption ? (
              <NativeSharing
                path={providedPath}
                onShare={nativeSharingHandler}
                shareShowcase={shareShowcase}
              />
            ) : (
              <>
                <QrCodeWrapper data-testid="share-code-qr">
                  <QRCode
                    style={{
                      paddingBottom: `30px`
                    }}
                    value={url}
                    renderAs="svg"
                    size={125}
                    fgColor={'#11082d'}
                  />
                </QrCodeWrapper>
                <ModalTitle>{localizer.shareTitle}</ModalTitle>
                <ModalDescription>{localizer.shareDetailText}</ModalDescription>
                <LinkWrapper onClick={copyToClipboard}>
                  <LinkContent>
                    <span>{baseLink}</span>
                    {'/'}
                    <span>{path}</span>
                    {envParam && <span>{`/${envParam}`}</span>}
                  </LinkContent>

                  {shareShowcase && features.multipleSharecodes && (
                    <RegenerateShareCodeButton
                      isUserAuthenticated={authenticated}
                      data={{
                        sessionToken: user?.sessionToken,
                        vaultObjectId: vault?.objectId,
                        projectObjectId: project?.objectId
                      }}
                      setErrorWhileRegeneratingCode={
                        setErrorWhileRegeneratingCode
                      }
                      setIsRegeneratingCode={setIsRegeneratingCode}
                      isRegeneratingCode={isRegeneratingCode}
                      updateShareCode={updateShareCode}
                    />
                  )}

                  <AnimatePresence>
                    {successMessage && (
                      <CopyWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <CopyMessage>{successMessage}</CopyMessage>
                      </CopyWrapper>
                    )}
                  </AnimatePresence>
                </LinkWrapper>
              </>
            )}

            {errorWhileRegeneratingCode && (
              <ErrorMessage>
                {localizer.regenerateCodeError}
                {errorWhileRegeneratingCode?.response?.status === 403 && (
                  <div>{localizer.refreshPage}</div>
                )}
              </ErrorMessage>
            )}

            {/* Share code settings are available only for authenticated users */}
            {showSettings && (
              <SettingsContainer>
                <ShareCodeSettings
                  key={activeShareItem?.objectId}
                  shareItemId={localShareItemId || activeShareItem?.objectId}
                  code={localShareCode}
                  disabled={isRegeneratingCode}
                  canArchiveSharecodes={features.archiveSharecodes}
                  setSharecodeSettings={setSharecodeSettings}
                  isInvalidCode={isInvalidCode}
                  processing={isUpdatingShareItem}
                  error={shareItemError}
                  setShareItemError={setShareItemError}
                  sendEmailToContact={sendEmailToContact}
                  setSendEmailToContact={setSendEmailToContact}
                  favoritesList={favoritesList}
                  isShareOnlyFavorites={isShareOnlyFavorites}
                  setShareOnlyFavorites={setShareOnlyFavorites}
                  emailLanguage={emailLanguage}
                  onLanguageChanged={setEmailLanguage}
                  shouldCreateLead={shouldCreateLead}
                  setShouldCreateLead={setShouldCreateLead}
                />
              </SettingsContainer>
            )}

            <DoneButton
              onClick={onDoneClicked}
              disabled={
                sharecodeSettings &&
                ((sharecodeSettings.customerEmail &&
                  !sharecodeSettings.isValidEmail) ||
                  (sendEmailToContact &&
                    (!sharecodeSettings.customerEmail ||
                      !sharecodeSettings.isValidEmail)))
              }
            >
              <p>
                {capitalize(
                  sendEmailToContact
                    ? localizer.shareCodeSettings.sendEmail
                    : localizer.done
                )}
              </p>
            </DoneButton>
            <CloseButtonWrapper>
              <CloseButton
                onClick={onCloseModal}
                icon={['fal', 'times']}
                size="1x"
              />
            </CloseButtonWrapper>
          </ContentWrapper>
        </ModalContent>
      </ModalWrapper>
    </Wrapper>
  );
};

ShareModal.propTypes = {
  onCloseModal: func,
  shareShowcase: bool,
  withMobileOption: bool,
  nativeSharingHandler: func
};

ShareModal.defaultProps = {
  onCloseModal: () => {},
  shareShowcase: false,
  withMobileOption: false,
  nativeSharingHandler: () => {}
};

export default ShareModal;
