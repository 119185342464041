import React, { useReducer, createContext, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { addQueryParams, removeQueryParams } from 'helpers';

export const Context = createContext();

const TourStore = (props) => {
  const [TourState, TourStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'update':
          return {
            ...state,
            ...action.payload
          };
        case 'addLoadedContentCollection': {
          return {
            ...state,
            loadedContentCollections: {
              ...state.loadedContentCollections,
              [action.payload.objectId]: action.payload
            }
          };
        }
        case 'reset':
          const nextMediaView =
            state?.mediaViews && state?.mediaViews?.length > 0
              ? state.mediaViews[0]
              : null;
          if (nextMediaView) {
            addQueryParams([{ amv: nextMediaView.objectId }]);
          } else {
            removeQueryParams([{ amv: '' }]);
          }
          return {
            ...state,
            activeMediaView: nextMediaView
          };
        default:
          return state;
      }
    },
    {
      activeMediaView: null,
      showUnits: isMobile,
      mediaViews: null,
      highlightedSpot: null,
      highlightedUnitSpotParams: null,
      loadedContentCollections: {}
    }
  );
  return (
    <Context.Provider value={{ TourState, TourStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useTourState = () => useContext(Context);

export default TourStore;
