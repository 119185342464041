import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';

// Helpers
import { isMobile } from 'react-device-detect';
import localizer from 'localization/localizer';
import { useProjectState } from 'stores/ProjectStore';
import { getShareItemExpirationDate } from 'helpers';
import { ShareItem } from '@prompto-api';

// Styling
import styled, { keyframes, css } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RegenerateIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: inherit;
  margin-right: ${isMobile ? '9px' : '0'};
  ${(props) =>
    props.animate &&
    css`
      animation: ${spin} 2s linear infinite;
    `}
`;

const Button = styled.button`
  flex-shrink: 0;
  border: none;
  background: none;
  outline: none;
  margin-left: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.primary100};
  ${isMobile &&
  css`
    width: 200px;
    height: 40px;
    padding: 8px;
    margin: 8px 0 0;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: ${(props) => props.theme.grayWhiteOff};
    color: ${(props) => props.theme.primary400};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const RegenerateShareCodeButton = ({
  isUserAuthenticated,
  data,
  setErrorWhileRegeneratingCode,
  setIsRegeneratingCode,
  isRegeneratingCode,
  updateShareCode
}) => {
  const { sessionToken, vaultObjectId, projectObjectId } = data;
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  const expirationDate = getShareItemExpirationDate(
    showcaseConfiguration?.defaultExpirationTime
  );

  const regenerateShareCode = (event) => {
    event.stopPropagation();
    setErrorWhileRegeneratingCode(false);
    setIsRegeneratingCode(true);

    const createParams = {
      stringTargetType: 'showcaseItem',
      vaultObjectId,
      projectObjectId
    };

    if (expirationDate > 0) {
      createParams.expiresAt = expirationDate;
    }

    ShareItem.create(createParams, sessionToken)
      .then((result) => {
        const shareItem = result?.data?.shareItem;
        updateShareCode(shareItem);
        ProjectStateDispatch({
          type: 'setProjectData',
          payload: {
            shareCode: shareItem?.shareCode
          }
        });
        ProjectStateDispatch({
          type: 'setActiveShareItem',
          payload: shareItem
        });
      })
      .catch((error) => setErrorWhileRegeneratingCode(error))
      .finally(() => {
        setIsRegeneratingCode(false);
      });
  };

  if (!isUserAuthenticated) return null;

  return (
    <Tippy
      offset={[0, 10]}
      theme="favoritesToggler"
      placement="bottom"
      content={<div>{localizer.regenerateCode}</div>}
      arrow={false}
      touch={false}
    >
      <Button onClick={regenerateShareCode}>
        <RegenerateIcon
          icon={['far', 'sync']}
          size="1x"
          animate={isRegeneratingCode ? 1 : 0}
        />
        {isMobile && localizer.regenerateCode}
      </Button>
    </Tippy>
  );
};

export default RegenerateShareCodeButton;
