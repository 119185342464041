import React, { useState, useEffect, useCallback } from 'react';
import { string } from 'prop-types';

// Components
import FullscreenIframePreview from 'components/other/FullscreenIframePreview';

// Helpers
import {
  buildGalleryUrlForUnit,
  buildGalleryUrlForProject,
  getCurrentEnvironment
} from 'helpers';
import isTouchDevice from 'is-touch-device';
import localizer from 'localization/localizer';

const StreamController = ({
  type,
  unit,
  streamUrl,
  project,
  useProjectGallery,
  sessionToken,
  onExit
}) => {
  const [selectedExperience, setSelectedExperience] = useState();
  const [loaderGalleryUrl, setLoaderGalleryUrl] = useState(null);

  const startUnitStreaming = useCallback(
    async (unit) => {
      if (!unit) {
        return;
      }
      const { scene, vault, sceneCloudStreamingUrl, objectId } = unit;

      let streamingUrl = sceneCloudStreamingUrl;

      //detect if touch device and append ' PROMPTO_TOUCHSCREEN_MODE=true' (url encoded) to it if it is
      const isTouch = isTouchDevice();
      if (isTouch) {
        streamingUrl += '%20PROMPTO_TOUCHSCREEN_MODE%3Dtrue';
      }

      setSelectedExperience({
        type: 'streaming',
        url: streamingUrl
      });

      setLoaderGalleryUrl(
        buildGalleryUrlForUnit(
          scene.objectId,
          scene.catalogue?.objectId,
          objectId,
          vault.objectId,
          localizer.getLanguage(),
          getCurrentEnvironment().env,
          sessionToken
        )
      );
    },
    [sessionToken]
  );

  const startStreaming = useCallback(
    (url) => {
      if (useProjectGallery) {
        setLoaderGalleryUrl(
          buildGalleryUrlForProject(
            project.objectId,
            project.vault.objectId,
            localizer.getLanguage(),
            getCurrentEnvironment().env,
            sessionToken
          )
        );
      }

      setSelectedExperience({
        type: 'streaming',
        url
      });
    },
    [project, sessionToken, useProjectGallery]
  );

  // We only receive a url
  useEffect(() => {
    switch (type) {
      default:
      case 'url':
        if (streamUrl) {
          if (selectedExperience?.url) {
            // Check if we aren't currently streaming this url
            if (selectedExperience?.url !== streamUrl) {
              startStreaming(streamUrl);
            }
          } else {
            startStreaming(streamUrl);
          }
        }
        break;
      case 'unit':
        startUnitStreaming(unit);
        break;
    }
  }, [
    type,
    streamUrl,
    unit,
    selectedExperience,
    startStreaming,
    startUnitStreaming
  ]);

  useEffect(() => {
    if (loaderGalleryUrl) {
      setTimeout(() => {
        setLoaderGalleryUrl(null);
      }, 40000);
    }
  }, [loaderGalleryUrl]);

  return (
    <>
      {selectedExperience && (
        <FullscreenIframePreview
          key="iframeKey"
          experienceType={selectedExperience.type}
          src={selectedExperience.url}
          onExit={onExit}
          loaderGalleryUrl={loaderGalleryUrl}
        />
      )}
    </>
  );
};

StreamController.propTypes = {
  type: string
};

StreamController.defaultProps = {};

export default StreamController;
