import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import DesktopConnectPage from './desktop/DesktopConnectPage';
import MobileConnectPage from './mobile/MobileConnectPage';

// Helpers
import qs from 'query-string';
import { useAuthState } from 'stores/AuthStore';

import { isMobileDevice, getShareItemExpirationDate } from 'helpers/index';
import { fetchSettingsFromURL } from 'helpers';
import navigationCategories from 'configs/NavigationCategories.json';
import { ShareItem, Project, ShowcaseConfiguration } from '@prompto-api';

const ConnectPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { AuthState, AuthStateDispatch } = useAuthState();
  const { user } = AuthState;

  const [shareItems, setShareItems] = useState([]);

  const [isWaitingForGetAllCall, setIsWaitingForGetAllCall] = useState(true);
  const [isGettingAllCall, setIsGettingAllCall] = useState(false);
  const [isCreatingShareItem, setIsCreatingShareItem] = useState(false);
  const [features, setFeatures] = useState();

  // Features
  useEffect(() => {
    let initialFeatures = {
      multipleSharecodes: false
    };

    if (user?.features) {
      Object.keys(initialFeatures).forEach((feature) => {
        const featureActive = user?.features?.includes(feature);
        initialFeatures[feature] = featureActive;
      });
      setFeatures(initialFeatures);
    }
  }, [user]);

  // Create sharecode if objectId is being passed
  // and navigate to that showcase.
  useEffect(() => {
    const {
      navigationItemObjectId,
      projectId,
      vaultId,
      shownInScene,
      contractId,
      useDeeplinks
    } = fetchSettingsFromURL();

    if (projectId && vaultId) {
      const sessionToken = AuthState.user.sessionToken;

      if (!isWaitingForGetAllCall || features?.multipleSharecodes) {
        // 1. get the project
        Project.get(projectId, vaultId, sessionToken, {})
          .then((res) => {
            const project = res.data?.vmProjectSection;
            const showcaseConfigId = project?.showcaseConfiguration?.objectId;
            // 2. get the project's showcase configuration
            ShowcaseConfiguration.get(showcaseConfigId).then((res) => {
              const showcaseConfiguration = res?.data?.vmShowcaseConfiguration;
              const expirationDate = getShareItemExpirationDate(
                showcaseConfiguration?.defaultExpirationTime
              );
              let subrouteString = navigationCategories.home;
              const params = {};
              if (AuthState.env) {
                params.env = AuthState.env;
              }
              if (shownInScene) {
                params.shownInScene = shownInScene;
              }
              if (useDeeplinks) {
                params.useDeeplinks = true;
                params.sessionToken = sessionToken;
                params.vaultId = vaultId;
              }
              if (contractId) {
                params.contractId = contractId;
                subrouteString = navigationCategories.contracts;
              }
              if (shareItems.length === 0 && !isCreatingShareItem) {
                setIsCreatingShareItem(true);

                const createParams = {
                  stringTargetType: 'showcaseItem',
                  vaultObjectId: vaultId,
                  projectObjectId: projectId
                };

                if (navigationItemObjectId) {
                  createParams.navigationItemObjectId = navigationItemObjectId;
                }

                if (expirationDate > 0) {
                  createParams.expiresAt = expirationDate;
                }

                ShareItem.create(createParams, sessionToken).then(
                  ({ data }) => {
                    const shareCode = data.shareItem?.shareCode;

                    let link = `/${shareCode}/${subrouteString}/?${qs.stringify(
                      params
                    )}`;
                    navigate(link, {
                      replace: true
                    });
                    setIsLoading(false);
                  }
                );
              } else if (shareItems.length > 0) {
                let link = `/${
                  shareItems[0].shareCode
                }/${subrouteString}/?${qs.stringify(params)}`;
                navigate(link, { replace: true });
                setIsLoading(false);
              }
            });
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
  }, [
    AuthState.env,
    AuthState.contentCollectionObjectId,
    AuthState.navigationItemObjectId,
    AuthState.user,
    navigate,
    AuthStateDispatch,
    isWaitingForGetAllCall,
    shareItems,
    isCreatingShareItem,
    features
  ]);

  useEffect(() => {
    const { projectId, vaultId, createdByUserObjectId } =
      fetchSettingsFromURL();
    if (
      projectId &&
      vaultId &&
      !isGettingAllCall &&
      features &&
      !features?.multipleSharecodes
    ) {
      setIsWaitingForGetAllCall(true);
      setIsGettingAllCall(true);
      const sessionToken = AuthState.user.sessionToken;

      // Get all share items for the given project
      ShareItem.getAll(
        {
          vaultObjectId: vaultId,
          projectObjectId: projectId,
          targetType: 'showcaseItem',
          createdByUserObjectId: createdByUserObjectId
        },
        sessionToken
      )
        .then(({ data: { shareItemList } }) => {
          const newShareItems = shareItemList
            .sort((a, b) => b.createdAt - a.createdAt)
            .filter((x) => !x.expiresAt || x.expiresAt > Date.now());
          if (newShareItems.length > 0) setShareItems(newShareItems);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsWaitingForGetAllCall(false);
        });
    }
  }, [AuthState.user, isGettingAllCall, features]);

  if (isLoading) {
    return null;
  }

  const isMobile = isMobileDevice();
  if (isMobile) {
    return <MobileConnectPage />;
  } else {
    return <DesktopConnectPage />;
  }
};

export default ConnectPage;
