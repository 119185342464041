import * as THREE from 'three';

import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { OrbitControls } from '../components/OrbitControls.js';

const initializeSceneForClean3DModel = (scene) => {
  const light = new THREE.AmbientLight(0xaaaaaa, 0);
  scene.add(light);

  //add sunlight
  const sunTarget = new THREE.Object3D();
  sunTarget.position.set(-10, -10, -10);
  scene.add(sunTarget);

  const directionalLight = new THREE.DirectionalLight(0xffeedd, 0.95);
  scene.add(directionalLight);
  directionalLight.target = sunTarget;

  //add hemisphere lighting
  const hemiLight = new THREE.HemisphereLight(0xaaaaaa, 0xaaaaaa, 0.6);
  hemiLight.color.setHSL(0.6, 0.85, 0.6);
  hemiLight.groundColor.setHSL(0.095, 0.35, 0.75);
  hemiLight.position.set(0, 50, 0);
  scene.add(hemiLight);
};

const initializeCameras = (width, height, scene, cam, animation) => {
  const nCamera = new THREE.PerspectiveCamera(
    cam.fov,
    cam.aspect,
    cam.near,
    cam.far
  );

  nCamera.setFocalLength(cam.fov);

  const { quaternion: Q, position: P } = animation;
  const startQuaternion = Q[0];
  const startPosition = P[0];

  if (startPosition) {
    const [x, z, y] = startPosition;
    nCamera.position.set(x, y, z);
  }

  if (startQuaternion) {
    const [x, y, z, w] = startQuaternion;
    nCamera.quaternion.set(x, y, z, w);
    nCamera.updateProjectionMatrix();
  }

  return { PC: nCamera };
};

const initializeRenders = (width, height) => {
  const nRenderer = new THREE.WebGLRenderer({
    alpha: true
  });
  nRenderer.setSize(width, height);
  nRenderer.autoClear = false;
  nRenderer.shadowMap.enabled = false;
  nRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
  nRenderer.outputEncoding = THREE.sRGBEncoding;

  const labelR = new CSS2DRenderer();
  labelR.setSize(width, height);
  labelR.domElement.style.position = 'absolute';
  labelR.domElement.style.top = '0px';
  labelR.domElement.style.pointerEvents = 'none';
  labelR.domElement.style.color = 'green';

  return { renderer: nRenderer, labelRenderer: labelR };
};

const initializeControls = (PC, renderer) => {
  const controls = new OrbitControls(PC, renderer.domElement);
  controls.enabled = true;
  controls.update();

  return {
    orbitControls: controls
  };
};

export const initialize3DScene = (
  width,
  height,
  initialCameraSettings,
  animation,
  onInitializeDone
) => {
  // Main scene
  const nScene = new THREE.Scene();
  nScene.background = null;

  // Create Percpective camera (PC)
  const { PC } = initializeCameras(
    width,
    height,
    nScene,
    initialCameraSettings,
    animation
  );

  // Create the renderers
  const { renderer, labelRenderer } = initializeRenders(width, height);

  // Create the controls
  const { orbitControls } = initializeControls(PC, renderer);

  initializeSceneForClean3DModel(nScene);

  onInitializeDone({
    nScene,
    PC,
    orbitControls,
    renderer,
    labelRenderer
  });
};

/**
 * Check if certain click is inside the UI
 * We manually control the mouse input of the user and thus manually check where this click is happening.
 * @param {number} mouseInViewX
 * @param {number} mouseInViewY
 */
export const isClickInUI = (
  mouseInViewX,
  mouseInViewY,
  viewerWidth,
  viewerHeight
) => {
  // The magic numbers used here are calculated based on the sizes of the UI components on screen, would be good to dynamically calculate them
  const zoneRightWidth = 120;
  const zoneRightHeight = 60;

  const zoneLeftWidth = 180;
  const zoneLeftHeight = 60;

  // Check zone right
  if (
    mouseInViewX >= viewerWidth - zoneRightWidth &&
    mouseInViewY >= viewerHeight - zoneRightHeight
  ) {
    return true;
  }

  // Check zone left
  if (
    mouseInViewX <= zoneLeftWidth &&
    mouseInViewY >= viewerHeight - zoneLeftHeight
  ) {
    return true;
  }

  return false;
};

export function parseAnimations(collada) {
  if (!collada) return;
  const cameraAnimation = collada.scene.animations[0];

  const calculatedAnimation = {};

  cameraAnimation.tracks.forEach((track) => {
    // "D0D0F8E5-6F5B-4E06-AAFE-8EF601AFA870.quaternion"
    const name = track.name.split('.')[1];

    // times property represents the number of frames
    const times = track.times;

    // represents animation values in form of repetitive x, y, z, x, y, z ... values
    // in case of quaternion there is also w value presented
    const values = track.values;
    const preparedValues = [];
    const valuesGroupLength = values.length / times.length;
    for (let i = 0; i < values.length; i += valuesGroupLength) {
      preparedValues.push(values.slice(i, i + valuesGroupLength));
    }

    calculatedAnimation[name] = preparedValues;
    calculatedAnimation.position = [
      calculatedAnimation.position[0],
      ...calculatedAnimation.position.slice(1).reverse()
    ];
  });

  return calculatedAnimation;
}
