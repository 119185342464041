import React, { useState, useEffect, useCallback } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowcaseLoader from 'components/other/ShowcaseLoader';

// Helpers
import { isDarkColor } from 'helpers';
import localizer from 'localization/localizer';
import { motion, AnimatePresence } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 600px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.showcaseWhite};
  color: ${({ theme }) => theme.showcaseBlack};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'normal')};
  border: ${({ isActive, theme }) =>
    isActive
      ? 'solid 1px ' +
        (isDarkColor(theme.contactCardBg)
          ? theme.contactCardBg
          : theme.brand500)
      : 'solid 1px ' + theme.gray200};
  &:hover {
    cursor: pointer;
    border: solid 1px
      ${({ theme }) =>
        isDarkColor(theme.contactCardBg)
          ? theme.contactCardBg
          : theme.brand500};
  }
`;

const LocationIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.primary100};
  margin-right: 10px;
`;

const ShowMoreButton = styled.button`
  height: 20px;
  border: none;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.mainFontFamily};
  color: ${({ theme }) =>
    isDarkColor(theme.contactCardBg) ? theme.contactCardBg : theme.brand500};
  cursor: pointer;
  flex-shrink: 0;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const LoaderWrapper = styled.div`
  height: 50px;
  margin: 0 auto;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
`;

const Divider = styled.div`
  height: 60px;
  flex-shrink: 0;
`;

const ContentWrapper = styled(motion.div)``;

const SEARCH_RADIUS = 500;
const NUMBER_OF_SHOWN_RESULTS = 4;

const FoundLocations = ({
  mapInstance,
  value,
  showLocationOnMap,
  setNumberOfLocations
}) => {
  const [lastRequest, setLastRequest] = useState('');
  const [placesService, setPlacesService] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);

  const [locations, setLocations] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [defaultSearchLocationActived, setDefaultSearchLocationActived] =
    useState(false);

  const locationsToShow = showAll
    ? locations
    : locations.slice(0, NUMBER_OF_SHOWN_RESULTS);

  const onLocationClick = useCallback(
    (location, viewport) => {
      const newLatLong = {
        lat: location.lat(),
        lng: location.lng()
      };
      showLocationOnMap({ location: newLatLong, viewport: viewport });
    },
    [showLocationOnMap]
  );

  useEffect(() => {
    if (!value) {
      setLocations([]);
      setNumberOfLocations(0);
      setLastRequest('');
    }
  }, [value, setNumberOfLocations]);

  useEffect(() => {
    const handlePressEnter = (event) => {
      if (event.key === 'Enter' && defaultSearchLocationActived) {
        onLocationClick(
          locationsToShow[0].geometry.location,
          locationsToShow[0].geometry.viewport
        );
      }
    };

    window.addEventListener('keydown', handlePressEnter);

    return () => {
      window.removeEventListener('keydown', handlePressEnter);
    };
  }, [defaultSearchLocationActived, locationsToShow, onLocationClick]);

  useEffect(() => {
    if (window.google && mapInstance && !placesService) {
      setPlacesService(
        new window.google.maps.places.PlacesService(mapInstance)
      );
    }
  }, [mapInstance, placesService]);

  useEffect(() => {
    if (mapInstance) {
      setMapCenter(mapInstance.getCenter());
    }
  }, [mapInstance]);

  useEffect(() => {
    setError(false);
    if (!value) return;
    if (placesService && value !== lastRequest) {
      setLastRequest(value);
      setLoading(true);

      const request = {
        location: mapCenter,
        radius: String(SEARCH_RADIUS),
        query: value
      };

      placesService.textSearch(request, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          setLoading(false);
          setDefaultSearchLocationActived(true);
          setLocations(results);
          setNumberOfLocations(results.length);
        } else {
          setError(true);
        }
      });
    }
  }, [value, lastRequest, placesService, mapCenter, setNumberOfLocations]);

  const moreToShow = Math.max(locations.length - NUMBER_OF_SHOWN_RESULTS, 0);

  const content = loading ? (
    <LoaderWrapper>
      <ShowcaseLoader />
    </LoaderWrapper>
  ) : (
    <ContentWrapper
      initial={{ opacity: 0, x: -15 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 15 }}
    >
      {locationsToShow.map((loc, i) => (
        <CardWrapper
          key={i}
          onClick={() =>
            onLocationClick(loc.geometry.location, loc.geometry.viewport)
          }
          isActive={i === 0 && defaultSearchLocationActived}
          onMouseEnter={() => setDefaultSearchLocationActived(false)}
        >
          <LocationIcon icon={['far', 'map-marker-alt']} size="1x" />
          {loc.formatted_address}
        </CardWrapper>
      ))}
      {!!moreToShow ? (
        <ShowMoreButton onClick={() => setShowAll((prev) => !prev)}>
          {showAll
            ? localizer.marketplace.showLess
            : localizer.formatString(
                localizer.marketplace.showMore,
                moreToShow
              )}
        </ShowMoreButton>
      ) : (
        <Divider />
      )}
    </ContentWrapper>
  );

  return (
    <Wrapper>
      {error ? (
        <ErrorMessage>{localizer.marketplace.cantFindLocations}</ErrorMessage>
      ) : (
        <AnimatePresence>{content}</AnimatePresence>
      )}
    </Wrapper>
  );
};

export default FoundLocations;
