import React, { useEffect, useState } from 'react';

// Components
import Navigation from './Navigation';
import { motion, AnimatePresence } from 'framer-motion';
import FilterBlock from '../portfolioFilter/FilterBlock';
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { useMarketplaceState } from 'stores/MarketplaceStore';
import { isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import { unitMatchFilter } from '../PortfolioPageHelpers';
import isEqual from 'lodash.isequal';

// Images
import WizardBedrooms from 'resources/images/wizardBedrooms.jpeg';
import WizardBathrooms from 'resources/images/wizardBathrooms.jpeg';
import WizardSurface from 'resources/images/wizardSurface.jpeg';
import WizardPrice from 'resources/images/wizardPrice.jpeg';
import WizardPropertyType from 'resources/images/wizardPropertyType.jpeg';

// Styling
import styled, { css } from 'styled-components';

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Wizard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 160px);
  height: calc(100% - 100px);
  border-radius: 4px;
  display: grid;
  grid-template: auto / 37% 63%;
  overflow: hidden;
  ${isMobileOnly &&
  css`
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    grid-template: ${({ showMobileQuestions }) =>
        showMobileQuestions ? '1fr' : '1fr minmax(40%, max-content)'} / auto;
  `}
`;

const CompanyLogo = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const Image = styled(motion.img)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0 0 20px 0;
  color: ${({ theme }) => theme.showcaseBlack};
  text-align: center;
  ${isMobileOnly &&
  css`
    margin-bottom: 15px;
  `}
`;

const Subtitle = styled.p`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.primary100};
  text-align: center;
`;

const ContentHeader = styled.div`
  padding: 80px 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${isMobileOnly &&
  css`
    padding: 15px 30px;
  `}
`;

const ContentMain = styled.div`
  padding: 15px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  ${isMobileOnly &&
  css`
    padding: 40px 15px 10px;
  `}
`;

const ContentFooter = styled(motion.div)`
  height: 100px;
  flex-shrink: 0;
  padding: 10px 30px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.gray300};
  background-color: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  ${isMobileOnly &&
  css`
    height: 70px;
    padding: 15px;
  `}
`;

const FilterTitle = styled.p`
  font-size: 3rem;
  color: ${({ theme }) => theme.showcaseBlack};
  margin: 0 0 20px;
  text-align: center;
  ${isMobileOnly &&
  css`
    font-size: 1.5rem;
  `}
`;

const FilterWrapper = styled.div`
  width: 100%;
  max-height: 150px;
  display: flex;
  justify-content: ${({ justify }) => justify};
  ${isMobileOnly &&
  css`
    margin: auto 0;
    max-height: 40vh;
  `}
  ${({ styles }) => styles}
`;

const ActiveFilterBlock = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 14px;
  top: 16px;
  background: none;
  outline: none;
  border: none;
  width: fit-content;
  cursor: pointer;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${(props) => props.theme.primary100};
`;

const MobileButtonsGroup = styled(motion.div)`
  width: 100%;
  padding: 15px 15px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  flex-shrink: 0;
  border: none;
  color: ${({ theme }) => theme.primary100};
  background-color: ${({ theme }) => theme.showcaseWhite};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartButton = styled(Button)`
  background-color: ${({ theme }) => theme.mainAccent};
  color: ${({ theme }) => theme.showcaseWhite};
`;

const SkipButton = styled(Button)``;

const Info = styled.div`
  color: ${({ theme }) => theme.primary100};
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const chipStyles = css`
  height: 60px;
  min-width: 60px;
  border-radius: 30px;
  font-size: 1.25rem;
  ${isMobileOnly &&
  css`
    height: 50px;
    min-width: 50px;
    border-radius: 25px;
    font-size: 1rem;
  `}
`;

const mapFilterByKey = {
  numberOfBedrooms: {
    name: 'numberOfBedrooms',
    displayMax: 4,
    chipStyles
  },
  numberOfBathrooms: {
    name: 'numberOfBathrooms',
    displayMax: 3,
    chipStyles
  },
  surface: {
    name: 'surface',
    title: 'surface',
    hideTitle: true,
    type: 'range',
    styles: css`
      overflow: visible;
    `
  },
  price: {
    name: 'price',
    title: 'price',
    hideTitle: true,
    type: 'range',
    styles: css`
      overflow: visible;
    `
  },
  propertyType: {
    name: 'propertyType',
    chipStyles: css`
      height: 60px;
      min-width: 140px;
      border-radius: 30px;
    `,
    optionsWrapperStyles: css`
      overflow: auto;
      flex-wrap: ${isMobileOnly ? 'wrap' : 'nowrap'};
      justify-content: space-around;
    `
  }
};

// TODO: need settings for a Wizard
const company = {
  title: 'Company'
};

const availableFilters = [
  'numberOfBedrooms',
  'numberOfBathrooms',
  'surface',
  'price',
  'propertyType'
];

const wizardImages = {
  numberOfBedrooms: WizardBedrooms,
  numberOfBathrooms: WizardBathrooms,
  surface: WizardSurface,
  price: WizardPrice,
  propertyType: WizardPropertyType
};

const calculateUsedFilters = (filter) => {
  const usedFilters = [];
  for (let key in filter) {
    if (
      filter[key].selected?.length > 0 ||
      filter[key].limits?.min !== filter[key].values?.min ||
      filter[key].limits?.max !== filter[key].values?.max
    ) {
      usedFilters.push(key);
    }
  }
  return usedFilters;
};

const calculateFilteredProjects = (projectsData, filter) => {
  const filteredProjectIds = Object.entries(projectsData)
    .filter(([, { units }]) =>
      units.some((unit) => unitMatchFilter(unit, filter))
    )
    .map(([projectId]) => projectId);
  return filteredProjectIds;
};

const PortfolioWizard = ({
  preShow,
  show,
  onClose,
  shouldShowPrices,
  applyFilters
}) => {
  // Marketplace state
  const { MarketplaceState, MarketplaceStateDispatch } = useMarketplaceState();
  const { wizardFilter, initialFilter, projectsData } = MarketplaceState;

  const [filterKeys, setFilterKeys] = useState([]);
  const [activeFilterIdx, setActiveFilterIdx] = useState(0);

  const [activeFilter, setActiveFilter] = useState();
  const [filterBlockProps, setFilterBlockProps] = useState();

  const [sliding, setSliding] = useState(false);
  const [showQuestionsMobile, setShowQuestionsMobile] = useState(false);

  const [wizardImageLoaded, setWizardImageLoaded] = useState(false);

  const L = localizer.wizard;

  const closeWizard = () => {
    MarketplaceStateDispatch({
      type: 'setMarkerplaceData',
      payload: {
        wizardFilter: initialFilter
      }
    });
    setActiveFilterIdx(0);
    onClose();
    setWizardImageLoaded(false);
  };

  const applyAndCloseWizard = () => {
    // do not apply filter if none options were selected
    if (isEqual(wizardFilter, initialFilter)) {
      closeWizard();
      return;
    }

    const usedFilters = calculateUsedFilters(wizardFilter);
    const filteredProjectIds = calculateFilteredProjects(
      projectsData,
      wizardFilter
    );
    MarketplaceStateDispatch({
      type: 'setMarkerplaceData',
      payload: {
        sharedFilter: wizardFilter,
        appliedFilters: usedFilters,
        usedFilters,
        filteredProjectIds
      }
    });
    closeWizard();
    applyFilters(filteredProjectIds);
  };

  const updateActiveFilter = (filterName, filterValues) => {
    MarketplaceStateDispatch({
      type: 'setMarkerplaceData',
      payload: {
        wizardFilter: {
          ...wizardFilter,
          [filterName]: filterValues
        }
      }
    });
  };

  const slide = (direction) => {
    setSliding(true);
    setActiveFilterIdx((prev) => prev + direction);

    setTimeout(() => {
      setSliding(false);
    }, 100);
  };

  useEffect(() => {
    if (filterKeys.length === 0) return;
    const filterKey = filterKeys[activeFilterIdx];
    if (filterKey === 'price' && !shouldShowPrices) {
      setFilterKeys((prev) => prev.filter((x) => x !== 'price'));
      return;
    }
    setActiveFilter(wizardFilter[filterKey]);
    setFilterBlockProps(mapFilterByKey[filterKey]);
  }, [filterKeys, activeFilterIdx, wizardFilter, shouldShowPrices]);

  useEffect(() => {
    if (wizardFilter) {
      setFilterKeys(
        Object.keys(wizardFilter)
          .sort()
          // filter out filters for which we don't need a wizard step
          .filter((key) => key !== 'projectStatus')
      );
    }
  }, [wizardFilter]);

  if (!preShow) return null;

  return (
    <AnimatePresence>
      {preShow && filterBlockProps && (
        <Backdrop
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: show ? 1 : 0,
            y: show ? 0 : -90
          }}
          transition={{ ease: 'linear', duration: 0.2 }}
        >
          <Wizard showMobileQuestions={showQuestionsMobile}>
            {!isMobileOnly && (
              <CloseButton onClick={closeWizard}>
                <CloseIcon icon={['fal', 'times']} size="1x" />
              </CloseButton>
            )}

            {(!isMobileOnly || (isMobileOnly && !showQuestionsMobile)) && (
              <CompanyLogo>
                {availableFilters.map((filter) => (
                  <Image
                    key={filter}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity:
                        filterBlockProps?.name === filter && wizardImageLoaded
                          ? 1
                          : 0
                    }}
                    src={wizardImages[filter]}
                    alt=""
                    onLoad={() => {
                      if (!wizardImageLoaded) setWizardImageLoaded(true);
                    }}
                  />
                ))}
              </CompanyLogo>
            )}

            <Content>
              {(!isMobileOnly || (isMobileOnly && !showQuestionsMobile)) && (
                <ContentHeader>
                  <Title>
                    {localizer.formatString(L.title, company.title)}
                  </Title>
                  <Subtitle>{L.subtitle}</Subtitle>
                </ContentHeader>
              )}

              {(!isMobileOnly || (isMobileOnly && showQuestionsMobile)) && (
                <>
                  <ContentMain>
                    <AnimatePresence>
                      {!sliding ? (
                        <ActiveFilterBlock
                          initial={{ scale: 0.9, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0.9, opacity: 0 }}
                          transition={{ ease: 'linear' }}
                        >
                          <FilterTitle>{L[filterBlockProps.name]}</FilterTitle>
                          <FilterWrapper
                            styles={
                              filterBlockProps.name === 'propertyType'
                                ? css`
                                    overflow: auto;
                                  `
                                : ``
                            }
                            justify={
                              filterBlockProps.name === 'propertyType'
                                ? 'flex-start'
                                : 'center'
                            }
                          >
                            <FilterBlock
                              options={activeFilter}
                              rangeOptions={activeFilter}
                              setOptions={updateActiveFilter}
                              overflowText={localizer.marketplace.orMore}
                              {...filterBlockProps}
                            />
                          </FilterWrapper>

                          <Info>
                            <InfoIcon icon={['far', 'info-circle']} size="1x" />
                            {filterBlockProps.type === 'range'
                              ? L.canChangeLater
                              : L.selectOptions}
                          </Info>
                        </ActiveFilterBlock>
                      ) : (
                        <ShowcaseLoader color={'grey'} />
                      )}
                    </AnimatePresence>
                  </ContentMain>
                  <ContentFooter
                    initial={{ y: 110 }}
                    animate={filterKeys.length > 0 ? { y: 0 } : { y: 110 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Navigation
                      currentFilterIdx={activeFilterIdx}
                      filters={filterKeys}
                      onPrev={() => slide(-1)}
                      onNext={() => slide(1)}
                      onGetStarted={applyAndCloseWizard}
                    />
                  </ContentFooter>
                </>
              )}

              {isMobileOnly && !showQuestionsMobile && (
                <MobileButtonsGroup>
                  <StartButton onClick={() => setShowQuestionsMobile(true)}>
                    {L.start}
                  </StartButton>
                  <SkipButton onClick={closeWizard}>{L.skip}</SkipButton>
                </MobileButtonsGroup>
              )}
            </Content>
          </Wizard>
        </Backdrop>
      )}
    </AnimatePresence>
  );
};

export default PortfolioWizard;
