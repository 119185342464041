import React, { useState, useEffect, useRef } from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  useMatch,
  useResolvedPath,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

// Components
import UnitDetailPage from './components/pages/unitDetail/UnitDetailPage';
import UnitsPage from './components/pages/unitList/UnitsPage';
import ResourcesPage from 'components/pages/resources/ResourcesPage';
import TourPage from './components/pages/tour/TourPage';
import MapPage from './components/pages/map/MapPage';
import MorePage from './components/pages/more/MorePage';
import ProjectInformationPage from 'components/pages/project-information/ProjectInformationPage';
import DocumentViewerPage from 'components/pages/document-viewer/DocumentViewerPage';
import ShareProjectPage from 'components/pages/share-project/ShareProjectPage';
import FavoritesPage from 'components/pages/favoritesList/FavoritesPage';

import NavigationMenu from './components/navigation/NavigationMenu';
import ConfirmationModal from 'components/other/ConfirmationModal';
import { AnimatePresence, motion } from 'framer-motion';
import ShareModal from 'components/share/ShareModal';
import IntroVideo from 'components/introVideo/IntroVideo';
import InfoWall from 'components/infoWall/infoWall';
import EnlargedUsp from 'components/enlargedUsp/EnlargedUsp';
import EmbeddedShowcaseCoverImage from 'components/embeddedShowcaseCoverImage/EmbeddedShowcaseCoverImage';
import ShowcaseBackButton from 'components/other/ShowcaseBackButton';

import OnboardingScreens, {
  showOnboardingScreensCookie
} from 'components/share/onboardingScreens/OnboardingScreens';
import { theme } from '../main';
import ContactUsModal from 'components/share/ContactUs/ContactUsModal';
import TurntablePage from 'components/pages/turntable/TurntablePage';

// Helpers
import {
  ProjectFolderConfiguration,
  ContentCollection,
  ShowcaseConfiguration,
  Visitor,
  Project,
  Tracking,
  ShareItem,
  NavigationItem,
  UniqueSellingPoint,
  CustomFieldType,
  Turntable
} from '@prompto-api';

import ShowcaseLoader from 'components/other/ShowcaseLoader';
import { useProjectState } from 'stores/ProjectStore';
import { useStreamState } from 'stores/StreamStore';
import { useAuthState } from 'stores/AuthStore';
import { useTourState } from 'stores/TourStore';
import { useUiState } from 'stores/UiStore';
import MapStore from 'stores/MapStore';
import localizer from 'localization/localizer';
import SwitcherStore from 'stores/SwitcherStore';
import {
  capitalize,
  isShareCodeInvalid,
  canParseJSON,
  getCurrentEnvironment,
  checkSettingsAgainstFeatureFlags,
  getUserData,
  fetchSettingsFromURL
} from 'helpers';
import {
  filteredUnitListForProjectStore,
  setCurrencyCode
} from 'helpers/units/VmUnitHelper';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import Cookies from 'universal-cookie';
import to from 'await-to-js';
import {
  useCustomShowcaseTheme,
  useFavoriteUnitsList,
  useUnitsFilterParams,
  useGoogleTagManager,
  usePreloadImages
} from 'helpers/customHooks';

// Styling
import styled, { ThemeProvider, css } from 'styled-components';

const cookies = new Cookies();

const Wrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.whitePure};
  ${isMobile &&
  `
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
    `}
`;

const MainViewWrapper = styled.div`
  height: ${({ showmenu }) => (showmenu ? 'calc(100% - 80px)' : '100vh')};
  background-color: ${(props) => props.theme.whitePure};
  ${({ showmenu }) => {
    if (!isMobile) return;
    return css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: ${showmenu ? 'calc(100% - 60px)' : '100%'};
    `;
  }}
`;

const NavigationMenuWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  z-index: 51;
  background-color: white;
  ${isMobile &&
  `
    position: fixed;
    bottom: 0;
  `}
`;

const LoaderWrapper = styled.div`
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.whitePure};
`;

const subRoutes = {
  tour: 'tour',
  turntable: 'turntable',
  units: 'units',
  stream: 'stream',
  resources: 'resources',
  contracts: 'contracts',
  map: 'map',
  more: 'more',
  share: 'share',
  projectInformation: 'project_information',
  favorites: 'favorites'
};

const ShowcaseRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname: path } = useResolvedPath();
  const { code } = useParams();
  const createVisitorProcessStarted = useRef(false);
  const createVisitorProcessCounter = useRef(0);

  const docViewerMatch = useMatch(
    `${path}/document_viewer/:objectId/:contentUri`
  );

  // Auth store
  const { AuthState, AuthStateDispatch } = useAuthState();
  const { user, startCreateVisitorProcess, authenticated } = AuthState;
  const { sessionToken, vaultId, allowedOperations, features } = user;

  // Stream store
  const { StreamStateDispatch } = useStreamState();

  // Project store
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const { project, navigationItem, showcaseConfiguration } = ProjectState;

  // Tour store
  const { TourStateDispatch } = useTourState();

  // UI store
  const { UiState, UiStateDispatch } = useUiState();
  const { showMenu, disableMenu } = UiState;

  // Custom showcase theme
  const { customTheme } = useCustomShowcaseTheme(theme);

  // Shareitem related
  const [checkingSharecodeCustomer, setCheckingSharecodeCustomer] =
    useState(true);
  const [sharecodeHasCustomer, setSharecodeHasCustomer] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  const [hasImageTour, setHasImageTour] = useState(false);
  const [error, setError] = useState();

  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  const [isShowOnboarding, setShowOnboarding] = useState(true);

  const [activeSubRoute, setActiveSubRoute] = useState();
  const [tourEnabled, setTourEnabled] = useState(false);
  const [turntableEnabled, setTurntableEnabled] = useState(false);
  const [unitsEnabled, setUnitsEnabled] = useState(false);
  const [streamEnabled, setStreamEnabled] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [showIntroVideo, setShowIntroVideo] = useState(false);
  const [showCoverImage, setShowCoverImage] = useState(false);
  const [isEmbeddedView, setIsEmbeddedView] = useState(false);
  const [infoWallEnabled, setInfoWallEnabled] = useState(false);

  const [turntableImageSrcs, setTurntableImageSrcs] = useState([]);

  const [, setForcedLanguage] = useState(localizer.getLanguage());

  useFavoriteUnitsList();
  useUnitsFilterParams(showcaseConfiguration?.hideFilterOptions);
  useGoogleTagManager();
  usePreloadImages(turntableImageSrcs);

  useEffect(() => {
    let sessionHeartInterval;

    const triggerStartSession = () => {
      createVisitorProcessStarted.current = false;
      AuthStateDispatch({ type: 'startCreateVisitorProcess', payload: true });
    };

    if (startCreateVisitorProcess && !createVisitorProcessStarted.current) {
      createVisitorProcessStarted.current = true;
      AuthStateDispatch({ type: 'startCreateVisitorProcess', payload: false });
      createVisitorProcessCounter.current =
        createVisitorProcessCounter.current + 1;
      const visitorIdFromCookie = cookies.get(`showcase_visitor_id`);

      Project.getProjectByCode(code)
        .then(async (result) => {
          const { data } = result;
          const { project } = data;
          const { vault } = project;
          const showcasePromptoSessionCreateParams = {
            vaultId: vault.objectId,
            shareCode: code,
            projectId: project.objectId
          };

          const handleStartSession = (response) => {
            const { visitorObjectId: visitorId, sessionObjectId: sessionId } =
              response.data;
            Tracking.trackShowcaseLoaded({
              shareCode: code,
              visitorId,
              sessionId
            }).catch(() => {
              console.warn('Could not track Showcase loaded action');
            });
            AuthStateDispatch({
              type: 'startVisitorSession',
              payload: sessionId
            });
            AuthStateDispatch({
              type: 'setVisitor',
              payload: visitorId
            });
            sessionHeartInterval = setInterval(() => {
              Visitor.updateSession({ sessionId }).catch(() => {
                console.warn('Could not update the end session timestamp');
              });
            }, 10000);

            cookies.set(`showcase_visitor_id`, visitorId, {
              maxAge: 315360000000, // 10 years,
              sameSite: 'none',
              secure: true
            });
          };

          if (visitorIdFromCookie) {
            Visitor.startSession({
              visitorId: visitorIdFromCookie,
              showcasePromptoSessionCreateParams
            })
              .then((response) => handleStartSession(response))
              .catch(() => {
                // do one more try
                if (createVisitorProcessCounter.current < 2) {
                  triggerStartSession();
                }
              });
          } else {
            let visitorCreateParams = {};
            if (import.meta.env.VITE_APP_PROD === 'true') {
              const userBrowserData = await getUserData();

              const userAgentData = navigator?.userAgentData;
              const userDeviceData = {
                mobile: userAgentData?.mobile,
                platform: userAgentData?.platform
              };

              visitorCreateParams = {
                ipAddress: userBrowserData?.ip,
                network: userBrowserData?.network,
                version: userBrowserData?.version,
                city: userBrowserData?.city,
                region: userBrowserData?.region,
                region_code: userBrowserData?.region_code,
                country: userBrowserData?.country,
                country_code: userBrowserData?.country_code,
                country_code_iso3: userBrowserData?.country_code_iso3,
                country_name: userBrowserData?.country_name,
                country_capital: userBrowserData?.country_capital,
                country_tld: userBrowserData?.country_tld,
                continent_code: userBrowserData?.continent_code,
                in_eu: userBrowserData?.in_eu,
                postal: userBrowserData?.postal,
                latitude: userBrowserData?.latitude,
                longitude: userBrowserData?.longitude,
                timezone: userBrowserData?.timezone,
                utc_offset: userBrowserData?.utc_offset,
                country_calling_code: userBrowserData?.country_calling_code,
                currency: userBrowserData?.currency,
                currency_name: userBrowserData?.currency_name,
                languages: userBrowserData?.languages,
                country_area: userBrowserData?.country_area,
                asn: userBrowserData?.asn,
                country_population: userBrowserData?.country_population,
                org: userBrowserData?.org,
                ...userDeviceData
              };
            }
            Visitor.startSession({
              showcasePromptoSessionCreateParams,
              visitorCreateParams
            })
              .then((response) => handleStartSession(response))
              .catch(() => {
                // do one more try
                if (createVisitorProcessCounter.current < 2) {
                  triggerStartSession();
                }
              });
          }
        })
        .catch(() => {
          // do one more try
          if (createVisitorProcessCounter.current < 2) {
            triggerStartSession();
          }
        });
    }
    return () => {
      if (sessionHeartInterval) clearInterval(sessionHeartInterval);
    };
  }, [code, vaultId, startCreateVisitorProcess, AuthStateDispatch]);

  // enable / disable the InfoWall
  useEffect(() => {
    setInfoWallEnabled(
      !authenticated && !checkingSharecodeCustomer && !sharecodeHasCustomer
    );
  }, [authenticated, checkingSharecodeCustomer, sharecodeHasCustomer]);

  // Show cover image if the Showcase is embedded
  useEffect(() => {
    if (window.self !== window.top) {
      setShowCoverImage(true);
      setIsEmbeddedView(true);
      const handler = (event) => {
        if (event?.data && canParseJSON(event?.data)) {
          const data = JSON.parse(event.data);
          if (data.type === 'SYNC_LOCALIZATION' && data.language) {
            localizer.setLanguage(data.language);
            // by updating the state we force the localizer to apply changes
            setForcedLanguage(data.language);
          }
        }
      };

      window.addEventListener('message', handler);
      return () => window.removeEventListener('message', handler);
    }
  }, []);

  // Don't show the Onboarding screens if respective cookie exists
  useEffect(() => {
    const cookies = new Cookies();
    const showOnboardingScreens = cookies.get(showOnboardingScreensCookie);
    if (showOnboardingScreens) {
      setShowOnboarding(false);
    }
  }, []);

  // Get project data
  useEffect(() => {
    if (code !== 'null' && !isInitialized) {
      /**
       * Step #0 Get the shareItem object and check if the code is valid
       * If it's valid, go to the next step
       */
      ShareItem.getByCode(code, sessionToken)
        .then((result) => {
          const shareItem = result?.data?.shareItem;

          // If sharecode has a customer assigned to it
          // and customer has non empty email and firstName properties
          // we do not show the InfoWall
          ShareItem.get(shareItem.objectId)
            .then((result) => {
              setCheckingSharecodeCustomer(false);
              const customer = result.data?.customer;
              if (customer?.firstName && customer?.email) {
                setSharecodeHasCustomer(true);
              }
            })
            .catch(() => {
              setCheckingSharecodeCustomer(false);
            });

          ProjectStateDispatch({
            type: 'setActiveShareItem',
            payload: shareItem
          });

          if (isShareCodeInvalid(shareItem)) {
            setError(true);
            setIsInitialized(true);
          } else {
            /**
             * Step #1 Get only the first image and the configuration of the showcase
             * In case there is an image tour the first image is the image of the landing page
             * In case there is no image tour we get the first image of the project
             */
            ContentCollection.getFirstImageAndShowcaseConfigurationByCode(code)
              .then(async (result) => {
                const { data } = result;
                const {
                  firstImage,
                  showcaseConfiguration: rawShowcaseConfiguration,
                  containsImageTour,
                  startingVideo
                } = data;

                const alternativeShowcaseConfiguration =
                  shareItem.showcaseConfigurationObjectId
                    ? await ShowcaseConfiguration.get(
                        shareItem.showcaseConfigurationObjectId
                      )
                    : null;

                const shareItemShowcaseConfiguration =
                  alternativeShowcaseConfiguration?.data
                    ?.vmShowcaseConfiguration;
                const allowedUnitsIdList =
                  shareItemShowcaseConfiguration?.allowedUnitsIdList;

                // if shareItem has its own showcase configuration, put it in the app state
                // this configuration will be used for the showcase with higher priority under default ones
                // first step - filter out only allowed units
                if (shareItemShowcaseConfiguration) {
                  ProjectStateDispatch({
                    type: 'setProjectData',
                    payload: {
                      shareItemShowcaseConfiguration:
                        shareItemShowcaseConfiguration
                    }
                  });
                }

                const showcaseConfiguration =
                  await checkSettingsAgainstFeatureFlags(
                    rawShowcaseConfiguration
                  );

                if (
                  startingVideo &&
                  (showcaseConfiguration?.showStartingVideo ||
                    showcaseConfiguration?.showStartingVideo === undefined)
                ) {
                  setShowIntroVideo(true);
                }

                ProjectStateDispatch({
                  type: 'setProjectData',
                  payload: {
                    firstImage,
                    showcaseConfiguration,
                    introVideo: startingVideo,
                    project: showcaseConfiguration?.project,
                    vault: showcaseConfiguration?.vault,
                    shareCode: code
                  }
                });

                TourStateDispatch({
                  type: 'update',
                  payload: {
                    showUnits:
                      showcaseConfiguration?.initialTourShowUnits ?? false
                  }
                });
                setHasImageTour(containsImageTour);
                setIsInitialized(true);

                /**
                 * Step #2 Get the image tour
                 */
                NavigationItem.getByShowcaseCode(code).then((result) => {
                  const { data } = result;
                  const { navigationItem, contentCollection } = data;

                  ProjectStateDispatch({
                    type: 'setProjectData',
                    payload: {
                      contentCollection,
                      navigationItem
                    }
                  });

                  // Show navigation menu after the 'tourEnable' is set
                  setTourEnabled(!!navigationItem);

                  const queryParams = fetchSettingsFromURL();
                  const hideMenuParam = queryParams?.hideMenu;
                  if (hideMenuParam !== true) {
                    setShowNavigationMenu(true);
                  } else {
                    UiStateDispatch({
                      type: 'update',
                      payload: { disableMenu: true }
                    });
                  }

                  /**
                   * Step #3 Get the entire project with units
                   */
                  Project.getProjectByCode(code).then((result) => {
                    const { data } = result;
                    const { project } = data;
                    const { vaultSettings } = data;
                    const { vault } = project;

                    // save initial units to make it possible to sort them later
                    ProjectStateDispatch({
                      type: 'setProjectData',
                      payload: {
                        initialUnitList: project?.unitList
                      }
                    });

                    if (vaultSettings) {
                      // Let's store the vault settings in the vault object
                      vault.vaultSettings = vaultSettings;

                      // There is a preferred number notation, apply this only if it's not set to the default "auto"
                      if (
                        vaultSettings.defaultNumberFormat &&
                        vaultSettings.defaultNumberFormat !== 'auto'
                      ) {
                        numeral.locale(vaultSettings.defaultNumberFormat);
                      }

                      if (
                        vaultSettings.defaultCurrencyCode &&
                        vaultSettings.defaultCurrencyCode !== 'auto'
                      ) {
                        setCurrencyCode(vaultSettings.defaultCurrencyCode);
                      }
                    }

                    // We get the unit list fresh from the backend, need to perform couple actions to prepare the unit data for usage in the showcase
                    if (project.unitList) {
                      project.unitList = filteredUnitListForProjectStore({
                        unitList: project.unitList,
                        allowedUnitsIdList: allowedUnitsIdList,
                        contentCollection,
                        showcaseConfiguration
                      });
                    }

                    UniqueSellingPoint.getAll(vault.objectId, project.objectId)
                      .then((result) => {
                        if (result) {
                          const { data } = result;
                          const { vmUniqueSellingPointList } = data;

                          if (vmUniqueSellingPointList) {
                            let newList = vmUniqueSellingPointList.filter(
                              (x) => x.vmUniqueSellingPointState !== 'archived'
                            );
                            ProjectStateDispatch({
                              type: 'setAllUsps',
                              payload: newList
                            });
                          }
                        }
                      })
                      .catch((e) => {});

                    // Get the turntables
                    if (
                      showcaseConfiguration?.showTurnTable &&
                      showcaseConfiguration?.defaultTurnTableObjectId
                    ) {
                      setTurntableEnabled(true);
                    }

                    const getParams = {
                      projectsSectionObjectId: project.objectId,
                      vaultObjectId: vault.objectId,
                      turnTableState: 'active'
                    };
                    Turntable.getAll(getParams, sessionToken)
                      .then((result) => {
                        if (result) {
                          const { data } = result;
                          const { vmTurnTableList } = data;

                          // For each turntable, get the loaded version
                          const turntablePromises = vmTurnTableList.map(
                            async (item) => {
                              const [, completeItem] = await to(
                                Turntable.get(item.objectId, {
                                  includeNavigationTargetList: false
                                })
                              );
                              return completeItem.data.vmTurnTable;
                            }
                          );

                          Promise.all(turntablePromises).then(
                            (turntableList) => {
                              const turntablesWithContentItem =
                                turntableList.map((x) => {
                                  const { turnPointOfViewList } = x;

                                  return {
                                    ...x,
                                    contentItem:
                                      turnPointOfViewList[0]?.contentItem
                                  };
                                });

                              ProjectStateDispatch({
                                type: 'setAllTurntable',
                                payload: turntablesWithContentItem
                              });

                              if (
                                showcaseConfiguration?.showTurnTable &&
                                showcaseConfiguration?.defaultTurnTableObjectId
                              ) {
                                const defaultTurntable =
                                  turntablesWithContentItem.filter(
                                    (x) =>
                                      x.objectId ===
                                      showcaseConfiguration.defaultTurnTableObjectId
                                  )[0];
                                if (defaultTurntable) {
                                  ProjectStateDispatch({
                                    type: 'setTurntable',
                                    payload: defaultTurntable
                                  });

                                  if (defaultTurntable.turnPointOfViewList) {
                                    const imageSrcs = [];
                                    const baseUrl =
                                      getCurrentEnvironment().baseImageUrl;
                                    defaultTurntable.turnPointOfViewList.forEach(
                                      (turnPoint, idx) => {
                                        const fullUri = `${baseUrl}/h=250/${turnPoint?.contentItem?.contentUri}`;
                                        imageSrcs.push(fullUri);
                                        if (idx === 0) {
                                          imageSrcs.push(
                                            `${baseUrl}/h=800/${turnPoint?.contentItem?.contentUri}`
                                          );
                                        }
                                      }
                                    );
                                    setTurntableImageSrcs(imageSrcs);
                                  }
                                }
                              } else {
                                ProjectStateDispatch({
                                  type: 'setTurntable',
                                  payload: 'notPresent'
                                });
                              }
                            }
                          );
                        }
                      })
                      .catch((e) => {});

                    const getVaultCustomFieldRequestParams = {
                      vaultObjectId: vault.objectId
                    };
                    // Get vault custom fields
                    CustomFieldType.getAll(getVaultCustomFieldRequestParams)
                      .then((result) => {
                        if (result) {
                          const { data } = result;
                          const { vmCustomFieldTypeList } = data;

                          let worker;

                          if (typeof Worker !== 'undefined') {
                            if (typeof worker === 'undefined') {
                              worker = new Worker(
                                '/customFieldsTransformWorker.js'
                              );
                            }
                            worker.postMessage({ vmCustomFieldTypeList });
                            worker.onmessage = (e) => {
                              const { map } = e.data;
                              ProjectStateDispatch({
                                type: 'setProjectData',
                                payload: {
                                  customFieldsMap: map
                                }
                              });
                              worker.terminate();
                            };
                          }
                        }
                      })
                      .catch(() => {});

                    ProjectStateDispatch({
                      type: 'setProjectData',
                      payload: {
                        project: data.project, // todo: check if it might rewrite filtered units or other stuff
                        showcaseLoaded: true,
                        vault: vault
                      }
                    });

                    // get project media folders configuration
                    ProjectFolderConfiguration.get(project?.objectId)
                      .then((result) => {
                        const folderList =
                          result.data?.vmProjectFolderConfiguration?.folderList;
                        if (folderList) {
                          ProjectStateDispatch({
                            type: 'setProjectFolderConfiguration',
                            payload: folderList
                          });
                        }
                      })
                      .catch((err) => {
                        ProjectStateDispatch({
                          type: 'setProjectFolderConfiguration',
                          payload: []
                        });
                      });
                  });
                });
              })
              .catch((error) => {
                console.log(error);
                setError(error?.response?.data || error);
                setIsInitialized(true);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setError(error?.response?.data || error);
          setIsInitialized(true);
        });
    }
  }, [
    code,
    ProjectStateDispatch,
    TourStateDispatch,
    vaultId,
    sessionToken,
    allowedOperations,
    isInitialized,
    features,
    UiStateDispatch
  ]);

  // Whenever we navigate, trigger the appcues page
  useEffect(() => {
    if (window && window.Appcues) {
      window.Appcues.page();
    }

    const subRoute = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    setActiveSubRoute(subRoute);
  }, [location]);

  // Determine what nav buttons should be present
  useEffect(() => {
    setUnitsEnabled(project?.unitList?.length > 0);

    const iFrameContentList =
      project?.vmContentCollection?.vmContentItemList?.filter(
        (x) => x.contentItemType === 'iFrame'
      );
    setStreamEnabled(iFrameContentList?.length > 0 && user?.sessionToken);
  }, [project, navigationItem, user]);

  // Setup heartbeat event
  useEffect(() => {
    if (code) {
      //heartbeat event, needed to make sure Google Analytics recognises all events etc as the same 'session'
      //we send this not through Segment, but directly to Google Analytics, to avoid hitting the rate limits of Segment (it's only needed for GA anyway)
      setInterval(() => {
        if (window.ga && !window.disableAllTracking) {
          window.ga('send', 'event', 'heartbeat', 'alive');
        }
      }, 10000);
    }
  }, [code]);

  // Menu functions
  const navigateTo = (subRoute) => {
    const url = `/${code}/${subRoute}${window.location.search}`;

    UiStateDispatch({
      type: 'update',
      payload: { enableShowcaseBackButton: true }
    });
    UiStateDispatch({
      type: 'increaseStack'
    });

    navigate(url);
  };

  const onTourClick = () => {
    navigateTo(subRoutes.tour);
    TourStateDispatch({ type: 'reset' });
  };

  const onTurntableClick = () => {
    navigateTo(subRoutes.turntable);
  };

  const onUnitsClick = () => {
    navigateTo(subRoutes.units);
  };

  const onResourcesClick = () => {
    navigateTo(subRoutes.resources);
  };

  const onMapClick = () => {
    navigateTo(subRoutes.map);
  };

  const onUnitDetailClick = (unit) => {
    const unitId = unit?.objectId;
    if (unitId) {
      UiStateDispatch({
        type: 'update',
        payload: { enableShowcaseBackButton: true }
      });
      UiStateDispatch({
        type: 'increaseStack'
      });
      navigate(`/${code}/unit/${unitId}${window.location.search}`);
    }
  };

  const onStreamClick = () => {
    const iFrameContentList =
      project?.vmContentCollection?.vmContentItemList?.filter(
        (x) => x.contentItemType === 'iFrame'
      );

    if (iFrameContentList?.length > 0) {
      const iFrameToOpen = iFrameContentList[0];

      StreamStateDispatch({
        type: 'open',
        payload: {
          streamType: 'url',
          streamUrl: iFrameToOpen.value,
          project: project,
          useProjectGallery: true,
          sessionToken: user?.sessionToken
        }
      });
    }
  };

  const onMoreClick = () => {
    navigateTo(subRoutes.more);
  };

  // Navigation Menu
  let navigationMenu;
  if (showNavigationMenu) {
    const navigationButtons = [];

    // Tour
    if (tourEnabled) {
      navigationButtons.push({
        id: 'tourButton',
        icon: ['fal', 'compass'],
        iconSize: '1x',
        active: activeSubRoute === subRoutes.tour,
        callback: onTourClick,
        text: capitalize(localizer.tour)
      });
    } else if (!isMobile) {
      // Resources - no tour
      navigationButtons.push({
        id: 'resourcesButton',
        icon: ['fal', 'folder'],
        iconSize: '1x',
        active: activeSubRoute === subRoutes.resources,
        callback: onResourcesClick,
        text: capitalize(localizer.resources)
      });
    }

    if (turntableEnabled) {
      navigationButtons.push({
        id: 'turntableButton',
        icon: ['fal', 'cube'],
        iconSize: '1x',
        active: activeSubRoute === subRoutes.turntable,
        callback: onTurntableClick,
        text: 'Turntable'
      });
    }

    // Stream - do not show if mobile or tablet
    if (streamEnabled && !isMobile) {
      navigationButtons.push({
        id: 'streamButton',
        icon: ['fal', 'tv-alt'],
        iconSize: '1x',
        active: activeSubRoute === subRoutes.stream,
        callback: onStreamClick,
        text: capitalize(localizer.maquette)
      });
    }

    // Map
    navigationButtons.push({
      id: 'mapButton',
      icon: ['fal', 'map'],
      iconSize: '1x',
      active: activeSubRoute === subRoutes.map,
      callback: onMapClick,
      text: capitalize(localizer.map)
    });

    // Units
    if (unitsEnabled) {
      navigationButtons.push({
        id: 'unitsButton',
        icon: ['fal', 'building'],
        iconSize: '1x',
        active: activeSubRoute === subRoutes.units,
        callback: onUnitsClick,
        text: capitalize(localizer.units)
      });
    }
    if (tourEnabled && !isMobile) {
      // Resources - with tour - do not show if mobile or tablet
      navigationButtons.push({
        id: 'resourcesButton',
        icon: ['fal', 'folder'],
        iconSize: '1x',
        active: activeSubRoute === subRoutes.resources,
        callback: onResourcesClick,
        text: capitalize(localizer.resources)
      });
    }

    // Add More button
    if (isMobile) {
      navigationButtons.push({
        id: 'moreButton',
        icon: ['fal', 'ellipsis-h-alt'],
        iconSize: '1x',
        active: [
          subRoutes.more,
          subRoutes.projectInformation,
          subRoutes.resources,
          subRoutes.share
        ].includes(activeSubRoute),
        callback: onMoreClick,
        text: capitalize(localizer.more)
      });
    }

    navigationMenu = (
      <NavigationMenuWrapper key="menu">
        <NavigationMenu
          data={navigationButtons}
          onShareClick={() => {
            setShowShareModal(true);
          }}
          tourEnabled={tourEnabled}
        />
      </NavigationMenuWrapper>
    );
  }

  // Share modal
  let shareModal;
  if (showShareModal) {
    shareModal = (
      <ShareModal
        key="modal"
        shareShowcase={true}
        onCloseModal={() => {
          setShowShareModal(false);
        }}
      />
    );
  }

  if (error) {
    return (
      <ConfirmationModal
        open={true}
        onConfirm={() => {
          navigate(`/`);
        }}
        title={localizer.invalidShowcaseTitle}
        descriptionBlocks={[
          localizer.formatString(localizer.invalidShowcaseDescription, code)
        ]}
        confirmButtonText={localizer.tryDifferentCode}
      />
    );
  }

  if (!isInitialized) {
    return (
      <LoaderWrapper height={window.innerHeight}>
        <ShowcaseLoader color={'grey'} />
      </LoaderWrapper>
    );
  }

  if (docViewerMatch?.isExact) {
    return (
      <Wrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Route
          path={`${path}/document_viewer/:objectId/:contentUri`}
          element={<DocumentViewerPage key="DocumentViewer" />}
        />
      </Wrapper>
    );
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Wrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* ===== OVERLAYS START ===== */}
        <IntroVideo
          show={showIntroVideo}
          onClose={() => setShowIntroVideo(false)}
        />
        <ShowcaseBackButton />

        <OnboardingScreens
          show={isShowOnboarding}
          showEmbeddedView={isEmbeddedView}
          onClose={() => setShowOnboarding(false)}
        />
        <ContactUsModal />
        {infoWallEnabled && <InfoWall />}
        <EnlargedUsp />
        <EmbeddedShowcaseCoverImage
          show={showCoverImage}
          onClose={() => setShowCoverImage(false)}
        />
        {/* ===== OVERLAYS END ===== */}

        <MainViewWrapper id="mainContainer" showmenu={showMenu && !disableMenu}>
          <MapStore>
            <SwitcherStore>
              <AnimatePresence exitBeforeEnter={true}>
                <Routes>
                  {/* Image tour */}
                  <Route path={`/${subRoutes.tour}`} element={<TourPage />} />

                  {/* Turntable */}
                  <Route
                    path={`/${subRoutes.turntable}`}
                    element={
                      <TurntablePage onOpenUnitPage={onUnitDetailClick} />
                    }
                  />

                  {/* Unit list */}
                  <Route
                    path={`/${subRoutes.units}`}
                    element={<UnitsPage onOpenUnitPage={onUnitDetailClick} />}
                  />

                  {/* Favorites list */}
                  <Route
                    path={`/${subRoutes.favorites}`}
                    element={
                      <FavoritesPage onOpenUnitPage={onUnitDetailClick} />
                    }
                  />

                  {/* Resources */}
                  <Route
                    path={`/${subRoutes.resources}`}
                    element={<ResourcesPage key="resources" />}
                  />

                  {/* Unit Detail */}
                  <Route path={`/unit/:unitId`} element={<UnitDetailPage />} />

                  {/* Map */}
                  <Route path={`/map`} element={<MapPage key="Map" />} />

                  {/* More - available for mobile and tablet only */}
                  <Route path={`/more`} element={<MorePage key="More" />} />

                  {/* Project information - subpage available from the More page */}
                  <Route
                    path={`/project_information`}
                    element={
                      <ProjectInformationPage key="ProjectInformation" />
                    }
                  />

                  {/* Document viewer - view PDF documents in separate window */}
                  <Route
                    path={`/document_viewer/:objectId/:contentUri`}
                    element={<DocumentViewerPage key="DocumentViewer" />}
                  />

                  {/* Share project - available for mobile and tablet only (it exists as a modal on desktops) */}
                  <Route
                    path={`/share`}
                    element={<ShareProjectPage key="Share" />}
                  />

                  {/* Fall back redirect in case of non-specific link */}
                  <Route
                    path={`/`}
                    Component={() => {
                      const defaultSubRoute = hasImageTour
                        ? subRoutes.tour
                        : subRoutes.resources;
                      return (
                        <Navigate
                          to={`/${code}/${defaultSubRoute}${window.location.search}`}
                          replace={true}
                        />
                      );
                    }}
                  />

                  {/* Fallback old 'showcase' links */}
                  <Route
                    path={`/showcase`}
                    Component={() => {
                      const defaultSubRoute = hasImageTour
                        ? subRoutes.tour
                        : subRoutes.resources;

                      return (
                        <Navigate
                          to={`/${code}/${defaultSubRoute}${window.location.search}`}
                        />
                      );
                    }}
                  />
                </Routes>
              </AnimatePresence>
            </SwitcherStore>
          </MapStore>
        </MainViewWrapper>
        <AnimatePresence>
          {!disableMenu && navigationMenu}
          {shareModal}
        </AnimatePresence>
      </Wrapper>
    </ThemeProvider>
  );
};

export default ShowcaseRouter;
