import React, { useState, useEffect } from 'react';
import { array, shape, func, string } from 'prop-types';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import UnitContentCard from './UnitContentCard';

// Helpers
import { getCurrentEnvironment } from 'helpers';
import { AnimatePresence } from 'framer-motion';
import { Misc } from '@prompto-api';

// Styling
import styled from 'styled-components';

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
`;

const ContentList = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: flex;
`;

const UnitContentList = ({
  contentItems,
  album360Code,
  sceneContentId,
  externalExperienceUrl,
  selectedContentItem,
  onSelectContentItem,
  on360Selected,
  onSceneSelected,
  onExternalExperienceSelected,
  fallbackThumbnail
}) => {
  const previewTransform = 'q=80:w=200';

  const [loading, setLoading] = useState(false);

  const [album360CoverImage, setAlbum360CoverImage] = useState();
  const [visibleContentItems, setVisibleContentItems] = useState([]);

  // Load 360 thumbnail and content
  useEffect(() => {
    if (album360Code) {
      setLoading(true);
      Misc.getAlbumByCode(album360Code).then(({ data }) => {
        setAlbum360CoverImage(`data:image/jpeg;base64, ${data.image}`);

        setLoading(false);
      });
    }
  }, [album360Code]);

  useEffect(() => {
    let combinedList = [];

    if (sceneContentId) {
      combinedList.push({
        objectId: 'scene',
        contentItemType: 'scene',
        id: sceneContentId
      });
    }

    // externalExperienceUrl property is deprecated
    // we should use it only if there are no 'url' content items
    const urlContentItems = contentItems.filter(
      (item) => item.contentItemType === 'url'
    );
    if (urlContentItems.length === 0 && externalExperienceUrl) {
      combinedList.push({
        objectId: 'externalExperience',
        contentItemType: 'externalExperience',
        contentUri: externalExperienceUrl
      });
    }

    if (contentItems) {
      combinedList = [...combinedList, ...contentItems];
    }

    combinedList = combinedList.map((item) => {
      if (item.contentItemType === 'tour360') {
        return {
          ...item,
          contentUri: album360CoverImage
        };
      } else {
        return item;
      }
    });

    setVisibleContentItems(combinedList);
  }, [album360CoverImage, sceneContentId, externalExperienceUrl, contentItems]);

  const contentPreviews = visibleContentItems.map((contentItem) => {
    const isSelected = selectedContentItem?.objectId === contentItem.objectId;

    let contentUri = contentItem.contentUri;
    if (
      contentItem.contentItemType !== 'tour360' &&
      contentItem.contentItemType !== 'externalExperience' &&
      contentItem.contentItemType !== 'document' &&
      contentItem.contentItemType !== 'floorplan' &&
      contentItem.contentItemType !== 'url' &&
      contentUri
    ) {
      contentUri = `${
        getCurrentEnvironment().baseImageUrl
      }/${previewTransform}/${contentItem.contentUri}`;
    }

    return (
      <UnitContentCard
        key={contentItem.objectId}
        contentItem={{ ...contentItem, contentUri }}
        isSelected={isSelected}
        onSelectContentItem={() => {
          onSelectContentItem(contentItem);
        }}
        on360Selected={on360Selected}
        onSceneSelected={onSceneSelected}
        onExternalExperienceSelected={onExternalExperienceSelected}
        fallbackThumbnail={fallbackThumbnail}
      />
    );
  });

  if (loading) {
    return <ShowcaseLoader />;
  }

  return (
    <ContentWrapper>
      <ContentList>
        <AnimatePresence>{contentPreviews}</AnimatePresence>
      </ContentList>
    </ContentWrapper>
  );
};

UnitContentList.propTypes = {
  contentItems: array,
  album360Code: string,
  sceneContentId: string,
  externalExperienceUrl: string,
  selectedContentItem: shape({}),
  onSelectContentItem: func.isRequired,
  on360Selected: func.isRequired,
  onSceneSelected: func.isRequired,
  fallbackThumbnail: string
};

UnitContentList.defaultProps = {
  contentItems: [],
  album360Code: null,
  sceneContentId: null,
  externalExperienceUrl: null,
  selectedContentItem: null,
  fallbackThumbnail: null
};

export default UnitContentList;
