import qs from 'query-string';
import path from 'path';
import configs from '../config.json';
import to from 'await-to-js';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  isIOS,
  getUA,
  isMobileSafari,
  isAndroid,
  isWinPhone
} from 'react-device-detect';
import localizer from 'localization/localizer';
import add from 'date-fns/add';
import getTime from 'date-fns/getTime';
import numeral from 'numeral';

import { showcaseConfiguration } from 'configs/mapFeatureFlagsToDefaultSettings.json';
import { Vault } from '@prompto-api';

import { version } from '../../../../../package.json';

/**
 * Runs a querystring search on the url params, to fetch the data,
 * destructure it as you need params
 */
export const fetchSettingsFromURL = () => {
  return qs.parse(window.location.search, {
    parseBooleans: true,
    parseNumbers: true
  });
};

/**
 * Check if the application is run in an iframe
 * @returns True if rendered in iframe
 */
export const checkIfRenderedInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Gets the current environment config
 */
export const getCurrentEnvironment = () => {
  const { env } = qs.parse(window.location.search.substring(1));

  // Set default environment to dev if it is run on localhost. Otherwise set to production
  const isLocalhost =
    window.location.host.match(/localhost/g) ||
    window.location.hostname.match(
      /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    );

  const isDevEnvironment =
    window.location.href.match(configs.development.showcaseUrl) ||
    window.location.href.match('appspot.com');

  const result =
    configs[env] ||
    (isLocalhost || isDevEnvironment
      ? configs['development']
      : configs['production']);

  return result;
};

// Get the version defined in package.json, since this is the centralized point
export const getPackageVersion = () => {
  return version;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
/* istanbul ignore next */
export const debounce = (func, wait, immediate) => {
  let timeout;
  /* istanbul ignore next */
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * Add or edit params to the search string
 * @param {Array<Object>} params - must be {key: value} objects
 */
export const addQueryParams = (params) => {
  const parsed = qs.parse(window.location.search);

  params.forEach((param) => {
    const key = Object.keys(param)[0];
    parsed[key] = param[key];
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${qs.stringify(parsed)}`
  );
};

/**
 * remove params to the search string
 * @param {Array<Object>} params - must be {key: value} objects
 */
export const removeQueryParams = (params) => {
  const parsed = qs.parse(window.location.search);

  params.forEach((param) => {
    const key = Object.keys(param)[0];
    parsed[key] = undefined;
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${qs.stringify(parsed)}`
  );
};

/**
 * Ping prompto with a url change with params
 * @param {array} params - must be {key: value} objects
 */
export const pingPrompto = (params) => {
  addQueryParams(params);
  setTimeout(() => {
    removeQueryParams(params);
  }, 50);
};

/**
 * Round up to nearest even number
 */
export const roundUpToNearestEvenNumber = (value) => {
  return Math.ceil(value / 2) * 2;
};

/**
 * Compute cloudinary ID
 * @param {array} uri - uri
 * @param {array} bucketId - bucketId
 */
export const computeCloudinaryId = (uri, bucketId) => {
  const splitString = bucketId.concat('/o/');
  const tokens = uri.split(splitString);
  if (!tokens || tokens.length < 2) {
    return null;
  }

  const relativePath = tokens[1].split('?')[0];
  const fileExtension = path.extname(relativePath);
  const cloudinaryId = relativePath.replace(fileExtension, '');

  return cloudinaryId;
};

/**
 * Compute cloudinary Thumbnail
 */
export const computeCloudinaryThumbnail = (
  uri,
  bucketId,
  baseUrl,
  transform
) => {
  if (uri && bucketId) {
    const cloudinaryId = computeCloudinaryId(uri, bucketId);
    const macMachine = navigator.userAgent
      .toString()
      .toLowerCase()
      .match(/mac/g);

    const extension = macMachine ? 'jpeg' : 'webp';
    let finalImageUri = `${baseUrl}`;
    if (transform) {
      finalImageUri += `/${transform}`;
    }
    finalImageUri += `/${cloudinaryId}.${extension}`;

    return finalImageUri;
  }
};

/**
 * It builds a complete cloudinary transform url
 *
 * @param {string} uri the data uri stored on prompto backend
 * @param {string} bucketId the google bucket id
 * @param {string} baseUrl the base cloudinary image or video url
 * @param {string} transform optional cloudinary transformation
 * @param {string} extension file extension
 */
export const computeTransformUri = (
  uri,
  bucketId,
  baseUrl,
  transform,
  extension
) => {
  if (uri && bucketId) {
    const cloudinaryId = computeCloudinaryId(uri, bucketId);
    let finalTransformUri = `${baseUrl}`;
    if (transform) {
      finalTransformUri += `/${transform}`;
    }
    finalTransformUri += `/${cloudinaryId}.${extension}`;
    return finalTransformUri;
  }
};

/**
 * It checks based on provided params is a specific cloudinary transformation is available
 *
 * @param {function} setTransformAvailable call back function
 * @param {string} uri the date uri stored on prompto backend
 * @param {string} bucketId the google bucket id
 * @param {string} baseUrl the base cloudinary image or video url
 * @param {string} transform optional cloudinary transformation
 * @param {string} extension file extension
 */
export const isCloudinaryTransformationAvailable = async (
  setTransformAvailable,
  uri,
  bucketId,
  baseUrl,
  transform,
  extension
) => {
  const checkUrl = computeTransformUri(
    uri,
    bucketId,
    baseUrl,
    transform,
    extension
  );
  const [err, result] = await to(axios({ method: 'head', url: checkUrl }));

  if (err) {
    setTransformAvailable(false);
    return;
  }

  const { status } = result;
  if (status === 200) {
    setTransformAvailable(true);
  } else {
    setTransformAvailable(false);
  }
};

/**
 * Uppercase the first letter of a string
 * @param {string} s - string to capitalize
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Checks if the device is a mobile device
 */
export const isMobileDevice = () => {
  var isMobile = false;

  // Check screen size
  if (!isMobile) {
    // Detect browser based on navigator
    // http://detectmobilebrowsers.com/
    const value = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        value
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        value.substr(0, 4)
      )
    ) {
      isMobile = true;
    }
  }

  return isMobile;
};

/**
 * Create a URL to load the mediagallery in an iframe
 * @param {string} sceneObjectId
 * @param {string} sceneCatalogueId
 * @param {string} unitObjectId
 * @param {string} unitVaultObjectId
 * @param {string} language
 */
export const buildGalleryUrlForUnit = (
  sceneObjectId,
  sceneCatalogueId,
  unitObjectId,
  unitVaultObjectId,
  lang,
  env,
  sessionToken
) => `https://mediagallery.prompto.com/?${qs.stringify({
  sceneObjectId,
  sceneCatalogueId,
  unitObjectId,
  unitVaultObjectId,
  lang,
  env,
  sessionToken
})}
`;

export const buildGalleryUrlForProject = (
  projectObjectId,
  projectVaultObjectId,
  lang,
  env,
  sessionToken
) => `https://mediagallery.prompto.com/?${qs.stringify({
  projectObjectId,
  projectVaultObjectId,
  lang,
  env,
  sessionToken
})}
  `;

/**
 * Get user details stored in cookies
 */
export const getAuth = () => {
  const { useDeeplinks, vaultId, sessionToken } = fetchSettingsFromURL();

  // get cookie data to see auth info
  const cookies = new Cookies();

  if (useDeeplinks) {
    return {
      vaultId: vaultId,
      sessionToken: sessionToken
    };
  } else {
    return {
      vaultId: cookies.get(`prompto/${getCurrentEnvironment().env}/vid`),
      sessionToken: cookies.get(`prompto/${getCurrentEnvironment().env}/ust`),
      userId: cookies.get(`prompto/${getCurrentEnvironment().env}/uid`)
    };
  }
};

/**
 * Get if safari is running iOS in desktopMode
 */
export const isSafariRunningIosInDesktopMode = () => {
  return (
    isIOS && //running iOS
    isMobileSafari && //running Safari
    !getUA.includes('iPad') && //when running in 'desktop' mode the user agent does not contain 'ipad' or 'iphone' but 'Macintosh' instead
    !getUA.includes('iPhone')
  );
};

/**
 * Get if is running on tablet or mobile
 */
export const isTabletOrMobileDevice = () => {
  return (
    isIOS || //running iOS
    isAndroid ||
    isWinPhone
  );
};

export const getSymboyForCurrencyCode = (code) => {
  switch (code) {
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case 'EUR':
    default:
      return '€';
  }
};

/**
 * Check if provided url is valid
 * @param {String} url
 */
export const isValidUrl = (url) => {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(url);
};

/**
 * Return the list of heights that are generated for each image by Cloudinary
 */
export const getBackendGeneratedHeights = () => {
  return [200, 250, 300, 400, 800, 1080, 1600, 2160];
};

/**
 * Check if link contains protocol and add it if necessary
 * @param {String} url
 */
export const getAbsoluteUrl = (url) => {
  const httpsPattern = /^https:\/\//i;
  const httpPattern = /^http:\/\//i;
  if (httpsPattern.test(url) || httpPattern.test(url)) {
    return url;
  } else {
    return `http://${url}`;
  }
};

/**
 * Convert timestamp to a DD/MM/YYYY format date
 * @param {Number} timestamp
 */
export const getDDMMYYYDate = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

/**
 * Get localized value or return default one
 * @param {{ textMap: Object }} localizedValues
 * @param {string} defaultValue value to display if target language translation is missing, 'en' - by default
 * @returns {string} returns localized value or default value
 */
export const getValueFromLocalized = (localizedValues, defaultValue = '') => {
  const appLang = localizer.getLanguage();
  let localizedValue = localizedValues?.textMap?.[appLang];
  if (!localizedValue) {
    localizedValue = localizedValues?.textMap?.en;
  }

  return localizedValue || defaultValue;
};

/**
 * Transform address object into readable string
 * @param {{ addressLine1: string, city: string, country: string, zipCode: string }} address
 * @returns {string} returns readable address
 */
export const buildAddress = (address) => {
  return `${address?.addressLine1 ?? ''} ${address?.city ?? ''} ${
    address?.country ?? ''
  } ${address?.zipCode ?? ''}`;
};

/**
 * Filter out units on the basis of showcase configuration
 * @param {Array<{ state: string }>} units list of unfiltered units
 * @param {Object} showcaseConfiguration actual showcaseConfiguration
 * @returns {Array<Object>} array of filtered units
 */
export const filterUnitsByShowcaseConfiguration = (
  units,
  showcaseConfiguration
) => {
  return units.filter((unit) => {
    let keepUnit = true;
    if (showcaseConfiguration?.hideInOptionSoldUnits) {
      keepUnit = !['SOLD', 'IN_OPTION'].includes(unit?.state);
    }
    return keepUnit;
  });
};

/**
 * Calculate general field icon based on showcase configuration
 * @param {Object} showcaseConfiguration actual showcaseConfiguration
 * @param {string} fieldName general field name
 * @param {string} library FontAwesome icons collection: far, fal, fas
 * @returns {Array<string>} calculated icon
 */
export const getFieldIcon = (showcaseConfiguration, fieldName, library) => {
  let defaultIcon = '';
  switch (fieldName) {
    case 'price':
      defaultIcon = 'tag';
      break;
    case 'surface':
      defaultIcon = 'vector-square';
      break;
    case 'numberOfBedrooms':
      defaultIcon = 'bed';
      break;
    case 'numberOfBathrooms':
      defaultIcon = 'bath';
      break;
    default:
  }
  const icon = showcaseConfiguration?.iconSettings?.[fieldName] ?? defaultIcon;
  if (!icon) return '';
  return library ? [library, icon] : icon;
};

/**
 * Validate share code based on its expiration date and an archived property
 * @param {Object} shareItem
 * @returns {boolean} whether code invalid or not
 */

export const isShareCodeInvalid = (shareItem) => {
  const isCodeExpired = shareItem?.expiresAt
    ? shareItem.expiresAt < Date.now()
    : false;
  return isCodeExpired || !!shareItem?.archived;
};

export const canParseJSON = (dataToParse) => {
  try {
    let parsedData = JSON.parse(dataToParse); //eslint-disable-line no-unused-vars
  } catch {
    return false;
  }
  return true;
};

/**
 * Calculate share item expiration date based on the showcase configuration
 * @param {string | undefined} shareCodeDefaultExpirationTime
 * @returns {number} expiration date timestamp or 0 if share item should never expire
 */

export const getShareItemExpirationDate = (shareCodeDefaultExpirationTime) => {
  const getDate = (config) => getTime(add(Date.now(), config));
  switch (shareCodeDefaultExpirationTime) {
    case 'oneWeek':
      return getDate({ weeks: 1 });
    case 'twoWeeks':
      return getDate({ weeks: 2 });
    case 'oneMonth':
      return getDate({ months: 1 });
    case 'never':
    default:
      return 0;
  }
};

/**
 * Display localized value
 * @param {Object} textMap
 * @returns {String} localized value
 */
export const displayLocalizedValue = (textMap) => {
  if (!textMap) return '';
  const lang = localizer.getLanguage();

  // 1. try to display a value in app language
  const value = textMap[lang];
  if (value) return value;

  // 2. fallback to value in other languages following the order [en, nl, fr, de]
  const fallbackLang = ['en', 'nl', 'fr', 'de'].find((lang) => textMap[lang]);
  return fallbackLang ? textMap[fallbackLang] : '';
};

// https://en.wikipedia.org/wiki/YIQ
export function isDarkColor(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq < 200;
}

/**
 * Filter out showcase configuration to return only settings relevant for current subscription plan
 * @param {Object} showcaseConfig
 */
export const checkSettingsAgainstFeatureFlags = async (showcaseConfig) => {
  if (!showcaseConfig) return {};

  const response = await Vault.getFeatureFlags(showcaseConfig.vault.objectId);
  const featureFlags = response?.data?.vaultFeatureFlagList;

  const filteredConfig = { ...showcaseConfig };
  let settingsToExclude = [];

  if (featureFlags) {
    Object.entries(showcaseConfiguration).forEach(([flag, settings]) => {
      if (!featureFlags.includes(flag)) {
        settingsToExclude = settingsToExclude.concat(settings);
      }
    });

    settingsToExclude.forEach((setting) => delete filteredConfig[setting]);
  }

  return filteredConfig;
};

/*
 * get user data from ipapi.co api
 * */
export const getUserData = async () => {
  const response = await fetch(
    `https://ipapi.co/json/?key=${getCurrentEnvironment().ipapiApiKey}`
  );
  return await response.json();
};

/**
 * Creates a `${name} is required` message that is localized
 * @param {string} name
 */
export const createIsRequiredMessage = (name) =>
  `${capitalize(name)} ${localizer.isRequired}.`;

export const getPriceToShowTypeLabel = (type) => {
  switch (type) {
    default:
    case 'pricePerMonth':
      return ' / month';
    case 'pricePerYear':
      return ' / year';
    case 'pricePerSquareMeterPerMonth':
      return ' / m² / month';
    case 'pricePerSquareMeterPerYear':
      return ' / m² / year';
  }
};

export const getUnitPrice = (unit) => {
  let unitPrice = `${numeral(unit?.unitPrice?.price).format()}`;

  if (unit?.unitFlow === 'rental') {
    if (unit?.priceToShow && unit?.priceToShowType) {
      unitPrice = `${numeral(
        unit?.priceToShow?.price
      ).format()} ${getPriceToShowTypeLabel(unit?.priceToShowType)}`;
    } else {
      unitPrice += localizer.perMonth;
    }
  }

  return unitPrice;
};
