import React, { useState, memo } from 'react';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';

// Styling
import styled, { css } from 'styled-components';

const Button = styled(motion.button)`
  border-radius: 25px;
  backdrop-filter: blur(4px);
  border: solid 1px ${({ theme }) => theme.gray150};
  background-color: rgba(255, 255, 255, 0.85);
  color: ${({ theme }) => theme.primary100};
  cursor: pointer;
  font-family: ${({ theme }) => theme.mainFontFamily};
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  &:hover {
    padding: ${isMobile ? '5px' : '5px 30px 5px 10px'};
  }
  ${isMobile &&
  css`
    border: none;
  `}
`;

const iconSize = isMobile ? 24 : 40;

const IconWrapper = styled(motion.div)`
  width: ${iconSize}px;
  height: ${iconSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ hovered }) => (hovered ? 5 : 0)}px;
  transition: all 100ms ease 100ms;
  font-size: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.375rem;
`;

const Text = styled(motion.div)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.03rem;
  font-size: 0.875rem;
  font-weight: 600;
`;

const LaunchWizardButton = memo(({ onClick, onHover, onUnhover }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <AnimatePresence>
        <Button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onMouseEnter={() => {
            setIsHovered(true);
            onHover && onHover();
          }}
          onMouseLeave={() => {
            setIsHovered(false);
            onUnhover && onUnhover();
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <IconWrapper hovered={isHovered && !isMobile}>
            <Icon icon={['fal', 'magic']} size="1x" />
          </IconWrapper>
          <AnimatePresence>
            {isHovered && !isMobile && (
              <Text
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
              >
                {localizer.wizard.launchWizard}
              </Text>
            )}
          </AnimatePresence>
        </Button>
      </AnimatePresence>
    </>
  );
});

export default LaunchWizardButton;
