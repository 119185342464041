import React, { Fragment } from 'react';
import { array, func, string } from 'prop-types';

// Components
import { Button, Divider, Modal } from 'components/ui';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 10px;
  padding-top: 20px;
  min-width: 300px;
`;

const Title = styled.strong`
  color: ${(props) => props.theme.primary400};
  font-size: 24px;
`;

const Text = styled.p`
  color: ${(props) => props.theme.primary200};
  font-size: 14px;
  line-height: 1.5;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

const ConfirmationModal = ({
  onConfirm,
  onCancelled,
  title,
  descriptionBlocks,
  callToAction,
  confirmButtonText,
  cancelButtonText,
  ...rest
}) => {
  let descriptionContent = (
    <Text>
      {localizer.destructiveAction}
      <br />
      {localizer.wishToContinueQuestion}
    </Text>
  );

  if (descriptionBlocks) {
    descriptionContent = descriptionBlocks.map((description, index) => (
      <Text key={index}>{capitalize(description)}</Text>
    ));
  }

  let callToActionContent;
  if (callToAction) {
    callToActionContent = <Text>{capitalize(callToAction)}</Text>;
  }

  const dummy = () => {};
  return (
    <Modal
      center
      showCloseIcon={false}
      onClose={onCancelled || dummy}
      {...rest}
    >
      <Wrapper>
        <Fragment>
          <Title>{capitalize(title || localizer.areYouSure)}</Title>
          <Divider marginSize={25} />
          {descriptionContent}
          {callToActionContent}
          <Divider marginSize={25} />
          <ButtonWrapper>
            {onCancelled && (
              <Button
                onClickAction={onCancelled}
                label={capitalize(cancelButtonText || localizer.cancel)}
                dataTestId="cancelButton"
              />
            )}
            {onConfirm && (
              <Button
                onClickAction={onConfirm}
                type={'submit'}
                label={capitalize(confirmButtonText || localizer.continue)}
                dataTestId="continueButton"
              />
            )}
          </ButtonWrapper>
        </Fragment>
      </Wrapper>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onConfirm: func,
  onCancelled: func,
  title: string,
  descriptionBlocks: array,
  callToAction: string,
  confirmButtonText: string,
  cancelButtonText: string
};
ConfirmationModal.defaultProps = {
  onConfirm: null,
  onCancelled: null,
  title: null,
  descriptionBlocks: null,
  callToAction: null,
  confirmButtonText: null,
  cancelButtonText: null
};

export default ConfirmationModal;
