import React, { useState } from 'react';
import { array } from 'prop-types';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Helpers
import categories from 'configs/SupportedFileCategories.json';
import { getCurrentEnvironment } from 'helpers';

// Components
import ImagePreview from '../../resources/contentItems/types/image/ImagePreview';
import VideoPreview from '../../resources/contentItems/types/video/VideoPreview';
import MobileMediaGallery from '../../resources/contentGrid/MobileMediaGallery';

const GalleryWrapper = styled(motion.div)`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const GalleryItemWrapper = styled.div`
  height: ${(props) => (props.index % 3 ? '100px' : '200px')};
  width: ${(props) => (props.index % 3 ? 'calc(50% - 10px)' : '100%')};
  margin: 5px;
`;

const Media = ({ items }) => {
  const [shownItem, setShownItem] = useState(0);
  const [openGallery, setOpenGallery] = useState(false);

  if (items.length === 0) return null;

  let transform = 'q=80:h=400';
  const videoFallbackThumbnail = `${
    getCurrentEnvironment().baseImageUrl
  }/${transform}/${items[0].contentUri}`;

  const openGalleryHandler = (itemIndex) => {
    setShownItem(itemIndex);
    setOpenGallery(true);
  };

  return (
    <GalleryWrapper>
      {items.map((data, idx) => {
        const sharedProps = {
          data,
          key: data.objectId
        };
        let item;
        switch (data.contentItemType) {
          case categories.image:
          case categories.image360:
            item = (
              <ImagePreview {...sharedProps} pageIndex={idx} isMobile={true} />
            );
            break;
          case categories.video:
            item = (
              <VideoPreview
                {...sharedProps}
                fallbackThumbnail={videoFallbackThumbnail}
              />
            );
            break;
          default:
            item = null;
        }
        return (
          <GalleryItemWrapper
            key={idx}
            index={idx}
            onClick={() => openGalleryHandler(idx)}
          >
            {item}
          </GalleryItemWrapper>
        );
      })}

      {openGallery && (
        <MobileMediaGallery
          pages={items}
          activePage={shownItem}
          videoFallbackThumbnail={videoFallbackThumbnail}
          onClose={() => setOpenGallery(false)}
        />
      )}
    </GalleryWrapper>
  );
};

Media.propTypes = {
  items: array
};

Media.defaultProps = {
  items: []
};

export default Media;
