import React, { useRef, useEffect, useState, useCallback } from 'react';

// Components
import TurntableSvgMask from './TurntableSvgMask';
import ModelViewer from './modelViewer/ModelViewer';

// Helpers
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const SvgOverlay = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: 0;
  left: 0;
`;

const SvgContainer = styled.svg`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  ${(props) => props.transformation};
`;

const ModelViewWrapper = styled(motion.div)`
  position: absolute;
  z-index: 49;
  top: ${({ offset }) => `calc(50% + ${offset}px)`};
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${({ dimensions }) => dimensions.width}px;
  height: ${({ dimensions }) => dimensions.height}px;
  display: flex;
`;

const BlockingOverlay = styled.div`
  position: absolute;
  z-index: 1005;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

const LoadingText = styled.div`
  margin: auto;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary100};
`;

const TurntableMasks = ({
  maskType,
  drawingAreaSize,
  originalSize,
  svgOverlayTransformation,
  svgPolygons = [],
  selectedUnit,
  onOpenUnitPage,
  highlightAllPolygons,
  isRotating,
  mouseEventDebouncedHandler,
  setSelectedUnit,
  onMaskClicked,
  cameraModel,
  visibleImageIndex,
  navItems = [],
  units,
  setHoveredUnitLocation,
  hoveredUnitCard,
  scale,
  masksAreVisible
}) => {
  const [modelOffset, setModelOffset] = useState(0);
  const [leftOffset, setLeftOffset] = useState(0);
  const [isHighlighting3dMasks, setIsHighlighting3dMasks] = useState(false);
  const [highlightingDone, setHighlightingDone] = useState(false);
  const [is3DMaskHovered, setIs3DMaskHovered] = useState(false);

  useEffect(() => {
    if (highlightAllPolygons) {
      setIsHighlighting3dMasks(true);
      setHighlightingDone(false);
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setIsHighlighting3dMasks(false);
        const timerTwo = setTimeout(() => {
          clearTimeout(timerTwo);
          setHighlightingDone(true);
        }, 400);
      }, 400);
    }
  }, [highlightAllPolygons]);

  const onShowLinkedUnit = useCallback(
    (mousePos, unitId) => {
      setHoveredUnitLocation(mousePos);
      const selectedUnit = units.find((x) => x.objectId === unitId);
      setSelectedUnit(selectedUnit);
    },
    [setSelectedUnit, setHoveredUnitLocation, units]
  );

  const onHideLinkedUnit = useCallback(() => {
    setHoveredUnitLocation(null);
    setSelectedUnit(null);
  }, [setHoveredUnitLocation, setSelectedUnit]);

  const modelViewWrapperRef = useRef();
  useEffect(() => {
    if (!drawingAreaSize) return;
    setTimeout(() => {
      if (cameraModel?.canvasOffsetCoefficient && modelViewWrapperRef.current) {
        setModelOffset(
          modelViewWrapperRef.current.offsetHeight /
            cameraModel.canvasOffsetCoefficient
        );
        setLeftOffset(
          modelViewWrapperRef.current?.getBoundingClientRect().left ?? 0
        );
      }
    }, 300);
  }, [cameraModel, drawingAreaSize]);

  let masks;

  switch (maskType) {
    case 'three_d':
      masks = drawingAreaSize && (
        <ModelViewWrapper
          id="model-view-wrapper"
          ref={modelViewWrapperRef}
          dimensions={drawingAreaSize}
          offset={modelOffset}
          initial={{ opacity: 0.2 }}
          // we control visibility of 3D masks by changing the opacity of its container
          // in the ModelViewer we only define which masks should be visible in a certain situation
          animate={{
            opacity:
              isHighlighting3dMasks || is3DMaskHovered
                ? 0.75
                : isMobile && !isRotating
                ? 0.6
                : isMobile
                ? 0
                : isRotating
                ? 0.2
                : 0
          }}
          transition={{
            duration: !highlightingDone || is3DMaskHovered ? 0.4 : 0,
            type: 'tween'
          }}
        >
          <BlockingOverlay />
          {modelOffset && masksAreVisible ? (
            <ModelViewer
              currentImageIdx={visibleImageIndex}
              frameSize={originalSize}
              offset={modelOffset}
              leftOffset={leftOffset}
              cameraModel={cameraModel}
              turnNavItems={navItems}
              units={units}
              onShowLinkedUnit={onShowLinkedUnit}
              onHideLinkedUnit={onHideLinkedUnit}
              selectedUnit={selectedUnit}
              hoveredUnitCard={hoveredUnitCard}
              setIs3DMaskHovered={setIs3DMaskHovered}
              onOpenUnitPage={onOpenUnitPage}
            />
          ) : (
            <LoadingText>{'Loading masks ...'}</LoadingText>
          )}
        </ModelViewWrapper>
      );
      break;
    default:
      masks = (
        <SvgOverlay
          id="svg-overlay"
          width={drawingAreaSize.width}
          height={drawingAreaSize.height}
        >
          {masksAreVisible && (
            <SvgContainer
              width="100%"
              height="100%"
              top="0px"
              left="0px"
              viewBox={`0 0 ${originalSize.width} ${originalSize.height}`}
              transformation={svgOverlayTransformation}
              onMouseMove={({ target }) => mouseEventDebouncedHandler(target)}
              onClick={({ target }) => {
                if (isMobile) return;
                const targetId = target?.dataset?.target;
                if (targetId && targetId === selectedUnit?.objectId) {
                  onMaskClicked(selectedUnit);
                }
              }}
              onTouchStart={mouseEventDebouncedHandler}
            >
              {svgPolygons.map((x, i) => {
                return (
                  <TurntableSvgMask
                    key={i}
                    maskData={x}
                    maskToShow={highlightAllPolygons ? x.number : ''}
                    active={
                      selectedUnit?.objectId === x.target ||
                      (isMobile && !isRotating)
                    }
                    visible={
                      !!selectedUnit?.objectId || (isRotating && !isMobile)
                    }
                    scale={scale}
                  />
                );
              })}
            </SvgContainer>
          )}
        </SvgOverlay>
      );
      break;
  }

  return masks;
};

export default TurntableMasks;
