import React from 'react';
import { string, number } from 'prop-types';
import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { motion } from 'framer-motion';

// Styling
const Box = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled(FontAwesomeIcon)`
  font-weight: 300;
  text-align: center;
  color: ${(props) => props.color};
`;

const Description = styled.p`
  font-size: 14px;
  text-align: center;
`;

const ShowcaseLoader = ({ description, className, color, size }) => {
  return (
    <Box
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={className}
    >
      <Spinner
        icon={['fal', 'spinner']}
        pulse
        size={`${size}x`}
        color={color}
      />
      {description && <Description>{description}</Description>}
    </Box>
  );
};

ShowcaseLoader.propTypes = { description: string, size: number };

ShowcaseLoader.defaultProps = { description: '', size: 1 };

export default ShowcaseLoader;
