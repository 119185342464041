import React, { useState, useEffect } from 'react';
import { func, arrayOf, string } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'react-switch';

// Helpers
import localizer from 'localization/localizer';
import {
  getLocalizedTextForUnitState,
  getColorForUnitState
} from 'helpers/units/VmUnitHelper';

// Styling
import styled, { withTheme } from 'styled-components';
import { motion } from 'framer-motion';

const CardWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.gray400};
  border-radius: 10px;
  background: white;
  padding-bottom: 15px;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const ToggleOption = styled(motion.div)`
  display: flex;
  flex-flow: row;
  padding: 10px 25px;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background: ${(props) =>
    props.hovered ? 'rgba(127, 127, 127, 0.1)' : 'white'};

  &:first-child {
    padding-top: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    padding-bottom: 25px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 7px;
  font-size: 12px;
  color: ${(props) => getColorForUnitState(props.theme, props.unitstate)};
  cursor: pointer;
  opacity: ${(props) => props.opacity};
`;

const Label = styled.label`
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  margin-right: auto;
  color: ${(props) => getColorForUnitState(props.theme, props.unitstate)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  margin: 0px 15px;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 5px 10px;
`;

const ClearButton = styled(StyledButton)`
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
`;
const ConfirmButton = styled(StyledButton)`
  font-size: 14px;
  color: ${(props) => props.theme.primary400};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
  background-color: ${(props) => props.theme.gray100};
`;

const StyledSwitch = styled(Switch)`
  margin-left: 25px;
`;

/**
 * Filter card that shows a list of toggleable options for each value in an array.
 * Note: At this moment it's only made for the state field of a unit
 */
const FilterArrayToggleCard = ({
  arrayToFilter,
  onConfirm,
  initialValues,
  onUpdateValues,
  theme
}) => {
  const [hoveredOption, setHoveredOption] = useState();
  const [localArray, setLocalArray] = useState([]);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    if (localArray.length === 0 && initialValues) {
      setLocalArray(
        arrayToFilter.map((item) => {
          return { item, toggled: initialValues.includes(item) };
        })
      );
    } else if (localArray.length === 0) {
      setLocalArray(
        arrayToFilter.map((item) => {
          return { item, toggled: true };
        })
      );
    }
  }, [arrayToFilter, initialValues, localArray]);

  useEffect(() => {
    if (needsUpdate) {
      onUpdateValues(localArray.filter((x) => x.toggled).map((x) => x.item));
      setNeedsUpdate(false);
    }
  }, [needsUpdate, localArray, onUpdateValues]);

  useEffect(() => {
    setNeedsUpdate(true);
  }, [localArray]);

  // Toggle the option to the other state
  const toggleOption = (option) => {
    setLocalArray((current) => {
      return current.map((x) => {
        if (x.item === option.item) {
          x.toggled = !x.toggled;
        }
        return x;
      });
    });
  };

  const enableAllOptions = () => {
    setLocalArray((current) => {
      return current.map((x) => {
        x.toggled = true;
        return x;
      });
    });
  };

  return (
    <CardWrapper data-testid={`filterArrayToggleCard`}>
      <VerticalWrapper>
        {localArray.map((x) => {
          return (
            <ToggleOption
              hovered={hoveredOption === x}
              key={`toggleOption${x.item}`}
              onClick={() => {
                toggleOption(x);
              }}
              onHoverStart={() => {
                setHoveredOption(x);
              }}
              onHoverEnd={() => {
                setHoveredOption(null);
              }}
            >
              <Icon
                icon={['far', 'check']}
                size="1x"
                unitstate={x.item}
                opacity={x.toggled ? 1 : 0}
              />
              <Label unitstate={x.item}>
                {getLocalizedTextForUnitState(x.item).toUpperCase()}
              </Label>
              <StyledSwitch
                onChange={() => {}}
                checked={x.toggled}
                onColor={theme.gray400}
                offColor={theme.gray200}
                onHandleColor={theme.primary100}
                offHandleColor={theme.primary100}
                width={36}
                height={10}
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </ToggleOption>
          );
        })}
        <Divider />
        <ButtonWrapper>
          <ClearButton onClick={enableAllOptions}>
            {localizer.clear.toUpperCase()}
          </ClearButton>
          <ConfirmButton
            data-testid={`confirmstate`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onConfirm(localArray.filter((x) => x.toggled).map((x) => x.item));
            }}
          >
            {localizer.confirm.toUpperCase()}
          </ConfirmButton>
        </ButtonWrapper>
      </VerticalWrapper>
    </CardWrapper>
  );
};

FilterArrayToggleCard.propTypes = {
  /** The array for which we want to display toggles and filter on */
  arrayToFilter: arrayOf(string).isRequired,
  /** Initial values that we use if they are passed */
  initialValues: arrayOf(string),
  /** Callback when the user confirms the current values
   * @callback
   * @param {array} filteredList The list the user defined he wants to filter on
   */
  onConfirm: func.isRequired,
  /** Callback when the values are updated but not confirmed
   * @callback
   * @param {{min: number, max: number}} updatedValues The values that were updated
   */
  onUpdateValues: func.isRequired
};

export default withTheme(FilterArrayToggleCard);
