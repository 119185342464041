import React, { useCallback } from 'react';

// Components
import UspSpotTooltip from './UspSpotTooltip';

// Helpers
import { motion } from 'framer-motion';
import { useUiState } from 'stores/UiStore';
import { isMobile } from 'react-device-detect';

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const TooltipWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-width: 330px;
  max-height: calc(100% - 58px);
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.showcaseWhite};
  padding-top: 28px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${(props) => props.theme.primary100};
  padding: 0;
`;

const UspSpotMobileTooltip = () => {
  // UI state
  const { UiState, UiStateDispatch } = useUiState();
  const { showUspMobileTooltip, activeUsp } = UiState;

  const closeTooltip = useCallback(() => {
    UiStateDispatch({
      type: 'update',
      payload: { showUspMobileTooltip: false, activeUsp: null }
    });
  }, [UiStateDispatch]);

  if (!isMobile) return null;
  if (!showUspMobileTooltip || !activeUsp?.spot) return null;

  return (
    <Backdrop>
      <TooltipWrapper>
        <CloseButton onClick={closeTooltip}>
          <CloseIcon icon={['fal', 'times']} size="1x" />
        </CloseButton>
        <UspSpotTooltip
          spotObject={activeUsp?.spot}
          uspSpotLocation={activeUsp.location}
          uspSpotAbsoluteLocation={activeUsp.location}
        />
      </TooltipWrapper>
    </Backdrop>
  );
};

UspSpotMobileTooltip.propTypes = {};

export default UspSpotMobileTooltip;
