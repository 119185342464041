import React, { useCallback, useEffect, useState } from 'react';

// Components
import InputField from 'components/other/InputField';
import TextArea from 'components/other/TextArea';
import ShowcaseLoader from 'components/other/ShowcaseLoader';

// Helpers
import localizer from 'localization/localizer';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { createIsRequiredMessage } from 'helpers';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import { useAuthState } from 'stores/AuthStore';
import { Lead } from '@prompto-api';

// Styling
import styled from 'styled-components';

const Wrapper = styled.form`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  align-items: stretch;
  column-gap: 20px;
  width: 100%;
  flex-shrink: 0;
`;

const StyledButton = styled.button`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.gray400 : theme.defaultBrandPrimaryColor};
  color: ${({ theme }) => theme.showcaseWhite};
  min-width: 120px;
  height: 40px;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
  word-break: break-word;
  text-align: center;
  padding: 0 20px;
  font-size: 0.875rem;
  font-weight: 600;
  align-self: flex-end;
  cursor: pointer;
  column-gap: 10px;
  flex-shrink: 0;
  ${({ styles }) => styles};
`;

const FormSentInfo = styled.div`
  padding: 15px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 300;
  color: ${({ theme, error }) =>
    error ? theme.errorColor : theme.showcaseBlack};
`;

const phoneRegExp = /^\+?\d*$/;
const emailRegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  yourMessage: ''
};

Yup.addMethod(Yup.string, 'validatePhoneNumber', function (payload) {
  const { errorMessage } = payload;
  // @ts-ignore
  return this.test('validatePhoneNumber', errorMessage, function (value) {
    // @ts-ignore
    const { createError } = this;

    let isValid = false;
    const code = value ? value.slice(0, 2) : '';

    switch (code) {
      case '32':
        isValid = value.length === 11;
        break;
      case '04':
        isValid = value.length === 10;
        break;
      default:
        isValid = value ? value.length > 9 && value.length < 15 : false;
    }

    return isValid || createError(errorMessage);
  });
});

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(createIsRequiredMessage(localizer.form.firstName))
      .max(
        30,
        localizer.formatString(
          localizer.isTooLong,
          localizer.form.firstName,
          30
        )
      ),
    lastName: Yup.string()
      .trim()
      .required(createIsRequiredMessage(localizer.form.lastName))
      .max(
        30,
        localizer.formatString(localizer.isTooLong, localizer.form.lastName, 30)
      ),
    email: Yup.string()
      .matches(emailRegExp, localizer.form.provideCorrectEmail)
      .required(createIsRequiredMessage(localizer.form.email)),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, localizer.form.provideCorrectPhoneNumber)
      .required(createIsRequiredMessage(localizer.form.phoneNumber))
      .matches(/[^\s*].*[^\s*]/g, localizer.form.provideCorrectPhoneNumber)
      .validatePhoneNumber({
        errorMessage: localizer.form.provideCorrectPhoneNumber
      })
      .max(
        64,
        localizer.formatString(
          localizer.isTooLong,
          localizer.form.phoneNumber,
          64
        )
      ),
    yourMessage: Yup.string()
  }),
  mapPropsToValues: () => {
    return defaultFormValues;
  },
  displayName: 'defaultForm'
});

const DefaultForm = (props) => {
  const {
    onFormSubmit = () => {},
    dirty,
    errors,
    handleBlur,
    handleChange,
    readOnly,
    setFieldValue,
    setSubmitting,
    isSubmitting,
    touched,
    values,
    showSuccessDefaultMsg,
    setShowSuccessDefaultMsg,
    showErrorDefaultMsg,
    setShowErrorDefaultMsg,
    setValues,
    setTouched,
    formType
  } = props;
  const sharedInputProps = {
    onChange: handleChange,
    onBlur: handleBlur,
    setFieldValue,
    readOnly
  };

  // Project state
  const { ProjectState } = useProjectState();
  const { vault, project, shareCode, favoritesList } = ProjectState;

  // UI state
  const { UiState, UiStateDispatch } = useUiState();
  const { contactUsActiveUnit, utmSource, utmCampaign } = UiState;

  // Auth state
  const { AuthState } = useAuthState();
  const { authenticated, visitorId } = AuthState;

  const [leadPreparedData, setLeadPreparedData] = useState();

  const { pathname } = window.location;

  useEffect(() => {
    const currentUnit = pathname.includes('/unit/')
      ? // if pathname /AAU7P/unit/l6Zh4UVui3 then we get third elem which is unit id
        [pathname.split('/')[3]]
      : [];
    const activeUnit =
      formType === 'ContactUs' && contactUsActiveUnit?.objectId
        ? [contactUsActiveUnit?.objectId]
        : [];

    const unitObjectIds = [...currentUnit, ...favoritesList, ...activeUnit];
    if (unitObjectIds.length) {
      // favorite unit might be unit from current page.
      // and also it could be an active unit (hovered or clicked)
      // that reason array might have same ids, using Set - only unique values left in array
      const uniqueUnitObjectIds = [...new Set(unitObjectIds)];
      setLeadPreparedData((prev) => ({
        ...prev,
        unitObjectIds: uniqueUnitObjectIds
      }));
    }
  }, [ProjectState, contactUsActiveUnit, favoritesList, formType, pathname]);

  useEffect(() => {
    if (project && vault && shareCode) {
      setLeadPreparedData((prevState) => ({
        ...prevState,
        shareCode,
        projectId: project.objectId,
        vaultId: vault.objectId
      }));
    }
  }, [project, vault, shareCode]);

  const onDefaultFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!authenticated) {
        setSubmitting(true);
        const { email, firstName, lastName, phoneNumber, yourMessage } = values;
        const capturedDataFromForm = {
          ...leadPreparedData,
          email,
          name: firstName + ' ' + lastName,
          phone: phoneNumber,
          message: yourMessage,
          visitorObjectId: visitorId,
          language: localizer.getLanguage()
        };

        if (utmSource) {
          capturedDataFromForm.utmSource = utmSource;
        }
        if (utmCampaign) {
          capturedDataFromForm.utmCampaign = utmCampaign;
        }

        Lead.create({
          leadCreateShowcaseParams: capturedDataFromForm
        })
          .then(() => {
            setShowSuccessDefaultMsg(true);
          })
          .catch(() => {
            setShowErrorDefaultMsg(true);
          })
          .finally(() => {
            setSubmitting(false);
            onFormSubmit();
          });
      } else {
        onFormSubmit();
        setValues({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          yourMessage: ''
        });
        setTouched({
          firstName: false,
          lastName: false,
          email: false,
          phoneNumber: false,
          yourMessage: false
        });
        UiStateDispatch({
          type: 'update',
          payload: { showContactUsModal: false, contactUsActiveUnit: null }
        });
      }
    },
    [
      authenticated,
      leadPreparedData,
      values,
      setSubmitting,
      onFormSubmit,
      setShowSuccessDefaultMsg,
      setShowErrorDefaultMsg,
      UiStateDispatch,
      setValues,
      setTouched,
      visitorId,
      utmSource,
      utmCampaign
    ]
  );

  const getErrorIfTouched = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? errors[fieldName] : '';
  };
  if (showSuccessDefaultMsg)
    return (
      <FormSentInfo>{localizer.contactUsOnFormSubmitMessage}</FormSentInfo>
    );
  if (showErrorDefaultMsg)
    return <FormSentInfo error>{localizer.noForm}</FormSentInfo>;
  return (
    <Wrapper onSubmit={onDefaultFormSubmit}>
      <Row>
        <InputField
          label={localizer.form.firstName}
          name={'firstName'}
          required
          error={getErrorIfTouched('firstName')}
          value={values.firstName}
          {...sharedInputProps}
        />
        <InputField
          label={localizer.form.lastName}
          name={'lastName'}
          required
          error={getErrorIfTouched('lastName')}
          value={values.lastName}
          {...sharedInputProps}
        />
      </Row>
      <Row>
        <InputField
          label={localizer.form.email}
          name={'email'}
          required
          error={getErrorIfTouched('email')}
          value={values.email}
          {...sharedInputProps}
        />
      </Row>
      <Row>
        <InputField
          label={localizer.form.phoneNumber}
          name={'phoneNumber'}
          required
          error={getErrorIfTouched('phoneNumber')}
          value={values.phoneNumber}
          {...sharedInputProps}
        />
      </Row>
      <Row>
        <TextArea
          label={localizer.form.yourMessage}
          name={'yourMessage'}
          error={getErrorIfTouched('yourMessage')}
          value={values.yourMessage}
          {...sharedInputProps}
        />
      </Row>
      <StyledButton
        type="submit"
        disabled={Object.keys(errors).length > 0 || isSubmitting || !dirty}
      >
        {isSubmitting && <ShowcaseLoader />}
        {localizer.form.send}
      </StyledButton>
    </Wrapper>
  );
};

export default formikEnhancer(DefaultForm);
