import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { isMobile } from 'react-device-detect';
import { displayLocalizedValue, getCurrentEnvironment } from 'helpers';
import { ExpandDirections, getDisplayDirection } from '../Spot';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import { useTourState } from 'stores/TourStore';
import { usePrevious } from '@prompto-helpers';
import isEqual from 'lodash.isequal';
import { ContentCollection } from '@prompto-api';

// Components
import ShowcaseLoader from 'components/other/ShowcaseLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdealImage } from '@prompto-ui';
import { RichTextRenderer } from '@prompto-ui';
import StyledScrollbarWrapper from 'components/other/StyledScrollbarsWrapper';

// Styling
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const offsetMargin = 20;
const defaultTooltipWidth = 330;
const pinnedUspMobileSize = 120;

const MainWrapper = styled(motion.div)`
  width: ${({ size }) => size.width}px;
  height: auto;
  max-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-flow: column;
  background-color: ${({ theme, isTransparentBackground }) =>
    isTransparentBackground ? 'transparent' : theme.whitePure};
  border-radius: ${({ pinned }) => (pinned && isMobile ? 8 : 2)}px;
  z-index: 105;
  cursor: pointer;
  transition: all 160ms ease-out;
  outline: ${({ pinned, theme }) =>
    pinned && isMobile
      ? `1px solid ${theme.defaultBrandPrimaryColor}`
      : 'transparent'};
  ${({
    useInheritPosition,
    size,
    displayDirection,
    offset,
    source,
    givenDirection
  }) => {
    if (isMobile) return '';
    if (useInheritPosition) {
      return css`
        position: relative;
      `;
    } else if (offset) {
      const yDirection =
        source === 'polygon' ? givenDirection.y : displayDirection.y;
      const toTop =
        source === 'polygon' ? size.height * -1 : offset.yTop - size.height;
      const toBottom = source === 'polygon' ? 0 : offset.yBottom;
      const top = yDirection === 'top' ? toTop : toBottom;
      const left =
        displayDirection.x === 'left'
          ? (size.width - offset.xLeft) * -1
          : offset.xRight * -1;

      return css`
        position: absolute;
        top: ${top}px;
        left: ${left}px;
      `;
    }
  }}
  ${isMobile &&
  `
  position: static;
  width: 100%;
  max-width: 100%;
  `}
  ${(props) =>
    props.enlarged &&
    `
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}
`;

const MainInfoWrapper = styled.div`
  width: calc(100% - ${isMobile ? '30px' : '60px'});
  padding: 20px 0 20px;
  box-sizing: border-box;

  ${isMobile &&
  css`
    max-height: 400px;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 12px;
    box-sizing: border-box;
  `}
`;

const Headline = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const MediaWrapper = styled.div`
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  ${({ size, pinned, isMobile }) =>
    size &&
    css`
      width: ${pinned && isMobile ? pinnedUspMobileSize : size.width}px;
      height: ${pinned && isMobile ? pinnedUspMobileSize : size.height}px;
      border-radius: ${({ pinned }) => (pinned && isMobile ? 8 : 0)}px;
    `}
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  z-index: 1;
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  object-position: center;
  object-fit: ${({ shouldfit }) => (shouldfit ? 'contain' : 'cover')};
  width: 100%;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  margin-top: -25px;
`;

const ToggleVolumeButton = styled.button`
  position: absolute;
  z-index: 4;
  right: 1rem;
  bottom: 1rem;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 1rem;
`;

const PointerIconWrapper = styled.div`
  position: absolute;
  z-index: 6;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  background-color: ${({ theme }) => theme.defaultBrandPrimaryColor};
  border-radius: 4px 0 8px 0;
`;

const StyledPointerIcon = styled(FontAwesomeIcon)`
  margin: auto;
  color: ${({ theme }) => theme.showcaseWhite};
`;

const getIfNearRightBottomPart = (position) => {
  const screenWith =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  // We check if the element is near the right of the window and of the bottom (near controls)
  if (position.x > screenWith - 95 && position.y > screenHeight - 200) {
    return true;
  }
  return false;
};

const UspSpotTooltip = ({
  spotObject,
  uspSpotLocation,
  uspSpotAbsoluteLocation = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2
  },
  useInheritPosition = false,
  isPinned = false,
  setEnlargedUspData = () => {},
  enlargeUsp = () => {},
  showTooltip,
  onSizeComputed = () => {},
  source,
  givenDirection,
  parentDimensions,
  idealImageRelativePosition
}) => {
  // Project store
  const { ProjectState } = useProjectState();
  const { showcaseConfiguration } = ProjectState;

  // Tour store
  const { TourState, TourStateDispatch } = useTourState();
  const { loadedContentCollections } = TourState;

  const [linkedContent, setLinkedContent] = useState();

  const [uspMediaType, setUspMediaType] = useState(undefined);
  const [imageContent, setImageContent] = useState();
  const [videoContent, setVideoContent] = useState();
  const [mediaShouldFit, setMediaShouldFit] = useState(true);
  const [mediaContentSize, setMediaContentSize] = useState(null);
  const [mediaContentHeightToWidthRatio, setMediaContentHeightToWidthRatio] =
    useState(0);

  const [videoLoaded, setVideoLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [isVideoMuted, setVideoMuted] = useState(true);

  const [tooltipSize, setTooltipSize] = useState({
    width: isMobile ? 200 : defaultTooltipWidth,
    height: 250
  });
  const [tooltipHeightAdjusted, setTooltipHeightAdjusted] = useState(false);
  const [displayDirection, setDisplayDirection] = useState({
    x: ExpandDirections.right,
    y: ExpandDirections.top
  });
  const [visibilitySettings] = useState(spotObject?.usp?.uspVisibilitySettings);

  const wrapperRef = useRef();
  const containerRef = useRef();

  const [maxTooltipWidth, setMaxTooltipWidth] = useState(null);

  const headline = displayLocalizedValue(spotObject?.usp?.headline?.textMap);
  const description = displayLocalizedValue(
    spotObject?.usp?.description?.textMap
  );
  const richTextDescription = displayLocalizedValue(
    spotObject?.usp?.richTextDescription?.textMap
  );

  const [fetchingUspMedia, setFetchingUspMedia] = useState(false);
  const [imageSizeCalculated, setImageSizeCalculated] = useState(false);
  const [showOnlyMedia, setShowOnlyMedia] = useState(true);
  const [mediaContentOffset, setMediaContentOffset] = useState(null);
  const [videoRatio, setVideoRatio] = useState(null);

  const previousShowTooltip = usePrevious(showTooltip);
  const previousTooltipSize = usePrevious(tooltipSize);

  // UI state
  const { UiState, UiStateDispatch } = useUiState();

  const elementId = 'uspSpot-' + spotObject.objectId;

  useEffect(() => {
    if (!isEqual(tooltipSize, previousTooltipSize)) {
      onSizeComputed(tooltipSize, uspSpotAbsoluteLocation);
    }
  }, [
    tooltipSize,
    onSizeComputed,
    previousTooltipSize,
    uspSpotAbsoluteLocation
  ]);

  useEffect(() => {
    if (visibilitySettings?.showMedia === false) return;
    if (!showcaseConfiguration) return;
    const enlargedUspData = {
      mediaType: uspMediaType,
      mediaContent: linkedContent?.contentUri,
      headline,
      description,
      richTextDescription,
      visibilitySettings,
      shouldFit: mediaShouldFit,
      isVideoMuted,
      isPinned,
      ratio: mediaContentHeightToWidthRatio,
      size: linkedContent?.originalImageSize
    };
    setEnlargedUspData(enlargedUspData);
  }, [
    setEnlargedUspData,
    uspMediaType,
    linkedContent,
    headline,
    description,
    richTextDescription,
    visibilitySettings,
    mediaShouldFit,
    isVideoMuted,
    isPinned,
    mediaContentHeightToWidthRatio,
    showcaseConfiguration
  ]);

  useEffect(() => {
    if (uspMediaType === 'video') {
      setMediaContentSize({
        width: maxTooltipWidth,
        height: maxTooltipWidth * videoRatio
      });
    }
  }, [videoRatio, maxTooltipWidth, uspMediaType]);

  useEffect(() => {
    if (uspMediaType === 'video') {
      let video = document.createElement('video');
      video.src = videoContent;
      video.addEventListener(
        'loadedmetadata',
        () => {
          setVideoRatio(video.videoHeight / video.videoWidth);
        },
        false
      );
    }
  }, [uspMediaType, videoContent]);

  useEffect(() => {
    if (!spotObject.size) {
      // 330px is a medium size width which is default one
      setMaxTooltipWidth(330);
    } else if (spotObject.size && spotObject.size !== 1) {
      setMaxTooltipWidth(spotObject.size);
    } else {
      if (tooltipSize?.width) {
        const width =
          tooltipSize?.width < window.innerWidth * 0.9
            ? tooltipSize?.width
            : window.innerWidth * 0.9;
        setMaxTooltipWidth(width);
      } else {
        setMaxTooltipWidth(defaultTooltipWidth);
      }
    }
  }, [spotObject, linkedContent, tooltipSize]);

  useEffect(() => {
    setMediaShouldFit(
      showcaseConfiguration?.initialTourImageFitFillType === 'fit'
    );
  }, [showcaseConfiguration]);

  // Get linked USP media
  useEffect(() => {
    // No need to fetch the media, if it's hidden by USP settings
    if (visibilitySettings?.showMedia === false) {
      setLinkedContent(-1);
      return;
    }

    // Avoid making multiple calls
    if (fetchingUspMedia) return;
    // Quit if the media is already fetched
    if (linkedContent) return;

    if (spotObject?.usp?.mediaCollection) {
      setFetchingUspMedia(true);

      const handleCotentCollection = (col) => {
        if (
          col?.vmContentItemList?.length > 0 &&
          col?.vmContentItemList[0]?.contentItemState !== 'archived'
        ) {
          // The usp feature only allows for one content item for now
          setLinkedContent(col?.vmContentItemList[0]);
        } else {
          setLinkedContent(-1);
        }
      };

      if (loadedContentCollections[spotObject?.usp?.mediaCollection.objectId]) {
        const collection =
          loadedContentCollections[spotObject?.usp?.mediaCollection.objectId];
        handleCotentCollection(collection);
      } else {
        ContentCollection.get(spotObject?.usp?.mediaCollection.objectId).then(
          (result) => {
            if (result) {
              const { data } = result;
              const { vmContentCollection } = data;
              TourStateDispatch({
                type: 'addLoadedContentCollection',
                payload: vmContentCollection
              });
              handleCotentCollection(vmContentCollection);
            } else {
              setLinkedContent(-1);
            }

            setFetchingUspMedia(false);
          }
        );
      }
    } else {
      setLinkedContent(-1);
    }
  }, [spotObject, linkedContent, visibilitySettings, fetchingUspMedia]);

  useEffect(() => {
    if (tooltipHeightAdjusted && !mediaContentSize) return;
    const maxHeight = containerRef.current.offsetHeight;
    if (
      containerRef.current &&
      linkedContent &&
      (imageContent || (videoContent && videoLoaded)) &&
      visibilitySettings?.showMedia !== false
    ) {
      const size = {
        width: maxTooltipWidth ?? defaultTooltipWidth,
        height: maxHeight
      };
      setTooltipSize(size);
      setTooltipHeightAdjusted(true);
    } else if (containerRef.current && linkedContent === -1) {
      const size = {
        width: maxTooltipWidth ?? defaultTooltipWidth,
        height: maxHeight
      };
      setTooltipSize(size);
      setTooltipHeightAdjusted(true);
    }
  }, [
    containerRef,
    linkedContent,
    videoLoaded,
    videoContent,
    imageContent,
    visibilitySettings,
    tooltipHeightAdjusted,
    mediaContentSize,
    maxTooltipWidth
  ]);

  useEffect(() => {
    if (source === 'polygon') return;
    if (!tooltipHeightAdjusted) return;
    if (tooltipSize && !useInheritPosition) {
      // we multiply width by 2 due to design changes between usp spot tooltip default position and unit spot one
      const displayDir = getDisplayDirection(
        uspSpotLocation,
        maxTooltipWidth * 2,
        tooltipSize.height,
        window.innerWidth
      );

      setDisplayDirection(displayDir);
    }
  }, [
    uspSpotLocation,
    maxTooltipWidth,
    tooltipSize,
    uspSpotAbsoluteLocation,
    useInheritPosition,
    tooltipHeightAdjusted,
    source
  ]);

  useEffect(() => {
    if (linkedContent) {
      switch (linkedContent.contentItemType) {
        case 'image':
          setUspMediaType('image');
          let imageUri = linkedContent?.contentUri;
          if (imageUri) {
            setImageContent(imageUri);
          }
          break;
        case 'video':
          setUspMediaType('video');
          setVideoContent(linkedContent.contentUri);
          break;
        default:
          setUspMediaType('no_media');
          break;
      }
    }
  }, [linkedContent]);

  useEffect(() => {
    setShowOnlyMedia(
      !visibilitySettings?.showHeadline &&
        !visibilitySettings?.showDescription &&
        visibilitySettings?.showHeadline !== undefined &&
        visibilitySettings?.showDescription !== undefined
    );
  }, [visibilitySettings]);

  useEffect(() => {
    if (tooltipSize && !useInheritPosition) {
      // Calculate offset to the left of the screen
      const xLeft = Math.min(
        Math.max(
          tooltipSize.width + offsetMargin - uspSpotAbsoluteLocation.x,
          0
        ),
        tooltipSize.width
      );
      // Calculate offset to the right of the screen
      const xRight = Math.min(
        Math.max(
          tooltipSize.width +
            offsetMargin +
            uspSpotAbsoluteLocation.x -
            window.innerWidth,
          0
        ),
        tooltipSize.width
      );
      // Calculate offset to the top of the screen
      let yTop = Math.min(
        Math.max(
          tooltipSize.height + offsetMargin - uspSpotAbsoluteLocation.y,
          0
        ),
        tooltipSize.height
      );
      // Calculate offset to the bottom of the screen
      const yBottom = Math.min(
        Math.max(
          tooltipSize.height +
            offsetMargin +
            uspSpotAbsoluteLocation.y -
            window.innerHeight,
          0
        ),
        tooltipSize.height
      );

      setMediaContentOffset({ xLeft, xRight, yTop, yBottom });
    }
  }, [tooltipSize, uspSpotAbsoluteLocation, useInheritPosition, source]);

  useEffect(() => {
    if (previousShowTooltip && previousShowTooltip !== showTooltip) {
      UiStateDispatch({
        type: 'update',
        payload: {
          hideTourControls: false
        }
      });
    } else {
      let hideTourControls = false;
      if (showTooltip && !isPinned) {
        const tooltip = document.getElementById(elementId);
        var x = tooltip.getBoundingClientRect().left;
        var y = tooltip.getBoundingClientRect().top;

        var spotPosition = {
          x: x + tooltipSize.width,
          y: y + tooltipSize.height
        };

        if (getIfNearRightBottomPart(spotPosition)) {
          hideTourControls = true;
        }

        UiStateDispatch({
          type: 'update',
          payload: {
            hideTourControls: hideTourControls
          }
        });
      }
    }
  }, [
    isPinned,
    UiState.hideTourControls,
    UiStateDispatch,
    elementId,
    showTooltip,
    tooltipSize,
    previousShowTooltip
  ]);

  const onLoadUspImage = useCallback(
    (iWidth, iHeight, target, isPlaceholder) => {
      if (isPlaceholder || imageSizeCalculated) return;

      setImageSizeCalculated(true);
      setImageLoaded(true);

      // adopt media content size
      // calculate original image ratio
      const iRatio = target.naturalHeight / target.naturalWidth;
      setMediaContentHeightToWidthRatio(iRatio);

      // calculate maximum possible size
      // it can't be bigger than window dimensions
      // and in mobile it also depends on tour image size
      const parentDimensionWidth = parentDimensions?.width
        ? parentDimensions?.width * 0.9
        : window.innerWidth * 0.9;
      const maxPossibleWidth = isMobile
        ? Math.min(parentDimensionWidth, window.innerHeight - 120)
        : window.innerHeight - 120;
      const parentDimensionsHeight = parentDimensions?.height
        ? parentDimensions?.height
        : window.innerWidth * 0.9;
      const maxPossibleHeight = isMobile
        ? Math.min(parentDimensionsHeight, window.innerWidth * 0.9)
        : window.innerWidth * 0.9;

      // calculate size depending on USP size settings
      const maxWidthIsNotSpecified = maxTooltipWidth === 1 || !maxTooltipWidth;
      const maxHeight = Math.min(
        maxWidthIsNotSpecified
          ? linkedContent?.originalImageSize?.height
          : maxTooltipWidth * iRatio,
        maxPossibleHeight
      );
      const maxWidth = Math.min(
        maxWidthIsNotSpecified
          ? linkedContent?.originalImageSize?.width
          : maxTooltipWidth,
        maxPossibleWidth
      );

      // adjust size to fit into window
      const imageWidth = maxWidth ?? target.naturalWidth;
      const imageHeight = maxHeight ?? target.naturalHeight;

      const heightRatio = imageHeight / maxHeight;
      const widthRatio = imageWidth / maxWidth;
      if (heightRatio < widthRatio) {
        const width = imageWidth > maxWidth ? maxWidth : imageWidth;
        const height = width * iRatio;
        setMediaContentSize({ width, height });
      } else {
        const height = imageHeight > maxHeight ? maxHeight : imageHeight;
        const width = height / iRatio;
        setMediaContentSize({ width, height });
      }
    },
    [imageSizeCalculated, linkedContent, maxTooltipWidth, parentDimensions]
  );

  const content =
    uspMediaType ||
    !isMobile ||
    (!uspMediaType && visibilitySettings?.showMedia === false) ? (
      <>
        {uspMediaType === 'image' && (
          <MediaWrapper
            ref={wrapperRef}
            isMobile={isMobile}
            size={mediaContentSize}
            pinned={isPinned}
          >
            {!imageLoaded && <ImagePlaceholder />}
            {imageContent && (
              <IdealImage
                key={`uspImage`}
                contentUri={imageContent}
                fallbackUri={imageContent}
                imageSize={{
                  width: wrapperRef.current?.offsetWidth ?? 0,
                  height: wrapperRef.current?.offsetHeight ?? 0
                }}
                containerSize={{
                  width:
                    isPinned && isMobile
                      ? pinnedUspMobileSize
                      : window.innerWidth,
                  height:
                    isPinned && isMobile
                      ? pinnedUspMobileSize
                      : window.innerHeight
                }}
                mustFillParent={true}
                onLoad={onLoadUspImage}
                shouldFit={isPinned && isMobile ? false : mediaShouldFit}
                baseImageUrl={getCurrentEnvironment().baseImageUrl}
              />
            )}
          </MediaWrapper>
        )}

        {videoContent && (
          <MediaWrapper
            isMobile={isMobile}
            size={mediaContentSize}
            pinned={isPinned}
          >
            <StyledVideo
              src={videoContent}
              autoPlay={true}
              controls={false}
              onLoadedData={() => {
                setVideoLoaded(true);
              }}
              preload="auto"
              playsInline={true}
              type="video/mp4"
              shouldfit={isPinned ? false : mediaShouldFit}
              muted={isVideoMuted}
            />
            <ToggleVolumeButton
              onClick={(e) => {
                e.stopPropagation();
                setVideoMuted(!isVideoMuted);
              }}
            >
              <FontAwesomeIcon
                icon={['fal', isVideoMuted ? 'volume-slash' : 'volume-up']}
                size="1x"
              />
            </ToggleVolumeButton>
          </MediaWrapper>
        )}

        {[
          visibilitySettings?.showHeadline !== false,
          visibilitySettings?.showDescription !== false
        ].some(Boolean) && (
          <MainInfoWrapper>
            {visibilitySettings?.showHeadline !== false && (
              <Headline>{headline}</Headline>
            )}
            {visibilitySettings?.showDescription !== false && (
              <StyledScrollbarWrapper size={'40vh'}>
                <RichTextRenderer
                  richText={richTextDescription}
                  fallbackValue={description}
                />
              </StyledScrollbarWrapper>
            )}
          </MainInfoWrapper>
        )}
      </>
    ) : (
      <LoaderWrapper>
        <ShowcaseLoader color={'grey'} />
      </LoaderWrapper>
    );

  return (
    <MainWrapper
      ref={containerRef}
      size={tooltipSize}
      displayDirection={displayDirection}
      givenDirection={givenDirection}
      initial={{ opacity: 0 }}
      animate={
        tooltipSize && tooltipHeightAdjusted ? { opacity: 1 } : { opacity: 0 }
      }
      transition={{ delay: 0.2, duration: 0.5 }}
      isMobile={isMobile}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
      onClick={enlargeUsp}
      useInheritPosition={useInheritPosition}
      isTransparentBackground={isPinned && showOnlyMedia}
      showOnlyMedia={showOnlyMedia}
      offset={mediaContentOffset}
      id={elementId}
      source={source}
      pinned={isPinned}
    >
      {content}
      {isPinned && isMobile && (
        <PointerIconWrapper>
          <StyledPointerIcon icon={['far', 'hand-pointer']} size="1x" />
        </PointerIconWrapper>
      )}
    </MainWrapper>
  );
};
UspSpotTooltip.propTypes = {
  /** Location of the spot that this tooltip is attached to */
  uspSpotLocation: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  })
};

export default UspSpotTooltip;
