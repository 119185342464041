import React, { useEffect, useState, createRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Component
import SwitcherView from './contentGrid/SwitcherView';
import Div100vh from 'react-div-100vh';
import { IdealImage } from '@prompto-ui';
import MobileLayoutHeader from 'components/share/MobileLayoutHeader';
import MobileContentView from './contentGrid/MobileContentView';
import FoldersNavigation from './FoldersNavigation';

// Helpers
import navigationCategories from 'configs/NavigationCategories.json';
import contentItemStates from 'configs/ContentItemStates.json';
import {
  fetchSettingsFromURL,
  capitalize,
  getCurrentEnvironment
} from 'helpers';
import categories from 'configs/SupportedFileCategories.json';
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useDragControls
} from 'framer-motion';
import { useSwitcherState } from 'stores/SwitcherStore';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import localizer from 'localization/localizer';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { orderContentCollection } from './resourcesPageHelpers';

// Styles
import styled from 'styled-components';
import LoadingMessage from 'components/other/LoadingMessage';

const Wrapper = styled(Div100vh)`
  text-align: center;
  background-color: white;
  width: 100vw;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  height: ${isMobile && !isMobileOnly ? 'calc(100% - 70px)' : '100%'};
  width: 100%;
`;

const FirstImageWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const PageTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

function ResourcesPage(props) {
  const navigate = useNavigate();
  const { contractId } = fetchSettingsFromURL();

  const { ProjectState } = useProjectState();
  const {
    contentCollection,
    project,
    shareCode,
    firstImage,
    showcaseLoaded,
    showcaseConfiguration,
    folderConfiguration
  } = ProjectState;

  const { UiStateDispatch } = useUiState();

  const { SwitcherState, SwitcherStateDispatch } = useSwitcherState();
  const {
    activeCategory,
    orderedContentCollection,
    folders,
    showFolders,
    foldersRefs,
    activeFolder: sharedActiveFolder
  } = SwitcherState;

  if (contentCollection && !orderedContentCollection && folderConfiguration)
    orderContentCollection(
      contentCollection,
      folderConfiguration,
      showcaseConfiguration,
      SwitcherStateDispatch,
      createRef
    );

  const [pages, setPages] = useState([]);
  const [resourcesHasDocuments, setResourcesHasDocuments] = useState(true);

  const [hideLoadingScreen, setHideLoadingScreen] = useState(showcaseLoaded);

  // folders related
  const [activeFolder, setActiveFolder] = useState();
  const [showFolderNavigation, setShowFolderNavigation] = useState(true);

  const localDragOffset = useMotionValue(0);
  const dragControls = useDragControls();

  useEffect(() => {
    if (sharedActiveFolder && sharedActiveFolder !== activeFolder) {
      setActiveFolder(sharedActiveFolder);
    }
  }, [sharedActiveFolder, activeFolder]);

  useEffect(() => {
    if (folders?.length > 0) {
      setActiveFolder(folders[0].uuid);
    }
  }, [folders]);

  const onSetActiveFolder = (folderUuid, folderIndex) => {
    if (sharedActiveFolder === folderUuid) return;

    SwitcherStateDispatch({
      type: 'toggleOnUpdateEvent',
      payload: false
    });

    const folderRef = foldersRefs[folderUuid];
    SwitcherStateDispatch({
      type: 'scrollToActiveFolder',
      payload: {
        ref: folderRef,
        uuid: folderUuid,
        folderIndex,
        currentOffset: localDragOffset.current
      }
    });
    SwitcherStateDispatch({
      type: 'setActiveFolderRef',
      payload: folderUuid
    });
    setActiveFolder(folderUuid);
  };

  useEffect(() => {
    if (orderedContentCollection && hideLoadingScreen) {
      UiStateDispatch({
        type: 'update',
        payload: {
          showMenu: true,
          resourcesHasDocuments: resourcesHasDocuments
        }
      });
    }
  }, [
    UiStateDispatch,
    activeCategory,
    SwitcherStateDispatch,
    orderedContentCollection,
    hideLoadingScreen,
    resourcesHasDocuments
  ]);

  // If contractId passed, go to contracts
  useEffect(() => {
    if (contractId) {
      navigate(
        `/${shareCode}/${navigationCategories.contracts}${window.location.search}`
      );
    }
  }, [navigate, shareCode, contractId]);

  const createPages = (allowedCategories, list) => {
    let hasDocuments = false;
    const filteredList = [];

    list.forEach((item) => {
      if (item.contentItemType === categories.document) hasDocuments = true;
      if (
        item.contentItemState === contentItemStates.published &&
        item.contentUri &&
        item.contentItemState === contentItemStates.published &&
        allowedCategories.includes(item.contentItemType)
      ) {
        filteredList.push({
          ...item,
          name: item.title.textMap.en || item.contentItemType || 'item'
        });
      }
    });

    return [filteredList, hasDocuments];
  };

  // Create the subpages based on the collection
  useEffect(() => {
    if (!orderedContentCollection) {
      return;
    }

    // Filter out the content items that have been archived or unpublished
    const filteredContentItemList =
      orderedContentCollection?.vmContentItemList.filter(
        (item) => item.contentItemState === contentItemStates.published
      );

    setPages(() => {
      let [allPageList, hasDocuments] = createPages(
        [
          categories.video,
          categories.image,
          categories.gif,
          categories.album360,
          categories.showcase,
          categories.image360,
          categories.document,
          categories.turntable
        ],
        filteredContentItemList
      );

      setResourcesHasDocuments(hasDocuments);

      return allPageList;
    });
  }, [orderedContentCollection, project, shareCode]);

  useEffect(() => {
    // Show the loading screen for at least 2 seconds
    setTimeout(() => {
      setHideLoadingScreen(true);
    }, 2000);
  }, [shareCode]);

  const contentReady = pages?.length > 0 && hideLoadingScreen;
  return (
    <Wrapper>
      <MobileLayoutHeader>
        <PageTitle>{capitalize(localizer.resources)}</PageTitle>
      </MobileLayoutHeader>
      <ContentWrapper>
        {!isMobileOnly && (
          <AnimatePresence>
            {showFolders &&
              folders?.length > 1 &&
              showFolderNavigation &&
              hideLoadingScreen && (
                <FoldersNavigation
                  folders={folders}
                  activeFolder={activeFolder}
                  setActiveFolder={onSetActiveFolder}
                />
              )}
          </AnimatePresence>
        )}

        <FirstImageWrapper
          initial={showcaseLoaded ? { opacity: 0 } : { opacity: 1 }}
          animate={contentReady ? { opacity: 0.0 } : { opacity: 1.0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          {!contentReady && <LoadingMessage />}
          <IdealImage
            key={firstImage?.objectId}
            contentUri={firstImage?.contentUri}
            fallbackUri={firstImage?.contentUri}
            imageSize={firstImage?.originalImageSize}
            containerSize={{
              width: window.innerWidth,
              height: window.innerHeight
            }}
            mustFillParent={true}
            baseImageUrl={getCurrentEnvironment().baseImageUrl}
          />
        </FirstImageWrapper>
        {isMobileOnly && (
          <MobileContentView
            content={orderedContentCollection}
            project={project}
            shareCode={shareCode}
            contentReady={contentReady}
            resourcesHasDocuments={resourcesHasDocuments}
          />
        )}
        {!isMobileOnly && (
          <motion.div
            key={'content'}
            initial={showcaseLoaded ? { opacity: 1.0 } : { opacity: 0.0 }}
            animate={contentReady ? { opacity: 1.0 } : { opacity: 0.0 }}
            transition={{ type: 'tween', duration: 2, delay: 1 }}
          >
            {contentReady && (
              <SwitcherView
                pages={pages}
                folders={folders}
                showFolders={showFolders}
                foldersRefs={foldersRefs}
                localDragOffset={localDragOffset}
                dragControls={dragControls}
                setShowFolderNavigation={setShowFolderNavigation}
                {...props}
              />
            )}
          </motion.div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

export default ResourcesPage;
