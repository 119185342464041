import React from 'react';
import { bool, shape, number, array, string } from 'prop-types';

// Components
import Album360Content from './types/album360/Album360Content';
import DocumentContent from './types/document/DocumentContent';
import ImageContent from './types/image/ImageContent';
import VideoContent from './types/video/VideoContent';
import ThreeSixtyImageContent from 'components/pages/unitDetail/contentTypes/ThreeSixtyImageContent';
import TurntableViewer from './types/turntable/TurntableViewer';

// Helpers
import fileCategories from 'configs/SupportedFileCategories.json';
import { isMobileOnly } from 'react-device-detect';
import { getCurrentEnvironment } from 'helpers';

// Styles
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: ${({ isMobileOnly }) => (isMobileOnly ? 'fixed' : 'relative')};
  ${({ isMobileOnly }) => {
    if (isMobileOnly)
      return `
  top: 0;
  left: 0;
  z-index: 56;
  background-color: rgba(0, 0, 0, 1);
`;
  }}
`;

function MobilePage(props) {
  const {
    data,
    videoFallbackThumbnail,
    setIsDragEnabled,
    onNextPage,
    onPreviousPage,
    isDragEnabled
  } = props;

  const sharedProps = {
    ...props,
    key: data.objectId
  };

  // Content
  const createPageContent = () => {
    switch (data.contentItemType) {
      case fileCategories.image: {
        return <ImageContent {...sharedProps} />;
      }
      case fileCategories.video: {
        return (
          <VideoContent
            {...sharedProps}
            fallbackThumbnail={videoFallbackThumbnail}
          />
        );
      }
      case fileCategories.document: {
        return <DocumentContent {...sharedProps} />;
      }
      case fileCategories.album360: {
        return <Album360Content {...sharedProps} />;
      }
      case fileCategories.image360: {
        return (
          <ThreeSixtyImageContent
            {...sharedProps}
            contentUri={`${getCurrentEnvironment().baseImageUrl}/o=true/${
              data?.contentUri
            }`}
            onLoad={() => {}}
          />
        );
      }
      case fileCategories.turntable: {
        return (
          <TurntableViewer
            key={data.objectId}
            turntableId={data.value}
            setIsDragEnabled={setIsDragEnabled}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            isDragEnabled={isDragEnabled}
          />
        );
      }
      default: {
        return <ImageContent {...sharedProps} />;
      }
    }
  };

  const content = createPageContent();

  return (
    <Wrapper isMobileOnly={isMobileOnly} key={data.name}>
      {content}
    </Wrapper>
  );
}

MobilePage.propTypes = {
  allPages: array,
  autoplay: bool,
  data: shape({}).isRequired,
  pageIndex: number,
  videoFallbackThumbnail: string
};

MobilePage.defaultProps = {
  allPages: [],
  autoplay: false,
  onNextPage: () => {},
  pageIndex: 0,
  videoFallbackThumbnail: null
};

export default MobilePage;
