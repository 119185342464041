import React, { useState, useEffect } from 'react';
import { shape, string, bool, func } from 'prop-types';

import * as pdfjsLib from 'pdfjs-dist/build/pdf';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Svg360Icon, PlayThinIcon } from 'resources/icons';

// Helpers
import { motion } from 'framer-motion';
import { capitalize } from 'helpers';
import localizer from 'localization/localizer';
import { getCurrentEnvironment, computeCloudinaryThumbnail } from 'helpers';

import { ReactComponent as PdfPlaceholder } from 'resources/svgs/pdf-placeholder.svg';

// Styling
import styled from 'styled-components';

const PreviewWrapper = styled(motion.div)`
  width: 160px;
  max-width: 160px;
  height: 100%;
  max-height: 100%;
  position: relative;
  margin-right: 10px;
  box-sizing: border-box;
  opacity: ${(props) => (props.isSelected ? 1 : 0.6)};
  transition: 0.2s;
  cursor: pointer;
  flex-shrink: 0;
`;

const OverflowWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const ImageContent = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  filter: ${(props) => (props.blur ? 'blur(15px)' : 'none')};
  overflow: hidden;
`;

const CardOverlayWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.4;
`;

const Icon360 = styled(Svg360Icon)`
  color: ${(props) => props.theme.whitePure};
  transform: scale(0.7);
  z-index: 100;
`;

const SmallIcon360 = styled(Icon360)`
  transform: scale(0.5);
  color: ${(props) => props.theme.showcaseBlack};
`;

const VideoPlayIcon = styled(PlayThinIcon)`
  transform: scale(0.7);
  position: absolute;
  border-radius: 50%;
  color: ${(props) => props.theme.whitePure};
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.whitePure};
  z-index: 100;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.whitePure};
  position: absolute;
  margin: 0;
  margin-top: 40px;
`;

const PdfPlaceholderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.gray300};
  &::after {
    display: block;
    content: '';
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.gray300};
  }
`;

const StyledPdfPlaceholder = styled(PdfPlaceholder)`
  position: absolute;
  top: 0;
  left: 0;
  height: 160px;
  width: 100%;
`;

const Overlay = ({ over = false, children }) => (
  <CardOverlayWrapper>
    {over && <StyledOverlay />}
    {children}
  </CardOverlayWrapper>
);

const UnitContentCard = ({
  contentItem,
  isSelected,
  onSelectContentItem,
  on360Selected,
  onSceneSelected,
  onExternalExperienceSelected,
  fallbackThumbnail
}) => {
  /**
   * We need to set a worker in order to increase the performance when loading pdf's since the worker will do the heavy lifting.
   * We use a publicly availble worker from https://unpkg.com/ which is commonly used.
   * Make sure that the version specified here matches the pdfjs-dist version in the package.json
   */
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

  const [useFallback, setUseFallback] = useState(false);
  const [imageUri, setImageUri] = useState();

  const uri = useFallback ? fallbackThumbnail : contentItem?.contentUri;

  useEffect(() => {
    if (imageUri) return;

    let contentUri;

    switch (contentItem.contentItemType) {
      case 'video':
        contentUri = contentItem.thumbnailUri || uri;
        break;
      case 'document':
      case 'floorplan':
        try {
          contentUri = computeCloudinaryThumbnail(
            decodeURIComponent(uri),
            getCurrentEnvironment().googleStorageBucketId,
            getCurrentEnvironment().cloudinaryImageBaseUrl,
            'h_200,c_fill,dn_50'
          );
        } catch (e) {
          contentUri = 'none';
        }
        break;
      default:
        contentUri = uri;
    }

    setImageUri(contentUri);
  }, [contentItem, uri, imageUri]);

  let cardContent = <div>Loading</div>;

  if (imageUri) {
    switch (contentItem.contentItemType) {
      case 'video':
        cardContent = (
          <>
            <ImageContent
              src={imageUri}
              onError={() => {
                setUseFallback(true);
              }}
            />
            <Overlay>
              <VideoPlayIcon />
            </Overlay>
          </>
        );
        break;

      case 'document':
      case 'floorplan':
        if (imageUri === 'none' || useFallback) {
          cardContent = (
            <PdfPlaceholderWrapper>
              <StyledPdfPlaceholder />
            </PdfPlaceholderWrapper>
          );
        } else {
          cardContent = (
            <ImageContent
              src={imageUri}
              onError={() => {
                setUseFallback(true);
              }}
            />
          );
        }
        break;

      case 'scene':
        cardContent = (
          <>
            <ImageContent
              src={imageUri}
              blur
              onError={() => {
                setUseFallback(true);
              }}
            />
            <Overlay over>
              <Icon icon={['fal', 'walking']} size={'2x'} />
              <Text>{capitalize(localizer.explore)}</Text>
            </Overlay>
          </>
        );
        break;

      case 'tour360':
        cardContent = (
          <>
            <ImageContent
              src={imageUri}
              blur
              onError={() => {
                setUseFallback(true);
              }}
            />
            <Overlay over>
              <Icon360 />
            </Overlay>
          </>
        );
        break;

      case 'externalExperience':
      case 'url':
        cardContent = (
          <>
            <ImageContent src={fallbackThumbnail} blur />
            <Overlay over>
              <Icon icon={['fal', 'sparkles']} size={'2x'} />
              <Text>{localizer.www}</Text>
            </Overlay>
          </>
        );
        break;
      case 'image360':
        cardContent = (
          <>
            <ImageContent
              src={imageUri}
              onError={() => {
                setUseFallback(true);
              }}
            />
            <Overlay>
              <SmallIcon360 />
            </Overlay>
          </>
        );
        break;
      default:
      case 'image':
        cardContent = (
          <ImageContent
            src={imageUri}
            onError={() => {
              setUseFallback(true);
            }}
          />
        );
        break;
    }
  }

  return (
    <PreviewWrapper
      key={contentItem.objectId}
      initial={{ opacity: 0 }}
      animate={{ opacity: isSelected ? 1 : 0.6 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      isSelected={isSelected}
      onClick={() => {
        switch (contentItem.contentItemType) {
          default:
          case 'image':
          case 'video':
          case 'document':
          case 'floorplan':
          case 'image360':
            onSelectContentItem(contentItem);
            break;
          case 'tour360':
            on360Selected(contentItem);
            break;
          case 'scene':
            onSceneSelected(contentItem);
            break;
          case 'externalExperience':
          case 'url':
            onExternalExperienceSelected(contentItem);
            break;
        }
      }}
    >
      <OverflowWrapper>{cardContent}</OverflowWrapper>
    </PreviewWrapper>
  );
};

UnitContentCard.propTypes = {
  contentItem: shape({}),
  isSelected: bool,
  fallbackThumbnail: string,
  onSelectContentItem: func.isRequired,
  on360Selected: func.isRequired,
  onSceneSelected: func.isRequired
};

UnitContentCard.defaultProps = {
  contentItem: null,
  isSelected: false,
  fallbackThumbnail: null
};

export default UnitContentCard;
