import React, { useEffect, useState, useRef, useCallback } from 'react';
import { func } from 'prop-types';

import * as pdfjsLib from 'pdfjs-dist/build/pdf';

// Component
import UnitListCard from './UnitListCard';
import { IdealImage } from '@prompto-ui';
import { motion } from 'framer-motion';
import { Virtuoso } from 'react-virtuoso';
import SearchField from 'components/other/SearchField';
import IdealCloudinaryImage from 'components/other/IdealCloudinaryImage';
import FilterChipContainer from './searchAndFilter/FilterChipContainer';
import UnitsMobilePage from './UnitsMobilePage';
import SortUnits from './searchAndFilter/SortUnits';
import ShowcaseLoader from 'components/other/ShowcaseLoader';

// Helpers
import {
  capitalize,
  getCurrentEnvironment,
  computeCloudinaryThumbnail
} from 'helpers';
import {
  filterUnits,
  searchUnits,
  sortUnits,
  unitSortableFields,
  unitFilterOptions,
  localizeUnitFieldValues,
  sortBySortingOrders
} from 'helpers/units/VmUnitHelper';
import { useProjectState } from 'stores/ProjectStore';
import { useUiState } from 'stores/UiStore';
import localizer from 'localization/localizer';
import { isMobile } from 'react-device-detect';
import { useShowUnitGeneralFields } from 'helpers/customHooks';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  padding: 20px;
  align-items: center;
  height: 96%;
  background-color: white;
`;

const ImageWrapper = styled(motion.div)`
  position: relative;
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const UnitListWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: 715px;
`;

const SearchAndFilterWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 10px 15px;
`;

const StyledSearchField = styled(SearchField)`
  margin-right: 15px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.gray200};
  border-radius: 2px;
  padding: 0 10px;
  box-sizing: border-box;
`;

/**
 * We store the padding values of the unit cards to align the headers in the same way
 */
const unitListLeftMargin = -50;
const unitListLeftPadding = 30;
const pixelsOverlappingWithImage = Math.abs(
  unitListLeftMargin + unitListLeftPadding
);

/**
 * Virtuoso is a package to virtualize a list, meaning to render large lists without rendering all the items
 * You can find more information here: https://virtuoso.dev/
 */
const ListVirtualized = styled(Virtuoso)`
  z-index: 10;
  margin-left: ${unitListLeftMargin}px;
`;

// Compute the number and width of the columns that will contain the units informations in the cards and their headers
const unitsHeadersGridColumns = (
  addUnitStateColumn,
  showUnitGeneralFields,
  isEmbeddedShowcase
) => {
  const dividerWidth = 5;
  const numberOfDividers = 1;
  const sectionsBeforeFirstDivider = isEmbeddedShowcase ? 1 : 2;
  const sectionsAfterFirstDivider = addUnitStateColumn ? 4 : 3;
  const availableWidthForSections = 100.0 - numberOfDividers * dividerWidth;

  const numberOfSections =
    sectionsBeforeFirstDivider + sectionsAfterFirstDivider;
  const sectionWidth = availableWidthForSections / numberOfSections;
  const mainSectionWidth = sectionWidth + (isEmbeddedShowcase ? 15 : 8);
  const secondarySectionsWidth =
    availableWidthForSections - mainSectionWidth * sectionsBeforeFirstDivider;

  return showUnitGeneralFields
    ? `
  repeat(${sectionsBeforeFirstDivider}, ${mainSectionWidth}%) ${dividerWidth}% ${secondarySectionsWidth}%`
    : `repeat(2, 50%)`;
};

/**
 * The resulting width of the units header should be the same as the resulting width of the unit cards
 * This will allow the columns of their respective grids to be aligned
 */
const unitsHeaderLeftPadding = 20 + pixelsOverlappingWithImage;
const unitCardRightPadding = 15;

const NoContentWrapper = styled(motion.div)`
  position: absolute;
  height: auto;
  width: auto;
  color: ${(props) => props.theme.whitePure};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  z-index: 5;
  text-shadow: 0 0 16px ${(props) => props.theme.gray30};
  padding: 5px 25px 5px 5px;
  text-align: center;
`;

const NoPreviewAvailable = styled(NoContentWrapper)`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  color: ${(props) => props.theme.primary100};
  background-color: ${(props) => props.theme.gray100};
  text-shadow: 0 0 16px ${(props) => props.theme.gray100};
`;

/**
 * The way the informations in the unit cards are layed out should follow how the headers are layed out to get both values and headers aligned
 */
const createUnitCardHorizontalLayout = (
  addUnitStateColumn,
  showUnitGeneralFields,
  isEmbeddedShowcase
) => {
  return {
    paddingLeft: `${unitsHeaderLeftPadding}px`,
    paddingRight: `${unitCardRightPadding}px`,
    gridTemplateColumns: `${unitsHeadersGridColumns(
      addUnitStateColumn,
      showUnitGeneralFields,
      isEmbeddedShowcase
    )}`
  };
};

const mediaTypes = ['image', 'video', 'document', 'floorplan'];

const UnitsPage = ({ onOpenUnitPage }) => {
  /**
   * We need to set a worker in order to increase the performance when loading pdf's since the worker will do the heavy lifting.
   * We use a publicly availble worker from https://unpkg.com/ which is commonly used.
   * Make sure that the version specified here matches the pdfjs-dist version in the package.json
   */
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

  // Project state
  const { ProjectState, ProjectStateDispatch } = useProjectState();
  const {
    project,
    vault,
    contentCollection,
    unitFilters,
    filterSearchValue,
    favoritesList,
    showcaseConfiguration
  } = ProjectState;
  const defaultContentItemSorting = project?.defaultContentItemSorting;
  const embededUnitFormConfiguration =
    showcaseConfiguration?.embededUnitFormConfiguration;

  // UI state
  const { UiState, UiStateDispatch } = useUiState();
  const { showFavorites, isEmbeddedShowcase } = UiState;

  const [filterValues, setFilterValues] = useState(unitFilters);
  const [sortedField, setSortedField] = useState('title');
  const [sortAscending, setSortAscending] = useState(true);

  const [unitList, setUnitList] = useState();
  const [filteredUnitList, setFilteredUnitList] = useState();
  const [hoveredUnit, setHoveredUnit] = useState();

  const [unitHasContent, setUnitHasContent] = useState(true);
  const [previewContent, setPreviewContent] = useState();
  const [fallbackContent, setFallbackContent] = useState();

  const { showSurface, showBedrooms, showBathrooms } = useShowUnitGeneralFields(
    false,
    null,
    unitList
  );
  const showUnitGeneralFields = showSurface || showBedrooms || showBathrooms;

  const container = useRef();

  const showUnitState =
    ProjectState?.showcaseConfiguration?.showUnitState ?? true;

  const showContactUsCard =
    showFavorites &&
    embededUnitFormConfiguration?.showContactUsFormAsGeneric === true;

  const setSearchValue = useCallback(
    (value) => {
      ProjectStateDispatch({
        type: 'setProjectData',
        payload: {
          filterSearchValue: value
        }
      });
    },
    [ProjectStateDispatch]
  );

  const [index, setIndex] = useState(0);
  const [sortedUnitContent, setSortedUnitContent] = useState();
  const [isPreparingURL, setIsPreparingURL] = useState(true);

  // The user decided to not show the unit state or to show only available units
  if (!showUnitState || showcaseConfiguration?.hideInOptionSoldUnits) {
    let index = unitSortableFields.indexOf('state');
    if (index > -1) {
      unitSortableFields.splice(index, 1);
    }

    index = unitFilterOptions.indexOf('state');
    if (index > -1) {
      unitFilterOptions.splice(index, 1);
    }
  }

  const unitCardHorizontalLayout = createUnitCardHorizontalLayout(
    false,
    showUnitGeneralFields,
    isEmbeddedShowcase
  );

  useEffect(() => {
    UiStateDispatch({
      type: 'update',
      payload: {
        showMenu: true
      }
    });
  }, [UiStateDispatch]);

  useEffect(() => {
    if (unitList?.length > 0) {
      setHoveredUnit(unitList[0]);
    }
  }, [unitList]);

  useEffect(() => {
    if (project) {
      setUnitList(
        project.unitList?.map((unit) => localizeUnitFieldValues(project, unit))
      );

      // set the fallback image when no preview is available
      const projectContentList = contentCollection?.vmContentItemList?.filter(
        (x) =>
          x.contentItemType === 'image' && x.contentItemState !== 'archived'
      );
      if (projectContentList && projectContentList.length > 0) {
        setFallbackContent(projectContentList[0]);
      }
    }
  }, [project, contentCollection]);

  useEffect(() => {
    if (hoveredUnit) {
      // Get the first media item from the unit content
      const unitContentList =
        hoveredUnit?.vmContentCollection?.vmContentItemList?.filter((x) =>
          mediaTypes.includes(x.contentItemType)
        ) ?? [];
      const unitLinkedContentList =
        hoveredUnit?.linkedContentCollection?.vmContentItemList?.filter((x) =>
          mediaTypes.includes(x.contentItemType)
        ) ?? [];
      const contentList = [
        ...unitContentList,
        ...unitLinkedContentList.map((item) => ({ ...item, isLinked: true }))
      ];
      if (contentList.length > 0) {
        const sortedUnitContent = sortBySortingOrders(
          contentList,
          hoveredUnit?.contentItemCustomSortingValues,
          defaultContentItemSorting
        );
        setUnitHasContent(true);
        setSortedUnitContent(sortedUnitContent);
      } else {
        setUnitHasContent(false);
        setIsPreparingURL(false);
      }
    }
  }, [hoveredUnit, defaultContentItemSorting]);

  useEffect(() => {
    if (
      sortedUnitContent &&
      index < sortedUnitContent.length &&
      isPreparingURL
    ) {
      if (!!sortedUnitContent[index].contentUri) {
        if (
          sortedUnitContent[index].contentItemType === 'document' ||
          sortedUnitContent[index].contentItemType === 'floorplan'
        ) {
          const loadingTask = pdfjsLib.getDocument(
            sortedUnitContent[index].contentUri
          );
          setIsPreparingURL(true);
          loadingTask.promise
            .then(() => {
              setIsPreparingURL(false);
            })
            .catch((err) => {
              setIndex(index + 1);
              setIsPreparingURL(false);
            });
        } else {
          setIsPreparingURL(false);
        }
      } else {
        setIndex(index + 1);
      }
    }
  }, [sortedUnitContent, index, isPreparingURL]);

  useEffect(() => {
    if (sortedUnitContent) {
      setPreviewContent(sortedUnitContent[index]);
    }
  }, [sortedUnitContent, index]);

  useEffect(() => {
    if (filteredUnitList && hoveredUnit) {
      if (!filteredUnitList.includes(hoveredUnit)) {
        if (filteredUnitList.length > 0) {
          setHoveredUnit(filteredUnitList[0]);
        } else {
          setUnitHasContent(false);
        }
      }
    }
  }, [filteredUnitList, hoveredUnit]);

  // Preview
  let content;
  if (unitHasContent && previewContent?.contentUri && !isPreparingURL) {
    if (previewContent?.contentItemType === 'image') {
      content = (
        <IdealImage
          parentType={'unit'}
          id={previewContent.objectId}
          contentUri={previewContent.contentUri}
          imageSize={previewContent.originalImageSize}
          containerSize={{
            width: container.current?.getBoundingClientRect().width,
            height: container.current?.getBoundingClientRect().height
          }}
          enableTracking={true}
          vaultId={vault?.objectId}
          projectId={project?.objectId}
          baseImageUrl={getCurrentEnvironment().baseImageUrl}
        />
      );
    } else if (previewContent?.contentItemType === 'video') {
      const env = getCurrentEnvironment();
      const cloudinaryImageUri = computeCloudinaryThumbnail(
        decodeURIComponent(previewContent.contentUri),
        env.googleStorageBucketId,
        env.cloudinaryVideoBaseUrl,
        'h_1080,c_fill,dn_50'
      );

      content = (
        <IdealCloudinaryImage
          contentUri={cloudinaryImageUri}
          disableFade={true}
        />
      );
    } else if (
      previewContent?.contentItemType === 'document' ||
      previewContent?.contentItemType === 'floorplan'
    ) {
      const env = getCurrentEnvironment();
      const cloudinaryImageUri = computeCloudinaryThumbnail(
        decodeURIComponent(previewContent?.contentUri),
        env.googleStorageBucketId,
        env.cloudinaryImageBaseUrl,
        'h_1080,c_fill,dn_150'
      );
      content = (
        <IdealCloudinaryImage
          contentUri={cloudinaryImageUri}
          disableFade={true}
        />
      );
    }
  } else if (fallbackContent && !isPreparingURL) {
    content = (
      <>
        <IdealImage
          parentType={'unit'}
          id={fallbackContent.objectId}
          contentUri={fallbackContent.contentUri}
          imageSize={fallbackContent.originalImageSize}
          containerSize={{
            width: container.current?.getBoundingClientRect().width,
            height: container.current?.getBoundingClientRect().height
          }}
          enableTracking={true}
          vaultId={vault?.objectId}
          disableFade={true}
          projectId={project?.objectId}
          blur
        />
        <NoContentWrapper>
          {filteredUnitList?.length > 0
            ? capitalize(localizer.noPreview)
            : capitalize(localizer.noAttachmentForUnit)}
        </NoContentWrapper>
      </>
    );
  } else if (!isPreparingURL) {
    content = (
      <NoPreviewAvailable>{capitalize(localizer.noPreview)}</NoPreviewAvailable>
    );
  } else {
    content = <ShowcaseLoader />;
  }

  useEffect(() => {
    if (filterValues) {
      // We store the filtervalues in the store to use when returning to this page
      ProjectStateDispatch({
        type: 'setProjectData',
        payload: {
          unitFilters: filterValues
        }
      });
    }
  }, [filterValues, ProjectStateDispatch]);

  useEffect(() => {
    if (unitList) {
      let filteredList = filterUnits(unitList, filterValues);
      filteredList = searchUnits(filteredList, filterSearchValue);
      filteredList = sortUnits(filteredList, sortedField, sortAscending);

      if (showFavorites) {
        filteredList = filteredList.filter((unit) =>
          favoritesList.includes(unit.objectId)
        );
      }

      setFilteredUnitList(filteredList);
    }
  }, [
    unitList,
    filterSearchValue,
    sortedField,
    sortAscending,
    filterValues,
    showFavorites,
    favoritesList
  ]);

  if (isMobile)
    return (
      <UnitsMobilePage
        units={filteredUnitList}
        onOpenUnitPage={onOpenUnitPage}
        unitSortableFields={unitSortableFields}
        currentFieldToSort={sortedField}
        isSortAscending={sortAscending}
        onSortByChanged={(sortOnField, isAscending) => {
          setSortedField(sortOnField);
          setSortAscending(isAscending);
        }}
        setSearchValue={setSearchValue}
        setFilterValues={setFilterValues}
        searchValue={filterSearchValue}
        fallbackContent={fallbackContent}
        showContactUsCard={showContactUsCard}
      />
    );

  return (
    <Wrapper>
      <ImageWrapper ref={container}>{content}</ImageWrapper>
      {filteredUnitList && (
        <UnitListWrapper>
          <SearchAndFilterWrapper>
            <StyledSearchField
              inputFieldStyles={searchInputFieldStyles}
              placeholder={`${capitalize(localizer.search)}...`}
              onChange={setSearchValue}
              initValue={filterSearchValue}
            />
            <SortUnits
              fields={unitSortableFields}
              currentFieldToSort={sortedField}
              onSortByChanged={(sortOnField, isAscending) => {
                setSortedField(sortOnField);
                setSortAscending(isAscending);
              }}
              isSortAscending={sortAscending}
            />
            {!showcaseConfiguration?.hideFilterOptions && (
              <FilterChipContainer
                key={`filterChipContainer`}
                filterOptions={unitFilterOptions}
                initialFilterValues={unitFilters}
                units={unitList}
                onFiltersUpdated={setFilterValues}
              />
            )}
          </SearchAndFilterWrapper>
          <ListVirtualized
            data={
              showContactUsCard
                ? [{ type: 'contact-us-card' }, ...filteredUnitList]
                : filteredUnitList
            }
            itemContent={(index, cardData) => {
              return (
                <UnitListCard
                  key={cardData.objectId}
                  index={index}
                  unit={cardData}
                  hoveredUnit={hoveredUnit}
                  onOpenUnitPage={onOpenUnitPage}
                  onHoverUnit={(unit) => {
                    if (hoveredUnit.objectId !== unit.objectId) {
                      setHoveredUnit(unit);
                      setIndex(0);
                    }
                  }}
                  horizontalLayout={unitCardHorizontalLayout}
                  showUnitState={showUnitState}
                  fallbackContent={fallbackContent}
                />
              );
            }}
          />
        </UnitListWrapper>
      )}
    </Wrapper>
  );
};

const searchInputFieldStyles = `
  text-align: left;
  &::placeholder {
    text-transform: none;
    letter-spacing: normal;
  }
`;

UnitsPage.propTypes = {
  onOpenUnitPage: func.isRequired
};

UnitsPage.defaultProps = {};

export default UnitsPage;
