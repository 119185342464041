import React, { useState, useEffect } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

// Helpers
import view from 'configs/MarketplaceViews.json';
import { isMobileOnly } from 'react-device-detect';
import { capitalize } from 'helpers';
import localizer from 'localization/localizer';

// Styling
import styled, { css } from 'styled-components';

const mobileSpecificStyles = {
  wrapper: css`
    width: auto;
  `,
  viewOption: css`
    width: auto;
    min-width: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    span {
      display: none;
    }
  `
};

const Wrapper = styled(motion.div)`
  height: 50px;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  border: 1px solid ${({ theme }) => theme.gray300};
  background-color: rgba(
    249,
    247,
    250,
    ${({ theme }) => theme.controlsOpacity}
  );
  transition: all 180ms ease;
  ${isMobileOnly && mobileSpecificStyles.wrapper}
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const ViewOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 120px;
  border-radius: 21px;
  width: ${({ width }) => width}%;
  font-size: 1rem;
  color: ${({ selected, theme }) =>
    selected ? theme.primary300 : theme.primary100};
  background-color: ${({ selected, theme }) =>
    selected ? theme.showcaseWhite : 'transparent'};
  transition: all 200ms ease;
  cursor: pointer;
  span {
    margin: 0 0 0 5px;
  }
  ${isMobileOnly && mobileSpecificStyles.viewOption}
`;

const Highlighter = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ width, index }) => width * index}%;
  height: 100%;
  width: ${({ width }) => width}%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${({ theme }) => theme.gray150};
  border-radius: 21px;
  transition: left 250ms ease;
`;

const views = [
  { key: view.map, icon: 'map' },
  {
    key: view.gallery,
    icon: 'clone'
  },
  {
    key: view.cards,
    icon: 'grip-horizontal'
  }
];

const ViewSwitcher = ({ selectedView, setView, allowedViews }) => {
  const [availableViews, setAvailableViews] = useState(views);

  useEffect(() => {
    if (!allowedViews) return;
    setAvailableViews(views.filter((v) => allowedViews.includes(v.key)));
  }, [allowedViews]);

  const width = 100 / availableViews.length;

  if (availableViews.length === 1) return null;

  return (
    <Wrapper data-testid={`view_switcher_wrapper`}>
      <Container>
        {availableViews.map((v, i) => {
          const selected = v.key === selectedView;
          return (
            <ViewOption
              key={v.key}
              width={width}
              onClick={() => setView(v.key)}
              selected={selected}
              data-testid={`view_switcher_option-${v.key}`}
            >
              <FontAwesomeIcon icon={['fal', v.icon]} />
              <span>{capitalize(localizer[v.key])}</span>
            </ViewOption>
          );
        })}
        <Highlighter
          width={width}
          index={availableViews.findIndex((x) => x.key === selectedView)}
        />
      </Container>
    </Wrapper>
  );
};

export default ViewSwitcher;
